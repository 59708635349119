import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';
import {withHubSideMenu} from '../../common/hoc';
import {withDeviceMode} from '../../../../common/hoc/with-device-mode';
import {withServices} from '../../../../service-context';
import {
    onViewNotificationRequest,
    onDeleteNotificationRequest,
} from '../../../../../reducers/activity-hub/notifications';
import {
    switchMenuActiveLinkID,
    updateNotificationsFilters,
    updateNotificationsData,
} from '../../../../../actions/activity-hub';

import sweetAlert from '../../../../common/sweet-alert';

import Notifications from './notifications';
import Preloader from '../../../../common/preloader';

class NotificationsContainer extends Component {
    state = {
        list: [],
        isAllNotificationsVisible: true,
        isOpenNotification: false,
        isMultiSelectMode: false,
    };

    componentDidMount() {
        const {pageID, menuActiveLinkID} = this.props;
        /** Update active link in hub-side-menu */
        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        if (this.props.list) {
            this.onInitList();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                if (!prevProps.list) {
                    this.onInitList();
                } else {
                    let updatedState = {list: this.props.list};

                    if (!this.state.isAllNotificationsVisible) {
                        updatedState = this.transformListHandler();
                    }

                    this.setState(updatedState);
                }
            }
        }

        if (prevProps.deviceMode.active !== this.props.deviceMode.active) {
            if (this.props.list) {
                this.onInitList();
            }
        }
    }

    autoOpenNotification = (list) => {
        const openedNotification = list.find(({opened}) => opened);
        this.onOpenNotification(openedNotification.id, openedNotification.viewed);
    };

    transformListHandler = () => {
        const {list, deviceMode: {active}} = this.props;
        let updatedState = {list};

        if (!list.length) {
            return {
                ...updatedState,
                isAllNotificationsVisible: true, // hidden when list is empty
            };
        }

        // used deviceMode key: "md"
        if (active) {
            if (list.length > 5) {
                return {list: list.slice(0, 5), isAllNotificationsVisible: false};
            }
        }

        return {...updatedState, isAllNotificationsVisible: true};
    };

    onInitList = () => {
        const {additional} = this.props.deviceMode;
        const lgMode = additional.find(({key}) => key === 'lg');
        if (lgMode && lgMode.active) {
            if (this.props.list.length > 0) {
                let list = this.props.list;
                const openedNotification = this.props.list.find(({opened}) => opened);
                if (!openedNotification) {
                    list = this.props.list.map((item, idx) => ({...item, opened: idx === 0}));
                }

                this.autoOpenNotification(list);
            }
        }

        const updatedState = this.transformListHandler();
        this.setState(updatedState);
    };

    onToggleListViewMode = () => this.setState({
        list: this.props.list,
        isAllNotificationsVisible: true,
    });

    notificationsScrollToTop = () => {
        const element = document.getElementById('notifications-container-id');
        element.scrollTo({top: 0});
    };

    onToggleFilters = (filterId) => {
        if (!this.props.filters.find(item => item.id === filterId).isActive) {
            this.props.updateNotificationsFilters(this.props.filters, filterId);
        }

        this.notificationsScrollToTop();
        if (this.props.list) {
            if (!this.props.list.find(({opened}) => opened)) {
                this.setState({isAllNotificationsVisible: false});
            }
        }
    };

    onViewAction = (id, viewed) => {
        if (!viewed) {
            this.props.fetchDataOnViewNotification(id);
        }
    };

    onOpenNotification = (currentId, viewed) => {
        if (!this.state.isMultiSelectMode) {
            if (window.scrollY > 200) {
                window.scrollTo({top: 150});
            }

            this.props.updateNotificationsData(this.props.initList, currentId, 'viewed', true);
            this.setState({isOpenNotification: true});

            if (!viewed) {
                this.onViewAction(currentId, viewed);
            }
        }
    };

    onCloseNotification = (currentId) => {
        this.props.updateNotificationsData(this.props.initList, currentId, 'viewed', false);
        this.setState({isOpenNotification: false});
    };

    onSwitchNotification = (currentId, type) => {
        const {initList} = this.props;
        this.props.updateNotificationsData(initList, currentId, 'switch', false, type);

        const currentNotificationIdx = initList.findIndex((item) => item.id === currentId);
        let nextNotification = initList[currentNotificationIdx - 1];
        if (type === 'next') {
            nextNotification = initList[currentNotificationIdx + 1];
        }
        this.onViewAction(nextNotification.id, nextNotification.viewed);
    };

    onDeleteNotification = (currentId) => {
        const {delete_current_notification_question} = this.props.staticData.activity_hub.notifications;
        sweetAlert('question', delete_current_notification_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    let formData = new FormData();
                    formData.append('ids[]', currentId);
                    this.props.fetchDataOnBatchDeleteNotification({formData});
                    this.props.updateNotificationsData(this.props.initList, [currentId], 'delete', null);
                    if (this.state.isOpenNotification) {
                        this.setState({isOpenNotification: false});
                    }
                    this.notificationsScrollToTop();
                }
            });
    };

    onToggleMultiSelectMode = (action) => {
        if (action === 'open') {
            this.setState({isMultiSelectMode: true});
        } else {
            this.setState({isMultiSelectMode: false});
            if (this.props.selectedList.length > 0) {
                this.props.updateNotificationsData(this.props.initList, null, 'checked', 0, '', 0);
            }
        }
    };

    onAllCheckboxChange = ({target: {checked}}) => {
        const value = checked ? 1 : 0;
        this.props.updateNotificationsData(this.props.initList, null, 'checked', value, '', value);
    };

    onCheckboxChange = ({target: {id, checked}}) => {
        const value = checked ? 1 : 0;
        this.props.updateNotificationsData(this.props.initList, id, 'checked', value);
    };

    onMultipleDeleteNotifications = () => {
        const {delete_question} = this.props.staticData.activity_hub.notifications;
        sweetAlert('question', delete_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    let formData = new FormData();
                    const selectedIds = [];
                    this.props.selectedList.forEach(({id}) => {
                        formData.append('ids[]', id);
                        selectedIds.push(id);
                    });
                    this.props.fetchDataOnBatchDeleteNotification({formData});
                    this.props.updateNotificationsData(
                        this.props.initList, selectedIds, 'delete', null, '', 0
                    );
                    this.notificationsScrollToTop();
                }
            });
    };

    componentWillUnmount() {
        if (this.props.initList) {
            this.props.updateNotificationsData(this.props.initList, null, 'unmount');
            this.props.updateNotificationsFilters(this.props.filters, 'all');
        }
    }

    render() {
        const {filters, loading, updating, initList, list, selectedAll, selectedList} = this.props;

        const props = {
            filters, selectedAll, selectedList, initList,
            fullList: list || [],
            onOpenNotification: this.onOpenNotification,
            onCloseNotification: this.onCloseNotification,
            onSwitchNotification: this.onSwitchNotification,
            onDeleteNotification: this.onDeleteNotification,
            onToggleListViewMode: this.onToggleListViewMode,
            onToggleFilters: this.onToggleFilters,
            onToggleMultiSelectMode: this.onToggleMultiSelectMode,
            onCheckboxChange: this.onCheckboxChange,
            onAllCheckboxChange: this.onAllCheckboxChange,
            onMultipleDeleteNotifications: this.onMultipleDeleteNotifications,
        };

        return !loading && !updating
            ? <Notifications {...this.state} {...props}/>
            : <Preloader/>;
    }

    static propTypes = {
        language: PropTypes.string,
        initList: PropTypes.array,
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        filters: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        fetchDataOnViewNotification: PropTypes.func,
        fetchDataOnBatchDeleteNotification: PropTypes.func,
        switchMenuActiveLinkID: PropTypes.func,
        updateNotificationsData: PropTypes.func,
        updateNotificationsFilters: PropTypes.func,
    };
}

const mapServicesToProps = ({notificationsService}) => {
    const {postNotificationView, postNotificationBatchDelete} = notificationsService;

    return {
        fetchDataOnViewNotification: onViewNotificationRequest(postNotificationView),
        fetchDataOnBatchDeleteNotification: onDeleteNotificationRequest(postNotificationBatchDelete),
    };
};

const mapStateToProps = ({activityHub: {notifications, utils}, profile: {language}}) => ({
    language,
    initList: notifications.initList,
    list: notifications.list,
    loading: notifications.loading,
    updating: notifications.updating,
    filters: notifications.filters,
    selectedAll: notifications.selectedAll,
    selectedList: notifications.selectedList,
    menuActiveLinkID: utils.menuActiveLinkID,
});

const mapDispatchToProps = (dispatch, {
    fetchDataOnViewNotification,
    fetchDataOnBatchDeleteNotification,
}) => {
    return bindActionCreators({
        fetchDataOnViewNotification,
        fetchDataOnBatchDeleteNotification,
        switchMenuActiveLinkID,
        updateNotificationsData,
        updateNotificationsFilters,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withDeviceMode({
        key: 'md',
        default_size: 810,
        additional: [{key: 'lg', size: 992}],
    }), // default size depends on with-hub-side-menu media query
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationsContainer);