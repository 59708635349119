const uploadFileHandler = (files, onLoading, onLoaded) => {
    files.forEach(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onabort = () => console.log('file reading was aborted'); // need discussion with team and work, red-cross status
        reader.onerror = () => console.log('file reading has failed'); // need discussion with team and work

        reader.onprogress = (e) => {
            if (e.lengthComputable) {
                let progress = (e.loaded / e.total) * 100;

                onLoading(file, progress);
            }
        };

        reader.onload = () => {
            onLoaded(file);
        };
    });
};

export default uploadFileHandler;