import {createRequestHandler} from '../utils';

export const DOCUMENT_REQUEST = 'DOCUMENT_REQUEST';
export const DOCUMENT_SUCCESS = 'DOCUMENT_SUCCESS';
export const DOCUMENT_FAILURE = 'DOCUMENT_FAILURE';

export const DOCUMENT_UPDATING_REQUEST = 'DOCUMENT_UPDATING_REQUEST';
export const DOCUMENT_UPDATING_SUCCESS = 'DOCUMENT_UPDATING_SUCCESS';
export const DOCUMENT_UPDATING_FAILURE = 'DOCUMENT_UPDATING_FAILURE';

export const documentRequest = createRequestHandler('DOCUMENT');
export const updatingDocumentRequest = createRequestHandler('DOCUMENT_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    document: null,
    error: null,
};

const document = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case DOCUMENT_REQUEST:
            return initialState;

        case DOCUMENT_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case DOCUMENT_SUCCESS:
            return {
                ...initialState,
                document: payload,
                loading: false,
            };

        case DOCUMENT_UPDATING_SUCCESS:
            return {
                ...state,
                document: payload,
                updating: false,
            };

        case DOCUMENT_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case DOCUMENT_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'RESET_DOCUMENT':
            return initialState;

        default:
            return state;
    }
};

export default document;