export const about_marketplace_ru = {
    title: 'Как работает Exportery',
    pages: [
        {
            id: 'for_exporter',
            label: 'Для экспортера',
            content: [
                {
                    title: 'БЕСПЛАТНО ЗАРЕГИСТРИРУЙТЕ И ПОДТВЕРДИТЕ ДОКУМЕНТАЛЬНО',
                    description: 'свою организацию на промышленной е-платформе.',
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'РАЗМЕЩАЙТЕ СВОИ ТОВАРЫ',
                    description: 'в выбранном разделе через удобные инструменты е-платформы, включая пакетную загрузку для широкой номенклатуры товаров.',
                    img: 'man.svg',
                    alt: 'Man icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПОЛУЧАЙТЕ ЗАЯВКИ НА ПОСТАВКУ ОТ ИМПОРТЕРОВ И КОНЕЧНЫХ ПОЛЬЗОВАТЕЛЕЙ ПО ВСЕМУ МИРУ,',
                    description: 'включая правительственные организации, при абсолютно прозрачной ценовой политике в е-платформе.',
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПОЛУЧИТЕ СЕРТИФИКАТ КОНЕЧНОГО ПОЛЬЗОВАТЕЛЯ С ЭЛЕКТРОННОЙ ПОДПИСЬЮ',
                    description: 'для выполнения процедур экспортного контроля в стране отправки товара.',
                    img: 'certificate.svg',
                    alt: 'Certificate icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПОДПИШИТЕ ВАШ ЭЛЕКТРОННЫЙ КОНТРАКТ',
                    description: 'на условиях FCA.',
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'Е-ПЛАТФОРМА ПОЗВОЛИТ ВАМ АВТОМАТИЧЕСКИ СФОРМИРОВАТЬ ВСЮ ДОКУМЕНТАЦИЮ',
                    description: 'для таможенного оформления и экспорта вашего товара: инвойсы, упаковочные листы, транспортные документы и этикетки на отгрузочные места.',
                    img: 'package.svg',
                    alt: 'Package icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'СДАЙТЕ ВАШ ГРУЗ ПОСЛЕ ТАМОЖЕННОЙ ОЧИСТКИ НА ГРУЗОВОЙ ТЕРМИНАЛ АЭРОПОРТА НА УСЛОВИЯХ FCA (INCOTERMS 2020) В ВАШЕЙ СТРАНЕ,',
                    description: 'a е-платформа Exportery возьмет на себя ответсвенность за бронирование партии поставки у перевозчика , оплатит фрахт, выдаст авианакладную и предоставит международную транспортную страховку.',
                    img: 'shipment.svg',
                    alt: 'Shipment icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ВАШ ГРУЗ ПРИБУДЕТ НА ТРАНЗИТНЫЙ СКЛАД МЕЖДУНАРОДНОГО АЭРОПОРТА В НЕЙТРАЛЬНОЙ СТРАНЕ',
                    description: 'Мы проведем независимую техническую инспекцию по качеству и количеству вашего груза и подготовим его к передаче конечному пользователю. Ваш товар будет безопасно находится на транзитном складе до момента окончания расчетов.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПОЛУЧИТЕ СРЕДСТВА НА СВОЙ РАСЧЕТНЫЙ СЧЕТ',
                    description: 'согласно условий вашего е-Контракта.',
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ОТСЛЕЖИВАЙТЕ КАЖДЫЙ ЭТАП ДОСТАВКИ ВАШЕГО ГРУЗА',
                    description: 'от пункта отгрузки в вашей стране до конечного пункта назначения грузополучателя.',
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
        {
            id: 'for_importer',
            label: 'Для импортера',
            content: [
                {
                    title: 'БЕСПЛАТНО ЗАРЕГИСТРИРУЙТЕ И ПОДТВЕРДИТЕ ДОКУМЕНТАЛЬНО',
                    description: 'свою организацию на промышленной е-платформе.',
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПОЛУЧИТЕ ПРЕДЛОЖЕНИЯ НА ВАШИ ЗАЯВКИ С САМОЙ НИЗКОЙ ЦЕНОЙ И САМОЙ БЫСТРОЙ ДОСТАВКОЙ',
                    description: 'напрямую от производителей или экспортеров продукции.',
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ОТПРАВЬТЕ СВОЙ СЕРТИФИКАТ КОНЕЧНОГО ПОЛЬЗОВАТЕЛЯ',
                    description: 'через наши электронные инструменты, чтобы выполнить требования экспортного контроля в стране происхождения товара и/или получить гарантию выполнения контрактных обязательств.',
                    img: 'accept.svg',
                    alt: 'Accept icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПОДПИШИТЕ СВОЙ ЭЛЕКТРОННЫЙ КОНТРАКТ,',
                    description: 'на условиях CIP для вашего пункта назначения.',
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПРИНИМАЙТЕ ВСЮ НЕОБХОДИМУЮ ОТГРУЗОЧНУЮ ДОКУМЕНТАЦИЮ В ЭЛЕКТРОННОЙ ФОРМЕ ОТ ЭКСПОРТЕРА',
                    description: 'и получайте всю информацию о движении товара, используя нашу электронную панель.',
                    img: 'delivery.svg',
                    alt: 'Delivery icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ВАШ ГРУЗ ПРИБУДЕТ НА ТРАНЗИТНЫЙ СКЛАД МЕЖДУНАРОДНОГО АЭРОПОРТА В НЕЙТРАЛЬНОЙ СТРАНЕ',
                    description: 'Мы проведем независимую техническую инспекцию по качеству и количеству вашего груза и подготовим его к передаче конечному пользователю. Ваш товар будет безопасно находится на транзитном складе до момента окончания расчетов.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ПЕРЕВЕДИТЕ ВАШИ СРЕДСТВА',
                    description: 'После подписания акта приемо-передачи вы переводите денежные средства на счет согласно е-Контракта и одновременно наше агентство осуществит отправку груза в ваш адрес с транзитного склада.',
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ОТСЛЕЖИВАЙТЕ КАЖДЫЙ ЭТАП ДОСТАВКИ ВАШЕГО ГРУЗА',
                    description: 'до конечного пункта назначения, включая всю транспортную документацию а также уведомление о доставке.',
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
    ],
};