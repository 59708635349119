import React, {Component} from 'react';

import HttpService from '../../../services/http-service';
import PublicService from '../../../services/public-service';
import UserService from '../../../services/user-service';
import SearchService from '../../../services/search-service';
import DocumentService from '../../../services/document-service';
import {MarketplaceService, QuotationService} from '../../marketplace/services';
import {
    ActivityHubService,
    RFQService,
    PlaceProductService,
    MyProductsService,
    ProfileService,
    NotificationsService,
    SuggestService,
    EContractsService,
    OriginInvoiceService,
    PackingListWizardService,
} from '../../activity-hub/services';

import {ServiceProvider} from '../../service-context';

class ServiceRegistry extends Component {
    constructor(props) {
        super(props);

        const httpService = new HttpService();
        const publicService = new PublicService(httpService);
        const userService = new UserService(httpService);
        const searchService = new SearchService(httpService);
        const documentService = new DocumentService(httpService);

        const marketplaceService = new MarketplaceService(httpService);
        const quotationService = new QuotationService(httpService);

        const activityHubService = new ActivityHubService(httpService);
        const rfqService = new RFQService(httpService);
        const placeProductService = new PlaceProductService(httpService);
        const myProductsService = new MyProductsService(httpService);
        const profileService = new ProfileService(httpService);
        const notificationsService = new NotificationsService(httpService);
        const suggestService = new SuggestService(httpService);

        const eContractsService = new EContractsService(httpService);
        const originInvoiceService = new OriginInvoiceService(httpService);
        const packingListWizardService = new PackingListWizardService(httpService);

        this.state = {
            httpService,
            publicService,
            userService,
            searchService,
            documentService,

            marketplaceService,
            quotationService,

            activityHubService,
            rfqService,
            placeProductService,
            myProductsService,
            profileService,
            notificationsService,
            suggestService,

            eContractsService,
            originInvoiceService,
            packingListWizardService,
        };
    }

    register = (name, service) => {
        this.setState({
            [name]: service,
        });
    };

    render() {
        return (
            <ServiceProvider value={{...this.state, register: this.register}}>
                {this.props.children}
            </ServiceProvider>
        );
    }
}

export default ServiceRegistry;