import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const CREATE_BANK_ACCOUNT_REQUEST = 'CREATE_BANK_ACCOUNT_REQUEST';
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS';
export const CREATE_BANK_ACCOUNT_FAILURE = 'CREATE_BANK_ACCOUNT_FAILURE';

export const EDIT_BANK_ACCOUNT_REQUEST = 'EDIT_BANK_ACCOUNT_REQUEST';
export const EDIT_BANK_ACCOUNT_SUCCESS = 'EDIT_BANK_ACCOUNT_SUCCESS';
export const EDIT_BANK_ACCOUNT_FAILURE = 'EDIT_BANK_ACCOUNT_FAILURE';

export const createBankAccountRequest = createRequestHandler('CREATE_BANK_ACCOUNT');
export const editBankAccountRequest = createRequestHandler('EDIT_BANK_ACCOUNT');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const bankAccountActions = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CREATE_BANK_ACCOUNT_REQUEST:
        case EDIT_BANK_ACCOUNT_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case CREATE_BANK_ACCOUNT_SUCCESS:
        case EDIT_BANK_ACCOUNT_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case CREATE_BANK_ACCOUNT_FAILURE:
        case EDIT_BANK_ACCOUNT_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                ...initialState,
                error,
            };

        case 'RESET_BANK_ACCOUNT_ACTIONS':
            return initialState;

        default:
            return state;
    }
};

export default bankAccountActions;