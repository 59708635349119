const updatedPaginationParams = (location, page) => {
    const {pathname, search} = location;
    const regExp = new RegExp('page=.*', 'g');

    if (page === 1) {
        const regExp = new RegExp('[?|&]page=.*', 'g');

        return search.match(regExp)[0].includes('?')
            ? search.replace(regExp, pathname)
            : search.replace(regExp, '');
    }

    return search !== ''
        ? search.match(regExp) !== null
            ? search.replace(regExp, `page=${page}`)
            : `${search}&page=${page}`
        : `?page=${page}`;
};

export {
    updatedPaginationParams,
};