import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../../../common/hoc';
import ibanHandler from '../../../../../../../../../../../utils/iban-handler';
import sweetAlert from '../../../../../../../../../../common/sweet-alert';

import Preloader from '../../../../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './currency-section.module.scss';

const CurrencySection = (props) => {
    const {
        staticData, banksList,
        removeBankAccount: {id: removingBankAccountId, loading: removingBankAccountLoading},
        onRemoveAccount,
    } = props;
    const {
        default_account, set_as_default, no_accounts_message,
    } = staticData.activity_hub.profile.pages.payment_setup;

    const onRemove = (id, href) => {
        const {delete_item_question} = staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    onRemoveAccount(id, href);
                }
            });
    };

    return (
        <div className={styles.container} style={{pointerEvents: removingBankAccountLoading ? 'none' : 'auto'}}>
            {banksList.length !== 0
                ? banksList.map(({id, name, iban, href, isDefault}, idx) => {
                    const {edit, remove} = href;
                    const cx = classnames.bind(styles);
                    const labelClasses = cx('label', {'active': isDefault});
                    const label = !isDefault ? set_as_default : default_account;

                    return (
                        <div id={id} key={idx} className={styles.card}>
                            {!isDefault
                                ? (
                                    <Link to={edit}>
                                        <div className={labelClasses}>{label}</div>
                                    </Link>
                                ) : <div className={labelClasses}>{label}</div>}

                            <div className={styles.content}>
                                <div className={styles.name}>{name}</div>
                                <div className={styles.iban}>{ibanHandler(iban)}</div>

                                <div className={styles.buttons}>
                                    {remove && <div className={styles.delete} onClick={() => onRemove(id, remove)}/>}
                                    <Link to={edit}>
                                        <div className={styles.edit}/>
                                    </Link>
                                </div>

                                {(removingBankAccountLoading && id === removingBankAccountId)
                                && (
                                    <div className={styles['preloader-block']}>
                                        <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                                    </div>)}
                            </div>
                        </div>
                    );
                })
                : <div className={styles.message}>{no_accounts_message}</div>}
        </div>
    );
};

CurrencySection.defaultProps = {
    onRemoveAccount: () => {
    },
};

CurrencySection.propTypes = {
    staticData: PropTypes.object,
    banksList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        iban: PropTypes.string,
        href: PropTypes.shape({
            edit: PropTypes.string,
            remove: PropTypes.string,
        }),
        isDefault: PropTypes.number,
    })),
    removeBankAccount: PropTypes.shape({
        id: PropTypes.number,
        loading: PropTypes.bool,
    }),
    onRemoveAccount: PropTypes.func,
};

export default withAccordion()(CurrencySection);