import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {errorURLs} from '../../services/api-urls';

import NewsContainer from './new-container';
import Article from './components/pages/article';

class NewsModule extends Component {
    render() {
        const {match: {path: basePath}} = this.props;

        return (
            <Switch>
                <Route path={basePath} exact render={(props) =>
                    <NewsContainer {...props}/>
                }/>

                <Route path={`${basePath}/article/:id`} exact render={(props) =>
                    <Article {...props}/>
                }/>

                <Route>
                    <Redirect to={errorURLs.not_found}/>
                </Route>
            </Switch>
        );
    }

    static propTypes = {
        match: PropTypes.object,
    };
}

export default withRouter(NewsModule);