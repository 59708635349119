import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './alert.module.scss';

const Alert = ({children, status, onResetAlert}) => {
    const ref = useRef();
    const cx = classnames.bind(styles);
    const classes = cx('container', [status], 'shown');

    useEffect(() => {
        let timer = null;

        if (ref.current) {
            timer = setTimeout(() => {
                ref.current.classList.remove(`${styles.shown}`);
                onResetAlert();
                // timeout depends on the css animation
            }, 2600);
        }

        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    });

    return <div ref={ref} className={classes}>{children}</div>;
};

Alert.defaultProps = {
    status: 'ERROR',
    onResetAlert: () => {
    },
};

Alert.propTypes = {
    children: PropTypes.node,
    status: PropTypes.oneOf(['SUCCESS', 'INFO', 'WARNING', 'ERROR']),
    onResetAlert: PropTypes.func,
};

export default Alert;