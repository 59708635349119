import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';

import Preloader from '../../../../../../../common/preloader';
import Dropzone from '../../../../../../../common/dropzone';
import FileContainer from '../../../../../../../common/file-container/file-container';
import Button from '../../../../../../../common/button';

import classnames from 'classnames/bind';
import styles from './product-guides.module.scss';

const ProductGuides = (props) => {
    const {
        staticData, formFields: {loading, updating}, isDisabledSection,
        documents, isSaveButtonDisabled, defaultProps, deleteAlreadyUploadedFile,
        onDeleteFile, onDeleteRejectedFile, onDeleteAlreadyExistFile,
        onSave, onSkip, ...rest
    } = props;

    const {no_documents_text, button: {skip, save}} = staticData.activity_hub.place_product.from_form;

    const cx = classnames.bind(styles);

    const documentTypes = defaultProps.mimes;

    let documentsBlock = [];
    if (documents) {
        const {alreadyUploadedFiles, files, rejectedFiles, maxFiles, maxSize} = documents;
        const amountOfUploadedFiles = alreadyUploadedFiles.length + files.length;
        const filesBlockClasses = cx('files-block', {'full-width': isDisabledSection});
        documentsBlock = (
            <>
                {!isDisabledSection && (
                    <Dropzone {...rest}
                              id={'product-guides'}
                              containerClassNames={styles.dropzone}
                              fileTypes={documentTypes}
                              maxFiles={maxFiles}
                              maxSize={maxSize}
                              isDisabled={amountOfUploadedFiles >= defaultProps.maxCount}
                              multiple/>
                )}

                <div className={filesBlockClasses}>
                    {alreadyUploadedFiles.length !== 0
                        ? alreadyUploadedFiles.map((file, idx) => {
                            let fileDeleting = false;
                            if (deleteAlreadyUploadedFile) {
                                const {id, loading} = deleteAlreadyUploadedFile;
                                fileDeleting = loading && file.id === id;
                            }

                            return (
                                <FileContainer key={idx}
                                               classNames={styles.file}
                                               uploadedFile={file}
                                               fileTypes={documentTypes}
                                               fileSize={maxSize}
                                               fileDeleting={fileDeleting}
                                               onDelete={() => onDeleteAlreadyExistFile(file.id)}
                                               canDelete={!isDisabledSection}
                                               canDownload={!isDisabledSection}/>
                            );
                        }) : isDisabledSection
                            ? <div className={styles['empty-block']}>{no_documents_text}</div>
                            : null}
                    {files.length !== 0 && files.map((file, idx) => {
                        const fileObj = file.progress ? file.file : file;
                        return (
                            <FileContainer key={idx}
                                           classNames={styles.file}
                                           file={fileObj}
                                           fileTypes={documentTypes}
                                           fileSize={maxSize}
                                           fileProgress={file.progress}
                                           isFileLoaded={file.isLoaded}
                                           onDelete={() => onDeleteFile(idx)}
                            />
                        );
                    })}
                    {rejectedFiles.length !== 0 && rejectedFiles.map((file, idx) => {
                        return (
                            <FileContainer key={idx}
                                           classNames={styles.file}
                                           file={file}
                                           fileTypes={documentTypes}
                                           fileSize={maxSize}
                                           onDelete={() => onDeleteRejectedFile(idx)}
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    return (loading || updating)
        ? <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
        : (
            <div className={styles.container}>
                {documentsBlock}

                {!isDisabledSection && (
                    <div className={styles['btn-group']}>
                        <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                        <Button onClick={onSave} isDisabled={isSaveButtonDisabled}>{save}</Button>
                    </div>
                )}
            </div>
        );
};

ProductGuides.defaultProps = {
    isDisabledSection: false,
    onDeleteFile: () => {
    },
    onDeleteRejectedFile: () => {
    },
    onSave: () => {
    },
    onSkip: () => {
    },
};

ProductGuides.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    isDisabledSection: PropTypes.bool,
    documents: PropTypes.object,
    isSaveButtonDisabled: PropTypes.bool,
    defaultProps: PropTypes.shape({
        maxCount: PropTypes.number,
        mimes: PropTypes.array,
    }),
    deleteAlreadyUploadedFile: PropTypes.object,
    onDeleteFile: PropTypes.func,
    onDeleteRejectedFile: PropTypes.func,
    onDeleteAlreadyExistFile: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
};

export default withSectionPanel()(ProductGuides);