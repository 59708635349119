import React from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';

import hubMenuService from '../../../../services/hub-menu-service';

const withHubMenuLinks = () => (Wrapped) => {
    return (props) => {
        const {language, userInteraction, profile: {is_trial}} = useSelector(({profile}) => profile);

        const data = hubMenuService.getHubMenuLinks(language, userInteraction, is_trial);

        let menuLinks = data;
        const mdDevice = useMediaQuery({minWidth: 768});
        if (mdDevice && data && userInteraction === 'importer') {
            menuLinks = data.filter(({id}) => id !== hubMenuService.pageID.create_quotation);
        }

        return <Wrapped {...props} hubMenuLinks={menuLinks}/>;
    };
};

export default withHubMenuLinks;