const useParallaxDirection = () => {
    let carParallaxDirection = {};

    let helicopterParallaxDirection = {
        x: ['5%', '-25%'],
        y: ['70%', '-25%'],
    };

    let mountainParallaxDirection = {
        x: ['-12.5%', '0%'],
        y: ['-10%', '0%'],
    };

    let radarParallaxDirection = {x: ['15%', '0']};

    return {
        carParallaxDirection,
        helicopterParallaxDirection,
        mountainParallaxDirection,
        radarParallaxDirection,
    };
};

export {useParallaxDirection};