export default class SearchService {
    constructor(httpService) {
        this._http = httpService;
    }

    getSearchData = (slug, query) => {
        if (slug) {
            return this._http.get(`/search/${slug}`, query);
        }

        return this._http.get('/search', query);
    };
}