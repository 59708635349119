import React, {useEffect, useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';

import 'react-phone-input-2/lib/bootstrap.css';
import classnames from 'classnames/bind';
import styles from './input-phone.module.scss';

const InputPhone = (props) => {
    const {
        staticData, label, name, value, props: inputProps,
        containerClassNames, error, onInputPhoneChange, onInputPhoneFocus,
    } = props;
    const {search_placeholder, search_not_found} = staticData;

    const [inputValue, setInputValue] = useState(null);
    const [flagVisible, setFlagVisibility] = useState(false);

    useEffect(() => {
        if (value) {
            setInputValue(value);
            setFlagVisibility(true);
        }
    }, [value]);

    const isRequired = inputProps && inputProps.includes('required');
    const cx = classnames.bind(styles);
    const containerClasses = cx('container', containerClassNames, {'error': error});

    return (
        <div className={containerClasses}>
            <PhoneInput inputProps={{name}}
                        searchPlaceholder={search_placeholder}
                        searchNotFound={search_not_found}
                        value={inputValue}
                        placeholder={''}
                        onChange={(value, country) => {
                            let flagVisible = Object.keys(country).length > 0;
                            if (!value) {
                                value = null;
                                flagVisible = false;
                            }

                            setInputValue(value);
                            setFlagVisibility(flagVisible);
                            onInputPhoneChange(name, value);
                        }}
                        onFocus={onInputPhoneFocus}
                        containerClass={styles['input-wrapper']}
                        inputClass={styles.input}
                        buttonClass={cx('select-btn', {'flag-hidden': !flagVisible})}
                        dropdownClass={styles.menu}
                        enableSearch
                        enableTerritories
                        enableAreaCodes
                        enableLongNumbers/>

            <div className={styles.label}>
                {label}
                {isRequired && <span className={styles['required-icon']}>*</span>}
            </div>
            <div className={styles['error-message']}>{error}</div>
            <span className={styles['error-icon']}/>
        </div>
    );
};

InputPhone.defaultProps = {
    onInputPhoneChange: () => {
    },
    onInputPhoneFocus: () => {
    },
};

InputPhone.propTypes = {
    staticData: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    inputProps: PropTypes.arrayOf(PropTypes.string),
    containerClassNames: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onInputPhoneChange: PropTypes.func,
    onInputPhoneFocus: PropTypes.func,
};

export default withDataService('input_phone')(InputPhone);