import {createRequestHandler, createAlertResponse} from '../../utils';

export const REMOVE_PRODUCT_OFFER_REQUEST = 'REMOVE_PRODUCT_OFFER_REQUEST';
export const REMOVE_PRODUCT_OFFER_SUCCESS = 'REMOVE_PRODUCT_OFFER_SUCCESS';
export const REMOVE_PRODUCT_OFFER_FAILURE = 'REMOVE_PRODUCT_OFFER_FAILURE';

export const onRemoveProductOfferRequest = createRequestHandler(
    'REMOVE_PRODUCT_OFFER',
    {forbiddenErrorRedirect: false},
);

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const removeProductOffer = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case REMOVE_PRODUCT_OFFER_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case REMOVE_PRODUCT_OFFER_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case REMOVE_PRODUCT_OFFER_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_REMOVE_PRODUCT_OFFER':
            return initialState;

        default:
            return state;
    }
};

export default removeProductOffer;