import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../common/hoc';
import {loginAction} from '../../actions/common';

import Header from './header';

class HeaderContainer extends Component {
    render() {
        return <Header {...this.props}/>;
    }

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
    };
}

const mapStateToProps = ({session: {session}, profile}) => {
    return ({session, profile});
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onOpenSignIn: loginAction.openSignIn,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(HeaderContainer);