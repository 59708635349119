import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../common/hoc';
import {withHubSideMenu} from '../../../../common/hoc';
import {withServices} from '../../../../../../service-context';
import {
    shipmentOrganizationFormFieldsRequest,
    updatingShipmentOrganizationFormFieldsRequest,
} from '../../../../../../../reducers/activity-hub/profile/shipment-organization/shipment-organization-form-fields';
import {shipmentOrganizationRequest} from '../../../../../../../reducers/activity-hub/profile/shipment-organization/shipment-organization-actions';
import {setDefaultShipmentOrganizationRequest} from '../../../../../../../reducers/activity-hub/profile/shipment-organization/default-shipment-organization';
import {profileShipmentOrganizationAction} from '../../../../../../../actions/activity-hub';
import {
    getDataFromFields,
    onChangeFieldValue,
    transformFieldData,
} from '../../../../common/handlers/field-handlers';
import {onCreateFormData} from '../../../../../../common/handlers';
import {onGetAlertResponse} from '../../../../../../../utils';
import sweetAlert from '../../../../../../common/sweet-alert';

import ShipmentOrganization from './shipment-organization';
import Preloader from '../../../../../../common/preloader';

class ShipmentOrganizationCreateModule extends Component {
    state = {
        list: [],
        isDisabledFields: false,
        isSaveButtonDisabled: true,
    };

    componentDidMount() {
        const {match: {params: {id}}, organization_id, pageType = 'create'} = this.props;

        if (pageType === 'create') {
            this.props.fetchData(organization_id, null, null, pageType);
        }

        if (pageType === 'edit') {
            this.props.fetchData(organization_id, id, null, pageType);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {match: {params: {id}}, organization_id, pageType = 'create'} = this.props;

            if (pageType === 'create') {
                this.props.fetchUpdatingData(organization_id, null, null, pageType);
            }

            if (pageType === 'edit') {
                this.props.fetchUpdatingData(organization_id, id, null, pageType);
            }
        }

        if (prevProps.fieldsList !== this.props.fieldsList) {
            const {fieldsList, pageType} = this.props;
            if (fieldsList) {
                let updatedList = fieldsList.map((field) => transformFieldData(field));

                if (pageType === 'create') {
                    updatedList = updatedList.filter(({type}) => type !== 'button');
                }

                if (pageType === 'edit') {
                    updatedList = updatedList.map((field) => {
                        const {type, value} = field;
                        if (type === 'checkbox') {
                            const isDisabledFields = value === 1;
                            this.setState({isDisabledFields});
                        }

                        return field;
                    });
                }

                this.setState({list: updatedList});
            }
        }

        if (prevProps.actionResponse !== this.props.actionResponse) {
            const {status, text} = onGetAlertResponse(this.props.actionResponse);

            if (status === 'success') {
                sweetAlert(status, text)
                    .then(() => {
                        this.props.history.push(this.props.basePath);
                        this.props.resetShipmentOrganizationActions();
                    });
            }

            if (status === 'error') {
                const errorMessage = (
                    <ul>
                        {Object.values(text).map((item, idx) => {
                            return <li key={idx}>{`- ${item}`}</li>;
                        })}
                    </ul>
                );

                sweetAlert(
                    status, errorMessage,
                    false, false,
                    false, 3000,
                ).then(() => this.props.resetShipmentOrganizationActions());
            }
        }

        if (prevProps.setDefaultResponse !== this.props.setDefaultResponse) {
            const {status, text} = onGetAlertResponse(this.props.setDefaultResponse);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    if (status === 'success') {
                        const {match: {params: {id}}, organization_id, pageType} = this.props;
                        this.props.fetchUpdatingData(organization_id, id, null, pageType);
                    }

                    this.props.resetSetDefaultShipmentOrganization();
                });
            }
        }
    }

    onSaveButtonChange = () => {
        if (this.state.isSaveButtonDisabled) {
            this.setState({isSaveButtonDisabled: false});
        }
    };

    onSetDefault = (url) => {
        const {default_change_question} = this.props.staticData.activity_hub.profile.pages.shipment_organization;

        sweetAlert('question', default_change_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.onSetDefaultShipmentOrganization(url);
                }
            });
    };

    onUpdateFieldsValue = (name, value) => {
        const list = onChangeFieldValue(this.state.list, name, value);
        this.setState({list});

        this.onSaveButtonChange();
    };

    onClickSelectItem = (fieldName, {value}) => this.onUpdateFieldsValue(fieldName, value);

    onInputChange = ({target: {name, value}}) => this.onUpdateFieldsValue(name, value);

    onInputPhoneChange = (name, value) => this.onUpdateFieldsValue(name, value);

    onCheckboxChange = ({target: {name, checked}}) => {
        const {list} = this.state;
        const checkboxIdx = list.findIndex((field) => field.name === name);
        const checkbox = list[checkboxIdx];
        const updatedCheckbox = {...checkbox, value: checked ? 1 : 0};

        const updatedList = [
            ...list.slice(0, checkboxIdx),
            updatedCheckbox,
            ...list.slice(checkboxIdx + 1),
        ];

        this.setState({
            list: updatedList,
            isDisabledFields: checked,
        });

        this.onSaveButtonChange();
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {list} = this.state;
        const {match: {params: {id}}, organization_id, pageType} = this.props;

        const object = getDataFromFields(list);
        const formData = onCreateFormData(object);

        if (pageType === 'create') {
            this.props.onSubmitData(organization_id, null, {formData}, pageType);
        }

        if (pageType === 'edit') {
            this.props.onSubmitData(organization_id, id, {formData}, pageType);
        }
    };

    onCancel = () => this.props.history.push(this.props.basePath);

    render() {
        const {
            staticData, pageType, loading, updating, error,
            actionLoading, setDefaultLoading,
        } = this.props;

        const params = {
            staticData,
            pageType,
            actionLoading,
            setDefaultLoading,
            onClickSelectItem: this.onClickSelectItem,
            onInputChange: this.onInputChange,
            onInputPhoneChange: this.onInputPhoneChange,
            onCheckboxChange: this.onCheckboxChange,
            onSubmit: this.onSubmit,
            onCancel: this.onCancel,
            onSetDefault: this.onSetDefault,
        };

        return !loading && !updating && !error
            ? <ShipmentOrganization {...this.state} {...params}/>
            : <Preloader/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        basePath: PropTypes.string,
        pageType: PropTypes.string,
        organization_id: PropTypes.string,
        fieldsList: PropTypes.array,
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        actionLoading: PropTypes.bool,
        actionResponse: PropTypes.object,
        setDefaultAccountLoading: PropTypes.bool,
        setDefaultAccountResponse: PropTypes.object,
        fetchData: PropTypes.func,
        fetchUpdatingData: PropTypes.func,
        onSubmitData: PropTypes.func,
        onSetDefaultShipmentOrganization: PropTypes.func,
        resetShipmentOrganizationActions: PropTypes.func,
        resetSetDefaultShipmentOrganization: PropTypes.func,
    };
}

const mapServicesToProps = ({profileService: {shipmentOrganizationForm, setDefaultShipmentOrganization}}) => {
    return {
        fetchData: shipmentOrganizationFormFieldsRequest(shipmentOrganizationForm),
        fetchUpdatingData: updatingShipmentOrganizationFormFieldsRequest(shipmentOrganizationForm),
        onSubmitData: shipmentOrganizationRequest(shipmentOrganizationForm),
        onSetDefaultShipmentOrganization: setDefaultShipmentOrganizationRequest(setDefaultShipmentOrganization),
    };
};

const mapStateToProps = ({profile: {language}, activityHub: {profile: {shipmentOrganization}}}) => ({
    language,
    fieldsList: shipmentOrganization.formFields.list,
    loading: shipmentOrganization.formFields.loading,
    updating: shipmentOrganization.formFields.updating,
    error: shipmentOrganization.formFields.error,
    actionLoading: shipmentOrganization.actions.loading,
    actionResponse: shipmentOrganization.actions,
    setDefaultLoading: shipmentOrganization.default.loading,
    setDefaultResponse: shipmentOrganization.default,
});

const mapDispatchToProps = (dispatch, {
    fetchData,
    fetchUpdatingData,
    onSubmitData,
    onSetDefaultShipmentOrganization,
}) => {
    const {
        resetShipmentOrganizationActions,
        resetSetDefaultShipmentOrganization,
    } = profileShipmentOrganizationAction;

    return bindActionCreators({
        fetchData,
        fetchUpdatingData,
        onSubmitData,
        onSetDefaultShipmentOrganization,
        resetShipmentOrganizationActions,
        resetSetDefaultShipmentOrganization,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ShipmentOrganizationCreateModule);