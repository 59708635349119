import React from 'react';
import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import 'tippy.js/animations/shift-away.css';

import classnames from 'classnames/bind';
import styles from './dropdown.module.scss';

const Dropdown = (props) => {
    const {
        type, placement, id, label, children, offset,
        labelClassNames, itemsClassNames,
        isOpen, isDisable, onToggle,
    } = props;

    const cx = classnames.bind(styles);

    const labelClasses = cx('label', labelClassNames, {
        'filter': type === 'FILTER',
        'active': isOpen,
    });

    const itemsClasses = cx(itemsClassNames, {
        'filter': type === 'FILTER',
    });

    const content = type === 'FILTER' ? (
        <div className={`${styles['items-block']} ${itemsClasses}`}>
            <div className={styles.items}>
                {children}
                <div className={styles['close-btn']}
                     onClick={() => id !== undefined ? onToggle(id) : onToggle()}/>
            </div>
        </div>
    ) : (
        <div className={`${styles.items} ${itemsClasses}`}>
            {children}
        </div>
    );

    return (
        <Tippy
            content={content}
            animation={'shift-away'}
            arrow={false}
            appendTo={'parent'}
            popperOptions={{modifiers: [{name: 'flip', enabled: false}]}}
            interactive={true}
            maxWidth="none"
            role="dropdown"
            offset={offset}
            placement={placement}
            visible={isOpen}
            onClickOutside={
                !isDisable && id !== undefined
                    ? () => onToggle(id)
                    : () => onToggle()
            }>
            <div className={labelClasses}
                 onClick={() => id !== undefined ? onToggle(id) : onToggle()}>
                {label}
            </div>
        </Tippy>
    );
};

Dropdown.defaultProps = {
    type: 'PRIMARY',
    placement: 'bottom-start',
    isDisable: false,
    offset: [0, 0],
    onToggle: () => {
    },
};

Dropdown.propTypes = {
    type: PropTypes.oneOf(['PRIMARY', 'FILTER']),
    placement: PropTypes.string,
    id: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.node.isRequired,
    offset: PropTypes.arrayOf(PropTypes.number),
    labelClassNames: PropTypes.string,
    itemsClassNames: PropTypes.string,
    isOpen: PropTypes.bool,
    isDisable: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default Dropdown;