import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import FormFieldCreator from '../../../../../../common/handlers/form-field-creator';

import FormFieldConstructor from '../../../../../../../../common/form-field-constructor';
import ShipmentSection from './components/shipment-section';
import Button from '../../../../../../../../common/button';
import Preloader from '../../../../../../../../common/preloader';

import styles from './shipment-setup.module.scss';

const formFieldCreator = new FormFieldCreator();
const formFieldHandler = (formFields, listeners) => {
    const fields = formFieldCreator.getFormFields(formFields);

    return fields.map((field, idx) =>
        <FormFieldConstructor {...listeners} key={idx} field={field}/>,
    );
};

const ShipmentSetup = (props) => {
    const {
        staticData, userInteraction, list,
        shipmentListLoading: loading, shipmentListUpdating: updating, organizations,
        removingOrganization, removeShipmentOrganizationLoading,
        onClickSelectItem, onRadioButtonChange,
        onToggleAccordion, onRemoveOrganization,
    } = props;
    const {button} = staticData.activity_hub.profile.pages.shipment_setup;

    if (!list) {
        return null;
    }

    let shipmentOptionsBlock = [],
        radioBlock = null,
        selectsBlock = null,
        title = null;

    const listeners = {
        onClickSelectItem,
        onRadioButtonChange,
    };

    if (list.length > 0) {
        title = list[0].section;
        shipmentOptionsBlock = formFieldHandler(list, listeners);

        if (userInteraction === 'importer') {
            radioBlock = shipmentOptionsBlock.slice(0, 1);
            selectsBlock = shipmentOptionsBlock.slice(1);
        } else {
            selectsBlock = shipmentOptionsBlock;
        }
    }

    const shipmentSectionBlock = loading || updating
        ? <Preloader classNames={styles.preloader} type={'SECONDARY'}/>
        : organizations.map((organization, idx) => {
            const {id, label, list, href, isOpen} = organization;

            const labelBlock = (
                <div className={styles['setup-title']}>{label}
                    <Link to={href.add}>
                        <Button styleType={'WITH_PLUS'} classNames={styles.btn}>{button[id]}</Button>
                    </Link>
                </div>
            );

            return (
                <ShipmentSection key={idx}
                                 staticData={staticData}
                                 containerClassNames={styles.setup}
                                 labelClassNames={styles['setup-label']}
                                 contentClassNames={styles['setup-content']}
                                 label={labelBlock}
                                 organizationsList={list}
                                 isOpenManually={isOpen}
                                 onToggleAccordion={() => onToggleAccordion(id)}
                                 sectionId={id}
                                 onRemoveShipmentOrganization={onRemoveOrganization}
                                 removeOrganization={{
                                     id: removingOrganization,
                                     loading: removeShipmentOrganizationLoading,
                                 }}
                                 manuallyMode/>
            );
        });

    return (
        <div className={styles.container}>
            {list.length > 0 && (
                <div className={styles['shipment-options']}>
                    {title && <div className={styles.title}>{title}</div>}

                    {radioBlock && (
                        <div className={styles['radio-block']}>
                            {radioBlock}
                        </div>
                    )}

                    {selectsBlock && (
                        <div className={`${styles['grid-group']} ${styles['group-even-2']}`}>
                            {selectsBlock}
                        </div>
                    )}
                </div>
            )}

            <div className={styles['organizations-block']}>
                {shipmentSectionBlock}
            </div>
        </div>
    );
};

ShipmentSetup.defaultProps = {
    onClickSelectItem: () => {
    },
    onRadioButtonChange: () => {
    },
    onToggleAccordion: () => {
    },
    onRemoveOrganization: () => {
    },
};

ShipmentSetup.propTypes = {
    staticData: PropTypes.object,
    userInteraction: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    organizations: PropTypes.array,
    removingOrganization: PropTypes.number,
    removeShipmentOrganizationLoading: PropTypes.bool,
    onClickSelectItem: PropTypes.func,
    onRadioButtonChange: PropTypes.func,
    onToggleAccordion: PropTypes.func,
    onRemoveOrganization: PropTypes.func,
};

export default ShipmentSetup;