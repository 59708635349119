const dropzonePropsTransformer = (props) => {
    const key = props.split(':')[0];
    let value = props.split(':')[1];

    if (key === 'maxCount' || key === 'maxSize') {
        value = parseInt(value);
    }

    if (key === 'mimes') {
        value = value.split(',').map(mime => `.${mime}`);
    }

    return {
        [key]: value,
    };
};

export default dropzonePropsTransformer;