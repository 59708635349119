export const updateSuggestStrategyList = (newStrategyList) => {
    return {
        type: 'UPDATE_SUGGEST_STRATEGY_LIST',
        payload: newStrategyList,
    };
};

export const updateSelectedStrategy = (newStrategy) => {
    return {
        type: 'UPDATE_SELECTED_STRATEGY',
        payload: newStrategy,
    };
};

export const resetSuggestStrategyUpdating = () => {
    return {type: 'RESET_SUGGEST_STRATEGY_UPDATING'};
};