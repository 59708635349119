export default class SuggestService {
    constructor(httpService) {
        this._http = httpService;
    }

    getSuggested = (url, query) => {
        return this._http.get(url, query);
    };

    getDataByPnum = (slug) => {
        return this._http.get(`/product/${slug}/form`);
    };
};