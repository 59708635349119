import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../hoc';
import withHubMenuLinks from '../hoc/with-hub-menu-links';
import {withServices} from '../../../service-context';
import {logoutRequest} from '../../../../reducers/login';
import {staticURLs} from '../../../../services/api-urls';

import MenuItem from '../menu-item';

import classnames from 'classnames/bind';
import styles from './hub-side-menu.module.scss';

import profileIcon from '../../assets/profile_icons/profile_icon.svg';

class HubSideMenu extends Component {
    state = {
        menuItems: [],
    };

    componentDidMount() {
        if (this.props.hubMenuLinks) {
            this.menuItemsHandler(this.props.hubMenuLinks, this.props.menuActiveLinkID);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.hubMenuLinks !== this.props.hubMenuLinks ||
            prevProps.menuActiveLinkID !== this.props.menuActiveLinkID) {
            if (this.props.hubMenuLinks) {
                this.menuItemsHandler(this.props.hubMenuLinks, this.props.menuActiveLinkID);
            }
        }
    }

    menuItemsHandler = (menuItems, menuActiveLinkID) => {
        const newMenuItems = menuItems.map((item) => {
            if (item.id === menuActiveLinkID) {
                return {
                    ...item,
                    isActive: true,
                };
            }

            return {
                ...item,
                isActive: false,
            };
        });

        this.setState({menuItems: newMenuItems});
    };

    onLogout = () => {
        this.props.fetchLogout();
        this.props.history.push(staticURLs.home);
    };

    render() {
        const {menuItems} = this.state;
        const {staticData: {hub_menu}, profile} = this.props;
        const {user_name, profile_img_alt} = hub_menu;

        let name = user_name, email = null;

        if (profile) {
            name = profile.name;
            email = profile.email;
        }

        const itemsList = menuItems.map((item, idx) => {
            const cx = classnames.bind(styles);
            const itemClasses = cx('item', {
                'active': item.isActive,
                'with-icon': idx === menuItems.length - 1 ? 'logout' : false,
            });

            return (
                <MenuItem
                    key={idx}
                    {...item}
                    classNames={itemClasses}
                    withIcon={idx === menuItems.length - 1 ? 'logout' : false}
                    onClick={() => idx === menuItems.length - 1 && this.onLogout()}
                />
            );
        });

        return (
            <div className={styles.container}>
                <div className={styles['user-block']}>
                    <div className={styles.name}>
                        <img src={profileIcon} alt={profile_img_alt} className={styles.icon}/>
                        {name}
                    </div>
                    {email && <div className={styles.email}>{email}</div>}
                </div>

                <div className={styles['menu-items']}>{itemsList}</div>
            </div>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        profile: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
        }),
        fetchLogout: PropTypes.func,
        history: PropTypes.shape({
            push: PropTypes.func,
        }),
        hubMenuLinks: PropTypes.arrayOf(PropTypes.object),
    };
}

const mapServicesToProps = ({userService}) => ({
    fetchLogout: logoutRequest(userService.logout),
});

const mapStateToProps = ({profile: {profile}, activityHub: {utils}}) => ({
    profile,
    menuActiveLinkID: utils.menuActiveLinkID,
});

const mapDispatchToProps = (dispatch, {fetchLogout}) => {
    return bindActionCreators({
        fetchLogout,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService(),
    withHubMenuLinks(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(HubSideMenu);