import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';

import {withServices} from '../../../../service-context';
import {detailsRequest} from '../../../../../reducers/marketplace/product-page/product-details';
import {addToQuotationRequest} from '../../../../../reducers/marketplace/product-page/add-to-quotation';
import {productPageAction} from '../../../../../actions/marketplace';

import ProductPageContainer from './product-page-container';

class ProductPageModule extends Component {
    render() {
        return <ProductPageContainer {...this.props} />;
    }
}

const mapServicesToProps = ({marketplaceService, quotationService}) => ({
    fetchProductDetails: detailsRequest(marketplaceService.getProductDetails),
    fetchAddToQuotation: addToQuotationRequest(quotationService.addToQuotation),
});

const mapStateToProps = ({profile: {language, userInteraction, profile}, marketplace}) => {
    const {productDetails: {details, loading, error, inCart}, addToQuotation} = marketplace;

    return {
        details,
        loading,
        error,
        inCart,
        addToQuotation,
        language,
        userInteraction,
        profile,
    };
};

const mapDispatchToProps = (dispatch, {fetchProductDetails, fetchAddToQuotation}) => {
    const {setProductCurrentCurrency, resetAddToQuotation} = productPageAction;

    return bindActionCreators({
        fetchProductDetails,
        fetchAddToQuotation,
        setProductCurrentCurrency,
        resetAddToQuotation,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ProductPageModule);