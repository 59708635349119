import {createRequestHandler, createAlertResponse} from '../../../utils';

export const REMOVE_RFQ_ITEM_REQUEST = 'REMOVE_RFQ_ITEM_REQUEST';
export const REMOVE_RFQ_ITEM_SUCCESS = 'REMOVE_RFQ_ITEM_SUCCESS';
export const REMOVE_RFQ_ITEM_FAILURE = 'REMOVE_RFQ_ITEM_FAILURE';

export const removeRFQItemRequest = createRequestHandler('REMOVE_RFQ_ITEM');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const removeRFQItem = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case REMOVE_RFQ_ITEM_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case REMOVE_RFQ_ITEM_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case REMOVE_RFQ_ITEM_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_REMOVE_RFQ_ITEM':
            return initialState;

        default:
            return state;
    }
};

export default removeRFQItem;