import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import session from './session';
import registration from './registration';
import login, {LOGOUT_REQUEST} from './login';
import profile from './profile';
import search from './search';
import partNumber from './part-number';
import batchUploadForm from './batch-upload-form';
import fileUpload from './file-upload';
import utils from './utils';
import document from './document';

import {marketplaceReducer} from './marketplace';
import {activityHubReducer} from './activity-hub';
import {publicReducer} from './public-data';

export const RESET_REQUEST_LOCK = 'RESET_REQUEST_LOCK';

const stateHandler = (state, action) => {
    const {type} = action;

    const stateOnRequests = (method = 'inc') => {
        const {apiRequestCounter, breadcrumbs} = state.utils;

        const k = method === 'inc' ? 1 : -1;
        const apiCounter = apiRequestCounter + k;
        let updatedBreadcrumbs = breadcrumbs;

        if (apiCounter < 0) {
            return state;
        }

        if (apiCounter === 0 && breadcrumbs.list.length !== 0) {
            updatedBreadcrumbs = {
                ...breadcrumbs,
                loading: false,
            };
        }

        const updatedState = {
            ...state,
            utils: {
                ...state.utils,
                apiRequestCounter: apiCounter,
                breadcrumbs: updatedBreadcrumbs,
            },
        };

        return updatedState;
    };

    const typesForResetReduxState = ['SESSION_EXPIRED', LOGOUT_REQUEST];
    if (typesForResetReduxState.includes(type)) {
        const {session, publicData} = state;
        return {
            session,
            publicData,
        };
    }

    const arr = type.split('_');
    const actionType = arr[arr.length - 1];

    if (actionType === 'REQUEST') {
        return stateOnRequests();
    }

    if (actionType === 'SUCCESS' || actionType === 'FAILURE') {
        return stateOnRequests('dec');
    }

    return state;
};

const rootReducer = (history) => (state, action) => {
    state = stateHandler(state, action);

    return combineReducers({
        router: connectRouter(history),
        activityHub: activityHubReducer,
        marketplace: marketplaceReducer,
        publicData: publicReducer,
        session,
        registration,
        login,
        profile,
        search,
        partNumber,
        batchUploadForm,
        fileUpload,
        utils,
        document,
    })(state, action);
};

export default rootReducer;