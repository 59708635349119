export default class MarketplaceService {
    constructor(httpService) {
        this._http = httpService;
    }

    getAllProducts = (query) => {
        return this._http.get('/products/list', query);
    };

    getCategories = () => {
        return this._http.get('/categories/list');
    };

    getSectionProducts = () => {
        return this._http.get(`/products/transport-aviation/list`);
    };

    getCategoryProducts = (slug, query) => {
        return this._http.get(`/products/${slug}/list`, query);
    };

    getProductDetails = (slug, id) => {
        return this._http.get(`/product/${slug}/${id}/detail`);
    };
}