import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../common/hoc';

import Condition from './components/condition';
import Specification from './components/specification';
import Button from '../../../../../../common/button';
import ButtonConstructor from '../../../../../../common/button-constructor';

import classnames from 'classnames/bind';
import styles from './contract-conditions.module.scss';

const ContractConditions = (props) => {
    const {
        staticData, data, review, editableElementID, signLoading,
        onChangeCheckbox, onReview, onOpenDispute, onSign,
    } = props;
    const {title, subtitle, condition, description, offer: {name, ...offerRest}, buttons} = data;
    const {
        condition_table_labels,
        button: {cancel, review: review_mode, open_dispute},
    } = staticData.activity_hub.e_contracts.pages.contract_for_supply.condition_page;

    const cx = classnames.bind(styles);

    const commonParams = {
        containerClassNames: styles['accordion-container'],
        contentClassNames: styles['accordion-content'],
        labelClassNames: styles['accordion-label'],
        defaultOpen: true,
    };

    /* Hidden review button for last version of Exportery App 09.12.2022 */
    const hiddenReview = true;

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
            <div className={styles.description}>{parse(description)}</div>

            {/* general & special contract conditions */}
            {condition.map((item, idx) => {
                let {name, list} = item;

                if (list.length === 0) {
                    return null;
                }

                let id = 'contract_condition';
                if (list.find(({is_editable}) => is_editable)) {
                    id = `${editableElementID}_${idx}`;
                }

                list = [
                    {name: condition_table_labels.name, value: condition_table_labels.value},
                    ...list,
                ];

                const params = {label: name, id, list, review};
                return <Condition key={idx} {...commonParams} {...params}
                                  onChangeCheckbox={(e) => onChangeCheckbox(e, idx)}/>;
            })}

            {/* specification contract conditions */}
            <Specification {...offerRest} {...commonParams} label={name}/>

            <div className={styles['btn-block']}>
                {review.turnOn && !hiddenReview && (
                    <>
                        <Button styleType={'SECONDARY'}
                                classNames={`${styles.btn} ${styles.cancel}`}
                                onClick={() => onReview()}>
                            {cancel}
                        </Button>

                        <Button styleType={'SECONDARY'}
                                classNames={styles.btn}
                                isDisabled={!review.clickable}
                                onClick={() => onOpenDispute()}>
                            {open_dispute}
                        </Button>
                    </>

                )}
                {!review.turnOn && !hiddenReview && (
                    <Button styleType={'SECONDARY'}
                            classNames={styles.btn}
                            onClick={() => onReview(true)}>
                        {review_mode}
                    </Button>
                )}

                {buttons.length > 0 && buttons.map((button, idx) =>
                    <ButtonConstructor key={idx} {...button}
                                       dataLoading={signLoading}
                                       onClick={onSign}
                                       classNames={cx('btn', {'with-min-size': hiddenReview})}/>,
                )}
            </div>
        </div>
    );
};

ContractConditions.defaultProps = {
    onChangeCheckbox: () => {
    },
    onReview: () => {
    },
    onOpenDispute: () => {
    },
    onSign: () => {
    },
};

ContractConditions.propTypes = {
    staticData: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.object,
        PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            description: PropTypes.string,
            condition: PropTypes.object,
            offer: PropTypes.object,
            buttons: PropTypes.array,
        }),
    ]),
    review: PropTypes.shape({
        turnOn: PropTypes.bool,
        clickable: PropTypes.number,
    }),
    editableElementID: PropTypes.string,
    signLoading: PropTypes.bool,
    onChangeCheckbox: PropTypes.func,
    onReview: PropTypes.func,
    onOpenDispute: PropTypes.func,
    onSign: PropTypes.func,
};

export default withDataService()(ContractConditions);