import {en, ru, fr, es} from './data';

export default class DataService {
    constructor() {
        this.state = {en, ru, fr, es};
    }

    getStaticData = (lang, session = true) => {
        return this.state[lang](session);
    };
}