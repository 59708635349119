import React from 'react';
import PropTypes from 'prop-types';

const GridView = ({isActive, onClick}) => {
    const fillColor = isActive ? '#5e4834b2' : '#d6cab4';

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             onClick={onClick}>
            <path d="M0 0H6.49219V6.49219H0V0Z" fill={fillColor}/>
            <path d="M17.5078 0H24V6.49219H17.5078V0Z" fill={fillColor}/>
            <path d="M8.75391 0H15.2461V6.49219H8.75391V0Z" fill={fillColor}/>
            <path d="M0 8.75391H6.49219V15.2461H0V8.75391Z" fill={fillColor}/>
            <path d="M17.5078 8.75391H24V15.2461H17.5078V8.75391Z" fill={fillColor}/>
            <path d="M8.75391 8.75391H15.2461V15.2461H8.75391V8.75391Z" fill={fillColor}/>
            <path d="M0 17.5078H6.49219V24H0V17.5078Z" fill={fillColor}/>
            <path d="M17.5078 17.5078H24V24H17.5078V17.5078Z" fill={fillColor}/>
            <path d="M8.75391 17.5078H15.2461V24H8.75391V17.5078Z" fill={fillColor}/>
        </svg>
    );
};

GridView.defaultProps = {
    isActive: false,
    onClick: () => {
    },
};

GridView.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

export default GridView;