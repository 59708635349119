// CKEditor Plugins
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import AlignmentEditing from '@ckeditor/ckeditor5-alignment/src/alignmentediting';
import AlignmentUI from '@ckeditor/ckeditor5-alignment/src/alignmentui';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import Image from '@ckeditor/ckeditor5-image/src/image';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
// import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
// import ClipboardPipeline from '@ckeditor/ckeditor5-clipboard/src/clipboardpipeline';
// import DragDrop from '@ckeditor/ckeditor5-clipboard/src/dragdrop';
// import PastePlainText from '@ckeditor/ckeditor5-clipboard/src/pasteplaintext';

/**
 * Additional plugins, what can be install for classic editor (from basic list): CloudServices, EasyImage, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, MediaEmbed
 * We can set also another plugins from ckeditor https://ckeditor.com/docs/ckeditor5/latest/api/index.html
 * */

import styles from './text-editor.module.scss';

const editorConfiguration = {
    plugins: [
        Essentials,
        CKFinderUploadAdapter,
        Autoformat,
        Bold,
        Italic,
        Font,
        Alignment,
        AlignmentEditing,
        AlignmentUI,
        BlockQuote,
        CKFinder,
        Image,
        Heading,
        Indent,
        Link,
        List,
        ListStyle,
        Paragraph,
        Table,
        TableToolbar,
        TextTransformation,
        Strikethrough,
        Underline,
        Subscript,
        Superscript,
        PasteFromOffice,
        // Clipboard,
        // ClipboardPipeline,
        // DragDrop,
        // PastePlainText,
    ],
    heading: {
        options: [
            {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
            {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: `${styles['ck-heading_heading1']} ck-heading_heading1`,
            },
            {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: `${styles['ck-heading_heading2']} ck-heading_heading2`,
            },
            {
                model: 'heading3',
                view: 'h3',
                title: 'Heading 3',
                class: `${styles['ck-heading_heading3']}  ck-heading_heading3`,
            },
            {
                model: 'heading4',
                view: 'h4',
                title: 'Heading 4',
                class: `${styles['ck-heading_heading4']} ck-heading_heading4`,
            },
            {
                model: 'heading5',
                view: 'h5',
                title: 'Heading 5',
                class: `${styles['ck-heading_heading5']}  ck-heading_heading5`,
            },
        ],
    },
    fontFamily: {
        options: [
            'default',
            'Noto Sans',
            'Russo One',
            'Arial, sans-serif',
            'Courier New',
            'Georgia',
            'Lucida Sans Unicode',
            'Tahoma',
            'Times New Roman',
            'Verdana',
        ],
    },
    fontSize: {
        options: [
            8,
            10,
            12,
            14,
            'default',
            18,
            20,
            22,
            24,
        ],
    },
    toolbar: [
        'undo', 'redo', '|',
        'heading', '|',
        'bold', 'italic', 'fontSize', 'underline', 'blockQuote', '|',
        'alignment', '|',
        'bulletedList', 'numberedList', 'outdent', 'indent', '|',
        'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
        'fontFamily', 'fontColor', 'fontBackgroundColor', 'subscript', 'superscript', '|',
        'strikethrough', 'link', 'selectAll',
        // need additional settings for 'Copy', 'Cut', 'Paste',
    ],

    // also can be set
    // shouldNotGroupWhenFull: true,
    // viewportOffset: {},
};

export {editorConfiguration};