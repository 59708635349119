export const resetSuggestedPnum = () => {
    return {type: 'RESET_SUGGESTED_PART_NUMBER'};
};

export const resetSuggestedDataByPnum = () => {
    return {type: 'RESET_SUGGESTED_DATA_BY_PART_NUMBER'};
};

export const resetSuggestedPlatforms = () => {
    return {type: 'RESET_SUGGESTED_PLATFORMS'};
};

export const resetSuggestedTitles = () => {
    return {type: 'RESET_SUGGESTED_TITLES'};
};