import {combineReducers} from 'redux';

import {profileReducer} from './profile';
import {placeProductReducer} from './place-product';
import {requestsForQuotationsReducer} from './requests-for-quotations';
import {eContractsReducer} from './e-contracts';
import {contractMapsReducer} from './contract-maps';

import notifications from './notifications';
import utils from './utils';
import myProducts from './my-products';
import removeProductOffer from './remove-product-offer';
import requestOnButtonUrl from './request-on-button-url';
import removeAttachment from './remove-attachment';

export const activityHubReducer = combineReducers({
    profile: profileReducer,
    placeProduct: placeProductReducer,
    requestsForQuotations: requestsForQuotationsReducer,
    eContracts: eContractsReducer,
    contractMaps: contractMapsReducer,
    notifications,
    myProducts,
    removeProductOffer,
    utils,
    requestOnButtonUrl,
    removeAttachment,
});