export const setProductCurrentCurrency = (newCurrency) => {
    return {
        type: 'SET_PRODUCT_CURRENT_CURRENCY',
        payload: newCurrency,
    };
};

export const switchProductDocumentsVisibility = (newState) => {
    return {
        type: 'PRODUCT_DOCUMENTS_SHOWN',
        payload: newState,
    };
};

export const resetAddToQuotation = () => {
    return {type: 'RESET_ADD_TO_QUOTATION'};
};