export const about_core_services_en = {
    title: 'About core services',
    subtitle: `At Exportery, we offer our customers unrivaled game-changing features and 
    services in the industrial market trade realm`,
    platform: {
        title: 'Exportery ™ e-trade platform features',
        headings: ['For exporter', 'For importer'],
        list: [
            {
                text: `FREE e-trade platform membership, FREE listing of the spare parts and components 
                and unlimited RFQ’s originations`,
                interaction: 'both',
            },
            {
                text: `Multilanguage interface and Customer Service support EN-FR-ES-RU`,
                interaction: 'both',
            },
            {
                text: `Advanced registration and verification process eliminating the risk of 
                entering ghost members from both Importers or Exporter roles`,
                interaction: 'both',
            },
            {
                text: `Default pricing as per FCA Origin country airport Incoterms 2020 
                trade conditions for all products on the marketplace`,
                interaction: 'both',
            },
            {
                text: `Automatic instant pricing based on CIP Destination country airport 
                Incoterms 2020 for all Importers upon creating an electronic Request for Quotation (RFQ)`,
                interaction: 'importer',
            },
            {
                text: `Confidential product listings for Exporters upon request (hiding the name, 
                manufacture location, etc.) for the sake of fair competition and security`,
                interaction: 'exporter',
            },
            {
                text: `RFQ’s history logging with an assigned validity of each Quote`,
                interaction: 'both',
            },
            {
                text: `Part number search, advanced filtering system, platform identification, and 
                title grouping per original technical catalogs for easy search`,
                interaction: 'both',
            },
            {
                text: `Detailed Product Card encompassing main technical data, assigned resources, 
                manufacturer data, overhaul plants data, TBO, guarantee terms, and certification parameters`,
                interaction: 'both',
            },
            {
                text: `Spare parts and components Interchangeability search function and embedded analog 
                replacement offering to those available on the market according to the technical bulletins 
                and documentation from a Designer of a particular platform`,
                interaction: 'both',
            },
            {
                text: `Convenient bulk upload (Automatic conversion) from Excel file lists to get instant 
                RFQ pricing (CIP) for multiple spare parts and components within a minute`,
                interaction: 'importer',
            },
            {
                text: `Manual products placing on e-platform`,
                interaction: 'exporter',
            },
            {
                text: `Convenient bulk listings upload (Automatic conversion) from Excel file lists to 
                place mass offers on the e-trade platform`,
                interaction: 'exporter',
            },
            {
                text: `Manual moderation of all data on the e-trade platform by Exportery’s high-caliber 
                technical specialists with a proven track record in Transport Aviation, Helicopters, 
                Heavy duty trucks, etc., to ensure all the data are free from ghost entries, incorrect 
                rubric listings, and incorrect RFQs`,
                interaction: 'both',
            },
            {
                text: `Pricing and e-Contract originations and financial settlements in various 
                currencies (USD, EUR, RUB, AED, CNY, CHF, RWF)`,
                interaction: 'both',
            },
        ],
    },
    document_management_system: {
        title: ['Proprietary trade services and ', 'e-document management system features'],
        headings: ['For exporter', 'For importer'],
        list: [
            {
                text: `Holonic service, all risk-taking including currency fluctuation risks, and ultimate 
                responsibility for the consigned goods from FCA Origin collection to CIP destination final delivery`,
                interaction: 'both',
            },
            {
                text: `Contract Map ™ intuitive contract management engine system`,
                interaction: 'both',
            },
            {
                text: `Origination, submission and e-signing of End-User Certificate from Air Operator, 
                Fleet operators or Importing Government entities`,
                interaction: 'importer',
            },
            {
                text: `SWIFT Performance Guarantee for Government end-users for e-Contracts exceeding 250 000 USD`,
                interaction: 'importer',
            },
            {
                text: `SWIFT Exporter Trade Guarantee for shipments exceeding 100 000 USD upon request`,
                interaction: 'exporter',
            },
            {
                text: `Escrow account for Importer with only 25% of the total e-Contract value guarantee 
                deposit in local currency`,
                interaction: 'importer',
            },
            {
                text: `Establishment of e-Contract containing General Contract Conditions, Special Contract 
                Conditions, and a Specification`,
                interaction: 'both',
            },
            {
                text: `Escrow warehousing at our first transit service point at Dar Es Salaam, 
                Tanzania for the commercial safety of Importers and Exporters`,
                interaction: 'both',
            },
            {
                text: `Independent Technical Inspection and photo logging for all shipments and establishing 
                Inspection Report for the quantity, quality, and authentication parameters according to 
                numerous standards, e.g., ISO, GOST, MAK, EASA, and others, ensuring the operational 
                safety both for Importer and Exporter`,
                interaction: 'both',
            },
            {
                text: `Automatic establishment of e-Invoice and intuitive e-Packing list with embedded mechanism 
                to operate with multiple, single, sets, break bulk spare parts and components as per different 
                scenarios, minimizing the human error factor`,
                interaction: 'exporter',
            },
            {
                text: `Shipment lots splitting feature`,
                interaction: 'exporter',
            },
            {
                text: `Automatic DocuSign e-signature for all documents`,
                interaction: 'both',
            },
            {
                text: `E-AWB for all shipments`,
                interaction: 'both',
            },
            {
                text: `Automatic labeling for Export consignments`,
                interaction: 'exporter',
            },
            {
                text: `Establishment of Trilateral E-Give and Take Act between Exportery platform (Agent), 
                Exporter, and Importer upon completion of Inspection Report for release of the goods and 
                final settlements`,
                interaction: 'both',
            },
            {
                text: `Advanced system of contract stages accomplishment and shipment tracking`,
                interaction: 'both',
            },
            {
                text: `Advanced event notification systems`,
                interaction: 'both',
            },
            {
                text: `Dedicated Content Manager for Exporter`,
                interaction: 'exporter',
            },
            {
                text: `Dedicated Contracts Execution Manager for Exporter and Importer`,
                interaction: 'both',
            },
            {
                text: `Warranty claims and returns service`,
                interaction: 'importer',
            },
        ],
    },
};