import {LOCATION_CHANGE} from 'connected-react-router';
import {cookieStringToObject} from '../utils';
import {
    PROFILE_REQUEST,
    PROFILE_FAILURE,
    PROFILE_UPDATING_REQUEST,
    PROFILE_UPDATING_FAILURE,
    SWITCH_LANGUAGE,
} from './profile';
import {RESET_REQUEST_LOCK} from './index';
import {OPEN_SIGN_IN, CLOSE_SIGN_IN, LOGIN_REQUEST} from './login';
import {OPEN_SIGN_UP, CLOSE_SIGN_UP, REGISTRATION_REQUEST} from './registration';
import {breadcrumbsStaticURLs, errorURLs, hubBreadcrumbsStaticURLsWithIdParams} from '../services/api-urls';

const initialState = {
    apiTitle: 'Exportery',
    apiRequestCounter: 0,
    cookieConsent: {
        key: 'c_consent',
        isActive: false,
    },
    view: 'GRID',
    errorBoundry: false,
    serverError: null,
    notFoundError: null,
    notAcceptableError: null,

    // need work
    baseAPIUrl: null,
    departmentID: null,
    prevLocation: null,
    currentLocation: null,
    breadcrumbs: {
        loading: false,
        list: [],
        canBeLoading: true,
    },
};

/* Is pathname page with static breadcrumbs? */
const checkingBreadcrumbsStaticURLs = (pathname) => {
    /* Is pathname from profile page with id param? */
    const transformPathname = pathname.split('/').slice(0, -1).join('/');
    if (hubBreadcrumbsStaticURLsWithIdParams.includes(transformPathname)) {
        return true;
    }

    return breadcrumbsStaticURLs.includes(pathname);
};

const updateUtilsOnLocationChange = (state, action) => {
    const {
        prevLocation, currentLocation, breadcrumbs,
        serverError, notFoundError, notAcceptableError,
    } = state;
    const {action: actionType, location: {pathname}} = action.payload;

    let loading = false;
    let list = breadcrumbs.list;
    if (prevLocation !== currentLocation) {
        if (breadcrumbs.canBeLoading) {
            loading = !checkingBreadcrumbsStaticURLs(pathname);
            list = [];
        }
    }

    let stateObject = {
        ...state,
        breadcrumbs: {
            list,
            loading,
            canBeLoading: true,
        },
    };

    if (pathname !== currentLocation) {
        const updatePrevLocation = actionType !== 'REPLACE' ? currentLocation : null;

        if (currentLocation === errorURLs.not_found && notFoundError) {
            stateObject = {
                ...stateObject,
                notFoundError: null,
            };
        }

        if (currentLocation === errorURLs.not_acceptable && notAcceptableError) {
            stateObject = {
                ...stateObject,
                notAcceptableError: null,
            };
        }

        if (currentLocation === errorURLs.internal_server_error && serverError) {
            stateObject = {
                ...stateObject,
                serverError: null,
            };
        }

        stateObject = {
            ...stateObject,
            prevLocation: updatePrevLocation,
            currentLocation: pathname,
        };
    } else {
        stateObject = {
            ...stateObject,
            breadcrumbs: {
                ...stateObject.breadcrumbs,
                canBeLoading: false,
            },
        };
    }

    return stateObject;
};

// need work
const utils = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        const cookies = cookieStringToObject();
        if (!cookies[initialState.cookieConsent.key]) {
            return {
                ...initialState,
                cookieConsent: {
                    ...initialState.cookieConsent,
                    isActive: true,
                },
            };
        }

        return initialState;
    }

    switch (type) {
        case PROFILE_REQUEST:
        case PROFILE_FAILURE:
        case PROFILE_UPDATING_REQUEST:
        case PROFILE_UPDATING_FAILURE:
            return state;

        case LOCATION_CHANGE:
            return updateUtilsOnLocationChange(state, action);

        case 'SET_COOKIE_CONSENT':
            return {
                ...state,
                cookieConsent: {
                    ...state.cookieConsent,
                    isActive: payload,
                },
            };

        case 'SET_BASE_API_URL':
            return {
                ...state,
                baseAPIUrl: payload,
            };

        case 'SWITCH_DEPARTMENT_ID':
            return {
                ...state,
                departmentID: payload,
            };

        case 'SWITCH_VIEW':
            return {
                ...state,
                view: payload,
            };

        case 'SET_SERVER_ERROR':
            return {
                ...state,
                serverError: payload,
            };

        case 'SET_NOT_FOUND_ERROR':
            return {
                ...state,
                notFoundError: payload,
            };

        case 'SET_NOT_ACCEPTABLE_ERROR':
            return {
                ...state,
                notAcceptableError: payload,
            };

        case OPEN_SIGN_IN:
        case CLOSE_SIGN_IN:
        case OPEN_SIGN_UP:
        case CLOSE_SIGN_UP:
        case LOGIN_REQUEST:
        case REGISTRATION_REQUEST:
        case RESET_REQUEST_LOCK:
            return {
                ...state,
                serverError: null,
            };

        case 'RESET_ERROR_INDICATOR_SWITCHER':
            return {
                ...state,
                serverError: null,
                notFoundError: null,
                notAcceptableError: null,
            };

        case 'SET_ERROR_BOUNDRY':
            return {
                ...state,
                errorBoundry: true,
            };

        case 'UPDATE_BREADCRUMBS':
            if (payload.list) {
                return {
                    ...state,
                    breadcrumbs: payload,
                };
            }

            return {
                ...state,
                breadcrumbs: {
                    ...state.breadcrumbs,
                    list: payload,
                },
            };

        case SWITCH_LANGUAGE:
            if (!checkingBreadcrumbsStaticURLs(state.currentLocation)) {
                return {
                    ...state,
                    breadcrumbs: {
                        ...state.breadcrumbs,
                        loading: true,
                    },
                };
            }
            return state;

        default:
            return state;
    }
};

export default utils;