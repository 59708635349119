import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';
import {withGridArea} from '../hoc';

import Image from '../../../../common/image';
import Button from '../../../../common/button';

import classnames from 'classnames/bind';
import styles from './product-card.module.scss';

import defaultProductImage from '../../../../common/assets/other-icons/product_image_default.svg';

const ProductCard = (props) => {
    const cx = classnames.bind(styles);

    const {
        id, owner, preview_image, condition_value,
        part_number, name, offer, available,
        classNames, style, href, staticData,
        withoutSelfMode, session,
    } = props;

    const {formatted_supply_condition, organization_name, price, currency} = offer;
    const {button: {view_product}} = staticData.marketplace.product_card;

    const availabilityStatusClasses = available && cx({
        'in-stock': available.value === 'in_stock',
        'out-of-stock': available.value === 'out_stock',
    });
    const containerClasses = cx('container', classNames, {'self': owner && owner === 'self' && !withoutSelfMode});

    const desktopDevice = useMediaQuery({minWidth: 1200});

    const linkBtn = (
        <Link to={href}>
            <Button styleType={'SECONDARY'} classNames={styles.btn}>
                {view_product}
            </Button>
        </Link>
    );

    return (
        <div id={id} className={containerClasses} style={style}>
            <Link to={href}>
                <Image source={preview_image} alt={name}/>
            </Link>
            <div className={styles.condition}>{condition_value}</div>
            <div className={styles.content}>
                <Link to={href} className={styles['part-number']}>{part_number}</Link>
                <div className={styles.name}>{name}</div>
                {organization_name && <div className={styles['organization-name']}>{organization_name}</div>}
                {formatted_supply_condition &&
                <div className={styles['supply-condition']}>{formatted_supply_condition}</div>}
                {session && (
                    <div className={styles['price-block']}>
                        <div className={styles.price}>{price} {currency}</div>
                        {available && <div className={availabilityStatusClasses}>{available.label}</div>}
                    </div>
                )}
                {!desktopDevice && linkBtn}
            </div>
            <div className={styles['btn-block']}>
                <div className={styles['btn-wrapper']}>
                    {linkBtn}
                </div>
            </div>
        </div>
    );
};

ProductCard.defaultProps = {
    withoutSelfMode: false,
    preview_image: defaultProductImage,
    style: {},
    href: '#',
};

ProductCard.propTypes = {
    id: PropTypes.any.isRequired,
    owner: PropTypes.string,
    preview_image: PropTypes.shape({
        xs: PropTypes.shape({
            jpg: PropTypes.string,
            webp: PropTypes.string,
        }),
    }),
    condition_value: PropTypes.string,
    part_number: PropTypes.string,
    name: PropTypes.string,
    offer: PropTypes.shape({
        organization_name: PropTypes.string,
        formatted_supply_condition: PropTypes.string,
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        currency: PropTypes.string,
    }),
    available: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }),
    classNames: PropTypes.string,
    style: PropTypes.object,
    totalItems: PropTypes.number,
    href: PropTypes.string,
    staticData: PropTypes.object,
    withoutSelfMode: PropTypes.bool,
    session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default compose(
    withDataService(),
    withGridArea(),
)(ProductCard);