import {createRequestHandler} from '../../../../utils';

export const ORIGIN_INVOICE_REQUEST = 'ORIGIN_INVOICE_REQUEST';
export const ORIGIN_INVOICE_SUCCESS = 'ORIGIN_INVOICE_SUCCESS';
export const ORIGIN_INVOICE_FAILURE = 'ORIGIN_INVOICE_FAILURE';

export const ORIGIN_INVOICE_UPDATING_REQUEST = 'ORIGIN_INVOICE_UPDATING_REQUEST';
export const ORIGIN_INVOICE_UPDATING_SUCCESS = 'ORIGIN_INVOICE_UPDATING_SUCCESS';
export const ORIGIN_INVOICE_UPDATING_FAILURE = 'ORIGIN_INVOICE_UPDATING_FAILURE';

export const originInvoiceRequest = createRequestHandler('ORIGIN_INVOICE');
export const updatingOriginInvoiceRequest = createRequestHandler('ORIGIN_INVOICE_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    initialData: null,
    data: null,
    error: null,
    selectAllInvoiceItems: 1,
    isInvoiceCanBeSaved: false,
};

const onInitData = ({list, ...rest}) => {
    return {
        ...rest,
        list: list.map((item) => ({
            ...item,
            name: `offer[${item.id}]`,
            value: 1,
        })),
    };
};

const onUpdateData = (payload, data) => {
    const {list, ...rest} = data;
    const {list: newList} = payload;

    return {
        ...rest,
        list: list.map((item, idx) => ({
            ...item,
            ...newList[idx],
        })),
    };
};

const onCheckIsCanBeSaved = (list) => list.length > 0;

const originInvoice = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case ORIGIN_INVOICE_REQUEST:
            return initialState;

        case ORIGIN_INVOICE_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case ORIGIN_INVOICE_SUCCESS:
            const initialData = onInitData(payload);
            return {
                ...initialState,
                initialData,
                data: initialData,
                loading: false,
                isInvoiceCanBeSaved: onCheckIsCanBeSaved(initialData.list),
            };

        case ORIGIN_INVOICE_UPDATING_SUCCESS:
            return {
                ...state,
                initialData: onInitData(payload),
                data: onUpdateData(payload, state.data),
                updating: false,
                isInvoiceCanBeSaved: onCheckIsCanBeSaved(
                    onInitData(payload).list,
                ),
            };

        case ORIGIN_INVOICE_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case ORIGIN_INVOICE_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'UPDATE_ORIGIN_INVOICE_DATA':
            const {data, selectAllInvoiceItems, isInvoiceCanBeSaved} = payload;
            return {
                ...state,
                data,
                selectAllInvoiceItems,
                isInvoiceCanBeSaved,
            };

        case 'RESET_ORIGIN_INVOICE_DATA_TO_INITIAL_STATE':
            return initialState;

        default:
            return state;
    }
};

export default originInvoice;