import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withServices} from '../../../../service-context';
import {withDataService} from '../../../../common/hoc';
import {
    checkingEqualityOfFiles,
    dropzonePropsTransformer,
} from '../handlers';
import uploadFileHandler from '../../../../../utils/upload-file-handler';
import sweetAlert from '../../../../common/sweet-alert';
import {removeAttachmentRequest} from '../../../../../reducers/activity-hub/remove-attachment';
import {resetRemoveAttachmentFileState} from '../../../../../actions/activity-hub';

import UploadDocuments from './upload-documents';
import {onGetAlertResponse} from '../../../../../utils';

class UploadDocumentsContainer extends Component {
    state = {
        dropzoneName: null,
        documents: null,
        isCanBeUpdated: true,
        deletedFile: null,
        isChangeAlreadyUploadedFiles: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fields !== this.props.fields) {
            if (this.state.isCanBeUpdated) {
                const {props, value, documents, rejectedDocuments, ...rest} = this.props.fields[0];
                const dropzoneName = this.props.fields[0].name;
                const newProps = props.map(props => dropzonePropsTransformer(props));

                let newDefaultProps = {};
                for (let item of newProps) {
                    newDefaultProps = {
                        ...newDefaultProps,
                        ...item,
                    };
                }

                const partUploadedFiles = documents ? documents : [];
                const rejectedFiles = rejectedDocuments ? rejectedDocuments : [];
                const alreadyUploadedFiles = value && value !== '' ? value : [];
                const maxCount = newDefaultProps.maxCount - alreadyUploadedFiles.length - partUploadedFiles.length;

                const newDocuments = {
                    ...rest,
                    files: [],
                    partUploadedFiles,
                    rejectedFiles,
                    alreadyUploadedFiles,
                    defaultProps: newDefaultProps,
                    maxCount: maxCount,
                    maxSize: newDefaultProps.maxSize,
                };

                this.setState({documents: newDocuments, isCanBeUpdated: false, dropzoneName});
            }
        }

        if (prevState.documents !== this.state.documents) {
            /**
             - use this case when we added some files to the dropzone or then deleted them
             - do not use it on the first render
             */
            if (prevState.documents) {
                if (!this.state.isChangeAlreadyUploadedFiles) {
                    const {name, partUploadedFiles, files, rejectedFiles} = this.state.documents;
                    const newFiles = files
                        .map(({file, isLoaded}) => {
                            if (!isLoaded) {
                                return null;
                            }

                            return file;
                        })
                        .filter(file => file);
                    this.props.onDocumentsChange(
                        name, {
                            files: [...partUploadedFiles, ...newFiles],
                            rejectedFiles: rejectedFiles,
                        });

                } else {
                    const {name, alreadyUploadedFiles} = this.state.documents;
                    this.props.onDocumentsChange(name, {alreadyUploadedFiles});
                    this.setState({isChangeAlreadyUploadedFiles: false});
                }
            }
        }

        if (prevProps.removeAttachment.loading !== this.props.removeAttachment.loading) {
            if (prevProps.removeAttachment.loading) {
                if (this.state.deletedFile) {
                    const {deletedFile, dropzoneName, documents} = this.state;

                    if (deletedFile.dropzoneName === dropzoneName) {
                        const {status, text} = onGetAlertResponse(this.props.removeAttachment);

                        if (status !== '' && text !== '') {
                            if (status === 'success') {
                                const {alreadyUploadedFiles, ...rest} = documents;
                                const idx = alreadyUploadedFiles.findIndex(({id}) => id === deletedFile.id);
                                const updatedArray = [
                                    ...alreadyUploadedFiles.slice(0, idx),
                                    ...alreadyUploadedFiles.slice(idx + 1),
                                ];

                                const updatedDocuments = {
                                    alreadyUploadedFiles: updatedArray,
                                    ...rest,
                                };

                                this.setState({
                                    documents: updatedDocuments,
                                    deletedFile: null,
                                    isChangeAlreadyUploadedFiles: true,
                                });

                            } else {
                                this.setState({deletedFile: null});
                            }

                            sweetAlert(status, text).then(() => this.props.resetRemoveAttachmentFileState());
                        }
                    }
                }
            }
        }
    }

    onAcceptedFiles = (acceptedFiles) => {
        const {documents, documents: {files, partUploadedFiles, maxCount}} = this.state;
        const {same_name_message} = this.props.staticData.activity_hub.upload_documents;
        let newDocuments = documents;

        if (files.length > 0 || partUploadedFiles.length > 0) {
            const arrayOfFiles = files.map(({file}) => file);
            const {
                files: newAcceptedFiles, infoData,
            } = checkingEqualityOfFiles([...partUploadedFiles, ...arrayOfFiles], acceptedFiles);

            if (infoData && infoData.length !== 0) {
                const message = (
                    <div>
                        {same_name_message[0]}
                        <ul>{infoData.map((item, idx) => <li key={idx}>{`- ${item}`}</li>)}</ul>
                        {same_name_message[1]}
                    </div>
                );

                sweetAlert('info', message);
            }

            if (newAcceptedFiles.length > 0) {
                uploadFileHandler(newAcceptedFiles, this.onLoadingFile, this.onLoadedFile);
                const updatedMaxCount = maxCount - newAcceptedFiles.length;

                const newFiles = [
                    ...files,
                    ...newAcceptedFiles,
                ];

                newDocuments = {
                    ...documents,
                    files: newFiles,
                    maxCount: updatedMaxCount,
                };
            }

        } else {
            uploadFileHandler(acceptedFiles, this.onLoadingFile, this.onLoadedFile);

            newDocuments = {
                ...documents,
                files: acceptedFiles,
                maxCount: documents.maxCount - acceptedFiles.length,
            };
        }

        this.setState({documents: newDocuments});
    };

    onRejectedFiles = (files) => {
        const {documents} = this.state;
        const {quantity_message} = this.props.staticData.activity_hub.upload_documents;
        let newDocuments = {};
        let rightFiles = files.filter(({errors}) => errors[0].code === 'too-many-files');

        if (rightFiles.length !== 0) {
            const {
                defaultProps: {maxCount: defaultMaxCount},
                files,
                partUploadedFiles,
                alreadyUploadedFiles,
                maxCount,
            } = documents;
            let infoMessage = `${quantity_message[0]} ${defaultMaxCount} ${quantity_message[1]}`;

            const amountOfUploadedFiles = files.length + partUploadedFiles.length + alreadyUploadedFiles.length;
            if (amountOfUploadedFiles !== 0) {
                infoMessage = `${quantity_message[0]} ${defaultMaxCount} ${quantity_message[1]}
                ${quantity_message[2]} ${defaultMaxCount - maxCount} ${quantity_message[3]}`;
            }

            sweetAlert('info', infoMessage);
        } else {
            if (documents.rejectedFiles.length !== 0) {
                const newFiles = [
                    ...documents.rejectedFiles,
                    ...files,
                ];

                newDocuments = {
                    ...documents,
                    rejectedFiles: newFiles,
                };

            } else {
                newDocuments = {
                    ...documents,
                    rejectedFiles: files,
                };
            }

            this.setState({documents: newDocuments});
        }
    };

    onLoadingFile = (file, progress) => {
        const {documents} = this.state;
        const currentFileIdx = documents.files.findIndex((document) => document === file);
        const currentFile = documents.files[currentFileIdx];

        const newDocuments = {
            ...documents,
            files: [
                ...documents.files.slice(0, currentFileIdx),
                {
                    file: currentFile,
                    progress,
                    isLoaded: false,
                },
                ...documents.files.slice(currentFileIdx + 1),
            ],
        };

        this.setState({documents: newDocuments});
    };

    onLoadedFile = (file) => {
        const {documents} = this.state;
        const currentFileIdx = documents.files.findIndex((document) => document.file === file);
        const currentFile = documents.files[currentFileIdx];

        const newDocuments = {
            ...documents,
            files: [
                ...documents.files.slice(0, currentFileIdx),
                {
                    ...currentFile,
                    isLoaded: true,
                },
                ...documents.files.slice(currentFileIdx + 1),
            ],
        };

        this.setState({documents: newDocuments});
    };

    onDeleteFile = (currentIdx) => {
        const {documents} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newFiles = [
                        ...documents.files.slice(0, currentIdx),
                        ...documents.files.slice(currentIdx + 1),
                    ];

                    const newDocuments = {
                        ...documents,
                        files: newFiles,
                        maxCount: documents.maxCount + 1,
                    };

                    this.setState({documents: newDocuments});
                }
            });
    };

    onDeleteRejectedFile = (currentIdx) => {
        const {documents} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newFiles = [
                        ...documents.rejectedFiles.slice(0, currentIdx),
                        ...documents.rejectedFiles.slice(currentIdx + 1),
                    ];

                    const newDocuments = {
                        ...documents,
                        rejectedFiles: newFiles,
                    };

                    this.setState({documents: newDocuments});
                }
            });
    };

    onDeletePartUploadedFileFile = (currentIdx) => {
        const {documents, documents: {partUploadedFiles}} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newFiles = [
                        ...partUploadedFiles.slice(0, currentIdx),
                        ...partUploadedFiles.slice(currentIdx + 1),
                    ];

                    const newDocuments = {
                        ...documents,
                        partUploadedFiles: newFiles,
                        maxCount: documents.maxCount + 1,
                    };

                    this.setState({documents: newDocuments});
                }
            });
    };

    onDeleteAlreadyExistFile = (id) => {
        const {delete_item_question} = this.props.staticData.alert_modal;
        const {dropzoneName} = this.state;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.onRemoveFileFromServer(id);
                    this.setState({deletedFile: {dropzoneName, id}});
                }
            });
    };

    render() {
        const {deletedFile, ...rest} = this.state;
        const {removeAttachment: loading} = this.props;
        const deleteAlreadyUploadedFile = deletedFile ? {...deletedFile, loading} : null;

        return <UploadDocuments {...rest}
                                deleteAlreadyUploadedFile={deleteAlreadyUploadedFile}
                                onAcceptedFiles={this.onAcceptedFiles}
                                onRejectedFiles={this.onRejectedFiles}
                                onDeleteFile={this.onDeleteFile}
                                onDeleteRejectedFile={this.onDeleteRejectedFile}
                                onDeletePartUploadedFileFile={this.onDeletePartUploadedFileFile}
                                onDeleteAlreadyExistFile={this.onDeleteAlreadyExistFile}/>;
    }

    static defaultProps = {
        onDocumentsChange: () => {
        },
    };

    static propTypes = {
        files: PropTypes.arrayOf(PropTypes.object),
        removeAttachment: PropTypes.shape({
            loading: PropTypes.bool,
            message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
        onDocumentsChange: PropTypes.func,
        onRemoveFileFromServer: PropTypes.func,
        resetRemoveAttachmentFileState: PropTypes.func,
    };
}

const mapServicesToProps = ({activityHubService}) => ({
    onRemoveFileFromServer: removeAttachmentRequest(activityHubService.removeAttachmentFile),
});

const mapStateToProps = ({activityHub: {removeAttachment}}) => ({
    removeAttachment,
});

const mapDispatchToProps = (dispatch, {onRemoveFileFromServer}) => (
    bindActionCreators({
        onRemoveFileFromServer,
        resetRemoveAttachmentFileState,
    }, dispatch)
);

export default compose(
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(UploadDocumentsContainer);