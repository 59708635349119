import {createRequestHandler} from '../../../utils';
import FormFieldBuilder from '../../../utils/form-field-builder/form-field-builder';

const INVITATION_FORM_PUBLIC_REQUEST = 'INVITATION_FORM_PUBLIC_REQUEST';
const INVITATION_FORM_PUBLIC_SUCCESS = 'INVITATION_FORM_PUBLIC_SUCCESS';
const INVITATION_FORM_PUBLIC_FAILURE = 'INVITATION_FORM_PUBLIC_FAILURE';

export const invitationFormPublicRequest = createRequestHandler('INVITATION_FORM_PUBLIC', {language: true});

const formFieldBuilder = new FormFieldBuilder();

const initialState = {
    loading: true,
    data: null,
    list: null,
    error: null,
};

const onInitForm = (payload) => {
    const {form, language, ...rest} = payload;

    const updatedForm = form.map((item) => {
        const {type, list, value} = item;

        let transformValue = value;
        let transformList = list;

        if (type === 'select') {
            if (!value && list.length === 1) {
                transformValue = list[0].value;
            }

            transformList = formFieldBuilder.transformSelectFieldList(list, language);
        }

        return {
            ...item,
            list: transformList,
            value: transformValue,
        };
    });

    return {
        loading: false,
        data: rest,
        list: updatedForm,
        error: null,
    };
};

const form = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case INVITATION_FORM_PUBLIC_REQUEST:
            return initialState;

        case INVITATION_FORM_PUBLIC_SUCCESS:
            return onInitForm(payload);

        case INVITATION_FORM_PUBLIC_FAILURE:
            return {
                loading: false,
                data: null,
                list: null,
                error: payload,
            };

        default:
            return state;
    }
};

export default form;