import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';

import Tooltip from '../tooltip';

import classnames from 'classnames/bind';
import styles from './item-counter.module.scss';

class ItemCounter extends Component {
    state = {
        value: null,
        minValue: 1,
        maxValue: null,
        isError: false,
        valueErrorMessage: null,
    };

    valueTimerId = null;

    componentDidMount() {
        this.setState({
            value: this.props.value,
            maxValue: this.props.maxValue
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.value !== this.state.value) {
            if (prevState.value !== null) {
                const {value, maxValue} = this.state;

                if (this.props.updateDataOnChange) {
                    if (value > maxValue || !value) {
                        if (this.valueTimerId !== null) {
                            clearTimeout(this.valueTimerId);
                        }
                    }

                    if (value && value <= maxValue && !this.props.resetItemCounter) {
                        if (this.valueTimerId !== null) {
                            clearTimeout(this.valueTimerId);
                        }

                        this.valueTimerId = setTimeout(() => {
                            this.props.onCountChange(value, this.state.isError)
                        }, 1000);
                    }

                } else {
                    this.props.onCountChange(value, this.state.isError);
                }
            }
        }

        // need review
        if (prevProps.resetItemCounter !== this.props.resetItemCounter) {
            // console.log(this.state.value)
            if (this.props.resetItemCounter) {
                // check update function after this set
                this.setState({value: 1});
            }
        }

        if (prevProps.maxValue !== this.props.maxValue) {
            this.setState({maxValue: this.props.maxValue});
        }
    }

    onValueChange = (value) => {
        this.clearError();

        const {less_error_message, more_error_message} = this.props.staticData.item_counter;
        const moreErrorMessage = `${more_error_message[0]} ${this.state.maxValue} ${more_error_message[1]}`;

        if (value === 0) {
            this.setState({isError: true, valueErrorMessage: less_error_message});
        }

        if (value > this.state.maxValue) {
            this.setState({isError: true, valueErrorMessage: moreErrorMessage});
        }

        this.setState({value});
    };

    incrementCounter = () => {
        const {value, maxValue} = this.state;
        const num = parseInt(value);
        if (num < maxValue) {
            this.onValueChange(num + 1);
        }
    };

    decrementCounter = () => {
        const {value, minValue} = this.state;
        const num = parseInt(value);
        if (num > minValue) {
            this.onValueChange(num - 1);
        }
    };

    onInputChange = ({target: {value}}) => {
        /* set value to '' or convert to number */
        value = !value ? '' : parseInt(value);
        this.onValueChange(value);
    };

    onFocus = () => this.clearError();

    onBlur = ({target: {value}}) => {
        const {empty_error_message, less_error_message, more_error_message} = this.props.staticData.item_counter;
        const moreErrorMessage = `${more_error_message[0]} ${this.state.maxValue} ${more_error_message[1]}`;

        if (value === '') {
            this.setState({isError: true, valueErrorMessage: empty_error_message});
        }

        if (value === '0') {
            this.setState({isError: true, valueErrorMessage: less_error_message});
        }

        if (parseInt(value) > this.state.maxValue) {
            this.setState({isError: true, valueErrorMessage: moreErrorMessage});
        }
    };

    clearError = () => {
        if (this.state.isError) {
            this.setState({isError: false, valueErrorMessage: null});
        }
    };

    componentWillUnmount() {
        clearTimeout(this.valueTimerId);
    }

    render() {
        const {classNames, styleType, tooltipPlacement} = this.props;
        let {value, minValue, maxValue, isError, valueErrorMessage} = this.state;

        const cx = classnames.bind(styles);
        const counterClasses = cx('counter', classNames, {'secondary': styleType === 'SECONDARY'});
        const incrementBtnClasses = cx('increment', {
            'disabled': value >= maxValue && maxValue,
            'secondary': styleType === 'SECONDARY'
        });
        const decrementBtnClasses = cx('decrement', {
            'disabled': value <= minValue,
            'secondary': styleType === 'SECONDARY'
        });

        return (
            <Tooltip
                mode={'manual'}
                tooltip={<div className={styles['error-message']}>{valueErrorMessage}</div>}
                maxWidth={186}
                visible={isError}
                placement={tooltipPlacement}
                animationDuration={[300, 0]}
                arrow>
                <div className={counterClasses}>
                    <div className={decrementBtnClasses} onClick={this.decrementCounter}/>
                    <input type="number"
                           className={styles.count}
                           value={value || value === 0 ? value.toString() : ''}
                           onFocus={this.onFocus}
                           onBlur={this.onBlur}
                           onChange={this.onInputChange}
                    />
                    <div className={incrementBtnClasses} onClick={this.incrementCounter}/>
                </div>
            </Tooltip>
        );
    }

    static defaultProps = {
        styleType: 'PRIMARY',
        value: 1,
        tooltipPlacement: 'bottom',
        resetItemCounter: false,
        updateDataOnChange: false,
        onCountChange: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        classNames: PropTypes.string,
        styleType: PropTypes.oneOf(['PRIMARY', 'SECONDARY']),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        maxValue: PropTypes.number.isRequired,
        tooltipPlacement: PropTypes.string,
        onCountChange: PropTypes.func,
        resetItemCounter: PropTypes.bool,
        updateDataOnChange: PropTypes.bool,
    };
}

export default withDataService()(ItemCounter);