import React from 'react';
import PropTypes from 'prop-types';

import SkeletonPreloader from '../skeleton-preloader';

import classnames from 'classnames/bind';
import styles from './input-search.module.scss';

const InputSearch = (props) => {
    const {
        type, id, inputPlaceholder, labelClasses, isEmpty,
        isSkeletonPreloader, inputClasses, onInputChange,
        onInputKeyDown, onInputBlur, onClearInputSearch,
    } = props;

    const cx = classnames.bind(styles);
    const classes = cx('label', labelClasses, {
        'with-icon': type === 'WITH_ICON',
        'without-icon': type === 'WITHOUT_ICON',
    });

    const inputClassNames = cx('input', inputClasses);

    return !isSkeletonPreloader
        ? (
            <label htmlFor={id}
                   className={classes}>
                <input type="search"
                       id={id}
                       className={inputClassNames}
                       placeholder={inputPlaceholder}
                       autoComplete="off"
                       onChange={onInputChange}
                       onKeyDown={onInputKeyDown}
                       onMouseUp={onInputChange}
                       onTouchEnd={onInputChange}
                       onFocus={onInputChange}
                       onBlur={onInputBlur}
                />
                {!isEmpty && <div className={styles['clear-btn']} onClick={onClearInputSearch}/>}
            </label>
        ) : (
            <div className={cx('skeleton-container', inputClasses)}>
                <SkeletonPreloader type={'TEXT'}
                                   loading={isSkeletonPreloader}
                                   classNames={styles.skeleton}/>
            </div>
        );
};

InputSearch.defaultProps = {
    type: 'WITHOUT_ICON',
    onInputChange: () => {},
    onInputKeyDown: () => {},
    onInputBlur: () => {},
    onClearInputSearch: () => {},
    isSkeletonPreloader: false,
};

InputSearch.propTypes = {
    type: PropTypes.oneOf(['WITH_ICON', 'WITHOUT_ICON']),
    id: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    labelClasses: PropTypes.string,
    inputClasses: PropTypes.string,
    isEmpty: PropTypes.bool,
    isSkeletonPreloader: PropTypes.bool,
    onInputChange: PropTypes.func,
    onInputKeyDown: PropTypes.func,
    onInputBlur: PropTypes.func,
    onClearInputSearch: PropTypes.func,
};

export default InputSearch;