import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withServices} from '../service-context';
import {withDataService, withRedirectAction} from '../common/hoc';
import {
    documentRequest,
    updatingDocumentRequest,
} from '../../reducers/document';
import {resetRequestOnButtonUrl} from '../../actions/activity-hub';
import {onButtonUrlRequest} from '../../reducers/activity-hub/request-on-button-url';
import {resetDocument, utilsAction} from '../../actions/common';
import {updatingBreadcrumbsOnComponentAction} from '../activity-hub/components/pages/contract-maps/components/common/handlers/updating-breadcrumbs';
import {onGetButtonMethod} from '../common/button-constructor/handlers';
import sweetAlert from '../common/sweet-alert';
import {onGetAlertResponse} from '../../utils';

import DocumentViewer from '../common/document-viewer/document-viewer';

class DocumentPage extends Component {
    state = {
        documentID: null,
        actionButtonIdx: null,
    };

    componentDidMount() {
        const {match: {params: {id}}} = this.props;
        this.setState({documentID: id});
        this.props.fetchDocument(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchDocumentUpdating(this.state.documentID);
        }

        if (prevProps.document !== this.props.document) {
            const {
                staticData: {breadcrumbs: {document_page}},
                breadcrumbs: {list},
                document: documentData,
            } = this.props;
            let breadcrumbsAdditionalList = document_page.list;

            if (documentData && documentData.title) {
                breadcrumbsAdditionalList = document_page.list.map((item, idx) => ({
                    ...item,
                    label: idx === document_page.list.length - 1 ? documentData.title : item.label,
                }));
            }

            const updatedList = updatingBreadcrumbsOnComponentAction(list, breadcrumbsAdditionalList);
            this.props.updateBreadcrumbs(updatedList);
        }

        /**
         * Next case work with straight request to back-end server,
         * Not work with redirect to another resource
         **/
        if (prevProps.requestOnButtonUrlResp !== this.props.requestOnButtonUrlResp) {
            const {status, text} = onGetAlertResponse(this.props.requestOnButtonUrlResp);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    this.props.resetRequestOnButtonUrl();
                    this.setState({actionButtonIdx: null});
                });
            }
        }
    }

    onButtonClick = (idx, {method, url, target}) => {
        const {request_question} = this.props.staticData.activity_hub.e_contracts;

        if (method === 'POST') {
            sweetAlert('question', request_question)
                .then((resp) => {
                    if (resp.isConfirmed) {
                        this.setState({actionButtonIdx: idx});
                        this.props.sendPOSTRequestOnButtonUrl(url);
                    }
                });
        } else {
            onGetButtonMethod(url, target, this.props.history);
        }
    };

    componentWillUnmount() {
        this.props.resetDocument();
    }

    render() {
        const {
            document, loading, updating, error,
            requestOnButtonUrlResp: {loading: actionLoading},
        } = this.props;
        const componentProps = {
            document,
            loading,
            updating,
            error,
            actionButton: {idx: this.state.actionButtonIdx, loading: actionLoading},
        };

        return <DocumentViewer {...componentProps} onButtonClick={this.onButtonClick}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        breadcrumbs: PropTypes.object,
        requestOnButtonUrlResp: PropTypes.object,
        fetchDocument: PropTypes.func,
        fetchDocumentUpdating: PropTypes.func,
        sendPOSTRequestOnButtonUrl: PropTypes.func,
        resetRequestOnButtonUrl: PropTypes.func,
        resetDocument: PropTypes.func,
    };
}

const mapServicesToProps = ({documentService, activityHubService}) => ({
    fetchDocument: documentRequest(documentService.getContractDocument),
    fetchDocumentUpdating: updatingDocumentRequest(documentService.getContractDocument),
    sendPOSTRequestOnButtonUrl: onButtonUrlRequest(activityHubService.sendPOSTRequestOnButtonUrl),
});

const mapStateToProps = (props) => {
    const {
        profile: {language},
        utils: {breadcrumbs},
        document,
        activityHub: {requestOnButtonUrl},
    } = props;

    return {
        loading: document.loading,
        updating: document.updating,
        document: document.document,
        error: document.error,
        requestOnButtonUrlResp: requestOnButtonUrl,
        language,
        breadcrumbs,
    };
};

const mapDispatchToProps = (dispatch, {fetchDocument, fetchDocumentUpdating, sendPOSTRequestOnButtonUrl}) => (
    bindActionCreators({
        fetchDocument,
        fetchDocumentUpdating,
        sendPOSTRequestOnButtonUrl,
        updateBreadcrumbs: utilsAction.updateBreadcrumbs,
        resetRequestOnButtonUrl,
        resetDocument,
    }, dispatch)
);

export default compose(
    withRouter,
    withDataService(),
    withRedirectAction(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(DocumentPage);