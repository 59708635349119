import React from 'react';

import ActivityHubModule from './module';

const ActivityHubContainer = () => {
    return <ActivityHubModule/>;
};

export {
    ActivityHubContainer as ActivityHub,
};