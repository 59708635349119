import React, {Component} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../../common/hoc';

import Modal from '../../../../../../../../../common/modal';
import SerialNumberForm from '../serial-number-form/serial-number-form';
import Button from '../../../../../../../../../common/button';
import EllipsisTextTooltip from '../../../../../../../../../common/ellipsis-text-tooltip';

import classnames from 'classnames/bind';
import styles from './place-table.module.scss';

class PlaceTable extends Component {
    initialState = {
        modalIsOpen: null,
        withQuestionBeforeCloseModal: false,
    };

    state = this.initialState;

    onOpenSerialNumberForm = (offerData) => this.setState({
        modalIsOpen: {...offerData, active: true},
    });

    onCloseSerialNumberForm = () => this.setState(this.initialState);

    toggleStateWithQuestionBeforeCloseModal = (value) => this.setState({
        withQuestionBeforeCloseModal: value,
    });

    render() {
        const {
            staticData: {button: {add_serial_number}},
            formMode,
            list,
            form,
            placebunchIdx,
            placeIdx,
            strategy,
        } = this.props;

        if (list.length === 0) {
            return null;
        }

        const {modalIsOpen, withQuestionBeforeCloseModal} = this.state;

        let tmpForm = form;
        const groupedFormFields = [];
        form.forEach(({name}) => {
            if (tmpForm.length > 0) {
                if (tmpForm.find((item) => item.name === name)) {
                    let transformName = name.split('[');
                    transformName = transformName.slice(0, transformName.length - 1);
                    transformName = transformName.join('[');

                    const regExp = /\[offer]\[\d+]/;
                    const nameMatch = transformName.match(regExp);

                    let offerID = null;
                    if (nameMatch) {
                        let str = nameMatch[0].replace(/\[/g, ' ');
                        str = str.replace(/]/g, '');
                        str = str.split(' ');
                        offerID = parseInt(str[str.length - 1]);
                    }

                    const group = form.filter(({name}) => name.includes(transformName));
                    groupedFormFields.push(
                        offerID
                            ? {id: offerID, list: group}
                            : group,
                    );

                    group.forEach(({name}) => {
                        const idx = tmpForm.findIndex(item => item.name === name);
                        tmpForm = [
                            ...tmpForm.slice(0, idx),
                            ...tmpForm.slice(idx + 1),
                        ];
                    });
                }
            }
        });

        const cx = classnames.bind(styles);
        const withShortTable = [
            'supply_set_load',
            'break_bulk_load',
        ];
        const classObject = {
            'with-serial-number': withShortTable.every(value => value !== strategy),
            'with-short-table': withShortTable.find(value => value === strategy),
        };
        const tableHeadClasses = cx('table-head', classObject);
        const rowClasses = cx('row', classObject);

        const placeTableLabels = list[0].row.map(({label}) => label).filter(item => item);
        const tableLabels = ['№', ...placeTableLabels].map((label, idx) => {
            return <div key={idx} className={styles.column}>{label}</div>;
        });

        const tableItems = list.map(({id, invoice_id, quantity, row}, index) => {
            const rowItems = row.map(({label, value}, idx) => {
                if (groupedFormFields.length > 0) {
                    const groupList = groupedFormFields.find(item => item.id === id).list;
                    /* spare parts kit column */
                    if (idx === row.length - 1) {
                        const checkbox = groupList.find(({type}) => type === 'checkbox');
                        return (
                            <div key={idx} className={styles.column}>
                                {this.props.formFieldHandler([checkbox])}
                            </div>
                        );
                    }

                    /* net weight column */
                    if (idx === row.length - 2) {
                        const input = groupList.find(({type}) => type === 'text');
                        return (
                            <div key={idx} className={cx('column', 'input')}>
                                {this.props.formFieldHandler([input])}
                            </div>
                        );
                    }

                    /* serial number column */
                    if (idx === row.length - 3) {
                        const serialNumber = groupList.find(({type}) => type === 'modal');
                        const {name, value} = serialNumber;
                        const updatedValue = value ? value.split(',') : [];
                        const columnClasses = cx('column', 'column-serial-number');

                        let isSerialNumbersFormOpen = false;
                        if (modalIsOpen) {
                            isSerialNumbersFormOpen =
                                modalIsOpen.id === id &&
                                modalIsOpen.placebunchIdx === placebunchIdx &&
                                modalIsOpen.placeIdx === placeIdx &&
                                modalIsOpen.active;
                        }

                        return (
                            <div key={idx} className={columnClasses}>
                                {updatedValue.length > 0
                                    ? (
                                        <>
                                            <div className={styles.serial}>
                                                {updatedValue.map((item, idx) => (
                                                    <div key={idx} className={styles['serial-item']}>{item}</div>
                                                ))}

                                            </div>
                                            <div className={styles['edit-serial-number']}
                                                 onClick={() => this.onOpenSerialNumberForm({
                                                     id,
                                                     placebunchIdx,
                                                     placeIdx
                                                 })}/>
                                        </>
                                    )
                                    : (
                                        <Button styleType={'WITH_PLUS'}
                                                classNames={styles.btn}
                                                onClick={() => this.onOpenSerialNumberForm({
                                                    id,
                                                    placebunchIdx,
                                                    placeIdx
                                                })}>
                                            {add_serial_number}
                                        </Button>
                                    )}

                                <Modal>
                                    <SerialNumberForm
                                        offerData={{name, quantity, value}}
                                        formMode={formMode}
                                        modalClass={styles.modal}
                                        isShown={isSerialNumbersFormOpen}
                                        onClose={this.onCloseSerialNumberForm}
                                        onContainerClose={this.onCloseSerialNumberForm}
                                        toggleStateWithQuestionBeforeCloseModal={this.toggleStateWithQuestionBeforeCloseModal}
                                        onUpdateSerialNumbers={(offerData) => {
                                            this.props.onUpdateSerialNumbers(offerData);
                                            this.onCloseSerialNumberForm();
                                        }}
                                        withQuestionBeforeCloseModal={withQuestionBeforeCloseModal}
                                        closeButton/>
                                </Modal>
                            </div>
                        );
                    }
                }

                return (
                    <div key={idx} className={styles.column}>
                        {idx === 0 || idx === 1 ? <EllipsisTextTooltip tooltip={value}/> : value}
                    </div>
                );
            });

            return (
                <div key={index} className={rowClasses}>
                    <div className={styles.column}>{index + 1}</div>

                    {rowItems}
                </div>
            );
        });

        return (
            <div className={styles.container}>
                <div className={tableHeadClasses}>{tableLabels}</div>

                {tableItems}
            </div>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        form: PropTypes.array,
        list: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                invoice_id: PropTypes.number,
                quantity: PropTypes.number,
                row: PropTypes.arrayOf(PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                })),
            })),
        ]),
        placebunchIdx: PropTypes.number,
        placeIdx: PropTypes.number,
        strategy: PropTypes.string,
        formFieldHandler: PropTypes.func,
        onUpdateSerialNumbers: PropTypes.func,
    };
}

export default compose(
    withDataService('activity_hub.e_contracts.pages.packing_list_wizard.pages.shipment_lot'),
)(PlaceTable);