import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {switchProductDocumentsVisibility} from '../../../../../../../../../actions/marketplace/product-page';

import AvailableCurrencyTable from './delivery-and-payment-blocks/available-currency-table';
import ProductDescription from '../../../product-description';
import DocumentsList from '../../../documents-list/documents-list';

import styles from './delivery-and-payment.module.scss';

const DeliveryAndPayment = ({content}) => {
    const [
        availableMeansOfPayment,
        paymentOptions,
        regulatoryFramework,
        shipmentConditions,
        packaging,
        dangerousGoodsInformation,
    ] = content;

    const {
        attachments,
        attachments: {payment: paymentDocuments},
    } = useSelector(({marketplace: {productDetails: {details}}}) => (details));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(switchProductDocumentsVisibility(false));

        return () => {
            dispatch(switchProductDocumentsVisibility(true));
        };
    }, [dispatch]);

    const productDescriptionCreator = ({label, value}) => {
        return (
            <ProductDescription itemClassNames={styles['description-item']}
                                description={value}
                                title={label}
                                isTitle
                                isMobileTitle/>
        );
    };

    return (
        <div className={styles.container}>
            {(availableMeansOfPayment || paymentOptions) &&
            (<div className={styles['payment-container']}>
                {availableMeansOfPayment.value !== 0 && (<div className={styles['payment-means']}>
                    <div className={styles.title}>
                        {availableMeansOfPayment.label}
                    </div>
                    <div className={styles.subtitle}>
                        {availableMeansOfPayment.value.date}
                    </div>
                    <AvailableCurrencyTable data={availableMeansOfPayment.value.table}/>
                </div>)}

                {paymentOptions.length !== 0 && (<div className={styles['payment-options']}>
                    <div className={styles.title}>{paymentOptions.label}</div>
                    <div className={styles['open-invoice']}>
                        {paymentOptions.value}
                    </div>
                </div>)}
            </div>)
            }

            {(regulatoryFramework && regulatoryFramework.value.length !== 0) && (
                <div className={styles['description-container']}>
                    {productDescriptionCreator(regulatoryFramework)}
                </div>
            )}

            {(shipmentConditions && shipmentConditions.value.length !== 0) && (
                <div className={styles['description-container']}>
                    {productDescriptionCreator(shipmentConditions)}
                </div>
            )}

            {((packaging && packaging.value.length !== 0) || (dangerousGoodsInformation && dangerousGoodsInformation.value.length !== 0)) && (
                <div className={`${styles['description-container']} ${styles.secondary}`}>
                    {packaging.value.length !== 0 && (
                        <div className={styles['description-block']}>
                            {productDescriptionCreator(packaging)}
                        </div>
                    )}
                    {dangerousGoodsInformation.value.length !== 0 && (
                        <div className={styles['description-block']}>
                            {productDescriptionCreator(dangerousGoodsInformation)}
                        </div>
                    )}
                </div>
            )}

            {attachments.length !== 0 && paymentDocuments !== undefined && (
                <div className={`${styles['description-container']} ${styles.documents}`}>
                    <DocumentsList {...paymentDocuments} turnOffAccordion isMobileTitle/>
                </div>
            )}
        </div>
    );
};

DeliveryAndPayment.propTypes = {
    content: PropTypes.array,
};

export default DeliveryAndPayment;