export const resetSetDefaultShipmentOrganization = () => {
    return {type: 'RESET_SET_DEFAULT_SHIPMENT_ORGANIZATION'};
};

export const resetShipmentOrganizationActions = () => {
    return {type: 'RESET_SHIPMENT_ORGANIZATION_ACTIONS'};
};

export const resetRemoveShipmentOrganization = () => {
    return {type: 'RESET_REMOVE_SHIPMENT_ORGANIZATION'};
};