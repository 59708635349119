import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ProductsContainer from '../../common/products-container/products-container';

class SearchPage extends Component {
    state = {
        title: null,
    };

    componentDidMount() {
        this.setPageTitle();
        this.getProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {
            const {location: {search: params}} = this.props;
            this.props.fetchAllProducts(params);
        }

        if (prevProps.location.pageTitle !== this.props.location.pageTitle) {
            this.setPageTitle();
        }

        if (prevProps.language !== this.props.language) {
            this.getProducts();
        }
    }

    getProducts = () => {
        const {slug: {id, used}, location: {search: params}} = this.props;

        if (id && used) {
            this.props.fetchCategoryProducts(id, params);
            this.props.updateSearchCategorySlug({id: null});
        } else {
            this.props.fetchAllProducts(params);
        }
    };

    setPageTitle = () => {
        const {staticData, location: {pageTitle}} = this.props;
        const {title: defaultTitle} = staticData.marketplace.search_page;
        const newTitle = pageTitle || defaultTitle;

        this.setState({title: newTitle});
    };

    render() {
        const {history, location, products} = this.props;
        const {title} = this.state;

        return (
            <ProductsContainer
                productPage={products}
                history={history}
                location={location}
                pageTitle={title}
                filterTurnOn={false}/>
        );
    }

    static propTypes = {
        slug: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        location: PropTypes.shape({
            search: PropTypes.string,
            pageTitle: PropTypes.string,
        }),
        language: PropTypes.string,
        fetchAllProducts: PropTypes.func,
        fetchCategoryProducts: PropTypes.func,
        updateSearchCategorySlug: PropTypes.func,
    };
}

export default SearchPage;