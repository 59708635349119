import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {marketplaceURLs} from '../../../../../../../../services/api-urls';

import Button from '../../../../../../../common/button';

import styles from './empty-request-list.module.scss';

const EmptyRequestList = ({staticData, marketplaceUrl}) => {
    const {
        empty_request_list: {text},
        button: {add, upload},
    } = staticData.marketplace.quotation;

    return (
        <div className={styles.container}>
            <div className={styles.text}>
                {text}
            </div>
            <div className={styles['btn-block']}>
                <Link to={marketplaceUrl}>
                    <Button styleType="WITH_PLUS" classNames={styles['btn-add']}>
                        {add}
                    </Button>
                </Link>
                <Link to={marketplaceURLs.quotation_excel}>
                    <Button styleType="SECONDARY" classNames={styles['btn-upload']}>
                        {upload}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

EmptyRequestList.propTypes = {
    staticData: PropTypes.object,
    marketplaceUrl: PropTypes.string,
};

export default EmptyRequestList;