export const setCookieConsent = (payload = false) => {
    return {type: 'SET_COOKIE_CONSENT', payload};
};

export const setBaseAPIUrl = (payload) => {
    return {type: 'SET_BASE_API_URL', payload};
};

export const switchDepartmentID = (payload) => {
    return {type: 'SWITCH_DEPARTMENT_ID', payload};
};

export const switchView = (payload) => {
    return {type: 'SWITCH_VIEW', payload};
};

export const setServerError = (payload = null) => {
    return {type: 'SET_SERVER_ERROR', payload};
};

export const setNotFoundError = (payload = null) => {
    return {type: 'SET_NOT_FOUND_ERROR', payload};
};

export const setNotAcceptableError = (payload = null) => {
    return {type: 'SET_NOT_ACCEPTABLE_ERROR', payload};
};

export const resetErrorIndicatorSwitcher = () => {
    return {type: 'RESET_ERROR_INDICATOR_SWITCHER'};
};

export const setErrorBoundry = () => {
    return {type: 'SET_ERROR_BOUNDRY'};
};

export const updateBreadcrumbs = (list, loading = null) => {
    if (loading !== null) {
        // we can use this when response haven`t breadcrumbs
        return {type: 'UPDATE_BREADCRUMBS', payload: {loading, list, canBeLoading: true}};
    }

    return {type: 'UPDATE_BREADCRUMBS', payload: list};
};