import React from 'react';
import PropTypes from 'prop-types';

import {withSideDropMenu} from '../../hoc';

import Chip from '../../chip';
import Arrow from '../../arrow';

import styles from './sorting-mobile.module.scss';

const SortingMobile = ({title, sortingOptions, isUpdating, sortBy}) => {
    return (
        <>
            <div className={styles.title}>{title}</div>
            {sortingOptions.map(({name, href, isActive, arrow = ''}, idx) => (
                    <Chip key={idx}
                          type="SECONDARY"
                          classNames={styles.item}
                          isActive={isActive}
                          isDisable={isUpdating}
                          onClick={() => sortBy(href)}
                    >
                        {name}
                        {arrow === 'up' && <Arrow isActive={isActive} isDisable={isUpdating}/>}
                        {arrow === 'down' && <Arrow type="DOWN" isActive={isActive} isDisable={isUpdating}/>}
                    </Chip>
                ),
            )}
        </>
    );
};

SortingMobile.propTypes = {
    title: PropTypes.string,
    sortingOptions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        arrow: PropTypes.string,
    })),
};

export default withSideDropMenu()(SortingMobile);