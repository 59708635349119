import {createRequestHandler} from '../utils';

export const BATCH_UPLOAD_FORM_REQUEST = 'BATCH_UPLOAD_FORM_REQUEST';
export const BATCH_UPLOAD_FORM_SUCCESS = 'BATCH_UPLOAD_FORM_SUCCESS';
export const BATCH_UPLOAD_FORM_FAILURE = 'BATCH_UPLOAD_FORM_FAILURE';

export const batchUploadFormRequest = createRequestHandler('BATCH_UPLOAD_FORM');

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const batchUploadForm = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case BATCH_UPLOAD_FORM_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case BATCH_UPLOAD_FORM_SUCCESS:
            return {
                data: payload,
                loading: false,
                error: null,
            };

        case BATCH_UPLOAD_FORM_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default batchUploadForm;