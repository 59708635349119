import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';
import {withServices} from '../../../../service-context';
import {switchMenuActiveLinkID, profileAction} from '../../../../../actions/activity-hub';
import {
    profileDataRequest,
    updatingProfileDataRequest,
} from '../../../../../reducers/activity-hub/profile/data-list';
import {uploadProfileFormRequest} from '../../../../../reducers/activity-hub/profile/upload-profile-form';
import {onGetAlertResponse} from '../../../../../utils';
import {onCreateFormData} from '../../../../common/handlers';
import sweetAlert from '../../../../common/sweet-alert';
import {errorURLs, hubURLs} from '../../../../../services/api-urls';

import Profile from './profile';
import BankAccount from './pages/bank-account';
import ShipmentOrganization from './pages/shipment-organization';
import AccessSetup from "./pages/access-setup";

import styles from '../../../components/common/scss/required.module.scss';

class ProfileContainer extends Component {
    state = {
        list: [],
        submitAction: false,
        formID: 'profile-form',
        checkingFieldMode: false,
    };

    componentDidMount() {
        const {pageID, menuActiveLinkID} = this.props;
        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        // need work, dont call if page !== /hub/profile, check with another page for create bank and shipment org
        this.props.fetchProfileData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchUpdatingProfileData();
        }

        if (prevProps.list !== this.props.list) {
            this.setState({list: this.props.list});
        }

        if (prevState.list !== this.state.list) {
            if (this.state.submitAction) {
                let object = {};

                const activeTabIdx = this.state.list.findIndex(({tab}) => tab.isActive);
                const activeTab = this.state.list[activeTabIdx];
                const listWithoutActiveTab = [
                    ...this.state.list.slice(0, activeTabIdx),
                    ...this.state.list.slice(activeTabIdx + 1),
                ];
                const sortedList = [
                    activeTab,
                    ...listWithoutActiveTab,
                ];

                sortedList.forEach(({fields, tab}) => {
                    fields.forEach((listItem) => {
                        const {type, name, documents, props} = listItem;
                        let {value} = listItem;
                        let valid = true;

                        if (props) {
                            if (props.includes('required')) {
                                if (!value) {
                                    valid = false;
                                    window.scrollTo({top: 0, behavior: 'smooth'});
                                    this.setState({submitAction: false});

                                    if (tab.isActive) {
                                        this.onCheckingRequiredField(type, name);
                                    } else {
                                        this.setState({checkingFieldMode: {type, name}});
                                        this.onToggleTab({target: {id: tab.id}});
                                    }
                                }
                            }

                            if (type === 'text' && value && value.floatValue) {
                                value = value.floatValue;
                            }
                        }

                        if ((value || value === '') && valid) {
                            if (type === 'dropzone') {
                                if (documents) {
                                    object = {
                                        ...object,
                                        [name]: {files: documents},
                                    };
                                }

                            } else {
                                object = {
                                    ...object,
                                    [name]: value,
                                };
                            }
                        }
                    });
                });

                const formData = onCreateFormData(object);
                this.props.uploadProfileForm({formData});
            }
        }

        if (prevProps.uploadFormResponse !== this.props.uploadFormResponse) {
            const {status, text} = onGetAlertResponse(this.props.uploadFormResponse);

            if (status === 'success') {
                sweetAlert(status, text).then(() => {
                    this.props.resetUploadResponse();
                    this.props.fetchUpdatingProfileData();
                });
            }

            if (status === 'error') {
                const errorMessage = (
                    <ul>
                        {Object.values(text).map((item, idx) => {
                            return <li key={idx}>{`- ${item}`}</li>;
                        })}
                    </ul>
                );

                sweetAlert(status, errorMessage)
                    .then(() => this.props.resetUploadResponse());
            }

            this.setState({submitAction: false});
        }
    }

    routeHandler = (params, pageType = 'create') => {
        const {match: {path: basePath}, staticData} = this.props;
        const {match: {params: {pages}}} = params;
        const {bank_account, shipment_organization} = staticData.pages;

        let commonProps = {
            ...params,
            basePath,
            pageType,
            redirectUrl: this.props.redirectUrl,
            withRedirect: this.props.withRedirect,
        };

        let title = '';
        switch (pages) {
            case 'bank':
                title = bank_account[`${pageType}_account_title`];
                break;

            case 'consignee':
                title = shipment_organization[`${pageType}_consignee_title`];
                break;

            case 'notifyparty':
                title = shipment_organization[`${pageType}_notify_title`];
                break;

            case 'importerforwarder':
            case 'exporterforwarder':
                title = shipment_organization[`${pageType}_forwarder`];
                break;

            case 'shipper':
                title = shipment_organization[`${pageType}_shipper_title`];
                break;

            default:
                title = '';
        }

        if (pages === 'bank') {
            commonProps = {...commonProps, title};
            return <BankAccount {...commonProps}/>;
        }

        commonProps = {...commonProps, organization_id: pages, title};
        return <ShipmentOrganization {...commonProps}/>;
    };

    onCheckingRequiredField = (type, name) => {
        const form = document.getElementById(this.state.formID);
        const atr = type === 'select' ? `[data-name='${name}']` : `[name='${name}']`;

        if (form) {
            const element = form.querySelector(atr);
            const requiredClass = styles.required;

            if (element) {
                if (type === 'select') {
                    element.classList.add(requiredClass);
                }

                if (type === 'text') {
                    element.parentNode.classList.add(requiredClass);
                }
            }
        }

        this.setState({checkingFieldMode: false});
    };

    onResetErrorStateForFields = () => {
        const form = document.getElementById(this.state.formID);
        if (form) {
            const elements = form.querySelectorAll(`.${styles.required}`);
            elements.forEach(el => el.classList.remove(`${styles.required}`));
        }
    };

    onToggleTab = ({target: {id}}) => {
        const updatedList = this.state.list.map((item) => {
            return {
                ...item,
                tab: {
                    ...item.tab,
                    isActive: item.tab.id === id,
                },
            };
        });

        this.setState({list: updatedList});
        this.props.updateDataFields(updatedList);
    };

    onUpdateList = (id, list) => {
        this.onResetErrorStateForFields();

        const updatedList = this.state.list.map((item) => {
            if (item.tab.id === id) {
                return {
                    ...item,
                    fields: list,
                };
            }

            return item;
        });

        this.setState({list: updatedList});
    };

    onSubmit = () => this.setState({submitAction: true});

    render() {
        const {staticData: {title, pages: {access_setup}}, match: {path: basePath}, ...rest} = this.props;
        const {formID, ...restState} = this.state;
        const pages = [
            'bank', 'consignee', 'notifyparty',
            'importerforwarder', 'exporterforwarder', 'shipper',
        ].join('|');

        return (
            <Switch>
                <Route path={`${basePath}`} exact render={(props) =>
                    <Profile {...props} {...rest} {...restState}
                             title={title}
                             mainID={formID}
                             onCheckingRequiredField={this.onCheckingRequiredField}
                             onToggleTab={this.onToggleTab}
                             onUpdateList={this.onUpdateList}
                             onSubmit={this.onSubmit}/>
                }/>

                {/* Payment/Shipment setup create/edit account */}
                <Route
                    path={`${basePath}/:pages(${pages})/create`}
                    exact
                    render={(props) => this.routeHandler(props, 'create')}
                />

                <Route path={`${basePath}/:pages(${pages})/update/:id`}
                       exact
                       render={(props) => this.routeHandler(props, 'edit')}
                />

                <Route path={hubURLs.access_setup} exact render={(props) =>
                    <AccessSetup {...props}
                                 title={access_setup.title}
                                 redirectUrl={this.props.redirectUrl}
                                 withRedirect={this.props.withRedirect}/>
                }/>

                <Route>
                    <Redirect to={errorURLs.not_found}/>
                </Route>
            </Switch>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        list: PropTypes.array,
        uploadFormResponse: PropTypes.object,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        switchMenuActiveLinkID: PropTypes.func,
        fetchProfileData: PropTypes.func,
        fetchUpdatingProfileData: PropTypes.func,
        updateDataFields: PropTypes.func,
        uploadProfileForm: PropTypes.func,
        resetUploadResponse: PropTypes.func,
    };
}

const mapServicesToProps = ({profileService}) => ({
    fetchProfileData: profileDataRequest(profileService.getProfileDataFields),
    fetchUpdatingProfileData: updatingProfileDataRequest(profileService.getProfileDataFields),
    uploadProfileForm: uploadProfileFormRequest(profileService.editProfile),
});

const mapStateToProps = ({activityHub, profile: {language}}) => {
    const {
        utils,
        profile: {
            dataList: {loading, updating, list, error, tabsID},
            uploadProfileForm,
        },
        removeAttachment,
    } = activityHub;

    return {
        language, loading, updating, list, error, tabsID,
        menuActiveLinkID: utils.menuActiveLinkID,
        uploadFormResponse: uploadProfileForm,
        uploadFormLoading: uploadProfileForm.loading,
        removeAttachmentLoading: removeAttachment.loading,
    };
};

const mapDispatchToProps = (dispatch, {
    fetchProfileData,
    fetchUpdatingProfileData,
    uploadProfileForm,
}) => {
    const {updateDataFields, resetUploadResponse} = profileAction;

    return bindActionCreators({
        switchMenuActiveLinkID,
        fetchProfileData,
        fetchUpdatingProfileData,
        uploadProfileForm,
        updateDataFields,
        resetUploadResponse,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService('activity_hub.profile'),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ProfileContainer);