export const getInitItemFromSelectList = (list) => {
    let selectedItem = list.find(({value}) => value === 'default');

    if (!selectedItem) {
        selectedItem = list[0];
    }

    return selectedItem;
};

export const getSelectedItemFromSelectList = (list, selectedValue) => {
    return list.find((item) =>
        typeof item.value === 'number'
            ? item.value === parseInt(selectedValue)
            : item.value === selectedValue,
    );
};

export const getCheckboxValue = (field) => {
    /* need work */
    /* check one of the item in list */
    const typeOfValue = typeof field.list[0].value;
    let newValues = [];
    if (typeOfValue === 'number') {
        field.value.forEach(value => newValues.push(parseInt(value)));
    } else {
        newValues = field.value;
    }

    return newValues;
};