import React from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';

import ProductPanel from '../../../../../../common/product-panel';

import styles from './interchangeability.module.scss';

const Interchangeability = ({staticData, productName, content}) => {
    const {
        product_panel: {header},
        product_page: {interchangeability_text}
    } = staticData.marketplace;

    return (
        <div className={styles.container}>
            <div className={styles.name}>{productName} {interchangeability_text}</div>
            <div className={styles['list-header']}>
                <div className={styles.column}>{header.item_label}</div>
                <div className={styles.column}>{header.condition_label}</div>
                <div className={styles.column}>{header.price_label}</div>
            </div>
            <div className={styles['list-items']}>
                {content.map((item, idx) => <ProductPanel key={idx} {...item} />)}
            </div>
        </div>
    );
};

Interchangeability.propTypes = {
    productName: PropTypes.string,
    content: PropTypes.array,
    staticData: PropTypes.object,
};

export default withDataService()(Interchangeability);