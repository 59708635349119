import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withRedirectAction, withDataService} from '../../../../../../common/hoc';
import {errorURLs, marketplaceURLs} from '../../../../../../../services/api-urls';

import RequestForSupplyContainer from './request-for-supply';
import ExcelUploadPageContainer from './excel-upload-page';
import ExcelFormatPage from '../../../../../../excel-format-page';

import styles from './module.module.scss';

const EnhancedComponent = withRedirectAction()(ExcelFormatPage);

class QuotationPageModule extends Component {
    render() {
        const {match: {path: basePath}, marketplaceUrl, staticData} = this.props;
        const {title} = staticData.marketplace.quotation.request_for_supply;
        const urlForRedirect = marketplaceURLs.quotation;

        return (
            <div className={styles['page-container']}>
                <div className={styles.title}>{title}</div>

                <Switch>
                    <Route path={`${basePath}`} exact render={(props) =>
                        <RequestForSupplyContainer {...props} marketplaceUrl={marketplaceUrl}/>
                    }/>

                    <Route path={`${basePath}/upload`} exact render={(props) =>
                        <ExcelUploadPageContainer {...props} url={urlForRedirect}/>
                    }/>

                    <Route path={`${basePath}/upload/excel-format`} exact render={(props) =>
                        <EnhancedComponent {...props}
                                           url={urlForRedirect}
                                           forExcel="quotation"
                                           staticData={staticData}
                                           onBack={() => this.props.history.push(`${basePath}/upload`)}/>
                    }/>

                    <Route>
                        <Redirect to={errorURLs.not_found}/>
                    </Route>
                </Switch>
            </div>
        );
    }

    static propTypes = {
        basePath: PropTypes.string,
        marketplaceUrl: PropTypes.string,
        staticData: PropTypes.object,
    };
}

export default compose(
    withRouter,
    withDataService(),
)(QuotationPageModule);