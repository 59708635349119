import React, {useEffect, useRef, useState} from 'react';
import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';

import {leftTooltipPositionHandler} from '../tooltip-creator/handlers';

import TooltipCreator from '../tooltip-creator';

import classnames from 'classnames/bind';
import styles from './input-date.module.scss';

const InputDate = (props) => {
    const {
        containerClassNames, label, format, name,
        value: selectedDate, clearIcon, openCalendarOnFocus,
        tooltip, props: propsParams, onDateChange,
    } = props;

    const labelRef = useRef();
    const [state, setState] = useState({value: null, leftTooltipPosition: 0});

    useEffect(() => {
        let date = null;

        if (selectedDate && selectedDate !== '') {
            date = new Date(selectedDate);
        }

        setState(state => ({...state, value: date}));

    }, [selectedDate]);

    useEffect(() => {
        if (labelRef.current && label) {
            setState(state => ({
                ...state,
                leftTooltipPosition: leftTooltipPositionHandler(labelRef.current),
            }));
        }
    }, [label]);

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', containerClassNames);
    const labelClasses = cx('label', {'with-tooltip': tooltip});

    const isRequired = propsParams && propsParams.includes('required');
    const calendarIcon = <div className={styles.icon}/>;

    const maxYear = new Date().getFullYear() + 50;
    const minDate = new Date('1945-01-01');
    const maxDate = new Date(`${maxYear}-01-01`);

    const datePickerBaseSettings = {
        format, name, calendarIcon, clearIcon,
        openCalendarOnFocus, minDate, maxDate,

        onChange: (value) => {
            onDateChange(name, value);
            setState(state => ({...state, value}));
        },
    };

    return (
        <div className={containerClasses}>
            <DatePicker {...datePickerBaseSettings}
                        value={state.value}
                        className={styles['calendar-input']}
                        calendarClassName={styles.calendar}/>

            <span ref={labelRef} className={labelClasses}>
                {label}
                {isRequired && <span className={styles['required-icon']}>*</span>}
            </span>
            {tooltip && <TooltipCreator tooltip={tooltip}
                                        style={{left: `${state.leftTooltipPosition}px`}}
            />}
        </div>
    );
};

InputDate.defaultProps = {
    selectedDate: '',
    format: 'dd.MM.yyyy',
    clearIcon: null,
    openCalendarOnFocus: false,
    onDateChange: () => {
    },
};

InputDate.propTypes = {
    containerClassNames: PropTypes.string,
    label: PropTypes.string,
    format: PropTypes.string,
    name: PropTypes.string,
    selectedDate: PropTypes.string,
    clearIcon: PropTypes.bool,
    openCalendarOnFocus: PropTypes.bool,
    tooltip: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    onDateChange: PropTypes.func,
};

export default InputDate;