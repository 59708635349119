import * as productPageAction from './product-page';
import * as requestForSupplyAction from './request-for-supply';

export const toggleMarketplaceFilter = (newState) => {
    return {
        type: 'TOGGLE_MARKETPLACE_FILTER',
        payload: newState,
    };
};

export const resetMarketplaceProducts = () => {
    return {type: 'RESET_MARKETPLACE_PRODUCTS'};
};

export const resetPartNumberSearchData = () => {
    return {
        type: 'RESET_PART_NUMBER_SEARCH_DATA',
    };
};

export {productPageAction, requestForSupplyAction};