import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';

import TooltipCreator from '../../../../../../../../common/tooltip-creator';
import StatusIndicator from '../../../../../../common/status-indicator';

import classnames from 'classnames/bind';
import styles from './contract-dashboard.module.scss';

const ContractDashboard = ({staticData, data, tooltipZIndex}) => {
    const {status_label} = staticData.activity_hub.e_contracts.pages.contract_for_supply;
    const {shortinfo, contragent} = data;
    const infoLength = shortinfo.length;
    const cx = classnames.bind(styles);

    const contragentData = [];
    if (contragent) {
        let count = 0;
        for (let key in contragent) {
            if (contragent.hasOwnProperty(key) && contragent[key]) {
                const text = contragent[key];
                const element = (
                    <div key={count} className={styles.data}>
                        {parse(text)}
                    </div>
                );

                count = count + 1;
                contragentData.push(element);
            }
        }
    }

    const dataColumns = shortinfo.map(({label, value, color}, idx) => {
        const infoClasses = cx('info', {
            'price': idx === 2,
            'status': idx === infoLength - 1,
        });

        return (
            <div key={idx} className={styles.column}>
                <div className={styles.label}>
                    {idx === infoLength - 1
                        ? status_label
                        : idx === 0 && contragent
                            ? (
                                <>
                                    {label}
                                    <TooltipCreator tooltip={{text: contragentData}}
                                                    classNames={styles.tooltip}
                                                    contentClassNames={styles['tooltip-content']}
                                                    zIndex={tooltipZIndex}
                                    />
                                </>
                            ) : label}
                </div>
                {idx === infoLength - 1
                    ? <StatusIndicator status={{label, color}} classNames={infoClasses}/>
                    : <div className={infoClasses}>{value}</div>
                }
            </div>
        );
    });

    return (
        <div className={styles.container}>
            {dataColumns}
        </div>
    );
};

ContractDashboard.propTypes = {
    staticData: PropTypes.object,
    data: PropTypes.shape({
        shortinfo: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            color: PropTypes.string,
        })),
        contragent: PropTypes.object,
    }),
    tooltipZIndex: PropTypes.number,
};

export default withDataService()(ContractDashboard);