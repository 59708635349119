import React from 'react';
import {Link} from 'react-router-dom';

import {hubURLs} from '../../../../../../services/api-urls';

import Accordion from '../../../../../common/accordion';

import styles from './with-accordion-pages.module.scss';

const withAccordionPages = () => (Wrapped) => {
    return (props) => {
        const {staticData: {activity_hub: {pages}}, label} = props;

        return pages.map((page, idx) => {
            // temporary static urls
            const pageURL = idx === 0 ? hubURLs.requests : hubURLs.e_contracts;
            const accordionLabel = (
                <Link to={pageURL} className={styles['accordion-link']}>
                    <div className={styles['accordion-link-text']}>{page}</div>
                    <div className={styles.arrow}/>
                </Link>
            );
            return (
                <div key={idx} className={styles.container}>
                    <Accordion
                        isOpen={page === label}
                        label={accordionLabel}
                        labelClassNames={styles['accordion-label']}
                        contentClassNames={styles['accordion-content']}
                        withAnimation={false}
                    >
                        <Wrapped {...props}/>
                    </Accordion>
                </div>
            );
        });
    };
};

export default withAccordionPages;