import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../../../common/hoc';
import sweetAlert from '../../../../../../../../../../common/sweet-alert';

import Preloader from '../../../../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './shipment-section.module.scss';

const ShipmentSection = (props) => {
    const {
        staticData, organizationsList, sectionId,
        removeOrganization: {id: removingOrganizationId, loading: removeOrganizationLoading},
        onRemoveShipmentOrganization,
    } = props;
    const {default_label, set_as_default, no_items_message} = staticData.activity_hub.profile.pages.shipment_setup;

    const onRemove = (id, href) => {
        const {delete_item_question} = staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    onRemoveShipmentOrganization(id, href);
                }
            });
    };

    return (
        <div className={styles.container} style={{pointerEvents: removingOrganizationId ? 'none' : 'auto'}}>
            {organizationsList.length !== 0
                ? organizationsList.map(({id, name, surname, isDefault, href}, idx) => {
                    const {edit, remove} = href;
                    const cx = classnames.bind(styles);
                    const labelClasses = cx('label', {'active': isDefault});
                    const label = !isDefault ? set_as_default : default_label;
                    const itemId = `${sectionId}_${id}`;

                    return (
                        <div id={itemId} key={idx} className={styles.card}>
                            {!isDefault
                                ? (
                                    <Link to={edit}>
                                        <div className={labelClasses}>{label}</div>
                                    </Link>
                                ) : <div className={labelClasses}>{label}</div>}

                            <div className={styles.content}>
                                <div className={styles.name}>{name}</div>
                                <div className={styles.surname}>{surname}</div>

                                <div className={styles.buttons}>
                                    {remove && <div className={styles.delete}
                                                    onClick={() => onRemove(itemId, remove)}/>}
                                    <Link to={edit}>
                                        <div className={styles.edit}/>
                                    </Link>
                                </div>

                                {(removeOrganizationLoading && itemId === removingOrganizationId)
                                && (
                                    <div className={styles['preloader-block']}>
                                        <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })
                : <div className={styles.message}>{no_items_message}</div>}
        </div>
    );
};

ShipmentSection.defaultProps = {
    onRemoveShipmentOrganization: () => {
    },
};

ShipmentSection.propTypes = {
    staticData: PropTypes.object,
    organizationsList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        surname: PropTypes.string,
        isDefault: PropTypes.number,
        href: PropTypes.shape({
            edit: PropTypes.string,
            remove: PropTypes.string,
        }),
    })),
    sectionId: PropTypes.string,
    removeOrganization: PropTypes.shape({
        id: PropTypes.number,
        loading: PropTypes.bool,
    }),
    onRemoveShipmentOrganization: PropTypes.func,
};

export default withAccordion()(ShipmentSection);