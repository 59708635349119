import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withServices} from '../../../../../../service-context';
import {withHubSideMenu} from '../../../../common/hoc';
import sweetAlert from '../../../../../../common/sweet-alert';
import {withDataService} from '../../../../../../common/hoc';
import {onCreateFormData} from '../../../../../../common/handlers';
import {updatingBreadcrumbsOnComponentAction} from '../../components/common/handlers/updating-breadcrumbs';
import {utilsAction} from '../../../../../../../actions/common';
import {switchMenuActiveLinkID, contractMapsAction} from '../../../../../../../actions/activity-hub';
import {
    originInvoiceRequest, updatingOriginInvoiceRequest,
} from '../../../../../../../reducers/activity-hub/contract-maps/origin-invoice-wizard/origin-invoice';
import {
    createOriginInvoiceRequest
} from '../../../../../../../reducers/activity-hub/contract-maps/origin-invoice-wizard/create-origin-invoice';
import {onGetAlertResponse, priceStringToNumber} from '../../../../../../../utils';

import OriginInvoiceWizard from './origin-invoice-wizard';
import Preloader from '../../../../../../common/preloader';

class OriginInvoiceWizardContainer extends Component {
    state = {
        invoiceID: null,
        linkToContractMap: '/hub',
    };

    componentDidMount() {
        const {match: {params: {id}}, location, pageID, menuActiveLinkID} = this.props;
        const linkToContractMap = location.pathname.replace('/invoice/create', '/map');

        this.props.fetchOriginInvoice(id);
        this.setState({invoiceID: id, linkToContractMap});

        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchOriginInvoiceUpdating(this.state.invoiceID);
        }

        if (prevProps.invoiceInitialData !== this.props.invoiceInitialData) {
            const {staticData: {breadcrumbs: {contract_for_supply_invoice}}, breadcrumbs: {list}} = this.props;
            const updatedList = updatingBreadcrumbsOnComponentAction(list, contract_for_supply_invoice.list);
            this.props.updateBreadcrumbs(updatedList);
        }

        if (prevProps.createInvoiceResponse !== this.props.createInvoiceResponse) {
            const {status, text} = onGetAlertResponse(this.props.createInvoiceResponse);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    if (status === 'success') {
                        this.props.history.replace(this.state.linkToContractMap);
                    }

                    this.props.resetCreateOriginInvoice();
                });
            }
        }
    }

    onGetTotalSum = (list) => {
        let total = 0;
        list.forEach(({row, value}) => {
            if (value) {
                const sumPrice = priceStringToNumber(row[4].value);
                total = total + sumPrice;
            }
        });

        return total;
    };

    onChangeCheckbox = ({target: {name, checked}}) => {
        const {invoiceData: {list, ...rest}} = this.props;
        const updatedList = list.map((item) => {
            if (item.name === name) {
                return {
                    ...item,
                    value: checked ? 1 : 0,
                };
            }

            return item;
        });

        const updatedTotal = this.onGetTotalSum(updatedList);

        let selectAllInvoiceItems = 1;
        if (updatedList.find((item) => !item.value)) {
            selectAllInvoiceItems = 0;
        }

        const isListBeSaved = updatedList.some(({value}) => value);

        this.props.updatingDataAfterToggleCheckbox({
            ...rest, list: updatedList, total: updatedTotal,
        }, selectAllInvoiceItems, isListBeSaved);
    };

    onChangeSelectAllInvoiceItems = ({target: {checked}}) => {
        const {invoiceData: {list, ...rest}, invoiceInitialData: {total}} = this.props;

        let updatedList = [];
        let updatedTotal = total;
        let isListBeSaved = false;

        if (list.length > 0) {
            updatedList = list.map((item) => {
                return {
                    ...item,
                    value: checked ? 1 : 0,
                };
            });

            updatedTotal = checked ? total : 0;
            isListBeSaved = checked;
        }

        const selectAllInvoiceItems = checked ? 1 : 0;

        this.props.updatingDataAfterToggleCheckbox({
            ...rest, list: updatedList, total: updatedTotal,
        }, selectAllInvoiceItems, isListBeSaved);
    };

    onQuantityChange = (quantity, id, error = false) => {
        let {
            invoiceData: {list, total, ...rest},
            selectAllInvoiceItems,
        } = this.props;

        if (quantity === '') {
            quantity = 0;
        }

        const updatedList = list.map((item) => {
            if (item.id === id) {
                // item.row[3].value -> price for one piece of product
                const priceForOnePiece = priceStringToNumber(item.row[3].value);
                const newSumPrice = priceForOnePiece * quantity;

                const newRow = item.row.map((rowItem, idx) => {
                    // item.row[2].value -> quantity for pieces of product
                    if (idx === 2) {
                        return {
                            ...rowItem,
                            value: quantity,
                        };
                    }

                    // item.row[4].value -> price for all pieces of product
                    if (idx === 4) {
                        return {
                            ...rowItem,
                            value: newSumPrice,
                        };
                    }

                    return rowItem;
                });

                return {
                    ...item,
                    quantity,
                    row: newRow,
                };
            }

            return item;
        });

        const updatedTotal = this.onGetTotalSum(updatedList);
        const isListBeSaved = !error && quantity !== 0;

        this.props.updatingDataAfterToggleCheckbox({
            ...rest, list: updatedList, total: updatedTotal,
        }, selectAllInvoiceItems, isListBeSaved);
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {invoiceID} = this.state;
        const {invoiceData: {list}, isInvoiceCanBeSaved} = this.props;

        if (isInvoiceCanBeSaved) {
            let object = {};
            list.forEach(({name, value, quantity}) => {
                if (value) {
                    object = {
                        ...object,
                        [name]: quantity,
                    };
                }
            });

            const formData = onCreateFormData(object);
            this.props.onSubmitOriginInvoice(invoiceID, {formData});
        }
    };

    onResetInvoiceData = () => {
        this.props.resetInvoiceToInitialState();
    };

    onCancel = () => {
        this.onResetInvoiceData();
        this.props.history.push(this.state.linkToContractMap);
    };

    componentWillUnmount() {
        this.onResetInvoiceData();
    }

    render() {
        const {
            staticData, invoiceInitialData, invoiceData, selectAllInvoiceItems,
            isInvoiceCanBeSaved, loading, updating, createInvoiceLoading,
        } = this.props;

        const commonProps = {
            staticData,
            invoiceInitialData,
            invoiceData,
            selectAllInvoiceItems,
            isCanBeSaved: isInvoiceCanBeSaved,
            createInvoiceLoading,
        };

        return (invoiceData && !loading && !updating)
            ? <OriginInvoiceWizard {...commonProps}
                                   onQuantityChange={this.onQuantityChange}
                                   onChangeCheckbox={this.onChangeCheckbox}
                                   onChangeSelectAllInvoiceItems={this.onChangeSelectAllInvoiceItems}
                                   onSubmit={this.onSubmit}
                                   onCancel={this.onCancel}/>
            : <Preloader/>;
    }

    static propTypes = {
        language: PropTypes.string,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        invoiceData: PropTypes.object,
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        createInvoiceResponse: PropTypes.object,
        switchMenuActiveLinkID: PropTypes.func,
        updateBreadcrumbs: PropTypes.func,
        fetchOriginInvoice: PropTypes.func,
        fetchOriginInvoiceUpdating: PropTypes.func,
        updatingDataAfterToggleCheckbox: PropTypes.func,
        onSubmitOriginInvoice: PropTypes.func,
        resetCreateOriginInvoice: PropTypes.func,
        resetInvoiceToInitialState: PropTypes.func,
    };
}

const mapServicesToProps = ({originInvoiceService}) => ({
    fetchOriginInvoice: originInvoiceRequest(originInvoiceService.getOriginInvoice),
    fetchOriginInvoiceUpdating: updatingOriginInvoiceRequest(originInvoiceService.getOriginInvoice),
    onSubmitOriginInvoice: createOriginInvoiceRequest(originInvoiceService.createOriginInvoice),
});

const mapStateToProps = ({profile: {language}, activityHub: {contractMaps: {invoice}}, utils}) => {
    const {
        originInvoice: {
            initialData, data, selectAllInvoiceItems,
            isInvoiceCanBeSaved, loading, updating, error,
        },
        createOriginInvoice,
    } = invoice;

    return {
        language,
        menuActiveLinkID: utils.menuActiveLinkID,
        breadcrumbs: utils.breadcrumbs,
        invoiceInitialData: initialData,
        invoiceData: data,
        selectAllInvoiceItems,
        isInvoiceCanBeSaved,
        loading: loading,
        updating: updating,
        error: error,
        createInvoiceLoading: createOriginInvoice.loading,
        createInvoiceResponse: createOriginInvoice,
    };
};

const mapDispatchToProps = (dispatch, {fetchOriginInvoice, fetchOriginInvoiceUpdating, onSubmitOriginInvoice}) => {
    const {
        updatingDataAfterToggleCheckbox,
        resetCreateOriginInvoice,
        resetInvoiceToInitialState,
    } = contractMapsAction.invoiceAction;
    const {updateBreadcrumbs} = utilsAction;

    return bindActionCreators({
        fetchOriginInvoice,
        fetchOriginInvoiceUpdating,
        switchMenuActiveLinkID,
        updateBreadcrumbs,
        updatingDataAfterToggleCheckbox,
        onSubmitOriginInvoice,
        resetCreateOriginInvoice,
        resetInvoiceToInitialState,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(OriginInvoiceWizardContainer);