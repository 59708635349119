const initialState = {
    menuActiveLinkID: null,
};

const utils = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case 'SWITCH_MENU_ACTIVE_LINK_ID':
            return {
                menuActiveLinkID: payload,
            };

        default:
            return state;
    }
};

export default utils;