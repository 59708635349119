import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';

import Select from '../select';
import Button from '../button';

import classnames from 'classnames/bind';
import styles from './button-menu.module.scss';

const ButtonMenu = ({staticData, classNames}) => {
    const {importer, exporter} = staticData.header.button_menu;

    const [isButtonMenuOpen, setIsButtonMenuOpen] = useState(false);
    const profile = useSelector(({profile: {profile}}) => (profile));

    useEffect(() => {
        window.addEventListener('resize', () => setIsButtonMenuOpen(false));

        return () => {
            window.removeEventListener('resize', () => setIsButtonMenuOpen(false));
        };
    });

    const cx = classnames.bind(styles);

    let menu = importer.menu;
    let buttonLabel = importer.btn_label;

    if (profile && profile.interaction === 'exporter') {
        menu = exporter.menu;
        buttonLabel = exporter.btn_label;
    }

    const menuItemList = menu.map(({label, href, isDisabled}, idx) => {
        const linkClasses = cx('link', {'disabled': isDisabled});

        return href
            ? <Link key={idx} to={href} className={linkClasses}
                    onClick={() => setIsButtonMenuOpen(false)}>{label}</Link>
            : <span key={idx} className={linkClasses} onClick={() => setIsButtonMenuOpen(false)}>{label}</span>;
    });

    return (
        <div className={classNames}>
            <Select
                selectedItem={(
                    <Button classNames={styles.btn}>
                        {buttonLabel}
                    </Button>
                )}
                isOpenSelect={isButtonMenuOpen}
                optionsClassName={styles['options-block']}
                onToggleSelect={(isOpen) => setIsButtonMenuOpen(isOpen)}
                withArrow
            >
                {menuItemList}
            </Select>
        </div>
    );
};

ButtonMenu.propTypes = {
    staticData: PropTypes.object,
    classNames: PropTypes.string,
};

export default withDataService()(ButtonMenu);