import {createRequestHandler} from '../../../../utils';
import {transformFieldData} from "../../../../components/activity-hub/components/common/handlers/field-handlers";
import {RESET_ACCESS_SETUP_DATA_UPLOAD} from "./upload-form-data";

export const ACCESS_SETUP_FORM_FIELDS_REQUEST = 'ACCESS_SETUP_FORM_FIELDS_REQUEST';
export const ACCESS_SETUP_FORM_FIELDS_SUCCESS = 'ACCESS_SETUP_FORM_FIELDS_SUCCESS';
export const ACCESS_SETUP_FORM_FIELDS_FAILURE = 'ACCESS_SETUP_FORM_FIELDS_FAILURE';

export const accessSetupFormFieldsRequest = createRequestHandler('ACCESS_SETUP_FORM_FIELDS');

const initialState = {
    loading: true,
    list: null,
    error: null,
};

const onInit = ({list}) => {
    return list.map(field => transformFieldData(field));
}

const formFields = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case ACCESS_SETUP_FORM_FIELDS_REQUEST:
            return initialState;

        case ACCESS_SETUP_FORM_FIELDS_SUCCESS:
            return {
                loading: false,
                list: onInit(payload),
                error: null,
            };

        case ACCESS_SETUP_FORM_FIELDS_FAILURE:
            return {
                loading: false,
                list: null,
                error: payload,
            };

        case RESET_ACCESS_SETUP_DATA_UPLOAD:
            return {
                loading: false,
                list: payload,
                error: null,
            }

        default:
            return state;
    }
};

export default formFields;