import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {resetMarketplaceProducts} from '../../../../../actions/marketplace';
import {updateSearchCategorySlug} from '../../../../../actions/common/search';
import {withServices} from '../../../../service-context';
import {categoriesRequest} from '../../../../../reducers/marketplace/home/categories';
import {categoryProductsRequest, updatingProductsRequest} from '../../../../../reducers/marketplace/products';

import CategoryPage from '../../../../marketplace/components/pages/category-page/category-page';

class CategoryPagePublicContainer extends Component {
    render() {
        const {session, ...rest} = this.props;
        if (!session && session !== null) {
            return <CategoryPage  {...rest}/>;
        }

        return null;
    }

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchCategories: categoriesRequest(publicService.getPublicCategories),
    fetchCategoryProducts: categoryProductsRequest(publicService.getPublicCategoryProducts),
    fetchUpdatingProducts: updatingProductsRequest(publicService.getPublicCategoryProducts),
});

const mapStateToProps = ({
                             session: {session},
                             publicData: {homePage: {language}},
                             marketplace: {products, categories: {categories}},
                         }) => {
    return ({
        session,
        products,
        categories,
        language,
    });
};

const mapDispatchToProps = (dispatch, {
    fetchCategories,
    fetchCategoryProducts,
    fetchUpdatingProducts,
}) => {
    return bindActionCreators({
        fetchCategories,
        fetchCategoryProducts,
        fetchUpdatingProducts,
        updateSearchCategorySlug,
        resetMarketplaceProducts,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(CategoryPagePublicContainer);