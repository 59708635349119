import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withServices} from '../../../../../../service-context';
import {withHubSideMenu} from '../../../../common/hoc';
import {withDataService} from '../../../../../../common/hoc';
import {
    productsListForPackingRequest,
    updatingProductsListForPackingRequest,
} from '../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/products-list-for-packing';
import {updatingBreadcrumbsOnComponentAction} from '../../components/common/handlers/updating-breadcrumbs';
import {contractMapsAction, switchMenuActiveLinkID} from '../../../../../../../actions/activity-hub';
import {utilsAction} from '../../../../../../../actions/common';

import PackingListWizard from './packing-list-wizard';
import Preloader from '../../../../../../common/preloader';

class PackingListWizardContainer extends Component {
    componentDidMount() {
        const {match: {params: {id}}, pageID, menuActiveLinkID} = this.props;

        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        this.props.fetchProductsListForPacking(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {match: {params: {id}}} = this.props;

            this.props.fetchProductsListForPackingUpdating(id);
        }

        if (prevProps.loading !== this.props.loading || prevProps.updating !== this.props.updating) {
            if (!this.props.loading || !this.props.updating) {
                const {staticData: {breadcrumbs: {contract_for_supply_packing_list}}, breadcrumbs: {list}} = this.props;
                const updatedList = updatingBreadcrumbsOnComponentAction(list, contract_for_supply_packing_list.list);
                this.props.updateBreadcrumbs(updatedList);
            }
        }
    }

    onToggleTab = ({target}) => {
        const {tabs} = this.props;

        const newTabs = tabs.map((tab) => {
            if (target.id === tab.id) {
                return {
                    ...tab,
                    isActive: true,
                };
            }

            return {
                ...tab,
                isActive: false,
            };
        });

        this.props.updateTabsForPacking(newTabs);
    };

    componentWillUnmount() {
        this.props.resetPackingListWizardPage();
    }

    render() {
        const {loading, updating, tabs, packedItemsCounter} = this.props;

        return (!loading && !updating && tabs)
            ? <PackingListWizard tabs={tabs} packedItemsCounter={packedItemsCounter} onToggleTab={this.onToggleTab}/>
            : <Preloader/>;
    }

    static propTypes = {
        language: PropTypes.string,
        breadcrumbs: PropTypes.shape({
            loading: PropTypes.bool,
            list: PropTypes.array,
        }),
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        tabs: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(PropTypes.object)]),
        packedItemsCounter: PropTypes.number,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        switchMenuActiveLinkID: PropTypes.func,
        fetchProductsListForPacking: PropTypes.func,
        fetchProductsListForPackingUpdating: PropTypes.func,
        updateBreadcrumbs: PropTypes.func,
        updateTabsForPacking: PropTypes.func,
        resetPackingListWizardPage: PropTypes.func,
    };
}

const mapServicesToProps = ({packingListWizardService}) => {
    const {getProductsListForPacking} = packingListWizardService;

    return {
        fetchProductsListForPacking: productsListForPackingRequest(getProductsListForPacking),
        fetchProductsListForPackingUpdating: updatingProductsListForPackingRequest(getProductsListForPacking),
    };
};

const mapStateToProps =
    ({
         profile: {language},
         activityHub: {
             contractMaps: {packingListWizard: {productsList}},
             utils,
         },
         utils: {breadcrumbs},
     }) => ({
        language,
        loading: productsList.loading,
        updating: productsList.updating,
        tabs: productsList.tabs,
        packedItemsCounter: productsList.packedItemsCounter,
        menuActiveLinkID: utils.menuActiveLinkID,
        breadcrumbs,
    });

const mapDispatchToProps = (dispatch, {
    fetchProductsListForPacking,
    fetchProductsListForPackingUpdating,
}) => {
    const {
        productListForPackingAction: {updateTabsForPacking},
        packingListAction: {resetPackingListWizardPage},
    } = contractMapsAction;

    return bindActionCreators({
        fetchProductsListForPacking,
        fetchProductsListForPackingUpdating,
        switchMenuActiveLinkID,
        updateBreadcrumbs: utilsAction.updateBreadcrumbs,
        updateTabsForPacking,
        resetPackingListWizardPage,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(PackingListWizardContainer);