import {createRequestHandler} from '../utils';

export const SEARCH_DATA_REQUEST = 'SEARCH_DATA_REQUEST';
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const SEARCH_DATA_FAILURE = 'SEARCH_DATA_FAILURE';

export const searchRequest = createRequestHandler('SEARCH_DATA', {
    setGlobalServerError: false,
});

const initialState = {
    list: null,
    loading: false,
    error: null,
    slug: {
        id: null,
        used: false,
    },
};

const search = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SEARCH_DATA_REQUEST:
            return {
                ...state,
                list: null,
                loading: true,
                error: null,
            };

        case SEARCH_DATA_SUCCESS:
            return {
                ...state,
                list: payload,
                loading: false,
                error: null,
            };

        case SEARCH_DATA_FAILURE:
            return {
                ...state,
                list: null,
                loading: false,
                error: payload,
            };

        case 'UPDATE_SEARCH_CATEGORY_SLUG':
            return {
                ...state,
                slug: payload,
            };

        case 'RESET_MARKETPLACE_PRODUCTS':
            const {id, used} = state.slug;
            if (id && !used) {
                return {
                    ...state,
                    slug: {
                        id: null,
                        used: false,
                    },
                };
            }

            return state;

        case 'RESET_SEARCH_DATA':
            return {
                ...state,
                list: null,
                loading: false,
                error: null,
            };

        default:
            return state;
    }
};

export default search;