import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const UNPACK_PACKED_PRODUCT_REQUEST = 'UNPACK_PACKED_PRODUCT_REQUEST';
export const UNPACK_PACKED_PRODUCT_SUCCESS = 'UNPACK_PACKED_PRODUCT_SUCCESS';
export const UNPACK_PACKED_PRODUCT_FAILURE = 'UNPACK_PACKED_PRODUCT_FAILURE';

export const unpackPackedProductRequest = createRequestHandler('UNPACK_PACKED_PRODUCT');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const unpackPackedProduct = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case UNPACK_PACKED_PRODUCT_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case UNPACK_PACKED_PRODUCT_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case UNPACK_PACKED_PRODUCT_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_UNPACK_PACKED_PRODUCT':
        case 'RESET_PACKING_LIST_WIZARD_PAGE_WITHOUT_TABS':
            return initialState;

        default:
            return state;
    }
};

export default unpackPackedProduct;