import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';

import Button from '../../../../../../../common/button';
import Dropzone from '../../../../../../../common/dropzone';
import Preloader from '../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './photo.module.scss';

const Photo = (props) => {
    const {
        staticData, formFields: {loading, updating},
        isDisabledSection, photos, alreadyUploadedPhotos,
        rejectedFiles, isSaveButtonDisabled, maxAmountOfFiles, deleteAlreadyUploadedPhoto,
        defaultProps, onDeletePhoto, onDeleteRejectedFile, onDeleteAlreadyExistPhoto, onSave, onSkip,
        onAcceptedFiles, onRejectedFiles, onLoadedFile, onLoadingFile,
    } = props;

    const maxCount = defaultProps.maxCount;
    const maxSize = defaultProps.maxSize;
    const photoTypes = defaultProps.mimes;
    const amountOfUploadedPhotos = alreadyUploadedPhotos.length + photos.length;

    const dropzoneHandlers = {onAcceptedFiles, onRejectedFiles, onLoadedFile, onLoadingFile};

    const {
        sections: {photo: {size_message, invalid_types_label, rejected_files_label, alt_label}},
        button: {skip, save},
    } = staticData.activity_hub.place_product.from_form;

    const cx = classnames.bind(styles);
    const isRejectedFiles = rejectedFiles[0].length !== 0 || rejectedFiles[1].length !== 0;
    const dropzoneClasses = cx('dropzone', {'dropzone-small': amountOfUploadedPhotos !== 0 || isRejectedFiles});

    const photoBlockHandler = (previewItems, onDelete, alreadyUploaded = false) => {
        return previewItems.map((item, idx) => {
            const loadingProcess = (item.isLoaded !== undefined && !item.isLoaded);

            let deletingProcess = false;
            if (alreadyUploaded && deleteAlreadyUploadedPhoto) {
                const {id, loading} = deleteAlreadyUploadedPhoto;
                deletingProcess = loading && item.file.id === id;
            }

            const imgWrapperClasses = cx('img-wrapper', {
                'with-hover': item.isLoaded === undefined || item.isLoaded,
            });
            const imgHidingContainerClasses = cx('hiding-container', {
                'show': loadingProcess || deletingProcess,
            });

            const imageSrc = alreadyUploaded ? `${process.env.REACT_APP_API_URL}${item.preview}` : item.preview;

            return (
                <div className={imgWrapperClasses} key={idx}>
                    <img src={imageSrc} alt={alt_label} className={styles.img}/>
                    <div className={imgHidingContainerClasses}>
                        <div className={styles['delete-btn']} onClick={() => {
                            if (alreadyUploaded) {
                                onDelete(item.file.id);
                            } else {
                                onDelete(idx);
                            }
                        }}/>
                        {
                            (loadingProcess || deletingProcess) &&
                            <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                        }
                    </div>
                </div>
            );
        });
    };

    const containerClasses = cx('container', {
        'without-min-height': isDisabledSection,
    });

    return (loading || updating)
        ? <Preloader type={'SECONDARY'} classNames={styles['main-preloader']}/>
        : (
            <div className={containerClasses}>
                {!isDisabledSection && (
                    <Dropzone {...dropzoneHandlers}
                              id={'photo'}
                              containerClassNames={dropzoneClasses}
                              fileTypes={photoTypes}
                              maxFiles={maxAmountOfFiles}
                              maxSize={maxSize}
                              isDisabled={amountOfUploadedPhotos >= maxCount}
                              multiple/>
                )}

                {(alreadyUploadedPhotos.length !== 0 || photos.length !== 0) && (
                    <div className={styles['photo-block']}>
                        {alreadyUploadedPhotos.length !== 0
                        && photoBlockHandler(alreadyUploadedPhotos, onDeleteAlreadyExistPhoto, true)}
                        {photos.length !== 0 && photoBlockHandler(photos, onDeletePhoto)}
                    </div>
                )}

                {(rejectedFiles[0].length !== 0 || rejectedFiles[1].length !== 0) && (
                    <>
                        <div className={styles.title}>{rejected_files_label}</div>
                        {rejectedFiles[0].length !== 0 && (
                            <>
                                <div
                                    className={styles.subtitle}>{`${size_message[0]} ${maxSize} ${size_message[1]}`}</div>
                                <div className={styles['photo-block']}>
                                    {photoBlockHandler(rejectedFiles[0], (idx) => onDeleteRejectedFile(idx, 0))}
                                </div>
                            </>
                        )}

                        {rejectedFiles[1].length !== 0 && (
                            <>
                                <div className={styles.subtitle}>{invalid_types_label}</div>
                                <div className={styles['photo-block']}>
                                    {photoBlockHandler(rejectedFiles[1], (idx) => onDeleteRejectedFile(idx, 1))}
                                </div>
                            </>
                        )}
                    </>
                )}

                {!isDisabledSection && (
                    <div className={styles['btn-group']}>
                        <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                        <Button onClick={onSave} isDisabled={isSaveButtonDisabled}>{save}</Button>
                    </div>
                )}
            </div>
        );
};

Photo.defaultProps = {
    isDisabledSection: false,
    onDeletePhoto: () => {
    },
    onDeleteRejectedFile: () => {
    },
    onSave: () => {
    },
};

Photo.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    isDisabledSection: PropTypes.bool,
    photos: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.array]),
    alreadyUploadedPhotos: PropTypes.oneOfType([PropTypes.array]),
    rejectedFiles: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.array]),
    isSaveButtonDisabled: PropTypes.bool,
    maxAmountOfFiles: PropTypes.number,
    defaultProps: PropTypes.shape({
        maxCount: PropTypes.number,
        maxSize: PropTypes.number,
        mimes: PropTypes.array,
    }),
    deleteAlreadyUploadedPhoto: PropTypes.object,
    onDeletePhoto: PropTypes.func,
    onDeleteRejectedFile: PropTypes.func,
    onDeleteAlreadyExistPhoto: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
};

export default withSectionPanel()(Photo);