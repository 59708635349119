import React from 'react';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';

import Image from '../../../../common/image';
import Button from '../../../../common/button';

import classnames from 'classnames/bind';
import styles from './article-card.module.scss';

import defaultImage from '../../../../../services/news-service/assets/default.png';

const ArticleCard = ({staticData, id, preview_image, href, title, content, classNames}) => {
    const {image_alt, button: {view}} = staticData;
    const cx = classnames.bind(styles);

    return (
        <div id={id} className={cx('container', classNames)}>
            <Link to={href}>
                <Image source={preview_image} alt={image_alt}/>
            </Link>
            <div className={styles.content}>
                <Link to={href} className={styles.title}>{title}</Link>
                <div>
                    <div className={styles.preview}>{parse(content)}</div>
                    <Link to={href}>
                        <Button styleType={'SECONDARY'}>
                            {view}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

ArticleCard.defaultProps = {
    preview_image: defaultImage,
};

ArticleCard.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.number,
    preview_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    href: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    classNames: PropTypes.string,
};

export default compose(
    withDataService('news'),
)(ArticleCard);