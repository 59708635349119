import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';
import {
    onChangeFieldValue,
    onChangeRadioButtonValue,
} from '../../../../../../common/handlers';
import {transformFieldData} from '../../../../../../common/handlers/field-handlers';
import {withServices} from '../../../../../../../../service-context';
import {
    shipmentListRequest,
    updatingShipmentListRequest,
} from '../../../../../../../../../reducers/activity-hub/profile/shipment-organization/shipment-list';
import {removeShipmentOrganizationRequest} from '../../../../../../../../../reducers/activity-hub/profile/shipment-organization/remove-shipment-organization';
import {profileShipmentOrganizationAction} from '../../../../../../../../../actions/activity-hub';
import {onGetAlertResponse} from '../../../../../../../../../utils';
import sweetAlert from '../../../../../../../../common/sweet-alert';

import ShipmentSetup from './shipment-setup';

class ShipmentSetupContainer extends Component {
    state = {
        list: null,
        organizations: [],
        removingOrganization: null,
        isCanBeUpdated: true,
    };

    componentDidMount() {
        const updatedList = this.fieldHandler();
        this.setState({list: updatedList});

        this.props.fetchShipmentList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchUpdatingShipmentList();
        }

        if (prevProps.list !== this.props.list) {
            if (this.state.isCanBeUpdated) {
                const updatedList = this.fieldHandler();
                this.setState({list: updatedList});
            }
        }

        if (prevState.list !== this.state.list) {
            if (this.state.isCanBeUpdated) {
                this.setState({isCanBeUpdated: false});

            } else {
                if (this.props.checkingFieldMode) {
                    const {type, name} = this.props.checkingFieldMode;
                    this.props.onCheckingRequiredField(type, name);
                }

                if (prevState.list.length !== 0 && this.state.list.length > 0) {
                    this.onUpdateReduxState();
                }
            }
        }

        if (prevProps.submitAction !== this.props.submitAction && this.props.submitAction) {
            this.onUpdateReduxState();
        }

        if (prevProps.shipmentList !== this.props.shipmentList) {
            if (this.props.shipmentList) {
                const updatedList = this.props.shipmentList.map((item) => {
                    return {
                        ...item,
                        isOpen: item.list.length > 0,
                    };
                });

                this.setState({organizations: updatedList});
            }
        }

        if (prevProps.removeShipmentOrganizationResponse !== this.props.removeShipmentOrganizationResponse) {
            const {status, text} = onGetAlertResponse(this.props.removeShipmentOrganizationResponse);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    this.setState({removingOrganization: null});
                    this.props.resetRemoveShipmentOrganization();
                });
            }
        }
    }

    fieldHandler = () => this.props.list.map((field) => transformFieldData(field));

    onToggleAccordion = (currentID) => {
        const newOrganizations = this.state.organizations.map((item) => {
            if (currentID === item.id) {
                return {
                    ...item,
                    isOpen: !item.isOpen,
                };
            }

            return item;
        });

        this.setState({organizations: newOrganizations});
    };

    onUpdateFieldsValue = (name, value) => {
        const list = onChangeFieldValue(this.state.list, name, value);
        this.setState({list});
    };

    onClickSelectItem = (fieldName, {value}) => this.onUpdateFieldsValue(fieldName, value);

    onRadioButtonChange = ({target: {id, name, value}}) => {
        let updatedFields = onChangeRadioButtonValue(this.state.list, id, name, value);
        updatedFields = onChangeFieldValue(updatedFields, name, value);
        this.setState({list: updatedFields});
    };

    onRemoveOrganization = (id, href) => {
        this.setState({removingOrganization: id});
        this.props.onRemoveShipmentOrganization(href);
    };

    onUpdateReduxState = () => {
        this.props.onUpdateList(this.props.id, this.state.list);
    };

    render() {
        const {
            staticData, userInteraction, shipmentListLoading,
            shipmentListUpdating, removeShipmentOrganizationLoading,
        } = this.props;

        const params = {
            staticData,
            userInteraction,
            shipmentListLoading,
            shipmentListUpdating,
            removeShipmentOrganizationLoading,
        };

        return <ShipmentSetup {...params} {...this.state}
                              onClickSelectItem={this.onClickSelectItem}
                              onRadioButtonChange={this.onRadioButtonChange}
                              onToggleAccordion={this.onToggleAccordion}
                              onRemoveOrganization={this.onRemoveOrganization}/>;
    }

    static propTypes = {
        id: PropTypes.string,
        list: PropTypes.array,
        language: PropTypes.string,
        checkingFieldMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        removeShipmentOrganizationResponse: PropTypes.object,
        fetchShipmentList: PropTypes.func,
        fetchUpdatingShipmentList: PropTypes.func,
        onRemoveShipmentOrganization: PropTypes.func,
        resetRemoveShipmentOrganization: PropTypes.func,
        onUpdateList: PropTypes.func,
        onCheckingRequiredField: PropTypes.func,
    };
}

const mapServicesToProps = ({profileService}) => ({
    fetchShipmentList: shipmentListRequest(profileService.getShipmentList),
    fetchUpdatingShipmentList: updatingShipmentListRequest(profileService.getShipmentList),
    onRemoveShipmentOrganization: removeShipmentOrganizationRequest(profileService.removeShipmentOrganization),
});

const mapStateToProps = ({profile: {language, userInteraction}, activityHub: {profile: {shipmentOrganization}}}) => ({
    language,
    userInteraction,
    shipmentList: shipmentOrganization.list.list,
    shipmentListLoading: shipmentOrganization.list.loading,
    shipmentListUpdating: shipmentOrganization.list.updating,
    shipmentListError: shipmentOrganization.list.error,
    removeShipmentOrganizationLoading: shipmentOrganization.remove.loading,
    removeShipmentOrganizationResponse: shipmentOrganization.remove,
});

const mapDispatchToProps = (dispatch, {
    fetchShipmentList,
    fetchUpdatingShipmentList,
    onRemoveShipmentOrganization,
}) => {
    const {resetRemoveShipmentOrganization} = profileShipmentOrganizationAction;

    return bindActionCreators({
        fetchShipmentList,
        fetchUpdatingShipmentList,
        onRemoveShipmentOrganization,
        resetRemoveShipmentOrganization,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(ShipmentSetupContainer);