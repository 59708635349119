export const resetUpdatedTheNumberOfItemsStatus = () => {
    return {type: 'RESET_UPDATED_THE_NUMBER_OF_ITEMS_STATUS'};
};

export const resetRemoveItemFromRFS = () => {
    return {type: 'RESET_REMOVE_ITEM_FROM_RFS'};
};

export const resetReplaceItemInRFS = () => {
    return {type: 'RESET_REPLACE_ITEM_IN_RFS'};
};

export const resetConfirmRFS = () => {
    return {type: 'RESET_CONFIRM_RFS'};
};

export const clearAllRFS = () => {
    return {type: 'CLEAR_ALL_RFS'};
};