import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import PropTypes from 'prop-types';

import FormFieldCreator from "../../../../common/handlers/form-field-creator";
import {hubURLs} from "../../../../../../../services/api-urls";
import {withDataService} from "../../../../../../common/hoc";
import {withHubSideMenu} from "../../../../common/hoc";
import {withServices} from "../../../../../../service-context";
import {onChangeFieldValue} from "../../../../common/handlers";
import {onCheckingPasswordFields, getDataFromFields} from "../../../../common/handlers/field-handlers";
import {onCreateFormData} from "../../../../../../common/handlers";
import {
    accessSetupFormFieldsRequest
} from "../../../../../../../reducers/activity-hub/profile/access-setup/form-fields";
import {
    accessSetupDataUploadRequest
} from "../../../../../../../reducers/activity-hub/profile/access-setup/upload-form-data";
import {onGetAlertResponse} from "../../../../../../../utils";
import {resetAccessSetupDataUpload} from "../../../../../../../actions/activity-hub/profile/common";
import sweetAlert from "../../../../../../common/sweet-alert";

import FormFieldConstructor from "../../../../../../common/form-field-constructor";
import Preloader from "../../../../../../common/preloader";
import Button from "../../../../../../common/button";

import styles from './access-setup.module.scss';

class AccessSetup extends Component {
    state = {
        id: 'access-setup-form',
        list: [],
        passwordError: false,
        passwordErrors: null,
        twoFactorErrors: null,
        isSaveButtonDisabled: true,
    };

    formFieldCreator = new FormFieldCreator();

    componentDidMount() {
        this.props.fetchFormFields();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchFormFields();
        }

        if (prevProps.formFields.list !== this.props.formFields.list) {
            if (this.props.formFields.list) {
                const transformArray = this.props.formFields.list.map(field => {
                    if (field.name.includes('password') && field.type !== 'password') {
                        return {
                            ...field,
                            type: 'password',
                        };
                    }

                    return field;
                });

                this.setState({list: transformArray});
            }
        }

        if (prevState.list !== this.state.list) {
            const {list, isSaveButtonDisabled} = this.state;
            /* check if changes were in the one of fields */
            if (list.length > 0) {
                let isDisabled = true;

                for (let i = 0; i < list.length; i++) {
                    const {name, value} = list[i];
                    const {formFields} = this.props;
                    const initFieldValue = formFields.list.find(field => field.name === name).value;
                    if (value !== initFieldValue) {
                        isDisabled = false;
                        break;
                    }
                }

                if (isSaveButtonDisabled !== isDisabled) {
                    this.setState({isSaveButtonDisabled: isDisabled});
                }
            }
        }

        if (prevState.passwordError !== this.state.passwordError) {
            const fieldsName = [];
            this.state.list.forEach(({name}) => {
                if (name.includes('password')) {
                    fieldsName.push(name);
                }
            });
            this.onSetResetError(fieldsName, this.state.passwordError);
        }

        if (prevProps.uploadFormData !== this.props.uploadFormData) {
            const {status, text} = onGetAlertResponse(this.props.uploadFormData);

            if (status !== '' && text !== '') {
                if (status === 'success') {
                    sweetAlert(status, text).then(() => {
                        /* reset success response message and
                        update access form fields with new data in the redux store */
                        this.props.resetAccessSetupDataUpload(this.state.list);
                    });

                } else {
                    const fieldNamesWithError = Object.keys(text);
                    const passwordError = fieldNamesWithError.find(name => name.includes('password')) !== undefined;
                    const passwordErrors = [];
                    const twoFactorErrors = [];

                    if (!this.state.passwordError && passwordError) {
                        this.setState({passwordError});
                    }

                    fieldNamesWithError.forEach(name => {
                        if (name.includes('password')) {
                            passwordErrors.push(...text[name]);

                        } else {
                            twoFactorErrors.push(text[name]);
                        }
                    });

                    this.setState({passwordErrors, twoFactorErrors});
                }
            }
        }
    }

    formFieldHandler = (formFields, listeners) => {
        const fields = this.formFieldCreator.getFormFields(formFields);
        return fields.map((field, idx) => <FormFieldConstructor {...listeners} key={idx} field={field}/>);
    };

    onInputChange = ({target: {name, value}}) => {
        if (this.state.passwordError) {
            this.setState({passwordError: false});
        }

        /* this case checking separately from first case because call only on input change */
        if (this.state.passwordErrors) {
            this.setState({passwordErrors: null});
        }

        let list = onChangeFieldValue(this.state.list, name, value);
        list = onCheckingPasswordFields(list, name);
        this.setState({list});
    };

    onInputFocus = () => {
        if (this.state.passwordError) {
            this.setState({passwordError: false});
        }
    }

    onSwitcherChange = ({target: {name, checked}}) => {
        const idx = this.state.list.findIndex(field => field.name === name);
        const list = [
            ...this.state.list.slice(0, idx),
            {
                ...this.state.list[idx],
                value: checked ? 1 : 0,
            },
            ...this.state.list.slice(idx + 1),
        ];

        if (this.state.twoFactorErrors) {
            this.setState({twoFactorErrors: null});
        }

        this.setState({list});
    };

    onSetResetError = (names, error = true) => {
        for (let name of names) {
            const element = document.querySelector(`[name='${name}']`);

            if (error) {
                element.parentNode.classList.add(styles.required);
            } else {
                element.parentNode.classList.remove(styles.required);
            }
        }
    };

    onCancel = () => this.props.history.push(hubURLs.profile);

    onSubmit = (e) => {
        e.preventDefault();
        const {list} = this.state;

        const passwordField = list.find(({name}) =>
            name.includes('password') && !name.includes('password_confirmation'));
        const passwordConfirmationField = list.find(({name}) => name.includes('password_confirmation'));
        const {correct} = passwordConfirmationField;

        /* password confirmation must be true, null or undefined
        * if password confirmation null or undefined then check password value === '' */
        let passwordValid = true;
        if (correct !== null && correct !== undefined) {
            passwordValid = correct;

        } else {
            if (passwordField.value) {
                passwordValid = false;
            }
        }

        if (passwordValid) {
            const data = getDataFromFields(list);
            const formData = onCreateFormData(data);
            this.props.onUploadFormData({formData});
        }

        if (!passwordValid) {
            this.setState({passwordError: true});
        }
    };

    componentWillUnmount() {
        const {message, error} = this.props.uploadFormData;
        if (message || error) {
            this.props.resetAccessSetupDataUpload();
        }
    }

    render() {
        const {list, passwordErrors, twoFactorErrors, isSaveButtonDisabled} = this.state;
        const {staticData, formFields: {loading, error}, uploadFormData} = this.props;
        const {activity_hub: {profile: {button: {cancel, save}}}, password_requirements} = staticData;

        const listeners = {
            onInputChange: this.onInputChange,
            onInputFocus: this.onInputFocus,
            onSwitcherChange: this.onSwitcherChange,
        };

        let passwordFields = [], switcherField = [];
        if (list.length > 0) {
            const fields = this.formFieldHandler(list, listeners);
            passwordFields = fields.slice(0, 2);
            switcherField = fields.slice(2);
        }

        const errorBlockHandler = (errorArray) => {
            return (
                <ul className={styles['error-block']}>
                    {errorArray.map((value, idx) => <li key={idx} className={styles.item}>{value}</li>)}
                </ul>
            );
        };

        return !loading && !error
            ? (
                <form id={this.state.id} className={styles.container} onSubmit={this.onSubmit}>
                    <div className={styles['password-block']}>
                        <div className={styles.password}>{passwordFields}</div>
                        <div className={styles.description}>
                            <ul className={styles.list}>
                                {password_requirements.text}
                                {password_requirements.list.map((item, idx) => <li key={idx}>{item}</li>)}
                            </ul>
                        </div>
                    </div>
                    {passwordErrors && errorBlockHandler(passwordErrors)}
                    <div className={styles['two-factor']}>
                        {switcherField}
                        {twoFactorErrors && errorBlockHandler(twoFactorErrors)}
                    </div>
                    <div className={styles['buttons-container']}>
                        <Button styleType={'SECONDARY'}
                                classNames={styles['cancel-btn']}
                                onClick={this.onCancel}>
                            {cancel}
                        </Button>
                        <Button type={'submit'}
                                classNames={styles['save-btn']}
                                isDisabled={isSaveButtonDisabled}
                                dataLoading={uploadFormData.loading}>
                            {save}
                        </Button>
                    </div>
                </form>
            ) : <Preloader/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        formFields: PropTypes.shape({
            loading: PropTypes.bool,
            list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
            error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
        }),
        uploadFormData: PropTypes.shape({
            loading: PropTypes.bool,
            message: PropTypes.object,
            error: PropTypes.object,
        }),
        fetchFormFields: PropTypes.func,
        onUploadFormData: PropTypes.func,
        resetAccessSetupDataUpload: PropTypes.func,
    };
}

const mapServicesToProps = ({profileService}) => ({
    fetchFormFields: accessSetupFormFieldsRequest(profileService.accessSetupForm),
    onUploadFormData: accessSetupDataUploadRequest(profileService.accessSetupForm)
});

const mapStateToProps = ({profile: {language}, activityHub: {profile}}) => ({
    language,
    formFields: profile.accessSetup.formFields,
    uploadFormData: profile.accessSetup.uploadFormData,
});

const mapDispatchToProps = (dispatch, {fetchFormFields, onUploadFormData}) => (
    bindActionCreators({
        fetchFormFields,
        onUploadFormData,
        resetAccessSetupDataUpload,
    }, dispatch)
);

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(AccessSetup);