import React, {useEffect, useState} from 'react';

import classnames from 'classnames/bind';
import styles from './scroll-to-top.module.scss';

const withScrollToTop = () => (Wrapped) => {
    return (props) => {
        const {
            loading = false, withScroll = true, scrollMode = 'smooth',
            userMode = false, allScreen = false, classNames = null,
            onCallback = () => {},
        } = props;
        const [hidden, setHidden] = useState(true);

        useEffect(() => {
            if (userMode) {
                const scrollHandler = () => {
                    const hidden = window.scrollY < window.innerHeight / 2;
                    setHidden(hidden);
                };

                window.addEventListener('scroll', scrollHandler);
                return () => window.removeEventListener('scroll', scrollHandler);
            }
        });

        const cx = classnames.bind(styles);
        const classes = cx('arrow-up', classNames, {
            'visible-always': allScreen,
            'hidden': hidden && userMode,
        });

        return (
            <>
                <Wrapped {...props}/>
                {(!loading && withScroll) && (
                    <div className={classes} onClick={(e) => {
                        e.stopPropagation();
                        if (scrollMode === 'fast') {
                            window.scrollTo({top: 0});
                        }
                        if (scrollMode === 'smooth') {
                            window.scrollTo({top: 0, behavior: 'smooth'});
                        }
                        onCallback();
                    }}/>
                )}
            </>
        );
    };
};

export default withScrollToTop;