import React from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';
import FormFieldCreator from '../../../../../../common/handlers/form-field-creator';

import FormFieldConstructor from '../../../../../../../../common/form-field-constructor';
import UploadDocumentsContainer from '../../../../../../common/upload-documents';

import styles from './organization-profile.module.scss';

const formFieldCreator = new FormFieldCreator();
const formFieldHandler = (formFields, listeners) => {
    const fields = formFieldCreator.getFormFields(formFields);

    return fields.map((field, idx) =>
        <FormFieldConstructor {...listeners} key={idx} field={field}/>,
    );
};

const OrganizationProfile = (props) => {
    const {
        list, onRadioButtonChange, onClickSelectItem,
        onInputChange, onInputPhoneChange, onDocumentsChange,
    } = props;

    if (!list) {
        return null;
    }

    const filterFields = (id) => list.filter(({sectionId}) => sectionId === id);

    const organizationFields = filterFields('organization_details');
    const authorisedSignatoryFields = filterFields('authorised_signatory_details');
    const addressFields = filterFields('address_details');
    const registrationDocuments = filterFields('registration_documents');
    const certificates = filterFields('certificates');
    const dropzoneFields = [registrationDocuments, certificates];

    const listeners = {
        onRadioButtonChange,
        onClickSelectItem,
        onInputChange,
        onInputPhoneChange,
    };

    let organizationDetailsBlock = [],
        authorisedSignatoryDetailsBlock = [],
        addressDetailsBlock = [];

    if (organizationFields.length > 0) {
        organizationDetailsBlock = formFieldHandler(organizationFields, listeners);
    }

    const organizationDetailsRadioBlock = organizationDetailsBlock.slice(0, 1);
    const organizationDetailsRestBlock = organizationDetailsBlock.slice(1);

    if (authorisedSignatoryFields.length > 0) {
        authorisedSignatoryDetailsBlock = formFieldHandler(authorisedSignatoryFields, listeners);
    }

    if (addressFields.length > 0) {
        addressDetailsBlock = formFieldHandler(addressFields, listeners);
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {organizationFields.length > 0 && organizationFields[0].section}
            </div>

            <div className={`${styles['grid-group']} ${styles['group-even-2']}`}>
                <div className={styles['radio-group']}>{organizationDetailsRadioBlock}</div>
                {organizationDetailsRestBlock}
            </div>

            <div className={styles.title}>
                {authorisedSignatoryFields.length > 0 && authorisedSignatoryFields[0].section}
            </div>

            <div className={`${styles['grid-group']} ${styles['group-even-2']}`}>
                {authorisedSignatoryDetailsBlock}
            </div>

            <div className={styles.title}>
                {addressFields.length > 0 && addressFields[0].section}
            </div>

            <div className={`${styles['grid-group']} ${styles['group-even-2']} ${styles['group-even-2-revert']}`}>
                {addressDetailsBlock}
            </div>

            {dropzoneFields.map((fields, idx) => (
                <UploadDocumentsContainer key={idx} fields={fields} onDocumentsChange={onDocumentsChange}/>
            ))}
        </div>
    );
};

OrganizationProfile.defaultProps = {
    onRadioButtonChange: () => {
    },
    onClickSelectItem: () => {
    },
    onInputChange: () => {
    },
};

OrganizationProfile.propTypes = {
    list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onRadioButtonChange: PropTypes.func,
    onClickSelectItem: PropTypes.func,
    onInputChange: PropTypes.func,
    onInputPhoneChange: PropTypes.func,
    onDocumentsChange: PropTypes.func,
};

export default withDataService()(OrganizationProfile);