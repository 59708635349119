import {challenges_en} from './en';
import {challenges_es} from './es';
import {challenges_fr} from './fr';
import {challenges_ru} from './ru';

export {
    challenges_en,
    challenges_es,
    challenges_fr,
    challenges_ru,
};