export const updatingBreadcrumbsOnComponentDidMount = (list, additionalList) => {
    /**
     * additional list always must have 2 items in array
     * 1 item - Home page link
     * 2 item - Current page label, link not active
     * **/

    if (!list.length) {
        return additionalList;
    }

    const transformList = list.map((item, idx) => {
        if (idx === list.length - 1) {
            return {
                ...item,
                isActive: true,
            };
        }
        return item;
    });

    return [...transformList, additionalList[1]];
};

export const updatingBreadcrumbsOnComponentDidUpdate = (list, additionalList) => {
    const transformList = list.map((item, idx) => {
        if (idx === list.length - 1) {
            return additionalList[1];
        }

        return item;
    });

    return transformList;
};

export const updatingBreadcrumbsOnComponentAction = (list, additionalList, mount = true) => {
    return mount
        ? updatingBreadcrumbsOnComponentDidMount(list, additionalList)
        : updatingBreadcrumbsOnComponentDidUpdate(list, additionalList);
};