import {combineReducers} from 'redux';

import bankAccountsList from './bank-accounts-list';
import bankAccountFormFields from './bank-account-form-fields';
import bankAccountActions from './bank-account-actions';
import defaultBankAccount from './default-bank-account';
import removeBankAccount from './remove-bank-account';

export const bankAccountReducer = combineReducers({
    list: bankAccountsList,
    formFields: bankAccountFormFields,
    actions: bankAccountActions,
    default: defaultBankAccount,
    remove: removeBankAccount,
});