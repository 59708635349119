import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService, withPagination} from '../common/hoc';

import Preloader from '../common/preloader';
import ArticleCard from './components/common/article-card/article-card';

import styles from './news.module.scss';

const News = ({staticData, list, loading}) => {
    const {title, no_articles} = staticData.news;

    return (
        <div className={styles['page-container']}>
            <h1 className={styles.title}>{title}</h1>
            {loading
                ? <Preloader/>
                : (list.length > 0
                        ? (
                            <div className={styles['grid-container']}>
                                {list.map((item, idx) => <ArticleCard key={idx} {...item}/>)}
                            </div>
                        ) : <div>{no_articles}</div>
                )}
        </div>
    );
};

News.propTypes = {
    staticData: PropTypes.object,
    list: PropTypes.array,
    loading: PropTypes.bool,
};

export default compose(
    withDataService(),
    withPagination(),
)(News);