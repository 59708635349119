import React from 'react';
import PropTypes from 'prop-types';

import {onGetButtonMethod} from '../../../../../../../../common/button-constructor/handlers';
import {useHistory} from 'react-router-dom';

import StatusIndicator from '../../../../../../common/status-indicator';
import ButtonConstructor from '../../../../../../../../common/button-constructor';
import DocumentLink from '../../../../../../../../common/document-link';

import classnames from 'classnames/bind';
import styles from './contract-information-item.module.scss';

const ContractInformationItem = (props) => {
    const {classNames, label, href, date, status, days_left, buttons} = props;
    const history = useHistory();
    const cx = classnames.bind(styles);

    let actionButton = null;
    if (buttons.length) {
        actionButton = buttons.map((button, idx) => (
            <ButtonConstructor {...button} key={idx}
                               onClick={({url, target}) => onGetButtonMethod(url, target, history)}/>
        ));
    }

    return (
        <div className={`${styles.container} ${classNames}`}>
            <div className={styles.column}>
                <DocumentLink data={{href, label, status}}/>
            </div>
            <div className={styles.column}>{date}</div>
            <StatusIndicator status={status} classNames={cx('column', 'status')}/>
            <div className={cx('column', 'days')}>{days_left}</div>
            <div className={cx('column', 'button-block')}>{actionButton}</div>
        </div>
    );
};

ContractInformationItem.propTypes = {
    classNames: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.object,
    days_left: PropTypes.string,
    buttons: PropTypes.array,
};

export default ContractInformationItem;