import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../common/hoc';
import {withServices} from '../../service-context';
import {countiesPublicRequest} from '../../../reducers/public-data/countries';
import {onCreateFormData} from '../../common/handlers';
import sweetAlert from '../../common/sweet-alert';

import SignUp from './sign-up';

import styles from './sign-up.module.scss';

class SignUpContainer extends Component {
    initialState = {
        first_name: null,
        last_name: null,
        email: null,
        organization_name: null,
        country: null,
        telephone: null,
        interaction: [],
        interest: [],
        first_name_error: null,
        last_name_error: null,
        email_error: null,
        organization_name_error: null,
        country_error: null,
        telephone_error: null,
        common_error: null,
        privacy_policy: false,
    };

    state = {
        ...this.initialState,
        updateCountry: false,
    };

    componentDidMount() {
        this.initInteraction();
        this.initInterest();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            if (this.props.countries.list.length > 0) {
                this.setState({updateCountry: true});
            }
        }

        if (prevProps.signUpActive !== this.props.signUpActive) {
            this.setState(this.initialState);
            this.initInteraction();
            this.initInterest();

            if (this.state.updateCountry) {
                if (this.props.signUpActive) {
                    this.props.fetchCounties();
                }

                if (prevProps.signUpActive) {
                    this.setState({updateCountry: false});
                }
            }

            if (this.props.signUpActive && this.props.countries.list.length === 0) {
                this.props.fetchCounties();
            }
        }

        if (prevProps.message !== this.props.message && this.props.message) {
            sweetAlert('success', this.props.message)
                .then(() => this.props.onClose());
        }

        if (prevProps.error !== this.props.error) {
            const {error} = this.props;
            if (error) {
                if (error.country && this.props.countries.list.length === 0) {
                    this.props.fetchCounties();
                }

                if (typeof error === 'string') {
                    this.setState({common_error: error});
                }

                if (Object.keys(error).length > 0) {
                    for (const key in error) {
                        if (error.hasOwnProperty(key)) {
                            let message = error[key];
                            if (Array.isArray(error[key])) {
                                message = [];
                                for (const item in error[key]) {
                                    if (error[key].hasOwnProperty(item)) {
                                        message.push(error[key][item]);
                                    }
                                }
                            }

                            this.setState({[`${key}_error`]: message});
                        }
                    }
                }
            } else {
                this.setState({
                    first_name_error: null,
                    last_name_error: null,
                    email_error: null,
                    organization_name_error: null,
                    country_error: null,
                    telephone_error: null,
                    common_error: null,
                });
            }
        }
    }

    initInteraction = () => {
        const {role} = this.props.staticData;
        const interaction = [
            role[0],
            {id: 'importer_role', label: role[1], value: 'importer', isChecked: true},
            {id: 'exporter_role', label: role[2], value: 'exporter', isChecked: false},
        ];

        this.setState({interaction});
    };

    initInterest = () => {
        const {interest_status} = this.props.staticData;
        const interest = [
            {id: 'marketplaceMembership', label: interest_status[0], value: 1, isChecked: true},
            {id: 'independentTechnicalSurveying', label: interest_status[1], value: 2, isChecked: false},
            {id: 'tradeFinanceServices', label: interest_status[2], value: 3, isChecked: false},
        ];

        this.setState({interest});
    };

    onInputChange = ({target: {name, value}}) => {
        this.setState({
            [name]: value,
        });

        this.onResetError(name);
    };

    onInputFocus = ({target: {name}}) => {
        this.onResetError(name);
    };

    onResetError = (name) => {
        if (this.state.common_error) {
            this.setState({
                common_error: null,
            });
        }

        if (this.state[`${name}_error`]) {
            this.setState({
                [`${name}_error`]: null,
            });
        }
    };

    onSelectCountry = (name, value) => {
        this.onResetError(name);
        this.setState({[name]: value});
    };

    onToggleRadioBtn = ({target}) => {
        const newInteraction = this.state.interaction.map((option) => {
            if (typeof option !== 'string') {
                return {
                    ...option,
                    isChecked: target.id === `${option.id}:${option.value}`,
                };
            }

            return option;
        });

        this.setState({interaction: newInteraction});
    };

    onCheckboxChange = ({target}) => {
        const newInterest = this.state.interest.map((option) => {
            if (target.id === option.id) {
                return {
                    ...option,
                    isChecked: !option.isChecked,
                };
            }

            return option;
        });

        this.setState({interest: newInterest});
    };

    onCheckboxPrivacyChange = ({target: {checked}}) => this.setState({privacy_policy: checked});

    onSubmit = (e) => {
        e.preventDefault();
        const {
            first_name, last_name, email, organization_name,
            country, telephone, interaction, interest,
        } = this.state;
        const {required_field_error_message, email_field_error_message} = this.props.staticData;

        let valid = true;

        if (!first_name) {
            valid = false;
            this.setState({first_name_error: required_field_error_message});
        }

        if (!last_name) {
            valid = false;
            this.setState({last_name_error: required_field_error_message});
        }

        if (!email) {
            valid = false;
            this.setState({email_error: required_field_error_message});
        } else {
            if (!email.includes('@')) {
                valid = false;
                this.setState({email_error: email_field_error_message});
            }
        }

        if (!organization_name) {
            valid = false;
            this.setState({organization_name_error: required_field_error_message});
        }

        if (!country && this.props.countries.list.length > 0) {
            valid = false;
            this.setState({country_error: required_field_error_message});
        }

        if (!telephone) {
            valid = false;
            this.setState({telephone_error: required_field_error_message});
        }

        if (valid) {
            const selectedInteraction = interaction.find(item => item.isChecked).value;
            const interestsArray = interest
                .filter(item => item.isChecked)
                .map(item => item.value);

            const dataObj = {
                first_name,
                last_name,
                email,
                organization_name,
                country,
                telephone,
                [`interest[]`]: interestsArray,
                interaction: selectedInteraction,
            };

            const formData = onCreateFormData(dataObj);
            this.props.onSubmit({formData});
        }
    };

    render() {
        const {signUpActive, ...otherProps} = this.props;

        return <SignUp {...otherProps} {...this.state}
                       modalClass={styles.modal}
                       closeBtnColorMobile="WHITE"
                       onInputChange={this.onInputChange}
                       onInputFocus={this.onInputFocus}
                       onSelectCountry={this.onSelectCountry}
                       onToggleRadioBtn={this.onToggleRadioBtn}
                       onCheckboxChange={this.onCheckboxChange}
                       onCheckboxPrivacyChange={this.onCheckboxPrivacyChange}
                       onSubmit={this.onSubmit}
                       isShown={signUpActive}
                       closeButton/>;
    }

    static propTypes = {
        signUpActive: PropTypes.bool,
        countries: PropTypes.object,
        language: PropTypes.string,
        fetchCounties: PropTypes.func,
        onSubmit: PropTypes.func,
        onClose: PropTypes.func,
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchCounties: countiesPublicRequest(publicService.getPublicData),
});

const mapStateToProps = (props) => {
    const {
        registration: {loading, message, error, signUpActive},
        publicData: {countries, homePage: {language}},
    } = props;

    return {
        signUpActive,
        countries: countries.data,
        language,
        loading,
        message,
        error,
    };
};

const mapDispatchToProps = (dispatch, {fetchCounties}) => (
    bindActionCreators({
        fetchCounties,
    }, dispatch)
);

export default compose(
    withDataService('authorization.sign_up'),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(SignUpContainer);