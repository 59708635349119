import React from 'react';
import PropTypes from 'prop-types';

import Chip from '../../chip';
import SkeletonPreloader from '../../skeleton-preloader';

import classnames from "classnames/bind";
import styles from './filters-active.module.scss';

const FiltersActive = (props) => {
    const {
        staticData, filterOptions, isSkeletonPreloader,
        canResetPnumFilter, toggleOptions, onReset, isUpdating,
    } = props;
    const {reset_all, reset_pnum} = staticData.filters_sorting_button;

    const listOfOptions = filterOptions
        .filter(filter => filter.options && filter.options.length !== 0)
        .map(filter => filter.options)
        .map(options => options
            .map((option) => option.isActive && option)
            .filter(option => option));

    let activeFilterOptions = [];
    for (let options of listOfOptions) {
        options.forEach(({name, count, href, isActive}, idx) => {
            activeFilterOptions.push(
                <SkeletonPreloader type={'TEXT'}
                                   loading={isSkeletonPreloader}
                                   classNames={styles['option-item']}>
                    <Chip
                        key={idx}
                        isActive={isActive}
                        isDisable={isUpdating}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleOptions(href);
                        }}>
                        {name} ({count})
                    </Chip>
                </SkeletonPreloader>,
            );
        });
    }

    const cx = classnames.bind(styles);
    const resetBtnClasses = cx('reset-btn', {
        'reset-pnum-btn': canResetPnumFilter && activeFilterOptions.length === 0,
    });
    const resetBtnLabel = canResetPnumFilter && activeFilterOptions.length === 0
        ? reset_pnum
        : reset_all;

    return (
        <div className={styles.container}>
            <div className={styles['options-block']}>{activeFilterOptions}</div>
            {(activeFilterOptions.length > 0 || canResetPnumFilter) && !isSkeletonPreloader &&
                <div className={resetBtnClasses} onClick={() => onReset(canResetPnumFilter)}>{resetBtnLabel}</div>}
        </div>
    );
};

FiltersActive.defaultProps = {
    isSkeletonPreloader: false,
    canResetPnumFilter: false,
    toggleOptions: () => {
    },
    onReset: () => {
    },
};

FiltersActive.propTypes = {
    staticData: PropTypes.object,
    filterOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })),
    isSkeletonPreloader: PropTypes.bool,
    canResetPnumFilter: PropTypes.bool,
    toggleOptions: PropTypes.func,
    onReset: PropTypes.func,
};

export default FiltersActive;