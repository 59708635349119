import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {onInitSectionStateFromDataByServer, sectionSettingsHandler} from '../../common/handlers';
import {checkingEqualityOfFiles, dropzonePropsTransformer} from '../../../../../common/handlers';
import sweetAlert from '../../../../../../../common/sweet-alert';
import uploadFileHandler from '../../../../../../../../utils/upload-file-handler';

import ProductGuides from './product-guides';

class ProductGuidesContainer extends Component {
    state = {
        id: null,
        formDataKey: null,
        documents: null,
        tmpDocuments: null,
        isDisabledSection: true,
        isDisabledAlwaysSection: false,
        isSaveButtonDisabled: true,
        defaultProps: {
            maxCount: 3,
            maxSize: 10,
            mimes: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.jpg', '.jpeg'],
        },
        deletedFileID: null,
    };

    componentDidMount() {
        const newDocuments = {
            alreadyUploadedFiles: [],
            files: [],
            rejectedFiles: [],
            maxFiles: 3,
            maxSize: 10,
        };

        this.setState({
            id: this.props.id,
            tmpDocuments: newDocuments,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (this.props.sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, this.props.sections);

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }

        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                const productGuidesArray = this.props.list.filter(({section}) => section.id === 'product_guides_and_documents');
                const productGuides = productGuidesArray[0];
                const props = productGuides.props.map(props => dropzonePropsTransformer(props));

                let newDefaultProps = {};
                for (let item of props) {
                    newDefaultProps = {
                        ...newDefaultProps,
                        ...item,
                    };
                }

                const newDocuments = {
                    ...this.state.tmpDocuments,
                    alreadyUploadedFiles: productGuides.value,
                    maxFiles: newDefaultProps.maxCount - productGuides.value.length,
                    maxSize: newDefaultProps.maxSize,
                };

                if (this.props.pageMode === 'EDIT') {
                    const isDisabled = onInitSectionStateFromDataByServer(productGuidesArray);
                    if (isDisabled !== this.state.isDisabledSection) {
                        this.props.updatedPlaceProductSectionDisabledStatus(this.state.id, isDisabled);
                    }
                }

                this.setState({
                    formDataKey: productGuides.name,
                    defaultProps: newDefaultProps,
                    documents: newDocuments,
                    tmpDocuments: newDocuments,
                });
            }
        }

        if (prevState.documents !== this.state.documents) {
            if (this.state.documents) {
                let isDisabled = true;
                if (this.state.documents.files.length > 0) {
                    isDisabled = false;

                } else {
                    const {documents} = prevState;
                    if (documents && documents.files.length > 0) {
                        isDisabled = false;
                    }
                }

                if (this.state.isSaveButtonDisabled !== isDisabled) {
                    this.setState({isSaveButtonDisabled: isDisabled});
                    this.props.onUpdateSaveStatus(this.state.id, isDisabled);
                }
            }
        }

        if (prevProps.fetchingDataFromSection !== this.props.fetchingDataFromSection) {
            const {fetchingDataFromSection} = this.props;

            if (fetchingDataFromSection && fetchingDataFromSection.currentSection === this.state.id) {
                this.onUploadDataFromSection();
            }
        }

        if (prevProps.removeAttachment.loading !== this.props.removeAttachment.loading) {
            if (prevProps.removeAttachment.loading && this.state.deletedFileID) {
                this.setState({deletedFileID: null});
            }
        }
    }

    onAcceptedFiles = (acceptedFiles) => {
        const {tmpDocuments} = this.state;
        const {same_name_message} = this.props.staticData.activity_hub.place_product.from_form.sections.product_guides_and_documents;
        let newDocuments = tmpDocuments;

        if (tmpDocuments.files.length !== 0) {
            const files = tmpDocuments.files.map(({file}) => file);
            const {
                files: newAcceptedFiles, infoData,
            } = checkingEqualityOfFiles(files, acceptedFiles);

            if (infoData && infoData.length !== 0) {
                const message = (
                    <div>
                        {same_name_message[0]}
                        <ul>{infoData.map((item, idx) => <li key={idx}>{`- ${item}`}</li>)}</ul>
                        {same_name_message[1]}
                    </div>
                );

                sweetAlert('info', message);
            }

            if (newAcceptedFiles.length !== 0) {
                uploadFileHandler(newAcceptedFiles, this.onLoadingFile, this.onLoadedFile);

                const newFiles = [
                    ...tmpDocuments.files,
                    ...newAcceptedFiles,
                ];

                newDocuments = {
                    ...tmpDocuments,
                    files: newFiles,
                    maxFiles: tmpDocuments.maxFiles - newAcceptedFiles.length,
                };
            }

        } else {
            uploadFileHandler(acceptedFiles, this.onLoadingFile, this.onLoadedFile);

            newDocuments = {
                ...tmpDocuments,
                files: acceptedFiles,
                maxFiles: tmpDocuments.maxFiles - acceptedFiles.length,
            };
        }

        this.setState({tmpDocuments: newDocuments});
    };

    onRejectedFiles = (files) => {
        const {tmpDocuments, defaultProps} = this.state;
        const {quantity_message} = this.props.staticData.activity_hub.place_product.from_form.sections.product_guides_and_documents;
        let newDocuments = {};
        let rightFiles = files.filter(({errors}) => errors[0].code === 'too-many-files');

        if (rightFiles.length !== 0) {
            const {alreadyUploadedFiles, files} = tmpDocuments;
            const {maxCount} = defaultProps;
            const maxAmountOfFiles = tmpDocuments.maxFiles;

            let infoMessage = `${quantity_message[0]} ${maxCount} ${quantity_message[1]}`;

            const amountOfUploadedFiles = files.length + alreadyUploadedFiles.length;
            if (amountOfUploadedFiles !== 0) {
                infoMessage = `${quantity_message[0]} ${maxCount} ${quantity_message[1]}
                ${quantity_message[2]} ${maxCount - maxAmountOfFiles} ${quantity_message[3]}`;
            }

            sweetAlert('info', infoMessage);
        } else {
            if (tmpDocuments.rejectedFiles.length !== 0) {
                const newFiles = [
                    ...tmpDocuments.rejectedFiles,
                    ...files,
                ];

                newDocuments = {
                    ...tmpDocuments,
                    rejectedFiles: newFiles,
                };

            } else {
                newDocuments = {
                    ...tmpDocuments,
                    rejectedFiles: files,
                };
            }

            this.setState({
                tmpDocuments: newDocuments,
                documents: newDocuments,
            });
        }
    };

    onLoadingFile = (file, progress) => {
        const {tmpDocuments} = this.state;
        const currentFileIdx = tmpDocuments.files.findIndex((document) => document === file);
        const currentFile = tmpDocuments.files[currentFileIdx];

        const newDocuments = {
            ...tmpDocuments,
            files: [
                ...tmpDocuments.files.slice(0, currentFileIdx),
                {
                    file: currentFile,
                    progress,
                    isLoaded: false,
                },
                ...tmpDocuments.files.slice(currentFileIdx + 1),
            ],
        };

        this.setState({tmpDocuments: newDocuments});
    };

    onLoadedFile = (file) => {
        const {tmpDocuments} = this.state;
        const currentFileIdx = tmpDocuments.files.findIndex((document) => document.file === file);
        const currentFile = tmpDocuments.files[currentFileIdx];

        const newDocuments = {
            ...tmpDocuments,
            files: [
                ...tmpDocuments.files.slice(0, currentFileIdx),
                {
                    ...currentFile,
                    isLoaded: true,
                },
                ...tmpDocuments.files.slice(currentFileIdx + 1),
            ],
        };

        this.setState({
            tmpDocuments: newDocuments,
            documents: newDocuments,
        });
    };

    onDeleteFile = (currentIdx) => {
        const {tmpDocuments} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newFiles = [
                        ...tmpDocuments.files.slice(0, currentIdx),
                        ...tmpDocuments.files.slice(currentIdx + 1),
                    ];

                    const newDocuments = {
                        ...tmpDocuments,
                        files: newFiles,
                        maxFiles: tmpDocuments.maxFiles + 1,
                    };

                    this.setState({
                        tmpDocuments: newDocuments,
                        documents: newDocuments,
                    });
                }
            });
    };

    onDeleteRejectedFile = (currentIdx) => {
        const {tmpDocuments} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newFiles = [
                        ...tmpDocuments.rejectedFiles.slice(0, currentIdx),
                        ...tmpDocuments.rejectedFiles.slice(currentIdx + 1),
                    ];

                    const newDocuments = {
                        ...tmpDocuments,
                        rejectedFiles: newFiles,
                    };

                    this.setState({
                        tmpDocuments: newDocuments,
                        documents: newDocuments,
                    });
                }
            });
    };

    onDeleteAlreadyExistFile = (id) => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.onDeleteAlreadyUploadedFile(this.state.formDataKey, id);
                    this.setState({deletedFileID: id});
                }
            });
    };

    onUploadDataFromSection = () => {
        let data = null;
        if (this.state.documents.files.length > 0) {
            const files = this.state.documents.files.map(({file}) => file);
            data = {[this.state.formDataKey]: {files}};
        }

        this.props.onSave(this.state.id, data);
        this.setState({isSaveButtonDisabled: true});
    };

    onProductGuidesSave = () => {
        this.onUploadDataFromSection();
    };

    render() {
        const {product_guides_and_documents} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {removeAttachment: {loading}, ...restProps} = this.props;
        const {id, deletedFileID, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;
        const deleteAlreadyUploadedFile = deletedFileID ? {id: deletedFileID, loading} : null;

        return <ProductGuides {...restProps} {...rest}
                              id={id}
                              panelData={product_guides_and_documents}
                              isDisabled={isDisabledSection}
                              isDisabledAlways={isDisabledAlwaysSection}
                              deleteAlreadyUploadedFile={deleteAlreadyUploadedFile}
                              onAcceptedFiles={this.onAcceptedFiles}
                              onRejectedFiles={this.onRejectedFiles}
                              onDeleteFile={this.onDeleteFile}
                              onDeleteRejectedFile={this.onDeleteRejectedFile}
                              onDeleteAlreadyExistFile={this.onDeleteAlreadyExistFile}
                              onClick={() => this.props.onClick(id)}
                              onSave={this.onProductGuidesSave}
                              onSkip={() => this.props.onSkip(id)}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onClick: () => {},
        onSave: () => {},
        onUpdateSaveStatus: () => {},
        onDeleteAlreadyUploadedFile: () => {},
    };

    static propTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        sections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        fetchingDataFromSection: PropTypes.object,
        removeAttachment: PropTypes.shape({
            loading: PropTypes.bool,
        }),
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
        onUpdateSaveStatus: PropTypes.func,
        onDeleteAlreadyUploadedFile: PropTypes.func,
        updatedPlaceProductSectionDisabledStatus: PropTypes.func,
    };
}

export default ProductGuidesContainer;