import React from 'react';
import PropTypes from 'prop-types';

import {withModal} from '../../../../../../common/hoc';

import ProductImageSlider from '../product-image-slider';

import classnames from 'classnames/bind';
import styles from './popup-image-slider.module.scss';

const PopupImageSlider = ({name, price, currency, session, ...otherProps}) => {
    const cx = classnames.bind(styles);
    return (
        <div className={styles.container}>
            <div className={styles['title-block']}>
                <div className={cx('title', {'with-bottom-indent': !session})}>{name}</div>
                {session && <div className={styles.subtitle}>{price} {currency}</div>}
            </div>

            <ProductImageSlider {...otherProps}/>
        </div>
    );
};

PopupImageSlider.propTypes = {
    name: PropTypes.string,
    price: PropTypes.node,
    currency: PropTypes.string,
    session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default withModal()(PopupImageSlider);