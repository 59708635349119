import React from 'react';
import PropTypes from 'prop-types';

import Select from '../select';
import Preloader from '../preloader';

import classnames from 'classnames/bind';
import styles from './language-menu.module.scss';

const LanguageMenu = (props) => {
    const {
        loading, language, languagesList, selectOpen,
        classNames, toggleLanguageSelect, onSwitchLanguage,
    } = props;

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames);

    return languagesList.length > 0 ? (
        <div className={containerClasses}>
            <Select selectedItem={language}
                    isOpenSelect={selectOpen}
                    selectedItemClasses={styles['selected-item']}
                    optionsClassName={cx('options-block', {'loading': loading})}
                    onToggleSelect={(isOpen) => toggleLanguageSelect(isOpen)}
                    offsetWithArrow={[10, 16]}
                    placement={'bottom-end'}
                    withArrow>
                {loading && (
                    <div className={styles['hiding-container']}>
                        <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                    </div>
                )}

                {languagesList.map(({code, label, isActive}, idx) => {
                    const selectedClasses = cx('item', {'active-item': isActive});

                    return (
                        <li key={idx} className={selectedClasses}
                            onClick={() => !isActive && onSwitchLanguage(code)}>
                            <div className={styles.abbr}>{code}</div>
                            {label}
                        </li>
                    );
                })}
            </Select>
        </div>
    ) : (
        <div className={containerClasses}>
            <div className={styles['selected-item']}>{language}</div>
        </div>
    );
};

LanguageMenu.defaultProps = {
    toggleLanguageSelect: () => {},
    onSwitchLanguage: () => {},
};

LanguageMenu.propTypes = {
    language: PropTypes.string,
    languagesList: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        label: PropTypes.string,
        isActive: PropTypes.bool,
    })),
    selectOpen: PropTypes.bool,
    classNames: PropTypes.string,
    toggleLanguageSelect: PropTypes.func,
    onSwitchLanguage: PropTypes.func,
};

export default LanguageMenu;