import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';

import Button from '../../../../common/button';

import classnames from 'classnames/bind';
import styles from './product-panel.module.scss';

const ProductPanel = (props) => {
    const {
        id, owner, condition_value,
        part_number, name, offer, available,
        delivery_time, href, staticData, session,
    } = props;

    const {formatted_supply_condition, organization_name, price, currency} = offer;

    const {
        delivery_time_label,
        exporter_label,
        supply_conditions_label,
        button: {view_product},
    } = staticData.marketplace.product_panel;

    const cx = classnames.bind(styles);

    const availabilityStatusClasses = available && cx('availability', {
        'in-stock': available.value === 'in_stock',
        'out-of-stock': available.value === 'out_stock',
    });
    const itemClasses = cx('item', {'self': owner && owner === 'self'});

    const xsDevice = useMediaQuery({maxWidth: 575.9});

    return (
        <div id={id} className={itemClasses}>
            <div className={styles['main-info']}>
                <div className={cx('column', 'title-block')}>
                    <Link to={href} className={styles.title}>{part_number}</Link>
                    <div className={styles.name}>{name}</div>
                </div>
                {session && <div className={cx('column', 'condition')}>{condition_value}</div>}
                <div className={cx('column', 'price-block')}>
                    {session && <div className={styles.price}>{price} {currency}</div>}
                    {available && <div className={availabilityStatusClasses}>{available.label}</div>}
                    <div className={cx('condition', {'private-marketplace-mode': session})}>{condition_value}</div>
                </div>
            </div>

            <div className={styles.description}>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.label}>{delivery_time_label}{xsDevice && ':'}</div>
                        <div className={styles.txt}>{delivery_time}</div>
                    </div>
                    {organization_name && (
                        <div className={styles.row}>
                            <div className={styles.label}>{exporter_label}{xsDevice && ':'}</div>
                            <div className={styles.txt}>{organization_name}</div>
                        </div>
                    )}
                    {formatted_supply_condition && (
                        <div className={styles.row}>
                            <div className={styles.label}>{supply_conditions_label}{xsDevice && ':'}</div>
                            <div className={styles.txt}>{formatted_supply_condition}</div>
                        </div>
                    )}
                </div>
                <div className={styles['btn-block']}>
                    {available && <div className={availabilityStatusClasses}>{available.label}</div>}
                    <Link to={href}>
                        <Button styleType={'SECONDARY'} classNames={styles.btn}>
                            {view_product}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

ProductPanel.defaultProps = {
    delivery_time: '30 days',
    href: '#',
};

ProductPanel.propTypes = {
    id: PropTypes.any.isRequired,
    owner: PropTypes.string,
    condition_value: PropTypes.string,
    part_number: PropTypes.string,
    name: PropTypes.string,
    offer: PropTypes.shape({
        organization_name: PropTypes.string,
        formatted_supply_condition: PropTypes.string,
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        currency: PropTypes.string,
    }),
    available: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    delivery_time: PropTypes.string.isRequired,
    href: PropTypes.string,
    staticData: PropTypes.object,
    session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default withDataService()(ProductPanel);