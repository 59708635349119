import React from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';

import Checkbox from '../../../../common/checkbox';
import NotificationItem from './components/notification-item';
import NotificationCard from './components/notification-card';

import classnames from 'classnames/bind';
import styles from './notifications.module.scss';

const NOTIFICATION_CARD_MIN_HEIGHT = 476;
const NOTIFICATIONS_CONTAINER_MAX_HEIGHT = 500;

const Notifications = (props) => {
    const {
        staticData, initList, list, fullList, filters, isOpenNotification, isAllNotificationsVisible,
        isMultiSelectMode, selectedAll, selectedList,
        onOpenNotification, onToggleListViewMode, onToggleFilters,
        onAllCheckboxChange, onToggleMultiSelectMode,
        onCheckboxChange, onMultipleDeleteNotifications,
        ...rest
    } = props;

    const {
        no_notifications_message,
        button: {select, cancel, delete_btn, show_more},
    } = staticData.activity_hub.notifications;

    const mdDevice = useMediaQuery({minWidth: 810}); // depends on with-hub-side-menu media query
    const lgDevice = useMediaQuery({minWidth: 992});

    const cx = classnames.bind(styles);
    const optionsBlockClasses = cx('options-block', {'extended': isMultiSelectMode});
    const notificationsBlockClasses = cx('notifications-block', {'hidden': isOpenNotification && !lgDevice});
    const notificationsClasses = cx('notifications', {'scrollable': isAllNotificationsVisible});
    const selectBtnClasses = cx('select', {'hidden': isMultiSelectMode || isOpenNotification});
    const deleteBtnClasses = cx('delete', {'disable': selectedList.length === 0});

    const createOptionsBlock = () => {
        const filtersBlock = filters && filters.map(({id, label, isActive}, idx) => {
            const labelClasses = cx('filter', {'active': isActive});

            return <div key={idx} className={labelClasses} onClick={() => onToggleFilters(id)}>{label}</div>;
        });

        if (isMultiSelectMode) {
            const checkboxType = fullList.length !== selectedList.length && selectedList.length > 0 ? 'SECONDARY' : 'PRIMARY';
            const activeCheckbox = selectedAll !== 0 ? [selectedAll] : [];

            return (
                <>
                    <Checkbox id="select-all-notifications"
                              styleType={checkboxType}
                              name="all-notifications"
                              value={selectedAll}
                              onChange={onAllCheckboxChange}
                              activeCheckboxList={activeCheckbox}/>

                    <div className={styles.panel}>
                        <div className={deleteBtnClasses} onClick={onMultipleDeleteNotifications}>{delete_btn}</div>
                        <div className={styles.cancel} onClick={() => onToggleMultiSelectMode('close')}>{cancel}</div>
                        <div className={styles.filters}>{filtersBlock}</div>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className={styles.filters}>{filtersBlock}</div>
                <div className={selectBtnClasses} onClick={() => onToggleMultiSelectMode('open')}>
                    {select}
                </div>
            </>
        );
    };

    const createOpenNotification = () => {
        return initList.map((item, idx) => {
            if (item.opened) {
                const notificationClasses = cx('notification', 'open');
                const isFirsNotificationOpen = idx === 0;
                const isLastNotificationOpen = idx === initList.length - 1;
                const style = {height: !isAllNotificationsVisible ? `${NOTIFICATION_CARD_MIN_HEIGHT}px` : null};

                return <NotificationCard key={idx} {...item} {...rest}
                                         style={style}
                                         classNames={notificationClasses}
                                         isFirsNotificationOpen={isFirsNotificationOpen}
                                         isLastNotificationOpen={isLastNotificationOpen}/>;
            }

            return null;
        });
    };

    const createNotificationsList = () => {
        if (list.length > 0) {
            return list.map((item, idx) => {
                const {id, checked} = item;

                return (
                    <div key={idx} className={styles['notification-item-block']}>
                        {isMultiSelectMode && <Checkbox id={id}
                                                        value={checked}
                                                        activeCheckboxList={checked !== 0 ? [checked] : []}
                                                        onChange={onCheckboxChange}/>}
                        <NotificationItem  {...item}
                                           classNames={cx('notification-item', {'default-pointer': isMultiSelectMode})}
                                           onOpenNotification={onOpenNotification}/>
                    </div>
                );
            });
        }

        const activeFilter = filters.find(({isActive}) => isActive);
        return (
            <div className={styles['empty-message']}>
                {no_notifications_message[activeFilter.id]}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={notificationsBlockClasses}>
                <div className={optionsBlockClasses}>
                    {createOptionsBlock()}
                </div>

                <div id={'notifications-container-id'}
                     className={notificationsClasses}
                     style={mdDevice
                         ? {maxHeight: `${NOTIFICATIONS_CONTAINER_MAX_HEIGHT}px`}
                         : null
                     }
                >
                    {createNotificationsList()}
                </div>

                {!isAllNotificationsVisible &&
                    <div className={styles.show} onClick={onToggleListViewMode}>
                        {show_more}
                    </div>}
            </div>

            {fullList.length > 0 && createOpenNotification()}
        </div>
    );
};

Notifications.propTypes = {
    staticData: PropTypes.object,
    initList: PropTypes.array,
    list: PropTypes.array,
    fullList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    filters: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
    ]),
    isAllNotificationsVisible: PropTypes.bool,
    isOpenNotification: PropTypes.bool,
    isMultiSelectMode: PropTypes.bool,
    selectedList: PropTypes.arrayOf(PropTypes.object),
    selectedAll: PropTypes.number,
    onToggleListViewMode: PropTypes.func,
    onToggleFilters: PropTypes.func,
    onToggleMultiSelectMode: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onAllCheckboxChange: PropTypes.func,
    onMultipleDeleteNotifications: PropTypes.func,
};

export default withDataService()(Notifications);