const onCreateFormData = (object) => {
    let formData = new FormData();
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            if (Array.isArray(object[key])) {
                object[key].forEach((data) => {
                    let value = data;
                    // case for simple object, like {}
                    if (data instanceof Object) {
                        value = data.value;
                    }

                    formData.append(key, value);
                });
            } else {
                if (object[key] instanceof Object) {
                    if (object[key].files) {
                        // case for files (photo, documents, other files)
                        object[key].files.forEach(file => {
                            formData.append(key, file);
                        });
                    }
                } else {
                    formData.set(key, object[key]);
                }
            }
        }
    }

    return formData;
};

export default onCreateFormData;