export const terms_and_conditions_es = {
    side_menu: [
        'Introduction',
        'Definitions and interpretation',
        'Terms',
        'Use of The E-trade platform, Exportery',
        'Rights of access and user accounts',
        'Relationship of Exportery LLC (agent) with The Exporter (seller)',
        'Relationship of Exportery LLC (agent) to The Importer (buyer)',
        'Creating a trade and e-contract acceptance, e-signatures compliance and regulating the electronic flow of documents',
        'End-user certificate definition, peculiarity of use and applicability',
        'Fund deposited into Exportery escrow account',
        'Importer’s (buyer’s) acceptance of goods',
        'Independent inspection in bonded escrow warehouse',
        'Payment out of the escrow account',
        'Refund to importer',
        'Fees and allocation of amounts',
        'Dispute resolution',
        'Arbitration',
        'Returned goods or domain names',
        'KYC and AML requirements',
        'Duty and capacity of users',
        'Cancellation',
        'Transactions involving an agent or broker as a party',
        'Integrations',
        'Unclaimed funds',
        'Support',
        'Disclaimer of warranties',
        'Acceptable use and conduct',
        'Intellectual property',
        'Hyperlinks, deep links, framing',
        'Advertising and sponsorship',
        'Indemnity',
        'Disclaimers',
        'Limitation of liability',
        'Termination',
        'Disputes between users and Exportery',
        'Notices and domicile',
        'Company information',
        'Circumstances beyond control',
        'Cession and assignment',
        'Confidentiality and the processing of your personal information',
        'Non-performance of selling/supplying party in certain transaction classes',
        'General',
    ],

    content: [
        {
            title: 'Introduction',
            text: [
                `Exportery is a B2B e-commerce platform that bridges the cross-border gap between OEM
                manufacturers of global spare parts and the end users within the aviation and land transport
                industries.These terms are the general terms which governs the relationship between Exportery 
                and the Parties. These terms cover the use of the platform as well as the use of any of the 
                services offered by the company.`,
                {
                    0: `By visiting, signing up on or logging in to the Exportery Platform, each Party accepts
                    and agrees to these Terms which will become binding and enforceable against all persons that 
                    access the`,
                    1: '',
                    2: {
                        text: 'www.exportery.com',
                        href: 'https://exportery.com',
                    },
                    3: '',
                    4: `website or any part thereof in accordance with the Law of Georgia on Entrepreneurs 
                    and other applicable international laws and treatiesto which Georgia is a signatory.`,
                },
                `The parties to the Transaction hereby agree to conduct the transaction electronically. 
                These Terms apply to any transaction that was created on the Exportery platform as well 
                as the use of any Exportery Services. The commercial terms of any Trade between Exportery 
                and the Parties will be contained in the Trade and will incorporate these Terms.`,
            ],
        },
        {
            title: 'Definitions and interpretation',
            text: [
                `For purposes of the Agreement, the following definitions apply:`,
                {
                    0: {
                        text: '"Affiliate"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means any parent company, subsidiaries, joint ventures, or other companies under 
                    a common control.`,
                },
                {
                    0: {
                        text: '"Agent"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `(which in the context of this platform, is Exportery LLC, a limited liability 
                    company located in Tbilisi, Georgia) or "Broker" means either a Broker, a sole Agent or 
                    an Agent (may be used interchangeably with "Lead Agent") who is assigned to lead a number 
                    of sub-agents.`,
                },
                {
                    0: {
                        text: '"Exporter"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: 'also',
                    3: '',
                    4: {
                        text: '"Seller"',
                        strong: true,
                        italic: true,
                    },
                    5: '',
                    6: `implies OEM Companies or sellers of spare parts and other products allowed on 
                    the Exportery Platform.`,
                },
                {
                    0: {
                        text: '"Importer"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: 'also',
                    3: '',
                    4: {
                        text: '"Buyer"',
                        strong: true,
                        italic: true,
                    },
                    5: '',
                    6: `means any individual or company with a valid trade agreement with the seller 
                    or exporter on the Exportery Platform`,
                },
                {
                    0: {
                        text: '"Origin Point"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means the FCA point of Exporter i.e. FCA Tbilisi airport cargo terminal or Warsaw
                    airport cargo terminal. FCA terms and conditions full comply with INCOTERMS 2020.`,
                },
                {
                    0: {
                        text: '"Origin Freight forwarder"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means a company that facilitates cargo movement and arranges shipments for 
                    arrival at the destination or origin port.`,
                },
                {
                    0: {
                        text: '"Escrow Warehouse"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `is a place whereby an asset is held by a third party on behalf of two other parties
                    that are in the process of completing a transaction. Escrow warehouses are managed by the
                    escrow agents who releases the assets or funds only upon the satisfaction of predetermined 
                    contractual obligations.`,
                },
                {
                    0: {
                        text: '"E-Contract"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means an agreement formulated online to create a binding mutual obligation 
                    between the Exportery and the Party (Exporters and Importers), comprising these Terms 
                    and any Trades the parties enter into.`,
                },
                {
                    0: {
                        text: '"Amended Escrow Instruction(s)"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means any addition to, deletion from, or alteration to the terms of the Trade
                    as applied by Exportery on the combined instruction of all Parties.`,
                },
                {
                    0: {
                        text: '"Arbitration"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `refers to the process whereby an independent arbitrator is assigned to 
                    the transaction to adjudicate the merits of the disputing parties in accordance 
                    with the Georgian rules for expedited arbitrations.`,
                },
                {
                    0: {
                        text: '"API"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: 'or',
                    3: '',
                    4: {
                        text: '"Application Programming Interface"',
                        strong: true,
                        italic: true,
                    },
                    5: '',
                    6: `means a set of functions and procedures that allow other systems or websites 
                    creation of applications which access the features or data of an operating system, 
                    application, or other service.`,
                },
                {
                    0: {
                        text: '"Business Day"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `shall refer to the working days Monday through Friday between the hours of 
                    8:00 a.m. and 5:00 p.m. local time.`,
                },
                {
                    0: {
                        text: '"Importer"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means the party procuring the goods and services and who is 
                    responsible for funding the transaction as well as providing Exportery 
                    the instruction to release the funds from the escrow account to the Exporter Party.`,
                },
                {
                    0: {
                        text: '"Dispute Date"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means the date the Buyer indicated on the Platform that he/she 
                    does not accept the goods or services as delivered by the Selling Party.`,
                },
                {
                    0: {
                        text: '"Delivery Lead Time"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: 'or',
                    3: '',
                    4: {
                        text: '"(DLT)"',
                        strong: true,
                        italic: true,
                    },
                    5: '.',
                    6: '',
                    7: `Where used and in any context where applicable, the Delivery Lead Time or DLT means 
                    the number of days required for the Exporter to deliver the goods to the Origin 
                    Service Point. For instance, to submit cleared cargo to the Origin Freight forwarder 
                    by fulfilling the FCA terms as per E-Contract.`,
                },
                {
                    0: {
                        text: '"Escrow Account"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `is an account where funds are held in trust while the parties complete 
                    a transaction. It means the audited and secure account where all money from Trades is held. 
                    The account is held by Exportery.`,
                },
                {
                    0: {
                        text: '"Inspection Period"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means the number of full calendar days established in a Trade to allow a 
                    Buyer to inspect the goods delivered or services received.`,
                },
                {
                    0: {
                        text: '"Dispute Period"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means 10 (ten) Business Days commencing from Dispute Date.`,
                },
                {
                    0: {
                        text: '"Parties"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `collectively refers to all legal persons that form party to the Trade
                     transaction and can include:`,
                    items_ul: [
                        `IMPORTER`,
                        `EXPORTER`,
                        `AGENT (EXPORTERY LLC)`,
                        `other beneficiaries that are Party to the transaction and require payment i.e. 
                        subagents, advisors or consultants, lawyers, delivery companies etc.`,
                    ],
                },
                {
                    0: {
                        text: '"Platform"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: 'means',
                    3: '',
                    4: {
                        text: 'www.exportery.com',
                        href: 'https://exportery.com',
                    },
                    5: ',',
                    6: '',
                    7: `an e-commerce platform that bridges the cross-border gap between 
                    OEM manufacturers of global spare parts and the end users within the aviation 
                    and land transport industries, and comprises:`,
                    items_ul: [
                        `all content, goods or services, applications, APIs or apps available 
                        through the Platform; and`,
                        `any other marketing material of Exportery.`,
                    ],
                },
                {
                    0: {
                        text: '"EXPORTER"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means the seller of the goods or services and is the party that receives 
                    the sales proceeds from the escrow account to their bank account.`,
                },
                {
                    0: {
                        text: '"Services"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means any services Exportery provides to the Party as specified by 
                    Exportery on the Platform.`,
                },
                {
                    0: {
                        text: '"Terms"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means these terms, comprising of:`,
                    items_ul: [
                        `these terms set out in this document ("Terms of Use"); and`,
                        `any other relevant specific terms, disclaimers, rules and notices 
                        agreed between the Parties, (including any that may be applicable to 
                        a specific section or module of the Platform or any specific Services).`,
                    ],
                },
                {
                    0: {
                        text: '"E-CONTRACT"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means a transaction between the Parties (OEM manufacturers of global 
                    spare parts and the end users within the aviation and land transport industries) 
                    for the provision of spare parts, where Exportery will provide escrow services to its users.`,
                },
                {
                    0: {
                        text: '"Trigger Event"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means an event defined by a Party in a Trade which will act as a 
                    signal for Exportery to perform its duties under a Trade. Trigger 
                    Events will typically include as a minimum:`,
                    items_ul: [
                        `When Exportery has received the funds from Importer;`,
                        `The Party accepts the goods or services. Exportery will then release 
                        funds to the Exporter, Agent or Broker, and/or other approved 
                        beneficiaries that require payment;`,
                        `When the DLT has been reached and there has been no action 
                        by the Buyer, despite numerous attempts by Exportery to reach 
                        the Importer telephonically or electronically;`,
                        `The Importing Party has initiated a dispute; and/or`,
                        `The Parties could not resolve the dispute within the ascribed 10 
                        (ten) Business Days resulting in Exportery initiating the Arbitration process.`,
                    ],
                },
                {
                    0: {
                        text: '"Writing"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: `means any mode of reproducing information or data in physical 
                    form and includes hard copy printouts, handwritten documents, fax transmissions.`,
                },
                {
                    0: {
                        text: '"User"',
                        strong: true,
                        italic: true,
                    },
                    1: '',
                    2: 'or',
                    3: '',
                    4: {
                        text: '"you"',
                        strong: true,
                        italic: true,
                    },
                    5: '',
                    6: `means any Party who visits, signs up to, and logs into the Platform.`,
                },
            ],
            items_ol: [
                {
                    title: 'Interpretation',
                    text: [
                        `A word defined or assigned a meaning in the Agreement will start with 
                        a capital letter.  All headings are inserted for reference purposes only and 
                        must not affect the interpretation of the Agreement.`,
                        `Whenever "including" or "include", or "excluding" or "exclude", together with 
                        specific examples or items follow a term, they will not limit its ambit. Terms other 
                        than those defined within the Agreement will be given their plain English meaning. References to 
                        any enactment will be deemed to include references to the enactment as re-enacted, amended, 
                        or extended from time to time.  A reference to a person includes a natural and juristic 
                        person and a reference to either party includes the party’s successors or permitted assigns. 
                        Unless otherwise stated in the Agreement, when any number of days is prescribed in the Agreement 
                        the first day will be excluded and the last day included.`,
                        `The rule of construction that an agreement must be interpreted against 
                        the party responsible for its drafting or preparation does not apply. 
                        GMT+4 will be used to calculate any times.`,
                    ],
                },
                {
                    title: 'Conflict',
                    text: [
                        `If there is a conflict of meaning between these Terms of Use 
                        and any other relevant specific terms, policies, disclaimers, rules 
                        and notices agreed between the parties, the specific terms will prevail 
                        in respect of the use of the relevant section or module of the Platform, or Services.`,
                    ],
                },
            ],
        },
        {
            title: 'Terms',
            text: `These Terms commence on acceptance by the Parties as required and will continue
            until terminated as provided for in these Terms of Use.`,
        },
        {
            title: 'Use of the e-trade platform, Exportery',
            items_ol: [
                {
                    title: 'License to Use the Platform',
                    text: `Exportery grants each registered user a singular and specific revocable 
                    license to use the Platform subject to the Agreement. Any person wishing 
                    to use the Platform contrary to the Agreement must obtain Exportery’s 
                    prior written consent.`,
                },
                {
                    title: 'Policies',
                    text: [
                        `Exportery may establish general polices and limits concerning the 
                        use of the Platform. Exportery reserves the right to log off, deactivate, or delete any 
                        User account in its sole discretion, provided that Exportery will provide prior reasonable 
                        notice of such actions with the relevant User.`,
                        {
                            0: `Exportery adopts the Terms and Conditions as obtained on`,
                            1: '',
                            2: {
                                text: 'https://www.docusign.com',
                                href: 'https://www.docusign.com',
                            },
                            3: '',
                            4: `E-Contract, E-signature, E-invoice, and E-packing list in accordance 
                            with the English common law and any relevant law applicable which is 
                            consistent with the English Common Law.`,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Rights of access and user accounts',
            items_ol: [
                {
                    title: 'Passwords and Security',
                    text: [
                        `Only a registered User can use the Platform. Each User may only hold one 
                        account in their name and is responsible for keeping their login credentials 
                        (i.e. the username and password required to access the system) secure and confidential. 
                        Each User is solely responsible and liable for activities that occur under their 
                        account. The User authorizes Exportery to act on any instruction given under 
                        User’s account, irrespective if it transpires that someone had maliciously 
                        taken control of the User’s account, unless the User has notified Exportery 
                        prior to Exportery acting on a fraudulent instruction.`,
                        {
                            0: `Exportery adopts the Terms and Conditions as obtained on`,
                            1: '',
                            2: {
                                text: 'https://www.docusign.com',
                                href: 'https://www.docusign.com',
                            },
                            3: '',
                            4: `E-Contract, E-signature, E-invoice, and E-packing list in accordance 
                            with the English common law and any relevant law applicable which is 
                            consistent with the English Common Law.`,
                        },
                        {
                            text: `If a User suspects that their login credentials may have been 
                            compromised, the User must:`,
                            items_ul: [
                                {
                                    0: 'Contact Exportery immediately on +995 706 001 010 or',
                                    1: '',
                                    2: {
                                        text: 'feedback@exportery.com',
                                        href: 'mailto:feedback@exportery.com',
                                    },
                                    3: '; and',
                                },
                                `Change their password immediately.`,
                            ],
                        },
                        `Sharing of credentials with any other person may jeopardize the 
                        integrity of the Exportery account. Exportery cannot be held accountable 
                        or liable for loss that incurs from shared authorized access.`,
                    ],
                },
                {
                    title: 'Right of Reserve',
                    text: [
                        `Exportery reserves the right to reject any User or 
                        deny the ability of a User to create an account should it feel that the User is 
                        using the Exportery account for illegitimate or unscrupulous purposes.`,
                        `User accounts registered by bots or other automated methods are not permitted. 
                        Each User must provide their full legal name, a valid email address, 
                        a valid cell phone number, their Identity number, company registration documents, 
                        proof of banking accounts and any other information requested by 
                        Exportery to complete the registration process.`,
                        `One person may only use each User’s login - Exportery does 
                        not permit multiple people to share a login.`,
                    ],
                },
                {
                    title: 'Verification of Banking Account Details',
                    text: [
                        `Exportery uses third party verification services provider to 
                        verify the bank account details of the Parties. To receive payment from Exportery, 
                        the Seller must have a valid bank account and the details of the bank account holder 
                        must correspond directly with the details that were entered on the Exportery 
                        platform. For a business account, the company registration number as 
                        entered on the Exportery Platform must agree to the company 
                        registration number associated with the applicable bank account.`,
                        `Exportery reserves the right to withhold payment until 
                        such time it ascertains that the User’s bank account details have been verified.`,
                    ],
                },
                {
                    title: 'Other Information That May Be Requested',
                    text: `Dependent on the nature of the User’s business, Exportery 
                    may be compelled to request further information or perform additional procedures 
                    which may include performing physical verification of the Seller’s/Exporter’s
                    site or premises. This is purely to negate the risk of money laundering
                    and to ensure the Exportery Platform is used for legitimate purposes`,
                },
            ],
        },
        {
            title: 'Relationship of Exportery LLC (Agent) with The Exporter (Seller)',
            text: [
                `Depending on the circumstances, Exportery may act as the agent of 
                the Exporter, as the case may be. For example:`,
                {
                    items_ol_type_a: [
                        `Exportery shall act in the capacity of an agent to the 
                        Exporter (seller) and is bound by the agency laws applicable in Georgia`,
                        {
                            0: `The Exporter is obligated to deliver the goods to the 
                            origin service point within the stipulated contract timeframe. 
                            A list of our origin service points, other origin service 
                            points list can be accessed by the following reference:`,
                            items_ul: [
                                `FCA Tbilisi Airport Cargo Terminal`,
                                `FCA Warsaw Airport Cargo Terminal`,
                            ],
                        },
                        {
                            0: `In the eventual default of the delivery to the origin service 
                            point at the stipulated time by the exporter, Exportery shall penalize 
                            the Exporter based on the prevalent industrial standard 
                            if any or accordance with the clause below:`,
                            items_ul: [],
                        },
                        `In case of delayed delivery except for Force Majeure cases, 
                        the Exporter shall pay for every week of delay penalty amounting 
                        to 0.5% of the total value of the Goods whose 
                        delivery has been delayed. Any fractional part of 
                        a week is to be considered a full week. The total 
                        amount of penalty shall not, however, exceed 5% 
                        of the total value of the Equipment involved in 
                        late delivery. If the delay is more than 10 weeks, 
                        Exportery is entitled to terminate this Contract. 
                        However, the Exporter will still be responsible for 
                        above mentioned penalties. Vice versa, in case the 
                        Importer delays the payment due to the Importer’s reason, 
                        the Exporter will not be responsible for late delivery.`,
                        `In the eventual loss or damage of goods from the point of sale 
                        to the delivery, Exportery shall bear the liabilities of 
                        such loss to the exclusion of the Exporter.`,
                        `Where the goods inspected is of lesser quality, a misfit, or does 
                        not align with the specifications agreed by the parties, the Exporter will be penalized 
                        and have the goods returned.`,
                        {
                            0: `Where the goods inspected is of lesser quality, misfit, or does not align 
                            with the specifications agreed by the parties, the following penalties shall 
                            be meted to the Exporter:`,
                            items_ol_style_lower_roman: [
                                `A one-off 5% payment made on the value of the deal involved`,
                                `A written warning from Exportery`,
                                'An opportunity to rectify the mishap',
                            ],
                        },
                        `For a repeat offender, a permanent ban from the Exportery platform 
                        will be carried out.`,
                        `Exportery LLC will provide a SWIFT Bank Guarantee for Exporters who are 
                        renowned on the platform for goods exceeding €100,000 if the Exporter chooses 
                        to activate the option through the electronic means. This guarantee is subject 
                        to the Uniform Rules for Demand Guarantees, ICC Publication No. 458, except 
                        that subparagraph (ii) of Sub-article 19(a) is hereby excluded.`,
                        `If an amendment or change to a Trade takes place, and the Parties agree that a 
                        portion of the funds is to be returned to the Importer, the Exporter shall instruct 
                        Exportery to approve the amendment or change to the Trade and to pay a refund to 
                        the Importer. In this example, Exportery in accepting the instruction and 
                        effecting payment to the Importer acts as an agent for the Exporter.`,
                    ],
                },
            ],
        },
        {
            title: 'Relationship of Exportery LLC (agent) to The Importer (buyer)',
            items_ol: [
                {
                    text: `If Importer accepts the goods after electronically signed trilateral Give 
                    and Take Act - funds will be released through a simple SWIFT wire transfer based on 
                    the Special Contract Conditions.`,
                },
                {
                    text: `Exportery shall be obligated to inspect the goods provided by the Exporter to 
                    ensure it is of quality and standard specified in the sales agreement between the parties 
                    and issue an Inspection Report which should be confirmed (approved) 
                    electronically by a respective Importer.`,
                },
                {
                    text: `Exportery (Agent) shall charge 25% on the independent escrow account in the independent 
                    banking institution after an electronically signed e-Contract. The balance of this shall 
                    be paid by a respective Importer according to the terms specified 
                    in the Special Contract Conditions.`,
                },
                {
                    text: `In some instances, such as when dealing between governments and the military, such as the 
                    Ministry of Defence of a Government, State tender execution etc., Exportery will not accept 
                    a deposit from the Importing party.`,
                },
                {
                    text: {
                        0: `Demurrages, storages incurred through the late or non-payment for goods in transit 
                        (escrow) warehouse shall be paid by the Importer as per following:`,
                        items_ul: [],
                    },
                },
            ],
        },
        {
            title: 'Creating a trade and e-contract acceptance, e-signatures compliance and regulating the electronic flow of documents',
            items_ol: [
                {
                    text: [
                        {
                            0: `The terms of the transaction for the provision of goods will be 
                            in accordance with terms and conditions provided on`,
                            1: '',
                            2: {
                                text: 'https://www.docusign.com',
                                href: 'https://www.docusign.com',
                            },
                            3: '.',
                            4: '',
                            5: 'When creating a transaction, users are required to enter or upload the following information:',
                            items_ul: [
                                'Comprehensive details of the transaction;',
                                `A description of the requested goods or service;`,
                                `All terms and conditions including the agreed inspection period and delivery 
                                arrangements;`,
                                `Any contracts, files, photos or documents (if applicable) that pertain to 
                                the transaction between the parties; and`,
                                `Payment Trigger Events that pertain to the Trade.`,
                            ],
                        },
                    ],
                },
                {
                    text: {
                        0: `Parties indicate whether they agree to the transaction and accept the Trade’s 
                        terms and conditions by clicking on the`,
                        1: '',
                        2: {
                            text: '"Create Contract/ Sign Contract"',
                            strong: true,
                        },
                        3: '',
                        4: 'button on the Platform.',
                    },
                },
                {
                    text: {
                        0: `All Parties are required to accept the Trade for the transaction to be a binding agreement between the Parties. 
                        The status of the transaction as displayed on the Platform will be highlighted as`,
                        1: '',
                        2: {
                            text: '"Contract Signed"',
                            strong: true,
                        },
                        3: '.',
                    },
                },
                {
                    text: {
                        0: `The parties will be notified by email that the Trade has been accepted and that the`,
                        1: '',
                        2: {
                            text: 'Importer',
                            strong: true,
                        },
                        3: '',
                        4: 'is required to deposit 25% into the Escrow Account.',
                    },
                },
            ],
        },
        {
            title: 'End-user certificate definition, peculiarity of use and applicability',
            items_ol: [
                {
                    text: `End-User Certificate is an undertaking by a purchaser/Importer 
                    that any of the controlled items/products covered by the process transferred 
                    from the exporting country is for the sole and lawful use within the recipient 
                    country, and that the controlled items/products are not destined for transfer 
                    or re-export to any other entity or country, without the prior written consent of 
                    the issuing authority.`,
                },
                {
                    text: `In order to trade certain goods such as weapons, and other classified goods, 
                    end-user certificate is mandatory.`,
                },
                {
                    text: `The Importer is required to provide Exportery any and all documentation 
                    including but not limited to End-User Certificate, required to support any Accepted 
                    RFQ subject to issuance of an export license or any documentation to secure the 
                    import of the goods subject to the Accepted RFQ.`,
                },
            ],
        },
        {
            title: 'Fund deposited into Exportery escrow account',
            items_ol: [
                {
                    text: `The Importer deposits 25%, the balance to be paid after signing the 
                    trilateral Give and Take Act, being the transaction value into the Escrow 
                    Account within the lead time specified in the Societal Contract Conditions.`,
                },
                {
                    text: `Exportery will not be held liable or responsible if the funds are deposited 
                    into the incorrect bank account should the Buyer use incorrect bank account details.`,
                },
                {
                    text: `Exportery will not rely on any proof of payments as submitted by the Buyer.`,
                },
                {
                    text: `All Parties will be notified once Exportery has verified the receipt of the 
                    funds into the Escrow Account.`,
                },
            ],
        },
        {
            title: 'Importer’s (buyer’s) acceptance of goods',
            items_ol: [
                {
                    text: `The Importer accepts the goods based on the Inspection conducted by 
                    Exportery and the Report compiled thereof after the signing of the Give and Take Act.`,
                },
                {
                    text: `The Importer will be deemed to have accepted the goods at the confirmation of 
                    the receipt of the Inspection Report compiled by Exportery and the Give and Take Act.`,
                },
                {
                    text: `The logistics expenses and risks involved in delivering the goods to the 
                    Escrow Warehouse in Addis Ababa are solely borne by Exportery.`,
                },
                {
                    text: `At the receipt of the report and other documents incidental to the deal, 
                    the Importer equally accepts to pay all the dues accrued as well as the shipping 
                    lots for the particular goods ordered by the importer.`,
                },
                {
                    text: `Exportery shall not be liable to any payment or fee which may accrue due 
                    to the collapse of the deal between the Exporter and Importer due to personal 
                    or technical reasons.`,
                },
                {
                    text: `Exportery shall be free from all encumbrances which may arise by way 
                    of applicable laws in different jurisdictions based on the originally agreed 
                    E-Contract between the parties in respect of a transaction.`,
                },
            ],
        },
        {
            title: 'Independent inspection in bonded escrow warehouse',
            items_ol: [
                {
                    text: `The Importer will be allowed, at its own discretion, to physically inspect 
                    the goods in the bonded escrow warehouse at the delivery to the bonded warehouse.`,
                },
                {
                    text: `Exportery shall carry out its own inspection by an independent technical 
                    specialist, make photo fixation, make a detailed examination of each place according 
                    to the E- packing list. The place of inspection is Addis Ababa Cargo Terminal, Bole 
                    International Airport, Addis Ababa, Ethiopia.`,
                },
                {
                    text: `Once the inspection is complete and approved through the agency of independently 
                    issued Inspection Report by Exportery LLC, the trilateral Give and Take Act 
                    should be signed and after balance payment per each shipment lot the shipment 
                    lot will be released and the final shipment delivery will be arranged based on 
                    the CIP Importer’s destination country airport.`,
                },
                {
                    text: `The inspections conducted by the experts at Exportery either hired 
                    due to technicalities or inhouse are based on the standards set by the International 
                    Standard Organization (ISO), State Union Standard (SUST/ GOST), International Civil 
                    Aviation Organization (ICAO), EASA PART-145, and the prevalent National standards in 
                    all the relevant jurisdictions.`,
                },
                {
                    text: `Where the importer is not satisfied with the delivery, the Importer is 
                    allowed to immediately contact Exportery and open a query into the identified issue.`,
                },
            ],
        },
        {
            title: 'Payment out of the escrow account',
            items_ol: [
                {
                    title: 'Payment to Exporter',
                    text: `Once the Trigger Event for the acceptance of the goods has occurred, 
                    Exportery will proceed to release funds to the Exporter after deducting the 
                    appropriate Fees (if any) as established in the Trade. Exportery shall use the 
                    Exporter’s bank account details that were entered on the Seller’s account profile. 
                    Exportery shall not be liable or be held responsible should it pay the funds 
                    into an incorrect bank account should the Seller have provided incorrect 
                    banking account details.`,
                },
                {
                    title: 'Payment to Agent or other Beneficiaries Who Require Payment',
                    text: `Once the Trigger Event for the acceptance of the goods or services has 
                    occurred, Exportery will proceed to release funds to the Exporter, then 
                    other payment beneficiaries after deducting the appropriate Fees (if any) 
                    as established in the Trade.`,
                },
                {
                    title: 'Summary of Instances Where Parties Will Be Disbursed',
                    text: {
                        0: `Exportery will release the funds to the Exporter, Agent or 
                        other beneficiaries requiring payment once the following criteria has been satisfied:`,
                        items_ul: [
                            `The Importer accepted the goods or services and Approved the Independent 
                        Inspection Report and signed a trilateral Give and Take Act;`,
                            `The Importer renegotiated certain Trade terms pertaining to the goods 
                        or services, of which the Exporter agreed to the renegotiated Trade terms;`,
                            `The Importer initiated a dispute which was subsequently resolved by 
                        all Parties within 10 Business Days;`,
                            `The IMPORTER initiated a dispute which could NOT be subsequently resolved, 
                        and the matter was referred to Arbitration or Georgian court of law. 
                        Exportery will only act on the written instruction of the Arbitrator or 
                        Georgian Court of Law; or`,
                            `The Buyer is deemed to have accepted the goods or services after the 
                        transaction deadline ("DLT") and the Importer has failed to confirm electronically 
                        through the e-trade platform Exportery that they have either accepted, negotiated 
                        or disputed the goods or services, or requested an extension to the delivery 
                        or inspection period, despite numerous attempts to contact the Buyer.`,
                        ],
                    },
                },
                {
                    title: 'Exportery’s Payment Run',
                    text: [
                        `Exportery makes two payments at 10h00 and 15h00 every Business Day.`,
                        `If authorization to release the funds was received by Exportery 
                        between 08h01 and 14h00, then payment will be made at 15h00 that day.`,
                        `If authorization to release the funds took place after 14h01, then 
                        payment will be made at 09h00 the following Business Day.`,
                    ],
                },
                {
                    title: 'Priority of Parties in Respect of Payment',
                    text: {
                        0: `Payment to Parties will be done in the following order:`,
                        items_ul: [
                            'The Exporter',
                            'The Agent, Exportery LLC;  and then',
                            'other beneficiaries that require payment i.e. sub-agents, lawyers etc.',
                        ],
                    },
                },
            ],
        },
        {
            title: 'Refund to importer',
            items_ol: [
                {
                    title: 'Failure of Seller to Initiate Delivery of Product or Service',
                    text: [
                        `If the EXPORTER does not begin delivery of the goods or services 
                        within the TERMS specified in the Special Contract Conditions specified 
                        in the E-Contract, OR any other number of days as agreed by the Parties, 
                        from the time the Parties being notified that Exportery has received the 
                        funds from the Importer, then the IMPORTER is entitled to request a refund 
                        of the funds.`,
                        `Exportery will refund the Buyer after notifying all parties and deducting the Fees.`,
                    ],
                },
                {
                    title: 'Request Adjustment',
                    text: `Should the Buyer request an adjustment to the Trade terms of the Trade, 
                    which is subsequently accepted by the Parties, settlement price may be impacted. 
                    This may result in either a part or a full refund to the Buyer, net of Fees.`,
                },
                {
                    title: 'Dispute',
                    text: `Should the Buyer reject the goods or services and initiate a dispute, the 
                    Parties can agree to revised Trade terms which may impact the settlement price. 
                    This may result in either a part or a full refund to the Buyer, net of Fees`,
                },
                {
                    title: 'Legal',
                    text: `Upon written instruction by either a registered arbitrator or Georgia court of 
                        law, the Buyer may be entitled to either a part or a full refund, net of Fees.`,
                },
            ],
        },
        {
            title: 'Fees and allocation of amounts',
            text: [
                `The fees accrued to Expotery LLC are classified and not open to review by the parties.`,
                `The fees payable for the goods ordered will be based on the e-contracts signed 
                by the parties involved.`,
                `All the pages of the documents constituting the deal between the Importer and Exporter must 
                be signed based on the earlier provisions made about E-signature and E-contract.`,
                `Exportery only bears the costs for the movement of the goods to the origin point and not 
                any other expenses which arise based on the deal struck by the parties.`,
            ],
            items_ol: [
                {
                    title: 'Interest',
                    text: [
                        `Interest on any funds held in the Escrow Account will accrue to Exportery.`,
                        `If the Buyer or the Seller experiences an unexpected extended period before the 
                        finalisation of a Trade, the Buyer or Seller may request in writing an 
                        instruction to have Exportery place the funds into an interest-bearing account 
                        for the benefit of the Buyer or the Seller. Exportery reserves the right to 
                        either approve or reject such a request.`,
                    ],
                },
                {
                    title: 'Taxation',
                    text: [
                        `All escrow transaction fees are inclusive of value added tax.`,
                        `All escrow transaction fees exclude any other taxes levied in any 
                        jurisdiction, duty (including stamp duty), tariffs, rates, levies and any 
                        other governmental charge or expense payable, which will be payable where 
                        applicable by User in addition to the Fees.`,
                    ],
                },
                {
                    title: 'Dispute',
                    text: `Should the Buyer reject the goods or services and initiate a dispute, the 
                    Parties can agree to revised Trade terms which may impact the settlement price. 
                    This may result in either a part or a full refund to the Buyer, net of Fees`,
                },
                {
                    title: 'Costs to Implement',
                    text: `Unless otherwise stated, the Parties will bear their own costs to 
                    implement (or perform their obligations under) the Agreement.`,
                },
                {
                    title: 'Customs and Other Fees',
                    text: `Unless the Users agree otherwise, the Buyer is responsible for any 
                    duties, customs fees or other charges resulting from an international Trade, 
                    which will be included in the purchase price. It is the responsibility of 
                    the Seller to properly declare the merchandise and its value for customs procedures.`,
                },
            ],
        },
        {
            title: 'Dispute resolution',
            text: [
                `The Platform incorporates an embedded dispute resolution mechanism.`,
                `Buyers may initiate a dispute on the Platform should the goods or services 
                they receive be materially different to what was set forth in the Trade and 
                supporting paraphernalia.`,
                `Any dispute between the Users in relation to a transaction is between the Users. 
                Exportery will not get involved beyond its obligations under the Trade. Exportery 
                may help or facilitate the resolution of a dispute between Users if a dispute 
                between Users arises but is under no obligation to do so.`,
                `The Parties hereby agree to use their best efforts to negotiate in good faith 
                during the dispute to reach a resolution which is satisfactory for all parties.`,
                {
                    0: `If a User notifies Exportery of a dispute relating to a Trade, Exportery 
                    will immediately freeze the Trade. Exportery will only unfreeze the trade if:`,
                    items_ul: [
                        `The Parties indicate on the Platform that the dispute has been settled;`,
                        `It receives an order from an arbitrator; or`,
                        `It receives an order from an appropriate court of law or similar legal body.`,
                    ],
                },
            ],
        },
        {
            title: 'Arbitration',
            items_ol: [
                {
                    title: 'Introduction',
                    text: [
                        `If the dispute resolution process fails and Parties refer the matter to 
                        Arbitration, Exportery will ascribe to the expedited rules of arbitration in 
                        Nigeria and Georgia which provide for an Arbitrator to make a binding decision 
                        based on the documents, facts and merits of the dispute.`,
                        `The decision of the adjudicator will be binding on the parties.`,
                    ],
                },
                {
                    title: 'Arbitrator Selection',
                    text: `Parties may elect Exportery to nominate an adjudicator.`,
                },
                {
                    title: 'Arbitration Fees',
                    text: [
                        `All fees due to the Arbitrator will be paid by the parties to the 
                        dispute and Exportery will not be liable in any way.`,
                        `Exportery will inform all Parties the prescribed administration fees to 
                        be paid and, from time to time as the proceedings progress, such other fees 
                        and costs as may be payable.`,
                        `The aforesaid administration fees must be deposited into the Escrow Account 
                        for the Arbitration process to commence.`,
                    ],
                },
                {
                    title: 'Rules for Arbitration Proceedings',
                    text: `The proceedings shall be adjudicated in accordance with extant rules 
                    for expedited arbitrations.`,
                },
                {
                    title: 'Arbitration Award and Payment',
                    text: [
                        `The arbitrator shall provide his/her award within 30 (thirty) days 
                        after finalization of the proceedings unless the parties otherwise agree 
                        for an extension of that time.`,
                        `The arbitrator’s award will be published to all Parties.`,
                        `Exportery will act on the aforesaid award and abide by the instruction of the arbitrator.`,
                    ],
                },
                {
                    title: 'Non-Liability',
                    text: `Exportery shall have no liability in respect to the way the 
                    arbitration proceedings were conducted and the associated outcome.`,
                },
            ],
        },
        {
            title: 'Returned goods or domain names',
            items_ol: [
                {
                    title: 'Returned Goods',
                    text: [
                        `If the Trade has been disputed, and it is agreed that the goods are to 
                        be returned to the Seller, then the Buyer shall be responsible for 
                        returning the goods to the Seller to its original destination.`,
                        `The Buyer agrees to initiate delivery of the goods to the Seller 
                        within 10 (ten) Business Days from when being informed that the goods 
                        are to be returned to the Seller.`,
                        {
                            0: `The Seller is entitled to an inspection period, for rejected goods, of 5 (five) 
                            days or as mutually agreed.  This period will start when:`,
                            items_ul: [
                                `The Seller acknowledges receipt of merchandise, service or domains; or`,
                                `Exportery receives a verification of delivery via the shipper's or 
                                registrar's tracking services.`,
                            ],
                        },
                        `If the Seller rejects the goods, the Trade will remain in a "dispute" 
                        status and the funds will be held in escrow until such a time the dispute is 
                        resolved. Section 12 of the Agreement namely “Dispute Resolution” will apply and 
                        the Parties will have 10 (ten) Business days to resolve the dispute and indicate 
                        on the Platform that the dispute was resolved.`,
                        `The Seller may only reject the goods if the goods have not been received in 
                        the same condition as they were sent. If the Seller fails to reject the goods 
                        within 5 (five) Business Days, it will be deemed to have accepted the goods 
                        and the funds will be released to the Buyer.`,
                    ],
                },
                {
                    title: 'Rejection of Domain Names',
                    text: `If the Trade involves a domain name and the Buyer rejects a domain 
                    name (which is not being held by Exportery), then, in addition to the 
                    normal terms of rejection, the Seller agrees to cooperate in the return 
                    process of the domain.  In the event the domain transfer to the Buyer has 
                    caused an ICANN imposed registrar lock, the Buyer and Seller agree that 
                    the Seller will open an account at the receiving registrar to take 
                    possession of the domain or that the funds will be held in escrow until 
                    this period expires and the domain can be returned to the Seller’s registrar.`,
                },
            ],
        },
        {
            title: 'KYC and AML requirements',
            items_ol: [
                {
                    text: `Exportery may, at various times and depending on a range of factors 
                    in its sole discretion, including the amount of a Trade actioned and/or 
                    the exact nature of the User, require that a User submit certain information 
                    to Exportery for the User to be verified as not infringing any of Exportery’s 
                    KYC and/or AML requirements and/or local or foreign laws. This information may 
                    include identity documents, passport documents and/or bank account information. 
                    Exportery reserves the right to limit or terminate a User’s access and use of the 
                    Services should the User fail to adhere to these requirements to the standard required 
                    by Exportery. Exportery also reserves the right to share this information with any 
                    legal authority when required under applicable laws.`,
                },
                {
                    text: `Exportery may restrict User transactions that may violate laws or 
                    Exportery’s internal KYC or AML conditions herein and as updated from time to time.`,
                },
            ],
        },
        {
            title: 'Duty and capacity of users',
            items_ol: [
                {
                    title: 'Duty to Notify',
                    text: {
                        0: `Users agree to notify Exportery as soon as possible once any Trigger 
                        Event is met, or there is a dispute in respect of any Trade.`,
                        1: '',
                        2: {
                            text: `Exportery is not liable for any damages suffered by any User due the failure 
                            of a User to notify Exportery or the Parties on the Platform.`,
                            strong: true,
                        },
                    },
                },
                {
                    title: 'Duty to Act',
                    text: `Users agree that they will act promptly and act as soon as 
                    possible according to the obligations imposed on them under any Trade.`,
                },
                {
                    title: 'Capacity of Users',
                    text: [
                        {
                            0: `Each User represents and warrants that the User (and any person who uses Exportery):`,
                            items_ul: [
                                `is old enough under applicable law to enter into the Agreement (i.e. over 
                                the age of 18 (eighteen) years old);`,
                                `is legally capable of concluding any transaction;`,
                                `possesses the legal right, full power, and authority to enter into the Agreement;`,
                                `is authorised to access and use the account; and`,
                                `will submit true, accurate and correct information to Exportery and the Platform.`,
                            ],
                        },
                        `If User is younger than 18 years of age, User warrants that User 
                        has the consent of its legal guardian to enter into the Agreement and 
                        will be supervised by their guardian when using the Services and/or 
                        Platform, or that User has obtained legal status in another manner.`,
                    ],
                },
            ],
        },
        {
            title: 'Cancellation',
            text: {
                0: `The Parties may agree to cancel a Trade if all Parties agree 
                to such cancellation in writing.`,
                1: '',
                2: {
                    text: `Exportery may cancel any Trade at any time in its absolute 
                    discretion, provided it gives the relevant users reasonable notice.`,
                    strong: true,
                },
                3: '',
                4: `Exportery will refund any monies already paid by the Buyer less the Fees due.`,
            },
        },
        {
            title: 'Transactions involving an agent or broker as a party',
            items_ol: [
                {
                    title: 'Overview',
                    text: [
                        `A Buyer and Seller may participate in a Trade with or without the role of an 
                        Agent or Broker. The Terms will apply to the Agent once the User creates an 
                        account on the Platform.`,
                        `To the extent that an Agent or Broker may be involved in a transaction, 
                        it is recommended that the Agent or Broker creates the Trade between Buyer 
                        and the Seller. The User can do so by providing the Buyer’s and Seller’s email 
                        addresses on the Exportery Platform. If the Agent manages or leads other 
                        sub-agent/s and the said sub-agent/s are expected to participate in the 
                        Trade, the Agent will have the opportunity to add the sub-agent/s to 
                        the transaction as a payment beneficiary`,
                        `Neither Agent, Broker nor other payment beneficiaries will have the ability 
                        to accept or dispute a Trade on the Buyer’s or Seller’s behalf.`,
                    ],
                },
                {
                    title: 'Third Party Terms',
                    text: [
                        `If a third party supplies or delivers any goods or services directly to you, 
                        third party terms or conditions may apply. Users are solely responsible to 
                        ensure they understand and agree to those terms.`,
                    ],
                },
            ],
        },
        {
            title: 'Integrations',
            text: [
                `Any User, company or their affiliates, who integrates their systems or technology 
                with Exportery, be it either via its API or other partnership portals or gateways, 
                be it in whole or in part, shall be deemed to agree to the Terms as set forth in 
                this Agreement.`,
                `Merchants using the Platform and/or Exportery Services shall also be required to 
                complete a merchant agreement with Exportery, dictating the additional terms to 
                which they are subject when using the Platform and/or Services.`,
            ],
        },
        {
            title: 'Unclaimed funds',
            text: `To the extent permitted by law, Users registered with Exportery with a Georgian 
            or Nigerian address, that have escrow funds held on deposit for longer than 36 
            (thirty-six) months and have not allocated such funds to a valid transaction, 
            will have their unused balance refunded to them.`,
        },
        {
            title: 'Support',
            text: {
                0: `Services include access to priority email support which can be reached at`,
                1: '',
                2: {
                    text: 'feedback@exportery.com',
                    href: 'mailto:feedback@exportery.com',
                },
                3: '',
                4: `"Email support" means the ability to make requests for technical 
                support assistance by email at any time (with reasonable efforts by 
                Exportery to respond within 1 (one) Business Day) concerning the use 
                of the Services.`,
            },
        },
        {
            title: 'Disclaimer of warranties',
            items_ol: [
                {
                    title: 'Disclaimer',
                    text: [
                        {
                            0: `Use of the Platform is at the sole responsibility and risk 
                            of each User.  The Platform is provided on an "as is" and "as 
                            available" basis.  Except for the warranties given in the 
                            Agreement, Exportery expressly disclaims, to the extent 
                            allowed by law, all representations, warranties, or conditions of any kind, 
                            whether express or implied, including:`,
                            items_ul: [
                                `any implied warranties or conditions of satisfactory quality, no latent 
                                defects, merchantability, fitness for a particular purpose, accuracy, 
                                system integration, quiet enjoyment, title, and non-infringement; or`,
                                `any warranties with respect to third party software.`,
                            ],
                        },
                        `Exportery does not warrant that the Platform will meet the requirements of any 
                        User or be uninterrupted, be legally effective or complete, timely, secure or 
                        error-free.  Each User should keep up-to-date security software on the systems 
                        used to access the Platform.`,
                    ],
                },
            ],
        },
        {
            title: 'Acceptable use and conduct',
            items_ol: [
                {
                    title: 'Responsibility',
                    text: `Each User is responsible for its conduct and Trades on the Platform.  
                    Each User must ensure that it has the correct permissions to act on the Platform.`,
                },
                {
                    title: 'Prohibited conduct',
                    text: [
                        `Nothing performed by a User on the Platform or as part of the Service may be 
                        unlawful, harmful, threatening, abusive, harassing, defamatory, libelous, 
                        invasive of another's privacy, or harm minors in any way.  Users are prohibited 
                        from using the Platform for the purposes of money laundering, fraud, deception, 
                        the sale or distribution of narcotics or firearms, or any other activity that 
                        is considered unlawful or harmful.`,
                        `Users must not harass, stalk, degrade, intimidate, or be hateful toward an 
                        individual or group of individuals.`,
                        `No User may impersonate any person, falsely state, or otherwise misrepresent 
                        its affiliation with any person.  Nothing may be false, deceptive, misleading 
                        or deceitful.`,
                        `Users must not infringe any patent, trademark, trade secret, copyright, 
                        or other proprietary rights of any party.`,
                        `Any chain letter, pyramid scheme, or advertisement that is prohibited by law, 
                        is prohibited on the Platform.  The advertising and provision of any illegal 
                        service or the sale of any goods that are prohibited or restricted by 
                        applicable law are prohibited.`,
                        `Malware is prohibited.  Do not negatively affect the ability of any other User 
                        to use the Platform.  Do not employ misleading email or IP addresses, or forged 
                        headers or otherwise manipulated identifiers in Trade s to disguise the origin 
                        of content transmitted through the Platform.`,
                        `No User may impose an unreasonable or disproportionately large 
                        load on Exportery's infrastructure.`,
                        `Users must not attempt to gain unauthorised access to the Platform or engage 
                        in any activity that disrupts or diminishes the quality of, interferes with the 
                        performance of, or impairs the functionality of the Platform.`,
                        `Exportery will report any suspicious or illegal activity detected on the Platform 
                        to the relevant authorities.`,
                    ],
                },
            ],
        },
        {
            title: 'Intellectual property',
            items_ol: [
                {
                    title: 'Ownership',
                    text: `Except as provided to the contrary in the Agreement, all right, 
                    title, interest, and ownership (including all rights under all copyright, 
                    patent, and other intellectual property laws) in, to or of the Platform are 
                    the sole property of or will vest in Exportery or a third-party licensor. 
                    All moral rights are reserved.`,
                },
                {
                    title: 'Trademarks',
                    text: `Exportery’s logo and sub-logos, marks, and trade names are the trademarks of 
                    Exportery, and no person may use them without permission.  Any other trademark or 
                    trade name that may appear on the Platform or other marketing material of Exportery 
                    is the property of its respective owner.`,
                },
                {
                    title: 'Restrictions',
                    text: {
                        0: `Except as expressly permitted under the Agreement, the Platform may not be:`,
                        items_ul: [
                            `modified, distributed, or used to make derivative works;`,
                            `rented, leased, loaned, sold or assigned;`,
                            `decompiled, reverse engineered, or copied; or`,
                            `reproduced, transferred, or distributed.`,
                        ],
                    },
                },
                {
                    title: 'Prosecution',
                    text: `All violations of proprietary rights or the Agreement will be prosecuted to 
                    the full extent permissible under applicable law.`,
                },
            ],
        },
        {
            title: 'Hyperlinks, deep links, framing',
            items_ol: [
                {
                    text: `The Platform may include links to other internet sites 
                    ("the other sites"). Exportery does not own or endorse the other sites 
                    and is not responsible for the information, material, products or services 
                    contained on or accessible through the other sites. Any such hyperlinks do 
                    not imply any endorsement, agreement on or support of the content or products 
                    of such target sites.`,
                },
                {
                    text: {
                        0: `Exportery does not purport to own the content on other sites 
                        which may be shown on the Platform. Should the owner of any content showcased 
                        on the Platform want the content to be removed, please write to`,
                        1: '',
                        2: {
                            text: 'feedback@exportery.com',
                            href: 'mailto:feedback@exportery.com',
                        },
                        3: '',
                        4: `to request the removal of such content.`,
                    },
                },
                {
                    text: `The User’s access and use of the other sites remain solely at the User’s 
                    own risk and on the terms set by the relevant third-party operator of the other sites.`,
                },
            ],
        },
        {
            title: 'Advertising and sponsorship',
            items_ol: [
                {
                    text: `The Platform may contain advertising and sponsorship. Advertisers 
                    and sponsors are responsible for ensuring that material submitted for inclusion 
                    in the Platform complies with all applicable laws and regulations.`,
                },
                {
                    text: `Exportery, its shareholders, employees, suppliers, partners, 
                    affiliates and agents accordingly exclude, to the maximum extent permitted in law, 
                    any responsibility or liability for any error or inaccuracy appearing in advertising 
                    or sponsorship material.`,
                },
            ],
        },
        {
            title: 'Indemnity',
            items_ol: [
                {
                    text: `The User indemnifies and holds harmless Exportery, its shareholders, 
                    employees, and partners from any demand, action, regulation or application or other 
                    proceedings, including for attorneys’ fees and related costs such as tracing fees, 
                    made by any third party and arising out of or in connection with the User’s use of 
                    the Platform or Exportery Services offered or concluded through the Platform in any way.`,
                },
                {
                    text: `The User agrees to indemnify, defend, and hold Exportery harmless from 
                    any direct or indirect liability, loss, claim and expense (including reasonable legal 
                    fees) related to the User’s use of the Platform and/or the Exportery Services and 
                    for breach of these Terms.`,
                },
                {
                    text: `This clause will survive termination of this Agreement.`,
                },
            ],
        },
        {
            title: 'Disclaimers',
            items_ol: [
                {
                    text: `The Platform, including any intellectual property appearing therein, 
                    is provided "as is" and "as available". Exportery makes no representations 
                    or warranties, express or implied, including but not limited to warranties as 
                    to the accuracy, correctness or suitability of either the Platform, information 
                    provided by another user, the information contained on the Platform in any way.`,
                },
                {
                    text: `All information or opinions of Users made available on the Platform 
                    in relation to any of the Exportery Services are those of the authors and not 
                    Exportery. While Exportery makes every reasonable effort to present such information 
                    accurately and reliably on the Platform, Exportery does not endorse, approve or 
                    certify such information, nor guarantee the accuracy or completeness of such 
                    information on the Platform or from another User.`,
                },
                {
                    text: `To the fullest extent permitted by law, Exportery, its shareholders, 
                    employees, and partners, accept no liability whatsoever for any loss, whether direct 
                    or indirect, consequential, or arising from information made available on (or 
                    by means of) the Platform, other users thereon, and/or transactions or actions 
                    resulting therefrom, including a user’s use of any Services in any way.`,
                },
                {
                    text: `To the fullest extent permitted by law, Exportery, its shareholders, 
                    employees, partners and affiliates, accept no liability whatsoever for any costs, 
                    expenses, fines or damages, including but not limited to direct or indirect loss or 
                    damages, including any economic loss, consequential loss, loss of profits or any form 
                    of punitive damages, resulting from the facilitation and offering of the Exportery 
                    Services, and access to, or use of, the Platform in any manner.`,
                },
                {
                    text: `Users from locations outside of Nigeria and Georgia, please note that 
                    Exportery complies with all relevant Georgian and Nigerian laws in representing and 
                    offering the Exportery Services. Should foreign law be applicable in any regard to 
                    your use of the Exportery Services and/or the Platform in any way, you warrant that 
                    you are at all times acting in accordance with same foreign law, and indemnify Exportery 
                    from any liability it may acquire by virtue of its supply of the Platform and/or 
                    Exportery Services.`,
                },
                {
                    text: `Exportery takes reasonable security measures to ensure the safety and integrity 
                    of the Platform and to exclude viruses, unlawful monitoring and/or access from the 
                    Platform. However, Exportery does not warrant or represent that your access to 
                    the Platform will be uninterrupted or error free or that any information, data, 
                    content, software or other material accessible through the Platform will be 
                    free of bugs, viruses, worms, trojan horses or other harmful components. The 
                    User’s access to and use of the Platform remains solely at the User’s own 
                    risk and the User should take their own precautions accordingly.`,
                },
            ],
        },
        {
            title: 'Limitation of liability',
            items_ol: [
                {
                    title: 'Correct Faults',
                    text: `Exportery will correct any fault in the Platform where possible and as 
                    soon as reasonably practical and this is its entire liability regarding any 
                    fault in the Platform.  If this clause is held inapplicable or unenforceable, 
                    then the following clause will apply.`,
                },
                {
                    title: 'Direct Damages Limited',
                    text: `To the extent permitted by applicable law, regardless of the form (whether in 
                    contract, tort, or any other legal theory) in which any legal action may be brought, 
                    Exportery’s maximum liability to a User for direct damages for anything giving rise 
                    to any legal action will be an amount equal to the total fees already paid by the 
                    User to Exportery for the Services related to the claim. The aggregate amounts 
                    for all claims will not be greater than the maximum amount.`,
                },
            ],
        },
        {
            title: 'Termination',
            items_ol: [
                {
                    title: 'Discontinuance of the Platform',
                    text: `The Agreement will automatically terminate if Exportery discontinues the Platform.`,
                },
                {
                    title: 'Breach',
                    text: {
                        0: 'If a User:',
                        items_ul: [
                            `commits a breach of the Agreement;`,
                            `repeatedly infringes the copyrights or other rights of others;`,
                            `fails to pay any amount due and fails to remedy the breach within 2 (two) calendar days of 
                            having been called on in writing to do so;`,
                            `affects or attempts to affect a compromise or composition with its creditors;`,
                            `is suspected or found to be using the Platform in connection with a fraudulent or illegal 
                            activity in any way; or`,
                            `is provisionally or finally liquidated or placed under judicial management. 
                            then the User agrees that Exportery may, to the extent permitted by applicable 
                            law and without prejudice to its rights in the Agreement or in law, deactivate 
                            or delete the User’s account, terminate access to or use of the Platform, 
                            claim specific performance of the Agreement, or cancel the Agreement with 
                            the User immediately on written notice and claim damages from User (including, 
                            any claim for any Fees already due).`,
                        ],
                    },
                },
                {
                    title: 'Effects of Termination',
                    text: `On the deactivation or deletion of a User’s account, the Agreement with the User 
                    will terminate, and access rights will immediately cease to exist. On termination, 
                    cancellation or expiry of any Trade, all amounts due to Exportery for Services rendered 
                    prior to termination will become due and payable even if Exportery has not invoiced them. 
                    On termination, any amount that owing to a party shall be paid or refunded to that party. 
                    User may not withhold the amounts for any reason, unless the arbitrator directs otherwise. 
                    The termination, cancellation, or expiry of a Trade will not affect the enforceability 
                    of the terms that are intended to operate after expiry or termination.`,
                },
            ],
        },
        {
            title: 'Disputes between Users and Exportery',
            items_ol: [
                {
                    title: 'Scope',
                    text: `This dispute clause will only apply to a dispute between Users and 
                    Exportery and will not regulate disputes between Users.`,
                },
                {
                    title: 'Notifying Each Other',
                    text: {
                        0: `There will be a dispute about or from this Agreement if a Party writes 
                        to the other about it and asks for it to be resolved under this clause. 
                        The Parties must refer any dispute to be resolved by:`,
                        items_ul: [
                            `Negotiation ((direct talks to try and agree how to end the dispute); 
                            failing which`,
                            `Mediation (talks in which a neutral third party tries to help the parties agree how to end the dispute); failing which`,
                            `Arbitration (a hearing after which a neutral third party makes a binding decision about the dispute).`,
                        ],
                    },
                },
                {
                    title: 'Negotiation',
                    text: `Each Party must make sure that their chosen representatives meet within 10 (ten) Business 
                    Days of notification, to negotiate and try to end the dispute by written agreement 
                    within 15 (fifteen) more Business Days.`,
                },
                {
                    title: 'Mediation',
                    text: `If negotiation fails, the Parties must refer the dispute to mediation 
                    under the inherent rules in the country of dispute.`,
                },
                {
                    title: 'Arbitration',
                    text: `If mediation fails, the Parties must refer the dispute within 15 
                    (fifteen) Business Days to Arbitration (including any appeal against the 
                    arbitrator’s decision).`,
                },
                {
                    title: 'Periods',
                    text: `The Parties may agree in writing to change the periods for negotiation or mediation.`,
                },
                {
                    title: 'Urgent Interim Relief',
                    text: `This clause will not stop a Party from applying to court for urgent 
                    interim relief (temporary help) while the dispute resolution process is being 
                    finalised. An example might be an interdict.`,
                },
                {
                    title: 'Severability',
                    text: `This clause is separate and divisible from the rest of this Agreement 
                    and remains effective even if this agreement ends or is invalid.`,
                },
            ],
        },
        {
            title: 'Notice and domicile',
            items_ol: [
                {
                    title: 'Notices',
                    text: `All notices, authorisations, disclosures, acknowledgements, and requests 
                    must be sent by hand, prepaid registered post, courier, facsimile, or electronic 
                    mail to the addresses and numbers provided on the Platform. By providing contact 
                    information, each Party consents to its use for administering the relationship by 
                    the other Party and other third parties that help a Party administer the relationship.`,
                },
                {
                    title: 'Domicile',
                    text: `Each Party chooses its addresses and numbers provided on the Platform as 
                    its domicilium citandi et executandi (its domicile for being served summons and 
                    execution levied) for all purposes.`,
                },
                {
                    title: 'Change of Addresses and Numbers',
                    text: `Each party may change the addresses and numbers provided on the Platform.`,
                },
                {
                    title: 'Deemed Delivery',
                    text: `Notice will be deemed delivered on the date the notice was delivered by hand, 
                    or the date shown on prepaid registered post, courier, facsimile, or electronic mail 
                    confirmation of delivery.`,
                },
                {
                    title: 'Notice Received',
                    text: `If a notice is actually received by a Party, adequate notice will have been given.`,
                },
            ],
        },
        {
            title: 'Company information',
            text: {
                table: {
                    header: 'Site owner:  Exportery LLC',
                    rows: [
                        ['Legal status:', 'Limited Liability Company'],
                        ['Registration number:', '405545316'],
                        ['Director:', 'Limited Liability Company'],
                        ['Legal status:', 'Dmytro Yeremenko'],
                        ['Description of main business:', 'E-commerce Platform'],
                        ['Telephone number:', '+995 706 001 010'],
                        ['Email address:', {
                            0: {
                                text: 'feedback@exportery.com',
                                href: 'mailto:feedback@exportery.com',
                            },
                        }],
                        ['Application address:', ''],
                        ['Physical address:', ''],
                        ['Postal address:', ''],
                        ['Registered address:', {
                            0: 'Paata Datuashvili str. N23,',
                            1: 'apartment 76, 0158 Tbilisi',
                        }],
                    ],
                },
            },
        },
        {
            title: 'Circumstances beyond control',
            text: `No Party will be responsible for any breach of the Agreement caused by circumstances 
            beyond its control, including any breakdown or failure of power supply, failure of 
            performances of banks, force majeure, the Internet, any telecommunications systems, or 
            any computer hardware or software.`,
        },
        {
            title: 'Cession and assignment',
            text: `No User may delegate its duties under the Agreement or assign its rights 
            under the Agreement, in whole or in part, unless agreed in writing.`,
        },
        {
            title: 'Confidentiality and the processing of your personal information',
            text: [
                `Exportery takes client confidentiality very seriously. All information on the 
                Exportery platform is considered confidential and is encrypted using industry standards.`,
                {
                    0: `Exportery will never divulge, furnish, make available or disclose any client information 
                    or transaction information to any individual, company or party, except when and only 
                    when it is required by law AND the parties form part to the same transaction. For example:`,
                    items_ul: [],
                },
                {
                    0: `The following information will be divulged to all Parties involved in a 
                    particular transaction i.e. the Buyer and Seller to a particular transaction 
                    will have access to the following details of the other Party:`,
                    items_ul: [
                        `The individual's first and last name`,
                        `The User's Company name and Registration number`,
                        `Location or delivery address required for delivery`,
                        `Transaction details including documentation`,
                    ],
                },
                `Exportery will never sell or trade any customer information or participate in any 
                market related efforts that will require the use of client information or 
                transaction data.`,
                {
                    0: `All processing of your personally identifiable information will be conducted 
                    strictly in accordance with Exportery’s`,
                    1: '',
                    2: {
                        text: 'Privacy Policy',
                        // href: 'https://tradesafe.co.za/documents/privacy_policy.pdf',
                        href: 'https://exportery.com/privacy-policy',
                    },
                    3: '',
                    4: `which can be found on their website`,
                },
            ],
        },
        {
            title: `Non-performance of selling/supplying party in certain transaction classes`,
            text: [
                `In the context of this agreement and upon receipt by Exportery, the Give and 
                Take Act activates the transactions between the parties.`,
                `Where however, Exportery fails to perform to deliver the goods accepted by the 
                provisions of the Give and Take Act, the parties involved shall open a dispute via 
                mediation as provided under clause 35.4 of this terms and conditions.`,
                `Resort shall not be had to the court of law or litigation without prior notice to 
                Exportery and all forms of Alternative Dispute Resolution have been exhausted.`,
                {
                    0: `Should the Selling Party to Exportery transaction, operating in the 
                    following industries:`,
                    items_ul: [
                        `Original Equipment Manufacturer;`,
                        `The Military; or`,
                        `Government Agencies`,
                    ],
                    text: {
                        0: `fail to initiate delivery or perform on its contractual obligations, post 
                        the verification that the funds are in escrow, and the said non-performance 
                        was not due to force majeure AND the Selling Party was not the actual owner 
                        of the product or service, then Exportery, at its discretion, shall enforce 
                        the following punitive sanctions on the party listed as the Seller on the 
                        Exportery system:`,
                        items_ul: [
                            `Exportery will inform relevant industry role players and/or industry 
                            regulators of the Seller inability to perform as it is likely that 
                            the so-called supplier was masquerading as the owner of product.`,
                            `In the case of the military and the government agency, a direct contact 
                            with the Ministry responsible for such trade or transaction.`,
                        ],
                    },
                },
                `The aforesaid punitive sanctions will also be levied on the Selling Party if it is 
                found that the Seller had furnished Exportery's letter of funds confirmation to a 
                party outside of the transaction without Exportery's written consent.`,
            ],
        },
        {
            title: 'General',
            items_ol: [
                {
                    title: 'Entire Agreement',
                    text: `The Agreement constitutes the entire agreement between the Parties in respect 
                    of the subject matter of the Agreement.`,
                },
                {
                    title: 'Changes to Agreement',
                    text: `The Agreement may be changed at any time by Exportery and where this affects 
                    the rights and obligations of a User, Exportery will notify the User of any changes 
                    by placing a notice in a prominent place on the Platform (or by email). 
                    If a User does not agree with the change the User should stop using the Platform. 
                    If a User continues to use the Platform following notification of a change to the 
                    Agreement, the changed terms will apply to the User.`,
                },
                {
                    title: 'Waiver',
                    text: `No granting of time or forbearance will be, or be deemed to be, a waiver 
                    of the Agreement and no waiver of any breach will operate as a waiver of any 
                    continuing or subsequent breach.`,
                },
                {
                    title: 'Severability',
                    text: `If any term is void, unenforceable, or illegal, the term will be severed and 
                    the remainder of the Agreement will have full force and effect, provided the severance 
                    does not alter the nature of the Agreement.`,
                },
                {
                    title: 'Governing Law',
                    text: `The Agreement is governed by and must be interpreted under the laws 
                    of the Republic of Georgia and any applicable law in Nigeria.`,
                },
            ],
        },
    ],
};