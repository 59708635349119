import {createRequestHandler} from '../../utils';

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';

export const CATEGORY_PRODUCTS_REQUEST = 'CATEGORY_PRODUCTS_REQUEST';
export const CATEGORY_PRODUCTS_SUCCESS = 'CATEGORY_PRODUCTS_SUCCESS';
export const CATEGORY_PRODUCTS_FAILURE = 'CATEGORY_PRODUCTS_FAILURE';

export const PRODUCTS_UPDATING_REQUEST = 'PRODUCTS_UPDATING_REQUEST';
export const PRODUCTS_UPDATING_SUCCESS = 'PRODUCTS_UPDATING_SUCCESS';
export const PRODUCTS_UPDATING_FAILURE = 'PRODUCTS_UPDATING_FAILURE';

export const productsRequest = createRequestHandler('PRODUCTS');
export const categoryProductsRequest = createRequestHandler('CATEGORY_PRODUCTS');
export const updatingProductsRequest = createRequestHandler('PRODUCTS_UPDATING');

const initialState = {
    productsOptions: [],
    products: [],
    loading: true,
    updating: false,
    message: null,
    error: null,

    filters: null,
    sorting: null,
    isFilterOpen: false,
};

const products = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PRODUCTS_REQUEST:
        case CATEGORY_PRODUCTS_REQUEST:
        case 'RESET_MARKETPLACE_PRODUCTS':
            return initialState;

        case PRODUCTS_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case PRODUCTS_SUCCESS:
        case CATEGORY_PRODUCTS_SUCCESS:
            const {products, sort, ...restProps} = payload;
            if (products) {
                const {list, ...options} = products;

                return {
                    ...initialState,
                    productsOptions: options,
                    products: list,
                    loading: false,
                    updating: false,

                    filters: restProps,
                    sorting: sort,
                };
            } else {
                return {
                    ...initialState,
                    message: payload.message,
                    loading: false,
                    updating: false,
                };
            }

        case PRODUCTS_UPDATING_SUCCESS:
            const {products: updatedProducts, sort: updatedSort, ...restUpdatedSort} = payload;
            let {list, ...options} = updatedProducts;

            return {
                ...state,
                productsOptions: options,
                products: list,
                updating: false,

                filters: restUpdatedSort,
                sorting: updatedSort,
            };

        case PRODUCTS_FAILURE:
        case CATEGORY_PRODUCTS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case PRODUCTS_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'TOGGLE_MARKETPLACE_FILTER':
            return {
                ...state,
                isFilterOpen: payload,
            };

        default:
            return state;
    }
};

export default products;