export default class MyProductsService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/hub';
    }

    getMyProducts = (query) => {
        return this._http.get(`${this.baseUrl}/products/list`, query);
    };

    getSearchData = (query) => {
        return this._http.get(`${this.baseUrl}/search`, query);
    };
}