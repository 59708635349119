import React, {useEffect, useRef, useState} from 'react';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import {leftTooltipPositionHandler} from '../tooltip-creator/handlers';

import TooltipCreator from '../tooltip-creator';

import classnames from 'classnames/bind';
import styles from './select.module.scss';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/svg-arrow.css';

const Select = (props) => {
    const {
        staticData, type, withArrow, isOpenSelect, selectedItem, isAvailableSelectedItem,
        zIndex, withLabel, label, name, tooltip, children, selectedItemClasses,
        optionsClassName, placement, onToggleSelect, props: selectProps,
        offsetWithArrow, offsetWithoutArrow,
    } = props;
    const {search_field_placeholder, search_option_not_found} = staticData.select;

    const labelRef = useRef();
    const [state, setState] = useState({options: [], leftTooltipPosition: 0});
    const searchInput = useRef();

    useEffect(() => {
        if (children) {
            setState(state => ({...state, options: children}));
        }
    }, [children]);

    useEffect(() => {
        if (labelRef.current) {
            if (type === 'SECONDARY' && withLabel && label) {
                setState(state => ({
                    ...state,
                    leftTooltipPosition: leftTooltipPositionHandler(labelRef.current),
                }));
            }
        }
    }, [type, withLabel, label]);

    useEffect(() => {
        if (!isOpenSelect) {
            if (searchInput.current) {
                searchInput.current.value = '';
            }
        }
    }, [isOpenSelect]);

    const onChangeSearchInputValue = ({target: {value}}) => {
        const newOptions = children.filter(({props: {children: option}}) => {
            const optionInLowerCase = option.toLowerCase();
            const valueInLowerCase = value.toLowerCase();

            return optionInLowerCase.includes(valueInLowerCase);
        });
        setState(state => ({...state, options: newOptions}));
    };

    let isRequired, isDisabled, isReadOnly, isSearch;
    if (selectProps) {
        isRequired = selectProps.includes('required');
        isDisabled = selectProps.includes('disabled');
        isReadOnly = selectProps.includes('readonly');
        isSearch = selectProps.includes('search');
    }

    const cx = classnames.bind(styles);
    const tippySelectedItemClasses = cx(
        'selected', selectedItemClasses, {
            'primary': type === 'PRIMARY',
            'secondary': type === 'SECONDARY',
            'not-available': !isAvailableSelectedItem,
            'disabled': isDisabled || isReadOnly,
        },
    );
    const tippyOptionsClasses = cx('options', optionsClassName, {
        'primary': type === 'PRIMARY',
        'secondary': type === 'SECONDARY',
        'with-search': isSearch,
    });

    const labelClasses = cx('label', {'without-tooltip': !tooltip});

    return (
        <Tippy
            content={(
                <ul className={tippyOptionsClasses}>
                    {isSearch && (
                        <li className={styles['search-block']}>
                            <input ref={searchInput}
                                   type="text"
                                   className={styles.search}
                                   placeholder={search_field_placeholder}
                                   onChange={onChangeSearchInputValue}/>
                        </li>
                    )}

                    {state.options}

                    {isSearch && state.options.length === 0 &&
                    <li className={styles['empty-message']}>{search_option_not_found}</li>}
                </ul>
            )}
            animation={'shift-away'}
            arrow={withArrow}
            appendTo={'parent'}
            popperOptions={{modifiers: [{name: 'flip', enabled: false}]}}
            interactive={true}
            maxWidth="none"
            role="select"
            offset={withArrow ? offsetWithArrow : offsetWithoutArrow}
            placement={placement}
            visible={isOpenSelect}
            onClickOutside={() => onToggleSelect(false)}
            zIndex={zIndex}
        >
            <div className={tippySelectedItemClasses} data-name={name}
                 onClick={() => (!isDisabled && !isReadOnly) && onToggleSelect(!isOpenSelect)}>
                <div className={styles['selected-item']}>{selectedItem}</div>

                {(type === 'SECONDARY' && withLabel) && (
                    <>
                        <span ref={labelRef} className={labelClasses}>
                            {label}
                            {isRequired && <span className={styles['required-icon']}>*</span>}
                        </span>
                        {tooltip &&
                        <TooltipCreator tooltip={tooltip}
                                        style={{left: `${state.leftTooltipPosition}px`}}/>}
                    </>
                )}
            </div>
        </Tippy>
    );
};

Select.defaultProps = {
    type: 'PRIMARY',
    placement: 'bottom-start',
    withArrow: false,
    isOpenSelect: false,
    isAvailableSelectedItem: true,
    zIndex: 1,
    withLabel: false,
    name: 'select_component',
    offsetWithArrow: [0, 12],
    offsetWithoutArrow: [0, 0],
};

Select.propTypes = {
    staticData: PropTypes.object,
    type: PropTypes.oneOf(['PRIMARY', 'SECONDARY']),
    withArrow: PropTypes.bool,
    isOpenSelect: PropTypes.bool,
    selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    isAvailableSelectedItem: PropTypes.bool,
    zIndex: PropTypes.number,
    withLabel: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    tooltip: PropTypes.object,
    children: PropTypes.node,
    offsetWithArrow: PropTypes.arrayOf(PropTypes.number),
    offsetWithoutArrow: PropTypes.arrayOf(PropTypes.number),
    selectedItemClasses: PropTypes.string,
    labelClassName: PropTypes.string,
    optionsClassName: PropTypes.string,
    placement: PropTypes.string,
    selectProps: PropTypes.array,
    onToggleSelect: PropTypes.func,
};

export default withDataService()(Select);