import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {onInitSectionStateFromDataByServer, sectionSettingsHandler} from '../../common/handlers';
import {
    transformFieldWithDependsFrom,
    onChangeFieldValue,
    onChangeCheckboxGroupValue,
    onChangeRadioButtonValue,
    onCheckingRequiredFieldData,
    onResetFormError,
    formDataObjectHandler,
    onTransformFieldsWithSelectInputGroup,
} from '../../../../../common/handlers';

import {
    fetchSessionStorageItems,
    transformFieldData,
    getDataFromFields,
} from '../../../../../common/handlers/field-handlers';

import DeliveryPayment from './delivery-payment';

class DeliveryPaymentContainer extends Component {
    state = {
        id: null,
        deliveryPaymentFields: null,
        regulatoryFrameworkFields: null,
        shipmentConditionsFields: null,
        packagingFields: null,
        dangerousFields: null,
        isDisabledSection: false,
        isDisabledAlwaysSection: false,
        isSaveButtonDisabled: false,
        formError: false,
    };

    storageKey = 'delivery_and_payment';

    componentDidMount() {
        this.setState({id: this.props.id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (this.props.sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, this.props.sections);

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }

        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                const {list, storageItem, pageMode} = this.props;

                const sessionStorageItems = fetchSessionStorageItems(storageItem, this.storageKey);

                let deliveryPayment = list
                    .filter(({section}) => section.id === 'delivery_payment')
                    .map((field) => transformFieldData(field, sessionStorageItems));

                let regulatoryFramework = list
                    .filter(({section}) => section.id === 'regulatory_framework')
                    .map((field) => transformFieldData(field, sessionStorageItems));

                let shipmentConditions = list
                    .filter(({section}) => section.id === 'shipment_conditions')
                    .map((field) => transformFieldData(field, sessionStorageItems));

                let packaging = list
                    .filter(({section}) => section.id === 'packaging')
                    .map((field) => transformFieldData(field, sessionStorageItems));

                let dangerous = list
                    .filter(({section}) => section.id === 'dangerous')
                    .map((field) => transformFieldData(field, sessionStorageItems))
                    .map((field, idx, arr) => {
                        if (field.dependsFrom) {
                            return transformFieldWithDependsFrom(arr, field);
                        }

                        return field;
                    });

                if (deliveryPayment.filter(({group}) => group).length > 0) {
                    deliveryPayment = onTransformFieldsWithSelectInputGroup(deliveryPayment);
                }

                if (regulatoryFramework.filter(({group}) => group).length > 0) {
                    regulatoryFramework = onTransformFieldsWithSelectInputGroup(regulatoryFramework);
                }

                if (shipmentConditions.filter(({group}) => group).length > 0) {
                    shipmentConditions = onTransformFieldsWithSelectInputGroup(shipmentConditions);
                }

                if (packaging.filter(({group}) => group).length > 0) {
                    packaging = onTransformFieldsWithSelectInputGroup(packaging);
                }

                if (dangerous.filter(({group}) => group).length > 0) {
                    dangerous = onTransformFieldsWithSelectInputGroup(dangerous);
                }

                const allFields = [
                    ...deliveryPayment,
                    ...regulatoryFramework,
                    ...shipmentConditions,
                    ...packaging,
                    ...dangerous,
                ];

                if (pageMode === 'EDIT') {
                    const isDisabled = onInitSectionStateFromDataByServer(allFields);
                    if (isDisabled !== this.state.isDisabledSection) {
                        this.props.updatedPlaceProductSectionDisabledStatus(this.state.id, isDisabled);
                    }
                }

                this.setState({
                    deliveryPaymentFields: deliveryPayment,
                    regulatoryFrameworkFields: regulatoryFramework,
                    shipmentConditionsFields: shipmentConditions,
                    packagingFields: packaging,
                    dangerousFields: dangerous,
                });
            }
        }

        if (prevProps.fetchingDataFromSection !== this.props.fetchingDataFromSection) {
            const {fetchingDataFromSection} = this.props;

            if (fetchingDataFromSection && fetchingDataFromSection.currentSection === this.state.id) {
                this.onUploadDataFromSection();
            }
        }
    }

    onChangeValue = (name, value) => {
        const {
            deliveryPaymentFields,
            regulatoryFrameworkFields,
            shipmentConditionsFields,
            packagingFields,
            dangerousFields,
        } = this.state;

        const currentFieldsChecking = (fields) => fields.findIndex((field) => field.name === name) !== -1;

        const isDeliveryPaymentSection = currentFieldsChecking(deliveryPaymentFields);
        const isRegulatoryFrameworkSection = currentFieldsChecking(regulatoryFrameworkFields);
        const isShipmentConditionsSection = currentFieldsChecking(shipmentConditionsFields);
        const isPackagingSection = currentFieldsChecking(packagingFields);
        const isDangerousSection = currentFieldsChecking(dangerousFields);

        if (isDeliveryPaymentSection) {
            const newDeliveryPaymentFields = onChangeFieldValue(deliveryPaymentFields, name, value);
            this.setState({deliveryPaymentFields: newDeliveryPaymentFields});
        }

        if (isRegulatoryFrameworkSection) {
            const newRegulatoryFrameworkFields = onChangeFieldValue(regulatoryFrameworkFields, name, value);
            this.setState({regulatoryFrameworkFields: newRegulatoryFrameworkFields});
        }

        if (isShipmentConditionsSection) {
            const newShipmentConditionsFields = onChangeFieldValue(shipmentConditionsFields, name, value);
            this.setState({shipmentConditionsFields: newShipmentConditionsFields});
        }

        if (isPackagingSection) {
            const newPackagingFields = onChangeFieldValue(packagingFields, name, value);
            this.setState({packagingFields: newPackagingFields});
        }

        if (isDangerousSection) {
            const newDangerousFields = onChangeFieldValue(dangerousFields, name, value);
            this.setState({dangerousFields: newDangerousFields});
        }

        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onGroupCheckboxChange = ({target}) => {
        const name = target.name;
        const value = parseInt(target.value);
        const updatedFields = onChangeCheckboxGroupValue(this.state.deliveryPaymentFields, name, value);

        this.setState({deliveryPaymentFields: updatedFields});
        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onRadioButtonChange = ({target: {id, name, value}}) => {
        const updatedFields = onChangeRadioButtonValue(this.state.dangerousFields, id, name, value);
        this.setState({dangerousFields: updatedFields});
        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onSaveButtonChange = () => {
        if (this.state.isSaveButtonDisabled && !this.state.isDisabledSection) {
            this.setState({isSaveButtonDisabled: false});
            this.props.onUpdateSaveStatus(this.state.id, false);
        }
    };

    onResetFormFieldError = () => {
        if (this.state.formError) {
            onResetFormError(this.state.id);
            this.setState({formError: false});
        }
    };

    onUploadDataFromSection = (action = 'auto-get') => {
        const form = document.getElementById(`${this.state.id}_form`);

        const {
            deliveryPaymentFields,
            regulatoryFrameworkFields,
            shipmentConditionsFields,
            packagingFields,
            dangerousFields,
        } = this.state;

        const allFields = [
            ...deliveryPaymentFields,
            ...regulatoryFrameworkFields,
            ...shipmentConditionsFields,
            ...packagingFields,
            ...dangerousFields,
        ].filter(({isShown}) => isShown === undefined || isShown);

        const valid = onCheckingRequiredFieldData(allFields, form);

        if (valid) {
            const data = getDataFromFields(allFields);
            const formData = new FormData(form);
            const object = formDataObjectHandler(formData, data, allFields);

            if (action === 'save' && this.props.pageMode !== 'EDIT') {
                this.props.onSave(this.state.id, object, `${this.props.storageItem}_${this.storageKey}`);

            } else {
                this.props.onSave(this.state.id, object);
            }

            this.setState({isSaveButtonDisabled: true});

        } else {
            this.setState({formError: true});
            this.props.onFetchingDataFromSectionCallback(this.state.id, 'error');
        }
    };

    onDeliveryPaymentSave = () => {
        this.onUploadDataFromSection('save');
    };

    render() {
        const {delivery_and_payment} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {id, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;

        return <DeliveryPayment {...this.props} {...rest}
                                id={id}
                                panelData={delivery_and_payment}
                                isDisabled={isDisabledSection}
                                isDisabledAlways={isDisabledAlwaysSection}
                                onInputChange={({target: {name, value}}) => this.onChangeValue(name, value)}
                                onClickSelectItem={(name, {value}) => this.onChangeValue(name, value)}
                                onGroupCheckboxChange={this.onGroupCheckboxChange}
                                onRadioButtonChange={this.onRadioButtonChange}
                                onClick={() => this.props.onClick(id)}
                                onSave={this.onDeliveryPaymentSave}
                                onSkip={() => this.props.onSkip(id)}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onUpdateSaveStatus: () => {
        },
        onClick: () => {
        },
        onSave: () => {
        },
    };

    static propsTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        sections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        list: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        fetchingDataFromSection: PropTypes.object,
        onUpdateSaveStatus: PropTypes.func,
        updatedPlaceProductSectionDisabledStatus: PropTypes.func,
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
        onFetchingDataFromSectionCallback: PropTypes.func,
    };
}

export default DeliveryPaymentContainer;