/* in the order of displaying the sections for place product (new/edit page)
* id:required:disabled:disabled_always */

export const IDs = [
    'product_information:isRequired',
    'delivery_and_payment:isRequired',
    'photo',
    'main_technical_data',
    'resources::isDisabled',
    'supply_set::isDisabled:isDisabledAlways',
    'certification',
    'product_guides_and_documents',
];