import {createRequestHandler} from '../../../utils';

export const PROFILE_DATA_LIST_REQUEST = 'PROFILE_DATA_LIST_REQUEST';
export const PROFILE_DATA_LIST_SUCCESS = 'PROFILE_DATA_LIST_SUCCESS';
export const PROFILE_DATA_LIST_FAILURE = 'PROFILE_DATA_LIST_FAILURE';

export const PROFILE_DATA_LIST_UPDATING_REQUEST = 'PROFILE_DATA_LIST_UPDATING_REQUEST';
export const PROFILE_DATA_LIST_UPDATING_SUCCESS = 'PROFILE_DATA_LIST_UPDATING_SUCCESS';
export const PROFILE_DATA_LIST_UPDATING_FAILURE = 'PROFILE_DATA_LIST_UPDATING_FAILURE';

export const profileDataRequest = createRequestHandler('PROFILE_DATA_LIST');
export const updatingProfileDataRequest = createRequestHandler('PROFILE_DATA_LIST_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    list: [],
    error: null,
    tabsID: {
        userProfile: 'user_profile',
        organizationProfile: 'organization_profile',
        paymentSetup: 'payment_setup',
        shipmentSetup: 'shipment_setup',
    },
};

const filterData = (list) => {
    const {userProfile, organizationProfile, paymentSetup, shipmentSetup} = initialState.tabsID;

    const handler = (list, tabID) => list.filter(({tab}) => tab.id === tabID);

    const userData = handler(list, userProfile);
    const organizationData = handler(list, organizationProfile);
    const paymentSetupData = handler(list, paymentSetup);
    const shipmentSetupData = handler(list, shipmentSetup);

    return [userData, organizationData, paymentSetupData, shipmentSetupData];
};

const fieldHandler = (list, mount = true, prevList = []) => (
    list.map((item, idx) => {
        const tab = item[0].tab;
        if (mount) {
            return {
                fields: item,
                tab: {
                    ...tab,
                    isActive: idx === 0,
                },
            };
        }

        return {
            fields: item,
            tab: {
                ...tab,
                isActive: prevList[idx].tab.isActive,
            },
        };
    })
);

const onInitList = (list, state, mount = true) => {
    const filteredList = filterData(list);
    const transformedList = fieldHandler(filteredList, mount, state.list);

    return {
        ...state,
        list: transformedList,
        loading: false,
        updating: false,
    };
};

const dataList = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PROFILE_DATA_LIST_REQUEST:
            return initialState;

        case PROFILE_DATA_LIST_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case PROFILE_DATA_LIST_SUCCESS:
            return onInitList(payload.list, initialState);

        case PROFILE_DATA_LIST_UPDATING_SUCCESS:
            return onInitList(payload.list, state, false);

        case PROFILE_DATA_LIST_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case PROFILE_DATA_LIST_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'UPDATE_PROFILE_DATA_LIST':
            return {
                ...state,
                list: payload,
            };

        default:
            return state;
    }
};

export default dataList;