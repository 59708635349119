import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';

import Image from '../../../../common/image';

import classnames from 'classnames/bind';
import styles from './product-card.module.scss';

import defaultProductImage from '../../../../common/assets/other-icons/product_image_default.svg';

const ShortProductCard = (props) => {
    const {
        id, preview_image, condition_value,
        part_number, name, classNames, href, staticData,
    } = props;

    const {available_status_sold} = staticData.marketplace.product_card;

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', 'small-product-card', classNames);

    return (
        <div key={id} id={id} className={containerClasses}>
            <Link to={href}>
                <Image source={preview_image} alt={name}/>
            </Link>
            <div className={styles['sold-label']}>{available_status_sold.label}</div>
            <div className={styles.condition}>{condition_value}</div>
            <div className={styles.content}>
                <Link to={href} className={styles['part-number']}>{part_number}</Link>
                <div className={styles.name}>{name}</div>
            </div>
        </div>
    );
};

ShortProductCard.defaultProps = {
    preview_image: defaultProductImage,
    href: '#',
};

ShortProductCard.propTypes = {
    id: PropTypes.any.isRequired,
    preview_image: PropTypes.shape({
        xs: PropTypes.shape({
            jpg: PropTypes.string,
            webp: PropTypes.string,
        }),
    }),
    condition_value: PropTypes.string,
    part_number: PropTypes.string,
    name: PropTypes.string,
    classNames: PropTypes.string,
    href: PropTypes.string,
    staticData: PropTypes.object,
};

export default withDataService()(ShortProductCard);