export const updateSearchCategorySlug = (newSlug) => {
    const {id, used} = newSlug;
    let payload = {id, used: false};

    if (used) {
        payload = {id, used};
    }

    return {
        type: 'UPDATE_SEARCH_CATEGORY_SLUG',
        payload,
    };
};

export const resetSearchData = () => {
    return {type: 'RESET_SEARCH_DATA'};
};