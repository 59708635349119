import {createRequestHandler} from '../../../../utils';
import {REMOVE_BANK_ACCOUNT_SUCCESS} from './remove-bank-account';

export const BANK_ACCOUNTS_LIST_REQUEST = 'BANK_ACCOUNTS_LIST_REQUEST';
export const BANK_ACCOUNTS_LIST_SUCCESS = 'BANK_ACCOUNTS_LIST_SUCCESS';
export const BANK_ACCOUNTS_LIST_FAILURE = 'BANK_ACCOUNTS_LIST_FAILURE';

export const BANK_ACCOUNTS_LIST_UPDATING_REQUEST = 'BANK_ACCOUNTS_LIST_UPDATING_REQUEST';
export const BANK_ACCOUNTS_LIST_UPDATING_SUCCESS = 'BANK_ACCOUNTS_LIST_UPDATING_SUCCESS';
export const BANK_ACCOUNTS_LIST_UPDATING_FAILURE = 'BANK_ACCOUNTS_LIST_UPDATING_FAILURE';

export const bankAccountsListRequest = createRequestHandler('BANK_ACCOUNTS_LIST');
export const updatingBankAccountsListRequest = createRequestHandler('BANK_ACCOUNTS_LIST_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    list: null,
    error: null,
};

const bankAccountsList = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case BANK_ACCOUNTS_LIST_REQUEST:
            return initialState;

        case BANK_ACCOUNTS_LIST_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case BANK_ACCOUNTS_LIST_SUCCESS:
        case BANK_ACCOUNTS_LIST_UPDATING_SUCCESS:
        case REMOVE_BANK_ACCOUNT_SUCCESS:
            return {
                list: payload.list,
                loading: false,
                updating: false,
                error: null,
            };

        case BANK_ACCOUNTS_LIST_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case BANK_ACCOUNTS_LIST_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default bankAccountsList;