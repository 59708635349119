import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {resetPartNumberSearchData} from '../../../actions/marketplace';
import {withServices} from '../../service-context';
import {partNumberRequest} from '../../../reducers/part-number';
import {hubURLs} from '../../../services/api-urls';

import InputSearch from '../input-search';
import Search from '../search';

class PartNumber extends Component {
    state = {
        prevValue: '',
        searchData: {
            loading: false,
            list: null,
        },
        isEmpty: true,
    };

    timerId = null;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {
            const inputElement = document.getElementById(this.props.id);

            if (inputElement && inputElement.value !== '') {
                inputElement.value = '';
                this.setState({isEmpty: true});
            }
        }

        if (prevProps.partNumber !== this.props.partNumber) {
            if (this.props.partNumber.loading) {
                /* there is searchData list always is null
                * start search if we have prevValue, if we haven't then abort action */
                if (this.state.prevValue) {
                    this.setState({searchData: {loading: true, list: null}});

                } else {
                    this.setState({searchData: {loading: false, list: null}});
                }

            } else {
                const {searchData} = this.state;
                const {list, error} = this.props.partNumber;

                if (!searchData.loading) {
                    /* check this case only after pre-checking the searchData.loading */
                    if (list || error) {
                        /* field lost focus before get data */
                        this.onReset();
                    }

                } else {
                    let searchList = list;

                    if (list) {
                        if (Array.isArray(list) && list.length > 0) {
                            searchList = list[0];
                        }
                    }

                    if (error) {
                        searchList = error;
                    }

                    this.setState({searchData: {loading: false, list: searchList}});
                }
            }
        }
    }

    onInputChange = ({target: {value}, type}) => {
        /* start checking only on mouse click or touch (mobile) */
        if (type !== 'focus') {
            if (this.state.prevValue !== value) {
                if (this.timerId !== null) {
                    clearTimeout(this.timerId);
                }

                if (value === '' || value.length <= 1) {
                    if (this.state.searchData.list) {
                        this.onReset();
                    }
                }

                if (value.length > 1) {
                    this.timerId = setTimeout(() => {
                        const {session, location: {pathname}} = this.props;

                        if (session) {
                            if (pathname === hubURLs.products) {
                                this.props.fetchPartNumberDataHub({pnum: value});

                            } else {
                                this.props.fetchPartNumberData(null, {pnum: value});
                            }

                        } else {
                            this.props.fetchPartNumberDataPublic(null, {pnum: value});
                        }

                    }, 1000);
                }

                this.setState({prevValue: value, isEmpty: value.length < 1});
            }
        }
    };

    onInputBlur = () => {
        if (this.state.prevValue) {
            /* case if input lost focus before search started */
            this.setState({prevValue: ''});
        }
    };

    onClearInputSearch = () => {
        const inputElement = document.getElementById(this.props.id);
        if (inputElement.value !== '') {
            inputElement.value = '';
        }

        this.setState({isEmpty: true});
        this.onReset();
    };

    onReset = () => {
        const {searchData, prevValue} = this.state;
        const {list, error} = this.props.partNumber;
        const isReset = list || error;

        if (isReset) {
            this.props.resetPartNumberSearchData();
        }

        if (prevValue) {
            this.setState({prevValue: ''});
        }

        if (searchData.loading || searchData.list) {
            this.setState({searchData: {loading: false, list: null}});
        }
    };

    componentWillUnmount() {
        clearTimeout(this.timerId);
    }

    render() {
        const {labelClasses, ...rest} = this.props;
        const {searchData: {loading, list}, isEmpty} = this.state;

        return (
            <Search searchInputClasses={labelClasses}
                    searchList={list}
                    visible={loading || list !== null}
                    loading={loading}
                    searchBy={'PART_NUMBER'}
                    onHidden={this.onReset}>
                <InputSearch {...rest}
                             isEmpty={isEmpty}
                             onInputChange={this.onInputChange}
                             onInputBlur={this.onInputBlur}
                             onClearInputSearch={this.onClearInputSearch}/>
            </Search>
        );
    }

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        pathname: PropTypes.string,
        partNumber: PropTypes.object,
        fetchPartNumberData: PropTypes.func,
        fetchPartNumberDataHub: PropTypes.func,
        fetchPartNumberDataPublic: PropTypes.func,
        resetPartNumberSearchData: PropTypes.func,
    };
}

const mapServicesToProps = ({searchService, myProductsService, publicService}) => ({
    fetchPartNumberData: partNumberRequest(searchService.getSearchData),
    fetchPartNumberDataHub: partNumberRequest(myProductsService.getSearchData),
    fetchPartNumberDataPublic: partNumberRequest(publicService.getSearchData),
});

const mapStateToProps = ({session: {session}, partNumber}) => {
    return ({session, partNumber});
};

const mapDispatchToProps = (dispatch, {
    fetchPartNumberData,
    fetchPartNumberDataHub,
    fetchPartNumberDataPublic,
}) => {
    return bindActionCreators({
        fetchPartNumberData,
        fetchPartNumberDataHub,
        fetchPartNumberDataPublic,
        resetPartNumberSearchData,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(PartNumber);