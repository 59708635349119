import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const CREATE_PACKING_PLACE_REQUEST = 'CREATE_PACKING_PLACE_REQUEST';
export const CREATE_PACKING_PLACE_SUCCESS = 'CREATE_PACKING_PLACE_SUCCESS';
export const CREATE_PACKING_PLACE_FAILURE = 'CREATE_PACKING_PLACE_FAILURE';

export const createPackingPlaceRequest = createRequestHandler('CREATE_PACKING_PLACE');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const createPackingPlace = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CREATE_PACKING_PLACE_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case CREATE_PACKING_PLACE_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case CREATE_PACKING_PLACE_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_CREATE_PACKING_PLACE':
        case 'RESET_PACKING_LIST_WIZARD_PAGE_WITHOUT_TABS':
            return initialState;

        default:
            return state;
    }
};

export default createPackingPlace;