import React from 'react';

import MarketplaceModule from './module';

const MarketplaceContainer = () => {
    return <MarketplaceModule/>;
};

export {
    MarketplaceContainer as Marketplace,
};