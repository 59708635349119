import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import {withScrollToTop} from '../../../../common/hoc';

import ProductPageDesktop from './product-page-desktop';
import ProductPageMobile from './product-page-mobile';

const ProductPage = (props) => {
    const lgDevice = useMediaQuery({minWidth: 992});
    const [isPopupShown, setIsPopupShown] = useState(false);

    const onSwiperWrapperClick = () => setIsPopupShown(true);
    const onPopupClose = () => setIsPopupShown(false);

    const additionalProps = {
        isPopupShown,
        onSwiperWrapperClick,
        onPopupClose,
    };

    return !lgDevice
        ? <ProductPageMobile {...props} {...additionalProps}/>
        : <ProductPageDesktop {...props} {...additionalProps}/>;
};

export default withScrollToTop()(ProductPage);