export const about_marketplace_en = {
    title: 'How Exportery works',
    pages: [
        {
            id: 'for_exporter',
            label: 'For Exporter',
            content: [
                {
                    title: 'REGISTER AND VALIDATE',
                    description: `Sign up and validate your organization on the 
                        e-trade platform for free.`,
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'UPLOAD YOUR PRODUCTS',
                    description: `In the specific user section, upload your products using 
                        either a single or batch upload tool for one and multiple items respectively.`,
                    img: 'man.svg',
                    alt: 'Man icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'RECEIVE RFQs FROM IMPORTERS ALL OVER THE WORLD',
                    description: `With absolutely transparent pricing, our e-trade platform 
                        allows you to receive RFQs from importers all over the world, including government agencies.`,
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'GET AN END-USER CERTIFICATE WITH AN ELECTRONIC SIGNATURE',
                    description: `This will allow you to carry out export control procedures in 
                        the country of dispatch of goods.`,
                    img: 'certificate.svg',
                    alt: 'Certificate icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SIGN YOUR E-CONTRACT',
                    description: 'E-contracts are signed on FCA terms.',
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'AUTOMATIC GENERATION OF EXPORT DOCUMENTATION',
                    description: `Exportery allows you to automatically generate and to e-sign all 
                        export documentation needed for customs clearance and export of your goods: 
                        e-invoices, e-packing lists, transport documents, and shipping labels.`,
                    img: 'package.svg',
                    alt: 'Package icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SUBMIT YOUR CARGO TO THE DEDICATED AIRPORT CARGO TERMINAL BASED ON FCA TERMS (INCOTERMS 2020) IN YOUR COUNTRY',
                    description: `After the submission, Exportery will take responsibility for 
                        booking the consignment with the carrier, paying the freight, issuing the 
                        airway bill, and providing international transport insurance.`,
                    img: 'shipment.svg',
                    alt: 'Shipment icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'YOUR CARGO WILL ARRIVE AT THE INTERNATIONAL AIRPORT TRANSIT WAREHOUSE IN A NEUTRAL COUNTRY',
                    description: 'We will conduct an independent technical inspection of the quality and quantity of your cargo and prepare it for transfer to the end-user. Your goods will be safe in the escrow warehouse until the end of the settlement.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'GET FUNDS ON YOUR SETTLEMENT ACCOUNT',
                    description: 'The terms of your e-Contract will determine the funding.',
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'TRACK EVERY STAGE OF YOUR CARGO DELIVERY',
                    description: `Track every stage of your cargo delivery from the Origin of 
                        shipment in your country to the final destination of the consignee.`,
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
        {
            id: 'for_importer',
            label: 'For importer',
            content: [
                {
                    title: 'REGISTER AND VALIDATE',
                    description: 'Sign up and validate your organization on the e-marketplace for free.',
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'GET OFFERS FOR YOUR RFQs WITH THE LOWEST PRICE AND THE FASTEST DELIVERY',
                    description: 'Select the goods the manufacturers or exporters of products.',
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SUBMIT YOUR END-USER CERTIFICATE',
                    description: `The end-user certificate shall be submitted through our 
                        electronic tools to comply with export control requirements in the country 
                        of origin.`,
                    img: 'accept.svg',
                    alt: 'Accept icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SIGN YOUR ELECTRONIC CONTRACT',
                    description: `E-contracts are signed on CIP terms for your destination. 
                        Remit only 25% of the Contract's total value to the secure Escrow account 
                        in a reputable bank in the preferred currency to guarantee the deal.`,
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'AUTOMATIC GENERATION AND RECEIPT OF IMPORT DOCUMENTATION',
                    description: `Accept all necessary shipping documentation in electronic form 
                        from the Exporter and receive all information about the tracking of goods 
                        using our Contract Map panel.`,
                    img: 'delivery.svg',
                    alt: 'Delivery icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'YOUR CARGO WILL ARRIVE AT THE INTERNATIONAL AIRPORT TRANSIT WAREHOUSE IN A NEUTRAL COUNTRY',
                    description: 'We will conduct an independent technical inspection of the quality and quantity of your cargo and prepare it for transfer to the end-user. Your goods will be safe in the escrow warehouse until the end of the settlement.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'TRANSFER YOUR FUNDS',
                    description: `After signing the trilateral Give and Take Act, you transfer the 
                        remaining balance funds to the account according to the e-Contract. At the 
                        same time, our Agency will dispatch the goods to your destination airport 
                        from our bonded escrow warehouse.`,
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'CONSIGNMENT TRACKING IN REAL-TIME',
                    description: `Track every stage of your cargo delivery to the final 
                        destination, including all transport documentation and delivery notice.`,
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
    ],
};