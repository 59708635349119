import {createRequestHandler} from '../../../../utils';
import {
    transformFieldWithDependsFrom,
} from '../../../../components/activity-hub/components/common/handlers';

import {SUGGEST_STRATEGY_UPDATING_SUCCESS} from './suggest-strategy';
import FormFieldBuilder from '../../../../utils/form-field-builder/form-field-builder';

export const PACKING_FORM_REQUEST = 'PACKING_FORM_REQUEST';
export const PACKING_FORM_SUCCESS = 'PACKING_FORM_SUCCESS';
export const PACKING_FORM_FAILURE = 'PACKING_FORM_FAILURE';

export const PACKING_FORM_UPDATING_REQUEST = 'PACKING_FORM_UPDATING_REQUEST';
export const PACKING_FORM_UPDATING_SUCCESS = 'PACKING_FORM_UPDATING_SUCCESS';
export const PACKING_FORM_UPDATING_FAILURE = 'PACKING_FORM_UPDATING_FAILURE';

export const packingFormRequest = createRequestHandler('PACKING_FORM', {language: true});
export const updatingPackingFormRequest = createRequestHandler('PACKING_FORM_UPDATING', {language: true});

const formFieldBuilder = new FormFieldBuilder();

const initialState = {
    list: null,
    data: null,
    loading: false,
    updating: false,
    error: null,
};

export const onGetDataFromPackingFormFields = (list) => {
    let data = {};

    list.forEach(({placebunch}) => {
        placebunch.forEach(({form}) => {
            form.forEach(({type, name, value, list}) => {
                if (type === 'radio') {
                    const idx = list.findIndex(({isChecked}) => isChecked);
                    value = list[idx].value;
                }

                if (type === 'text' && value && value.floatValue) {
                    value = value.floatValue;
                }

                data = {
                    ...data,
                    [name]: value,
                };
            });
        });
    });

    return data;
};

export const onInitForm = (payload) => {
    const {list, language} = payload;
    const updatedList = list.map(({placebunch}) => {
        const updatedPlaceBunch = placebunch.map(({form, ...rest}) => {
            const updatedForm = form.map((field) => {
                if (field.type === 'select') {
                    const updatedList = formFieldBuilder.transformSelectFieldList(field.list, language);
                    field = {
                        ...field,
                        list: updatedList,
                    };
                }

                return formFieldBuilder.transformFieldData(field);
            })
                .map((field, idx, arr) => {
                    if (field.dependsFrom) {
                        return transformFieldWithDependsFrom(arr, field, true);
                    }

                    return field;
                });

            return {
                form: updatedForm,
                ...rest,
            };
        });

        return {placebunch: updatedPlaceBunch};
    });

    const data = onGetDataFromPackingFormFields(updatedList);
    return {list: updatedList, data};
};

export const onUpdateForm = (payload, state) => {
    const {list, language} = payload;
    const {list: prevList} = state;
    const newList = prevList.map(({placebunch}, placebunchIdx) => {
        const updatedPlaceBunch = placebunch.map(({form: prevForm}, placeIdx) => {
            const currentPlace = list[placebunchIdx].placebunch[placeIdx];
            const currentForm = currentPlace.form;
            const updatedForm = prevForm.map((prevField) => {
                const idx = currentForm.findIndex(({name}) => {
                    let prevName = prevField.name;
                    const handler = (name) => {
                        const regExpLeft = /\[/g;
                        // 0 - form, 1 - place, 2 - random index, 3 - name of field
                        name = name.replace(regExpLeft, ' ').split(' ').slice(3);
                        return `[${name.join('[')}`;
                    };

                    name = handler(name);
                    prevName = handler(prevName);
                    return name === prevName;
                });

                const field = currentForm[idx];

                if (prevField.type !== 'radio') {
                    const value = prevField.value;

                    if (prevField.type === 'select') {
                        /* transform new list from server */
                        const updatedList = formFieldBuilder.transformSelectFieldList(field.list, language);
                        return {
                            ...prevField,
                            ...field,
                            list: updatedList,
                            value,
                        };
                    }

                    return {
                        ...prevField,
                        ...field,
                        value,
                    };

                } else {
                    const updatedList = prevField.list.map((listItem) => {
                        const idx = field.list.findIndex(({value}) => value === listItem.value);
                        const newItem = field.list[idx];

                        return {
                            ...listItem,
                            ...newItem,
                        };
                    });

                    return {
                        ...prevField,
                        ...field,
                        list: updatedList,
                    };
                }
            });

            return {
                ...currentPlace,
                form: updatedForm,
            };
        });

        return {placebunch: updatedPlaceBunch};
    });

    const data = onGetDataFromPackingFormFields(newList);
    return {list: newList, data};
};

const packingForm = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PACKING_FORM_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case PACKING_FORM_UPDATING_REQUEST:
            return state;

        case PACKING_FORM_SUCCESS:
            const initForm = onInitForm(payload);

            return {
                list: initForm.list,
                data: initForm.data,
                loading: false,
                updating: false,
                error: null,
            };

        case PACKING_FORM_UPDATING_SUCCESS:
            const updatedForm = onUpdateForm(payload, state);

            return {
                ...state,
                updating: false,
                list: updatedForm.list,
                data: updatedForm.data,
            };

        case PACKING_FORM_FAILURE:
            return {
                list: null,
                data: null,
                loading: false,
                updating: false,
                error: payload,
            };

        case PACKING_FORM_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case SUGGEST_STRATEGY_UPDATING_SUCCESS:
            return {
                ...state,
                updating: true,
            };

        case 'UPDATE_PACKING_FORM':
            return {
                ...state,
                list: payload.list,
                data: payload.data,
            };

        case 'RESET_PACKING_FORM_UPDATING':
            return {
                ...state,
                updating: false,
            };

        case 'RESET_PACKING_FORM':
        case 'RESET_PACKING_LIST_WIZARD_STATE_TO_INITIAL':
        case 'RESET_PACKING_LIST_WIZARD_PAGE_WITHOUT_TABS':
        case 'RESET_PACKING_LIST_WIZARD_PAGE':
            return initialState;

        default:
            return state;
    }
};

export default packingForm;