import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination, Thumbs, EffectFade} from 'swiper/core';
import PropTypes from 'prop-types';

import './image-slider.scss';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

SwiperCore.use([Navigation, Pagination, Thumbs, EffectFade]);

const ImageSlider = (props) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const {
        slides, slidesPerView, spaceBetween, effect, containerClass,
        withLoop, withPagination, withNavigation, withThumbsSlider,
        onSwiperWrapperClick,
    } = props;

    const mainParams = {
        loop: withLoop,
        pagination: (withPagination && slides.length > 1) && {'clickable': true} ,
        navigation: withNavigation,
        thumbs: withThumbsSlider && {swiper: thumbsSwiper},
        containerModifierClass: `swiper-container-${effect} ${containerClass} `,
        watchOverflow: true,
        effect,
        speed: 500,
        onSwiper: ({wrapperEl}) => {
            wrapperEl.addEventListener('click', onSwiperWrapperClick);
        },
        onDestroy: ({wrapperEl}) => {
            wrapperEl.removeEventListener('click', onSwiperWrapperClick);
        }
    };

    const thumbsParams = {
        onSwiper: setThumbsSwiper,
        slidesPerView: slidesPerView,
        spaceBetween: spaceBetween,
        slideToClickedSlide: true,
        containerModifierClass: 'swiper-container-thumb ',
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    };

    const swiperSlides = slides.map((slide, idx) => <SwiperSlide key={idx}>{slide}</SwiperSlide>);

    return (
        <>
            <Swiper {...mainParams}>
                {swiperSlides}
            </Swiper>

            {(withThumbsSlider && slides.length > 1) && (
                <Swiper {...thumbsParams}>
                    {swiperSlides}
                </Swiper>
            )}
        </>
    );
};

ImageSlider.defaultProps = {
    effect: 'slide',
    spaceBetween: 14,
    withLoop: false,
    withPagination: false,
    withNavigation: false,
    withThumbsSlider: false,
};

ImageSlider.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.node),
    slidesPerView: PropTypes.number,
    spaceBetween: PropTypes.number,
    containerClass: PropTypes.string,
    withLoop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withNavigation: PropTypes.bool,
    withThumbsSlider: PropTypes.bool,
    onSwiperWrapperClick: PropTypes.func,
};

export default ImageSlider;