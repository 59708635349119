import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../../common/hoc';
import {withServices} from '../../../../../../../../../service-context';
import {onCreateFormData} from '../../../../../../../../../common/handlers';
import {contractMapsAction} from '../../../../../../../../../../actions/activity-hub';
import {
    suggestStrategyRequest,
    updatingSuggestStrategyRequest,
} from '../../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/suggest-strategy';
import {
    packingFormRequest,
    updatingPackingFormRequest,
} from '../../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/packing-form';

import ShipmentLot from './shipment-lot';

class ShipmentLotContainer extends Component {
    state = {
        isUpdatingStrategyList: false,
        isSelectOpen: false,
    };

    timerID = null;

    componentDidMount() {
        const {productsForPacking, strategyListUpdating} = this.props;

        /* when user change language we have next several steps after updated products list for packing
        (after updated products list of packing suggest strategy changed updating value to true automatically,
        but without request to server):
        * - update suggest strategy.
        If user has already selected one item at least in the table we send request to server.
        If user hasn't selected one item at least in the table yet, then we reset updating to false in redux.
        - update packing form, if the suggest strategy selected before updated language
        */
        if (strategyListUpdating) {
            if (productsForPacking.length > 0) {
                this.setState({isUpdatingStrategyList: true});
            } else {
                this.props.resetSuggestStrategyUpdating();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.productsForPacking !== this.props.productsForPacking) {
            if (this.props.productsForPacking.length > 0) {
                this.getStrategyList();
            }
        }

        if (prevState.isUpdatingStrategyList !== this.state.isUpdatingStrategyList) {
            this.getStrategyList();
            this.setState({isUpdatingStrategyList: false});
        }

        if (prevProps.selectedStrategy !== this.props.selectedStrategy) {
            this.getPackingForm();
        }

        if (prevProps.packingFormUpdating !== this.props.packingFormUpdating) {
            if (this.props.packingFormUpdating) {
                this.getPackingForm();
            }
        }
    }

    getStrategyList = () => {
        const {dataForPacking} = this.props;
        if (this.timerID) {
            clearTimeout(this.timerID);
        }

        const formData = onCreateFormData(dataForPacking);

        /* little pause for case if user want to select several items for packing in a list */
        this.timerID = setTimeout(() => {
            if (!this.props.strategyListUpdating) {
                this.props.fetchSuggestStrategy({formData});
            } else {
                this.props.fetchSuggestStrategyUpdating({formData});
            }
        }, 1000);
    };

    getPackingForm = () => {
        if (this.props.selectedStrategy) {
            const {selectedStrategy, invoiceID, dataForPacking} = this.props;

            const formData = onCreateFormData({
                strategy: selectedStrategy,
                ...dataForPacking,
            });

            if (!this.props.packingFormUpdating) {
                this.props.fetchPackingForm(invoiceID, {formData});
            } else {
                this.props.fetchPackingFormUpdating(invoiceID, {formData});
            }

        } else {
            if (this.props.packingForm) {
                this.props.resetPackingForm();
            }

            if (this.props.packingFormUpdating) {
                this.props.resetPackingFormUpdating();
            }
        }
    };

    onCheckboxChange = ({target: {name, checked}}) => {
        const {unpacked, productsForPacking} = this.props;
        const {list} = unpacked;

        const currentProductIdx = list.findIndex((item) => item.name === name);
        const currentProduct = list[currentProductIdx];
        const newCurrentProduct = {
            ...currentProduct,
            isChecked: checked ? 1 : 0,
        };

        const newList = [
            ...list.slice(0, currentProductIdx),
            newCurrentProduct,
            ...list.slice(currentProductIdx + 1),
        ];

        const productIdx = productsForPacking.findIndex((item) => item.name === name);
        let newProductsForPacking = [];
        if (productIdx !== -1) {
            newProductsForPacking = [
                ...productsForPacking.slice(0, productIdx),
                ...productsForPacking.slice(productIdx + 1),
            ];
        } else {
            newProductsForPacking = [...productsForPacking, newCurrentProduct];
        }

        const newUnpacked = {
            ...unpacked,
            list: newList,
        };

        this.props.updateProductsListForPacking(newUnpacked, newProductsForPacking);
        this.onResetReduxState();
    };

    onClickSelectItem = (value) => {
        const newStrategyList = this.props.strategyList.map((item) => {
            if (item.value === value) {
                return {
                    ...item,
                    isSelected: true,
                };
            }

            return {
                ...item,
                isSelected: false,
            };
        });

        const selectedStrategy = value !== 'default' ? value : null;

        this.props.updateSuggestStrategyList(newStrategyList);
        this.props.updateSelectedStrategy(selectedStrategy);
    };

    onToggleSelect = () => {
        this.setState((prevState) => ({isSelectOpen: !prevState.isSelectOpen}));
    };

    onResetReduxState = () => {
        /* clear every time when newItemsForPacking array change */
        if (this.props.strategyList.length > 1) {
            const strategyList = this.props.strategyList
                .filter(({value}) => value === 'default')
                .map((item) => ({
                    ...item,
                    isSelected: true,
                }));

            this.props.updateSuggestStrategyList(strategyList);
        }

        if (this.props.selectedStrategy) {
            this.props.updateSelectedStrategy(null);
        }

        if (this.props.packingForm) {
            this.props.resetPackingForm();
        }
    };

    componentWillUnmount() {
        if (this.timerID) {
            clearTimeout(this.timerId);
        }
    }

    render() {
        const {
            staticData, unpacked,
            strategyList, strategyListLoading, strategyListUpdating,
            packingForm, packingFormLoading, packingFormUpdating, switchSpareKitLoading,
        } = this.props;

        const {isSelectOpen} = this.state;
        const isExistCheckedItems = unpacked.list.find(({isChecked}) => isChecked);

        const data = {
            staticData,
            productsList: unpacked.list,
            strategyList: isExistCheckedItems ? strategyList : strategyList.slice(0, 1),
            strategyListLoading,
            strategyListUpdating,
            packingForm,
            packingFormLoading,
            packingFormUpdating,
            isSelectOpen,
            switchSpareKitLoading,
            onCheckboxChange: this.onCheckboxChange,
            onToggleSelect: this.onToggleSelect,
            onClickSelectItem: this.onClickSelectItem,
        };

        return <ShipmentLot {...data}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        invoiceID: PropTypes.number,
        unpacked: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.shape({
                invoice_id: PropTypes.number,
                list: PropTypes.arrayOf(PropTypes.object),
            })]),
        productsForPacking: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        dataForPacking: PropTypes.object,
        strategyList: PropTypes.array,
        selectedStrategy: PropTypes.string,
        packingForm: PropTypes.array,
        strategyListUpdating: PropTypes.bool,
        packingFormUpdating: PropTypes.bool,
        fetchSuggestStrategy: PropTypes.func,
        fetchSuggestStrategyUpdating: PropTypes.func,
        fetchPackingForm: PropTypes.func,
        fetchPackingFormUpdating: PropTypes.func,
        updateProductsListForPacking: PropTypes.func,
        updateSuggestStrategyList: PropTypes.func,
        updateSelectedStrategy: PropTypes.func,
        resetSuggestStrategyUpdating: PropTypes.func,
        resetPackingForm: PropTypes.func,
        resetPackingFormUpdating: PropTypes.func,
    };
}

const mapServicesToProps = ({packingListWizardService}) => {
    const {getSuggestedStrategy, getPackingForm} = packingListWizardService;

    return {
        fetchSuggestStrategy: suggestStrategyRequest(getSuggestedStrategy),
        fetchSuggestStrategyUpdating: updatingSuggestStrategyRequest(getSuggestedStrategy),
        fetchPackingForm: packingFormRequest(getPackingForm),
        fetchPackingFormUpdating: updatingPackingFormRequest(getPackingForm),
    };
};

const mapStateToProps = ({activityHub: {contractMaps: {packingListWizard}}}) => {
    const {productsList, suggestStrategy, packingForm} = packingListWizard;

    return {
        invoiceID: productsList.invoiceID,
        unpacked: productsList.unpacked,
        productsForPacking: productsList.forPacking,
        dataForPacking: productsList.dataForPacking,
        strategyList: suggestStrategy.list,
        strategyListLoading: suggestStrategy.loading,
        strategyListUpdating: suggestStrategy.updating,
        selectedStrategy: suggestStrategy.selectedStrategy,
        packingForm: packingForm.list,
        packingFormLoading: packingForm.loading,
        packingFormUpdating: packingForm.updating,
    };
};

const mapDispatchToProps = (dispatch, {
    fetchSuggestStrategy,
    fetchSuggestStrategyUpdating,
    fetchPackingForm,
    fetchPackingFormUpdating,
}) => {
    const {
        packingFormAction: {resetPackingForm, resetPackingFormUpdating},
        productListForPackingAction: {updateProductsListForPacking},
        suggestStrategyAction: {
            updateSuggestStrategyList,
            updateSelectedStrategy,
            resetSuggestStrategyUpdating,
        },
    } = contractMapsAction;

    return bindActionCreators({
        fetchSuggestStrategy,
        fetchSuggestStrategyUpdating,
        fetchPackingForm,
        fetchPackingFormUpdating,
        updateProductsListForPacking,
        updateSuggestStrategyList,
        updateSelectedStrategy,
        resetSuggestStrategyUpdating,
        resetPackingForm,
        resetPackingFormUpdating,
    }, dispatch);
};

export default compose(
    withDataService('activity_hub.e_contracts.pages.packing_list_wizard'),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ShipmentLotContainer);