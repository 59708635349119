import React from 'react';

import IndependentTechnicalSurveyingModule from './module';

const IndependentTechnicalSurveyingModuleContainer = () => {
    return <IndependentTechnicalSurveyingModule/>;
};

export {
    IndependentTechnicalSurveyingModuleContainer as IndependentTechnicalSurveying,
};