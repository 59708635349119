import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import {withDataService} from '../../hoc';
import {hubURLs, marketplaceURLs, staticURLs} from '../../../../services/api-urls';

import Preloader from '../../preloader';
import Chip from '../../chip';

import classnames from 'classnames/bind';
import styles from './search-result.module.scss';

const SearchResult = (props) => {
    const {staticData, loading, searchList, searchResultClasses, searchBy, onHidden} = props;
    const [platform, title] = staticData.search.result_titles;
    const history = useHistory();
    const session = useSelector(({session: {session}}) => session);

    const createLinkURL = (href) => {
        if (session) {
            const {location: {pathname}} = history;

            if (searchBy === 'PART_NUMBER' && pathname === hubURLs.products) {
                /* activity-hub my-products */
                return `${hubURLs.products}${href}`;

            } else {
                return `${marketplaceURLs.marketplace_search}${href}`;
            }
        }

        return staticURLs.request_access;
    };

    const onClick = (link) => {
        onHidden(true);
        history.push(link);
    };

    const createProposedOptionsList = (optionsList) => {
        return optionsList.map(({name, href}, idx) => {
            const linkURL = createLinkURL(href);

            return (
                <div key={idx} className={styles['proposed-option']} onClick={() => onClick(linkURL)}>
                    <div className={styles['search-icon']}/>
                    <div className={styles['proposed-title']}>{parse(name)}</div>
                </div>
            );
        });
    };

    const createOptionsList = (optionsList) => {
        return optionsList.map(({name, href}, idx) => {
                const linkURL = createLinkURL(href);

                return (
                    <div key={idx} className={styles['option-item']} onClick={() => onClick(linkURL)}>
                        <Chip>{name}</Chip>
                    </div>
                );
            },
        );
    };

    const createResultList = () => {
        if (!searchList) {
            return null;
        }

        if (typeof searchList === 'string') {
            return (
                <div className={styles['proposed-option']}>
                    <div className={styles['search-icon']}/>
                    <div className={styles['proposed-title']}>{searchList}</div>
                </div>
            );
        }

        const proposedOptions = searchList.products ? createProposedOptionsList(searchList.products.list) : [];
        const platformOptions = searchList.platforms ? createOptionsList(searchList.platforms.list) : [];
        const titleOptions = searchList.titles ? createOptionsList(searchList.titles.list) : [];

        return (
            <>
                {proposedOptions}

                {searchList.platforms && <div className={styles.title}>{platform}</div>}
                <div className={styles.options}>{platformOptions}</div>

                {searchList.titles && <div className={styles.title}>{title}</div>}
                <div className={styles.options}>{titleOptions}</div>
            </>
        );
    };

    const cx = classnames.bind(styles);
    const classes = cx('container', searchResultClasses, {
        'loading': loading,
        'no-data': typeof searchList === 'string',
        'with-padding': searchList !== null && (searchList.platforms || searchList.titles),
    });

    return (
        <div className={classes}>
            {
                !loading
                    ? createResultList()
                    : <Preloader type="SECONDARY" classNames={styles.preloader}/>
            }
        </div>
    );
};

SearchResult.defaultProps = {
    searchBy: 'GLOBAL_SEARCH',
};

SearchResult.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    searchResultClasses: PropTypes.string,
    searchList: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.objectOf(PropTypes.shape({
                name: PropTypes.string,
                href: PropTypes.string,
            }),
        ),
    ]),
    searchBy: PropTypes.oneOf(['PART_NUMBER', 'GLOBAL_SEARCH']),
    onHidden: PropTypes.func,
};

export default withDataService()(SearchResult);