import {combineReducers} from 'redux';

import productsList from './products-list-for-packing';
import suggestStrategy from './suggest-strategy';
import packingForm from './packing-form';
import createPackingPlace from './create-packing-place';
import packingEditingForm from './packing-editing-form';
import editPackingPlace from './edit-packing-place';
import unpackPackedProduct from './unpack-packed-product';
import confirmPackingList from './confirm-packing-list';

export const packingListWizardReducer = combineReducers({
    productsList,
    suggestStrategy,
    packingForm,
    createPackingPlace,
    packingEditingForm,
    editPackingPlace,
    unpackPackedProduct,
    confirmPackingList,
});