const resetAllUrlParams = (searchParams, defaultPath) => {
    const regExpPNum = new RegExp('\\?pnum=.*?((?=&)|$)', 'g');

    if (searchParams.match(regExpPNum) !== null) {
        return searchParams.match(regExpPNum)[0];
    }

    return defaultPath;
};

export {resetAllUrlParams};