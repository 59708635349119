import React from 'react';
import PropTypes from 'prop-types';

import Dropzone from '../../../../../../common/dropzone/dropzone';
import FileContainer from '../../../../../../common/file-container/file-container';
import Button from '../../../../../../common/button';
import ButtonConstructor from '../../../../../../common/button-constructor';

import classnames from 'classnames/bind';
import styles from './upload-document.module.scss';

import documentIcon from '../../../../../../common/assets/documents-icons/file-lines.svg';

const UploadDocument = (props) => {
    const {
        staticData, autogenerateMode, file, progress, loaded, loading: responseLoading,
        contractDocumentUpload: {data: {buttons, subtitle}},
        onButtonClick, onSubmit, onDeleteFile, onCancel, ...restProps
    } = props;
    const {
        text,
        button: {cancel, create, btn_text},
    } = staticData.activity_hub.e_contracts.pages.upload_document;
    const cx = classnames.bind(styles);

    if (autogenerateMode) {
        return (
            <div className={styles['autogenerate-mode']}>
                <img src={documentIcon} className={styles['file-icon']} alt=""/>

                <div className={styles['btn-block']}>
                    {buttons.map((button, idx) => {
                        const {style} = button;
                        const manualUpload = style && style.includes('text');
                        const btnClasses = cx({'secondary': manualUpload});

                        return (
                            <React.Fragment key={idx}>
                                {idx > 0 && <div className={styles.text}>{btn_text}</div>}
                                <ButtonConstructor
                                    key={idx}
                                    {...button}
                                    dataLoading={responseLoading && !manualUpload}
                                    classNames={btnClasses}
                                    onClick={(remote) => manualUpload
                                        ? onButtonClick(remote, true)
                                        : onButtonClick(remote)
                                    }/>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.text}>{subtitle || text}</div>

                <div className={styles['upload-container']}>
                    <div className={styles['dropzone-block']}>
                        <Dropzone {...restProps}
                                  containerClassNames={styles.dropzone}
                                  isDisabled={file !== null}
                                  fileTypes={['.pdf']}
                        />
                    </div>

                    {file && <FileContainer file={file}
                                            fileTypes={['.pdf']}
                                            fileProgress={progress}
                                            isFileLoaded={loaded}
                                            onDelete={onDeleteFile}/>}
                </div>
            </div>

            <div className={styles['btn-group']}>
                <Button styleType="SECONDARY" classNames={styles.cancel} onClick={onCancel}>{cancel}</Button>
                <Button
                    dataLoading={responseLoading}
                    isDisabled={!loaded}
                    classNames={styles.proceed}
                    onClick={onSubmit}>
                    {create}
                </Button>
            </div>
        </>
    );
};

UploadDocument.propTypes = {
    staticData: PropTypes.object,
    autogenerateMode: PropTypes.bool,
    file: PropTypes.object,
    progress: PropTypes.number,
    loaded: PropTypes.bool,
    responseLoading: PropTypes.bool,
    contractDocumentUpload: PropTypes.object,
    onButtonClick: PropTypes.func,
    onSubmit: PropTypes.func,
    onDeleteFile: PropTypes.func,
    onCancel: PropTypes.func,
};

export default UploadDocument;