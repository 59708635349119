import React from 'react';
import PropTypes from 'prop-types';

import ProductDescription from '../../../product-description';

import styles from './resources.module.scss';

const Resources = ({content}) => {
    const [resources, warranty] = content;

    return (
        <div className={styles.container}>
            {resources.length !== 0 && resources.value.length !== 0 && (
                <div className={styles['resources-block']}>
                    <ProductDescription
                        description={resources.value}
                        title={resources.label}
                        itemClassNames={styles['description-item']}
                        isTitle
                    />
                </div>
            )}
            {warranty.length !== 0 && warranty.value.length !== 0 && (
                <div className={styles['warranty-block']}>
                    <ProductDescription
                        description={warranty.value}
                        title={warranty.label}
                        itemClassNames={styles['description-item']}
                        isTitle
                        isMobileTitle
                    />
                </div>
            )}
        </div>
    );
};

Resources.propTypes = {
    content: PropTypes.array,
};

export default Resources;