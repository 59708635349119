import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withModal} from '../../common/hoc';

import RadioButton from '../../common/radio-button';
import Button from '../../common/button';
import Input from '../../common/input';
import Select from '../../common/select/select';
import InputPhone from '../../common/input-phone/input-phone';
import Preloader from '../../common/preloader';

import classnames from 'classnames/bind';
import styles from './sign-up.module.scss';

import Logo from '../../common/logo';

const SignUp = (props) => {
    const {
        staticData, countries, interaction, interest, privacy_policy,
        first_name_error, last_name_error, email_error, organization_name_error,
        country_error, telephone_error, common_error,
        loading, onSelectCountry, onToggleRadioBtn, onCheckboxPrivacyChange,
        onCheckboxChange, onSubmit, ...inputEventListeners
    } = props;

    const {
        welcome_title,
        description,
        title,
        first_name_placeholder,
        last_name_placeholder,
        email_placeholder,
        organization_name_placeholder,
        country_placeholder,
        telephone_placeholder,
        tick_the_areas_label,
        privacy_policy_text,
        button: {submit},
    } = staticData;

    const cx = classnames.bind(styles);
    const errorClassHandler = (error) => error ? cx('input-error') : null;

    const nameParams = {
        inputName: 'first_name',
        inputPlaceholder: first_name_placeholder,
        label: first_name_placeholder,
        isError: first_name_error !== null,
        errorMessage: first_name_error,
        inputContainerClass: errorClassHandler(first_name_error),
    };

    const surnameParams = {
        inputName: 'last_name',
        inputPlaceholder: last_name_placeholder,
        label: last_name_placeholder,
        isError: last_name_error !== null,
        errorMessage: last_name_error,
        inputContainerClass: errorClassHandler(last_name_error),
    };

    const emailParams = {
        inputName: 'email',
        inputPlaceholder: email_placeholder,
        label: email_placeholder,
        isError: email_error !== null,
        errorMessage: email_error,
        inputContainerClass: errorClassHandler(email_error),
    };

    const companyParams = {
        inputName: 'organization_name',
        inputPlaceholder: organization_name_placeholder,
        label: organization_name_placeholder,
        isError: organization_name_error !== null,
        errorMessage: organization_name_error,
        inputContainerClass: errorClassHandler(organization_name_error),
    };

    const countryParams = {
        name: 'country',
        label: country_placeholder,
        error: country_error,
        withLabel: true,
    };

    const phoneParams = {
        name: 'telephone',
        label: telephone_placeholder,
        error: telephone_error,
        containerClassNames: `${styles['input-phone']} ${errorClassHandler(telephone_error)}`,
        onInputPhoneChange: (name, value) => inputEventListeners.onInputChange({target: {name, value}}),
        onInputPhoneFocus: inputEventListeners.onInputFocus,
    };

    const [state, setState] = useState({
        selectIsOpen: false,
        selectedItem: '',
    });

    useEffect(() => {
        const selected = !countries.loading
            ? countries.selected.label || countries.selected
            : <Preloader type={'SECONDARY'} classNames={styles.preloader}/>;

        setState(state => ({
            ...state,
            selectedItem: selected,
        }));

    }, [countries]);

    let selectList = [];
    if (countries.list.length > 0) {
        selectList = countries.list.map((country, idx) => {
            const {selectedItem} = state;
            const className = cx('item', {
                'active': selectedItem.value && selectedItem.value === country.value,
                'not-available': country.value === '',
            });

            return (
                <li key={idx}
                    className={className}
                    onClick={() => {
                        setState({
                            selectIsOpen: false,
                            selectedItem: country.label,
                        });
                        onSelectCountry(countryParams.name, country.value);
                    }}>
                    {country.label}
                </li>
            );
        });
    }

    const countryBlock = () => {
        const props = countries.list.length === 0 ? ['disabled', 'search'] : ['search'];
        const selectContainerClasses = cx('select-container', {
            'error': countryParams.error,
        });

        return (
            <div className={selectContainerClasses}>
                <Select {...countryParams}
                        type={'SECONDARY'}
                        selectedItemClasses={styles.select}
                        selectedItem={state.selectedItem}
                        isOpenSelect={state.selectIsOpen}
                        onToggleSelect={() => setState(state => ({
                            ...state,
                            selectIsOpen: !state.selectIsOpen,
                        }))}
                        props={props}>
                    {selectList}
                </Select>
                <div className={styles['error-message']}>{countryParams.error}</div>
            </div>
        );
    };
    const interactionItems = interaction.slice(1).map(({id, label, value, isChecked}, idx) => {
        return <RadioButton key={idx} name={id}
                            value={value} label={label}
                            isChecked={isChecked} onRadioChange={onToggleRadioBtn}/>;
    });

    const interestItems = interest.map(({id, label, value, isChecked}, idx) => {
        return (
            <label className={styles.checkbox} htmlFor={id} key={idx}>
                <input id={id}
                       type="checkbox"
                       value={value}
                       checked={isChecked}
                       onChange={onCheckboxChange}
                       hidden/>
                <span className={styles.box}/>
                {label}
            </label>
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles['info-block']}>
                <div className={styles.title}>{welcome_title}</div>
                <div className={styles.description}>
                    {description.map((item, idx) => {
                        return <p key={idx} className={styles.text}>{item}</p>;
                    })}
                </div>

                <Logo color={'WHITE'}/>
            </div>
            <div className={styles['form-block']}>
                <div className={styles.title}>{title}</div>
                <div className={cx('common-error', {'show': common_error})}>{common_error}</div>
                <form id="sign-up-form" onSubmit={(e) => onSubmit(e)}>
                    <div className={styles['inputs-block']}>
                        <div className={styles['name-block']}>
                            <Input {...nameParams} {...inputEventListeners}/>
                            <Input {...surnameParams} {...inputEventListeners}/>
                        </div>
                        <Input {...emailParams} {...inputEventListeners}/>
                        <Input {...companyParams} {...inputEventListeners}/>
                        {countryBlock()}
                        <InputPhone {...phoneParams}/>
                    </div>

                    <div className={cx('selection-block', 'user-role')}>
                        <div className={styles.label}>{interaction[0]}</div>
                        <div className={styles['radio-block']}>{interactionItems}</div>
                    </div>

                    <div className={styles['selection-block']}>
                        <div className={styles.label}>{tick_the_areas_label}</div>
                        {interestItems}
                    </div>

                    <label className={cx('checkbox', 'privacy_checkbox')} htmlFor={'privacy_policy_id'}>
                        <input id={'privacy_policy_id'}
                               type="checkbox"
                               checked={privacy_policy}
                               onChange={onCheckboxPrivacyChange}
                               hidden/>
                        <span className={styles.box}/>
                        <span> {privacy_policy_text.map((item, idx) => {
                            if (idx === 1) {
                                return (
                                    <Link key={idx}
                                          to="/privacy-policy"
                                          target="_blank"
                                          className={styles.link}>
                                        {item}
                                    </Link>
                                );
                            }

                            return item;
                        })}</span>
                    </label>

                    <Button
                        type="submit"
                        classNames={styles.btn}
                        dataLoading={loading}
                        isDisabled={!privacy_policy || countries.loading}>
                        {submit}
                    </Button>
                </form>
            </div>
        </div>
    );
};

SignUp.defaultProps = {
    onSelectCountry: () => {
    },
    onToggleRadioBtn: () => {
    },
    onCheckboxChange: () => {
    },
    onCheckboxPrivacyChange: () => {
    },
};

SignUp.propTypes = {
    staticData: PropTypes.object,
    countries: PropTypes.shape({
        loading: PropTypes.bool,
        list: PropTypes.array,
        selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
    interaction: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    interest: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any,
        label: PropTypes.string,
        value: PropTypes.number,
        isChecked: PropTypes.bool,
    })),
    privacy_policy: PropTypes.bool,
    first_name_error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    last_name_error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    email_error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    organization_name_error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    country_error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    telephone_error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    common_error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    loading: PropTypes.bool,
    onSelectCountry: PropTypes.func,
    onToggleRadioBtn: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onCheckboxPrivacyChange: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default withModal()(SignUp);