import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService, withScrollToTop} from '../common/hoc';

import classnames from 'classnames/bind';
import styles from './privacy-policy.module.scss';

const PrivacyPolicy = ({staticData}) => {
    const {title, updated, text} = staticData.privacy_policy;
    const cx = classnames.bind(styles);

    const pageTextHandler = (item, idx, paragraph = true) => {
        if (typeof item === 'string') {
            if (!paragraph) {
                return item;
            }

            return <p key={idx}>{item}</p>;
        }

        if (item.tag) {
            const {tag, href, rel, target, text, items, className} = item;
            switch (tag) {
                case 'h1':
                    return <h1 key={idx}>{text}</h1>;
                case 'h2':
                    return <h2 key={idx}>{text}</h2>;
                case 'h3':
                    return <h3 key={idx}>{text}</h3>;
                case 'a':
                    return (
                        <a key={idx} href={href} rel={rel} target={target} className={styles.link}>
                            {text}
                        </a>
                    );
                case 'ul':
                    return (
                        <ul key={idx} className={styles.list}>
                            {items.map((item, idx) =>
                                <li key={idx} className={cx(className)}>
                                    {pageTextHandler(item, null, false)}
                                </li>,
                            )}
                        </ul>
                    );
                case 'strong':
                    return <strong key={idx}>{text}</strong>;
                default: // tag = span
                    return <span key={idx}>{text}</span>;
            }
        }

        return item.map((item, idx) => pageTextHandler(item, idx));
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.text}>
                <p className={styles.updated}>{updated}</p>
                {text.map((item, idx) => pageTextHandler(item, idx))}
            </div>
        </div>
    );
};

const EnhancedComponent = compose(
    withDataService(),
    withScrollToTop(),
)(PrivacyPolicy);

const PrivacyPolicyContainer = () => (
    <div className={styles.wrapper}>
        <EnhancedComponent
            classNames={styles['scroll-to-top']}
            scrollMode={'fast'}
            userMode
            allScreen
        />
    </div>
);

PrivacyPolicy.propTypes = {
    staticData: PropTypes.object,
};

export default PrivacyPolicyContainer;