import {createRequestHandler} from '../../../../utils';

import {
    PRODUCTS_LIST_FOR_PACKING_UPDATING_SUCCESS,
} from './products-list-for-packing';

export const SUGGEST_STRATEGY_REQUEST = 'SUGGEST_STRATEGY_REQUEST';
export const SUGGEST_STRATEGY_SUCCESS = 'SUGGEST_STRATEGY_SUCCESS';
export const SUGGEST_STRATEGY_FAILURE = 'SUGGEST_STRATEGY_FAILURE';

export const SUGGEST_STRATEGY_UPDATING_REQUEST = 'SUGGEST_STRATEGY_UPDATING_REQUEST';
export const SUGGEST_STRATEGY_UPDATING_SUCCESS = 'SUGGEST_STRATEGY_UPDATING_SUCCESS';
export const SUGGEST_STRATEGY_UPDATING_FAILURE = 'SUGGEST_STRATEGY_UPDATING_FAILURE';

export const suggestStrategyRequest = createRequestHandler('SUGGEST_STRATEGY');
export const updatingSuggestStrategyRequest = createRequestHandler('SUGGEST_STRATEGY_UPDATING');

const initialState = {
    list: [{
        value: 'default',
        isSelected: true,
    }],
    selectedStrategy: null,
    loading: false,
    updating: false,
    error: null,
};

const onInitList = (payload, state) => {
    const {list} = payload;
    const {list: prevList} = state;

    const newList = list.map((item) => {
        return {
            ...item,
            isSelected: false,
        };
    });

    return [...prevList, ...newList];
};

const onUpdateList = (payload, state) => {
    const {list} = payload;
    const {list: prevList} = state;

    let newList = prevList;
    list.forEach((item) => {
        const {value} = item;

        newList = newList.map((listItem) => {
            if (listItem.value === value) {
                return {
                    ...listItem,
                    ...item,
                };
            }

            return listItem;
        });
    });

    return [...newList];
};

const suggestStrategy = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SUGGEST_STRATEGY_REQUEST:
            return {
                ...state,
                selectedStrategy: null,
                loading: true,
                updating: false,
                error: null,
            };

        case SUGGEST_STRATEGY_UPDATING_REQUEST:
            return state;

        case SUGGEST_STRATEGY_SUCCESS:
            const initList = onInitList(payload, state);

            return {
                list: initList,
                selectedStrategy: null,
                loading: false,
                updating: false,
                error: null,
            };

        case SUGGEST_STRATEGY_UPDATING_SUCCESS:
            const updatedList = onUpdateList(payload, state);

            return {
                ...state,
                updating: false,
                list: updatedList,
            };

        case SUGGEST_STRATEGY_FAILURE:
            return {
                list: [],
                selectedStrategy: null,
                loading: false,
                updating: false,
                error: payload,
            };

        case SUGGEST_STRATEGY_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case PRODUCTS_LIST_FOR_PACKING_UPDATING_SUCCESS:
            return {
                ...state,
                updating: true,
            };

        case 'UPDATE_SUGGEST_STRATEGY_LIST':
            return {
                ...state,
                list: payload,
            };

        case 'UPDATE_SELECTED_STRATEGY':
            return {
                ...state,
                selectedStrategy: payload,
            };

        case 'RESET_SUGGEST_STRATEGY_UPDATING':
            return {
                ...state,
                updating: false,
            };

        case 'RESET_PACKING_LIST_WIZARD_STATE_TO_INITIAL':
        case 'RESET_PACKING_LIST_WIZARD_PAGE_WITHOUT_TABS':
        case 'RESET_PACKING_LIST_WIZARD_PAGE':
            return initialState;

        default:
            return state;
    }
};

export default suggestStrategy;