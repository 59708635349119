import {createRequestHandler} from '../../utils';

const COUNTRIES_PUBLIC_REQUEST = 'COUNTRIES_PUBLIC_REQUEST';
const COUNTRIES_PUBLIC_SUCCESS = 'COUNTRIES_PUBLIC_SUCCESS';
const COUNTRIES_PUBLIC_FAILURE = 'COUNTRIES_PUBLIC_FAILURE';

export const countiesPublicRequest = createRequestHandler('COUNTRIES_PUBLIC');

const initialState = {
    data: {
        loading: false,
        list: [],
        selected: '',
    },
};

const onInitCountriesPublic = ({list}) => {
    let updatedState = {
        loading: false,
        list,
        selected: '',
    };

    if (list.length > 0) {
        return {
            ...updatedState,
            selected: list[0],
        };
    }

    return updatedState;
};

const countries = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case COUNTRIES_PUBLIC_REQUEST:
            return {
                ...state,
                data: {
                    loading: true,
                    list: [],
                    selected: '',
                },
            };

        case COUNTRIES_PUBLIC_SUCCESS:
            return {
                ...state,
                data: onInitCountriesPublic(payload.countries),
            };

        case COUNTRIES_PUBLIC_FAILURE:
            return {
                ...state,
                data: {
                    loading: false,
                    list: [],
                    selected: '',
                },
            };

        default:
            return state;
    }
};

export default countries;