import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../hoc';
import withHubMenuLinks from '../hoc/with-hub-menu-links';
import {withServices} from '../../../service-context';
import {logoutRequest} from '../../../../reducers/login';
import {staticURLs} from '../../../../services/api-urls';

import MenuItem from '../menu-item';
import Dropdown from '../../dropdown';

import classnames from 'classnames/bind';
import styles from './hub-dropdown-menu.module.scss';

import profileIcon from '../../assets/profile_icons/profile_icon.svg';
import whiteProfileIcon from '../../assets/profile_icons/white_profile_icon.svg';

class HubDropdownMenu extends Component {
    state = {
        isHubSelectOpen: false,
        menuItems: [],
    };

    onEvents = () => {
        this.setState({isHubSelectOpen: false});
    };

    componentDidMount() {
        if (this.props.hubMenuLinks) {
            this.menuLinkCreator(this.props.hubMenuLinks);
        }

        window.addEventListener('resize', this.onEvents);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.hubMenuLinks !== this.props.hubMenuLinks) {
            if (this.props.hubMenuLinks) {
                this.menuLinkCreator(this.props.hubMenuLinks);
            }
        }
    }

    menuLinkCreator = (links) => {
        const menuItems = links.map((item) => {
            if (item.submenu) {
                return {
                    ...item,
                    isOpen: false,
                };
            }

            return item;
        });
        this.setState({menuItems});
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onEvents);
    }

    onToggleMenuItem = (currentId) => {
        const {menuItems} = this.state;

        const newMenuItems = menuItems.map(({id, isOpen, ...rest}) => {
                if (id === currentId) {
                    return {
                        id,
                        ...rest,
                        isOpen: !isOpen,
                    };
                }

                return {
                    id,
                    ...rest,
                    isOpen: false,
                };
            },
        );

        this.setState({menuItems: newMenuItems});
    };

    onLogout = () => {
        this.props.fetchLogout();
        this.props.history.replace(staticURLs.home);
    };

    render() {
        const {isHubSelectOpen, menuItems} = this.state;
        const {userInteraction, profile, classNames} = this.props;
        const {
            hub_menu: {user_name, profile_img_alt},
            header: {trial_identifier},
        } = this.props.staticData;
        const cx = classnames.bind(styles);

        let name = user_name, email = null, isTrial = null;

        if (profile) {
            name = profile.name;
            email = profile.email;
            isTrial = profile.is_trial;
        }

        const menuItemsList = menuItems.map((item, idx) => {
            const {id, submenu, ...rest} = item;

            if (submenu) {
                const labelClasses = cx('label', {'active': item.isOpen});

                return (
                    <div id={id} className={styles['dropdown-item']} key={idx}>
                        <Dropdown
                            {...rest}
                            labelClassNames={labelClasses}
                            onToggle={() => this.onToggleMenuItem(id)}
                        >
                            {submenu.map((item, idx) => (
                                <MenuItem
                                    key={idx}
                                    {...item}
                                    classNames={styles['submenu-item']}
                                    onClick={() => !item.isDisabled && this.setState({isHubSelectOpen: false})}/>
                            ))}
                        </Dropdown>
                    </div>
                );
            }

            return (
                <MenuItem
                    key={idx}
                    {...rest}
                    withIcon={idx === menuItems.length - 1 ? 'logout' : false}
                    onClick={() => {
                        this.setState({isHubSelectOpen: false});

                        if (idx === menuItems.length - 1) {
                            this.onLogout();
                        }
                    }}
                />
            );
        });

        const userProfile = (
            <div className={styles['user-profile']}>
                <img src={profileIcon} alt={profile_img_alt} className={styles.icon}/>
                <div className={cx('name', {'lg-visible': userInteraction === 'exporter' || isTrial})}>
                    {name}
                </div>
            </div>
        );

        return (
            <div className={cx('container', classNames)}>
                <Dropdown label={userProfile}
                          isOpen={isHubSelectOpen}
                          itemsClassNames={styles['options-block']}
                          placement={'bottom'}
                          offset={[0, 16]}
                          onToggle={() =>
                              this.setState(({isHubSelectOpen}) => ({
                                  isHubSelectOpen: !isHubSelectOpen,
                              }))}
                >
                    <div className={styles['user-block']}>
                        <div className={styles.name}>
                            <img src={whiteProfileIcon} alt={profile_img_alt} className={styles.icon}/>
                            {name}
                        </div>
                        <div className={styles.email}>{email}</div>
                        {isTrial && <div className={styles['profile-status']}>{trial_identifier.label}</div>}
                    </div>
                    <div className={styles.items}>{menuItemsList}</div>
                </Dropdown>
            </div>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        userInteraction: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        profile: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            is_trial: PropTypes.bool,
        }),
        hubMenuLinks: PropTypes.arrayOf(PropTypes.object),
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        fetchLogout: PropTypes.func,
        history: PropTypes.shape({
            push: PropTypes.func,
        }),
    };
}

const mapServicesToProps = ({userService}) => ({
    fetchLogout: logoutRequest(userService.logout),
});

const mapStateToProps = ({profile: {userInteraction, profile}}) => ({
    userInteraction,
    profile,
});

const mapDispatchToProps = (dispatch, {fetchLogout}) => {
    return bindActionCreators({
        fetchLogout,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    withDataService(),
    withHubMenuLinks(),
    connect(mapStateToProps, mapDispatchToProps),
)(HubDropdownMenu);