import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {getDataFromLocalStorage, updateLocalStorage} from '../../../utils';
import {switchHomePageLanguage, switchLanguage} from '../../../actions/common';

import LanguageMenu from './language-menu';

class LanguageMenuContainer extends Component {
    state = {
        selectOpen: false,
        loading: false,
    };

    componentDidMount() {
        this.onUpdateHomePageLanguage();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {
            this.onUpdateHomePageLanguage();
        }

        if (prevState.selectOpen !== this.state.selectOpen) {
            if (this.state.selectOpen) {
                if (this.props.loading || this.props.updating) {
                    this.setState({loading: true});
                }
            }
        }

        if (prevProps.languagesList !== this.props.languagesList) {
            if (this.state.loading) {
                this.setState({loading: false});
            }
        }
    }

    onUpdateHomePageLanguage = () => {
        if (this.props.session !== null && !this.props.session) {
            const lsLanguage = getDataFromLocalStorage('slanguage');

            if (lsLanguage) {
                this.props.switchHomePageLanguage(lsLanguage);
            }
        }
    };

    onSwitchLanguage = (code) => {
        this.setState({selectOpen: false});
        updateLocalStorage('slanguage', code);

        if (this.props.session) {
            this.props.switchLanguage(code);
        } else {
            this.props.switchHomePageLanguage(code);
        }
    };

    toggleLanguageSelect = (isOpen) => this.setState({selectOpen: isOpen});

    render() {
        return <LanguageMenu {...this.props} {...this.state}
                             toggleLanguageSelect={this.toggleLanguageSelect}
                             onSwitchLanguage={this.onSwitchLanguage}/>;
    }

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        switchLanguage: PropTypes.func,
        switchHomePageLanguage: PropTypes.func,
    };
}

const mapStateToProps = ({session: {session}, publicData: {homePage}, profile}) => ({
    session,
    loading: session && profile.loading,
    updating: session && profile.updating,
    language: session ? profile.language : homePage.language,
    languagesList: session ? profile.languagesList : homePage.languagesList,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        switchLanguage,
        switchHomePageLanguage,
    }, dispatch);
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(LanguageMenuContainer);