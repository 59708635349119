import {createAlertResponse, createRequestHandler} from "../../../utils";
import {CLOSE_SIGN_IN} from "../../login";

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPasswordRequest = createRequestHandler('RESET_PASSWORD', {
    notAcceptableErrorRedirect: false,
});

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const request = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case RESET_PASSWORD_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case RESET_PASSWORD_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case RESET_PASSWORD_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case CLOSE_SIGN_IN:
        case 'SET_INITIAL_RESET_PASSWORD_REDUX_STATE':
            return initialState;

        default:
            return state;
    }
};

export default request;