export const updateDataFields = (newFields) => {
    return {
        type: 'UPDATE_PROFILE_DATA_LIST',
        payload: newFields,
    };
};

export const resetUploadResponse = () => {
    return {type: 'RESET_UPLOAD_PROFILE_RESPONSE'};
};

export const resetAccessSetupDataUpload = (list) => {
    if (list) {
        return {type: 'RESET_ACCESS_SETUP_DATA_UPLOAD', payload: list};
    }

    return {type: 'RESET_ACCESS_SETUP_DATA_UPLOAD'};
};