import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../common/hoc';
import {NOT_CAPITALIZE_LANGUAGES} from '../../services/data-service/constants';

import ActionStepCard from '../common/action-step-card';
import RequestAccessZone from '../common/request-access-zone/request-access-zone';

import classnames from 'classnames/bind';
import styles from './about-independent-technical-surveing.module.scss';

const AboutIndependentTechnicalSurveying = ({staticData}) => {
    const {title, subtitle, content} = staticData;
    const {language, session} = useSelector(({
                                                 session: {session},
                                                 profile,
                                                 publicData: {homePage},
                                             }) => ({
        language: session
            ? profile.language
            : homePage.language,
        session,
    }));
    const cx = classnames.bind(styles);
    const notCapitalizeClass = cx({'not-capitalize-text': NOT_CAPITALIZE_LANGUAGES.includes(language)});
    const titleClasses = `${styles.title} ${notCapitalizeClass}`;
    const subtitleClasses = `${styles.subtitle} ${notCapitalizeClass}`;

    return (
        <>
            <div className={styles.container}>
                <h1 className={titleClasses}>{title}</h1>
                <div className={subtitleClasses}>{subtitle}</div>
                <ActionStepCard actionList={content}/>
            </div>
            {!session && <RequestAccessZone/>}
        </>
    );
};

AboutIndependentTechnicalSurveying.propTypes = {
    staticData: PropTypes.object,
};

export default withDataService(
    'about_independent_technical_surveying',
)(AboutIndependentTechnicalSurveying);