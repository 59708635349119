import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../common/hoc';
import onCreateFormData from "../../common/handlers/create-form-data";
import {onGetAlertResponse} from "../../../utils";
import sweetAlert from "../../common/sweet-alert";

import SignIn from './sign-in';

import styles from './sign-in.module.scss';

class SignInContainer extends Component {
    initialState = {
        email: null,
        password: null,
        code: null,
        emailError: null,
        passwordError: null,
        codeError: null,
        commonError: null,
        isVisiblePassword: false,
        isForgotPasswordForm: false,
    };

    state = this.initialState;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.error !== this.props.error) {
            const {error} = this.props;

            if (error) {
                if (typeof error === 'string') {
                    this.setState({commonError: error});
                }

                if (Array.isArray(error)) {
                    for (let item of error) {
                        this.setState({
                            [`${item.field}Error`]: item.message,
                        });
                    }
                }
            } else {
                this.setState({emailError: null, passwordError: null, commonError: null});
            }
        }

        if (prevProps.resetPasswordRequest !== this.props.resetPasswordRequest) {
            if (prevProps.resetPasswordRequest.loading) {
                const {status, text} = onGetAlertResponse(this.props.resetPasswordRequest);

                if (status !== '' && text !== '') {
                    if (status === 'success') {
                        sweetAlert(status, text).then(() => {
                            this.props.onSuccessResetPassword();
                        });

                    } else {
                        this.setState({commonError: text});
                    }
                }
            }
        }

        if (prevProps.signInActive !== this.props.signInActive) {
            const form = document.getElementById('sign-in-form');

            if (form) {
                const inputs = form.querySelectorAll('input');
                for (let input of inputs) {
                    input.value = '';
                }
            }

            this.setState(this.initialState);
        }
    }

    onInputChange = ({target: {name, value}}) => {
        this.setState({
            [name]: value,
        });

        this.onResetError(name);
    };

    onInputFocus = ({target: {name}}) => {
        this.onResetError(name);
    };

    onResetError = (name) => {
        if (this.state.commonError) {
            if (this.props.resetPasswordRequest.error) {
                this.props.onSetInitialResetPasswordReduxState();
            }

            this.setState({
                commonError: null,
            });
        }

        if (this.state[`${name}Error`]) {
            this.setState({
                [`${name}Error`]: null,
            });
        }
    };

    onResetPassword = () => {
        this.setState({
            ...this.initialState,
            isForgotPasswordForm: true,
        });
    };

    onSubmitCredentials = () => {
        const {email, password} = this.state;
        const {required_field_error_message} = this.props.staticData.authorization.sign_in;

        let valid = true;

        if (!email) {
            valid = false;
            this.setState({emailError: required_field_error_message});
        }

        if (!password) {
            valid = false;
            this.setState({passwordError: required_field_error_message});
        }

        if (valid) {
            this.props.onSubmit({email, password});
        }
    };

    onSubmitTwoFactorCredentials = () => {
        const {code} = this.state;
        const {twoFactorData: {url, form}} = this.props;
        const {required_field_error_message} = this.props.staticData.authorization.sign_in;

        let valid = true;

        if (!code) {
            valid = false;
            this.setState({codeError: required_field_error_message});
        }

        if (valid) {
            const formData = onCreateFormData({...form, code});
            this.props.onSubmitTwoFactor(url, {formData});
        }
    };

    onSubmitResetPassword = () => {
        const {email} = this.state;
        const {required_field_error_message} = this.props.staticData.authorization.sign_in;

        let valid = true;

        if (!email) {
            valid = false;
            this.setState({emailError: required_field_error_message});
        }

        if (valid) {
            let formData = new FormData();
            formData.append('email', email);
            this.props.onSubmitResetPassword({formData});
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {isForgotPasswordForm} = this.state;
        const {isTwoFactorActive} = this.props;
        const {target: form} = e;
        const inputs = form.querySelectorAll('input');
        inputs.forEach((input) => input.blur());

        if (isTwoFactorActive) {
            this.onSubmitTwoFactorCredentials();

        } else if (isForgotPasswordForm) {
            this.onSubmitResetPassword();

        } else {
            this.onSubmitCredentials();
        }
    };

    togglePasswordVisibility = () => {
        this.setState(({isVisiblePassword}) => ({
            isVisiblePassword: !isVisiblePassword,
        }));
    };

    render() {
        const {signInActive, loading, resetPasswordRequest, ...otherProps} = this.props;

        return <SignIn {...otherProps} {...this.state}
                       loading={loading || resetPasswordRequest.loading}
                       modalContainerClass={styles['modal-container']}
                       modalClass={styles.modal}
                       closeBtnColorMobile="WHITE"
                       onInputChange={this.onInputChange}
                       onInputFocus={this.onInputFocus}
                       togglePasswordVisibility={this.togglePasswordVisibility}
                       onResetPassword={this.onResetPassword}
                       onSubmit={this.onSubmit}
                       isShown={signInActive}
                       closeButton/>;

    }

    static propTypes = {
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
            PropTypes.array,
        ]),
        signInActive: PropTypes.bool,
        isTwoFactorActive: PropTypes.bool,
        twoFactorData: PropTypes.object,
        resetPasswordRequest: PropTypes.object,
        onSubmit: PropTypes.func,
        onSubmitTwoFactor: PropTypes.func,
        onSubmitResetPassword: PropTypes.func,
        onSuccessResetPassword: PropTypes.func,
        onSetInitialResetPasswordReduxState: PropTypes.func,
    };
}

const mapStateToProps = ({login, publicData: {resetPassword: {request}}}) => ({
    loading: login.loading,
    error: login.error,
    signInActive: login.signInActive,
    isTwoFactorActive: login.isTwoFactorActive,
    twoFactorData: login.twoFactorData,
    resetPasswordRequest: request,
});

export default compose(
    withDataService(),
    connect(mapStateToProps),
)(SignInContainer);