import React, {Component} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../common/hoc';

import FileContainer from '../common/file-container';

import styles from './excel-format-page.module.scss';
import excelExamples from '../../excel_examples.json';

class ExcelFormatPage extends Component {
    state = {
        file: null,
    };

    componentDidMount() {
        const {forExcel} = this.props;
        const currentFileIdx = excelExamples.findIndex(({id}) => id === forExcel);
        const currentFile = excelExamples[currentFileIdx];

        fetch(currentFile.href)
            .then((resp) => resp.blob())
            .then((blob) => {
                const newFile = {
                    ...currentFile,
                    size: blob.size,
                    type: blob.type,
                };
                this.setState({file: newFile});
            });
    }

    render() {
        const {staticData, forExcel, onBack} = this.props;
        const {file} = this.state;

        const {
            quotation_text, quotation_tip, place_product_text,
            place_product_tip, quotation_table, place_product_table,
            button: {back},
        } = staticData;

        const quotationTable = (
            <table className={styles['quotation-table']}>
                <thead>
                <tr>
                    {quotation_table.headers.map((item, idx) => (
                        <th key={idx} className={styles.title}>{item}</th>
                    ))}
                </tr>
                </thead>

                <tbody>
                {quotation_table.body.map(({part_number, description, doq, quantity}, idx) => {
                    return (
                        <tr key={idx}>
                            <td className={styles.cell}>{part_number}</td>
                            <td className={styles.cell}>{description}</td>
                            <td className={styles.cell}>{doq}</td>
                            <td className={styles.cell}>{quantity}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );

        const placeProductTable = (
            <table className={styles['place_product_table']}>
                <thead>
                <tr>
                    {place_product_table.headers.map((item, idx) => (
                        <th key={idx} className={styles.title}>{item}</th>
                    ))}
                </tr>
                </thead>

                <tbody>
                {place_product_table.body.map((item, idx) => {
                    const {
                        part_number, part_number_cyrillic, description,
                        section, doq, quantity, condition, price,
                        currency, date, offer_validity, delivery_lead_time,
                        required_from_importer, supply_conditions, supply_point,
                    } = item;

                    return (
                        <tr key={idx}>
                            <td className={styles.cell}>{part_number}</td>
                            <td className={styles.cell}>{part_number_cyrillic}</td>
                            <td className={styles.cell}>{description}</td>
                            <td className={styles.cell}>{section}</td>
                            <td className={styles.cell}>{doq}</td>
                            <td className={styles.cell}>{quantity}</td>
                            <td className={styles.cell}>{condition}</td>
                            <td className={styles.cell}>{price}</td>
                            <td className={styles.cell}>{currency}</td>
                            <td className={styles.cell}>{date}</td>
                            <td className={styles.cell}>{offer_validity}</td>
                            <td className={styles.cell}>{delivery_lead_time}</td>
                            <td className={styles.cell}>{required_from_importer}</td>
                            <td className={styles.cell}>{supply_conditions}</td>
                            <td className={styles.cell}>{supply_point}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );

        return (
            <div id="excel-format-page" className={styles.container}>
                <div className={styles['back-btn']} onClick={onBack}>{back}</div>

                <div className={styles.text}>{forExcel === 'quotation' ? quotation_text : place_product_text}</div>
                <div className={styles['table-container']}>
                    {forExcel === 'quotation' ? quotationTable : placeProductTable}
                </div>
                <div className={styles.tip}>{forExcel === 'quotation' ? quotation_tip : place_product_tip}</div>
                {file && <FileContainer file={file} blockClassNames={styles.file} canDownload canDelete={false}/>}
            </div>
        );
    }

    static defaultProps = {
        forExcel: 'quotation',
        onBack: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        forExcel: PropTypes.oneOf(['quotation', 'place-product']),
        onBack: PropTypes.func,
    };
}

export default compose(
    withDataService('excel_format_page'),
)(ExcelFormatPage);