import React from 'react';
import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import 'tippy.js/animations/shift-away.css';

import styles from './tooltip.module.scss';

const Tooltip = (props) => {
    const {
        mode, tooltip, contentClassNames,
        arrow, minWidth, maxWidth, placement, animationDuration,
        interactive, zIndex, children, visible, onClickOutside,
    } = props;

    const baseSettings = {
        content: (
            <div className={`${styles.content} ${contentClassNames}`}
                 style={{minWidth: `${minWidth}px`}}
                 onClick={(e) => e.stopPropagation()}>
                {tooltip}
            </div>
        ),
        animation: 'shift-away',
        duration: animationDuration,
        arrow,
        appendTo: 'parent',
        maxWidth,
        role: 'tooltip',
        placement,
        interactive,
        zIndex,
    };

    if (mode !== 'default') {
        return (
            <Tippy {...baseSettings}
                   visible={visible}
                   onClickOutside={onClickOutside}>
                {children}
            </Tippy>
        );
    }

    return (
        <Tippy {...baseSettings}>
            {children}
        </Tippy>
    );
};

Tooltip.defaultProps = {
    mode: 'default',
    arrow: false,
    minWidth: 0,
    maxWidth: 300,
    placement: 'bottom',
    animationDuration: [300, 250],
    interactive: true,
    zIndex: 1,
    onClickOutside: () => {
    },
};

Tooltip.propTypes = {
    mode: PropTypes.oneOf(['default', 'manual']),
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    contentClassNames: PropTypes.string,
    arrow: PropTypes.bool,
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxWidth: PropTypes.number,
    placement: PropTypes.string,
    animationDuration: PropTypes.array,
    interactive: PropTypes.bool,
    zIndex: PropTypes.number,
    children: PropTypes.node,
    visible: PropTypes.bool,
    onClickOutside: PropTypes.func,
};

export default Tooltip;