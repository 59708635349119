import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {sectionSettingsHandler} from '../../common/handlers';

import SupplySet from './supply-set';

class SupplySetContainer extends Component {
    state = {
        id: null,
        supplySetData: null,
        isDisabledSection: false,
        isDisabledAlwaysSection: false,
    };

    componentDidMount() {
        this.setState({id: this.props.id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (this.props.sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, this.props.sections);

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }
    }

    onSupplySetSave = (e) => {
        e.preventDefault();
    };

    render() {
        const {supply_set} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {id, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;

        return <SupplySet {...this.props} {...rest}
                          id={id}
                          panelData={supply_set}
                          isDisabled={isDisabledSection}
                          isDisabledAlways={isDisabledAlwaysSection}
                          onClick={() => this.props.onClick(id)}
                          onSave={this.onSupplySetSave}
                          onSkip={() => this.props.onSkip(id)}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onClick: () => {
        },
        onSave: () => {
        },
        onSkip: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        sections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
    };
}

export default SupplySetContainer;