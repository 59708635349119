import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './shipment-notice-progress-bar.module.scss';

const ShipmentNoticeProgressBar = ({list}) => {
    const [progressList, setProgressList] = useState(null);

    useEffect(() => {
        let kLeft = 0;
        const newProgress = list
            .map((point, idx) => {
                const shiftLeft = kLeft;
                const percent = 100 / (list.length - 1);
                let width = idx !== 0 ? percent : 0;

                kLeft += width;

                return {
                    ...point,
                    width,
                    shiftLeft,
                };
            });

        setProgressList(newProgress);
    }, [list]);

    const createProgressBlock = () => {
        if (!list.length) {
            return null;
        }

        const cx = classnames.bind(styles);
        const progressBaseLineBeforeCircleStatus = list[0].status;
        const progressBaseLineBeforeCircleClass =
            progressBaseLineBeforeCircleStatus !== 'pending'
                ? `before-circle-${progressBaseLineBeforeCircleStatus}`
                : null;
        let progressBlockClasses = cx('progress-base-line', progressBaseLineBeforeCircleClass);

        const stepList = progressList && progressList.map(({label, status, width, shiftLeft}, idx) => {
            const stepClasses = cx('step', [`${status}`], {
                'even': idx % 2 !== 0,
            });
            const zIndex = list.length - idx;

            return (
                <div key={idx} className={stepClasses}
                     style={{width: `${width}%`, left: `${shiftLeft}%`, zIndex: zIndex}}>
                    <div className={styles.info}>{label}</div>
                </div>
            );
        });

        return <div className={progressBlockClasses}>{stepList}</div>;
    };

    return <div className={styles.container}>{createProgressBlock()}</div>;
};

ShipmentNoticeProgressBar.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        status: PropTypes.string,
    })),
};

export default ShipmentNoticeProgressBar;