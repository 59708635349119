import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';

import Preloader from '../../../../../../../common/preloader';
import FormFieldConstructor from '../../../../../../../common/form-field-constructor';
import Button from '../../../../../../../common/button';

import classnames from 'classnames/bind';
import styles from './main-technical-data.module.scss';

const MainTechnicalData = (props) => {
    const {
        staticData, id, formFields: {loading, updating}, isDisabledSection, mainTechnicalDataFields,
        isSaveButtonDisabled, onAddInputBlock, onRemoveInputBlock, onSave, onSkip, ...rest
    } = props;

    const {
        sections: {main_technical_data: {button: {add, remove}}},
        button: {save, skip},
    } = staticData.activity_hub.place_product.from_form;

    const cx = classnames.bind(styles);

    const fields = mainTechnicalDataFields ? mainTechnicalDataFields.map((field, idx) => {
        if (Array.isArray(field)) {
            return field.map((item, idx) =>
                <FormFieldConstructor {...rest} key={idx} field={item}/>,
            );
        } else {
            return <FormFieldConstructor {...rest} key={idx} field={field}/>;
        }
    }) : [];

    let [textareaBlock, ...inputBlocks] = fields.reverse(); // to find only last
    inputBlocks = inputBlocks.reverse(); // reverse back input blocks

    return (
        (loading || updating)
            ? <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
            : (
                <form id={`${id}_form`} className={styles.form}>
                    {inputBlocks.map((block, idx) => {
                        const inputBlockClasses = cx('input-block', {
                            'with-indent-bottom': isDisabledSection && idx === inputBlocks.length - 1,
                        });

                        return (
                            <div key={idx} className={inputBlockClasses}>
                                <div className={styles.group}>{block}</div>

                                {(!isDisabledSection && idx > 0) && (
                                    <Button styleType={'WITH_MINUS'}
                                            classNames={styles['remove-item-btn']}
                                            onClick={() => onRemoveInputBlock(idx)}>
                                        {remove}
                                    </Button>
                                )}
                            </div>
                        );
                    })}

                    {!isDisabledSection && (
                        <Button styleType={'WITH_PLUS'}
                                classNames={styles['add-item-btn']}
                                onClick={onAddInputBlock}>
                            {add}
                        </Button>
                    )}

                    {textareaBlock}

                    {!isDisabledSection && (
                        <div className={styles['btn-group']}>
                            <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                            <Button classNames={styles['btn-submit']}
                                    isDisabled={isSaveButtonDisabled}
                                    onClick={onSave}>
                                {save}
                            </Button>
                        </div>
                    )}
                </form>
            )
    );
};

MainTechnicalData.defaultProps = {
    isDisabledSection: false,
    onSave: () => {
    },
    onSkip: () => {
    },
};

MainTechnicalData.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.string,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    isDisabledSection: PropTypes.bool,
    mainTechnicalDataFields: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    isSaveButtonDisabled: PropTypes.bool,
    onAddInputBlock: PropTypes.func,
    onRemoveInputBlock: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
};

export default withSectionPanel()(MainTechnicalData);