import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';

import Button from '../button';
import Preloader from '../preloader';

import classnames from 'classnames/bind';
import styles from './section.module.scss';

const Section = (props) => {
    const {
        title, page, cardContent, iconsContent,
        dataLoading, staticData, sectionClassNames, style,
    } = props;
    let {children, baseView, noContentMessage} = props;
    const {no_content, button: {more_btn, less_btn}} = staticData;

    noContentMessage = noContentMessage || no_content;

    const smDevice = useMediaQuery({minWidth: 440});
    const mdDevice = useMediaQuery({minWidth: 768});

    if (Array.isArray(baseView)) {
        baseView = mdDevice
            ? baseView[2] : smDevice
                ? baseView[1] : baseView[0];
    }

    let content = children;
    if (cardContent && children.length > baseView) {
        content = children.slice(0, baseView);
    }

    const [isShowAll, setIsShowAllValue] = useState(false);
    const titleRef = useRef(null);
    const history = useHistory();

    const cx = classnames.bind(styles);
    const contentClasses = cx('content', {
        'grid-for-card': cardContent,
        'grid-for-icons': iconsContent,
        'show-more-active': isShowAll,
    });

    const onToggleContent = () => {
        if (page) {
            history.push(page);
        } else {
            setIsShowAllValue(!isShowAll);

            if (!isShowAll) {
                titleRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }
    };

    return (
        <div className={cx('section-container', sectionClassNames)}>
            <h1 ref={titleRef} className={styles.title}>{title}</h1>
            {dataLoading
                ? (
                    <div className={styles.content}>
                        <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                    </div>
                ) : children.length > 0
                    ? <div className={contentClasses} style={style}>{content}</div>
                    : <div className={styles.content}>{noContentMessage}</div>
            }
            {cardContent && children.length > baseView && (
                <Button styleType={'SECONDARY'}
                        classNames={styles.btn}
                        isTransformText={false}
                        onClick={onToggleContent}>
                    {!isShowAll ? more_btn : less_btn}
                </Button>
            )}
        </div>
    );
};

Section.defaultProps = {
    cardContent: false,
    iconsContent: false,
    baseView: 4,
};

Section.propTypes = {
    title: PropTypes.string.isRequired,
    page: PropTypes.string,
    children: PropTypes.node.isRequired,
    cardContent: PropTypes.bool,
    iconsContent: PropTypes.bool,
    baseView: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    staticData: PropTypes.object,
    sectionClassNames: PropTypes.string,
    style: PropTypes.object,
    sectionGridSize: PropTypes.string,
};

export default withDataService('section')(Section);