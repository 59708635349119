import React, {useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';

import onRedirect from '../handlers/redirect';
import sweetAlert from '../sweet-alert';
import {staticURLs} from '../../../services/api-urls';

const withRedirectAction = () => (Wrapped) => {
    return (props) => {
        const {staticData, history, url = '/hub'} = props;
        const xlDevice = useMediaQuery({minWidth: 1200});

        useEffect(() => {
            if (!xlDevice) {
                const {redirect_disclaimer} = staticData.alert_modal;

                if (history && url) {
                    onRedirect(history, url, redirect_disclaimer);
                } else {
                    sweetAlert('info', redirect_disclaimer)
                        .then(() => window.location.replace(staticURLs.home));
                }

            }
        }, [xlDevice, history, url, staticData]);

        return xlDevice ? <Wrapped {...props}/> : null;
    };
};

export default withRedirectAction;