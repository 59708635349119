import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {onGetAlertResponse, priceHandler} from '../../../../../../../../utils';
import {withServices} from '../../../../../../../service-context';
import {confirmRFSRequest} from '../../../../../../../../reducers/marketplace/quotation-page/request-for-supply/confirm-rfs';
import sweetAlert from '../../../../../../../common/sweet-alert';
import {resetConfirmRFS} from '../../../../../../../../actions/marketplace/request-for-supply';

import Button from '../../../../../../../common/button';

import styles from './order-summary.module.scss';

class OrderSummary extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.confirmRFS !== this.props.confirmRFS) {
            const {status, text} = onGetAlertResponse(this.props.confirmRFS);

            if (status !== '' && text !== ''){
                sweetAlert(status, text).then(() => {
                    if (status === 'success') {
                        this.props.history.push('/hub');
                    }
                    this.props.resetConfirmRFS();
                });
            }
        }
    }

    onConfirmQuotation = () => {
        this.props.fetchConfirmingRFS();
    };

    render() {
        const {staticData, summary, loading} = this.props;
        const {inspection, shipping, sub_total, total} = summary;
        const {title, button: {confirm_quotation}} = staticData.marketplace.quotation.order_summary_block;

        if (inspection && shipping && sub_total && total) {
            const totalLabelAsArray = total.label.split(': ');
            const totalLabelBlock = (
                <span>
                    {`${totalLabelAsArray[0]}: `}
                    <span className={styles.substr}>{totalLabelAsArray[1]}</span>
                </span>
            );
            return (
                <div className={styles.container}>
                    <div className={styles.title}>
                        {title}
                    </div>

                    <div className={styles['subtotal-block']}>
                        <div className={styles['subtotal-item']}>
                            <span>{sub_total.label}</span>
                            <span>{sub_total.value} {sub_total.currency}</span>
                        </div>
                        <div className={styles['subtotal-item']}>
                            <span>{shipping.label}</span>
                            <span>{shipping.value} {shipping.currency}</span>
                        </div>
                        <div className={styles['subtotal-item']}>
                            <span>{inspection.label}</span>
                            <span>{priceHandler(inspection.value)} {inspection.currency}</span>
                        </div>
                    </div>

                    <hr className={styles.separator}/>

                    <div className={styles['total-label']}>
                        {totalLabelBlock}
                        <span>{total.value} {total.currency}</span>
                    </div>

                    <Button classNames={styles['btn-confirm']}
                            dataLoading={loading}
                            onClick={this.onConfirmQuotation}>
                        {confirm_quotation}
                    </Button>
                </div>
            );
        }

        return null;
    }

    static propTypes = {
        staticData: PropTypes.object,
        summary: PropTypes.shape({
            inspection: PropTypes.shape({
                value: PropTypes.string,
                currency: PropTypes.string,
                label: PropTypes.string,
            }),
            shipping: PropTypes.shape({
                value: PropTypes.string,
                currency: PropTypes.string,
                label: PropTypes.string,
            }),
            sub_total: PropTypes.shape({
                value: PropTypes.string,
                currency: PropTypes.string,
                label: PropTypes.string,
            }),
            total: PropTypes.shape({
                value: PropTypes.string,
                currency: PropTypes.string,
                label: PropTypes.string,
            }),
        }).isRequired,
        loading: PropTypes.bool,
        confirmRFS: PropTypes.object,
        history: PropTypes.shape({
            push: PropTypes.func,
        }),
        fetchConfirmingRFS: PropTypes.func,
        resetConfirmRFS: PropTypes.func,
    };
}

const mapServicesToProps = ({quotationService}) => ({
    fetchConfirmingRFS: confirmRFSRequest(quotationService.confirmRFS),
});

const mapStateToProps = ({marketplace: {quotation: {confirmRFS}}}) => ({
    loading: confirmRFS.loading,
    confirmRFS,
});

const mapDispatchToProps = (dispatch, {fetchConfirmingRFS}) => {
    return bindActionCreators({
        fetchConfirmingRFS,
        resetConfirmRFS,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(OrderSummary);