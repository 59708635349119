import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {onInitSectionStateFromDataByServer, sectionSettingsHandler} from '../../common/handlers';
import {
    transformFieldWithDependsFrom,
    onChangeFieldValue,
    onChangeCheckboxGroupValue,
    onTransformFieldsWithSelectInputGroup,
    onUpdateSelectInputGroup,
    onChangeRadioButtonValue,
    onCheckingRequiredFieldData,
    onResetFormError,
    formDataObjectHandler,
} from '../../../../../common/handlers';
import {
    fetchSessionStorageItems,
    transformFieldData,
    getDataFromFields,
} from '../../../../../common/handlers/field-handlers';

import {withServices} from '../../../../../../../service-context';
import {
    suggestPlatformsRequest
} from '../../../../../../../../reducers/activity-hub/place-product/suggest/suggest-platforms';
import {suggestTitlesRequest} from '../../../../../../../../reducers/activity-hub/place-product/suggest/suggest-titles';
import {placeProductSuggestAction} from '../../../../../../../../actions/activity-hub';

import ProductInformation from './product-information';

class ProductInformationContainer extends Component {
    state = {
        id: null,
        productInformationFields: null,
        fieldsNameAutocompleteByPnum: [],
        categoryIds: null,
        isUpdatingSelectAutocomplete: true,
        isResourcesSection: false,
        isDisabledSection: false,
        isDisabledAlwaysSection: false,
        isSaveButtonDisabled: false,
        formError: false,
        sessionStorageItems: null,
    };

    storageKey = 'product_information';
    storageFilledByPnumKey = 'fieldwithdatabypnum';

    componentDidMount() {
        if (this.props.pageMode === 'EDIT') {
            this.setState({isUpdatingSelectAutocomplete: false});
        }
        this.setState({id: this.props.id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            const {sections} = this.props;

            if (sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, sections);

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }

        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                const {list, storageItem, pageMode} = this.props;

                const sessionStorageItems = fetchSessionStorageItems(storageItem, this.storageKey);
                const sessionFilledByPnumItems = fetchSessionStorageItems(storageItem, this.storageFilledByPnumKey);

                let productInformation = list
                    .filter(({section}) => section.id === 'product_information')
                    .map((field) => {
                        const newField = transformFieldData(field, sessionStorageItems, sessionFilledByPnumItems);

                        if (newField.type === 'checkbox' && newField.list) {
                            this.setState({categoryIds: newField.value});
                        }

                        return newField;
                    })
                    .map((field, idx, arr) => {
                        if (field.dependsFrom) {
                            return transformFieldWithDependsFrom(arr, field);
                        }

                        return field;
                    });

                if (productInformation.filter(({group}) => group).length > 0) {
                    productInformation = onTransformFieldsWithSelectInputGroup(productInformation);
                }

                if (pageMode === 'EDIT') {
                    const isDisabled = onInitSectionStateFromDataByServer(productInformation);

                    if (isDisabled !== this.state.isDisabledSection) {
                        this.props.updatedPlaceProductSectionDisabledStatus(this.state.id, isDisabled);
                    }
                }

                const fieldsNameAutocompleteByPnum = productInformation
                    .filter(({props}) =>
                        props && (props.includes('autocomplete_by_pnum') || props.includes('autocomplete_pnum')))
                    .map(({name}) => name);

                this.setState({
                    productInformationFields: productInformation,
                    fieldsNameAutocompleteByPnum,
                    sessionStorageItems,
                });
            }
        }

        if (prevState.categoryIds !== this.state.categoryIds) {
            if (this.state.isUpdatingSelectAutocomplete) {
                if ((!prevState.categoryIds && this.state.categoryIds.length > 0) || prevState.categoryIds !== null) {
                    this.getSelectAutocompleteParams(this.state.categoryIds);
                }
            }

            this.onShowDisabledSection(this.state.categoryIds);
        }

        if (prevProps.suggestPlatform !== this.props.suggestPlatform || prevProps.suggestTitles !== this.props.suggestTitles) {
            const {productInformationFields} = this.state;
            const {suggestPlatform, suggestTitles} = this.props;
            const selectAutocomplete = [suggestPlatform, suggestTitles];

            if (productInformationFields) {
                /* case for platform and title select_autocomplete */
                const selectAutocompleteFields = productInformationFields.filter(({type}) => type === 'select_autocomplete');
                const newSelectAutocompleteFields = [];

                selectAutocomplete.forEach((select, idx) => {
                    let {list, loading} = select;

                    if (list.length !== 0) {
                        list = list.map(({id, name}) => ({
                            value: id,
                            label: name,
                        }));
                    }

                    newSelectAutocompleteFields.push({
                        ...selectAutocompleteFields[idx],
                        optionsLoading: loading,
                        options: list,
                    });
                });

                let updatedFields = productInformationFields;
                newSelectAutocompleteFields.forEach((select) => {
                    const idx = updatedFields.findIndex((field) => field.name === select.name);
                    updatedFields = [
                        ...updatedFields.slice(0, idx),
                        select,
                        ...updatedFields.slice(idx + 1),
                    ];
                });

                this.setState({productInformationFields: updatedFields});
            }
        }

        if (prevState.isResourcesSection !== this.state.isResourcesSection) {
            const isDisabledSection = !this.state.isResourcesSection;

            if (this.props.pageMode !== 'EDIT') {
                this.props.updatedPlaceProductSectionDisabledStatus(
                    'resources',
                    isDisabledSection,
                );
            }
        }

        if (prevState.fieldsNameAutocompleteByPnum !== this.props.fieldsNameAutocompleteByPnum) {
            const {fieldsNameAutocompleteByPnum: prevArray} = prevState;
            const {fieldsNameAutocompleteByPnum: array} = this.state;

            if (prevArray.length !== array.length) {
                if (this.props.pageMode !== 'EDIT') {
                    const isDisabledSection = array.length > 0;

                    this.props.updatedPlaceProductSectionDisabledStatus(
                        'main_technical_data',
                        isDisabledSection,
                    );
                }
            }
        }

        if (prevProps.fetchingDataFromSection !== this.props.fetchingDataFromSection) {
            const {fetchingDataFromSection} = this.props;

            if (fetchingDataFromSection && fetchingDataFromSection.currentSection === this.state.id) {
                this.onUploadDataFromSection();
            }
        }
    }

    onSetPnumAutocomplete = (inputArray) => {
        const {productInformationFields} = this.state;
        let updatedFields = productInformationFields;

        inputArray.forEach(({name, value}) => {
            updatedFields = onChangeFieldValue(updatedFields, name, value);
        });

        this.setState({productInformationFields: updatedFields});
        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    getSelectAutocompleteParams = (categoryIds) => {
        const {productInformationFields} = this.state;

        const selectAutocomplete = productInformationFields.filter(({type}) => type === 'select_autocomplete');
        const {
            url: platformUrl,
            params: platformParams,
        } = selectAutocomplete[selectAutocomplete.findIndex(({name}) => name === 'platform[]')].remote;
        const {
            url: titleUrl,
            params: titleParams,
        } = selectAutocomplete[selectAutocomplete.findIndex(({name}) => name === 'title[]')].remote;

        if (categoryIds.length > 0) {
            const currentCategory = categoryIds.join(',');
            this.props.fetchPlatformsByCategory(platformUrl, {[platformParams[1]]: currentCategory});
            this.props.fetchTitlesByCategory(titleUrl, {[titleParams[1]]: currentCategory});

        } else {
            if (this.props.platformsList) {
                this.props.resetSuggestedPlatforms();
            }

            if (this.props.titlesList) {
                this.props.resetSuggestedTitles();
            }
        }
    };

    onUpdateFieldsWithDataByPnum = (object) => {
        const fieldsNameAutocompleteByPnum = Object.keys(object);
        if (fieldsNameAutocompleteByPnum.length > 0) {
            const newFields = this.state.productInformationFields.map((field) => {
                const {type, name, props} = field;
                const additionalProps = ['readonly', 'autocomplete_by_pnum'];
                if (object[name]) {
                    if (type === 'checkbox') {
                        const value = object[name].map(({value}) => value);
                        this.setState({categoryIds: value});

                        return {
                            ...field,
                            value,
                            props: props ? [...props, ...additionalProps] : additionalProps,
                        };
                    }

                    if (type === 'select_autocomplete') {
                        const value = object[name].map(({id, name}) => ({
                            value: id,
                            label: name,
                            isFixed: true,
                        }));

                        return {
                            ...field,
                            value,
                            fixedOptions: value,
                            props: props ? [...props, ...additionalProps] : additionalProps,
                        };
                    }

                    if (type === 'text') {
                        if (name !== 'part_number') {
                            return {
                                ...field,
                                value: object[name],
                                props: props ? [...props, ...additionalProps] : additionalProps,
                            };

                        } else {
                            return {
                                ...field,
                                value: object[name],
                                props: props ? [...props, 'autocomplete_pnum'] : ['autocomplete_pnum'],
                            };
                        }
                    }
                }

                return field;
            });

            this.setState({productInformationFields: newFields});
        }

        this.setState({fieldsNameAutocompleteByPnum});

        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onChangeValue = (name, value) => {
        const newFields = onChangeFieldValue(this.state.productInformationFields, name, value);

        this.setState({productInformationFields: newFields});
        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onGroupCheckboxChange = ({target}) => {
        const {productInformationFields, categoryIds} = this.state;
        const name = target.name;
        const value = parseInt(target.value);
        let updatedFields = onChangeCheckboxGroupValue(productInformationFields, name, value);

        let newCategoryIds = [
            ...categoryIds,
            value,
        ];

        if (categoryIds.includes(value)) {
            const valueIdx = categoryIds.findIndex(categoryID => categoryID === value);
            const selectAutocompleteFields = updatedFields.filter(({type}) => type === 'select_autocomplete');
            const newSelectAutocompleteFields = [];

            selectAutocompleteFields.forEach((select) => {
                newSelectAutocompleteFields.push({
                    ...select,
                    value: [],
                });
            });

            newSelectAutocompleteFields.forEach((select) => {
                const idx = updatedFields.findIndex((field) => field.name === select.name);
                updatedFields = [
                    ...updatedFields.slice(0, idx),
                    select,
                    ...updatedFields.slice(idx + 1),
                ];
            });

            newCategoryIds = [
                ...categoryIds.slice(0, valueIdx),
                ...categoryIds.slice(valueIdx + 1),
            ];
        }

        this.setState({
            productInformationFields: updatedFields,
            categoryIds: newCategoryIds,
        });
        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onRadioButtonChange = ({target: {id, name, value}}) => {
        const updatedFields = onChangeRadioButtonValue(this.state.productInformationFields, id, name, value);
        this.setState({productInformationFields: updatedFields});
        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onChangeSelectInputGroup = (fields) => {
        const updatedFields = onUpdateSelectInputGroup(fields, this.state.productInformationFields);
        this.setState({productInformationFields: updatedFields});
        this.onResetFormFieldError();
        this.onSaveButtonChange();
    };

    onShowDisabledSection = (categoryIds) => {
        if (this.state.productInformationFields) {
            const idx = this.state.productInformationFields.findIndex(({type, list}) => type === 'checkbox' && list);
            const categoryWithResources = this.state.productInformationFields[idx].list
                .filter(({showResources}) => showResources);
            let isResourcesSection = categoryWithResources.filter(({value}) => categoryIds.includes(value)).length > 0;
            this.setState({isResourcesSection});
        }
    };

    onSaveButtonChange = () => {
        if (this.state.isSaveButtonDisabled) {
            this.setState({isSaveButtonDisabled: false});
            this.props.onUpdateSaveStatus(this.state.id, false);
        }
    };

    onResetFormFieldError = () => {
        if (this.state.formError) {
            onResetFormError(this.state.id);
            this.setState({formError: false});
        }
    };

    onResetReadOnlyFieldsByPnum = (pnumCurrentValue) => {
        const {productInformationFields, fieldsNameAutocompleteByPnum, sessionStorageItems} = this.state;
        let updatedFields = productInformationFields;

        fieldsNameAutocompleteByPnum.forEach((name) => {
            const onChangedFields = (fields, fieldName) => {
                const idx = fields.findIndex(({name}) => name === fieldName);
                let field = fields[idx];

                const {props, ...rest} = field;
                if (field.name !== 'part_number') {
                    const newProps = props.filter(property => property !== 'readonly' && property !== 'autocomplete_by_pnum');
                    field = {...rest, props: newProps};

                    if (field.type === 'text') {
                        field = {
                            ...field,
                            value: '',
                        };
                    }

                    if (field.type === 'checkbox' && field.list) {
                        field = {
                            ...field,
                            value: [],
                        };
                    }

                    if (field.type === 'select_autocomplete') {
                        field = {...field, fixedOptions: [], value: []};
                    }

                } else {
                    const newProps = props.filter(property => property !== 'autocomplete_pnum');

                    field = {
                        ...field,
                        props: newProps,
                        value: pnumCurrentValue,
                    };
                }

                return [
                    ...fields.slice(0, idx),
                    field,
                    ...fields.slice(idx + 1),
                ];
            };

            updatedFields = onChangedFields(updatedFields, name);
        });

        if (sessionStorageItems) {
            fieldsNameAutocompleteByPnum.forEach((item) => {
                delete sessionStorageItems[item];
            });

            this.props.storage.setItem(
                `${this.props.storageItem}_${this.storageKey}`,
                JSON.stringify(sessionStorageItems),
            );
            this.props.storage.removeItem(`${this.props.storageItem}_${this.storageFilledByPnumKey}`);
        }

        this.setState({
            productInformationFields: updatedFields,
            categoryIds: [],
            fieldsNameAutocompleteByPnum: [],
        });
    };

    onUploadDataFromSection = (action = 'auto-get') => {
        const form = document.getElementById(`${this.state.id}_form`);
        let {productInformationFields, fieldsNameAutocompleteByPnum} = this.state;

        productInformationFields = productInformationFields
            .filter(({isShown}) => isShown === undefined || isShown);

        const sometimesNeedSend = productInformationFields.filter(({props}) => props && props.includes('sometimes'));
        let excludedFieldForSend = [];

        //need work
        if (sometimesNeedSend.length > 0) {
            // check for all type of field, now check only for group select input field
            sometimesNeedSend.forEach(({type, group, value}) => {
                if (type === 'checkbox' && group) {
                    if (typeof value === 'string') {
                        value = parseInt(value);
                    }

                    const groupField = productInformationFields.filter((field) => field.group === group);
                    const field = groupField.find((field) => {
                        const type = value === 1 ? 'select' : 'text';
                        return field.type === type && field.group === group;
                    });

                    if (field && (!field.value || field.value === 'default')) {
                        groupField.forEach(({name}) => excludedFieldForSend.push(name));
                    }
                }
            });
        }
        //

        const valid = onCheckingRequiredFieldData(productInformationFields, form);

        if (valid) {
            const data = getDataFromFields(productInformationFields);
            const formData = new FormData(form);
            const object = formDataObjectHandler(formData, data, productInformationFields);

            excludedFieldForSend.forEach(name => {
                delete object[name];
            });

            if (action === 'save' && this.props.pageMode !== 'EDIT') {
                this.props.onSave(this.state.id, object, `${this.props.storageItem}_${this.storageKey}`);

                if (fieldsNameAutocompleteByPnum.length > 0) {
                    this.props.storage.setItem(
                        `${this.props.storageItem}_${this.storageFilledByPnumKey}`,
                        JSON.stringify(fieldsNameAutocompleteByPnum),
                    );
                }

            } else {
                this.props.onSave(this.state.id, object);
            }

            this.setState({isSaveButtonDisabled: true});

        } else {
            this.setState({formError: true});
            this.props.onFetchingDataFromSectionCallback(this.state.id, 'error');
        }
    };

    onProductInformationSave = () => {
        this.onUploadDataFromSection('save');
    };

    render() {
        const {product_information} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {id, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;

        const params = {
            storageKey: this.storageKey,
            panelData: product_information,
            isDisabled: isDisabledSection,
            isDisabledAlways: isDisabledAlwaysSection,
            onSetPnumAutocomplete: this.onSetPnumAutocomplete,
            onUpdateFieldsWithDataByPnum: this.onUpdateFieldsWithDataByPnum,
            onSelectAutoCompleteOptionsChange: this.onChangeValue,
            onInputChange: ({target: {name, value}}) => this.onChangeValue(name, value),
            onClickSelectItem: (name, item) => this.onChangeValue(name, item.value),
            onDateChange: this.onChangeValue,
            onGroupCheckboxChange: this.onGroupCheckboxChange,
            onRadioButtonChange: this.onRadioButtonChange,
            onChangeSelectInputGroup: this.onChangeSelectInputGroup,
            onResetReadOnlyFieldsByPnum: this.onResetReadOnlyFieldsByPnum,
            onClick: () => this.props.onClick(id),
            onSave: this.onProductInformationSave,
            onSkip: () => this.props.onSkip(id),
        };

        return <ProductInformation {...this.props} {...rest} {...params}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onUpdateSaveStatus: () => {
        },
        onClick: () => {
        },
        onSave: () => {
        },
    };

    static propsTypes = {
        staticData: PropTypes.object,
        sections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        list: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        suggestPlatform: PropTypes.object,
        suggestTitles: PropTypes.object,
        platformsList: PropTypes.arrayOf(PropTypes.object),
        titlesList: PropTypes.arrayOf(PropTypes.object),
        platformsOptions: PropTypes.arrayOf(PropTypes.object),
        titlesOptions: PropTypes.arrayOf(PropTypes.object),
        storage: PropTypes.object,
        storageItem: PropTypes.string,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        fetchingDataFromSection: PropTypes.object,
        fetchPlatformsByCategory: PropTypes.func,
        fetchTitlesByCategory: PropTypes.func,
        resetSuggestedPlatforms: PropTypes.func,
        resetSuggestedTitles: PropTypes.func,
        onUpdateSaveStatus: PropTypes.func,
        updatedPlaceProductSectionDisabledStatus: PropTypes.func,
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
        onFetchingDataFromSectionCallback: PropTypes.func,
    };
}

const mapServicesToProps = ({suggestService}) => ({
    fetchPlatformsByCategory: suggestPlatformsRequest(suggestService.getSuggested),
    fetchTitlesByCategory: suggestTitlesRequest(suggestService.getSuggested),
});

const mapStateToProps = ({activityHub: {placeProduct: {suggest}}}) => ({
    loadingDataByPnum: suggest.pnum.loading,
    platformsList: suggest.platforms.list,
    titlesList: suggest.titles.list,
    suggestPlatform: suggest.platforms,
    suggestTitles: suggest.titles,
});

const mapDispatchToProps = (dispatch, {
    fetchPlatformsByCategory,
    fetchTitlesByCategory,
}) => {
    const {
        resetSuggestedPlatforms,
        resetSuggestedTitles,
    } = placeProductSuggestAction;

    return bindActionCreators({
        fetchPlatformsByCategory,
        fetchTitlesByCategory,
        resetSuggestedPlatforms,
        resetSuggestedTitles,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ProductInformationContainer);