import React, {forwardRef} from 'react';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import PropTypes from 'prop-types';

import {withDataService} from '../common/hoc';

import Button from '../common/button';
import PrintBlob from './components/print-blob/print-blob';

import styles from './page-to-print.module.scss';

const ComponentToPrint = forwardRef(({children}, ref) => (
    <div ref={ref}>{children}</div>
));

ComponentToPrint.propTypes = {
    children: PropTypes.node,
};

class PageToPrint extends React.PureComponent {
    render() {
        const {
            history, originUrl, children, contentToPrint, isPrintBtnDisabled,
            isCreateContentBeforePrint, printBlob, documentTitle, staticData,
        } = this.props;

        if (printBlob) {
            const params = {staticData, originUrl, src: children};
            return <PrintBlob {...params}/>;
        }

        const {title: defaultTitle, button: {cancel, print}} = staticData;
        const {title = defaultTitle} = this.props;

        return (
            <>
                <div className={styles['top-container']}>
                    <h1>{title}</h1>
                    <div className={styles['btn-block']}>
                        <Button styleType="SECONDARY"
                                onClick={() => history.goBack()}>
                            {cancel}
                        </Button>
                        <ReactToPrint content={() => this.componentRef}
                                      documentTitle={documentTitle}>
                            <PrintContextConsumer>
                                {({handlePrint}) => (
                                    <Button classNames={styles['print-btn']}
                                            isDisabled={isPrintBtnDisabled}
                                            onClick={handlePrint}>
                                        {print}
                                    </Button>
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                    </div>
                </div>

                {isCreateContentBeforePrint && children}

                <div style={{display: isCreateContentBeforePrint && 'none'}}>
                    <ComponentToPrint ref={el => (this.componentRef = el)}>
                        {isCreateContentBeforePrint ? contentToPrint : children}
                    </ComponentToPrint>
                </div>
            </>
        );
    }

    static defaultProps = {
        isCreateContentBeforePrint: false,
        isPrintBtnDisabled: false,
        printBlob: false,
        contentToPrint: <div>My content to print!</div>,
        children: <div>My content to print!</div>,
    };

    static propTypes = {
        title: PropTypes.string,
        history: PropTypes.shape({
            goBack: PropTypes.func,
        }),
        originUrl: PropTypes.string,
        children: PropTypes.node.isRequired,
        contentToPrint: PropTypes.node,
        isCreateContentBeforePrint: PropTypes.bool,
        isPrintBtnDisabled: PropTypes.bool,
        printBlob: PropTypes.bool,
        staticData: PropTypes.object,
    };
}

export default compose(
    withRouter,
    withDataService('page_to_print'),
)(PageToPrint);
