export const sectionSettingsHandler = (currentID, sections) => {
    const currentSectionIdx = sections.findIndex(({id}) => id === currentID);
    const isDisabled = sections[currentSectionIdx].isDisabled;
    const isDisabledAlways = sections[currentSectionIdx].isDisabledAlways;

    return {isDisabled, isDisabledAlways};
};

export const prevStateSectionSettingsHandler = (currentID, sections) => {
    return sectionSettingsHandler(currentID, sections);
};