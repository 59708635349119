import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import parse, {attributesToProps, domToReact} from 'html-react-parser';
import PropTypes from 'prop-types';

import {withServices} from '../../../../service-context';
import {newsArticleRequest} from '../../../../../reducers/public-data/news/article';
import {withDataService} from '../../../../common/hoc';

import Image from '../../../../common/image';
import Button from '../../../../common/button';
import Preloader from '../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './article.module.scss';

import defaultImage from '../../../../../services/news-service/assets/default.png';

class Article extends Component {
    state = {
        article: null,
    };

    componentDidMount() {
        if (this.props.session !== null) {
            this.fetchArticleDetails();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.fetchArticleDetails();
        }

        /** case for related news or another news on the article page
         *
         if (prevProps.match.params !== this.props.match.params) {
            this.fetchArticleDetails();
        }
         */
    }

    fetchArticleDetails = () => {
        const {match: {params}} = this.props;
        this.props.fetchArticle(params.id);
    };

    render() {
        const {article, loading} = this.props;
        const {page, image_alt, button: {all_news}} = this.props.staticData;
        const cx = classnames.bind(styles);

        if (!loading && article) {
            const {title, preview_image, content, date} = article;
            const options = {
                replace: ({name, attribs, children}) => {
                    if (attribs && name === 'img') {
                        const {src, ...otherAttribs} = attribs;
                        const props = attributesToProps({...otherAttribs});
                        return (
                            <div className={styles['image-container']}>
                                <img {...props} src={`${process.env.REACT_APP_API_URL}${src}`}
                                     alt={image_alt}
                                     className={styles.image}
                                />
                            </div>
                        );
                    }

                    if (attribs && name === 'a') {
                        const props = attributesToProps(attribs);
                        return <a {...props} className={styles.link}>{domToReact(children, options)}</a>;
                    }

                    /* slug = exportery-aiming-become-one-stop-shop-air... */
                    if (article.id === 2) {
                        if (name === 'table') {
                            const props = attribs ? attributesToProps(attribs) : {};
                            return (
                                <div className={styles['table-wrapper']}>
                                    <table {...props} className={styles.table}>{domToReact(children, options)}</table>
                                </div>
                            );
                        }

                        if (name === 'tr') {
                            const props = attribs ? attributesToProps(attribs) : {};
                            return <tr {...props} className={styles.tr}>{domToReact(children, options)}</tr>;
                        }

                        if (name === 'td') {
                            let updatedAttribs = {};
                            let props = {};
                            if (attribs.style) {
                                const {style, ...rest} = attribs;
                                let updatedStyle = [];

                                style.split(';').forEach((item) => {
                                    if (!item.includes('width') && item !== '') {
                                        updatedStyle.push(item);
                                    }
                                });

                                updatedAttribs = {...rest};
                                if (updatedStyle.length > 0) {
                                    updatedAttribs = {
                                        ...rest,
                                        style: updatedStyle.join(';'),
                                    };
                                }

                                props = attributesToProps(updatedAttribs);
                            }
                            return <td {...props} className={styles.td}>{domToReact(children, options)}</td>;
                        }
                    }
                },
            };

            return (
                <>
                    <div className={cx('page-container', 'container')}>
                        <h1 className={styles.title}>{title}</h1>
                        <div className={styles.date}>{date}</div>
                        <div className={styles.content}>
                            <Image source={preview_image} alt={image_alt} skeletonClassNames={styles['main-image']}/>
                            <div className={styles.body}>{parse(content, options)}</div>
                        </div>
                    </div>

                    <Link to={page} className={styles.btn}>
                        <Button styleType={'SECONDARY'}>{all_news}</Button>
                    </Link>
                </>
            );
        }

        return <Preloader/>;
    }

    static defaultProps = {
        preview_image: defaultImage,
    };

    static propTypes = {
        staticData: PropTypes.object,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        article: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
            title: PropTypes.string,
            preview_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            content: PropTypes.string,
            date: PropTypes.string,
        })]),
        loading: PropTypes.bool,
        language: PropTypes.string,
        fetchArticle: PropTypes.func,
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchArticle: newsArticleRequest(publicService.getArticleDetails),
});

const mapStateToProps = ({session: {session}, profile, publicData: {homePage, news: {article}}}) => ({
    session,
    article: article.item,
    loading: article.loading,
    language: session ? profile.language : homePage.language,
});

const mapDispatchToProps = (dispatch, {fetchArticle}) => {
    return bindActionCreators({
        fetchArticle,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService('news'),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(Article);