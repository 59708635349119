import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../common/hoc';
import {
    formFieldsRequest,
    updatingFormFieldsRequest,
} from '../../../../../../../reducers/activity-hub/place-product/form-fields';
import {uploadFormDataRequest} from '../../../../../../../reducers/activity-hub/place-product/upload-form-data';
import {withServices} from '../../../../../../service-context';
import sweetAlert from '../../../../../../common/sweet-alert';

import FormPage from '../../components/form-page';

class NewProductPage extends Component {
    storageItem = 'place_product';

    componentDidMount() {
        this.props.onInitSections();
        this.props.fetchFormFields();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.onInitSections();
            this.props.fetchUpdatingFormFields();
        }
    }

    onClearForm = () => {
        const {clear_form_question} = this.props.staticData.alert_modal;
        sweetAlert('question', clear_form_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.onClearForm(this.storageItem, this.props.fetchUpdatingFormFields);
                }
            });
    };

    onPlaceProduct = () => {
        this.props.onUploadData(this.props.uploadProductData, this.storageItem);
    };

    render() {
        const {onSectionClick, ...rest} = this.props;

        return <FormPage {...rest}
                         storageItem={this.storageItem}
                         onClick={onSectionClick}
                         onUpload={this.onPlaceProduct}
                         onClearForm={this.onClearForm}
                         onCancel={() => this.props.onCancel(this.storageItem)}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        onInitSections: PropTypes.func,
        fetchFormFields: PropTypes.func,
        fetchUpdatingFormFields: PropTypes.func,
        uploadProductData: PropTypes.func,
        onClearSessionStorage: PropTypes.func,
        onUploadData: PropTypes.func,
        onCancel: PropTypes.func,
        onClearForm: PropTypes.func,
    };
}

const mapServicesToProps = ({placeProductService}) => ({
    fetchFormFields: formFieldsRequest(placeProductService.getFormFields),
    fetchUpdatingFormFields: updatingFormFieldsRequest(placeProductService.getFormFields),
    uploadProductData: uploadFormDataRequest(placeProductService.uploadProductData),
});

const mapStateToProps = ({activityHub: {placeProduct}, profile: {language}}) => {
    const {uploadFormData} = placeProduct;
    return {
        language,
        uploadFormData,
    };
};

const mapDispatchToProps = (dispatch, {
    fetchFormFields,
    fetchUpdatingFormFields,
    uploadProductData,
}) => {
    return bindActionCreators({
        fetchFormFields,
        fetchUpdatingFormFields,
        uploadProductData,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(NewProductPage);