import React from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../../../common/hoc';

import styles from './available-currency-table.module.scss';

const AvailableCurrencyTable = ({staticData, data}) => {
    const {
        available_currency_table_labels: [label1, label2],
    } = staticData.marketplace.product_page;

    const tableRow = data.map(({name, icon, rate, accepted}, idx) => {
            return (
                <li key={idx} className={styles.item}>
                    <div className={styles.currency}>
                        <div className={styles.flag} style={{backgroundImage: `url(${icon})`}}/>
                        {name}
                    </div>
                    <div className={styles['exchange-rate']}>
                        <span className={styles.rate}>{rate}</span>
                        <span className={styles.status}>{accepted}</span>
                    </div>
                </li>
            );
        }
    );

    return (
        <div>
            <div className={styles['label-row']}>
                <div className={styles.label}>{label1}</div>
                <div className={styles.label}>{label2}</div>
            </div>
            {tableRow}
        </div>
    );
};

AvailableCurrencyTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.string,
        rate: PropTypes.string,
        accepted: PropTypes.string,
    })),
    staticData: PropTypes.object,
};

export default withDataService()(AvailableCurrencyTable);