import {createRequestHandler} from '../../../../utils';

export const SUGGEST_DATA_BY_PART_NUMBER_REQUEST = 'SUGGEST_DATA_BY_PART_NUMBER_REQUEST';
export const SUGGEST_DATA_BY_PART_NUMBER_SUCCESS = 'SUGGEST_DATA_BY_PART_NUMBER_SUCCESS';
export const SUGGEST_DATA_BY_PART_NUMBER_FAILURE = 'SUGGEST_DATA_BY_PART_NUMBER_FAILURE';

export const suggestDataByPnumRequest = createRequestHandler('SUGGEST_DATA_BY_PART_NUMBER');

const initialState = {
    list: null,
    loading: false,
    error: null,
};

const suggestDataByPnum = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SUGGEST_DATA_BY_PART_NUMBER_REQUEST:
            return {
                list: null,
                loading: true,
                error: null,
            };

        case SUGGEST_DATA_BY_PART_NUMBER_SUCCESS:
            return {
                list: payload.form,
                loading: false,
                error: null,
            };

        case SUGGEST_DATA_BY_PART_NUMBER_FAILURE:
            return {
                list: null,
                loading: false,
                error: payload,
            };

        case 'RESET_SUGGESTED_DATA_BY_PART_NUMBER':
            return initialState;

        default:
            return state;
    }
};

export default suggestDataByPnum;