import React from 'react';
import PropTypes from 'prop-types';

import Preloader from '../../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './sub-quotation-item.module.scss';

const SubQuotationItem = (props) => {
    const {list, removingItem, withDeleteItem, onRemoveSubQuotationItem: onRemove} = props;
    const cx = classnames.bind(styles);

    const tableHeadRow = list
        .filter((item, idx) => idx === 0)
        .map(({row}) => {
            return row.map(({field}, idx) => {
                const headColumnClasses = cx('head-column', {
                    'second-last': idx === row.length - 2,
                    'last': idx === row.length - 1,
                });
                return <div className={headColumnClasses} key={idx}>{field}</div>;
            });
        });

    const tableRowsHandler = ({row}) => row.map(({value}, idx) => {
        const columnClasses = cx('column', {
            'second-last': idx === row.length - 2,
            'last': idx === row.length - 1,
        });

        return <div className={columnClasses} key={idx}>{value}</div>;
    });

    const tableRows = list.map((item, idx) => {
        let hidingContainer = <div className={styles['hiding-container']}/>;

        if (removingItem) {
            const {loading, offer_id} = removingItem;

            if (loading && item.id === offer_id) {
                hidingContainer = (
                    <div className={styles['hiding-container']}>
                        <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                    </div>
                );
            }
        }

        const itemClasses = cx('item', {'hide': removingItem && removingItem.loading});

        return (
            <div className={itemClasses} key={idx}>
                {removingItem && removingItem.loading && hidingContainer}
                <div className={styles.column}>{idx + 1}</div>
                {tableRowsHandler(item)}

                {withDeleteItem && <div className={styles.delete} onClick={() => onRemove(item.id)}/>}
            </div>
        );
    });

    return (
        <div className={styles.container}>
            <div className={`${styles.item} ${styles.head}`}>
                <div className={styles['head-column']}>№</div>
                {tableHeadRow}
            </div>
            {tableRows}
        </div>
    );
};

SubQuotationItem.defaultProps = {
    withDeleteItem: true,
    onRemoveSubQuotationItem: () => {
    },
};

SubQuotationItem.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        row: PropTypes.arrayOf(PropTypes.shape({
            field: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })),
    })),
    removingItem: PropTypes.shape({
        loading: PropTypes.bool,
        offer_id: PropTypes.number,
    }),
    withDeleteItem: PropTypes.bool,
    onRemoveSubQuotationItem: PropTypes.func,
};

export default SubQuotationItem;