import React from 'react';
import PropTypes from 'prop-types';

import PartNumber from '../../part-number';
import Dropdown from '../../dropdown';
import FilterOptions from '../filter-desktop-options';
import SkeletonPreloader from '../../skeleton-preloader';

import classnames from 'classnames/bind';
import styles from './filters-desktop.module.scss';

const FiltersDesktop = (props) => {
    const {
        options, dropdowns, toggleDropdown, toggleOptions,
        isUpdating, isSkeletonPreloader, ...rest
    } = props;
    const cx = classnames.bind(styles);

    return (
        <div className={cx('container', {'disabled': isUpdating})}>
            <PartNumber
                {...rest}
                id="filter-pnum"
                labelClasses={styles['input-block']}
                inputClasses={styles.input}
                isSkeletonPreloader={isSkeletonPreloader}
            />

            {dropdowns.map((dropdown, idx) => {
                const {id: dropdownID, label} = dropdown;
                const currentOptionsIdx = options.findIndex(({id}) => id === dropdownID);
                const currentOptions = options[currentOptionsIdx].options;

                const filterClasses = cx('filter', {'disabled': currentOptions.length === 0});

                return (
                    <Dropdown
                        {...dropdown}
                        key={idx}
                        type="FILTER"
                        id={dropdownID}
                        label={(
                            <SkeletonPreloader type={'TEXT'}
                                               loading={isSkeletonPreloader}
                                               classNames={cx({'skeleton-preloader': isSkeletonPreloader})}>
                                <span>{label}</span>
                            </SkeletonPreloader>
                        )}
                        labelClassNames={filterClasses}
                        isDisable={isUpdating}
                        onToggle={(id) => !isUpdating && toggleDropdown(id)}>
                        <FilterOptions
                            filterID={dropdownID}
                            options={currentOptions}
                            isUpdating={isUpdating}
                            toggleOptions={toggleOptions}
                        />
                    </Dropdown>
                );
            })}
        </div>
    );
};

FiltersDesktop.defaultProps = {
    isUpdating: false,
    isSkeletonPreloader: false,
    toggleOptions: () => {
    },
};

FiltersDesktop.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        options: PropTypes.array,
    })),
    dropdowns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
    })),
    isSkeletonPreloader: PropTypes.bool,
    toggleOptions: PropTypes.func,
    isUpdating: PropTypes.bool,
};

export default FiltersDesktop;