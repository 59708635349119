import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './category-plate.module.scss';

const CategoryPlate = ({id, icon, name, href, isShared}) => {
    const {path: basePath} = useRouteMatch();

    const content = (
        <>
            <img src={icon} alt={name}/>
            <div className={styles.title}>{name}</div>
        </>
    );

    return isShared === 1 ? (
        <Link id={id} to={`${basePath}${href}`} className={styles.container}>
            {content}
        </Link>
    ) : (
        <div id={id} className={`${styles.container} ${styles['no-link']}`}>
            {content}
            <div className={styles['hiding-container']}/>
        </div>
    );
};

CategoryPlate.defaultProps = {
    isShared: 1,
}

CategoryPlate.propTypes = {
    id: PropTypes.any.isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    isShared: PropTypes.number,
};

export default CategoryPlate;