import React from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';
import FormFieldCreator from '../../../../../../common/handlers/form-field-creator';

import FormFieldConstructor from '../../../../../../../../common/form-field-constructor';
import PlaceTable from './place-table';

import styles from './packing-place.module.scss';

const PackingPlace = (props) => {
    const {
        staticData: {place_label}, classNames, title, form, strategy,
        withPlaceTable, onSwitcherChange, onInputChange, onClickSelectItem,
        onRadioButtonChange, onCheckboxChange, onUpdateSerialNumbers, ...rest
    } = props;

    const formFieldCreator = new FormFieldCreator();
    const formFieldHandler = (formFields) => {
        const fields = formFieldCreator.getFormFields(formFields);

        return fields.map((field, idx) =>
            <FormFieldConstructor key={idx}
                                  field={field}
                                  onSwitcherChange={onSwitcherChange}
                                  onInputChange={onInputChange}
                                  onClickSelectItem={onClickSelectItem}
                                  onRadioButtonChange={onRadioButtonChange}
                                  onCheckboxChange={onCheckboxChange}/>
        );
    };

    let tableFields = [];
    const fieldsBlockMarkup = () => {
        const switchers = form.filter((field) => field.type === 'switcher');
        tableFields = form.filter((field) => field.name.includes('[offer]'));
        const otherFields = form.filter((field) => field.type !== 'switcher' && !field.name.includes('[offer]'));

        const switcherFields = form ? formFieldHandler(switchers) : [];
        const fields = form ? formFieldHandler(otherFields) : [];

        switch (strategy) {
            case 'supply_set_load':
                return (
                    <>
                        <div className={styles['switchers-group']}>{switcherFields}</div>
                        <div className={styles.description}>{fields[0]}</div>
                        <div className={styles['grid-group-7']}>{fields.slice(1)}</div>
                    </>
                );

            case 'break_bulk_load':
                const mainFields = fields.filter(({props: {field}}) => !field.dependsFrom && field.type !== 'radio');
                const radioButton = fields.filter(({props: {field}}) => field.type === 'radio');
                const dependentFields = fields.filter(({props: {field}}) => field.dependsFrom);

                return (
                    <>
                        <div className={styles['switchers-group']}>{switcherFields}</div>

                        <div className={styles['grid-group-4']}>
                            {mainFields}
                        </div>

                        <div className={styles.radio}>{radioButton}</div>
                        <div className={styles['grid-group-4-even']}>{dependentFields}</div>
                    </>
                );

            default:
                return (
                    <>
                        <div className={styles['switchers-group']}>{switcherFields}</div>
                        <div className={styles['grid-group-7']}>{fields}</div>
                    </>
                );
        }
    };

    return (
        <div className={`${styles.container} ${classNames}`}>
            <div className={styles.title}>{title ? title : place_label}</div>

            {fieldsBlockMarkup()}

            {withPlaceTable && (
                <PlaceTable
                    {...rest}
                    strategy={strategy}
                    form={tableFields}
                    formFieldHandler={formFieldHandler}
                    onUpdateSerialNumbers={onUpdateSerialNumbers}/>
            )}
        </div>
    );
};

PackingPlace.propTypes = {
    staticData: PropTypes.object,
    title: PropTypes.string,
    form: PropTypes.arrayOf(PropTypes.object),
    strategy: PropTypes.string,
    classNames: PropTypes.string,
    withPlaceTable: PropTypes.bool,
    onSwitcherChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onClickSelectItem: PropTypes.func,
    onRadioButtonChange: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onUpdateSerialNumbers: PropTypes.func,
};

export default withDataService(
    'activity_hub.e_contracts.pages.packing_list_wizard.pages.shipment_lot'
)(PackingPlace);