import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withServices} from '../../../../service-context';
import {detailsRequest} from '../../../../../reducers/marketplace/product-page/product-details';
import {productPageAction} from '../../../../../actions/marketplace';

import ProductPageContainer from '../../../../marketplace/components/pages/product-page/product-page-container';

class ProductPageModule extends Component {
    render() {
        const {session} = this.props;
        if (!session && session !== null) {
            return <ProductPageContainer {...this.props}/>;
        }

        return null;
    }

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchProductDetails: detailsRequest(publicService.getPublicProductDetails),
});

const mapStateToProps = ({session: {session}, publicData: {homePage: {language}}, marketplace}) => {
    const {productDetails: {details, loading, error}} = marketplace;

    return {
        session,
        details,
        loading,
        error,
        language,
    };
};

const mapDispatchToProps = (dispatch, {fetchProductDetails}) => {
    const {setProductCurrentCurrency} = productPageAction;

    return bindActionCreators({
        fetchProductDetails,
        setProductCurrentCurrency,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ProductPageModule);