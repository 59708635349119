import React from 'react';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../common/hoc';
import {priceHandler} from '../../../../../../../../../utils';

import StatusIndicator from '../../../../../../common/status-indicator';

import styles from './e-contract.module.scss';

const EContract = ({staticData, list}) => {
    const {status_row_label} = staticData.activity_hub.e_contracts;

    return (
        <div className={styles.container}>
            {list.map(({label, value, color, currency}, idx) => {
                if (currency) {
                    return (
                        <div className={styles['item-block']} key={idx}>
                            <div className={styles.label}>{label}:</div>
                            <div className={styles.value}>{priceHandler(value)} {currency}</div>
                        </div>
                    );
                }

                if (idx === list.length - 1) {
                    return (
                        <div key={idx} className={styles['item-block']}>
                            <div className={styles.label}>{status_row_label}:</div>
                            <StatusIndicator status={{label, color}}
                                             classNames={`${styles.value} ${styles.status}`}/>
                        </div>
                    );
                }

                return (
                    <div className={styles['item-block']} key={idx}>
                        <div className={styles.label}>{label}:</div>
                        <div className={styles.value}>{value}</div>
                    </div>
                );
            })}
        </div>
    );
};

EContract.propTypes = {
    staticData: PropTypes.object,
    list: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
        currency: PropTypes.string,
    })),
};

export default withAccordion()(EContract);