import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useMediaQuery} from 'react-responsive';

import {tabNameCreator} from './handlers';

import Accordion from '../../../../../../common/accordion';
import {
    MainTechnicalData,
    Resources,
    Interchangeability,
    SupplySet,
    Certification,
    DeliveryAndPayment,

} from './tabs-options';

import classnames from 'classnames/bind';
import styles from './product-tabs.module.scss';

const ProductTabs = ({tabs, ...otherProps}) => {
    const [productTabs, setProductTabs] = useState([]);
    const [tabsOptions] = useState({
        mainTechnicalDataTab: MainTechnicalData,
        resourcesTab: Resources,
        interchangeabilityTab: Interchangeability,
        supplySetTab: SupplySet,
        certificationTab: Certification,
        deliveryPaymentTab: DeliveryAndPayment,
    });

    const cx = classnames.bind(styles);
    const lgDevice = useMediaQuery({minWidth: 992});

    useEffect(() => {
        const newProductTabs = tabs !== undefined
            ? tabs.map(({id, label, content}) => {
                if (content.length !== 0) {
                    return {
                        id,
                        label,
                        content,
                        isActive: false,
                    };
                }

                return null;
            })
                .filter(tab => tab !== null)
                .map(({isActive, ...rest}, idx) => ({
                    ...rest,
                    isActive: lgDevice && idx === 0,
                }))
            : [];

        setProductTabs(newProductTabs);
    }, [tabs, lgDevice]);

    const onToggleMobileTabs = (currentID) => {
        const currentTabIdx = productTabs.findIndex(({id}) => id === currentID);
        const currentTab = productTabs[currentTabIdx];

        const newProductTabs = [
            ...productTabs.slice(0, currentTabIdx),
            {
                ...currentTab,
                isActive: !currentTab.isActive,
            },
            ...productTabs.slice(currentTabIdx + 1),
        ];

        setProductTabs(newProductTabs);
    };

    const onToggleDesktopTabs = (currentID) => {
        const currentTabIdx = productTabs.findIndex(({id}) => id === currentID);
        const currentTab = productTabs[currentTabIdx];

        if (!currentTab.isActive) {
            const newProductTabs = productTabs.map((tab) => {
                const {id, isActive, ...rest} = tab;

                if (id === currentID) {
                    return {
                        id,
                        ...rest,
                        isActive: true,
                    };
                }

                return {
                    id,
                    ...rest,
                    isActive: false,
                };
            });

            setProductTabs(newProductTabs);
        }
    };

    const setTabContent = (id, label, content, idx) => {
        const currentTab = tabNameCreator(id);
        const TabContentComponent = tabsOptions[currentTab];

        return <TabContentComponent key={idx} {...otherProps} content={content} title={label}/>;
    };

    const mobileTabs = tabs !== undefined
        ? productTabs.map(({id, label, content, isActive}, idx) => {
            return (
                <div key={idx} className={styles['tab-container']}>
                    <Accordion
                        label={label}
                        labelClassNames={styles['tab-label']}
                        onToggle={() => onToggleMobileTabs(id)}
                        isOpen={isActive}
                    >
                        {setTabContent(id, label, content)}
                    </Accordion>
                </div>
            );
        })
        : null;

    const desktopDevice = () => {
        const labels = productTabs.map(({id, label, isActive}, idx) => {
            const labelClasses = cx('label', {'active': isActive});

            return <div key={idx} className={labelClasses} onClick={() => onToggleDesktopTabs(id)}>{label}</div>;
        });

        const contents = productTabs.map(({id, label, content, isActive}, idx) => (
            isActive ? setTabContent(id, label, content, idx) : null
        ));

        return (
            <>
                <div className={styles['tab-labels']}>
                    {labels}
                </div>

                <div className={styles['tab-content']}>
                    {contents}
                </div>
            </>
        );
    };

    return (
        <div className={styles.container}>
            {lgDevice ? desktopDevice() : mobileTabs}
        </div>
    );
};

ProductTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        content: PropTypes.any,
    })),
};

export default ProductTabs;