import React, {useEffect, useRef, useState} from 'react';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';

import SearchResult from './search-result';

import classnames from 'classnames/bind';
import styles from './search.module.scss';

const Search = (props) => {
    const inputRef = useRef(null);
    const [parentNode, setParentNode] = useState('parent');
    const {visible, children, searchInputClasses, onHidden, ...rest} = props;
    const cx = classnames.bind(styles);

    useEffect(() => {
        setParentNode(inputRef.current);
    }, [inputRef]);

    return (
        <Tippy
            content={<SearchResult {...rest} onHidden={onHidden}/>}
            appendTo={parentNode}
            arrow={false}
            interactive={true}
            maxWidth="none"
            role="search-beard"
            offset={[0, 0]}
            placement="bottom-start"
            visible={visible}
            onClickOutside={onHidden}
        >
            <div ref={inputRef} className={cx('container', searchInputClasses)}>
                {children}
            </div>
        </Tippy>
    );
};

Search.defaultProps = {
    onHidden: () => {},
};

Search.propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.node,
    searchInputClasses: PropTypes.string,
    onHidden: PropTypes.func,
};

export default Search;