import React from 'react';
import PropTypes from 'prop-types';

import {IDs} from '../../form-sections-id';

import {
    CertificationContainer,
    DeliveryPaymentContainer,
    MainTechnicalDataContainer,
    PhotoContainer, ProductGuidesContainer,
    ProductInformationContainer,
    ResourcesContainer,
    SupplySetContainer,
} from '../tabs-options';
import Sidebar from '../sidebar';
import Button from '../../../../../../common/button';

import styles from './form-page.module.scss';

const FormPage = (props) => {
    const {
        uploadFormData: {loading}, isFetchingData, isUploadButtonDisabled, activeSectionId,
        onDeleteAlreadyUploadedFile, onSaveFiles, onUpload, onCancel, onClearForm, onScrollToSection, ...rest
    } = props;

    const {
        from_form: {
            title, edit_title, required_message,
            button: {cancel, place_product, edit_product, clear_form},
        },
    } = props.staticData.activity_hub.place_product;

    /* destructuring separately because we need to use this value later */
    const {removeAttachment: {loading: removeAttachmentLoading}} = props;

    const mainTitle = props.pageMode === 'EDIT' ? edit_title : title;
    const uploadButtonLabel = props.pageMode === 'EDIT' ? edit_product : place_product;

    let sectionIDs = [];
    IDs.forEach(id => sectionIDs.push(id.split(':')[0]));

    /* set in right order */
    const [
        product_information_id,
        delivery_payment_id,
        photo_id,
        main_technical_data_id,
        resources_id,
        supply_set_id,
        certification_id,
        product_guides_id,
    ] = sectionIDs;

    const sidebarParams = {
        buttonParams: {
            loading: loading || isFetchingData,
            isDisabled: isUploadButtonDisabled,
            uploadLabel: uploadButtonLabel,
            cancelLabel: cancel,
            onUpload,
            onCancel,
        },
        activeSectionId,
        onScrollToSection,
    };

    const dropzoneSectionParams = {
        onDeleteAlreadyUploadedFile,
        onSave: onSaveFiles,
    };

    return (
        <>
            <div className={styles.title}>{mainTitle}</div>

            <div className={styles['required-message']}>
                {required_message[0]} <span className={styles['required-symbol']}>
                {required_message[1]}
                </span> {required_message[2]}
            </div>

            <div style={{pointerEvents: removeAttachmentLoading ? 'none' : 'auto'}} className={styles.sections}>
                {removeAttachmentLoading && <div className={styles['hiding-container']}/>}

                <div>
                    <ProductInformationContainer {...rest} id={product_information_id}/>
                    <DeliveryPaymentContainer {...rest} id={delivery_payment_id}/>
                    <PhotoContainer {...rest} {...dropzoneSectionParams} id={photo_id}/>
                    <MainTechnicalDataContainer {...rest} id={main_technical_data_id}/>
                    <ResourcesContainer {...rest} id={resources_id}/>
                    <SupplySetContainer {...rest} id={supply_set_id}/>
                    <CertificationContainer {...rest} {...dropzoneSectionParams} id={certification_id}/>
                    <ProductGuidesContainer {...rest} {...dropzoneSectionParams} id={product_guides_id}/>

                    {props.pageMode === 'NEW' && (
                        <Button styleType="SECONDARY"
                                classNames={styles['clear-form']}
                                onClick={onClearForm}>
                            {clear_form}
                        </Button>
                    )}
                </div>

                <Sidebar {...sidebarParams}/>
            </div>
        </>
    );
};

FormPage.defaultProps = {
    onDeleteAlreadyUploadedFile: () => {},
    onSaveFiles: () => {},
    onClearForm: () => {},
};

FormPage.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    isFetchingData: PropTypes.bool,
    pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
    removeAttachmentLoading: PropTypes.bool,
    onDeleteAlreadyUploadedFile: PropTypes.func,
    onSaveFiles: PropTypes.func,
    onClearForm: PropTypes.func,
};

export default FormPage;