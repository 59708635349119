import {staticURLs} from '../../../api-urls';

export const advantages_fr = [
    {
        title: 'Accès direct à l\'approvisionnement et à un large éventail de produits industriels',
        text: [
            `Exportery est la première plateforme de commerce électronique avec un pool de ressources 
            techniques, de composants et de pièces de rechange pour les avions, les hélicoptères, 
            les transports terrestres et divers équipements de production orientale et européenne, 
            activement utilisés par les secteurs privé et public africains et latino-américains.`,
            `En tant que service universel, Exportery est équipé d’un système de gestion électronique des 
            documents exclusif qui élève l'efficacité et la sécurité des achats transfrontaliers à 
            un nouveau niveau. Nous fournissons des solutions modernes assurant une livraison rapide 
            des pièces de rechange et un processus d’approvisionnement facile.`,
        ],
        btn: 'Comment fonctionne exportery',
        href: staticURLs.about_marketplace,
        img: 'marketplace.png',
        alt_img: 'Marketplace',
    },
    {
        title: 'Contrôle technique indépendant',
        text: [
            `L'assurance de la qualité et le contrôle technique font partie intégrante de tout processus 
            d’approvisionnement. Un processus d'assurance de la qualité et de contrôle correctement 
            exécuté garantit que les utilisateurs finaux obtiennent les pièces de rechange et les 
            composants à temps et avec des conditions techniques et de qualité validées conformes 
            aux normes internationales ISO, EASA et OACI.`,
            `Auparavant, il y avait de nombreux cas de fournitures de produits réparés ou de 
            qualité inférieure présentés comme neufs. Chez Exportery, nous effectuons un examen 
            indépendant de chaque envoi international effectué via notre plateforme électronique 
            afin d'assurer la sécurité des utilisateurs finaux des produits. L'inspection technique 
            indépendante est effectuée dans les entrepôts d'entiercement en Éthiopie et en Tanzanie 
            par du personnel technique certifié.`,
        ],
        btn: 'En savoir plus sur l\'expertise technique indépendante',
        href: staticURLs.about_independent_technical_surveying,
        img: 'technical-surveying.png',
        alt_img: 'Technical surveying',
    },
    {
        title: 'Services de base',
        radio: [
            {
                title: 'Point d\'expédition FCA pour exportateur',
                text: [
                    `Tous les prix des pièces de rechange et composants répertoriés sur la plateforme 
                    de commerce électronique sont basés sur les Incoterms 2020 FCA Aéroport du pays d'origine, 
                    ce qui rend le processus d'évaluation et de négociation transparent et pratique pour les 
                    acteurs du marché. L'exportateur n’a qu’à passer les formalités de dédouanement dans son 
                    pays d’exportation.`,
                    `Exportery assumera toute la charge logistique et tous les risques financiers des 
                    marchandises expédiées du pays d'origine de l'exportateur par l'intermédiaire d'un 
                    réseau de confiance d'opérateurs logistiques, de compagnies aériennes et d'agents.`,
                ],
                img: 'FCA.png',
                alt_img: 'Core services',
            },
            {
                title: 'Point de destination CIP pour l\'importateur',
                text: `Exportery calcule automatiquement les prix de toutes les pièces de rechange et 
                tous les composants lors de la création d'une demande électronique pour les importateurs 
                sur la base de Incoterms 2020 CIP Aéroport de destination. Cela permettra à l'importateur 
                d'obtenir un devis instantané en quelques minutes qui comprend la livraison vers l'une 
                des destinations éloignées du monde, y compris l'assurance.`,
                img: 'CIP.png',
                alt_img: 'Core services',
            },
            {
                title: 'Automatisation de la documentation d\'exportation-importation',
                text: [
                    `Notre plateforme de commerce électronique vise à transcender les procédures 
                    bureaucratiques et chronophages dans les processus d’approvisionnement et à 
                    numériser l'établissement et l'échange de certificats d'utilisateur final, de 
                    preuves de livraison et de garanties bancaires par l'intermédiaire de la technologie 
                    blockchain mise en œuvre et d'entités partenaires.`,
                    `Nous offrons déjà à nos clients une commodité, une responsabilité sans précédent et un 
                    support inégalé grâce au système de gestion des contrats développé par le propriétaire, 
                    qui permet l'établissement et le flux automatiques et intuitifs des documents 
                    d'importation-exportation essentiels tels que les factures électroniques, les 
                    certificats électroniques d'acceptation et de transfert, les listes de colisage 
                    électroniques, les contrats électroniques et, enfin, les bons de livraison électroniques.`,
                ],
                img: 'automation_of_documentation.png',
                alt_img: 'Core services',
            },
            {
                title: 'Signatures électroniques sur tous les documents',
                text: `Chez Exportery, tous les documents sont sécurisés et signés électroniquement via 
                le système DocuSign réputé accepté dans la plupart des pays. Outre le fait de faciliter 
                le dédouanement des marchandises, il assure également l'authenticité et la propriété de 
                toute la documentation, ce qui crée la confiance de toutes les parties dans la plateforme 
                de commerce électronique.`,
                img: 'e-signatures.png',
                alt_img: 'Core services',
            },
            {
                title: 'Entreposage d\'entiercement et comptes séquestres',
                text: [
                    `Nous sommes des pionniers dans la fourniture de méthodes efficaces d'entreposage
                     d'entiercement dans les marchés émergents pour garantir l'exécution de la 
                     transaction entre toutes les parties. Les marchandises seront libérées de 
                     l'entrepôt d’entiercement lorsque les conditions du contrat électronique sont remplies.`,
                    `Nous apportons des changements de qualité dans le marché international des 
                    pièces de rechange grâce à nos nouveaux services d'entiercement et à la responsabilité 
                    et au soutien sans précédent que nous offrons à tous nos clients. Tous les produits 
                    contractés sur la plateforme de commerce électronique passent par le processus d'entreposage 
                    d'entiercement en Tanzanie pour l'exécution des garanties de transaction, et les fonds des 
                    importateurs sont protégés sur des comptes séquestres dans les institutions bancaires 
                    panafricaines indépendantes réputées permettant à nos importateurs d'effectuer tous les 
                    règlements dans leurs devises locales d'une manière sécurisée et efficace.`,
                ],
                img: 'escrow-warehousing.png',
                alt_img: 'Core services',
            },
            {
                title: 'Sécurité et confidentialité des données électroniques',
                text: `Contrairement à d’autres plateformes électroniques vulnérables aux violations de 
                sécurité des données, Exportery dispose d’une architecture d'assurance de sécurité des 
                données fiable qui protège à la fois les informations des importateurs et des exportateurs 
                contre l'accès non autorisé et le vol. Notre organisation respecte des normes de confidentialité 
                élevées et ne partage pas les contacts et les enregistrements d'opérations du commerce 
                électronique avec des tiers. En outre, nos bases de données et nos serveurs sont situés 
                dans des pays sûrs et neutres tels que la Suisse et les Émirats arabes unis, ce qui 
                renforce notre crédibilité et notre fiabilité.`,
                img: 'security.png',
                alt_img: 'Core services',
            },
        ],
        btn: 'En savoir plus sur les services de base',
        href: staticURLs.about_core_services,
    },
];