export const about_marketplace_es = {
    title: 'Cómo funciona Exportery',
    pages: [
        {
            id: 'for_exporter',
            label: 'Para el exportador',
            content: [
                {
                    title: 'REGÍSTRATE Y VALIDA',
                    description: 'su organización en el mercado electrónico industrial de forma gratuita.',
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'COLOCA TUS PRODUCTOS',
                    description: 'en la sección específica a través de prácticas herramientas de mercado electrónico, incluida la carga por lotes de varios artículos.',
                    img: 'man.svg',
                    alt: 'Man icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'RECIBE RFQ DE IMPORTADORES DE TODO EL MUNDO',
                    description: 'incluidas las agencias gubernamentales con precios absolutamente transparentes en nuestro mercado electrónico.',
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'OBTENGA UN CERTIFICADO DE USUARIO FINAL CON FIRMA ELECTRÓNICA',
                    description: 'para llevar a cabo los procedimientos de control de exportaciones en el país de despacho de las mercancías.',
                    img: 'certificate.svg',
                    alt: 'Certificate icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SIGNEZ VOTRE E-CONTRAT',
                    description: 'aux conditions FCA.',
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'PLATAFORMA ELECTRÓNICA TE PERMITE FORMULAR TODA LA DOCUMENTACIÓN AUTOMÁTICAMENTE',
                    description: 'para el despacho aduanero y la exportación de sus mercancías: facturas, listas de embalaje, documentos de transporte y etiquetas de envío.',
                    img: 'package.svg',
                    alt: 'Package icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ENVÍE SU CARGA A LA TERMINAL DE CARGA DEL AEROPUERTO ESPECIALIZADA EN BASE A LOS TÉRMINOS FCA (INCOTERMS 2020) EN SU PAÍS',
                    description: 'y e-platform Exportery asumirá la responsabilidad de reservar el envío con el transportista, pagar el flete, emitir la guía aérea y proporcionar un seguro de transporte internacional.',
                    img: 'shipment.svg',
                    alt: 'Shipment icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SU CARGA LLEGARÁ AL ALMACÉN DE TRÁNSITO DEL AEROPUERTO INTERNACIONAL EN UN PAÍS NEUTRAL',
                    description: 'Realizaremos una inspección técnica independiente de la calidad y cantidad de su carga y la prepararemos para transferirla al usuario final. Sus bienes estarán seguros en el depósito de garantía hasta el final de la liquidación.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'OBTENGA FONDOS EN SU CUENTA DEL ACUERDO',
                    description: 'de acuerdo con los términos de su contrato electrónico.',
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SEGUIMIENTO DE CADA ETAPA DE SU ENTREGA DE CARGA',
                    description: 'desde el origen del envío en su país hasta el destino final del consignatario.',
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
        {
            id: 'for_importer',
            label: 'Para el importador',
            content: [
                {
                    title: 'REGÍSTRATE Y VALIDA',
                    description: 'su organización en el mercado electrónico industrial de forma gratuita.',
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'OBTENGA OFERTAS PARA SUS RFQ CON EL PRECIO MÁS BAJO Y LA ENTREGA MÁS RÁPIDA',
                    description: 'directamente de los fabricantes o exportadores de productos.',
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ENVÍE SU CERTIFICADO DE USUARIO FINAL',
                    description: 'a través de nuestras herramientas electrónicas para cumplir con los requisitos de control de exportación en el país de origen de las mercancías y obtener una garantía de cumplimiento de las obligaciones contractuales.',
                    img: 'accept.svg',
                    alt: 'Accept icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'FIRMA TU CONTRATO ELECTRÓNICO',
                    description: 'en términos CIP para su destino.',
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ACEPTE TODA LA DOCUMENTACIÓN DE ENVÍO NECESARIA EN FORMA ELECTRÓNICA DEL EXPORTADOR',
                    description: 'y recibe toda la información sobre el seguimiento de mercancías a través de nuestro panel electrónico.',
                    img: 'delivery.svg',
                    alt: 'Delivery icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SU CARGA LLEGARÁ AL ALMACÉN DE TRÁNSITO DEL AEROPUERTO INTERNACIONAL EN UN PAÍS NEUTRAL',
                    description: 'Realizaremos una inspección técnica independiente de la calidad y cantidad de su carga y la prepararemos para transferirla al usuario final. Sus bienes estarán seguros en el depósito de garantía hasta el final de la liquidación.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'TRANSFIERE SUS FONDOS',
                    description: 'Después de firmar el certificado de aceptación, transfiere fondos a la cuenta de acuerdo con el contrato electrónico y, al mismo tiempo, nuestra agencia enviará los productos a su dirección desde el almacén de tránsito.',
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SEGUIMIENTO DE CADA ETAPA DE SU ENTREGA DE CARGA',
                    description: 'hasta el destino final, incluyendo toda la documentación de transporte y aviso de entrega.',
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
    ],
};