import React from 'react';
import {useSelector} from 'react-redux';

import DataService from '../../../services/data-service';

const withDataService = (serviceParam) => (Wrapped) => {
    return (props) => {
        const {
            session,
            homePage,
            profile,
        } = useSelector(({
                             session: {session},
                             publicData: {homePage},
                             profile,
                         }) => ({session, homePage, profile}));
        const dataService = new DataService();
        let data = dataService.getStaticData(profile.language, session);

        if (!session) {
            data = dataService.getStaticData(homePage.language, session);
        }

        if (serviceParam) {
            const arr = serviceParam.split('.');
            data = arr.reduce((accumulator, value) => {
                if (Object.keys(accumulator).length > 0) {
                    return accumulator[value];
                }
                return data[value];
            }, {});
        }

        return <Wrapped {...props} staticData={data}/>;
    };
};

export default withDataService;