import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../../../../common/button';

import classnames from 'classnames/bind';
import styles from './sidebar.module.scss';

class Sidebar extends Component {
    state = {
        itemList: null,
        prevSectionId: null,
    };

    componentDidMount() {
        if (this.props.sections.length > 0) {
            this.setState({prevSectionId: this.props.sections[0].id});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (prevProps.sections.length === 0) {
                this.setState({prevSectionId: this.props.sections[0].id});
            }

            this.setState({itemList: this.props.sections});
        }

        if (prevProps.activeSectionId !== this.props.activeSectionId) {
            const {activeSectionId} = this.props;

            if (activeSectionId && activeSectionId !== this.state.prevSectionId) {
                this.setState({prevSectionId: activeSectionId});
            }
        }
    }

    render() {
        const {itemList, prevSectionId} = this.state;
        const {
            loading, isDisabled, uploadLabel,
            cancelLabel, onUpload, onCancel,
        } = this.props.buttonParams;

        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    {itemList && itemList.map(({id, label, isDisabled, isSaved}, idx) => {
                        const cx = classnames.bind(styles);
                        const itemClasses = cx('item', {
                            'active': prevSectionId === id,
                            'disabled': isDisabled,
                            'saved': isSaved && !isDisabled,
                        });

                        return (
                            <div key={idx} className={itemClasses} onClick={() => {
                                if (id !== prevSectionId) {
                                    this.setState({prevSectionId: id});
                                }

                                this.props.onScrollToSection(id, prevSectionId);
                            }}>
                                <span className={styles.step}>{idx + 1}</span>
                                {label}
                            </div>
                        );
                    })}
                </div>

                <div className={styles['btn-block']}>
                    <Button classNames={styles['primary-btn']}
                            dataLoading={loading}
                            isDisabled={isDisabled}
                            onClick={onUpload}>
                        {uploadLabel}
                    </Button>
                    <Button styleType="SECONDARY" onClick={onCancel}>{cancelLabel}</Button>
                </div>
            </div>
        );
    }

    static defaultProps = {
        onUpload: () => {},
        onCancel: () => {},
        onScrollToSection: () => {},
    };

    static propTypes = {
        sections: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
        buttonParams: PropTypes.shape({
            loading: PropTypes.bool,
            isDisabled: PropTypes.bool,
            uploadLabel: PropTypes.string,
            cancelLabel: PropTypes.string,
            onUpload: PropTypes.func,
            onCancel: PropTypes.func,
        }),
        activeSectionId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        onScrollToSection: PropTypes.func,
    };
}

const mapStateToProps = ({activityHub: {placeProduct: {formSections: {list}}}}) => ({
    sections: list,
});

export default connect(mapStateToProps)(Sidebar);