import React from 'react';
import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';
import 'tippy.js/animations/shift-away.css';

import classnames from 'classnames/bind';
import styles from './accordion.module.scss';

const Accordion = (props) => {
    const {
        label, children, labelClassNames, labelTextClassNames, contentClassNames,
        isOpen, withAnimation, turnOffAnimationOnClose, onToggle,
    } = props;
    const cx = classnames.bind(styles);

    const labelClasses = cx('label', labelClassNames, {'active': isOpen});
    const contentClasses = cx('content', contentClassNames);

    const content = (
        <div className={contentClasses}>
            {children}
        </div>
    );

    return (
        <Tippy
            content={content}
            animation={withAnimation ? 'shift-away' : false}
            arrow={false}
            appendTo={'parent'}
            popperOptions={{modifiers: [{name: 'flip', enabled: false}]}}
            interactive={true}
            maxWidth="none"
            role="accordion"
            offset={[0, 0]}
            placement="bottom-start"
            visible={isOpen}
            duration={turnOffAnimationOnClose ? [300, 0] : [300, 250]}
            zIndex={0}
        >
            <div className={labelClasses} onClick={onToggle}>
                <div className={cx(['label-text'], labelTextClassNames)}>{label}</div>
            </div>
        </Tippy>
    );
};

Accordion.defaultProps = {
    withAnimation: true,
    turnOffAnimationOnClose: false,
    onToggle: () => {
    },
};

Accordion.propTypes = {
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    labelClassNames: PropTypes.string,
    labelTextClassNames: PropTypes.string,
    contentClassNames: PropTypes.string,
    isOpen: PropTypes.bool,
    withAnimation: PropTypes.bool,
    turnOffAnimationOnClose: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default Accordion;