export default class FormFieldCreator {
    foundGroups = (array) => {
        let indexes = [];

        for (let i = 0; i < array.length; i++) {
            if (array[i].group) {
                indexes.push(i);
            }
        }

        if (indexes.length !== 0) {
            if (indexes.length > 3) {
                let formFields = [];
                let fieldOfGroup = [];

                for (let j = 0; j < 3; j++) {
                    fieldOfGroup.push(array[indexes[j]]);
                }

                formFields = [
                    ...array.slice(0, indexes[0]),
                    fieldOfGroup,
                    ...array.slice(indexes[2] + 1),
                ];

                return this.foundGroups(formFields);
            } else {
                let fieldOfGroup = [];
                const [first, , last] = indexes;

                for (let i = 0; i < indexes.length; i++) {
                    fieldOfGroup.push(array[indexes[i]]);
                }

                return [
                    ...array.slice(0, first),
                    fieldOfGroup,
                    ...array.slice(last + 1),
                ];
            }
        }

        return array;
    };

    getFormFieldInputGroup = (array) => {
        const itemsWithGroup = [];
        let itemWithoutGroup = [];

        for (let i = 0; i < array.length; i++) {
            if (array[i].group) {
                const currentIdx = itemsWithGroup.findIndex(item => item.group === array[i].group);

                if (currentIdx === -1) {
                    itemsWithGroup.push(array[i]);
                }
            } else {
                itemWithoutGroup.push(array[i]);
            }
        }

        const group = itemsWithGroup.map(groupItem =>
            array.filter(item => item.group === groupItem.group),
        );

        return [
            ...group,
            ...itemWithoutGroup,
        ];
    };

    getFormFields = (array) => {
        return this.foundGroups(array);
    };
}