import React from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../common/hoc';
import arrayOfItemsHandler from './handlers/array-of-items-handler';

import Checkbox from '../../../../../../common/checkbox';
import Switcher from '../../../../../../common/switcher';
import Logo from '../../../../../../common/logo/logo';

import classnames from 'classnames/bind';
import styles from './packing-list-labelling.module.scss';

import fragileIcon from './assets/fragile.svg';
import keepDryIcon from './assets/keep-dry.svg';
import handsIcon from './assets/hands.svg';

const PackingListLabelling = (props) => {
    const {
        staticData, labels, isSelectAll, isPrintContent, isPrintCutLine,
        onCheckboxChange, onSelectAllCheckbox, onSwitchPrintCutLine,
    } = props;
    const {
        checkbox_label,
        switcher_label,
        row_labels,
    } = staticData.activity_hub.e_contracts.pages.packing_list_wizard.pages.labelling;

    if (labels.length !== 0) {
        const arrayOfList = arrayOfItemsHandler(labels, 4);

        const cx = classnames.bind(styles);
        const containerClasses = cx('container', {'for-view': !isPrintContent});

        const createLabellingList = (list) =>
            list.map((item, idx) => {
                const {
                    id, num, contract, carrier, consignee, content, destination, dims,
                    gross_weight, net_weight, packing_date, shipper, waybill,
                    isChecked, is_keep_dry, is_fragile, is_handle_care,
                } = item;

                const labelClasses = cx('label', {
                    'for-print': isPrintContent,
                    'print-with-cut-line': isPrintContent && isPrintCutLine,
                });

                return (
                    <div key={idx} className={labelClasses}>
                        <div className={styles['title-block']}>
                            {!isPrintContent &&
                            <Checkbox
                                id={id}
                                label={num}
                                value={isChecked ? 1 : 0}
                                activeCheckboxList={isChecked ? [1] : []}
                                classNames={styles.checkbox}
                                onChange={onCheckboxChange}/>}
                            {isPrintContent && <div className={styles.title}>{num}</div>}
                            <div className={styles.icons}>
                                {is_fragile !== 0 && <img src={fragileIcon} alt={'fragile'}/>}
                                {is_keep_dry !== 0 && <img src={keepDryIcon} alt={'keep-dry'}/>}
                                {is_handle_care !== 0 && <img src={handsIcon} alt={'hands'}/>}
                            </div>
                        </div>
                        <div className={styles.description}>
                            <div className={styles['description-label']}>{row_labels['contract']}</div>
                            <div className={styles['description-value']}>{contract}</div>
                            <div className={styles['description-label']}>{row_labels['packing_date']}</div>
                            <div className={styles['description-value']}>{packing_date}</div>
                            <div className={styles['description-label']}>{row_labels['shipper']}</div>
                            <div className={styles['description-value']}>{shipper}</div>
                            <div className={styles['description-label']}>{row_labels['consignee']}</div>
                            <div className={styles['description-value']}>{consignee}</div>
                            <div className={styles['description-label']}>{row_labels['destination']}</div>
                            <div className={styles['description-value']}>{destination}</div>
                            <div className={styles['description-label']}>{row_labels['carrier']}</div>
                            <div className={styles['description-value']}>{carrier}</div>
                            <div className={styles['description-label']}>{row_labels['waybill']}</div>
                            <div className={styles['description-value']}>{waybill}</div>
                            <div className={styles['description-label']}>{row_labels['content']}</div>
                            <div className={styles['description-value']}>{content}</div>
                            <div className={styles['description-label']}>{row_labels['dims']}</div>
                            <div className={styles['description-value']}>{dims}</div>
                            <div className={styles['description-label']}>{row_labels['net_weight']}</div>
                            <div className={styles['description-value']}>{net_weight}</div>
                            <div className={styles['description-label']}>{row_labels['gross_weight']}</div>
                            <div className={styles['description-value']}>{gross_weight}</div>
                        </div>

                        <Logo classNames={styles.logo}/>
                    </div>
                );
            });

        return (
            <>
                {!isPrintContent &&
                <div className={styles['addition-settings-block']}>
                    <Checkbox
                        id={'select-all-labels-to-print'}
                        label={checkbox_label}
                        value={isSelectAll ? 1 : 0}
                        activeCheckboxList={isSelectAll ? [1] : []}
                        onChange={onSelectAllCheckbox}/>

                    <Switcher
                        id={'print-border-switcher'}
                        label={switcher_label}
                        value={isPrintCutLine ? 1 : 0}
                        activeSwitcherList={isPrintCutLine ? [1] : []}
                        onChange={onSwitchPrintCutLine}/>
                </div>}

                <div className={containerClasses}>
                    {arrayOfList.map((list, idx) => {
                        const unpairedIdx = idx % 2 !== 0;

                        const listClasses = cx('list', {
                            'for-print': isPrintContent,
                            'one-label': list.length === 1 && !unpairedIdx,
                            'one-label-in-group': list.length === 1 && unpairedIdx,
                            'two-label': list.length === 2 && !unpairedIdx,
                            'two-label-in-group': list.length === 2 && unpairedIdx,
                            'full-grid': list.length > 2,
                            'print-with-cut-line': isPrintContent && isPrintCutLine,
                        });

                        return (
                            <div key={idx} className={listClasses}>
                                {createLabellingList(list)}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    return null;
};

PackingListLabelling.defaultProps = {
    isSelectAll: false,
    isPrintContent: false,
    isPrintCutLine: false,
    onCheckboxChange: () => {
    },
    onSwitchPrintCutLine: () => {
    },
};

PackingListLabelling.propTypes = {
    staticData: PropTypes.object,
    labels: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.arrayOf(PropTypes.object),
    ]),
    isSelectAll: PropTypes.bool,
    isPrintContent: PropTypes.bool,
    isPrintCutLine: PropTypes.bool,
    onCheckboxChange: PropTypes.func,
    onSelectAllCheckbox: PropTypes.func,
    onSwitchPrintCutLine: PropTypes.func,
};

export default withDataService()(PackingListLabelling);