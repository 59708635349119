import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const CONFIRM_PACKING_LIST_REQUEST = 'CONFIRM_PACKING_LIST_REQUEST';
export const CONFIRM_PACKING_LIST_SUCCESS = 'CONFIRM_PACKING_LIST_SUCCESS';
export const CONFIRM_PACKING_LIST_FAILURE = 'CONFIRM_PACKING_LIST_FAILURE';

export const confirmPackingListRequest = createRequestHandler('CONFIRM_PACKING_LIST');

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const confirmPackingList = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CONFIRM_PACKING_LIST_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case CONFIRM_PACKING_LIST_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                data: {
                    ...payload,
                    message,
                },
                error: null,
            };

        case CONFIRM_PACKING_LIST_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                data: null,
                error,
            };

        case 'RESET_CONFIRM_PACKING_LIST':
        case 'RESET_PACKING_LIST_WIZARD_PAGE':
            return initialState;

        default:
            return state;
    }
};

export default confirmPackingList;