import React from 'react';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../common/hoc';

import SubQuotationItem from '../sub-quotation-item';

import styles from './sub-quotation.module.scss';

const EnhancedComponent = withAccordion()(SubQuotationItem);

const SubQuotation = ({rfq_sub_number, organization, ...rest}) => {
    const subQuotationItem = (
        <div className={styles.container}>
            <div className={styles.number}>{rfq_sub_number}</div>
            <div className={styles.organization}>{organization}</div>
        </div>
    );

    return (
        <EnhancedComponent {...rest}
            label={subQuotationItem}
            containerClassNames={styles['accordion-container']}
            labelClassNames={styles['accordion-label']}
            contentClassNames={styles['accordion-content']}
            isActiveLabelOnOpen
        />
    );
};

SubQuotation.propTypes = {
    rfq_sub_number: PropTypes.string,
    organization: PropTypes.string,
};

export default SubQuotation;