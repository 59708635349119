import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withTabHeader} from '../../../../common/hoc';

import EContract from './components/e-contract';
import Preloader from '../../../../../../common/preloader';
import SkeletonPreloader from '../../../../../../common/skeleton-preloader';

import styles from './e-contracts-desktop.module.scss';

const EContractsDesktop = ({staticData, userInteraction, contractsList, loading, contractPageUrl}) => {
    const {table_labels_importer, table_labels_exporter} = staticData.activity_hub.e_contracts;
    let headerLabels = userInteraction === 'importer' ? table_labels_importer : table_labels_exporter;

    if (contractsList && contractsList.length > 0 && typeof contractsList[0] !== 'string') {
        headerLabels = contractsList[0].row.map(({label}) => label);
        headerLabels.unshift('№');
    }

    const tableHead = headerLabels.map((label, idx) => (
        <div key={idx}>
            <SkeletonPreloader type={'TEXT'} loading={loading}>
                {label}
            </SkeletonPreloader>
        </div>
    ));

    const tableItems = !loading && contractsList
        ? typeof contractsList[0] !== 'string'
            ? contractsList.map((item, idx) => {
                return (
                    <Link to={() => contractPageUrl(item.id)} key={idx}>
                        <EContract {...item} classNames={styles.item}/>
                    </Link>
                );
            })
            : <div className={styles.empty}>{contractsList[0]}</div>
        : <Preloader type={'SECONDARY'} classNames={styles.preloader}/>;

    return (
        <div className={styles.container}>
            <div className={styles['table-head']}>
                {tableHead}
            </div>

            <div className={styles['table-items']}>{tableItems}</div>
        </div>
    );
};

EContractsDesktop.propTypes = {
    staticData: PropTypes.object,
    userInteraction: PropTypes.string,
    contractsList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
    loading: PropTypes.bool,
    contractPageUrl: PropTypes.func,
};

export default withTabHeader()(EContractsDesktop);