import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';

import Button from '../button';

import classnames from 'classnames/bind';
import styles from './uploaded-file-info.module.scss';

const UploadedFileInfo = (props) => {
    const {staticData, classNames, withScroll, onClear} = props;
    let {data} = props;

    const {
        row_id, filters, no_errors,
        button: {clear_all},
    } = staticData.excel_response_info;

    const [infoFilters, setInfoFilters] = useState([
        {id: 'all', label: filters[0], isActive: true},
        {id: 'errors', label: filters[1], isActive: false},
    ]);
    const [activeFilter, setActiveFilter] = useState(infoFilters[0].id);

    useEffect(() => {
        if (data && withScroll) {
            window.scrollTo({top: 0});
        }
    }, [data, withScroll]);

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames, {'show': data});

    const onToggleFilters = (filterID) => {
        const newInfoFilters = infoFilters.map((filter) => {
            if (filter.id === filterID) {
                return {
                    ...filter,
                    isActive: true,
                };
            }

            return {
                ...filter,
                isActive: false,
            };
        });

        setActiveFilter(filterID);
        setInfoFilters(newInfoFilters);
    };

    const filtersBlock = infoFilters && infoFilters.map(({id, label, isActive}, idx) => {
        const labelClasses = cx('filter', {'active': isActive});

        return (
            <div key={idx} className={labelClasses} onClick={() => onToggleFilters(id)}>
                {label}
            </div>
        );
    });

    let itemsBlock = [];
    if (data) {
        if (activeFilter === 'errors') {
            data = data.filter(item => item.status === 'failed');
            if (data.length === 0) {
                itemsBlock = no_errors;
            }
        }

        if (data.length > 0) {
            itemsBlock = data.map(({rowId, status, message, list}, idx) => {
                const itemClasses = cx('item', {
                    'success': status === 'success',
                    'failed': status === 'failed',
                });

                return (
                    <div className={itemClasses} key={idx}>
                        <div className={styles.mark}/>

                        <div className={styles.content}>
                            <div className={styles.title}>{message}</div>
                            {status === 'failed' && (
                                <div className={styles.description}>
                                    {list.map((item, idx) => {
                                        return (
                                            <div className={styles['description-item']} key={idx}>{item}</div>
                                        );
                                    })}
                                </div>
                            )}
                            <div className={styles['row-number']}>{row_id}: {rowId}</div>
                        </div>
                    </div>
                );
            });
        }
    }

    return (
        <div className={containerClasses}>
            <div className={styles.filters}>
                {filtersBlock}
            </div>

            <div className={cx('items-block', {'text-center': typeof itemsBlock === 'string'})}>
                {itemsBlock}
            </div>

            <div className={styles['btn-block']}>
                <Button styleType="SECONDARY" onClick={onClear}>
                    {clear_all}
                </Button>
            </div>
        </div>
    );
};

UploadedFileInfo.defaultProps = {
    withScroll: false,
    onClear: () => {
    },
};

UploadedFileInfo.propTypes = {
    staticData: PropTypes.object,
    classNames: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        rowId: PropTypes.number,
        status: PropTypes.string,
        message: PropTypes.string,
        list: PropTypes.array,
    })),
    withScroll: PropTypes.bool,
    onClear: PropTypes.func,
};

export default withDataService()(UploadedFileInfo);