import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';
import {withServices} from '../../../../service-context';
import {productsRequest, categoryProductsRequest} from '../../../../../reducers/marketplace/products';
import {searchAction} from '../../../../../actions/common';

import SearchPage from '../../../../marketplace/components/pages/search-page/search-page';

class SearchPagePublic extends Component {
    render() {
        const {session, ...rest} = this.props;
        if (!session && session !== null) {
            return <SearchPage {...rest}/>;
        }

        return null;
    }

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchAllProducts: productsRequest(publicService.getPublicProducts),
    fetchCategoryProducts: categoryProductsRequest(publicService.getPublicCategoryProducts),
});

const mapStateToProps = ({
                             session: {session},
                             publicData: {homePage: {language}},
                             search: {slug},
                             marketplace: {products},
                         }) => {
    return ({
        session,
        slug,
        products,
        language,
    });
};

const mapDispatchToProps = (dispatch, {fetchAllProducts, fetchCategoryProducts}) => {
    const {updateSearchCategorySlug} = searchAction;

    return bindActionCreators({
        fetchAllProducts,
        fetchCategoryProducts,
        updateSearchCategorySlug,
    }, dispatch);
};

export default compose(
    withDataService(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(SearchPagePublic);