import React, {useState} from 'react';
import PropTypes from 'prop-types';

import styles from '../../page-to-print.module.scss';

import printIconDisabled from '../../../common/assets/other-icons/print-icon-disabled.svg';
import printIcon from '../../../common/assets/other-icons/print-icon.svg';

const PrintBlob = ({staticData, originUrl, src}) => {
    const [loaded, setLoaded] = useState(false);
    const img = src ? printIcon : printIconDisabled;
    return (
        <div className={styles['print-blob']}>
            <iframe id="print-blob-iframe" src={src}
                    title="print-blob-iframe-title"
                    name="print-blob-iframe-name"
                    style={{display: 'none'}}
                    onLoad={() => setLoaded(true)}>
            </iframe>
            {src && !loaded && (
                <a href={originUrl} target='_blank' rel={'noreferrer nofollow noopener'}>
                    <img src={img} alt={staticData.print_blob_button_alt}/>
                </a>
            )}
            {src && loaded &&
                <img src={printIcon}
                     alt={staticData.print_blob_button_alt}
                     onClick={() => {
                         const element = document.getElementById('print-blob-iframe');
                         loaded && element.contentWindow.print();
                     }}/>
            }
            {!src && <img src={printIconDisabled} alt={staticData.print_blob_button_alt}/>}
        </div>
    );
};

PrintBlob.propTypes = {
    staticData: PropTypes.object,
    originUrl: PropTypes.string,
    src: PropTypes.string,
};

export default PrintBlob;