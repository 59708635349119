import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../../../../../common/hoc';

import InfoMark from '../../../../../../../../../../../../common/info-mark';
import Tooltip from '../../../../../../../../../../../../common/tooltip';
import EllipsisTextTooltip from '../../../../../../../../../../../../common/ellipsis-text-tooltip';

import styles from './packing-list-item.module.scss';

const PackingListItem = (props) => {
    const {
        staticData: {unpacking_tooltip},
        classNames,
        id,
        list: itemList,
        status,
        onEditButtonClick,
        onUnpackProduct
    } = props;

    const itemHandler = ({list, num, mix}, actionBlock) => {
        return (
            <div className={styles.item}>
                <div className={styles.column}>{num}</div>
                <div className={styles.column}>
                    {list.map((item, idx) => {
                        const {name, serial, quantity, doq, net_weight, gross_weight} = item;
                        return (
                            <div className={styles.row} key={idx}>
                                <div>
                                    <EllipsisTextTooltip tooltip={name}/>
                                </div>
                                <div>{serial
                                    ? serial.split(',').map((number, idx) =>
                                        <EllipsisTextTooltip key={idx}
                                                             lassNames={styles.serial}
                                                             tooltip={number}/>
                                    ) : '-'}
                                </div>
                                <div>{quantity}</div>
                                <div>{doq}</div>
                                <div>{net_weight || '-'}</div>
                                <div>{gross_weight || '-'}</div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.column}>
                    {parse(mix)}
                </div>
                <div className={styles.column}>
                    {status !== 'confirmed' && actionBlock && (
                        <div className={styles['icons-block']}>
                            <div className={styles['unpack-block']}>
                                <span className={styles.unpack} onClick={() => onUnpackProduct(id)}/>

                                <Tooltip tooltip={unpacking_tooltip}
                                         contentClassNames={styles.tooltip}
                                         placement={'top-start'}
                                         minWidth={160}
                                         maxWidth={300}>
                                    <div className={styles.info}>
                                        <InfoMark classNames={styles['info-icon']}/>
                                    </div>
                                </Tooltip>
                            </div>

                            <span className={styles.edit} onClick={() => onEditButtonClick(id)}/>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (itemList.length > 1) {
        return (
            <div className={`${styles.container} ${classNames}`}>
                {itemList.map((item, idx) => {
                    const actionBlock = idx === 0;
                    return (
                        <div className={styles.row} key={idx}>
                            {itemHandler(item, actionBlock)}
                        </div>
                    );
                })}
            </div>
        );
    }

    return itemList.map((item, idx) => {
        const actionBlock = idx === 0;
        return (
            <div className={`${styles.container} ${classNames}`} key={idx}>
                {itemHandler(item, actionBlock)}
            </div>
        );
    });
};

PackingListItem.propTypes = {
    staticData: PropTypes.object,
    classNames: PropTypes.string,
    id: PropTypes.number,
    list: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        list: PropTypes.arrayOf(PropTypes.shape({
            list: PropTypes.arrayOf(PropTypes.shape({
                doq: PropTypes.string,
                name: PropTypes.string,
                quantity: PropTypes.number,
                serial: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
                gross_weight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
                net_weight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            })),
            num: PropTypes.string,
            mix: PropTypes.string,
        })),
    })),
    status: PropTypes.string,
    onEditButtonClick: PropTypes.func,
    onUnpackProduct: PropTypes.func,
};

export default withDataService(
    'activity_hub.e_contracts.pages.packing_list_wizard.pages.packing_list'
)(PackingListItem);