import React from 'react';
import PropTypes from 'prop-types';

import ImageSlider from '../../../../../../common/image-slider';
import Image from '../../../../../../common/image';

import styles from './product-image-slider.module.scss';

const ProductImageSlider = ({staticData, images, containerClass, ...otherProps}) => {
    const {image_alt} = staticData.marketplace.product_page;

    const newImages = images.map((slide, idx) => (
        <Image key={idx} source={slide} alt={image_alt} classNames={styles.image}/>
    ));

    return (
        <div className={styles.container}>
            <ImageSlider {...otherProps} slides={newImages} containerClass={containerClass}/>
        </div>
    );
};

ProductImageSlider.defaultProps = {
    containerClass: 'swiper-container-main',
};

ProductImageSlider.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
    containerClass: PropTypes.string,
    staticData: PropTypes.object,
};

export default ProductImageSlider;