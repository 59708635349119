import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const REMOVE_SHIPMENT_ORGANIZATION_REQUEST = 'REMOVE_SHIPMENT_ORGANIZATION_REQUEST';
export const REMOVE_SHIPMENT_ORGANIZATION_SUCCESS = 'REMOVE_SHIPMENT_ORGANIZATION_SUCCESS';
export const REMOVE_SHIPMENT_ORGANIZATION_FAILURE = 'REMOVE_SHIPMENT_ORGANIZATION_FAILURE';

export const removeShipmentOrganizationRequest = createRequestHandler('REMOVE_SHIPMENT_ORGANIZATION');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const removeShipmentOrganization = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case REMOVE_SHIPMENT_ORGANIZATION_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case REMOVE_SHIPMENT_ORGANIZATION_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case REMOVE_SHIPMENT_ORGANIZATION_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                ...initialState,
                error,
            };

        case 'RESET_REMOVE_SHIPMENT_ORGANIZATION':
            return initialState;

        default:
            return state;
    }
};

export default removeShipmentOrganization;