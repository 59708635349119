import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {onInitSectionStateFromDataByServer, sectionSettingsHandler} from '../../common/handlers';
import {checkingEqualityOfFiles, dropzonePropsTransformer} from '../../../../../common/handlers';
import uploadFileHandler from '../../../../../../../../utils/upload-file-handler';
import sweetAlert from '../../../../../../../common/sweet-alert';

import Photo from './photo';

import rejectedPhotoImg from './assets/rejected_photo.png';

class PhotoContainer extends Component {
    state = {
        id: null,
        formDataKey: null,
        photos: [],
        tmpPhotos: [],
        alreadyUploadedPhotos: [],
        rejectedFiles: [[], []],
        isDisabledSection: false,
        isDisabledAlwaysSection: false,
        isSaveButtonDisabled: true,
        maxAmountOfFiles: 1,
        defaultProps: {
            maxCount: 10,
            maxSize: 5,
            mimes: ['.jpg', '.jpeg', '.bmp', '.png', '.webp', '.gif', '.svg'],
        },
        deletedFileID: null,
    };

    componentDidMount() {
        this.setState({id: this.props.id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (this.props.sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, this.props.sections);

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }

        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                const photoArray = this.props.list.filter(({section}) => section.id === 'photo');
                const photo = photoArray[0];
                const props = photo.props.map(props => dropzonePropsTransformer(props));

                const alreadyUploadedPhotos = photo.value.map((photo) => {
                    return {
                        file: photo,
                        preview: photo.href,
                    };
                });

                let newDefaultProps = {};
                for (let item of props) {
                    newDefaultProps = {
                        ...newDefaultProps,
                        ...item,
                    };
                }

                if (this.props.pageMode === 'EDIT') {
                    const isDisabled = onInitSectionStateFromDataByServer(photoArray);
                    if (isDisabled !== this.state.isDisabledSection) {
                        this.props.updatedPlaceProductSectionDisabledStatus(this.state.id, isDisabled);
                    }
                }

                this.setState({
                    formDataKey: photo.name,
                    alreadyUploadedPhotos,
                    defaultProps: newDefaultProps,
                    maxAmountOfFiles: newDefaultProps.maxCount - alreadyUploadedPhotos.length,
                });
            }
        }

        if (prevState.photos !== this.state.photos) {
            let isDisabled = true;
            if (this.state.photos.length > 0) {
                isDisabled = false;

            } else {
                if (prevState.photos.length > 0) {
                    isDisabled = false;
                }
            }

            if (this.state.isSaveButtonDisabled !== isDisabled) {
                this.setState({isSaveButtonDisabled: isDisabled});
                this.props.onUpdateSaveStatus(this.state.id, isDisabled);
            }
        }

        if (prevProps.fetchingDataFromSection !== this.props.fetchingDataFromSection) {
            const {fetchingDataFromSection} = this.props;

            if (fetchingDataFromSection && fetchingDataFromSection.currentSection === this.state.id) {
                this.onUploadDataFromSection();
            }
        }

        if (prevProps.removeAttachment.loading !== this.props.removeAttachment.loading) {
            if (prevProps.removeAttachment.loading && this.state.deletedFileID) {
                this.setState({deletedFileID: null});
            }
        }
    }

    onAcceptedPhotos = (acceptedFiles) => {
        const {tmpPhotos, maxAmountOfFiles} = this.state;
        const {same_name_message} = this.props.staticData.activity_hub.place_product.from_form.sections.photo;

        if (tmpPhotos.length === 0) {
            uploadFileHandler(acceptedFiles, this.onLoadingPhoto, this.onLoadedPhoto);
            const newPhotos = acceptedFiles.map(acceptedFile => ({
                file: acceptedFile,
                preview: URL.createObjectURL(acceptedFile),
            }));

            this.setState({
                tmpPhotos: newPhotos,
                maxAmountOfFiles: maxAmountOfFiles - acceptedFiles.length,
            });
        } else {
            const files = tmpPhotos.map(({file}) => file);
            const {
                files: newAcceptedFiles, infoData,
            } = checkingEqualityOfFiles(files, acceptedFiles);

            if (infoData && infoData.length !== 0) {
                const message = (
                    <div>
                        {same_name_message[0]}
                        <ul>{infoData.map((item, idx) => <li key={idx}>{`- ${item}`}</li>)}</ul>
                        {same_name_message[1]}
                    </div>
                );

                sweetAlert('info', message);
            }

            if (newAcceptedFiles.length !== 0) {
                uploadFileHandler(newAcceptedFiles, this.onLoadingPhoto, this.onLoadedPhoto);

                const newPhotos = newAcceptedFiles.map(acceptedFile => ({
                    file: acceptedFile,
                    preview: URL.createObjectURL(acceptedFile),
                }));

                this.setState({
                    tmpPhotos: [...tmpPhotos, ...newPhotos],
                    maxAmountOfFiles: maxAmountOfFiles - newAcceptedFiles.length,
                });
            }
        }
    };

    onRejectedFiles = (files) => {
        const {rejectedFiles} = this.state;
        const {quantity_message} = this.props.staticData.activity_hub.place_product.from_form.sections.photo;

        let rightFiles = files.filter(({errors}) => errors[0].code === 'too-many-files');

        if (rightFiles.length !== 0) {
            const {defaultProps: {maxCount}, alreadyUploadedPhotos, tmpPhotos} = this.state;
            let infoMessage = `${quantity_message[0]} ${maxCount} ${quantity_message[1]}`;

            const amountOfUploadedPhotos = alreadyUploadedPhotos.length + tmpPhotos.length;
            if (amountOfUploadedPhotos !== 0) {
                infoMessage = `${quantity_message[0]} ${maxCount} ${quantity_message[1]} ${quantity_message[2]} ${maxCount - this.state.maxAmountOfFiles} ${quantity_message[3]}`;
            }

            sweetAlert('info', infoMessage);
        } else {
            let newFiles = files.map(({file, errors}) => {
                if (errors[0].code === 'file-too-large') {
                    return {
                        file,
                        errorType: 'file-too-large',
                        preview: URL.createObjectURL(file),
                    };
                }

                return {
                    file,
                    errorType: errors[0].code,
                    preview: rejectedPhotoImg,
                };
            });

            const tooLargeFiles = newFiles.filter(({errorType}) => errorType === 'file-too-large');
            const invalidFiles = newFiles.filter(({errorType}) => errorType !== 'file-too-large');

            if (rejectedFiles.length === 0) {
                newFiles = [tooLargeFiles, invalidFiles];
            } else {
                newFiles = [
                    [...rejectedFiles[0], ...tooLargeFiles],
                    [...rejectedFiles[1], ...invalidFiles],
                ];
            }

            this.setState({rejectedFiles: newFiles});
        }
    };

    onLoadingPhoto = (file) => {
        const {tmpPhotos} = this.state;

        const currentPhotoIdx = tmpPhotos.findIndex((photo) => photo.file === file);
        const currentPhoto = tmpPhotos[currentPhotoIdx];

        const newPhotos = [
            ...tmpPhotos.slice(0, currentPhotoIdx),
            {
                ...currentPhoto,
                isLoaded: false,
            },
            ...tmpPhotos.slice(currentPhotoIdx + 1),
        ];

        this.setState({tmpPhotos: newPhotos});
    };

    onLoadedPhoto = (file) => {
        const {tmpPhotos} = this.state;

        const currentPhotoIdx = tmpPhotos.findIndex((photo) => photo.file === file);
        const currentPhoto = tmpPhotos[currentPhotoIdx];

        const newPhotos = [
            ...tmpPhotos.slice(0, currentPhotoIdx),
            {
                ...currentPhoto,
                isLoaded: true,
            },
            ...tmpPhotos.slice(currentPhotoIdx + 1),
        ];

        this.setState({tmpPhotos: newPhotos, photos: newPhotos});
    };

    onDeletePhoto = (currentIdx) => {
        const {tmpPhotos} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newPhotos = [
                        ...tmpPhotos.slice(0, currentIdx),
                        ...tmpPhotos.slice(currentIdx + 1),
                    ];

                    this.setState({
                        tmpPhotos: newPhotos,
                        photos: newPhotos,
                        maxAmountOfFiles: this.state.maxAmountOfFiles + 1,
                    });
                }
            });
    };

    onDeleteRejectedFile = (currentIdx, arrayIdx) => {
        const {rejectedFiles} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newFiles = [
                        ...rejectedFiles[arrayIdx].slice(0, currentIdx),
                        ...rejectedFiles[arrayIdx].slice(currentIdx + 1),
                    ];

                    const newRejectedFiles = [
                        ...rejectedFiles.slice(0, arrayIdx),
                        newFiles,
                        ...rejectedFiles.slice(arrayIdx + 1),
                    ];

                    this.setState({rejectedFiles: newRejectedFiles});
                }
            });
    };

    onUploadDataFromSection = () => {
        let data = null;
        if (this.state.photos.length > 0) {
            const files = this.state.photos.map(({file}) => file);
            data = {[this.state.formDataKey]: {files}};
        }

        this.props.onSave(this.state.id, data);
        this.setState({isSaveButtonDisabled: true});
    };

    onPhotoSave = () => {
        this.onUploadDataFromSection();
    };

    onDeleteAlreadyExistPhoto = (id) => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.onDeleteAlreadyUploadedFile(this.state.formDataKey, id);
                    this.setState({deletedFileID: id});
                }
            });
    };

    componentWillUnmount() {
        if (this.state.tmpPhotos.length !== 0) {
            this.state.tmpPhotos.forEach(photo => URL.revokeObjectURL(photo.preview));
        }

        if (this.state.photos.length !== 0) {
            this.state.photos.forEach(photo => URL.revokeObjectURL(photo.preview));
        }
    }

    render() {
        const {photo} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {removeAttachment: {loading}, ...restProps} = this.props;
        const {id, deletedFileID, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;
        const deleteAlreadyUploadedPhoto = deletedFileID ? {id: deletedFileID, loading} : null;

        return <Photo {...restProps} {...rest}
                      id={id}
                      panelData={photo}
                      isDisabled={isDisabledSection}
                      isDisabledAlways={isDisabledAlwaysSection}
                      deleteAlreadyUploadedPhoto={deleteAlreadyUploadedPhoto}
                      onAcceptedFiles={this.onAcceptedPhotos}
                      onRejectedFiles={this.onRejectedFiles}
                      onDeletePhoto={this.onDeletePhoto}
                      onDeleteRejectedFile={this.onDeleteRejectedFile}
                      onDeleteAlreadyExistPhoto={this.onDeleteAlreadyExistPhoto}
                      onClick={() => this.props.onClick(id)}
                      onSave={this.onPhotoSave}
                      onSkip={() => this.props.onSkip(id)}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onClick: () => {},
        onSave: () => {},
        onUpdateSaveStatus: () => {},
        onDeleteAlreadyUploadedFile: () => {},
    };

    static propTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        sections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        fetchingDataFromSection: PropTypes.object,
        removeAttachment: PropTypes.shape({
            loading: PropTypes.bool,
        }),
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
        onUpdateSaveStatus: PropTypes.func,
        onDeleteAlreadyUploadedFile: PropTypes.func,
        updatedPlaceProductSectionDisabledStatus: PropTypes.func,
    };
}

export default PhotoContainer;