import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';
import FormFieldCreator from '../../../../../common/handlers/form-field-creator';

import Preloader from '../../../../../../../common/preloader';
import FormFieldConstructor from '../../../../../../../common/form-field-constructor';
import Button from '../../../../../../../common/button';

import styles from './resources.module.scss';

const Resources = (props) => {
    const {
        staticData, id, formFields: {loading, updating},
        isDisabledSection, resourcesFields, warrantyFields,
        isSaveButtonDisabled, onChangeSelectInputGroup,
        onInputChange, onSave, onSkip,
    } = props;
    const {
        sections: {resources: {warranty_title}},
        button: {skip, save},
    } = staticData.activity_hub.place_product.from_form;

    const formFieldCreator = new FormFieldCreator();
    const formFieldHandler = (formFields) => {
        const fields = formFieldCreator
            .getFormFields(formFields)
            .map((field) => {
                if (Array.isArray(field)) {
                    const isShown = field[0].isShown !== undefined ? field[0].isShown : true;

                    return {
                        fields: field,
                        isShown,
                    };
                }

                return field;
            });

        return fields.map((field, idx) =>
            <FormFieldConstructor key={idx}
                                  field={field}
                                  onChangeSelectInputGroup={onChangeSelectInputGroup}
                                  onInputChange={onInputChange}/>,
        );
    };

    const resourcesBlock = resourcesFields ? formFieldHandler(resourcesFields) : [];
    const warrantyBlock = warrantyFields ? formFieldHandler(warrantyFields) : [];

    return (
        (loading || updating)
            ? <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
            : (
                <form id={`${id}_form`}>
                    <div className={`${styles['grid-group']} ${styles['group-even-2']}`}>
                        {resourcesBlock}
                    </div>

                    <div className={styles.title}>{warranty_title}</div>
                    <div className={`${styles['grid-group']} ${styles['group-even-3']}`}>
                        {warrantyBlock}
                    </div>

                    {!isDisabledSection && (
                        <div className={styles['btn-group']}>
                            <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                            <Button classNames={styles['btn-submit']}
                                    isDisabled={isSaveButtonDisabled}
                                    onClick={onSave}>
                                {save}
                            </Button>
                        </div>
                    )}
                </form>
            )
    );
};

Resources.defaultProps = {
    isDisabledSection: false,
    onSave: () => {
    },
    onSkip: () => {
    },
};

Resources.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.string,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    isDisabledSection: PropTypes.bool,
    resourcesFields: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    warrantyFields: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    isSaveButtonDisabled: PropTypes.bool,
    onChangeSelectInputGroup: PropTypes.func,
    onInputChange: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
};

export default withSectionPanel()(Resources);