import React, {useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {compose} from 'redux';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {utilsAction} from '../../../../../actions/common';
import {withPaginationProductsPage, withScrollToTop} from '../../../../common/hoc';
import {withProductsHandler} from '../../../../common/hoc';
import {hubURLs} from '../../../../../services/api-urls';

import {GridView, ListView} from '../../../../common/views';
import FiltersContainer from '../../../../common/filters';
import SortingContainer from '../../../../common/sorting';
import ProductPanel from './components/common/product-panel';
import ProductCard from './components/common/product-card';
import Preloader from '../../../../common/preloader';
import Button from '../../../../common/button';
import ButtonConstructor from '../../../../common/button-constructor';
import SkeletonPreloader from '../../../../common/skeleton-preloader';

import classnames from 'classnames/bind';
import styles from './my-products.module.scss';

const MyProducts = (props) => {
    const {
        staticData,
        productPage: {
            loading, updating, products,
            filters, sorting, fileButtons,
            message, isFilterOpen,
        },
        prevProducts,
        isLanguageUpdating,
        fileDownloading,
        offerDeleting,
        canResetPnumFilter,
        paginationBlock,
        toggleMyProductsFilter,
        onDownloadExcelFile,
        onRemoveProductOffer,
    } = props;

    const {
        title,
        product_panel: {header},
        device_info_message,
        empty_my_products_message,
        button: {upload_xls, upload_from_form},
    } = staticData.activity_hub.my_products;
    const cx = classnames.bind(styles);

    const view = useSelector(({utils: {view}}) => (view));
    const dispatch = useDispatch();

    const myProductsItemsRef = useRef(null);

    const currentProducts = products.length > 0 ? products : prevProducts;
    const isProductsList = currentProducts.length > 0;

    const xlDevice = useMediaQuery({minWidth: 1200});

    useEffect(() => {
        const initProductImageHeight = () => {
            if (isProductsList) {
                if (view === 'GRID' && myProductsItemsRef.current) {
                    if (myProductsItemsRef.current.childNodes.length !== 0) {
                        const productCard = myProductsItemsRef.current.childNodes[0];
                        const image = productCard.firstChild;
                        const imageWidth = image.offsetWidth;
                        const imageHeight = imageWidth * .8;

                        for (let child of myProductsItemsRef.current.childNodes) {
                            const firstRow = xlDevice && imageHeight > 210 ? '210px' : `${imageHeight}px`;
                            child.style.gridTemplateRows = `${firstRow} auto 1fr`;
                        }
                    }
                }
            }
        };

        initProductImageHeight();
        window.addEventListener('resize', initProductImageHeight);

        return () => {
            window.removeEventListener('resize', initProductImageHeight);
        };
    }, [xlDevice, isProductsList, view, myProductsItemsRef]);

    const onMobileFilterOpen = (value) => {
        if (value) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '' || '';
        }
    };

    const switchViewBlock = (
        <div className={styles.views}>
            <ListView
                isActive={view === 'LIST'}
                onClick={() => dispatch(utilsAction.switchView('LIST'))}/>
            <GridView
                isActive={view === 'GRID'}
                onClick={() => dispatch(utilsAction.switchView('GRID'))}/>
        </div>
    );

    const screenSizeInfoBlock = (
        <div className={styles['device-info-block']}>
            {device_info_message}
        </div>
    );

    const createProductsList = () => {
        const itemsClass = cx({
            'grid-items': view === 'GRID',
            'list-items': view === 'LIST',
        });
        const contentContainerClasses = cx('content-container', {'disabled': offerDeleting});
        const productsBlockClasses = cx('products-block', {'disabled': isFilterOpen && xlDevice});

        const itemsList = currentProducts.map((item, idx) => {
            const additionalProps = {
                offerDeleting: offerDeleting && item.id === offerDeleting,
                offerOptionsMenuDisabled: fileDownloading,
                onRemoveProductOffer: (...args) => onRemoveProductOffer(...args, item.id),
            };

            return view === 'GRID'
                ? <ProductCard key={idx} {...item} {...additionalProps}/>
                : <ProductPanel key={idx} {...item} {...additionalProps}/>;
        });

        const productsBlock = isProductsList ? (
            <>
                {view === 'LIST' && (
                    <div className={styles['list-header']}>
                        <div className={styles.column}>{header.id_label}</div>
                        <div className={styles.column}>{header.item_label}</div>
                        <div className={styles.column}>{header.condition_label}</div>
                        <div className={styles.column}>{header.price_label}</div>
                    </div>
                )}

                <div ref={myProductsItemsRef} className={itemsClass}>
                    {itemsList}
                </div>
                {paginationBlock}
            </>
        ) : (
            <div className={styles['empty-container']}>
                <div className={styles.message}>{message ? message : empty_my_products_message}</div>
                <Link to={hubURLs.products_excel}>
                    <Button styleType="SECONDARY" classNames={styles['btn-secondary']}>
                        {upload_xls}
                    </Button>
                </Link>
                <Link to={hubURLs.product_create_manual}>
                    <Button styleType="TEXT">{upload_from_form}</Button>
                </Link>
            </div>
        );

        return (
            <div className={contentContainerClasses}>
                {offerDeleting && <div className={styles['hiding-container']}/>}

                <div className={styles['filters-block']}>
                    <FiltersContainer filters={filters}
                                      updating={updating}
                                      screenChangeOn={1200}
                                      isSkeletonPreloader={isLanguageUpdating}
                                      canResetPnumFilter={canResetPnumFilter}
                                      onToggleFilter={toggleMyProductsFilter}
                                      onMobileFilterOpen={onMobileFilterOpen}/>
                    <SortingContainer sortingOptions={sorting}
                                      updating={updating}
                                      screenChangeOn={1200}
                                      isSkeletonPreloader={isLanguageUpdating}
                                      isDisabled={!isProductsList}
                                      onMobileFilterOpen={onMobileFilterOpen}/>
                </div>

                {!xlDevice && screenSizeInfoBlock}

                <div className={productsBlockClasses}>
                    {productsBlock}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className={cx('title-container', {'disabled': offerDeleting})}>
                <div className={styles.title}>{title}</div>
                <div className={styles['right-block']}>
                    {fileButtons && (
                        <div className={styles['download-file-container']}>
                            <SkeletonPreloader type={'TEXT'}
                                               loading={isLanguageUpdating}
                                               classNames={cx('download-file-skeleton', {
                                                   'active': isLanguageUpdating,
                                               })}>
                                <ButtonConstructor {...fileButtons[0]}
                                                   dataLoading={fileDownloading}
                                                   classNames={styles['download-file']}
                                                   onClick={onDownloadExcelFile}/>
                            </SkeletonPreloader>
                        </div>
                    )}
                    {(!loading && isProductsList) && switchViewBlock}
                </div>
            </div>

            {!loading ? createProductsList() : <Preloader/>}
        </>
    );
};

MyProducts.defaultProps = {
    onDownloadExcelFile: () => {},
    onRemoveProductOffer: () => {},
};

MyProducts.propTypes = {
    staticData: PropTypes.object,
    products: PropTypes.shape({
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        products: PropTypes.array,
        message: PropTypes.string,
        filters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        sorting: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        fileButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        isFilterOpen: PropTypes.bool,
    }),
    prevProducts: PropTypes.array,
    isLanguageUpdating: PropTypes.bool,
    fileDownloading: PropTypes.bool,
    offerDeleting: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    toggleMyProductsFilter: PropTypes.func,
    onDownloadExcelFile: PropTypes.func,
    onRemoveProductOffer: PropTypes.func,
    paginationBlock: PropTypes.node,
};

export default compose(
    withProductsHandler(),
    withPaginationProductsPage(),
    withScrollToTop(),
)(MyProducts);