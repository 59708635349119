import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import {loginAction, registrationAction} from '../../../actions/common';

import Button from '../button';

import styles from './request-access.module.scss';

class RequestAccessBlock extends Component {
    render() {
        const {
            staticData: {
                request_access_text,
                sign_in: {button: {sign_in}}, sign_up: {button: {request_access_btn}},
            },
            classNames,
        } = this.props;

        return (
            <div className={`${styles.container} ${classNames}`}>
                <div className={styles.text}>{request_access_text}</div>
                <div className={styles['btn-block']}>
                    <Button styleType="SECONDARY"
                            classNames={styles['sing-in-btn']}
                            onClick={this.props.onSignInOpen}>
                        {sign_in}
                    </Button>
                    <Button classNames={styles['sign-up-btn']}
                            onClick={this.props.onSignUpOpen}>
                        {request_access_btn}
                    </Button>
                </div>
            </div>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        classNames: PropTypes.string,
        onSignInOpen: PropTypes.func,
        onSignUpOpen: PropTypes.func,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onSignInOpen: loginAction.openSignIn,
        onSignUpOpen: registrationAction.openSignUp,
    }, dispatch);
};

export default compose(
    withDataService('authorization'),
    connect(null, mapDispatchToProps),
)(RequestAccessBlock);