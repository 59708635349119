import {createRequestHandler} from '../../../utils';

const RESET_PASSWORD_FORM_PUBLIC_UPLOAD_REQUEST = 'RESET_PASSWORD_FORM_PUBLIC_UPLOAD_REQUEST';
export const RESET_PASSWORD_FORM_PUBLIC_UPLOAD_SUCCESS = 'RESET_PASSWORD_FORM_PUBLIC_UPLOAD_SUCCESS';
const RESET_PASSWORD_FORM_PUBLIC_UPLOAD_FAILURE = 'RESET_PASSWORD_FORM_PUBLIC_UPLOAD_FAILURE';

export const resetPasswordFormPublicUploadRequest = createRequestHandler('RESET_PASSWORD_FORM_PUBLIC_UPLOAD', {
    notAcceptableErrorRedirect: false,
    setGlobalServerError: false,
});

const initialState = {
    loading: false,
    confirmed: false,
    error: null,
};

const uploadData = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case RESET_PASSWORD_FORM_PUBLIC_UPLOAD_REQUEST:
            return {
                loading: true,
                confirmed: false,
                error: null,
            };

        case RESET_PASSWORD_FORM_PUBLIC_UPLOAD_SUCCESS:
            return {
                loading: false,
                confirmed: true,
                error: null,
            };

        case RESET_PASSWORD_FORM_PUBLIC_UPLOAD_FAILURE:
            return {
                loading: false,
                confirmed: false,
                error: payload,
            };

        case 'SET_INITIAL_RESET_PASSWORD_FORM_PUBLIC_UPLOAD':
            return initialState;

        default:
            return state;
    }
};

export default uploadData;