import React from 'react';
import PropTypes from 'prop-types';

import {withScrollToTop} from '../../../../common/hoc';

import CategoryPlate from '../../../../marketplace/components/common/category-panel';
import Preloader from '../../../../common/preloader';
import ProductsContainer from '../../common/products-container/products-container';

import styles from '../../../../marketplace/components/pages/marketplace-home/marketplace-home.module.scss';

const MarketplaceHome = (props) => {
    const {
        session, categories,
        loading, products, list, updating,
        profileLoading, profileUpdating,
        staticData, history, location,
    } = props;

    const categoriesItem = categories.map((category, idx) =>
        <CategoryPlate key={idx} {...category}/>,
    );

    let sectionBlock = [];
    if (list.length > 0) {
        const {all_products_title} = staticData.marketplace.home;

        sectionBlock = (
            <ProductsContainer
                productPage={products}
                history={history}
                location={location}
                pageTitle={all_products_title}
                filterTurnOn={false}/>
        );
    }

    const marketplaceBlock = (
        <>
            <div className={styles.categories}>{categoriesItem}</div>
            {sectionBlock}
        </>
    );

    return (
        <div className={styles['page-container']}>
            {loading
                ? <Preloader/>
                : session && profileLoading
                    ? <Preloader/>
                    : marketplaceBlock
            }
        </div>
    );
};

MarketplaceHome.propTypes = {
    session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    categories: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    products: PropTypes.object,
    list: PropTypes.array,
    profileLoading: PropTypes.bool,
    staticData: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
};

export default withScrollToTop()(MarketplaceHome);