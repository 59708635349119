import DataService from '../../../../../../../../services/data-service';

/* Check breadcrumbs if need to use on different page. If this is not just new/edit product page */
export const errorResponseHandler = (data, language) => {
    const dataService = new DataService();
    const {error_response_place_product} = dataService.getStaticData(language).sweet_alert_static_data;
    const elements = data.map((item, idx) => <li key={idx}>- {item}</li>);

    return (
        <div>
            {error_response_place_product}
            <ul>{elements}</ul>
        </div>
    );
};