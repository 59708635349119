export default class NotificationsService {
    constructor(httpService) {
        this._http = httpService;
    }

    getNotificationsList = (query) => {
        return this._http.get('/hub/notifications/list', query);
    };

    postNotificationView = (id) => {
        return this._http.post(`/action/notification/${id}/view`);
    };

    postNotificationBatchDelete = (body) => {
        return this._http.post(`/action/notification/delete`, body);
    };
}