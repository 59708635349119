import {createRequestHandler} from '../../../../utils';

export const BANK_ACCOUNT_FORM_FIELDS_REQUEST = 'BANK_ACCOUNT_FORM_FIELDS_REQUEST';
export const BANK_ACCOUNT_FORM_FIELDS_SUCCESS = 'BANK_ACCOUNT_FORM_FIELDS_SUCCESS';
export const BANK_ACCOUNT_FORM_FIELDS_FAILURE = 'BANK_ACCOUNT_FORM_FIELDS_FAILURE';

export const BANK_ACCOUNT_FORM_FIELDS_UPDATING_REQUEST = 'BANK_ACCOUNT_FORM_FIELDS_UPDATING_REQUEST';
export const BANK_ACCOUNT_FORM_FIELDS_UPDATING_SUCCESS = 'BANK_ACCOUNT_FORM_FIELDS_UPDATING_SUCCESS';
export const BANK_ACCOUNT_FORM_FIELDS_UPDATING_FAILURE = 'BANK_ACCOUNT_FORM_FIELDS_UPDATING_FAILURE';

export const bankAccountFormFieldsRequest = createRequestHandler('BANK_ACCOUNT_FORM_FIELDS');
export const updatingBankAccountFormFieldsRequest = createRequestHandler('BANK_ACCOUNT_FORM_FIELDS_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    list: null,
    error: null,
};

const bankAccountFormFields = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case BANK_ACCOUNT_FORM_FIELDS_REQUEST:
            return initialState;

        case BANK_ACCOUNT_FORM_FIELDS_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case BANK_ACCOUNT_FORM_FIELDS_SUCCESS:
        case BANK_ACCOUNT_FORM_FIELDS_UPDATING_SUCCESS:
            return {
                list: payload.list,
                loading: false,
                updating: false,
                error: null,
            };

        case BANK_ACCOUNT_FORM_FIELDS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case BANK_ACCOUNT_FORM_FIELDS_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default bankAccountFormFields;