import React from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './notification-card.module.scss';

const NotificationCard = (props) => {
    const {
        classNames, style, id, content, date, isFirsNotificationOpen, isLastNotificationOpen,
        onSwitchNotification, onCloseNotification, onDeleteNotification,
    } = props;
    const {subject, hello, body, list, sign} = content;

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames);
    const prevArrowClasses = cx('prev-arrow', {'disabled': isFirsNotificationOpen});
    const nextArrowClasses = cx('next-arrow', {'disabled': isLastNotificationOpen});

    return (
        <div id={id} className={containerClasses} style={style}>
            <div className={styles['title-block']}>
                <div className={styles.title}>{subject}</div>
                <div className={styles['buttons-block']}>
                    <div className={styles['arrow-block']}>
                        <div className={prevArrowClasses}
                             onClick={() => !isFirsNotificationOpen && onSwitchNotification(id, 'prev')}/>
                        <div className={nextArrowClasses}
                             onClick={() => !isLastNotificationOpen && onSwitchNotification(id, 'next')}/>
                    </div>
                    <div className={styles.delete} onClick={() => onDeleteNotification(id)}/>
                    <div className={styles.close} onClick={() => onCloseNotification(id)}/>
                </div>
            </div>
            <div className={styles.time}>{date}</div>

            <div className={styles['content-block']}>
                <div className={styles['main-block']}>
                    <p className={styles['hello-block']}>{hello}</p>
                    <p>{parse(body)}</p>
                    <ul className={styles['list-block']}>{
                        list && list.length > 0 && list.map(({label, href}, idx) => {
                            if (!href) {
                                return <li key={idx}>{label}</li>;
                            }
                            return <li key={idx}><Link to={href}>{label}</Link></li>;
                        })
                    }</ul>
                </div>

                <div className={styles['sign-block']}>{sign}</div>
            </div>
        </div>
    );
};

NotificationCard.propTypes = {
    classNames: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.any,
    content: PropTypes.shape({
        subject: PropTypes.string,
        hello: PropTypes.string,
        body: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.object),
        sign: PropTypes.string,
    }),
    date: PropTypes.string,
    isFirsNotificationOpen: PropTypes.bool,
    isLastNotificationOpen: PropTypes.bool,
    onSwitchNotification: PropTypes.func,
    onCloseNotification: PropTypes.func,
    onDeleteNotification: PropTypes.func,
};

export default NotificationCard;