import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import PackingListItem from './components/common/packing-list-item';
import PackingListSummary from './components/common/packing-list-summary';
import PackingForm from '../../packing-form';
import Preloader from '../../../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './packing-list.module.scss';

const PackingList = (props) => {
    const {
        staticData, packingEditingForm, totalData, scrollToForm,
        isConfirmedDisabled, isUnpackedProduct, packingEditingFormLoading, unpackPackedProductLoading,
        confirmPackingListLoading, switchSpareKitLoading, onConfirm, callbackScrollToFrom, ...rest
    } = props;
    let {productsList} = props;
    const {table_labels, empty_message} = staticData;

    const packingFormRef = useRef(null);

    useEffect(() => {
        if (packingEditingForm && scrollToForm) {
            packingFormRef.current.scrollIntoView();
            callbackScrollToFrom();
        }

    }, [packingEditingForm, scrollToForm, callbackScrollToFrom]);

    productsList = productsList.filter(({list}) => list.length > 0);
    const loading = packingEditingFormLoading || unpackPackedProductLoading || switchSpareKitLoading;

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', {'hiding-container': loading});
    const tableClasses = cx('table', {'hiding-container': loading});

    const tableLabels = table_labels.map((label, idx) => {
        if (Array.isArray(label)) {
            return (
                <div className={styles.column} key={idx}>
                    {label.map((item, idx) => (
                        <React.Fragment key={idx}>
                            <div>{item}</div>
                        </React.Fragment>
                    ))}
                </div>
            );
        }

        return <div key={idx} className={styles.column}>{label}</div>;
    });

    const tableItems = productsList.length > 0
        ? productsList.map((item, idx) => <PackingListItem key={idx} {...item} {...rest}/>)
        : <div className={styles.empty}>{empty_message}</div>;

    return (
        <div className={containerClasses}>
            {loading && packingEditingForm &&
                <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
            }

            <div className={tableClasses}>
                {loading && !packingEditingForm &&
                    <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                }
                <div className={styles['table-head']}>
                    {tableLabels}
                </div>

                {tableItems}
            </div>

            {packingEditingForm &&
                <div ref={packingFormRef} className={styles['form-block']}>
                    <PackingForm formMode={'EDIT'}/>
                </div>
            }

            {(productsList.length > 0 && totalData) &&
                <PackingListSummary {...totalData}
                                    staticData={staticData}
                                    classNames={styles['total-block']}
                                    confirmPackingListLoading={confirmPackingListLoading}
                                    isConfirmedDisabled={isConfirmedDisabled}
                                    isUnpackedProduct={isUnpackedProduct}
                                    onConfirm={onConfirm}/>}
        </div>
    );
};

PackingList.propTypes = {
    staticData: PropTypes.object,
    productsList: PropTypes.array,
    scrollToForm: PropTypes.bool,
    packingEditingForm: PropTypes.array,
    confirmPackingListLoading: PropTypes.bool,
    isConfirmedDisabled: PropTypes.bool,
    isUnpackedProduct: PropTypes.bool,
    packingEditingFormLoading: PropTypes.bool,
    unpackPackedProductLoading: PropTypes.bool,
    switchSpareKitLoading: PropTypes.bool,
    onConfirm: PropTypes.func,
    callbackScrollToFrom: PropTypes.func,
};

export default PackingList;