import React from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {errorURLs} from '../../services/api-urls';

import RequestAccessBlock from '../common/request-access-block';

import styles from './request-access-page.module.scss';

const RequestAccessPage = () => {
    const session = useSelector(({session: {session}}) => session);
    return !session ? <RequestAccessBlock classNames={styles.container}/> : <Redirect to={errorURLs.not_found}/>;
};

export default RequestAccessPage;