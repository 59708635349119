import {getCurrentYear} from '../../../utils';
import {CONTACT_DATA} from '../constants';
import {hubURLs, staticURLs, URLs} from '../../api-urls';
import {terms_and_conditions_ru} from '../terms-and-conditions';
import {challenges_ru} from '../home-page/challenges';
import {advantages_ru} from '../home-page/advantages';
import {about_marketplace_ru} from '../about-marketplace';
import {about_independent_technical_surveying_ru} from '../about-independent-technical-surveying';
import {about_core_services_ru} from '../about-core-services';
import BreadcrumbsConstructor from '../../breadcrumbs';

const ru = (session) => ({
    meta_tags: {
        title: 'Exportery',
        description: 'Промышленная торговая e-платформа',
    },

    home_page: {
        banner_text: ['Промышленная торговая', 'e-платформа'],
        image_alt: 'Banner image',
        main_title: `Мы налаживаем кооперационные связи между глобальными производителями запасных 
        частей и их конечными пользователями, в отраслях авиации и наземного транспорта`,
        advantages_content: advantages_ru,
        challenges_subtitle: 'Вызовы',
        challenges_text: `Конечные пользователи, импортеры, организации по техобслуживанию и ремонту (ТОиР), 
        эксплуатанты воздушных судов, правительственные конечные пользователи и экспортеры, включая производителей, 
        а также ремонтные предприятия, сталкиваются с «проблемой» в цепочке поставок запасных частей и компонентов.`,
        challenges_list: challenges_ru,
        video_content: {
            title: ['Welcome to ', 'the new world of ', 'cross-border business'],
            // className: 'normal',
        },
        red_zone_title: 'Отправить запрос на доступ сейчас',
        red_zone_text: `Доступ к платформе электронной коммерции Exportery является БЕСПЛАТНЫМ для Экспортеров и 
        Импортеров. Чтобы полностью оценить функционал системы и ее содержимое, укажите 
        некоторые сведения, нажав на кнопку ниже.`,
        button: {
            request_access_btn: 'Запрос доступа',
            play_video: 'Воспроизвести видео',
            public_marketplace_btn: 'Попробуйте сейчас',
        },
    },

    about_marketplace: about_marketplace_ru,

    about_independent_technical_surveying: about_independent_technical_surveying_ru,

    about_core_services: about_core_services_ru,

    news: {
        title: 'Новости, статьи и исследования рынков',
        page: URLs.news,
        no_articles: 'Нет новостей для отображения',
        image_alt: 'Изображение к статьи',
        button: {
            view: 'Подробней',
            all_news: 'Перейти к новостям',
        },
    },

    section: {
        news: {
            page: URLs.news,
            title: 'Новости, статьи и исследования рынков',
            no_articles: 'Нет новостей для отображения',
        },
        shop_by_brand: {
            title: 'Находите запасные части и компоненты, разработанные:',
        },
        no_content: 'Нет элементов для отображения',
        button: {
            more_btn: 'показать больше',
            less_btn: 'показать меньше',
        },
    },

    terms_and_conditions: {
        nav_menu: 'Навигация по главам',
        data: terms_and_conditions_ru,
    },

    privacy_policy: {
        title: 'Политика конфиденциальности',
        updated: 'Last updated: June 21, 2022',
        text: [
            [`This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of 
                Your information when You use the Service and tells You about Your privacy rights and how the law
                protects You.`],
            [`We use Your Personal data to provide and improve the Service. By using the Service, You agree to the 
                collection and use of information in accordance with this Privacy Policy.`],
            {tag: 'h1', text: 'Interpretation and Definitions'},
            {tag: 'h2', text: 'Interpretation'},
            [`The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear
                in singular or in plural.`],
            {tag: 'h2', text: 'Definitions'},
            [`For the purposes of this Privacy Policy:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Account '},
                        {
                            tag: 'span',
                            text: 'means a unique account created for You to access our Service or parts of our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Business'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), 
                            refers to the Company as the legal entity that collects Consumers' personal information and
                            determines the purposes and means of the processing of Consumers' personal information, or
                            on behalf of which such information is collected and that alone, or jointly with others,
                            determines the purposes and means of the processing of consumers' personal information, that
                            does business in the State of California.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Company '},
                            {
                                tag: 'span',
                                text: `(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ${CONTACT_DATA.address[0]} ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                            },
                        ],
                        ['For the purpose of the GDPR, the Company is the Data Controller.'],
                    ],
                    [
                        {tag: 'strong', text: 'Consumer'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act),
                            means a natural person who is a California resident. A resident, as defined in the law,
                            includes (1) every individual who is in the USA for other than a temporary or transitory
                            purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a
                            temporary or transitory purpose.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Cookies '},
                        {
                            tag: 'span',
                            text: `are small files that are placed on Your computer, mobile device or
                            any other device by a website, containing the details of Your browsing history on that
                            website among its many uses.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Country '},
                        {tag: 'span', text: 'refers to: Georgia.'},
                    ],
                    [
                        {tag: 'strong', text: 'Data Controller'},
                        {
                            tag: 'span',
                            text: `, for the purposes of the GDPR (General Data Protection
                            Regulation), refers to the Company as the legal person which alone or jointly with others
                            determines the purposes and means of the processing of Personal Data.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Device '},
                        {
                            tag: 'span',
                            text: 'means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Do Not Track '},
                        {
                            tag: 'span',
                            text: `(DNT) is a concept that has been promoted by US regulatory
                            authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
                            industry to develop and implement a mechanism for allowing internet users to control the
                            tracking of their online activities across websites.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Personal Data '},
                            {
                                tag: 'span',
                                text: 'is any information that relates to an identified or identifiable individual.',
                            },
                        ],
                        [`For the purposes of GDPR, Personal Data means any information relating to You such as a name,
                            an identification number, location data, online identifier or to one or more factors
                            specific to the physical, physiological, genetic, mental, economic, cultural or social
                            identity.`],
                        [`For the purposes of the CCPA, Personal Data means any information that identifies, relates
                            to, describes or is capable of being associated with, or could reasonably be linked,
                            directly or indirectly, with You.`],
                    ],
                    [
                        {tag: 'strong', text: 'Sale'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), means
                            selling, renting, releasing, disclosing, disseminating, making available, transferring, or
                            otherwise communicating orally, in writing, or by electronic or other means, a Consumer's
                            personal information to another business or a third party for monetary or other valuable
                            consideration.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Service '},
                        {tag: 'span', text: 'refers to the Website.'},
                    ],
                    [
                        {tag: 'strong', text: 'Service Provider '},
                        {
                            tag: 'span',
                            text: `means any natural or legal person who processes the data on
                            behalf of the Company. It refers to third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the Service on behalf of the Company, to
                            perform services related to the Service or to assist the Company in analyzing how the
                            Service is used.
                            For the purpose of the GDPR, Service Providers are considered Data Processors.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Usage Data '},
                        {
                            tag: 'span',
                            text: `refers to data collected automatically, either generated by the
                            use of the Service or from the Service infrastructure itself (for example, the duration of a
                            page visit).`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Website '},
                        {tag: 'span', text: 'refers to Exportery, accessible from '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        [
                            {tag: 'strong', text: 'You '},
                            {
                                tag: 'span',
                                text: `means the individual accessing or using the Service, or the company, or
                                other legal entity on behalf of which such individual is accessing or using the Service, as
                                applicable.`,
                            },
                        ],
                        [`Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject
                            or as the User as you are the individual using the Service.`],
                    ],
                ],
            },
            {tag: 'h1', text: 'Collecting and Using Your Personal Data'},
            {tag: 'h2', text: 'Types of Data Collected'},
            {tag: 'h3', text: 'Personal Data'},
            [`While using Our Service, We may ask You to provide Us with certain personally identifiable
                information that can be used to contact or identify You. Personally identifiable information may
                include, but is not limited to:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Email address.',
                    'First name and last name.',
                    'Phone number.',
                    'Address, State, Province, ZIP/Postal code, City.',
                    'Bank account information in order to pay for products and/or services within the Service.',
                    'Usage Data.',
                ],
            },
            [`When You pay for a product and/or a service via bank transfer, We may ask You to provide information
                to facilitate this transaction and to verify Your identity. Such information may include, without
                limitation:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Date of birth.',
                    'Passport or National ID card.',
                    'Bank card statement.',
                    'Other information linking You to an address.',
                ],
            },
            {tag: 'h3', text: 'Usage Data'},
            ['Usage Data is collected automatically when using the Service.'],
            [`Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your
                visit, the time spent on those pages, unique device identifiers and other diagnostic data.`],
            [`When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.`],
            [`We may also collect information that Your browser sends whenever You visit our Service or when You
                access the Service by or through a mobile device.`],
            {tag: 'h3', text: 'Tracking Technologies and Cookies'},
            [`We use Cookies and similar tracking technologies to track the activity on Our Service and store
                certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
                information and to improve and analyze Our Service. The technologies We use may include:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Cookies or Browser Cookies. '},
                        {
                            tag: 'span',
                            text: `A cookie is a small file placed on Your Device. You
                            can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                            However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                            Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may
                            use Cookies.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Flash Cookies. '},
                        {
                            tag: 'span',
                            text: `Certain features of our Service may use local stored objects (or
                            Flash Cookies) to collect and store information about Your preferences or Your activity on our
                            Service. Flash Cookies are not managed by the same browser settings as those used for Browser
                            Cookies. For more information on how You can delete Flash Cookies, please read "Where can I
                            change the settings for disabling, or deleting local shared objects?" available at `,
                        },
                        {
                            tag: 'a',
                            href: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                            rel: 'external nofollow noopener noreferrer',
                            target: '_blank',
                            text: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        {tag: 'strong', text: 'Web Beacons. '},
                        {
                            tag: 'span',
                            text: `Certain sections of our Service and our emails may contain small
                            electronic files known as web beacons (also referred to as clear gifs, pixel tags, and
                            single-pixel gifs) that permit the Company, for example, to count users who have visited those
                            pages or opened an email and for other related website statistics (for example, recording the
                            popularity of a certain section and verifying system and server integrity).`,
                        },
                    ],
                ],
            },
            [
                {
                    tag: 'span',
                    text: `Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on
                    Your personal computer or mobile device when You go offline, while Session Cookies are deleted as
                    soon as You close Your web browser. Learn more about cookies on the `,
                },
                {
                    tag: 'a',
                    href: 'https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking',
                    rel: 'external nofollow noopener noreferrer',
                    target: '_blank',
                    text: 'Privacy Policies website',
                },
                {tag: 'span', text: ' article.'},
            ],
            ['We use both Session and Persistent Cookies for the purposes set out below:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Necessary / Essential Cookies.'},
                        ['Type: Session Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies are essential to provide You with services available through the
                            Website and to enable You to use some of its features. They help to authenticate users and
                            prevent fraudulent use of user accounts. Without these Cookies, the services that You have
                            asked for cannot be provided, and We only use these Cookies to provide You with those
                            services.`],
                    ],
                    [
                        {tag: 'strong', text: 'Cookies Policy / Notice Acceptance Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        ['Purpose: These Cookies identify if users have accepted the use of cookies on the Website.'],
                    ],
                    [
                        {tag: 'strong', text: 'Functionality Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies allow us to remember choices You make when You use the Website, such
                            as remembering your login details or language preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to avoid You having to re-enter your
                            preferences every time You use the Website.`],
                    ],
                    [
                        {tag: 'strong', text: 'Targeting and Advertising Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Third-Parties.'],
                        [`Purpose: These Cookies track your browsing habits to enable Us to show advertising which is
                            more likely to be of interest to You. These Cookies use information about your browsing
                            history to group You with other users who have similar interests. Based on that information,
                            and with Our permission, third party advertisers can place Cookies to enable them to show
                            adverts which We think will be relevant to your interests while You are on third party
                            websites.`],
                    ],
                ],
            },
            [`For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.`],
            {tag: 'h2', text: 'Use of Your Personal Data'},
            ['The Company may use Personal Data for the following purposes:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'To provide and maintain our Service'},
                        {tag: 'span', text: ', including to monitor the usage of our Service.'},
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your Account: '},
                        {
                            tag: 'span',
                            text: `to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You access to different functionalities of
                            the Service that are available to You as a registered user.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For the performance of a contract: '},
                        {
                            tag: 'span',
                            text: `the development, compliance and
                            undertaking of the purchase contract for the products, items or services You have purchased
                            or of any other contract with Us through the Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To contact You. '},
                        {
                            tag: 'span',
                            text: `To contact You by email, telephone calls, SMS, or other
                            equivalent forms of electronic communication, such as a mobile application's push
                            notifications regarding updates or informative communications related to the
                            functionalities, products or contracted services, including the security updates, when
                            necessary or reasonable for their implementation.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To provide You '},
                        {
                            tag: 'span',
                            text: `with news, special offers and general information about other
                            goods, services and events which we offer that are similar to those that you have already
                            purchased or enquired about unless You have opted not to receive such information.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your requests. '},
                        {
                            tag: 'span',
                            text: 'To attend and manage Your requests to Us.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer
                            of some or all of Our assets, whether as a going concern or as part of bankruptcy,
                            liquidation, or similar proceeding, in which Personal Data held by Us about our Service
                            users is among the assets transferred.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For other purposes. '},
                        {
                            tag: 'span',
                            text: `We may use Your information for other purposes, such as
                            data analysis, identifying usage trends, determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service, products, services, marketing and your
                            experience.`,
                        },
                    ],
                ],
            },
            ['We may share Your personal information in the following situations:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'With Service Providers. '},
                        {
                            tag: 'span',
                            text: `We may share Your personal information with Service
                            Providers to monitor and analyze the use of our Service, to show advertisements to You to help
                            support and maintain Our Service, for payment processing, to contact You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may share or transfer Your personal information in
                            connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                            acquisition of all or a portion of Our business to another company.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Affiliates. '},
                        {
                            tag: 'span',
                            text: `We may share Your information with Our affiliates, in which
                            case we will require those affiliates to honor this Privacy Policy. Affiliates include Our
                            parent company and any other subsidiaries, joint venture partners or other companies that We
                            control or that are under common control with Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With business partners. '},
                        {
                            tag: 'span',
                            text: 'We may share Your information with Our business partners to offer You certain products, services or promotions.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With other users: '},
                        {
                            tag: 'span',
                            text: `when You share personal information or otherwise interact in
                            the public areas with other users, such information may be viewed by all users and may be
                            publicly distributed outside.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Your consent. '},
                        {
                            tag: 'span',
                            text: 'We may disclose Your personal information for any other purpose with Your consent.',
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Retention of Your Personal Data'},
            [`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out
                in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply
                with our legal obligations (for example, if we are required to retain your data to comply with
                applicable laws), resolve disputes, and enforce our legal agreements and policies.`],
            [`The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
                retained for a shorter period of time, except when this data is used to strengthen the security or
                to improve the functionality of Our Service, or We are legally obligated to retain this data for
                longer time periods.`],
            {tag: 'h2', text: 'Transfer of Your Personal Data'},
            [`Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this
                information may be transferred to — and maintained on — computers located outside of Your state,
                province, country or other governmental jurisdiction where the data protection laws may differ than
                those from Your jurisdiction.`],
            [`Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.`],
            [`The Company will take all steps reasonably necessary to ensure that Your data is treated securely and
                in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other personal information.`],
            {tag: 'h2', text: 'Disclosure of Your Personal Data'},
            {tag: 'h3', text: 'Business Transactions'},
            [`If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                transferred. We will provide notice before Your Personal Data is transferred and becomes subject to
                a different Privacy Policy.`],
            {tag: 'h3', text: 'Law enforcement'},
            [`Under certain circumstances, the Company may be required to disclose Your Personal Data if required
                to do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).`],
            {tag: 'h3', text: 'Other legal requirements'},
            ['The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Comply with a legal obligation.',
                    'Protect and defend the rights or property of the Company.',
                    'Prevent or investigate possible wrongdoing in connection with the Service.',
                    'Protect the personal safety of Users of the Service or the public.',
                    'Protect against legal liability.',
                ],
            },
            {tag: 'h2', text: 'Security of Your Personal Data'},
            [`The security of Your Personal Data is important to Us, but remember that no method of transmission
                over the Internet, or method of electronic storage is 100% secure. While We strive to use
                commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute
                security.`],
            {tag: 'h1', text: 'Detailed Information on the Processing of Your Personal Data'},
            [`The Service Providers We use may have access to Your Personal Data. These third-party vendors
                collect, store, use, process and transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.`],
            {tag: 'h2', text: 'Advertising'},
            ['We may use Service Providers to show advertisements to You to help support and maintain Our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Google AdSense & DoubleClick Cookie.'},
                        [`Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of
                            the DoubleClick cookie enables it and its partners to serve ads to our users based on their
                            visit to our Service or other websites on the Internet.`],
                        [
                            {
                                tag: 'span',
                                text: `You may opt out of the use of the DoubleClick Cookie for interest-based advertising by
                                visiting the Google Ads Settings web page: `,
                            },
                            {
                                tag: 'a',
                                href: 'http://www.google.com/ads/preferences/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'http://www.google.com/ads/preferences/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                    [
                        {tag: 'strong', text: 'AdMob by Google.'},
                        ['AdMob by Google is provided by Google Inc.'],
                        [
                            {
                                tag: 'span',
                                text: 'You can opt-out from the AdMob by Google service by following the instructions described by Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://support.google.com/ads/answer/2662922?hl=en',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://support.google.com/ads/answer/2662922?hl=en',
                            },
                            {tag: 'span', text: '.'},
                        ],
                        [
                            [`For more information on how Google uses the collected information, please visit the "How
                                Google uses data when you use our partners' sites or app" page: `],
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/technologies/partner-sites',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/technologies/partner-sites',
                            },
                            {tag: 'span', text: ' or visit the Privacy Policy of Google: '},
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/privacy',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/privacy',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Email Marketing'},
            [`We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We
                send or by contacting Us.`],
            ['We may use Email Marketing Service Providers to manage and send emails to You.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Postmark'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://postmarkapp.com/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://postmarkapp.com/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Payments'},
            [`We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing (e.g. payment processors).`],
            [`We will not store or collect Your payment card details. That information is provided directly to Our
                third-party payment processors whose use of Your personal information is governed by their Privacy
                Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
                Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American
                Express and Discover. PCI-DSS requirements help ensure the secure handling of payment
                information.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Bilderlings'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://bilderlings.com',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://bilderlings.com',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            [`When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide
                information to facilitate this transaction and to verify Your identity.`],
            {tag: 'h2', text: 'Usage, Performance and Miscellaneous'},
            ['We may use third-party Service Providers to provide better improvement of our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Invisible reCAPTCHA'},
                        ['We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.'],
                        ['The reCAPTCHA service may collect information from You and from Your Device for security purposes.'],
                        [
                            {
                                tag: 'span',
                                text: 'The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://www.google.com/intl/en/policies/privacy/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://www.google.com/intl/en/policies/privacy/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h1', text: 'GDPR Privacy'},
            {tag: 'h2', text: 'We may process Personal Data under the following conditions:'},
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Consent. '},
                        {
                            tag: 'span',
                            text: 'You have given Your consent for processing Personal Data for one or more specific purposes.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Performance of a contract. '},
                        {
                            tag: 'span',
                            text: 'Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legal obligations. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Vital interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Public interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legitimate interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.',
                        },
                    ],
                ],
            },
            [`In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.`],
            {tag: 'h2', text: 'Your Rights under the GDPR'},
            ['The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.'],
            ['You have the right under this Privacy Policy, and by law if You are within the EU, to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Request access to Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `The right to access, update or delete the
                            information We have on You. Whenever made possible, you can access, update or request deletion
                            of Your Personal Data directly within Your account settings section. If you are unable to
                            perform these actions yourself, please contact Us to assist You. This also enables You to
                            receive a copy of the Personal Data We hold about You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request correction of the Personal Data that We hold about You. '},
                        {
                            tag: 'span',
                            text: 'You have the right to have any incomplete or inaccurate information We hold about You corrected.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Object to processing of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `This right exists where We are
                            relying on a legitimate interest as the legal basis for Our processing and there is something
                            about Your particular situation, which makes You want to object to our processing of Your
                            Personal Data on this ground. You also have the right to object where We are processing Your
                            Personal Data for direct marketing purposes.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request erasure of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: 'You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request the transfer of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `We will provide to You, or to a
                            third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable
                            format. Please note that this right only applies to automated information which You initially
                            provided consent for Us to use or where We used the information to perform a contract with You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Withdraw Your consent. '},
                        {
                            tag: 'span',
                            text: `You have the right to withdraw Your consent on using
                            your Personal Data. If You withdraw Your consent, We may not be able to provide You with access
                            to certain specific functionalities of the Service.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising of Your GDPR Data Protection Rights'},
            [`You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                Please note that we may ask You to verify Your identity before responding to such requests. If You
                make a request, We will try our best to respond to You as soon as possible.`],
            [`You have the right to complain to a Data Protection Authority about Our collection and use of Your
                Personal Data. For more information, if You are in the European Economic Area (EEA), please contact
                Your local data protection authority in the EEA.`],
            {tag: 'h1', text: 'CCPA Privacy'},
            [`This privacy notice section for California residents supplements the information contained in Our
                Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of
                California.`],
            {tag: 'h2', text: 'Categories of Personal Information Collected'},
            [`We collect information that identifies, relates to, describes, references, is capable of being
                associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer
                or Device. The following is a list of categories of personal information which we may collect or may
                have been collected from California residents within the last twelve (12) months.`],
            [`Please note that the categories and examples provided in the list below are those defined in the
                CCPA. This does not mean that all examples of that category of personal information were in fact
                collected by Us, but reflects our good faith belief to the best of our knowledge that some of that
                information from the applicable category may be and may have been collected. For example, certain
                categories of personal information would only be collected if You provided such personal information
                directly to Us.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Category A: Identifiers.'},
                        [`Examples: A real name, alias, postal address, unique personal identifier, online identifier,
                        Internet Protocol address, email address, account name, driver's license number, passport
                        number, or other similar identifiers.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                        },
                        [`Examples: A name, signature, Social Security number, physical characteristics or description,
                        address, telephone number, passport number, driver's license or state identification card
                        number, insurance policy number, education, employment, employment history, bank account
                        number, credit card number, debit card number, or any other financial information, medical
                        information, or health insurance information. Some personal information included in this
                        category may overlap with other categories.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category C: Protected classification characteristics under California or federal law.',
                        },
                        [`Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship,
                        religion or creed, marital status, medical condition, physical or mental disability, sex
                        (including gender, gender identity, gender expression, pregnancy or childbirth and related
                        medical conditions), sexual orientation, veteran or military status, genetic information
                        (including familial genetic information).`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category D: Commercial information.',
                        },
                        ['Examples: Records and history of products or services purchased or considered.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category E: Biometric information.',
                        },
                        [`Examples: Genetic, physiological, behavioral, and biological characteristics, or activity
                        patterns used to extract a template or other identifier or identifying information, such as,
                        fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other
                        physical patterns, and sleep, health, or exercise data.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category F: Internet or other similar network activity.',
                        },
                        ['Examples: Interaction with our Service or advertisement.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category G: Geolocation data.',
                        },
                        ['Examples: Approximate physical location.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category H: Sensory data.',
                        },
                        ['Examples: Audio, electronic, visual, thermal, olfactory, or similar information.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category I: Professional or employment-related information.',
                        },
                        ['Examples: Current or past job history or performance evaluations.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).',
                        },
                        [`Examples: Education records directly related to a student maintained by an educational
                        institution or party acting on its behalf, such as grades, transcripts, class lists, student
                        schedules, student identification codes, student financial information, or student
                        disciplinary records.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category K: Inferences drawn from other personal information.',
                        },
                        [`Examples: Profile reflecting a person's preferences, characteristics, psychological trends,
                        predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.`],
                        ['Collected: No.'],
                    ],
                ],
            },
            ['Under CCPA, personal information does not include:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Publicly available information from government records.',
                    'Deidentified or aggregated consumer information.',
                    [
                        {tag: 'span', text: 'Information excluded from the CCPA\'s scope, such as:'},
                        {
                            tag: 'ul',
                            className: 'second-nesting',
                            items: [
                                `Health or medical information covered by the Health Insurance Portability and
                                Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                                Information Act (CMIA) or clinical trial data.`,
                                `Personal Information covered by certain sector-specific privacy laws, including the Fair
                                Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial
                                Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.`,
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Sources of Personal Information'},
            ['We obtain the categories of personal information listed above from the following categories of sources:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Directly from You. '},
                        {
                            tag: 'span',
                            text: `For example, from the forms You complete on our Service,
                            preferences You express or provide through our Service, or from Your purchases on our Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Indirectly from You. '},
                        {
                            tag: 'span',
                            text: 'For example, from observing Your activity on our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Automatically from You. '},
                        {
                            tag: 'span',
                            text: 'For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'From Service Providers. '},
                        {
                            tag: 'span',
                            text: `For example, third-party vendors to provide advertising
                            on our Service, third-party vendors for payment processing, or other third-party vendors that We
                            use to provide the Service to You.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Use of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose personal information We collect for "business
            purposes" or "commercial purposes" (as defined under the CCPA), which may include the
            following examples:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'To operate our Service and provide You with our Service.',
                    `To provide You with support and to respond to Your inquiries, including to investigate and
                    address Your concerns and monitor and improve our Service.`,
                    `To fulfill or meet the reason You provided the information. For example, if You share Your
                    contact information to ask a question about our Service, We will use that personal information
                    to respond to Your inquiry. If You provide Your personal information to purchase a product or
                    service, We will use that information to process Your payment and facilitate delivery.`,
                    `To respond to law enforcement requests and as required by applicable law, court order, or
                    governmental regulations.`,
                    `As described to You when collecting Your personal information or as otherwise set forth in the
                    CCPA.`,
                    'For internal administrative and auditing purposes.',
                    `To detect security incidents and protect against malicious, deceptive, fraudulent or illegal
                    activity, including, when necessary, to prosecute those responsible for such activities.`,
                ],
            },
            [`Please note that the examples provided above are illustrative and not intended to be exhaustive. For
            more details on how we use this information, please refer to the "Use of Your Personal
            Data" section.`],
            [`If We decide to collect additional categories of personal information or use the personal information
            We collected for materially different, unrelated, or incompatible purposes We will update this
            Privacy Policy.`],
            {tag: 'h2', text: 'Disclosure of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose and may have used or disclosed in the last twelve (12) months the following
            categories of personal information for business or commercial purposes:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    `Category B: Personal information categories listed in the California Customer Records statute
                    (Cal. Civ. Code § 1798.80(e)).`,
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            [`Please note that the categories listed above are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact disclosed, but reflects our good
            faith belief to the best of our knowledge that some of that information from the applicable category
            may be and may have been disclosed.`],
            [`When We disclose personal information for a business purpose or a commercial purpose, We enter a
            contract that describes the purpose and requires the recipient to both keep that personal
            information confidential and not use it for any purpose except performing the contract.`],
            {tag: 'h2', text: 'Sale of Personal Information'},
            [`As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing,
            disclosing, disseminating, making available, transferring, or otherwise communicating orally, in
            writing, or by electronic or other means, a consumer's personal information by the business to a
            third party for valuable consideration. This means that We may have received some kind of benefit in
            return for sharing personal information, but not necessarily a monetary benefit.`],
            [`Please note that the categories listed below are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact sold, but reflects our good faith
            belief to the best of our knowledge that some of that information from the applicable category may
            be and may have been shared for value in return.`],
            ['We may sell and may have sold in the last twelve (12) months the following categories of personal information:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            {tag: 'h2', text: 'Share of Personal Information'},
            ['We may share Your personal information identified in the above categories with the following categories of third parties:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Service Providers.',
                    'Payment processors.',
                    'Our affiliates.',
                    'Our business partners.',
                    'Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You.',
                ],
            },
            {tag: 'h2', text: 'Sale of Personal Information of Minors Under 16 Years of Age'},
            [`We do not knowingly collect personal information from minors under the age of 16 through our Service,
                although certain third party websites that we link to may do so. These third-party websites have
                their own terms of use and privacy policies and we encourage parents and legal guardians to monitor
                their children's Internet usage and instruct their children to never provide information on other
                websites without their permission.`],
            [`We do not sell the personal information of Consumers We actually know are less than 16 years of age,
                unless We receive affirmative authorization (the "right to opt-in") from either the
                Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13
                years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales
                at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a
                request to Us by contacting Us.`],
            [`If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal
                information, please contact Us with sufficient detail to enable Us to delete that information.`],
            {tag: 'h2', text: 'Your Rights under the CCPA'},
            [`The CCPA provides California residents with specific rights regarding their personal information. If
                You are a resident of California, You have the following rights:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'The right to notice. '},
                        {
                            tag: 'span',
                            text: `You have the right to be notified which categories of
                            Personal Data are being collected and the purposes for which the Personal Data is being used.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to request. '},
                        {
                            tag: 'span',
                            text: `Under CCPA, You have the right to request that We
                            disclose information to You about Our collection, use, sale, disclosure for business purposes
                            and share of personal information. Once We receive and confirm Your request, We will disclose to
                            You:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'The categories of personal information We collected about You.',
                                'The categories of sources for the personal information We collected about You.',
                                'Our business or commercial purpose for collecting or selling that personal information.',
                                'The categories of third parties with whom We share that personal information.',
                                'The specific pieces of personal information We collected about You.',
                                [
                                    {
                                        tag: 'span',
                                        text: 'If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:',
                                    },
                                    {
                                        tag: 'ul', className: 'third-nesting', items: [
                                            'The categories of personal information categories sold.',
                                            'The categories of personal information categories disclosed.',
                                        ],
                                    },
                                ],
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to say no to the sale of Personal Data (opt-out). '},
                        {
                            tag: 'span',
                            text: `You have the right to direct Us to not sell Your personal information. 
                            To submit an opt-out request please contact Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to delete Personal Data. '},
                        {
                            tag: 'span',
                            text: `You have the right to request the deletion
                            of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request,
                            We will delete (and direct Our Service Providers to delete) Your personal information from our
                            records, unless an exception applies. We may deny Your deletion request if retaining the
                            information is necessary for Us or Our Service Providers to:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                `Complete the transaction for which We collected the personal information, provide a good
                                or service that You requested, take actions reasonably anticipated within the context of
                                our ongoing business relationship with You, or otherwise perform our contract with You.`,
                                `Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
                                activity, or prosecute those responsible for such activities.`,
                                `Debug products to identify and repair errors that impair existing intended
                                functionality.`,
                                `Exercise free speech, ensure the right of another consumer to exercise their free speech
                                rights, or exercise another right provided for by law.`,
                                `Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546
                                et. seq.).`,
                                `Engage in public or peer-reviewed scientific, historical, or statistical research in the
                                public interest that adheres to all other applicable ethics and privacy laws, when the
                                information's deletion may likely render impossible or seriously impair the research's
                                achievement, if You previously provided informed consent.`,
                                `Enable solely internal uses that are reasonably aligned with consumer expectations based
                                on Your relationship with Us.`,
                                'Comply with a legal obligation.',
                                `Make other internal and lawful uses of that information that are compatible with the
                                context in which You provided it.`,
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right not to be discriminated against. '},
                        {
                            tag: 'span',
                            text: `You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'Denying goods or services to You.',
                                `Charging different prices or rates for goods or services, including the use of discounts
                                or other benefits or imposing penalties.`,
                                'Providing a different level or quality of goods or services to You.',
                                'Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.',
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising Your CCPA Data Protection Rights'},
            ['In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
            [`Only You, or a person registered with the California Secretary of State that You authorize to act on
                Your behalf, may make a verifiable request related to Your personal information.`],
            ['Your request to Us must:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `Provide sufficient information that allows Us to reasonably verify You are the person about whom
                    We collected personal information or an authorized representative.`,
                    `Describe Your request with sufficient detail that allows Us to properly understand, evaluate,
                    and respond to it.`,
                ],
            },
            ['We cannot respond to Your request or provide You with the required information if we cannot:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Verify Your identity or authority to make the request.',
                    'And confirm that the personal information relates to You.',
                ],
            },
            [`We will disclose and deliver the required information free of charge within 45 days of receiving Your
                verifiable request. The time period to provide the required information may be extended once by an
                additional 45 days when reasonably necessary and with prior notice.`],
            [`Any disclosures We provide will only cover the 12-month period preceding the verifiable request's
                receipt.`],
            [`For data portability requests, We will select a format to provide Your personal information that is
                readily usable and should allow You to transmit the information from one entity to another entity
                without hindrance.`],
            {tag: 'h2', text: 'Do Not Sell My Personal Information'},
            [`You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a
                verifiable consumer request from You, we will stop selling Your personal information. To exercise
                Your right to opt-out, please contact Us.`],
            {
                tag: 'h1',
                text: '"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)',
            },
            ['Our Service does not respond to Do Not Track signals.'],
            [`However, some third party websites do keep track of Your browsing activities. If You are visiting
                such websites, You can set Your preferences in Your web browser to inform websites that You do not
                want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of
                Your web browser.`],
            {tag: 'h1', text: 'Children\'s Privacy'},
            [`Our Service does not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from anyone under the age of 13. If You are a parent or guardian and You
                are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware
                that We have collected Personal Data from anyone under the age of 13 without verification of
                parental consent, We take steps to remove that information from Our servers.`],
            [`If We need to rely on consent as a legal basis for processing Your information and Your country
                requires consent from a parent, We may require Your parent's consent before We collect and use that
                information.`],
            {tag: 'h1', text: 'Your California Privacy Rights (California\'s Shine the Light law)'},
            [`Under California Civil Code Section 1798 (California's Shine the Light law), California residents
                with an established business relationship with us can request information once a year about sharing
                their Personal Data with third parties for the third parties' direct marketing purposes.`],
            [`If you'd like to request more information under the California Shine the Light law, and if You are a
                California resident, You can contact Us using the contact information provided below.`],
            {
                tag: 'h1',
                text: 'California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)',
            },
            [`California Business and Professions Code Section 22581 allows California residents under the age of
                18 who are registered users of online sites, services or applications to request and obtain removal
                of content or information they have publicly posted.`],
            [`To request removal of such data, and if You are a California resident, You can contact Us using the
                contact information provided below, and include the email address associated with Your account.`],
            [`Be aware that Your request does not guarantee complete or comprehensive removal of content or
                information posted online and that the law may not permit or require removal in certain
                circumstances.`],
            {tag: 'h1', text: 'Links to Other Websites'},
            [`Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit.`],
            [`We have no control over and assume no responsibility for the content, privacy policies or practices
                of any third party sites or services.`],
            {tag: 'h1', text: 'Changes to this Privacy Policy'},
            [`We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the
                new Privacy Policy on this page.`],
            [`We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the "Last updated" date at the top of this Privacy Policy.`],
            [`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.`],
            {tag: 'h1', text: 'Contact Us'},
            ['If you have any questions about this Privacy Policy, You can contact us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
        ],
    },

    invitation: {
        subtitle: 'Пожалуйста, заполните форму ниже',
        user_section_title: 'Информация о пользователе',
        organization_section_title: 'Информация о компании',
        button: {
            cancel: 'Отменить',
            confirm: 'Подтвердить',
        },
    },

    password_requirements: {
        text: 'Пароль должен:',
        list: [
            'быть от 8 до 255 символов.',
            'состоять только из латинских букв.',
            'содержать хотя бы одну заглавную букву.',
            // 'содержать хотя бы одну цифру.',
            // 'содержать хотя бы один специальный символ (! ? @ # $ % & [ ]).',
        ],
    },

    authorization: {
        request_access_text: 'Войдите или укажите свои контактные данные ниже, чтобы узнать, как вы можете получить доступ к платформе',

        sign_in: {
            welcome_title: 'Добро пожаловать в Exportery',
            description: [
                'Промышленная торговая e-платформа',
                'Независимая техническая инспекция',
                'Решения по торговому финансированию на базе блокчейна',
            ],
            title: 'Войти',
            two_factor_title: 'Двухфакторная аутентификация',
            reset_password_title: 'Сброс пароля',
            email_placeholder: 'Имя пользователя',
            password_placeholder: 'Пароль',
            two_factor_code_placeholder: 'Код',
            request_access_question: 'Нет учетной записи?',
            required_field_error_message: 'Обязательное поле',
            two_factor_text: 'Пожалуйста, введите код, который был отправлен на вашу электронную почту',
            forgot_password_text: 'Пожалуйста, введите адрес электронной почты, и мы пришлем вам ссылку',
            security_code: {
                text: 'Please, enter code from your security token',
                placeholder: 'Security code',
                required_field_error_message: 'Обязательное поле',
            },
            reset_password: {
                subtitle: 'Пожалуйста, заполните форму ниже',
                button: {
                    cancel: 'Отменить',
                    confirm: 'Подтвердить',
                },
            },
            button: {
                request_access: 'Запрос доступа',
                sign_in: 'Войти',
                confirm: 'Подтвердить',
                reset: 'Сбросить',
            },
        },

        sign_up: {
            welcome_title: 'Добро пожаловать в Exportery',
            description: [
                'Пожалуйста, используйте регистрационную форму ниже, чтобы получить доступ к промышленной электронной торговой площадке в режиме просмотра. Мы предоставим полный доступ для вашей организации после завершения процесса валидации при поддержке нашей службы поддержки клиентов.',
                'Мы надеемся, что вам понравится простота, прозрачность нашей платформы, а также разработанная нами система управления документооборотом, которая переводит международные закупки промышленных товаров и услуг на новый уровень автоматизации.',
            ],
            title: 'Зарегистрироваться',
            first_name_placeholder: 'Имя',
            last_name_placeholder: 'Фамилия',
            email_placeholder: 'E-mail',
            organization_name_placeholder: 'Организация',
            country_placeholder: 'Страна',
            telephone_placeholder: 'Телефон',
            email_field_error_message: 'Электронная почта неверна',
            required_field_error_message: 'Обязательное поле',
            tick_the_areas_label: 'Отметьте, пожалуйста, интересующие вас разделы:',
            role: ['Моя роль:', 'Импортер', 'Экспортер'],
            interest_status: ['Членство в электронной торговой площадке', 'Независимая инспекция', 'Торговые услуги'],
            privacy_policy_text: ['Установив этот флажок и нажав "Подать", я соглашаюсь на обработку в соответствии с этой формой и ', 'политикой конфиденциальности', '.'],
            button: {
                submit: 'Подать',
                request_access_btn: 'Запрос доступа',
            },
        },
    },

    marketplace: {
        home: {
            all_products_title: 'все товары',
        },

        category_page: {
            empty_category_message: 'Мы активно работаем над созданием базы!',
        },

        product_card: {
            available_status_sold: {value: 'sold', label: 'продан'},
            button: {
                view_product: 'просмотреть товар',
            },
        },

        product_panel: {
            header: {
                item_label: 'Изделие',
                condition_label: 'Cостояние',
                price_label: 'Цена',
            },
            delivery_time_label: 'Срок поставки',
            exporter_label: 'Экспортер',
            supply_conditions_label: 'Условия поставки',
            button: {
                view_product: 'просмотреть товар',
            },
        },

        product_page: {
            image_alt: 'Изображение продукта',
            currency_array: ['USD', 'EUR', 'RUB', 'AED', 'CNY'],
            price_label: '/ Штука',
            offers_from_text: 'из',
            message_about_not_available_currency: 'Пожалуйста выберите другую валюту',
            main_technical_data_label: ['Наименование параметра', 'Значение'],
            details_accordion_label: 'Подробности',
            interchangeability_text: 'полностью взаимозаменяем с:',
            certification_title: 'Сертификаты',
            attachment_accordion_label: 'Руководства по продукту и документы',
            available_currency_table_labels: ['Валюта', 'Обменный курс USD'],
            button: {
                add_to_quotation: 'Добавить в заявку',
                added_to_quotation: 'Добавлено в заявку',
                edit_product: 'Редактировать',
            },
        },

        quotation: {
            other_offers_title: 'Другие предложения',
            excel_upload_form: {
                excel_upload_form_text: 'Загрузите файл Excel и заполните форму, чтобы получить мгновенное предложение',
                follow_format_tip: ['Чтобы загрузить Excel,', 'следуйте формату'],
                attach_xls_label: 'Вложите xls',
                button: {
                    cancel: 'Отменить',
                    upload: 'Загрузить',
                    confirm_quotation: 'Подтвердить заявку',
                },
            },

            request_for_supply: {
                title: 'Заявка на поставку',
                find_other_offers_text: 'Найти другие предложения',
                price_label: '/ Штука',
            },

            other_offers_block: {
                other_offers_title: 'Другие предложения',
                replace_message: 'Вы уверены, что хотите заменить товар?',
                offer: {
                    condition_label: 'Cостояние',
                    delivery_time_label: 'Срок поставки',
                    delivery_time_value: '60 дней',
                    exporter_label: 'Экспортер',
                    supply_conditions_label: 'Условия поставки',
                },
                button: {
                    replace: 'Заменить',
                },
            },

            order_summary_block: {
                title: 'Итог заказа',
                button: {
                    confirm_quotation: 'подтвердить заявку',
                },
            },

            empty_request_list: {
                text: 'Товаров пока нет',
            },

            product_image_alt: 'Product image',

            button: {
                add: 'Добавить товар',
                upload: 'Загрузить xls',
                delete_btn: 'Удалить',
                clear_all: 'Очистить все',
            },
        },

        search_page: {
            title: 'Результаты поиска',
        },
    },

    activity_hub: {
        pages: ['Заявки', 'E-Контракты'],
        requests_for_quotations: {
            title: 'Заявки и контракты',
            rfq_price_tooltip: 'Обратите внимание, что указанная заявка  содержит одину или несколько суб-заявок, деноминированных в разных валютах других чем Доллар США. Курс обмена фиксируется в течение валидности действия заявки.',
            empty_text: 'У вас нет заявок',
            table_labels: ['№', 'Тип', 'Дата', 'Валиден до', 'Осталось дней', 'Итого', 'Статус'],
            table_row_type_column: {label: 'Тип', value: 'Поставка'},
            status_row_label: 'Статус',
            create_contract_question: 'Вы уверены, что хотите создать контракт?',
        },
        e_contracts: {
            title: 'Заявки и контракты',
            empty_text: 'Электронных контрактов пока нет',
            table_labels_exporter: ['№', 'Тип', 'Дата', 'Импортер', 'Валиден до', 'Итого', 'Условия оплаты', 'Статус'],
            table_labels_importer: ['№', 'Тип', 'Дата', 'Экспортер', 'Валиден до', 'Итого', 'Условия оплаты', 'Статус'],
            status_row_label: 'Статус',
            request_question: 'Вы действительно хотите это сделать?',
            pages: {
                contract_for_supply: {
                    title: 'Контракт на поставку',
                    status_label: 'Статус',
                    total_label: 'Итого',
                    contract_information_label: 'Информация о контракте',
                    information_table_labels: ['Документ', 'Дата', 'Статус', 'До истечения, дней', 'Действие'],
                    table_labels: ['Документ', 'Дата', 'Статус', 'Действие'],
                    condition_page: {
                        condition_table_labels: {name: 'Заголовок', value: 'Информация'},
                        table_labels: {
                            index_number: '№',
                            part_number: 'Чертежный номер',
                            name: 'Наименование',
                            quantity: 'Кол-во',
                            doq: 'Ед. изм',
                            price: 'Цена,',
                            total: 'Сумма,',
                        },
                        total_label: 'Итого',
                        button: {
                            cancel: 'Отменить',
                            review: 'Предварительный просмотр',
                            open_dispute: 'Диспутировать',
                        },
                    },
                },
                origin_invoice: {
                    title: 'Конструктор отгрузочного инвойса',
                    message: 'Пожалуйста, выберите товары и их количество, которое вы хотите отправить',
                    table_labels: ['№', 'Чертежный номер', 'Наименование', 'Количество', 'Цена', 'Сумма'],
                    total_label: 'Итого',
                    button: {
                        save: 'Сохранить',
                        cancel: 'Отменить',
                    },
                },
                packing_list_wizard: {
                    title: 'Конструктор упаковочного листа',
                    delete_place_question: 'Вы уверены, что хотите удалить место?',
                    pages: {
                        shipment_lot: {
                            place_label: 'Создать место',
                            packed_status: 'Упаковано',
                            empty_message: 'Неупакованного товара пока нет',
                            select_data: {
                                label: 'Выберите метод',
                                default_item_label: '- Выбрать -',
                                tooltip: '',
                            },
                            button: {
                                add_serial_number: 'Добавить с/н',
                                remove_serial_number: 'Удалить s/n',
                                add_place: 'Добавить место',
                                remove_place: 'Удалить место',
                                save: 'Сохранить',
                                cancel: 'Отменить',
                            },
                        },
                        packing_list: {
                            table_labels: ['№ Места', ['Наименование', 'Серийный номер', 'Кол-во', 'Ед. изм', 'Вес нетто за ед., кг', 'Вес брутто за ед., кг'], 'Характеристика места'],
                            empty_message: 'Упакованного товара пока нет',
                            unpack_question: 'Вы уверены, что хотите удалить это место?',
                            total_places_label: 'Всего мест',
                            total_net_weight_label: 'Общий вес нетто',
                            total_gross_weight_label: 'Общий вес брутто',
                            confirm_question: 'Вы уверены, что хотите создать упаковочный лист?',
                            warning_message: 'Пожалуйста, упакуйте все товары в вашем лоте, чтобы продолжить',
                            unpacking_tooltip: 'Распаковка',
                            button: {
                                add_place: 'Добавить место',
                                remove_place: 'Удалить место',
                                save: 'Сохранить',
                                cancel: 'Отменить',
                                create_packing_list: 'Создать упаковочный лист',
                            },
                        },
                        labelling: {
                            title: 'Маркировка',
                            checkbox_label: 'Выбрать все маркировки',
                            switcher_label: 'Печать с линией разреза',
                            row_labels: {
                                carrier: 'Перевозчик',
                                consignee: 'Грузополучатель',
                                content: 'Содержание',
                                contract: 'Контракт',
                                destination: 'Пункт назначения',
                                dims: 'Ед изм., см',
                                gross_weight: 'Вес места брутто',
                                net_weight: 'Вес места нетто',
                                packing_date: 'Дата упаковки',
                                shipper: 'Грузоотправитель',
                                waybill: 'Рейс №',
                            },
                        },
                    },
                },
                upload_document: {
                    title: 'Загрузка документа',
                    text: 'Пожалуйста, загрузите документ в формате PDF:',
                    button: {
                        cancel: 'Отменить',
                        create: 'Создать',
                        btn_text: 'или',
                    },
                },
            },
        },
        place_product: {
            warranty_title: 'Гарантия',
            from_excel: {
                text: 'Загрузите файл Excel для размещения ваших товаров:',
                button: {
                    cancel: 'Отменить',
                    confirm: 'Перейти к моим товарам',
                },
            },
            from_form: {
                title: 'Разместить товар',
                edit_title: 'Редактировать товар',
                sections: {
                    product_information: {
                        label: 'Информация о товаре',
                        sub_tooltip: [
                            null, null, null, null,
                            [
                                {
                                    title: 'Новый',
                                    text: 'Товар - Новый, последних лет производства, упакован в оригинальной упаковке производителя (если упаковка предусмотрена производителем), имеет всю сопроводительную документацию, например, оригинальные паспорта, ярлыки или формуляры. Дубликаты документов не принимаются. Изделие не имеет следов демонтажа и монтажа. Пломбы производителя не должны быть повреждены. Назначенный срок хранения должен быть действителен не менее двух месяцев до окончания срока. Гарантия производителя полная и указана в документации.',
                                },
                                {
                                    title: 'Ремонтный',
                                    text: 'Ремонтный - изделие, прошедшее капитальный ремонт на авторизованном заводе или на сертифицированных ремонтных мастерских, упакованный в тару, обеспечивающее его безопасную транспортировку любым видом транспорта, имеет всю сопроводительную документацию, например, оригиналы паспортов, этикеток, формуляров. Дубликаты документов принимаются, если их выдает уполномоченная организация по ремонту данного изделия. Изделие может иметь признаки демонтажа и монтажа на объекте, но должно иметь пломбы ремонтной организации. Назначенный срок хранения должен быть действителен не менее двух месяцев до конца указанной даты. Гарантия ремонтного предприятия отражена надлежащим образом в технической документации изделия.',
                                },
                                {
                                    title: 'Б / У',
                                    text: 'Б / У - товар бывший в эксплуатации,  который должен иметь все признаки структурной целостности, упакован в покрытие, обеспечивающее его безопасную транспортировку любым видом транспорта, имеет всю сопроводительную документацию, например, оригиналы паспортов, этикеток, формуляров.',
                                },
                                {
                                    title: 'С хранения',
                                    text: `С хранения - продукт имеет все функции, указанные в категории «Новый», но у продукта истек назначенный срок хранения и / или гарантия производителя. Пример: Гирогоризонт АГБ-3К изготовлен в 1990 г. хранился на складе, согласно всем техническим условиям, в оригинальной упаковке производителя, с истекшим сроком хранения и консервации.`,
                                },
                            ],
                            {
                                title: 'Завод капитального ремонта',
                                text: 'Пожалуйста, выберите одно из предварительно выбранных названий или введите новые.',
                            },
                            {
                                title: 'Дата капитального ремонта',
                                text: 'Укажите дату капитального ремонта, указанную в сопроводительных документах, например, в паспортах, формулярах, этикетках.',
                            },
                            {
                                title: 'Количество капитальных ремонтов',
                                text: 'Пожалуйста, укажите количество капитальных ремонтов, например, либо он является первым или вторым ремонтом в течение всего срока службы продукта.',
                            },
                            {
                                title: 'Гарантия экспортера',
                                text: 'Гарантия, предоставляемая исключительно Экспортером, предусматривает, что возможная рекламация будет выставлена Экспортеру в течение указанного срока действия.',
                            },
                            {
                                title: 'Дата упаковки',
                                text: 'Пожалуйста, укажите дату упаковки, указанную на этикетке продукта, если таковая существует.',
                            },
                            {
                                title: 'Консервация до',
                                text: 'Означает время выполнения заказа до истечения срока хранения продукта.',
                            },
                            {
                                title: 'Производитель',
                                text: 'Пожалуйста, выберите одно из предварительно выбранных названий или введите новые.',
                            },
                            {
                                title: 'Дата производства',
                                text: 'Пожалуйста, укажите дату упаковки, указанную на этикетке продукта, если таковая существует.',
                            },
                            {
                                title: 'Условия поставки',
                                text: 'Данный товар должен включать доставку в наши пункты приема в соответствии с Инкотермс 2020. В настоящее время мы работаем через грузовой терминал аэропорта Борисполь, Украина, поэтому единственным доступным способом доставки является FCA Грузовой терминал аэропорта Борисполь, Украина.',
                            },
                            null,
                            {
                                title: 'Время выполнения поставки',
                                text: 'Это означает сроки поставки в днях, с даты совершения сделки до даты, указанной в экспортной таможенной декларации в пункте отправления или любой другой транспортной накладной. Пожалуйста, внимательно оценивайте это время, чтобы избежать санкций за несвоевременную доставку.',
                            },
                            {
                                title: 'Доступное количество',
                                text: 'Пожалуйста, укажите доступное количество, которое вы можете поставить в течение действующего срока поставки.',
                            },
                            {
                                title: 'Минимальный заказ',
                                text: 'Минимально возможное количество вашего товара для отгрузки на сервисную точку',
                            },
                            {
                                title: '«Обозначение количества»',
                                text: 'Выберите единицу измерения одну штуку (шт.) или комплект (к-т), содержащий несколько единиц товара в одном комплекте.',
                            },
                            {
                                title: 'Цена',
                                text: 'Указывается за 1 шт. или комплект (к-т).',
                            },
                            {
                                title: 'Валюта',
                                text: 'Обратите внимание, что все ваши котировки будут представлены на рынке Exportery в долларах США по умолчанию даже в случае, если вы не укажите доллар США, как возможную валюту для оплаты.',
                            },
                            {
                                title: 'Срок действия предложения',
                                text: 'Срок в днях, в течение которого ваше предложение будет действовать на торговой площадке в соответствии с указанными параметрами, включая цену. В течение этого срока вы не можете изменить основные условия этого блока.',
                            },
                        ],
                    },
                    delivery_and_payment: {
                        label: 'Доставка и оплата',
                        subsection: {
                            delivery_payment: {
                                tooltip: [
                                    {
                                        title: 'Платежные средства',
                                        text: 'В настоящее время мы предоставляем способ оплаты - оплату по гарантированной складской квитанции условного депонирования, подтверждающей прибытие товаров на наш транзитный таможенный склад в грузовом терминале аэропорта Абу-Даби. Этот способ оплаты прост: как только ваши товары прибудут в Абу-Даби, вам будет произведена оплата согласно условий Контракта.',
                                    },
                                    {
                                        title: 'Валюта',
                                        text: 'Вы можете отметить валюту, в которой вы можете продавать свой продукт на Exportery. Обратите внимание, что вы можете выбрать столько валют, сколько хотите, но расчет доступен только в одной валюте, когда ваш продукт добавлен в котировку Импортером.  Пример: вы указали все свои продукты на рынке в юанях и евро. Когда ваша сторона по договору выбирает один из ваших продуктов в китайских юанях, другие продукты могут быть проданы только в китайских юанях.',
                                    },
                                ],
                            },
                            regulatory_framework: {
                                title: 'Нормативно-правовая база',
                                tooltip: [
                                    {
                                        title: 'Требуется от импортера',
                                        text: 'Пожалуйста, оцените и выберите обязательные документы, которые вам должен будет предоставить Импортер (Покупатель) для подачи в  органы экспортного контроля вашей стране для таможенного оформления экспорта на ваш товар:\n' +
                                            '\n' +
                                            'Доступны три варианта для вашего выбора:\n' +
                                            ' - Сертификат конечного пользователя от Министерства обороны страны назначения;\n' +
                                            ' - Сертификат конечного пользователя от гражданского авиаперевозчика;\n' +
                                            ' - Сертификаты конечного пользователя не требуются для экспорта товара.',
                                    },
                                ],
                            },
                            shipment_conditions: {
                                title: 'Условия отгрузки',
                                tooltip: [
                                    {
                                        title: 'Режим отгрузки',
                                        text: 'Пожалуйста, выберите указанный способ доставки, которым вы хотите отправить свой товар.',
                                    },
                                ],
                            },
                            packaging: {
                                title: 'Упаковка',
                                tooltip: [
                                    null, null,
                                    {
                                        title: 'Тип упаковки',
                                        text: 'Пожалуйста, выберите материал упаковки',
                                    },
                                    null, null, null,
                                    {
                                        title: 'Единицы измерения',
                                        text: 'Пожалуйста, проверьте единицы измерения вашего товара',
                                    },
                                ],
                            },
                            dangerous_goods_information: {
                                title: 'Информация о опасных грузах',
                                tooltip: {
                                    title: 'Информация об опасных грузах',
                                    text: 'Пожалуйста, заполните этот раздел внимательно и проверьте сертификацию опасных грузов для ваших товаров или свяжитесь с нашим представителем службы поддержки клиентов.',
                                },
                            },
                        },
                    },
                    photo: {
                        label: 'Фото',
                        same_name_message: ['Фотографии с этими именами уже добавлены:', 'Пожалуйста, переименуйте их или добавьте другие!'],
                        quantity_message: ['Максимальное количество', 'шт.', 'Из них', 'уже добавлены!'],
                        size_message: ['Размер фото больше', 'Мб:'],
                        invalid_types_label: 'Недействительный тип файлов:',
                        rejected_files_label: 'Отклоненные файлы',
                        alt_label: 'Image alt',
                        tooltip: {
                            title: 'Фото',
                            text: 'Пожалуйста, загрузите фотографии вашего товара, максимальное количество 10 штук. Платформа может принимать фотографии в следующих форматах: .jpeg, .jpg, .png размером не более 10 Мб каждый.',
                        },
                    },
                    main_technical_data: {
                        label: 'Основные технические данные',
                        tooltip: {
                            title: 'Основные технические данные',
                            text: 'Пожалуйста, введите имя и значение параметра ячеек, если вы хотите загрузить техническое содержание вашего продукта в виде упорядоченной таблицы. Вы можете загрузить свой контент как обычный текст через место ввода текстовой области.',
                        },
                        button: {
                            add: 'Добавить позицию',
                            remove: 'Удалить позицию',
                        },
                    },
                    resources: {
                        label: 'Ресурсы',
                        warranty_title: 'Гарантия',
                        tooltip: {
                            title: 'Ресурсы',
                            text: 'Заполните в ячейках ресурсов актуальные данные, которые отражены в технической документации на продукт. Заполните сроки гарантии производителя или организации выполнившей капитальный ремонт изделия.',
                        },
                    },
                    supply_set: {
                        label: 'Комплект поставки',
                        tooltip: {
                            title: 'Комплект поставки',
                            text: 'Пожалуйста, укажите каждый номер детали, который представляет собой комплект поставки для конкретного продукта. Вы можете выбрать номер детали для каждой позиции из нашей обширной базы данных (в этом случае отметьте «выбрать из продуктов»).',
                        },
                    },
                    certification: {
                        label: 'Сертификация',
                        same_name_message: ['Файлы с такими именами уже добавлены:', 'Пожалуйста, переименуйте их или добавьте другие!'],
                        quantity_message: ['Максимальное количество', 'файлов для каждого типа.', 'Из них', 'уже добавлены к этому типу сертификата!'],
                        tooltip: {
                            title: 'Сертификация',
                            text: 'Пожалуйста, загрузите сертификаты на ваш продукт, максимальное количество 10 файлов. Платформа может принимать файлы в следующих форматах: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg размером не более 10 Мб каждый.',
                        },
                    },
                    product_guides_and_documents: {
                        label: 'Руководства по товарам и документы',
                        same_name_message: ['Файлы с такими именами уже добавлены:', 'Пожалуйста, переименуйте их или добавьте другие!'],
                        quantity_message: ['Максимальное количество', 'файлов.', 'Из них', 'уже добавлены!'],
                        tooltip: {
                            title: 'Руководства по изделиям и документы',
                            text: 'Пожалуйста, загрузите руководства на продукты и документы на ваши товары, максимальное количество 10 файлов. Платформа может принимать файлы в следующих форматах: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg размером не более 10 Мб каждый.',
                        },
                    },
                    shipment_guides_and_documents: {
                        label: 'Руководства по отгрузке и документы',
                        same_name_message: ['Файлы с такими именами уже добавлены:', 'Пожалуйста, переименуйте их или добавьте другие!'],
                        quantity_message: ['Максимальное количество', 'файлов.', 'Из них', 'уже добавлены!'],
                        tooltip: {
                            title: 'Инструкции по отгрузке и документы',
                            text: 'Пожалуйста, загрузите руководства по транспортировке вашего товара и супутствующие документы, максимальное количество 10 файлов. Платформа может принимать файлы в следующих форматах: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg размером не более 10 Мб каждый.',
                        },
                    },
                },
                required_message: ['Поля выделеные', '*', 'необходимо заполнить'],
                required_field_error_message: 'Пожалуйста, заполните все обязательные поля',
                no_documents_text: 'Нет документов для отображения',
                button: {
                    skip: 'Пропустить',
                    save: 'Сохранить',
                    cancel: 'Отменить',
                    place_product: 'Разместить товар',
                    edit_product: 'Обновить товар',
                    clear_form: 'Очистить форму',
                },
            },
        },
        my_products: {
            title: 'Мои товары',
            device_info_message: ['Пожалуйста, обновите свой профиль и используйте настольное устройство для создания или редактирования товаров'],
            product_panel: {
                header: {
                    id_label: 'Id',
                    item_label: 'Изделие',
                    condition_label: 'Cостояние',
                    price_label: 'Цена',
                },
                views_label: 'Просмотры',
            },
            product_card: {
                id_label: 'ID',
                views: 'Просмотры',
            },
            empty_my_products_message: 'Вы еще ничего не добавили',
            delete_offer_question: 'Вы уверены, что хотите удалить это предложение?',
            button: {
                upload_xls: 'загрузить xls',
                upload_from_form: 'заполните форму',
            },
        },
        profile: {
            title: 'Профиль',
            pages: {
                payment_setup: {
                    bank_accounts_title: 'Банковские счета',
                    no_accounts_message: 'Счета не созданы',
                    default_account: 'Счет по умолчанию',
                    set_as_default: 'Установить по умолчанию',
                    pg_draft: [
                        ['PG DRAFT SWIFT MT', '(NARRATIVE FIELD 79)', 'THE BANK GUARANTEE NO', 'DD', 'WAS ISSUED BY RAS BANK, UAE BY THE ORDER OF EXPORTERY LTD, ABU DHABI, UAE, ID CODE 01001), IN FAVOUR OF', 'FOR AMOUNT', 'WITH THE FOLLOWING CONTENT:'],
                        ['DATE:'],
                        ['CONTRACT NUMBER AND TITLE', ': FOR THE SUPPLY OF SPARE PARTS TO'],
                        ['BANK\'S BRANCH OR OFFICE:'],
                        ['BENEFICIARY:'],
                        ['WE HAVE BEEN INFORMED THAT EXPORTERY LTD  (HEREINAFTER CALLED THE “SELLER”) HAS ENTERED INTO CONTRACT NO', 'DD', 'WITH YOU, FOR THE SUPPLY OF SPARE PARTS TO', '(HEREINAFTER CALLED \'THE CONTRACT\'). FURTHERMORE, WE UNDERSTAND THAT, ACCORDING TO THE CONDITIONS OF THE CONTRACT, A PERFORMANCE GUARANTEE IS REQUIRED.'],
                        ['AT THE REQUEST OF THE SUPPLIER, WE HEREBY IRREVOCABLY UNDERTAKE TO PAY YOU ANY SUM(S) NOT EXCEEDING', 'UPON RECEIPT BY US OF YOUR FIRST DEMAND IN WRITING DECLARING THE SUPPLIER TO BE IN DEFAULT UNDER THE CONTRACT, WITHOUT CAVIL OR ARGUMENT, OR YOUR NEEDING TO PROVE OR TO SHOW GROUNDS OR REASONS FOR YOUR DEMAND OR THE SUM SPECIFIED THEREIN.'],
                        ['THIS GUARANTEE SHALL ENTER INTO FORCE ON THE TERMS SET OUT IN THE CONTRACT OF BANK GUARANTEE NO', 'OF', 'AND WILL BE VALID UNTIL THE'],
                        ['THIS GUARANTEE SHALL EXPIRE, NO LATER THAN THE OF', 'AND ANY DEMAND FOR PAYMENT UNDER IT MUST BE RECEIVED BY US AT THIS OFFICE ON OR BEFORE THAT DATE. HOWEVER, BEFORE THAT EXPIRATION DATE, IF THE PLANNED CONTRACT EXECUTION PERIOD HAS BEEN DELAYED OR EXTENDED, OR ITS VALUE INCREASED, THE EXPORTER SHALL RESPECTIVELY EXTEND THE VALIDITY PERIOD OF THIS PERFORMANCE SECURITY OR INCREASE ITS AMOUNT ACCORDINGLY.'],
                        ['THIS GUARANTEE IS SUBJECT TO THE UNIFORM RULES FOR DEMAND GUARANTEES, ICC PUBLICATION NO. 458, EXCEPT THAT SUBPARAGRAPH (II) OF SUB-ARTICLE 19(A) IS HEREBY EXCLUDED.'],
                        ['THANKS FOR COOPERATION,'],
                        ['BEST REGARDS,'],
                    ],
                    button: {
                        add_account: 'Добавить банковский счет',
                    },
                },
                shipment_setup: {
                    default_label: 'По умолчанию',
                    set_as_default: 'Установить по умолчанию',
                    no_items_message: 'Здесь пока ничего нет',
                    button: {
                        consignee: 'Добавить грузополучателя',
                        notifyparty: 'Добавить уведомляемую сторону',
                        shipper: 'Добавить грузоотправителя',
                        importerforwarder: 'Добавить экспедитора',
                        exporterforwarder: 'Добавить экспедитора',
                    },
                },
                shipment_organization: {
                    create_consignee_title: 'Создать реквизиты получателя',
                    create_notify_title: 'Создать реквизиты уведомляемой стороны',
                    create_forwarder: 'Создать экспедитора',
                    create_shipper_title: 'Создать грузоотправителя',
                    edit_consignee_title: 'Изменить реквизиты получателя',
                    edit_notify_title: 'Изменить уведомляемую сторону',
                    edit_forwarder: 'Изменить экспедитора',
                    edit_shipper_title: 'Изменить грузоотправителя',
                    default_change_question: 'Вы уверены, что хотите изменить элемент по умолчанию?',
                },
                bank_account: {
                    create_account_title: 'Создать банковский счет',
                    edit_account_title: 'Изменить банковский счет',
                    default_change_question: 'Вы уверены, что хотите изменить банковский счет по умолчанию?',
                },
                access_setup: {
                    title: 'Настройка доступа',
                },
            },
            button: {
                access_setup: 'Настройка доступа',
                save: 'Сохранить',
                cancel: 'Отменить',
            },
        },
        notifications: {
            title: 'Уведомления',
            filters: ['Все', 'Непрочитанные'],
            no_notifications_message: {
                all: 'У вас пока нет уведомлений',
                unread: 'У вас пока нет непрочитанных уведомлений',
            },
            delete_current_notification_question: 'Вы уверены, что хотите удалить это уведомление?',
            delete_question: 'Вы уверены, что хотите удалить выбранные уведомления?',
            button: {
                select: 'Выбрать',
                cancel: 'Отменить',
                delete_btn: 'Удалить',
                show_more: 'Показать еще',
            },
        },
        upload_documents: {
            same_name_message: ['Файлы с такими именами уже добавлены:', 'Пожалуйста, переименуйте их или добавьте другие!'],
            quantity_message: ['Максимальное количество', 'файлов.', 'Из них', 'уже добавлены!'],
        },
    },

    hub_menu: {
        link_labels: {
            quotation: 'Заявка',
            quotation_request_for_supply: 'Заявка на поставку',
            quotation_request_for_overhaul: 'Заявка на капитальный ремонт',
            activity_hub: 'Заявки и контракты',
            my_products: 'Мои товары',
            notifications: 'Уведомления',
            profile: 'Профиль',
            log_out: 'Выйти',
        },
        user_name: 'Пользователь',
        profile_img_alt: 'Profile icon',
    },

    search: {
        input_placeholder: 'Поиск',
        result_titles: ['Платформа', 'Раздел'],
    },

    header: {
        button_menu: {
            importer: {
                btn_label: 'Заявка',
                menu: [
                    {
                        label: 'Заявка на поставку',
                        href: '/marketplace/quotation',
                        isDisabled: false,
                    },
                    {
                        label: 'Заявка на капитальный ремонт',
                        isDisabled: true,
                    },
                ],
            },
            exporter: {
                btn_label: 'Разместить товар',
                menu: [
                    {
                        label: 'Пакетная загрузка',
                        href: hubURLs.products_excel,
                    },
                    {
                        label: 'Ручная загрузка',
                        href: hubURLs.product_create_manual,
                    },
                ],
            },
        },
        trial_identifier: {
            label: 'Пробный режим',
            tooltip: {
                text: `Пожалуйста, заполните данные профиля или свяжитесь со службой поддержки, 
                чтобы получить полный доступ.`,
            },
        },
        button: {
            sign_in: 'Войти',
        },
    },

    footer: {
        text: 'EXPORTERY — это безопасная промышленная электронная платформа, охватывающая глобальный рынок запасных частей и компонентов для конечных пользователей и экспортеров в авиационной и наземной транспортной отрасли, оснащенная запатентованной системой электронного документооборота, которая поднимает эффективность внешнеэкономической деятельности на качественно новый уровень.',
        navigation_links: [
            {
                id: 'contact_us',
                title: 'Свяжитесь с нами',
                url: '/contact_us',
            },
            {
                list: [
                    {
                        id: 'about-marketplace',
                        title: 'Как работает Exportery',
                        url: staticURLs.about_marketplace,
                    },
                    {
                        id: 'about-independent-technical-surveying',
                        title: 'Независимая инспекция',
                        url: staticURLs.about_independent_technical_surveying,
                    },
                    {
                        id: 'about-core-services',
                        title: 'Основные услуги',
                        url: staticURLs.about_core_services,
                    },
                    {
                        id: 'news',
                        title: 'Новости',
                        url: URLs.news,
                    },
                    {
                        id: 'terms_and_conditions',
                        title: 'Условия и положения',
                        url: staticURLs.terms_and_conditions,
                    },
                    {
                        id: 'privacy_policy',
                        title: 'Политика конфиденциальности',
                        url: staticURLs.privacy_policy,
                    },
                ],
            },
            {},
        ],
        social_links: [
            {
                id: 'linkedin',
                url: CONTACT_DATA.linkedin,
                img: 'linkedin',
            },
            {
                id: 'facebook',
                url: CONTACT_DATA.facebook,
                img: 'facebook',
            },
            {
                id: 'twitter',
                url: CONTACT_DATA.twitter,
                img: 'twitter',
            },
        ],
        contact_address: CONTACT_DATA.address_short,
        contact_email: CONTACT_DATA.email,
        contact_phone: CONTACT_DATA.phone,
        copyright: `Copyright © ${getCurrentYear()} EXPORTERY, все права защищены.`,
    },

    filters: {
        filters_label: 'Фильтры',
        part_number_label: 'Введите номер детали',
        part_number_label_mobile: 'Чертежный номер',
        title_label: 'Раздел',
        platform_label: 'Платформа',
        organization_label: ['Экспортер', 'Импортер'],
        search_label: 'Поиск',
        button: {
            show_more: 'показать больше',
            show_less: 'показать меньше',
        },
    },

    sorting: {
        sorting_label: 'Cортировка',
        sorting_title: 'Сортировать по:',
    },

    filters_sorting_button: {
        show_result: 'показать результат',
        reset_all: 'Cбросить все',
        reset_pnum: 'Сбросить фильтр по номеру детали',
    },

    breadcrumbs: new BreadcrumbsConstructor()
        .creator({
            notifications: ['Уведомления'],
            profile: ['Профиль'],
            access_setup: ['Профиль', 'Настройка доступа'],
            bank_account_create: ['Профиль', 'Создать банковский счет'],
            bank_account_update: ['Профиль', 'Изменить банковский счет'],
            consignee_details_create: ['Профиль', 'Создать реквизиты получателя'],
            consignee_details_update: ['Профиль', 'Изменить реквизиты получателя'],
            exporter_forwarder_create: ['Профиль', 'Создать экспедитора'],
            exporter_forwarder_update: ['Профиль', 'Изменить экспедитора'],
            importer_forwarder_create: ['Профиль', 'Создать экспедитора'],
            importer_forwarder_update: ['Профиль', 'Изменить экспедитора'],
            notify_party_create: ['Профиль', 'Создать реквизиты уведомляемой стороны'],
            notify_party_update: ['Профиль', 'Изменить уведомляемую сторону'],
            shipper_create: ['Профиль', 'Создать грузоотправителя'],
            shipper_update: ['Профиль', 'Изменить грузоотправителя'],
            quotation_excel: ['Рынок', 'Запрос на поставку', 'Загрузить XLS'],
            quotation_excel_format: ['Рынок', 'Запрос на поставку', 'Загрузить XLS', 'Формат'],
            independent_technical_surveying: ['Независимая инспекция'],
            core_services: ['Основные услуги'],
            terms_and_conditions: ['Условия и положения'],
            privacy_policy: ['Политика конфиденциальности'],
            request_access_page: ['Запрос доступа'],
            not_found_page: ['Страница не найдена'],
            not_acceptable_page: ['Страница неприемлема'],
            internal_server_error_page: ['Внутренняя ошибка сервера'],
            contract_for_supply_invoice: ['Создание отгрузочного инвойса'],
            contract_for_supply_packing_list: ['Мастер упаковочного листа'],
            contract_for_supply_labelling: ['Маркировка'],
            document_page: ['Документ'],
        }, 'Главная', session),

    error_page: {
        title: 'Извините',
        route_not_found: 'страница не найдена',
        service_under_development: 'страница в разработке',
        internal_server_error: [
            'Что ж, это неожиданно…',
            'Код ошибки: 500',
            'Произошла ошибка, и мы работаем над ее устранением.',
        ],
        button: {
            go_back: 'Вернуться',
            go_to_homepage: 'Вернуться на главную страницу',
        },
    },

    alert_modal: {
        delete_item_question: 'Вы уверены, что хотите удалить элемент?',
        delete_last_item_question: 'Вы уверены, что хотите удалить последнюю позицию?',
        delete_all_items_question: 'Вы уверены, что хотите удалить все элементы?',
        clear_all_question: 'Вы уверны, что ходите удалить все?',
        clear_form_question: 'Вы уверны, что ходите очистить форму?',
        session_expired_message: 'Уважаемый пользователь, срок вашей сессии истек. Пожалуйста, войдите снова!',
        redirect_disclaimer: 'Пожалуйста, используйте настольную версию, чтобы воспользоваться всеми функциями продукта',
        permission_denied: 'Разрешение отклонено для вашего пользователя!',
        close_modal_window_question: 'Несохраненные данные будут потеряны! Вы хотите закрыть форму?',
        button: {
            ok: 'ok',
            cancel: 'отмена',
        },
    },

    item_counter: {
        empty_error_message: 'Не может быть пустым!',
        less_error_message: 'Количество не может быть меньше 1 шт',
        more_error_message: ['Количество не может быть больше', 'шт'],
    },

    dropzone: {
        text: ['Выложите файл тут или', 'пролистайте', 'Файл должен быть'],
        not_for_desktop_text: ['Browse', 'the file from your device'],
    },

    file_container: {
        error_message: 'Файл должен быть',
        button: {
            delete_btn: 'Удалить',
        },
    },

    excel_response_info: {
        row_id: 'Строка ексель',
        filters: ['Все', 'Только ошибки'],
        no_errors: 'Нет ошибок для отображения',
        button: {
            clear_all: 'Очистить все',
        },
    },

    excel_format_page: {
        quotation_text: 'Пожалуйста, придерживайтесь примера заявки в формате .xls, чтобы получить мгновенное коммерческое предложение',
        place_product_text: `Следуйте приведенному ниже примеру, чтобы разместить свои продукты на электронной торговой площадке:`,
        quotation_tip: 'Пожалуйста, скачайте образец файла .xls заявки',
        place_product_tip: 'Загрузите пример файла .xls',
        quotation_table: {
            headers: ['Чертежный номер', 'Наименование', 'Eд. изм.', 'Количество'],
            body: [
                {
                    part_number: 'LFS-PS27-450',
                    description: 'Лампа',
                    doq: 'шт.',
                    quantity: '5',
                },
                {
                    part_number: 'АGB-3К Series 3',
                    description: 'Авиагоризонт',
                    doq: 'шт.',
                    quantity: '1',
                },
                {
                    part_number: '463B',
                    description: 'Топливный насос',
                    doq: 'шт.',
                    quantity: '2',
                },
                {
                    part_number: 'RU-19А-300',
                    description: 'ВСУ',
                    doq: 'комплект',
                    quantity: '1',
                },
                {
                    part_number: 'KURS-MP70',
                    description: 'Система навигации и посадки',
                    doq: 'комплект',
                    quantity: '1',
                },
                {
                    part_number: 'GA-192T',
                    description: 'Клапан',
                    doq: 'шт.',
                    quantity: '2',
                },
                {
                    part_number: '12-SAM-28',
                    description: 'Батарея',
                    doq: 'шт.',
                    quantity: '2',
                },
                {
                    part_number: '1300x480',
                    description: 'Основная шина',
                    doq: 'шт.',
                    quantity: '2',
                },
            ],
        },
        place_product_table: {
            headers: ['Чертежный номер', 'Чертежный номер(Кириллица)', 'Наименование', 'Раздел', 'Eд. изм.', 'Количество', 'Cостояние', 'Цена, USD', 'Валюта', 'Дата изготовления/ремонта', 'Срок действия предложения, дней', 'Срок доставки, дней', 'Требуется от импортера', 'Условия поставки (Инкотермс 2020)', 'Точка поставки'],
            body: [
                {
                    part_number: 'VR-14',
                    part_number_cyrillic: 'ВР-14',
                    description: 'Редуктор',
                    section: 'Вертолеты',
                    doq: 'шт.',
                    quantity: '3',
                    condition: 'НОВЫЙ',
                    price: '700000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9800-00',
                    part_number_cyrillic: '8АТ-9800-00',
                    description: 'Фаркоп',
                    section: 'Наземное оборудование',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '2000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9803-00',
                    part_number_cyrillic: '8АТ-9803-00',
                    description: 'КОВРИК ДЛЯ РАБОТЫ ПОД ФЮЗЕЛЯЖЕМ',
                    section: 'Наземное оборудование',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '400',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9924-00',
                    part_number_cyrillic: '8АТ-9924-00',
                    description: 'СТРОП ДЛЯ ПОДЪЕМА ЗАДНЕЙ БАЛКИ',
                    section: 'Наземное оборудование',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '2100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-9923-00',
                    part_number_cyrillic: '8-9923-00',
                    description: 'МАСЛОСБОРНИК',
                    section: 'Наземное оборудование',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '300',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3901-000',
                    part_number_cyrillic: '246-3901-000',
                    description: 'Хвостовой винт',
                    section: 'Вертолеты, Транспортная авиация',
                    doq: 'комплект',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '180000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-1960-000',
                    part_number_cyrillic: '8-1960-000',
                    description: 'Автомат перекоса',
                    section: 'Вертолеты',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '80000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3922-00',
                    part_number_cyrillic: '246-3922-00',
                    description: 'Лопасти хвостового винта',
                    section: 'Вертолеты',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '60000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '610200А',
                    part_number_cyrillic: '610200А',
                    description: 'КЛАПАН ЭЛЕКТРОМАГНИТНЫЙ',
                    section: 'Вертолеты',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '1100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8AT-2710-000',
                    part_number_cyrillic: '8AT-2710-000',
                    description: 'ЛНВ',
                    section: 'Вертолеты',
                    doq: 'шт.',
                    quantity: '10',
                    condition: 'НОВЫЙ',
                    price: '190000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'документы конечного пользователя не требуются',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
            ],
        },
        button: {
            back: 'Назад',
        },
    },

    input_phone: {
        search_placeholder: 'Поиск',
        search_not_found: 'Нет записей для отображения',
    },

    input_field: {
        input_type_message: 'Пожалуйста, введите',
        input_type: {
            'int': 'положительное целое число',
            'float': 'положительное целое или дробное число',
        },
    },

    select: {
        search_field_placeholder: 'Поиск',
        search_option_not_found: 'Нет записей для отображения',
        default_selected_label: '- Выбрать -',
    },

    select_autocomplete: {
        no_options_message: 'Нет доступных вариантов',
    },

    pagination: {
        button: {
            prev: 'Пред',
            next: 'След',
        },
    },

    page_to_print: {
        title: 'Страница для печати',
        button: {
            cancel: 'Отменить',
            print: 'Печать',
        },
        print_blob_button_alt: 'Печать',
    },

    document_viewer: {
        document_not_exist: 'Нет документа для отображения!',
        document_error: 'Произошла ошибка при загрузке файла!',
        document_loading_failed: 'Не удалось загрузить файл PDF.',
        pdf_no_data: 'Не указан файл PDF.',
        page_error: 'Не удалось загрузить страницу.',
        page_no_data: 'Страница не указана.',
    },

    cookie_consent: {
        title: 'Согласие на использование файлов cookie',
        text: 'Этот веб-сайт использует файлы cookies, которые помогают веб-сайту корректно функционировать, а также отслеживать как вы взаимодействуете с нашим веб-сайтом. Для того чтобы мы предоставили лучший сервис использования сайта включите определенные файлы cookie из настроек и нажмите кнопку Принять.',
        buttons: {
            reject_all: 'Отклонить все',
            accept_all: 'Принять все',
        },
    },

    sweet_alert_static_data: {
        error_response_place_product: 'Ошибки:',
    },

    logo_alt: 'Логотип',
});

export default ru;