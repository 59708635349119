import React from 'react';
import PropTypes from 'prop-types';

import {priceHandler} from '../../../../../../../utils';

import InvoiceItem from './components/invoice-item';
import Button from '../../../../../../common/button';
import Checkbox from '../../../../../../common/checkbox';

import styles from './origin-invoice-wizard.module.scss';

const OriginInvoiceWizard = (props) => {
    const {
        staticData,
        invoiceInitialData: {list: initialList},
        invoiceData,
        selectAllInvoiceItems,
        isCanBeSaved,
        createInvoiceLoading,
        onQuantityChange,
        onChangeCheckbox,
        onChangeSelectAllInvoiceItems,
        onSubmit,
        onCancel,
    } = props;
    const {list, total = 0, currency} = invoiceData;
    const {
        message, total_label, table_labels, button: {save, cancel},
    } = staticData.activity_hub.e_contracts.pages.origin_invoice;

    const tableLabelsList = list && list.length ? [{label: '№'}, ...list[0].row] : table_labels;
    const tableLabelsBlock = tableLabelsList.map((item, idx) => {
        let label = item;
        if (typeof item !== 'string') {
            label = item.label;
        }

        return <div key={idx} className={styles.column}>{label}</div>;
    });

    let tableItemsList = [];
    if (list && list.length) {
        tableItemsList = list.map((item, idx) => {
            return <InvoiceItem {...item}
                                key={idx}
                                number={idx + 1}
                                initialItem={initialList[idx]}
                                onQuantityChange={onQuantityChange}
                                onChangeCheckbox={onChangeCheckbox}/>;
        });
    }

    return (
        <div className={styles.container}>
            <div className={styles.message}>{message}</div>

            <form onSubmit={onSubmit}>
                <div className={styles['table-content']}>
                    <div className={styles['table-head']}>
                        <Checkbox id="select-all-invoice-items"
                                  styleType={'SECONDARY'}
                                  name="all-invoice-items"
                                  value={selectAllInvoiceItems}
                                  classNames={styles.checkbox}
                                  onChange={onChangeSelectAllInvoiceItems}
                                  activeCheckboxList={selectAllInvoiceItems !== 0 ? [selectAllInvoiceItems] : []}/>

                        <div className={styles['table-head-row']}>{tableLabelsBlock}</div>
                    </div>

                    {tableItemsList}

                    <div className={styles['total-block']}>
                        <div className={styles.title}>{total_label}</div>
                        <div className={styles.total}>
                            {priceHandler(total)} {currency}
                        </div>
                    </div>
                </div>

                <div className={styles['btn-block']}>
                    <Button type={'submit'} dataLoading={createInvoiceLoading}
                            classNames={styles['save-btn']}
                            isDisabled={!isCanBeSaved}>
                        {save}
                    </Button>
                    <Button styleType={'SECONDARY'} classNames={styles['cancel-btn']}
                            onClick={onCancel}>
                        {cancel}
                    </Button>
                </div>
            </form>
        </div>
    );
};

OriginInvoiceWizard.defaultProps = {
    onSubmit: () => {
    },
    onCancel: () => {
    },
};

OriginInvoiceWizard.propTypes = {
    staticData: PropTypes.object,
    invoiceData: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object),
        total: PropTypes.string,
        currency: PropTypes.string,
    })]),
    selectAllInvoiceItems: PropTypes.number,
    isCanBeSaved: PropTypes.bool,
    createInvoiceLoading: PropTypes.bool,
    onQuantityChange: PropTypes.func,
    onChangeCheckbox: PropTypes.func,
    onChangeSelectAllInvoiceItems: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

export default OriginInvoiceWizard;