export const resetSetDefaultBankAccount = () => {
    return {type: 'RESET_SET_DEFAULT_BANK_ACCOUNT'};
};

export const resetBankAccountActions = () => {
    return {type: 'RESET_BANK_ACCOUNT_ACTIONS'};
};

export const resetRemoveBankAccountState = () => {
    return {type: 'RESET_REMOVE_BANK_ACCOUNT_STATE'};
};