export default class QuotationService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/quotation';
    }

    addToQuotation = (productParams) => {
        return this._http.post(`${this.baseUrl}/add`, productParams);
    };

    updateNumOfItemInQuotation = (productParams) => {
        return this._http.post(`${this.baseUrl}/update`, productParams);
    };

    uploadFormOptions = () => {
        return this._http.get(`${this.baseUrl}/upload/form`);
    };

    uploadFormActions = (body, query) => {
        return this._http.post(`${this.baseUrl}/upload/action`, body, query);
    };

    getRFSProducts = () => {
        return this._http.get(`${this.baseUrl}/list`);
    };

    removeItemFromRFSList = (query) => {
        return this._http.post(`${this.baseUrl}/remove`, query);
    };

    replaceItemInRFSList = (query) => {
        return this._http.post(`${this.baseUrl}/change`, query);
    };

    confirmRFS = () => {
        return this._http.post(`${this.baseUrl}/confirm`);
    };

    clearRFSList = () => {
        return this._http.post(`${this.baseUrl}/clear`);
    };
}