import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withAccordion, withDataService} from '../../../../../../../../common/hoc';
import {priceHandler} from '../../../../../../../../../utils';

import EllipsisTextTooltip from '../../../../../../../../common/ellipsis-text-tooltip';

import styles from './specification.module.scss';

const Specification = (props) => {
    const {staticData, list, total, currency} = props;
    const {table_labels, total_label} = staticData.activity_hub.e_contracts.pages.contract_for_supply.condition_page;

    const tableLabels = Object.keys(table_labels).map((key, idx) => {
        const arrayLength = Object.keys(table_labels).length;
        let str = table_labels[key];

        if (idx === arrayLength - 2 || idx === arrayLength - 1) {
            str = `${table_labels[key]} ${currency}`;
        }

        return <div key={idx} className={styles.column}>{str}</div>;
    });

    const tableItems = list.map((item, idx) => {
        const rowKeys = Object.keys(item);
        const rowData = Object.keys(item).map((key, rowIdx) => {
            let str = item[key];

            if (key === 'offer_id') {
                return null;
            }

            if (rowIdx === 0 || rowIdx === 1) {
                str = <EllipsisTextTooltip tooltip={item[key]}/>;
            }

            if (rowIdx === rowKeys.length - 2 || rowIdx === rowKeys.length - 1) {
                str = priceHandler(item[key]);
            }

            return <div key={rowIdx} className={styles.column}>{str}</div>;
        });

        return (
            <div key={idx} className={styles['table-row']}>
                <div className={styles.column}>{idx + 1}</div>
                {rowData}
            </div>
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles['table-head']}>{tableLabels}</div>

            {tableItems}

            <div className={styles['total-block']}>
                <div className={styles.title}>{total_label}</div>
                <div className={styles.total}>{priceHandler(total)} {currency}</div>
            </div>
        </div>
    );
};

Specification.propTypes = {
    staticData: PropTypes.object,
    list: PropTypes.arrayOf(PropTypes.shape({
        part_number: PropTypes.string,
        name: PropTypes.string,
        quantity: PropTypes.number,
        doq: PropTypes.string,
        price: PropTypes.string,
        total: PropTypes.string,
        isActive: PropTypes.number,
    })),
    total: PropTypes.string,
    currency: PropTypes.string,
};

export default compose(
    withDataService(),
    withAccordion(),
)(Specification);