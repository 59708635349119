import React from 'react';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../common/hoc';

import ActionStepCard from '../../../common/action-step-card';

import styles from './about-marketplace-mobile.module.scss';

const ContentComponent = ({actionList}) => {
    return <ActionStepCard actionList={actionList}/>;
};

const EnhancedComponent = withAccordion()(ContentComponent);

const AboutMarketplaceMobile = ({pages}) => {
    return (
        <>
            {pages.map(({label, content}, idx) => {
                return <EnhancedComponent key={idx}
                                          label={label}
                                          defaultOpen={idx === 0}
                                          actionList={content}
                                          labelClassNames={styles['accordion-label']}
                                          contentClassNames={styles['accordion-content']}
                                          containerClassNames={styles['accordion-container']}/>;
            })}
        </>
    );
};

AboutMarketplaceMobile.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        content: PropTypes.arrayOf(PropTypes.object),
    })),
};

export default AboutMarketplaceMobile;