import React from 'react';
import PropTypes from 'prop-types';

import {withTabHeader} from '../../../../common/hoc';
import {withAccordion} from '../../../../../../common/hoc';

import Quotation from './components/quotation';
import SubQuotation from './components/sub-quotation';
import Preloader from '../../../../../../common/preloader';
import SkeletonPreloader from '../../../../../../common/skeleton-preloader';

import classnames from 'classnames/bind';
import styles from './requests-for-quotations-desktop.module.scss';

const SubQuotationItemList = ({subQuotations, ...rest}) => {
    return subQuotations.map((item, idx) => {
        return <SubQuotation key={idx} {...item} {...rest}/>;
    });
};

const EnhancedComponent = withAccordion()(SubQuotationItemList);

const RequestsForQuotationsDesktop = (props) => {
    const {
        staticData, loading: listLoading, quotationsList, removingItem,
        updatingItem, onButtonClick, onRemoveSubQuotationItem,
    } = props;

    const {
        empty_text,
        table_labels,
    } = staticData.activity_hub.requests_for_quotations;

    const cx = classnames.bind(styles);

    const itemList = !listLoading ?
        quotationsList
            ? (quotationsList.map(({id, list: subQuotations, ...rest}, idx) => {
                const quotationItemClasses = cx('item', {'expired-item': subQuotations.length === 0});
                const quotationItem = <Quotation {...rest} key={idx} staticData={staticData}
                                                 classNames={quotationItemClasses}/>;

                const commonSettings = {
                    key: idx,
                    subQuotations,
                    label: quotationItem,
                    containerActiveClassNames: styles['accordion-container-active'],
                    labelClassNames: styles['accordion-label'],
                    contentClassNames: styles['accordion-content'],
                    onRemoveSubQuotationItem: (offer_id) => onRemoveSubQuotationItem(id, offer_id),
                    onButtonClick: (...args) => onButtonClick(id, ...args),
                    withActiveContainer: true,
                };

                if (subQuotations.length !== 0) {
                    const additionalSettings = {
                        removingItem: removingItem && id === removingItem.rfq_id ? removingItem : null,
                        updatingItem: updatingItem && id === updatingItem.rfq_id ? updatingItem : null,
                    };

                    return <EnhancedComponent {...commonSettings} {...additionalSettings}/>;
                }

                return quotationItem;
            }))
            : <div className={styles.empty}>{empty_text}</div>
        : <Preloader type={'SECONDARY'} classNames={styles.preloader}/>;

    const tableItemsClasses = cx('table-items', {'not-accordion': listLoading || !quotationsList});

    return (
        <div className={styles.container}>
            <div className={styles['table-head']}>
                {table_labels.map((label, idx) => (
                    <div key={idx}>
                        <SkeletonPreloader type={'TEXT'} loading={listLoading}>
                            {label}
                        </SkeletonPreloader>
                    </div>
                ))}
            </div>

            <div className={tableItemsClasses}>{itemList}</div>
        </div>
    );
};

SubQuotationItemList.propTypes = {
    subQuotations: PropTypes.arrayOf(PropTypes.object),
};

RequestsForQuotationsDesktop.propTypes = {
    staticData: PropTypes.object,
    listLoading: PropTypes.bool,
    quotationsList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    removingItem: PropTypes.shape({
        rfq_id: PropTypes.number,
    }),
};

export default withTabHeader()(RequestsForQuotationsDesktop);