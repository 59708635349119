import React from 'react';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../common/hoc';

import Checkbox from '../../../../../../../../common/checkbox';

import styles from './condition.module.scss';

const Condition = ({list, review, onChangeCheckbox}) => {
    return list.map(({id, name, value, isActive}, idx) => {
        return (
            <div key={idx} className={styles.row}>
                <div className={styles.label}>{name}</div>
                <div className={styles.description}>{value}</div>

                {(review.turnOn && isActive !== undefined) && (
                    <Checkbox id={`condition_${id}`}
                              styleType={'WITH_ICONS'}
                              classNames={styles.checkbox}
                              value={isActive}
                              onChange={onChangeCheckbox}
                              activeCheckboxList={isActive !== 0 ? [isActive] : []}/>)}
            </div>
        );
    });
};

Condition.propTypes = {
    list: PropTypes.array,
    review: PropTypes.shape({
        turnOn: PropTypes.bool,
        clickable: PropTypes.number,
    }),
    onChangeCheckbox: PropTypes.func,
};

export default withAccordion()(Condition);