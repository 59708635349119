import {combineReducers} from 'redux';

import shipmentList from './shipment-list';
import shipmentOrganizationFormFields from './shipment-organization-form-fields';
import shipmentOrganizationActions from './shipment-organization-actions';
import defaultShipmentOrganization from './default-shipment-organization';
import removeShipmentOrganization from './remove-shipment-organization';

export const shipmentOrganizationReducer = combineReducers({
    list: shipmentList,
    formFields: shipmentOrganizationFormFields,
    actions: shipmentOrganizationActions,
    default: defaultShipmentOrganization,
    remove: removeShipmentOrganization,
});