import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../common/hoc';

import AboutMarketplace from './about-marketplace';

class AboutMarketplaceContainer extends Component {
    state = {
        pages: [],
    };

    componentDidMount() {
        const {pages} = this.props.staticData.about_marketplace;

        const newPages = pages.map((item, idx) => {
            return {
                ...item,
                isActive: idx === 0,
            };
        });

        this.setState({pages: newPages});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {pages} = this.props.staticData.about_marketplace;

            const newPages = this.state.pages.map((item, idx) => {
                return {
                    ...item,
                    label: pages[idx].label,
                    content: pages[idx].content,
                };
            });

            this.setState({pages: newPages});
        }
    }

    onTogglePage = ({target}) => {
        const newPages = this.state.pages.map((item) => {
            if (target.id === item.id) {
                return {
                    ...item,
                    isActive: true,
                };
            }

            return {
                ...item,
                isActive: false,
            };
        });

        this.setState({pages: newPages});
    };

    render() {
        return <AboutMarketplace {...this.state} {...this.props} onTogglePage={this.onTogglePage}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
    };
}

const mapStateToProps = ({publicData: {homePage}, profile, session: {session}}) => ({
    language: session ? profile.language : homePage.language,
    session,
});

export default compose(
    withDataService(),
    connect(mapStateToProps),
)(AboutMarketplaceContainer);
