import React, {useEffect, useRef, useState} from 'react';
import Tippy from '@tippyjs/react';

import CardSlider from '../../../../../../../../common/card-slider';
import OfferCard from '../../offer-card';

import 'tippy.js/animations/shift-away.css';
import classnames from 'classnames/bind';
import styles from './with-other-offers.module.scss';

const withOtherOffers = () => (Wrapped) => {
    return (props) => {
        const itemRef = useRef(null);
        const [isOpen, setIsOpen] = useState(false);
        const [parentNode, setParentNode] = useState('parent');

        const {otherOffers: {total_count, list: offersList}, onReplaceItem, ...rest} = props;
        const {other_offers_title} = props.staticData.marketplace.quotation.other_offers_block;

        const cx = classnames.bind(styles);
        const containerClasses = cx('container', {'show': isOpen});

        useEffect(() => {
            setParentNode(itemRef.current.nextSibling);

            window.addEventListener('resize', () => setIsOpen(false));

            return () => {
                window.addEventListener('resize', () => setIsOpen(false));
            };
        }, [itemRef]);

        if (total_count !== 0) {
            const breakpoints = [
                {
                    screenSize: 1200,
                    slidesToShow: 2.5,
                    slidesToScroll: 2,
                },
                {
                    screenSize: 992,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
                {
                    screenSize: 576,
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
                {
                    screenSize: 460,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            ];

            const content = (
                <CardSlider title={other_offers_title}
                            containerClassNames={styles.content}
                            titleClassNames={styles.title}
                            breakpoints={breakpoints}
                            closeBtnClassNames={styles['slider-close-btn']}
                            onCloseSlider={() => setIsOpen(false)}
                            withCloseBtn>
                    {offersList.map((item, idx) => (
                        <OfferCard {...item} key={idx}
                                   onReplaceItem={(changeId) => onReplaceItem(props.id, changeId)}/>
                    ))}
                </CardSlider>
            );

            return (
                <>
                    <Tippy
                        content={content}
                        animation={'shift-away'}
                        arrow={false}
                        appendTo={parentNode}
                        popperOptions={{modifiers: [{name: 'flip', enabled: false}]}}
                        interactive={true}
                        maxWidth="none"
                        role="other-offers-tab"
                        offset={[0, 0]}
                        placement="bottom-start"
                        visible={isOpen}
                        onClickOutside={() => isOpen && setIsOpen(false)}>

                        <Wrapped {...rest} ref={itemRef}
                                 otherOffers={total_count !== 0}
                                 isFindOtherOffersOpen={isOpen}
                                 onFindOtherOffers={() => setIsOpen(true)}/>
                    </Tippy>

                    <div className={containerClasses}/>
                </>
            );
        }

        return <Wrapped ref={itemRef} {...rest} otherOffers={total_count !== 0}/>;
    };
};

export default withOtherOffers;