import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {marketplaceURLs} from '../../../../../../../../services/api-urls';

import RequestItem from '../../common/request-item';
import Button from '../../../../../../../common/button';
import OrderSummary from '../../common/order-summary';

import styles from './request-for-supply.module.scss';

const RequestForSupply = (props) => {
    const {
        staticData, products, summary,
        removeItemFromRFS, replaceItemInRFS, marketplaceUrl,
        onRemoveItem, onReplaceItem, onQuantityChange, onClearAllItems,
        resetItemCounter,
    } = props;

    const {add, upload, clear_all} = staticData.marketplace.quotation.button;

    return (
        <div className={styles.container}>
            <Button styleType="TEXT" classNames={styles['btn-clear-all']}
                    onClick={onClearAllItems}>
                {clear_all}
            </Button>

            {products.list.map((item, idx) => (
                <RequestItem key={idx} {...item}
                             itemNumber={idx + 1}
                             staticData={staticData}
                             replacingItem={{
                                 id: props.replaceItemId,
                                 loading: replaceItemInRFS.loading,
                             }}
                             removingItem={{
                                 id: props.removingItemId,
                                 loading: removeItemFromRFS.loading,
                             }}
                             countingItem={{
                                 id: props.countOfItemId,
                                 loading: props.countOfItem.loading,
                             }}
                             onRemoveItem={onRemoveItem}
                             onReplaceItem={onReplaceItem}
                             onQuantityChange={onQuantityChange}
                             resetItemCounter={resetItemCounter}/>
            ))}

            <div className={styles['btn-block']}>
                <Link to={marketplaceUrl} className={styles['btn-add']}>
                    <Button styleType="WITH_PLUS">
                        {add}
                    </Button>
                </Link>

                <Link to={marketplaceURLs.quotation_excel} className={styles['btn-upload']}>
                    <Button styleType="SECONDARY">
                        {upload}
                    </Button>
                </Link>
            </div>

            <OrderSummary staticData={staticData} summary={summary}/>
        </div>
    );
};

RequestForSupply.propTypes = {
    staticData: PropTypes.object,
    products: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object),
    }),
    marketplaceUrl: PropTypes.string,
    removingItemId: PropTypes.number,
    removeItemFromRFS: PropTypes.shape({
        loading: PropTypes.bool,
    }),
    onClearAllItems: PropTypes.func,
};

export default RequestForSupply;