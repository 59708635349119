import React from 'react';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../common/hoc';

import DocumentationsInformation from '../documentations-information';
import ShipmentNoticeProgressBar from '../shipment-notice-progress-bar';

import classnames from 'classnames/bind';
import styles from './shipment-documentations.module.scss';

import truck from '../../../../../../../../common/assets/other-icons/truck.svg';
import planeDeparture from '../../../../../../../../common/assets/other-icons/plane-departure.svg';
import warehouse from '../../../../../../../../common/assets/other-icons/warehouse.svg';

const ContentComponent = ({lagsList, shipmentNotice}) => {
    const {list, title, subtitle, point, arrival} = shipmentNotice;
    const cx = classnames.bind(styles);

    const shipmentDocumentsBlock = lagsList.map((lag, idx) => {
        let label = (
            <>
                <img src={warehouse} alt={'warehouse'} className={cx('icon', 'warehouse')}/>
                {lag.label}
            </>
        );

        if (lag.from || lag.to) {
            const {from, to, delivery} = lag;

            let icon = planeDeparture;
            switch (delivery) {
                case 'ground':
                    icon = truck;
                    break;
                default:
            }

            label = (
                <>
                    <span>{from}</span>
                    <img src={icon} alt={'Delivery type icon'} className={cx('icon', {
                        'from': from,
                        'to': to,
                    })}/>
                    <span>{to}</span>
                </>
            );
        }

        return <DocumentationsInformation key={idx} {...lag}
                                          label={label}
                                          labelClassNames={styles['documents-label']}
                                          contentClassNames={styles['documents-content']}
                                          containerClassNames={styles['documents-container']}
                                          defaultOpen={lag.opened}/>;
    });

    return (
        <>
            <div className={styles['shipment-notice']}>
                <div className={styles['info-row']}>
                    <div className={styles['label-block']}>
                        <div className={styles.label}>{title}</div>
                        <div>{subtitle}</div>
                    </div>
                    <div className={styles['shipment-info']}>
                        <div>{arrival}</div>
                        <div className={styles['supply-point']}>{point}</div>
                    </div>
                </div>

                <ShipmentNoticeProgressBar list={list}/>
            </div>

            {shipmentDocumentsBlock}
        </>
    );
};

const EnhancedComponent = withAccordion()(ContentComponent);

const ShipmentDocumentations = ({list}) => {
    /* map() does not execute the function for empty elements. */
    return list.map(({label, lags: {list: lagsList}, opened, shipment_notice}, idx) => {
        return <EnhancedComponent key={idx}
                                  lagsList={lagsList}
                                  shipmentNotice={shipment_notice}
                                  label={label}
                                  containerClassNames={styles['accordion-container']}
                                  containerActiveClassNames={styles['accordion-container-active']}
                                  labelClassNames={styles['accordion-label']}
                                  contentClassNames={styles['accordion-content']}
                                  defaultOpen={opened}
                                  withActiveContainer/>;
    });
};

ContentComponent.propTypes = {
    lagsList: PropTypes.array,
    shipmentNotice: PropTypes.shape({
        list: PropTypes.array,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        point: PropTypes.string,
        arrival: PropTypes.string,
    }),
};

ShipmentDocumentations.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        lags: PropTypes.shape({
            list: PropTypes.array,
        }),
        opened: PropTypes.bool,
        shipment_notice: PropTypes.shape({
            list: PropTypes.array,
        }),
    })),
};

export default ShipmentDocumentations;