import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './chip.module.scss';

import activeIcon from './assets/active-icon.svg';

const Chip = ({type, children, classNames, isActive, isDisable, onClick}) => {
    const cx = classnames.bind(styles);

    const classes = cx('chip', classNames, {
        'primary': type === 'PRIMARY',
        'secondary': type === 'SECONDARY',
        'active': isActive,
        'updating-data': type === 'SECONDARY' && isDisable,
    });

    return (
        <div className={classes} onClick={onClick}>
            {
                isActive && type === 'PRIMARY'
                && <img src={activeIcon} className={styles['active-icon']} alt="Active"/>
            }
            {children}
            {(isDisable && type !== 'SECONDARY') && <div className={styles['updating-process']}/>}
        </div>
    );
};

Chip.defaultProps = {
    type: 'PRIMARY',
    isDisable: false,
    onClick: () => {
    },
};

Chip.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node.isRequired,
    classNames: PropTypes.string,
    isActive: PropTypes.bool,
    isDisable: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Chip;