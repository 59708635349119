import {combineReducers} from 'redux';

import request from "./request";
import form from './form';
import uploadData from './upload-data';

export const resetPasswordReducer = combineReducers({
    request,
    form,
    uploadData,
});