import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';

import {withServices} from '../../../../../../../../../service-context';
import {placeProductSuggestAction} from '../../../../../../../../../../actions/activity-hub';
import {suggestPnumRequest} from '../../../../../../../../../../reducers/activity-hub/place-product/suggest/suggest-part-number';
import {suggestDataByPnumRequest} from '../../../../../../../../../../reducers/activity-hub/place-product/suggest/suggest-data-by-pnum';

import styles from '../../product-information.module.scss';

class InputPnum extends Component {
    state = {
        isAutoComplete: true,
        inputAutoCompleteName: null,
        isSuggestResult: false,
        isFieldsReset: true,
    };

    timerId = null;
    partNumberRef = React.createRef();
    partNumberRuRef = React.createRef();

    componentDidMount() {
        if (this.props.fields) {
            this.onCheckingFilledState();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fields !== this.props.fields) {
            if (!prevProps.fields) {
                this.onCheckingFilledState();
            }
        }

        if (prevProps.suggestList !== this.props.suggestList) {
            const {suggestList} = this.props;
            if (suggestList && suggestList.length > 0) {
                this.setState({isSuggestResult: true});
            }
        }

        if (prevProps.dataByPnum !== this.props.dataByPnum) {
            if (this.props.dataByPnum) {
                const {dataByPnum} = this.props;
                let object = {};
                for (let key in dataByPnum) {
                    if (dataByPnum.hasOwnProperty(key)) {
                        let transformKey = key;
                        if (key.includes('ids') || key === 'title' || key === 'platform') {
                            transformKey = `${key}[]`;
                        }

                        object = {
                            ...object,
                            [transformKey]: dataByPnum[key],
                        };
                    }
                }

                this.props.onUpdateFieldsWithDataByPnum(object);
                this.props.resetSuggestedDataByPnum();
            }
        }
    }

    onCheckingFilledState = () => {
        const isFilled = this.props.fields.find(({value}) => !value) === undefined;

        if (isFilled) {
            this.setState({
                isAutoComplete: false,
                isFieldsReset: false,
            });
        }
    };

    onInputChange = ({target: {name, value}}, remote) => {
        const {isAutoComplete, inputAutoCompleteName, isFieldsReset} = this.state;
        const {fields, suggestList} = this.props;
        const currentFieldValue = fields.find((field) => field.name === name).value;

        const handler = () => {
            this.timerId = setTimeout(() => {
                const url = remote.url;
                const queryPhrase = remote.params[0];

                this.setState({inputAutoCompleteName: name});
                this.props.fetchSuggestedPnum(url, {[queryPhrase]: value});
            }, 1000);
        };

        if (isAutoComplete) {
            if (this.timerId !== null) {
                clearTimeout(this.timerId);
            }

            if (value === '' || value.length <= 1) {
                if (inputAutoCompleteName) {
                    this.setState({inputAutoCompleteName: null});
                }

                if (suggestList && suggestList.length > 0) {
                    this.props.resetSuggestedPnum();
                }
            }

            if (value.length > 1) {
                handler();
            }

            this.props.onSetPnumAutocomplete([{name, value}]);

        } else {
            if (value !== currentFieldValue) {
                if (!isFieldsReset) {
                    this.setState({isFieldsReset: true});
                    this.props.onResetReadOnlyFieldsByPnum(value);

                } else {
                    if (value.length > 1 && value.length > currentFieldValue.length) {
                        handler();
                        this.setState({isAutoComplete: true});
                    }

                    if (!value) {
                        this.setState({isAutoComplete: true});
                    }

                    this.props.onSetPnumAutocomplete([{name, value}]);
                }
            }
        }
    };

    setSuggestedPnum = (item) => {
        const {part_number, part_number_ru, slug} = item;

        this.props.onSetPnumAutocomplete([
            {name: this.partNumberRef.current.name, value: part_number},
            {name: this.partNumberRuRef.current.name, value: part_number_ru},
        ]);

        this.setState({
            isSuggestResult: false,
            isAutoComplete: false,
            isFieldsReset: false,
        });

        this.props.fetchDataByPnum(slug);
        this.props.resetSuggestedPnum();
    };

    onSuggestResultHidden = () => {
        if (this.state.inputAutoCompleteName) {
            this.setState({inputAutoCompleteName: null});
        }

        this.props.resetSuggestedPnum();
        this.setState({isSuggestResult: false});
    };

    componentWillUnmount() {
        if (this.timerId !== null) {
            clearTimeout(this.timerId);
        }
    }

    render() {
        const {fields, suggestList} = this.props;
        const {inputAutoCompleteName, isSuggestResult} = this.state;

        return fields && fields.map((field, idx) => {
            const {name, label, value, props, remote} = field;
            const isRequired = props && props.includes('required');
            const readOnly = props && props.includes('readonly');
            const ref = name === 'part_number' ? this.partNumberRef : this.partNumberRuRef;

            const input = (
                <div key={idx} className={styles['input-pnum']}>
                    <input ref={ref}
                           type="text"
                           className={styles.input}
                           autoComplete="off"
                           name={name}
                           value={value}
                           onChange={(e) => this.onInputChange(e, remote)}
                           onFocus={(e) => this.onInputChange(e, remote)}/>
                    {readOnly && <div className={styles['read-only']}/>}
                    <span className={styles.label}>{label}
                        {isRequired && <span className={styles['required-icon']}>*</span>}
                    </span>
                </div>
            );

            return (
                <div key={idx} className={styles['input-pnum-container']}>
                    <Tippy
                        content={
                            <div className={styles.content}>
                                {suggestList && suggestList.length > 0 && suggestList.map((item, idx) => {
                                    const keys = Object.keys(item);
                                    const currentKeyIdx = keys.findIndex(key => key === inputAutoCompleteName);
                                    const currentKey = keys[currentKeyIdx];

                                    return item[currentKey] && item[currentKey] !== '' ? (
                                        <div key={idx}
                                             className={styles['suggested-item']}
                                             onClick={() => this.setSuggestedPnum(item)}>
                                            {item[currentKey]}
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        }
                        appendTo={ref.current ? ref.current.parentNode : 'parent'}
                        arrow={false}
                        interactive={true}
                        maxWidth="none"
                        role="suggest-pnum-beard"
                        offset={[0, 0]}
                        placement="bottom-start"
                        visible={isSuggestResult && inputAutoCompleteName === name}
                        onClickOutside={this.onSuggestResultHidden}
                        zIndex={2}
                    >
                        {input}
                    </Tippy>
                </div>
            );
        });
    }

    static defaultProps = {
        fetchSuggestedPnum: () => {},
        fetchDataByPnum: () => {},
        onSetPnumAutocomplete: () => {},
        onUpdateFieldsWithDataByPnum: () => {},
        onResetReadOnlyFieldsByPnum: () => {},
    };

    static propTypes = {
        fields: PropTypes.arrayOf(PropTypes.object),
        suggestList: PropTypes.arrayOf(PropTypes.object),
        dataByPnum: PropTypes.object,
        fetchSuggestedPnum: PropTypes.func,
        fetchDataByPnum: PropTypes.func,
        resetSuggestedPnum: PropTypes.func,
        resetSuggestedDataByPnum: PropTypes.func,
        onSetPnumAutocomplete: PropTypes.func,
        onUpdateFieldsWithDataByPnum: PropTypes.func,
        onResetReadOnlyFieldsByPnum: PropTypes.func,
    };
}

const mapServicesToProps = ({suggestService}) => ({
    fetchSuggestedPnum: suggestPnumRequest(suggestService.getSuggested),
    fetchDataByPnum: suggestDataByPnumRequest(suggestService.getDataByPnum),
});

const mapStateToProps = ({activityHub: {placeProduct: {suggest}}}) => ({
    suggestList: suggest.pnum.list,
    dataByPnum: suggest.dataByPnum.list,
});

const mapDispatchToProps = (dispatch, {fetchSuggestedPnum, fetchDataByPnum}) => (
    bindActionCreators({
        fetchSuggestedPnum,
        fetchDataByPnum,
        resetSuggestedPnum: placeProductSuggestAction.resetSuggestedPnum,
        resetSuggestedDataByPnum: placeProductSuggestAction.resetSuggestedDataByPnum,
    }, dispatch)
);

export default compose(
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(InputPnum);