import React from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {staticURLs} from '../../services/api-urls';

import Logo from '../common/logo';
import Button from '../common/button';
import {LanguageMenu} from '../common/language-menu';
import SearchField from './search-field';
import {HubDropdownMenu} from '../common/hub-menu';
import ButtonMenu from '../common/button-menu';
import TooltipCreator from "../common/tooltip-creator";

import classnames from 'classnames/bind';
import styles from './header.module.scss';

const Header = (props) => {
    const {
        session,
        profile: {userInteraction, profile},
        staticData: {header: {button, trial_identifier}},
        onOpenSignIn,
    } = props;
    const cx = classnames.bind(styles);
    const mdScreen = useMediaQuery({minWidth: 768});
    const xlScreen = useMediaQuery({minWidth: 1200});

    const languageMenu = <LanguageMenu classNames={styles.lang}/>;
    const searchComponent = <SearchField searchInputClasses={styles.search}/>;
    const userProfile = session && <HubDropdownMenu classNames={styles.menu}/>;

    let mainButton = null;
    if (!session) {
        mainButton = (
            <Button classNames={styles['sign-in-btn']}
                    labelClassNames={styles['btn-label']}
                    onClick={onOpenSignIn}>
                {button.sign_in}
            </Button>
        );

    } else {
        if (profile && !profile.is_trial) {
            if (userInteraction === 'exporter' && xlScreen) {
                mainButton = <ButtonMenu classNames={styles['btn-menu']}/>;
            }

            if (userInteraction === 'importer' && mdScreen) {
                if (mdScreen) {
                    mainButton = <ButtonMenu classNames={styles['btn-menu']}/>;
                }
            }
        }

        if (profile && profile.is_trial) {
            mainButton = (
                <div className={styles['profile-status']}>
                    <span className={styles.label}>{trial_identifier.label}</span>
                    <TooltipCreator tooltip={trial_identifier.tooltip} contentClassNames={styles.tooltip}/>
                </div>
            );
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={cx(
                'container', {
                    'private': session,
                    'importer-role': userInteraction === 'importer',
                    'trial-mode': profile && profile.is_trial,
                })}>
                <Logo classNames={styles.logo} href={staticURLs.home}/>

                <div className={styles['btn-block']}>
                    {userProfile}
                    {mainButton}
                    {languageMenu}
                </div>

                {searchComponent}
            </div>
        </div>
    );
};

Header.propTypes = {
    session: PropTypes.bool,
    userInteraction: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    profile: PropTypes.shape({
        is_trial: PropTypes.bool,
    }),
    staticData: PropTypes.object,
    onOpenSignIn: PropTypes.func,
};

export default Header;