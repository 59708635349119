import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../../../../../../../common/select/select';
import ShipmentLotItem from './components/common/shipment-lot-item';
import PackingForm from '../../packing-form';
import Preloader from '../../../../../../../../../common/preloader';
import SkeletonPreloader from "../../../../../../../../../common/skeleton-preloader";

import classnames from 'classnames/bind';
import styles from './shipment-lot.module.scss';

const ShipmentLot = (props) => {
    const {
        staticData, productsList, strategyList,
        strategyListLoading, strategyListUpdating,
        packingForm, packingFormLoading, packingFormUpdating, switchSpareKitLoading,
        isSelectOpen, onCheckboxChange, onToggleSelect, onClickSelectItem,
    } = props;

    const {select_data, empty_message} = staticData.pages.shipment_lot;

    const loading =
        strategyListLoading || strategyListUpdating ||
        packingFormLoading || packingFormUpdating || switchSpareKitLoading;

    const cx = classnames.bind(styles);
    const tableContainerClasses = cx('table-container', {
        'hiding-container': loading,
    });
    const settingsBlockClasses = cx('settings-block', {
        'hiding-container': loading,
    });

    const shipmentTableLabels = productsList[0].row.map(({label}) => label).filter(item => item);
    const tableLabels = ['№', ...shipmentTableLabels].map((label, idx) => {
        return <div key={idx} className={styles.column}>{label}</div>;
    });

    const tableItems = productsList.length > 0
        ? productsList.map((item, idx) => {
            return <ShipmentLotItem {...item}
                                    key={idx}
                                    staticData={staticData}
                                    number={idx + 1}
                                    onCheckboxChange={onCheckboxChange}/>;
        })
        : <div className={styles.empty}>{empty_message}</div>;

    const selectedItemIdx = strategyList.findIndex(({isSelected}) => isSelected);
    const selectedItem = strategyList[selectedItemIdx];
    let selectedItemLabel = '';
    let selectPreloaderLoading = false;
    if (selectedItem.value === 'default') {
        selectedItemLabel = select_data.default_item_label;
    } else {
        selectedItemLabel = selectedItem.label;
        selectPreloaderLoading = strategyListUpdating;
    }

    const selectList = strategyList.map((item, idx) => {
        let {label, value} = item;
        let className = cx({
            'item-active': value === selectedItem.value,
            'item-not-available': value === 'default',
        });

        if (value === 'default') {
            label = select_data.default_item_label;
        }

        return (
            <li key={idx}
                className={className}
                onClick={() => {
                    onToggleSelect();
                    onClickSelectItem(value);
                }}>
                {label}
            </li>
        );
    });

    return (
        <div className={styles.container}>
            <div className={tableContainerClasses}>
                <div className={styles['table-head']}>
                    {tableLabels}
                </div>

                {tableItems}
            </div>

            <div className={settingsBlockClasses}>
                {loading && <Preloader type={'SECONDARY'} classNames={styles.preloader}/>}

                <div className={styles['select-block']}>
                    <Select type={'SECONDARY'}
                            label={select_data.label}
                            isOpenSelect={isSelectOpen}
                            selectedItem={
                                <SkeletonPreloader loading={selectPreloaderLoading}
                                                   classNames={styles['suggest-select-preloader']}>
                                    <span>{selectedItemLabel}</span>
                                </SkeletonPreloader>
                            }
                            selectedItemClasses={styles['select-item']}
                            props={strategyList.length === 1 ? ['disabled'] : null}
                            onToggleSelect={onToggleSelect}
                            withLabel>
                        {selectList}
                    </Select>
                </div>

                {packingForm && !selectPreloaderLoading && !packingFormUpdating && <PackingForm/>}
            </div>
        </div>
    );
};

ShipmentLot.defaultProps = {
    onCheckboxChange: () => {
    },
    onClickSelectItem: () => {
    },
};

ShipmentLot.propTypes = {
    staticData: PropTypes.object,
    productsList: PropTypes.arrayOf(PropTypes.object),
    strategyList: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    packingForm: PropTypes.array,
    strategyListLoading: PropTypes.bool,
    strategyListUpdating: PropTypes.bool,
    packingFormLoading: PropTypes.bool,
    packingFormUpdating: PropTypes.bool,
    switchSpareKitLoading: PropTypes.bool,
    isSelectOpen: PropTypes.bool,
    onCheckboxChange: PropTypes.func,
    onToggleSelect: PropTypes.func,
    onClickSelectItem: PropTypes.func,
};

export default ShipmentLot;