import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {utilsAction} from '../../actions/common';
import {errorURLs} from '../../services/api-urls';

import CategoryPage from './components/pages/category-page';
import MarketplaceHomeContainer from './components/pages/marketplace-home';
import ProductPage from './components/pages/product-page';
import SearchPage from './components/pages/search-page';
import QuotationPageModule from './components/pages/quotation-page/components/pages/module';
import Preloader from '../common/preloader';

class MarketplaceModule extends Component {
    componentDidMount() {
        const {baseAPIUrl, match: {path}} = this.props;

        if (baseAPIUrl !== path) {
            this.props.setBaseAPIUrl(path);
            this.props.switchDepartmentID(path.substring(1));
        }
    }

    render() {
        const {match: {path: basePath}, categoriesLoading} = this.props;

        return (
            <Switch>
                <Route path={basePath} exact render={(props) =>
                    <MarketplaceHomeContainer {...props}/>
                }/>

                <Route path={`${basePath}/quotation`} render={(props) =>
                    <QuotationPageModule {...props} marketplaceUrl={basePath}/>
                }/>

                <Route path={`${basePath}/search`} exact render={(props) =>
                    <SearchPage {...props}/>
                }/>

                <Route path={`${basePath}/product/:slug/:id`} exact render={(props) =>
                    <ProductPage {...props}/>
                }/>

                {/***
                 Route for categories always last of main route list because we do checking of categories
                 and shouldn`t be excluded previous routes from the list (ex. quotation, search, product)
                 */}
                <Route path={`${basePath}/:category`} exact render={(props) =>
                    <CategoryPage {...props}/>
                }/>

                <Route>
                    <Redirect to={errorURLs.not_found}/>
                </Route>

                {categoriesLoading && <Preloader/>}
            </Switch>
        );
    }

    static propTypes = {
        categoryLoading: PropTypes.bool,
        baseAPIUrl: PropTypes.string,
    };
}

const mapStateToProps = ({marketplace: {categories: {loading}}, utils: {baseAPIUrl}}) => {
    return {
        categoriesLoading: loading,
        baseAPIUrl,
    };
};

const mapDispatchToProps = (dispatch) => {
    const {setBaseAPIUrl, switchDepartmentID} = utilsAction;

    return bindActionCreators({
        setBaseAPIUrl,
        switchDepartmentID,
    }, dispatch);
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(MarketplaceModule);