export default class EContractsService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/contract';
    }

    createContract = (url) => {
        return this._http.post(url);
    };

    getContractsList = (query) => {
        return this._http.get(`${this.baseUrl}/list`, query);
    };

    getContractMap = (id) => {
        return this._http.get(`${this.baseUrl}/${id}/map`);
    };

    getContractCondition = (id) => {
        return this._http.get(`${this.baseUrl}/${id}/condition`);
    };

    uploadDocumentData = (id, page) => {
        return this._http.get(`${this.baseUrl}/${id}/${page}`);
    };

    uploadDocument = (url, body) => {
        if (body) {
            return this._http.post(url, body);
        }

        return this._http.post(url);
    };
}