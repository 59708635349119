import getReduxStore from '../../../utils/get-redux-store';

export const initializePlaceProductSections = (sections) => {
    return {
        type: 'INITIALIZE_PLACE_PRODUCT_SECTIONS',
        payload: sections,
    };
};

export const updatedPlaceProductSectionSaveStatus = (currentSectionID, newStatus) => {
    const {formSections: {list}} = getReduxStore().activityHub.placeProduct;

    const newPlaceProductSection = list.map((section) => {
        const {id, isSaved, ...rest} = section;

        if (currentSectionID === id) {
            return {
                id,
                isSaved: newStatus,
                ...rest,
            };
        }

        return section;
    });

    return {
        type: 'UPDATE_PLACE_PRODUCT_SECTION_SAVE_STATUS',
        payload: newPlaceProductSection,
    };
};

export const updatedPlaceProductSectionDisabledStatus = (currentSectionID, newStatus) => {
    const {formSections: {list}} = getReduxStore().activityHub.placeProduct;
    const newPlaceProductSection = list.map((section) => {
        const {id, isOpen, isDisabled, ...rest} = section;

        if (currentSectionID === id) {
            return {
                id,
                isDisabled: newStatus,
                ...rest,
            };
        }

        return section;
    });

    return {
        type: 'UPDATE_PLACE_PRODUCT_SECTION_DISABLED_STATUS',
        payload: newPlaceProductSection,
    };
};

export const resetPlaceProductFormData = () => {
    return {type: 'RESET_PLACE_PRODUCT_FORM_DATA_UPLOAD_RESPONSE'};
};

export const resetFormFields = () => {
    return {type: 'RESET_FORM_FIELDS'};
};