import React from 'react';
import {useMediaQuery} from 'react-responsive';

import EContractsDesktop from './components/e-contracts-desktop/e-contracts-desktop';
import EContractsMobile from './components/e-contracts-mobile/e-contracts-mobile';

const EContracts = (props) => {
    const {pages} = props.staticData.activity_hub;
    const xlDevice = useMediaQuery({minWidth: 1200});

    return xlDevice
        ? <EContractsDesktop {...props} label={pages[1]}/>
        : <EContractsMobile {...props} label={pages[1]}/>;
};

export default EContracts;