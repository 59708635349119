import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// import {generatePath} from 'react-router';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../common/hoc';
import {withHubSideMenu} from '../../../../common/hoc';
import {withServices} from '../../../../../../service-context';
import {resetFileUpload} from '../../../../../../../actions/common';
import {switchMenuActiveLinkID} from '../../../../../../../actions/activity-hub';
import uploadFileHandler from '../../../../../../../utils/upload-file-handler';
import {
    contractDocumentUploadDataRequest
} from '../../../../../../../reducers/activity-hub/contract-maps/contract-document-upload';
import {fileUploadRequest} from '../../../../../../../reducers/file-upload';
import {contractMapsAction} from '../../../../../../../actions/activity-hub';
import sweetAlert from '../../../../../../common/sweet-alert';

import UploadDocument from './upload-document';
import Preloader from '../../../../../../common/preloader';

class UploadDocumentContainer extends Component {
    initialState = {
        file: null,
        progress: 0,
        loaded: false,
    };

    state = {
        ...this.initialState,
        autogenerateMode: true,
        manualModeRemote: null,
    };

    componentDidMount() {
        const {pageID, menuActiveLinkID, match: {params}} = this.props;

        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        this.props.fetchPageData(params.id, params.pages);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {match: {params}} = this.props;
            this.props.fetchPageData(params.id, params.pages);
        }
    }

    cleaningHandler = () => {
        const {response: {list, error}} = this.props;
        if (list || error) {
            this.props.resetFileUpload();
        }

        this.props.resetContractDocumentUpload();
    };

    onButtonClick = (remote, manually = false) => {
        if (manually) {
            this.setState({
                autogenerateMode: false,
                manualModeRemote: remote,
            });

        } else {
            this.onSubmit(remote);
        }
    };

    onAcceptedFiles = (acceptedFiles) => {
        this.setState({file: acceptedFiles[0]});
        uploadFileHandler(acceptedFiles, this.onLoadingFile, this.onLoadedFile);
    };

    onRejectedFiles = (rejectedFiles) => {
        if (rejectedFiles.length !== 0) {
            this.setState({file: rejectedFiles[0]});
        }
    };

    onLoadingFile = (file, progress) => this.setState({progress});

    onLoadedFile = () => this.setState({loaded: true});

    onDeleteFile = () => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.setState(this.initialState);
                }
            });
    };

    onCancel = () => {
        this.setState({autogenerateMode: true});

        // go to contract map
        // const {match: {path, params}} = this.props;
        // const urlToRedirect = generatePath(path, {
        //     id: params.id,
        //     pages: 'map',
        // });
        //
        // this.props.history.push(urlToRedirect);
    };

    onSubmit = ({method, url}) => {
        const {autogenerateMode, file} = this.state;

        if (method === 'POST') {
            if (autogenerateMode) {
                this.props.uploadDocument(url);

            } else {
                this.props.uploadDocument(url, {fileData: file});
            }
        }
    };

    componentWillUnmount() {
        this.cleaningHandler();
    }

    render() {
        const {loading, data} = this.props.contractDocumentUpload;
        return loading || !data
            ? <Preloader/>
            : (
                <UploadDocument {...this.props} {...this.state}
                                id="upload-contract-document"
                                title={data.title}
                                onDeleteFile={this.onDeleteFile}
                                onAcceptedFiles={this.onAcceptedFiles}
                                onRejectedFiles={this.onRejectedFiles}
                                onCancel={this.onCancel}
                                onButtonClick={this.onButtonClick}
                                onSubmit={() => this.onSubmit(this.state.manualModeRemote)}
                />
            );
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        contractDocumentUpload: PropTypes.object,
        loading: PropTypes.bool,
        response: PropTypes.object,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        fetchPageData: PropTypes.func,
        uploadDocument: PropTypes.func,
        resetContractDocumentUpload: PropTypes.func,
        resetFileUpload: PropTypes.func,
        switchMenuActiveLinkID: PropTypes.func,
    };
}

const mapServicesToProps = ({eContractsService}) => ({
    fetchPageData: contractDocumentUploadDataRequest(eContractsService.uploadDocumentData),
    uploadDocument: fileUploadRequest(eContractsService.uploadDocument),
});

const mapStateToProps = ({
                             profile: {language},
                             activityHub: {contractMaps: {contractDocumentUpload}},
                             fileUpload,
                             utils
                         }) => ({
    language,
    contractDocumentUpload,
    loading: fileUpload.loading,
    response: fileUpload,
    breadcrumbs: utils.breadcrumbs,
    menuActiveLinkID: utils.menuActiveLinkID,
});

const mapDispatchToProps = (dispatch, {fetchPageData, uploadDocument}) => {
    return bindActionCreators({
        fetchPageData,
        uploadDocument,
        resetFileUpload,
        switchMenuActiveLinkID,
        resetContractDocumentUpload: contractMapsAction.resetContractDocumentUpload,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(UploadDocumentContainer);