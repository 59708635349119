import React from 'react';
import PropTypes from 'prop-types';

import {ShipmentLot, PackingList} from './components/tabs-options';

import styles from './packing-list-wizard.module.scss';

const PackingListWizard = (props) => {
    const {tabs, packedItemsCounter, onToggleTab} = props;

    const labelsBlock = tabs.map(({id, label, isActive}, idx) => {
        if (label) {
            return (
                <div key={idx} className={styles.tab}>
                    <input type="radio"
                           id={id}
                           checked={isActive}
                           onChange={onToggleTab}
                    />
                    <label htmlFor={id} className={styles.label}>
                        <div className={styles['label-text']}>
                            {label}

                            {(idx === 1 && packedItemsCounter !== 0) && (
                                <div className={styles.counter}>{packedItemsCounter}</div>)}
                        </div>
                    </label>
                </div>
            );
        }

        return null;
    });

    const tabContentBlock = tabs.map((tab, idx) => {
        const {id, isActive} = tab;
        if (isActive) {
            if (id === 'shipment_lot') {
                return <ShipmentLot key={idx}/>;
            }

            if (id === 'packing_list') {
                return <PackingList key={idx}/>;
            }
        }

        return null;
    });

    return (
        <div className={styles.container}>
            <div className={styles['tabs-labels']}>
                {labelsBlock}
            </div>

            {tabContentBlock}
        </div>
    );
};

PackingListWizard.propTypes = {
    tabs: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            isActive: PropTypes.bool,
        }))]),
    packedItemsCounter: PropTypes.number,
    onToggleTabs: PropTypes.func,
};

export default PackingListWizard;