import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withServices} from '../../../../../../../../service-context';
import {withDataService} from '../../../../../../../../common/hoc';
import {
    createPackingPlaceRequest
} from '../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/create-packing-place';
import {
    editPackingPlaceRequest
} from '../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/edit-packing-place';
import {
    productsListForPackingRequest
} from '../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/products-list-for-packing';
import {
    onChangeFieldValue,
    onChangeRadioButtonValue,
    onCheckingRequiredFieldData,
    onResetFormError,
} from '../../../../../../common/handlers';
import {contractMapsAction} from '../../../../../../../../../actions/activity-hub';
import {onCreateFormData} from '../../../../../../../../common/handlers';
import {onGetAlertResponse} from '../../../../../../../../../utils';
import sweetAlert from '../../../../../../../../common/sweet-alert';

import PackingPlace from '../packing-place';
import Button from '../../../../../../../../common/button';

import classnames from 'classnames/bind';
import styles from './packing-form.module.scss';

class PackingForm extends Component {
    state = {
        fields: null,
        formID: 'placebunch_form',
        isSaveDisabled: false,
        formError: false,
    };

    componentDidMount() {
        const {packingForm, packingEditingForm, formMode} = this.props;
        const fields = formMode === 'EDIT' ? packingEditingForm : packingForm;

        this.setState({fields});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.fields !== this.state.fields) {
            if (prevState.fields) {
                this.props.updatePackingForm(
                    this.state.fields,
                    this.props.formMode
                );
            }
        }

        if (prevProps.createPackingPlace !== this.props.createPackingPlace) {
            this.onGetResponse(this.props.createPackingPlace, this.props.resetCreatePackingPlace);
        }

        if (prevProps.editPackingPlace !== this.props.editPackingPlace) {
            this.onGetResponse(this.props.editPackingPlace, this.props.resetEditPackingPlace);
        }
    }

    onGetResponse = (responseObj, resetFunc) => {
        const {status, text} = onGetAlertResponse(responseObj);

        if (status !== '' && text !== '') {
            sweetAlert(status, text).then(() => {
                if (status === 'success') {
                    this.props.resetPackingListWizardPageWithoutTabs();
                    this.props.fetchProductsListForPacking(this.props.invoiceID);
                } else {
                    resetFunc();
                }

                window.scrollTo({top: 0});
            });
        }
    };

    onAddPlace = (placebunchIdx) => {
        const {formMode} = this.props;
        const {fields} = this.state;

        const currentPlacebunch = fields[placebunchIdx].placebunch;
        const {form, title, ...rest} = currentPlacebunch[currentPlacebunch.length - 1];
        let newPlace = {};

        const newForm = form.map((item) => {
            let {name, type, value} = item;

            const regExp = /^[^[]*|\[(.*?)]/gi;

            const newName = name
                .match(regExp)
                .map((item, idx) => {
                    if (idx === 2) {
                        const regExp = /[^\][]+(?=])/gi;
                        return item.replace(regExp, (item) => item + (placebunchIdx + 1));
                    }

                    return item;
                })
                .join('');

            if (type === 'switcher') value = 0;
            if (type === 'text') value = '';
            if (type === 'select') value = item.list[0].value;

            return {
                ...item,
                name: newName,
                value,
            };
        });

        if (formMode === 'EDIT' && title) {
            const newTitle = title.replace(/\d+/, (item) => +item.match(/\d+/) + 1);
            newPlace = {
                ...rest,
                form: newForm,
                title: newTitle,
            };

        } else {
            newPlace = {
                ...rest,
                form: newForm,
            };
        }

        const newFields = [
            ...fields.slice(0, placebunchIdx),
            {
                placebunch: [
                    ...currentPlacebunch,
                    newPlace,
                ],
            },
            ...fields.slice(placebunchIdx + 1),
        ];

        this.setState({fields: newFields});
    };

    onDeletePlace = (placebunchIdx, placeIdx) => {
        const {staticData, formMode} = this.props;
        const {delete_place_question} = staticData;
        const {fields} = this.state;

        sweetAlert('question', delete_place_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const currentPlacebunch = fields[placebunchIdx].placebunch;

                    const placesAfterDeleted = currentPlacebunch.slice(placeIdx + 1).map((item) => {
                        if (formMode === 'EDIT' && item.title) {
                            const regExp = /\d+/g;
                            const placeNumber = item.title.match(regExp)[0];
                            const newTitle = item.title.replace(placeNumber, +placeNumber - 1);

                            return {
                                ...item,
                                title: newTitle,
                            };
                        }

                        return item;
                    });

                    const newFields = [
                        ...fields.slice(0, placebunchIdx),
                        {
                            placebunch: [
                                ...currentPlacebunch.slice(0, placeIdx),
                                ...placesAfterDeleted,
                            ],
                        },
                        ...fields.slice(placebunchIdx + 1),
                    ];

                    this.setState({fields: newFields});
                }
            });
    };

    onResetFormFieldError = () => {
        if (this.state.formError) {
            onResetFormError(this.state.formID);
            this.setState({formError: false});
        }

        if (this.state.isSaveDisabled) {
            this.setState({isSaveDisabled: false});
        }
    };

    onUpdateFields = (updatedPlacebunchForm, currentPlacebunch, placebunchIdx, placeIdx) => {
        const {fields} = this.state;

        const newFields = [
            ...fields.slice(0, placebunchIdx),
            {
                placebunch: [
                    ...currentPlacebunch.slice(0, placeIdx),
                    {
                        ...currentPlacebunch[placeIdx],
                        form: updatedPlacebunchForm,

                    },
                    ...currentPlacebunch.slice(placeIdx + 1),
                ],
            },
            ...fields.slice(placebunchIdx + 1),
        ];

        this.setState({fields: newFields});
        this.onResetFormFieldError();
    };

    onChangeValue = (name, value, placebunchIdx, placeIdx) => {
        const {fields} = this.state;

        const currentPlacebunch = fields[placebunchIdx].placebunch;
        const currentPlacebunchForm = currentPlacebunch[placeIdx].form;
        const currentPlacebunchFormList = currentPlacebunch[placeIdx].list;
        let updatedPlacebunchForm = onChangeFieldValue(currentPlacebunchForm, name, value);

        /*
            withRecalculateNetWeight check
            in case when current name includes offer net_weight and if it was changed in the table
            */
        const regExp = /\[offer]\[\d+]\[net_weight]/;
        const withRecalculateNetWeight = name.match(regExp) !== null;
        if (withRecalculateNetWeight) {
            /* find common net_weight field */
            const netWeightField = updatedPlacebunchForm.find(({name}) =>
                name.includes('[net_weight]') && !name.includes('[offer]')
            );

            const offerNetWeightFieldsWithValue = updatedPlacebunchForm
                .filter(({name}) => name.includes('[net_weight]') && name.includes('[offer]'))
                .filter(({value}) => value);

            let updatedNetWeightValue = 0;
            if (offerNetWeightFieldsWithValue.length > 0) {
                offerNetWeightFieldsWithValue.forEach(({name, value}) => {
                    const partOfName = name.match(regExp)[0];
                    const regExpId = /\d+/;
                    const id = partOfName.match(regExpId)[0];
                    const currentOfferFromList = currentPlacebunchFormList.find((place) => place.id === parseInt(id));
                    const quantity = currentOfferFromList.quantity;
                    let currentValue = value.floatValue || parseFloat(value);
                    currentValue = currentValue * quantity;
                    /*
                    Floating point arithmetic is not always 100% accurate
                    To solve the problem, we need to multiply and divide on 100 (for float with ,00)
                    */
                    updatedNetWeightValue = ((updatedNetWeightValue * 100) + (currentValue * 100)) / 100;
                });
            }

            if (updatedNetWeightValue % 1 !== 0) { // float number
                updatedNetWeightValue = updatedNetWeightValue
                    .toString()
                    .replace('.', ',');
            }

            updatedPlacebunchForm = onChangeFieldValue(
                updatedPlacebunchForm,
                netWeightField.name,
                updatedNetWeightValue
            );
        }

        this.onUpdateFields(updatedPlacebunchForm, currentPlacebunch, placebunchIdx, placeIdx);
    };

    onSwitcherChange = ({target: {name, checked}}, placebunchIdx, placeIdx) => {
        const {fields} = this.state;

        const currentPlacebunch = fields[placebunchIdx].placebunch;
        const currentPlacebunchForm = currentPlacebunch[placeIdx].form;

        const currentSwitcherIdx = currentPlacebunchForm.findIndex((field) => field.name === name);
        const currentSwitcher = {
            ...currentPlacebunchForm[currentSwitcherIdx],
            value: checked ? 1 : 0,
        };

        const updatedCurrentPlacebunchForm = [
            ...currentPlacebunchForm.slice(0, currentSwitcherIdx),
            currentSwitcher,
            ...currentPlacebunchForm.slice(currentSwitcherIdx + 1),
        ];

        this.onUpdateFields(updatedCurrentPlacebunchForm, currentPlacebunch, placebunchIdx, placeIdx);
    };

    onInputChange = ({target: {name, value}}, placebunchIdx, placeIdx) => {
        this.onChangeValue(name, value, placebunchIdx, placeIdx);
    };

    onClickSelectItem = (name, {value}, placebunchIdx, placeIdx) => {
        this.onChangeValue(name, value, placebunchIdx, placeIdx);
    };

    onRadioButtonChange = ({target: {id, name, value}}, placebunchIdx, placeIdx) => {
        const {fields} = this.state;

        const currentPlacebunch = fields[placebunchIdx].placebunch;
        const currentPlacebunchForm = currentPlacebunch[placeIdx].form;
        const updatedFields = onChangeRadioButtonValue(currentPlacebunchForm, id, name, value, true);

        this.onUpdateFields(updatedFields, currentPlacebunch, placebunchIdx, placeIdx);
    };

    onCheckboxChange = (e, placebunchIdx, placeIdx) => this.onSwitcherChange(e, placebunchIdx, placeIdx);

    onUpdateSerialNumbers = ({name, value}, placebunchIdx, placeIdx) => {
        this.onChangeValue(name, value, placebunchIdx, placeIdx);
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {target: form} = e;
        const {
            formMode, invoiceID, dataForPacking, selectedStrategy,
            placeEditingID, packingForm, packingEditingForm,
            packingFormData, packingEditingFormData,
        } = this.props;

        let valid = true;
        let fields = packingForm;
        let dataFromFields = packingFormData;

        let object = {
            strategy: selectedStrategy,
            ...dataForPacking,
            ...dataFromFields,
        };

        if (formMode === 'EDIT') {
            fields = packingEditingForm;
            dataFromFields = packingEditingFormData;
            object = dataFromFields;
        }

        fields.forEach(({placebunch}) => {
            placebunch.forEach(({form: fields}) => {
                valid = onCheckingRequiredFieldData(fields, form);
            });
        });

        if (valid) {
            let objectWithoutEmptyValue = {};
            Object.keys(object).forEach((key) => {
                if (object[key] !== '') {
                    objectWithoutEmptyValue = {
                        ...objectWithoutEmptyValue,
                        [key]: object[key],
                    }
                }
            });

            const formData = onCreateFormData(objectWithoutEmptyValue);

            if (formMode === 'EDIT') {
                this.props.fetchEditPackingPlace(placeEditingID, {formData});

            } else {
                this.props.fetchPackingPlace(invoiceID, {formData});
            }

        } else {
            this.setState({isSaveDisabled: true, formError: true});
        }
    };

    onCancel = () => {
        if (this.props.formMode === 'EDIT') {
            this.props.resetPackingEditingForm();
        } else {
            this.props.resetPackingListWizardStateToInitial();
        }

        window.scrollTo({top: 0});
    };

    render() {
        const {fields, formID, isSaveDisabled} = this.state;
        const {staticData, formMode, selectedStrategy, createPackingPlace, editPackingPlace} = this.props;
        const loading = formMode === 'EDIT' ? editPackingPlace.loading : createPackingPlace.loading;

        const {
            cancel, save, add_place, remove_place,
        } = staticData.pages.shipment_lot.button;
        const cx = classnames.bind(styles);

        return (
            <form id={formID} onSubmit={this.onSubmit}>
                {fields && fields.map(({placebunch}, placebunchIdx) => {
                    const strategy = formMode === 'EDIT'
                        ? placebunch[placebunchIdx].strategy
                        : selectedStrategy;

                    const placeBunch = placebunch.map((place, placeIdx) => {
                        const placeClasses = cx('place', {
                            'without-top-indent': formMode === 'EDIT',
                            'with-underline': strategy === 'supply_set_load',
                        });

                        const packingPlaceParams = {
                            placebunchIdx,
                            placeIdx,
                            strategy,
                            formMode,
                            withPlaceTable: strategy !== 'supply_set_load' ||
                                (strategy === 'supply_set_load' && placeIdx === placebunch.length - 1),
                            onSwitcherChange: (e) => this.onSwitcherChange(e, placebunchIdx, placeIdx),
                            onInputChange: (e) => this.onInputChange(e, placebunchIdx, placeIdx),
                            onClickSelectItem: (...args) => this.onClickSelectItem(...args, placebunchIdx, placeIdx),
                            onRadioButtonChange: (e) => this.onRadioButtonChange(e, placebunchIdx, placeIdx),
                            onCheckboxChange: (e) => this.onCheckboxChange(e, placebunchIdx, placeIdx),
                            onUpdateSerialNumbers: (offerData) => this.onUpdateSerialNumbers(offerData, placebunchIdx, placeIdx),
                        };

                        return (
                            <div key={placeIdx} className={placeClasses}>
                                <PackingPlace {...place} {...packingPlaceParams}/>

                                {(strategy === 'supply_set_load' && placeIdx > 0) &&
                                    <Button styleType={'WITH_MINUS'}
                                            classNames={styles.remove}
                                            onClick={() => this.onDeletePlace(placebunchIdx, placeIdx)}>
                                        {remove_place}
                                    </Button>}
                            </div>
                        );
                    });

                    return (
                        <React.Fragment key={placebunchIdx}>
                            <div className={styles['places-block']}>
                                {placeBunch}
                            </div>

                            {strategy === 'supply_set_load' &&
                                <Button styleType={'WITH_PLUS'}
                                        classNames={styles.add}
                                        onClick={() => this.onAddPlace(placebunchIdx)}>
                                    {add_place}
                                </Button>}
                        </React.Fragment>
                    );
                })}

                <div className={styles['btn-block']}>
                    <Button styleType={'SECONDARY'} classNames={styles.btn} onClick={this.onCancel}>{cancel}</Button>
                    <Button type={'submit'}
                            classNames={styles.btn}
                            dataLoading={loading}
                            isDisabled={isSaveDisabled}>
                        {save}
                    </Button>
                </div>
            </form>
        );
    }

    static defaultProps = {
        formMode: 'CREATE',
    };

    static propTypes = {
        staticData: PropTypes.object,
        formMode: PropTypes.oneOf(['CREATE', 'EDIT']),
        invoiceID: PropTypes.number,
        placeEditingID: PropTypes.number,
        dataForPacking: PropTypes.object,
        selectedStrategy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        packingForm: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        packingEditingForm: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        packingFormData: PropTypes.object,
        packingEditingFormData: PropTypes.object,
        createPackingPlace: PropTypes.shape({
            loading: PropTypes.bool,
        }),
        editPackingPlace: PropTypes.shape({
            loading: PropTypes.bool,
        }),
        fetchPackingPlace: PropTypes.func,
        fetchEditPackingPlace: PropTypes.func,
        fetchProductsListForPacking: PropTypes.func,
        updatePackingForm: PropTypes.func,
        resetPackingForm: PropTypes.func,
        resetCreatePackingPlace: PropTypes.func,
        resetPackingEditingForm: PropTypes.func,
        resetEditPackingPlace: PropTypes.func,
        resetPackingListWizardStateToInitial: PropTypes.func,
        resetPackingListWizardPageWithoutTabs: PropTypes.func,
    };
}

const mapServicesToProps = ({packingListWizardService}) => ({
    fetchPackingPlace: createPackingPlaceRequest(packingListWizardService.createPackingPlace),
    fetchEditPackingPlace: editPackingPlaceRequest(packingListWizardService.editPackingPlace),
    fetchProductsListForPacking: productsListForPackingRequest(packingListWizardService.getProductsListForPacking),
});

const mapStateToProps = ({activityHub: {contractMaps: {packingListWizard}}}) => {
    const {
        productsList, suggestStrategy, packingForm,
        packingEditingForm, createPackingPlace, editPackingPlace,
    } = packingListWizard;

    return {
        invoiceID: productsList.invoiceID,
        placeEditingID: packingEditingForm.placeEditingID,
        dataForPacking: productsList.dataForPacking,
        selectedStrategy: suggestStrategy.selectedStrategy,
        packingForm: packingForm.list,
        packingEditingForm: packingEditingForm.list,
        packingFormData: packingForm.data,
        packingEditingFormData: packingEditingForm.data,
        createPackingPlace,
        editPackingPlace,
    };
};

const mapDispatchToProps = (dispatch, {
    fetchPackingPlace,
    fetchEditPackingPlace,
    fetchProductsListForPacking,
}) => {
    const {
        packingListAction: {
            resetPackingListWizardStateToInitial,
            resetPackingListWizardPageWithoutTabs,
        },
        packingFormAction: {
            updatePackingForm,
            resetPackingForm,
            resetCreatePackingPlace,
            resetPackingEditingForm,
            resetEditPackingPlace,
        },
    } = contractMapsAction;

    return bindActionCreators({
        fetchPackingPlace,
        fetchEditPackingPlace,
        fetchProductsListForPacking,
        updatePackingForm,
        resetPackingForm,
        resetCreatePackingPlace,
        resetPackingEditingForm,
        resetEditPackingPlace,
        resetPackingListWizardStateToInitial,
        resetPackingListWizardPageWithoutTabs,
    }, dispatch);
};

export default compose(
    withDataService('activity_hub.e_contracts.pages.packing_list_wizard'),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(PackingForm);