import {combineReducers} from 'redux';

import quotationReducer from './quotation-page';
import categories from './home/categories';
import section from './home/section';
import products from './products';
import productDetails from './product-page/product-details';
import addToQuotation from './product-page/add-to-quotation';

export const marketplaceReducer = combineReducers({
    quotation: quotationReducer,
    categories,
    section,
    products,
    productDetails,
    addToQuotation,
});