export const resetPackingListWizardStateToInitial = () => {
    return {type: 'RESET_PACKING_LIST_WIZARD_STATE_TO_INITIAL'};
};

export const resetPackingListWizardPageWithoutTabs = () => {
    return {type: 'RESET_PACKING_LIST_WIZARD_PAGE_WITHOUT_TABS'};
};

export const resetPackingListWizardPage = () => {
    return {type: 'RESET_PACKING_LIST_WIZARD_PAGE'};
};

export const resetUnpackPackedProduct = () => {
    return {type: 'RESET_UNPACK_PACKED_PRODUCT'};
};

export const resetConfirmPackingList = () => {
    return {type: 'RESET_CONFIRM_PACKING_LIST'};
};