import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../common/hoc';
import {priceHandler} from '../../../../../../../../utils';

import OptionsMenu from '../options-menu';
import ProductStatus from '../product-status';
import Tooltip from '../../../../../../../common/tooltip';
import InfoMark from '../../../../../../../common/info-mark';
import Preloader from '../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './product-panel.module.scss';

import {colors} from '../../../../../../../common/colors';

const ProductPanel = (props) => {
    const {
        staticData, id, offer_number, part_number, name, condition_value,
        main_data, offer, stat, href, buttons, review, offerDeleting,
        offerOptionsMenuDisabled, onRemoveProductOffer,
    } = props;
    const {price, currency, currency_list} = offer;
    const {reserved, total, viewed} = stat;
    const {views_label} = staticData.activity_hub.my_products.product_panel;
    const cx = classnames.bind(styles);

    const xsDevice = useMediaQuery({maxWidth: 575.9});

    const offerID = href
        ? <Link to={href} className={styles.number}>{offer_number}</Link>
        : <div className={styles.number}>{offer_number}</div>

    const titleBlock = href
        ? (
            <Link to={href} className={styles['title-block']}>
                <div className={styles['part-number']}>{part_number}</div>
                <div className={styles.name}>{name}</div>
            </Link>
        ) : (
            <div className={styles['title-block']}>
                <div className={styles['part-number']}>{part_number}</div>
                <div className={styles.name}>{name}</div>
            </div>
        );

    const contentBlockHTML = main_data.map(({label, value}, idx) => {
        return (
            <div className={styles.item} key={idx}>
                <div className={styles.label}>{label}{xsDevice && ':'}</div>
                <div className={styles.value}>{value}</div>
            </div>
        );
    });

    const contentBlock = href
        ? <Link to={href} className={styles.content}>{contentBlockHTML}</Link>
        : <div className={styles.content}>{contentBlockHTML}</div>;

    return (
        <div id={id} className={cx('container', {'deleting': offerDeleting})}>
            {offerDeleting && (
                <div className={styles['hiding-container']}>
                    <Preloader classNames={styles.preloader}/>
                </div>
            )}

            {offerID}
            <OptionsMenu buttons={buttons}
                         menuClassNames={styles['options-menu']}
                         isDisabledDelete={offerOptionsMenuDisabled}
                         onRemoveProductOffer={onRemoveProductOffer}
            />

            <div className={styles.main}>
                <div className={styles['main-info']}>
                    {titleBlock}
                    <div className={styles.condition}>{condition_value}</div>
                    <div className={styles['price-block']}>
                        <div className={styles.price}>{priceHandler(price)} {currency}
                            {currency_list.length !== 0 && (
                                <Tooltip tooltip={<div>{currency_list.join(', ')}</div>}
                                         contentClassNames={styles['info-content']}
                                         placement={'top'}
                                         minWidth={50}
                                         maxWidth={150}>
                                    <span className={styles.info}
                                          onClick={(e) => e.stopPropagation()}>
                                        <InfoMark classNames={styles['info-icon']} markColor={colors.darkGrey}/>
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                        <div className={styles.condition}>{condition_value}</div>
                    </div>
                </div>

                <div className={styles.description}>
                    {contentBlock}

                    <div className={styles.info}>
                        <div className={styles['stock-count']}>{total}</div>
                        {reserved && <div className={styles['reserved-count']}>{reserved}</div>}
                        <div className={styles['views-count']}>{views_label}: {viewed}</div>
                        <ProductStatus {...review} classNames={styles.status}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

ProductPanel.defaultProps = {
    offerDeleting: false,
};

ProductPanel.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.any,
    offer_number: PropTypes.string,
    part_number: PropTypes.string,
    name: PropTypes.string,
    condition_value: PropTypes.string,
    main_data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    offer: PropTypes.shape({
        price: PropTypes.string,
        currency: PropTypes.string,
    }),
    stat: PropTypes.shape({
        reserved: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        total: PropTypes.string,
        viewed: PropTypes.number,
    }),
    href: PropTypes.string,
    buttons: PropTypes.array,
    review: PropTypes.object,
    offerDeleting: PropTypes.bool,
};

export default withDataService()(ProductPanel);