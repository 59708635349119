import {profileAction, profileBankAccountAction, profileShipmentOrganizationAction} from './profile';
import {placeProductAction, placeProductSuggestAction} from './place-product';
import * as contractMapsAction from './contract-maps';

export const switchMenuActiveLinkID = (newID) => {
    return {
        type: 'SWITCH_MENU_ACTIVE_LINK_ID',
        payload: newID,
    };
};

export const toggleMyProductsFilter = (newState) => {
    return {
        type: 'TOGGLE_MY_PRODUCTS_FILTER',
        payload: newState,
    };
};

export const resetRemoveProductOffer = () => {
    return {type: 'RESET_REMOVE_PRODUCT_OFFER'};
};

export const resetRemoveRFQItem = () => {
    return {type: 'RESET_REMOVE_RFQ_ITEM'};
};

export const resetCreateEContract = () => {
    return {type: 'RESET_CREATE_E_CONTRACT'};
};

export const resetRequestOnButtonUrl = () => {
    return {type: 'RESET_REQUEST_ON_BUTTON_URL'};
};

export const updateNotificationsData = (
    list, itemId = null, key = 'viewed', value = false,
    switchType = '', selectedAll = null,
) => {
    let payload = list;

    if (key === 'viewed') {
        payload = list.map((item) => {
            if (item.id === itemId) {
                if (!item.viewed && value) { // value = true
                    return {...item, opened: value, viewed: value};
                }

                return {...item, opened: value};
            }

            return {...item, opened: false};
        });
    }

    if (key === 'switch') {
        const currentNotificationIdx = list.findIndex((item) => item.id === itemId);
        const prevNotification = list[currentNotificationIdx - 1];
        const nextNotification = list[currentNotificationIdx + 1];

        payload = list.map((item) => {
            if (switchType === 'prev') {
                return {...item, opened: item.id === prevNotification.id};
            }

            if (switchType === 'next') {
                return {...item, opened: item.id === nextNotification.id};
            }

            return null;
        });
    }

    if (key === 'delete') {
        let updatedList = list;
        itemId.forEach(id => {
            const idx = updatedList.findIndex((item) => item.id === id);
            updatedList = [
                ...updatedList.slice(0, idx),
                ...updatedList.slice(idx + 1),
            ];
        });

        if (selectedAll !== null) {
            payload = {
                list: updatedList,
                selectedList: [],
                selectedAll,
            };

        } else {
            payload = updatedList;
        }
    }

    if (key === 'checked') {
        let updatedList = list.map((item) => {
            return {...item, checked: selectedAll};
        });

        if (selectedAll === null) {
            updatedList = list.map((item) => {
                if (item.id === parseInt(itemId)) {
                    return {...item, checked: value};
                }

                return item;
            });
        }

        const selectedList = updatedList.filter(({checked}) => checked === 1);

        // This case is variable. Show checkbox if we have one selected item at least
        selectedAll = selectedList.length > 0 ? 1 : 0;

        payload = {
            list: updatedList,
            selectedList: selectedList,
            selectedAll,
        };
    }

    if (key === 'unmount') {
        //reset filter
        payload = list.map((item) => ({...item, checked: 0}));
    }

    return {type: 'UPDATE_NOTIFICATIONS', payload};
};

export const updateNotificationsFilters = (filters, currentId) => {
    const payload = filters.map((filter) => ({...filter, isActive: filter.id === currentId}));
    return {type: 'UPDATE_NOTIFICATIONS_FILTERS', payload};
};

export const resetRemoveAttachmentFileState = () => {
    return {type: 'RESET_REMOVE_ATTACHMENT_FILE_STATE'};
};

export {profileAction, profileBankAccountAction, profileShipmentOrganizationAction};
export {placeProductAction, placeProductSuggestAction};
export {contractMapsAction};