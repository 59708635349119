import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {rfq_statuses} from '../../../../../../common/status-indicator/statuses';

import ButtonConstructor from '../../../../../../../../common/button-constructor';
import Preloader from '../../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './sub-quotation-item.module.scss';

const SubQuotationItem = (props) => {
    const {
        rfq_sub_id, list, removingItem, buttons, updatingItem, status,
        onRemoveSubQuotationItem: onRemove,
        onButtonClick,
    } = props;
    const {userInteraction} = useSelector(({profile}) => profile);

    let isViewContractButton = false;
    if (buttons.length !== 0) {
        const {method} = buttons[0].remote;
        isViewContractButton = method === 'GET';
    }

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', {
        'without-btn': userInteraction === 'exporter' && !isViewContractButton,
    });

    const createContractLoading = updatingItem && updatingItem.rfq_sub_id === rfq_sub_id ? updatingItem.loading : false;
    const createContractBtn = buttons.length > 0 && (userInteraction === 'importer' || isViewContractButton)
        ? <ButtonConstructor {...buttons[0]}
                             classNames={styles.btn}
                             preloaderClassNames={styles['btn-preloader']}
                             dataLoading={createContractLoading}
                             onClick={(...args) => onButtonClick(rfq_sub_id, ...args)}/>
        : null;

    const descriptions = list.map(({id, name, part_number, row}, idx) => {
        const rows = row.filter((item, idx) => idx !== 0);
        const descriptionsRows = rows.map(({field, value}, idx) => {
            return (
                <div className={styles.item} key={idx}>
                    <div className={styles.label}>{field}:</div>
                    <div className={styles.value}>{value}</div>
                </div>
            );
        });

        let deleteItemBtn = <div className={styles.delete} onClick={() => onRemove(id)}/>;

        if (removingItem) {
            const {loading, offer_id} = removingItem;

            if (loading && id === offer_id) {
                deleteItemBtn = (
                    <div className={styles['preloader-block']}>
                        <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                    </div>
                );
            }
        }

        return (
            <div className={styles['product-card']} key={idx}>
                {removingItem && removingItem.loading && <div className={styles['hiding-container']}/>}

                <div className={styles['title-row']}>
                    <div className={styles.number}>{idx + 1}.</div>
                    <div className={styles['title-block']}>
                        <div className={styles.title}>{name}</div>
                        <div className={styles.subtitle}>{part_number}</div>
                    </div>
                    {status.value === rfq_statuses.contracted.value && deleteItemBtn}
                </div>

                <div className={styles['description-row']}>
                    {descriptionsRows}
                </div>
            </div>
        );
    });

    return (
        <div className={containerClasses}>
            {createContractBtn}
            {descriptions}
        </div>
    );
};

SubQuotationItem.defaultProps = {
    onRemoveSubQuotationItem: () => {
    },
};

SubQuotationItem.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        part_number: PropTypes.string,
        row: PropTypes.arrayOf(PropTypes.shape({
            field: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })),
    })),
    removingItem: PropTypes.shape({
        loading: PropTypes.bool,
        offer_id: PropTypes.number,
    }),
    buttons: PropTypes.arrayOf(PropTypes.object),
    updatingItem: PropTypes.shape({
        loading: PropTypes.bool,
        rfq_sub_id: PropTypes.number,
    }),
    status: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
    onRemoveSubQuotationItem: PropTypes.func,
    onButtonClick: PropTypes.func,
};

export default SubQuotationItem;