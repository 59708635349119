import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../common/hoc';
import {withHubSideMenu} from '../../../../common/hoc';
import {switchMenuActiveLinkID} from '../../../../../../../actions/activity-hub';
import {withServices} from '../../../../../../service-context';
import {
    contractMapRequest,
    updatingContractMapRequest,
} from '../../../../../../../reducers/activity-hub/contract-maps/contract-map';

import ContractForSupply from './contract-for-supply';
import Preloader from '../../../../../../common/preloader';

class ContractForSupplyContainer extends Component {
    componentDidMount() {
        const {match: {params: {id}}, pageID, menuActiveLinkID} = this.props;

        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        this.props.fetchContractMap(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {match: {params: {id}}} = this.props;

            this.props.fetchContractMapUpdating(id);
        }
    }

    render() {
        const {loading, updating, data} = this.props;

        return (data && !loading && !updating)
            ? <ContractForSupply {...this.state} data={data}/>
            : <Preloader/>;
    }

    static propTypes = {
        language: PropTypes.string,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        switchMenuActiveLinkID: PropTypes.func,
        fetchContractMap: PropTypes.func,
        fetchContractMapUpdating: PropTypes.func,
    };
}

const mapServicesToProps = ({eContractsService}) => ({
    fetchContractMap: contractMapRequest(eContractsService.getContractMap),
    fetchContractMapUpdating: updatingContractMapRequest(eContractsService.getContractMap),
});

const mapStateToProps = ({profile: {language}, activityHub: {contractMaps: {contractMap}, utils}}) => ({
    language,
    menuActiveLinkID: utils.menuActiveLinkID,
    loading: contractMap.loading,
    updating: contractMap.updating,
    data: contractMap.data,
});

const mapDispatchToProps = (dispatch, {fetchContractMap, fetchContractMapUpdating}) => {
    return bindActionCreators({
        fetchContractMap,
        fetchContractMapUpdating,
        switchMenuActiveLinkID,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ContractForSupplyContainer);