import {createRequestHandler, createAlertResponse} from '../../../utils';

export const UPLOAD_PLACE_PRODUCT_FORM_DATA_REQUEST = 'UPLOAD_PLACE_PRODUCT_FORM_DATA_REQUEST';
export const UPLOAD_PLACE_PRODUCT_FORM_DATA_SUCCESS = 'UPLOAD_PLACE_PRODUCT_FORM_DATA_SUCCESS';
export const UPLOAD_PLACE_PRODUCT_FORM_DATA_FAILURE = 'UPLOAD_PLACE_PRODUCT_FORM_DATA_FAILURE';

export const uploadFormDataRequest = createRequestHandler('UPLOAD_PLACE_PRODUCT_FORM_DATA', {
    notAcceptableErrorRedirect: false,
    forbiddenErrorRedirect: false,
});

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const uploadFormData = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case UPLOAD_PLACE_PRODUCT_FORM_DATA_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case UPLOAD_PLACE_PRODUCT_FORM_DATA_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case UPLOAD_PLACE_PRODUCT_FORM_DATA_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                message: null,
                loading: false,
                error,
            };

        case 'RESET_PLACE_PRODUCT_FORM_DATA_UPLOAD_RESPONSE':
            return initialState;

        default:
            return state;
    }
};

export default uploadFormData;