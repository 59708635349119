import React, {useState} from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import SkeletonPreloader from '../skeleton-preloader';

import classnames from 'classnames/bind';
import styles from './image.module.scss';

const Image = ({source, alt, classNames, skeletonClassNames}) => {
    const [isSourceWebp, setIsSourceWebp] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    const cx = classnames.bind(styles);
    const imageClasses = cx('image', classNames);

    let image = <img src={source} alt={alt} className={imageClasses} onLoad={() => setIsLoaded(true)}/>;

    if (_.isObject(source)) {
        const {jpg, webp} = source.xs;

        image = (
            <picture onError={() => setIsSourceWebp(false)} onLoad={() => setIsLoaded(true)}>
                {isSourceWebp && <source srcSet={webp} type="image/webp"/>}
                <img src={jpg} alt={alt} className={imageClasses}/>
            </picture>
        );
    }

    return (
        <SkeletonPreloader loading={!isLoaded} classNames={skeletonClassNames}>
            {image}
        </SkeletonPreloader>
    );
};

Image.defaultProps = {
    alt: 'Image',
};

Image.propTypes = {
    source: PropTypes.oneOfType([
        PropTypes.shape({
            xs: PropTypes.shape({
                jpg: PropTypes.string,
                webp: PropTypes.string,
            }),
        }),
        PropTypes.string,
    ]).isRequired,
    alt: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    skeletonClassNames: PropTypes.string,
};

export default Image;