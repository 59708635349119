import {createRequestHandler} from '../../../../utils';
import {CLEAR_ALL_RFS_SUCCESS} from './clear-all-rfs';
import {COUNT_OF_SELECTED_ITEM_UPDATING_SUCCESS} from "./сount-of-item";
import {REMOVE_ITEM_FROM_RFS_SUCCESS} from "./remove-item-from-rfs";
import {REPLACE_ITEM_IN_RFS_SUCCESS} from "./replace-item-in-rfs";

export const RFS_PRODUCTS_REQUEST = 'RFS_PRODUCTS_REQUEST';
export const RFS_PRODUCTS_SUCCESS = 'RFS_PRODUCTS_SUCCESS';
export const RFS_PRODUCTS_FAILURE = 'RFS_PRODUCTS_FAILURE';

export const RFS_PRODUCTS_UPDATING_REQUEST = 'RFS_PRODUCTS_UPDATING_REQUEST';
export const RFS_PRODUCTS_UPDATING_SUCCESS = 'RFS_PRODUCTS_UPDATING_SUCCESS';
export const RFS_PRODUCTS_UPDATING_FAILURE = 'RFS_PRODUCTS_UPDATING_FAILURE';

export const rfsProductsRequest = createRequestHandler('RFS_PRODUCTS');
export const rfsUpdatingProductsRequest = createRequestHandler('RFS_PRODUCTS_UPDATING');

const initialState = {
    products: [],
    loading: true,
    updating: false,
    error: null,
    summary: null,
};

const rfsProducts = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case RFS_PRODUCTS_REQUEST:
            return initialState;

        case RFS_PRODUCTS_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case RFS_PRODUCTS_SUCCESS:
        case RFS_PRODUCTS_UPDATING_SUCCESS:
        case COUNT_OF_SELECTED_ITEM_UPDATING_SUCCESS:
        case REPLACE_ITEM_IN_RFS_SUCCESS:
        case REMOVE_ITEM_FROM_RFS_SUCCESS:
        case CLEAR_ALL_RFS_SUCCESS:
            const {summary, list, total_count} = payload;
            if (summary) {
                return {
                    products: {
                        list,
                        total_count,
                    },
                    loading: false,
                    updating: false,
                    error: null,
                    summary,
                };
            }

            return {
                ...state,
                products: {
                    list,
                    total_count,
                },
                loading: false,
                updating: false,
                error: null,
            };

        case RFS_PRODUCTS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case RFS_PRODUCTS_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default rfsProducts;