import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../common/hoc';
import {withServices} from '../../../../../../../service-context';
import {
    rfsProductsRequest,
    rfsUpdatingProductsRequest,
} from '../../../../../../../../reducers/marketplace/quotation-page/request-for-supply/rfs-products';
import {removeItemRequest} from '../../../../../../../../reducers/marketplace/quotation-page/request-for-supply/remove-item-from-rfs';
import {replaceItemRequest} from '../../../../../../../../reducers/marketplace/quotation-page/request-for-supply/replace-item-in-rfs';
import {numberOfItemsRequest} from '../../../../../../../../reducers/marketplace/quotation-page/request-for-supply/сount-of-item';
import {clearAllRequest} from '../../../../../../../../reducers/marketplace/quotation-page/request-for-supply/clear-all-rfs';
import {requestForSupplyAction} from '../../../../../../../../actions/marketplace';
import {onGetAlertResponse} from '../../../../../../../../utils';
import sweetAlert from '../../../../../../../common/sweet-alert';

import RequestForSupply from './request-for-supply';
import EmptyRequestList from '../../common/empty-request-list';
import Preloader from '../../../../../../../common/preloader';

class RequestForSupplyContainer extends Component {
    initState = {
        removingItemId: null,
        replaceItemId: null,
        countOfItemId: null,
        resetItemCounter: false,
    };

    state = this.initState;

    componentDidMount() {
        this.props.fetchRFSProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchRFSProducts();
        }

        if (prevProps.removeItemFromRFS !== this.props.removeItemFromRFS) {
            this.onShowSweetAlert(
                this.props.removeItemFromRFS,
                this.props.resetRemoveItemFromRFS,
            );
        }

        if (prevProps.replaceItemInRFS !== this.props.replaceItemInRFS) {
            this.onShowSweetAlert(
                this.props.replaceItemInRFS,
                this.props.resetReplaceItemInRFS,
            );
        }

        if (prevProps.countOfItem !== this.props.countOfItem) {
            this.onShowSweetAlert(
                this.props.countOfItem,
                this.props.resetUpdatedTheNumberOfItemsStatus,
            );
        }

        if (prevProps.clearAllRFSResponse !== this.props.clearAllRFSResponse) {
            this.onShowSweetAlert(
                this.props.clearAllRFSResponse,
                this.props.clearAllRFS,
            );
        }

        if (prevProps.products !== this.props.products) {
            this.setState({...this.initState});
        }
    }

    onRemoveItem = (id) => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.setState({removingItemId: id});
                    this.props.removeItemFromRFSList({offer_id: id});
                }
            });
    };

    onReplaceItem = (offerId, changeId) => {
        const {replace_message} = this.props.staticData.marketplace.quotation.other_offers_block;

        sweetAlert('question', replace_message)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.setState({replaceItemId: offerId});
                    this.props.replaceItemInRFSList({
                        offer_id: offerId,
                        change_id: changeId,
                    });
                }
            });
    };

    onQuantityChange = (itemParams) => {
        this.props.updatingItemQuantity(itemParams);
        this.setState({countOfItemId: itemParams.offer_id});
    };

    onClearAllItems = () => {
        const {delete_all_items_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_all_items_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.clearAllItems();
                }
            });
    };

    onShowSweetAlert = (sweetAlertObject, clearAlertFunction) => {
        const {status, text} = onGetAlertResponse(sweetAlertObject);

        if (status !== '' && text !== '') {
            if (status === 'success') {
                // need review
                if (this.state.replaceItemId) {
                    this.setState({resetItemCounter: true});
                }
            }

            sweetAlert(status, text).then(() => {
                clearAlertFunction();
            });
        }
    };

    render() {
        const {products} = this.props;

        return !this.props.loading && !Array.isArray(products)
            ? products.total_count !== 0
                ? <RequestForSupply {...this.props} {...this.state}
                                    onRemoveItem={this.onRemoveItem}
                                    onReplaceItem={this.onReplaceItem}
                                    onQuantityChange={this.onQuantityChange}
                                    onClearAllItems={this.onClearAllItems}
                />
                : <EmptyRequestList
                    staticData={this.props.staticData}
                    marketplaceUrl={this.props.marketplaceUrl}/>
            : <Preloader/>;
    }

    static propTypes = {
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        products: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.shape({
                total_count: PropTypes.number,
            }),
        ]),
        removeItemFromRFS: PropTypes.object,
        replaceItemInRFS: PropTypes.object,
        countOfItem: PropTypes.object,
        clearAllRFSResponse: PropTypes.object,
        language: PropTypes.string,
        fetchRFSProducts: PropTypes.func,
        fetchRFSUpdatingProducts: PropTypes.func,
        removeItemFromRFSList: PropTypes.func,
        replaceItemInRFSList: PropTypes.func,
        updatingItemQuantity: PropTypes.func,
        clearAllItems: PropTypes.func,
        resetUpdatedTheNumberOfItemsStatus: PropTypes.func,
        resetRemoveItemFromRFS: PropTypes.func,
        resetReplaceItemInRFS: PropTypes.func,
        clearAllRFS: PropTypes.func,
    };
}

const mapServicesToProps = ({quotationService}) => ({
    fetchRFSProducts: rfsProductsRequest(quotationService.getRFSProducts),
    fetchRFSUpdatingProducts: rfsUpdatingProductsRequest(quotationService.getRFSProducts),
    removeItemFromRFSList: removeItemRequest(quotationService.removeItemFromRFSList),
    replaceItemInRFSList: replaceItemRequest(quotationService.replaceItemInRFSList),
    updatingItemQuantity: numberOfItemsRequest(quotationService.updateNumOfItemInQuotation),
    clearAllItems: clearAllRequest(quotationService.clearRFSList),
});

const mapStateToProps
    = ({
           marketplace: {
               quotation: {
                   rfsProducts,
                   removeItemFromRFS,
                   replaceItemInRFS,
                   countOfItem,
                   clearAllRFS: clearAllRFSResponse,
               },
           },
           profile,
       }) => ({
    loading: rfsProducts.loading,
    updating: rfsProducts.updating,
    products: rfsProducts.products,
    summary: rfsProducts.summary,
    error: rfsProducts.error, // need work
    removeItemFromRFS,
    replaceItemInRFS,
    countOfItem,
    clearAllRFSResponse,
    language: profile.language,
});

const mapDispatchToProps = (dispatch, {
    fetchRFSProducts,
    fetchRFSUpdatingProducts,
    removeItemFromRFSList,
    replaceItemInRFSList,
    updatingItemQuantity,
    clearAllItems,
}) => {
    const {
        resetUpdatedTheNumberOfItemsStatus,
        resetRemoveItemFromRFS,
        resetReplaceItemInRFS,
        clearAllRFS,
    } = requestForSupplyAction;

    return bindActionCreators({
        fetchRFSProducts,
        fetchRFSUpdatingProducts,
        removeItemFromRFSList,
        replaceItemInRFSList,
        updatingItemQuantity,
        clearAllItems,
        resetUpdatedTheNumberOfItemsStatus,
        resetRemoveItemFromRFS,
        resetReplaceItemInRFS,
        clearAllRFS,
    }, dispatch);
};

export default compose(
    withDataService(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(RequestForSupplyContainer);