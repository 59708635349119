import React from 'react';
import {Link} from 'react-router-dom';

import {hubURLs} from '../../../../../../services/api-urls';

import classnames from 'classnames/bind';
import styles from './with-tab-header.module.scss';

const withTabHeader = () => (Wrapped) => {
    return (props) => {
        const {staticData: {activity_hub: {pages}}, label} = props;

        const labels = pages.map((page, idx) => {
            const cx = classnames.bind(styles);
            const labelClasses = cx('label', {'active': label === page});

            // temporary
            const pageURL = idx === 0 ? hubURLs.requests : hubURLs.e_contracts;

            return <Link to={pageURL} key={idx} className={labelClasses}>{page}</Link>;
        });

        return (
            <>
                <div className={styles.container}>{labels}</div>
                <Wrapped {...props}/>
            </>
        );
    };
};

export default withTabHeader;