import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const REMOVE_ITEM_FROM_RFS_REQUEST = 'REMOVE_ITEM_FROM_RFS_REQUEST';
export const REMOVE_ITEM_FROM_RFS_SUCCESS = 'REMOVE_ITEM_FROM_RFS_SUCCESS';
export const REMOVE_ITEM_FROM_RFS_FAILURE = 'REMOVE_ITEM_FROM_RFS_FAILURE';

export const removeItemRequest = createRequestHandler('REMOVE_ITEM_FROM_RFS');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const removeItemFromRFS = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case REMOVE_ITEM_FROM_RFS_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case REMOVE_ITEM_FROM_RFS_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case REMOVE_ITEM_FROM_RFS_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_REMOVE_ITEM_FROM_RFS':
            return initialState;

        default:
            return state;
    }
};

export default removeItemFromRFS;