import {createRequestHandler, createAlertResponse} from '../../utils';

export const SEND_POST_REQUEST_ON_BUTTON_URL_REQUEST = 'SEND_POST_REQUEST_ON_BUTTON_URL_REQUEST';
export const SEND_POST_REQUEST_ON_BUTTON_URL_SUCCESS = 'SEND_POST_REQUEST_ON_BUTTON_URL_SUCCESS';
export const SEND_POST_REQUEST_ON_BUTTON_URL_FAILURE = 'SEND_POST_REQUEST_ON_BUTTON_URL_FAILURE';

export const onButtonUrlRequest = createRequestHandler('SEND_POST_REQUEST_ON_BUTTON_URL');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const requestOnButtonUrl = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SEND_POST_REQUEST_ON_BUTTON_URL_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case SEND_POST_REQUEST_ON_BUTTON_URL_SUCCESS:
            if (payload) {
                if (payload.message) {
                    const message = createAlertResponse('success', payload.message);
                    return {
                        loading: false,
                        message,
                        error: null,
                    };
                }

                if (payload.url) {
                    return {
                        loading: false,
                        message: payload,
                        error: null,
                    };
                }
            }

            return initialState;

        case SEND_POST_REQUEST_ON_BUTTON_URL_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_REQUEST_ON_BUTTON_URL':
            return initialState;

        default:
            return state;
    }
};

export default requestOnButtonUrl;