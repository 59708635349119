import React, {useEffect, useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import Accordion from '../../accordion';

import classnames from 'classnames/bind';
import styles from './with-accordion.module.scss';

const withAccordion = () => (Wrapped) => {
    return (props) => {
        const {
            containerClassNames,
            containerActiveClassNames,
            labelClassNames,
            contentClassNames,
            labelTextClassNames,
            manuallyMode = false,
            isOpenManually = false,
            isActiveLabelOnOpen = false,
            turnOffAccordion = false,
            accordionScreen = null,
            withActiveContainer = false,
            turnOffAnimationOnClose = false,
            onToggleAccordion,
            defaultOpen = false,
            ...rest
        } = props;

        const [isOpen, setIsOpen] = useState(defaultOpen);
        const [withAnimation, setWithAnimation] = useState(true);
        const mount = useRef(false);

        useEffect(() => {
            if (!mount.current) {
                mount.current = true;

                if (defaultOpen) {
                    setWithAnimation(false);
                }
            }
        }, [defaultOpen]);

        const handleClick = (isOpen) => {
            if (!withAnimation) {
                setWithAnimation(true);
            }

            if (manuallyMode) {
                onToggleAccordion();
            } else {
                setIsOpen(isOpen);
            }
        };

        const cx = classnames.bind(styles);
        const containerClasses = cx('container', containerClassNames, {
            [`${containerActiveClassNames}`]: isOpen && withActiveContainer,
        });
        const labelClasses = cx('label', labelClassNames, {'label-active': isActiveLabelOnOpen && isOpen});

        const accordion = (
            <div id={props.id} className={containerClasses}>
                <Accordion
                    isOpen={manuallyMode ? isOpenManually : isOpen}
                    label={props.label}
                    labelClassNames={labelClasses}
                    contentClassNames={contentClassNames}
                    labelTextClassNames={labelTextClassNames}
                    withAnimation={withAnimation}
                    turnOffAnimationOnClose={turnOffAnimationOnClose}
                    onToggle={() => handleClick(!isOpen)}>
                    <Wrapped isAccordionOpen={isOpen} {...rest}/>
                </Accordion>
            </div>
        );

        if (accordionScreen) {
            const screenMedia = useMediaQuery(accordionScreen);

            // some screens with accordion
            return screenMedia && accordion;
        }

        return !turnOffAccordion ? accordion : <Wrapped {...rest} />;
    };
};

export default withAccordion;