import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withAccordion, withDataService} from '../common/hoc';
import {staticURLs} from '../../services/api-urls';
import {NOT_CAPITALIZE_LANGUAGES} from '../../services/data-service/constants';

import Logo from '../common/logo';

import classNames from 'classnames/bind';
import styles from './footer.module.scss';

import geoIcon from '../common/assets/other-icons/geo.svg';
import emailIcon from '../common/assets/other-icons/email.svg';
import phoneIcon from '../common/assets/other-icons/phone.svg';

const ContactUsItem = ({staticData}) => {
    const {contact_email, contact_phone, contact_address} = staticData;
    return (
        <>
            <span className={styles['contact-item']}>
                <img src={geoIcon} className={styles.icon} alt="Geo icon"/>
                <span className={styles.address}>
                    {contact_address.map((item, idx) => <div key={idx}>{item}</div>)}
                </span>
            </span>
            {contact_phone !== '' && (
                <span className={styles['contact-item']}>
                    <img src={phoneIcon} className={styles.icon} alt="Phone icon"/>
                    <span>{contact_phone}</span>
                </span>
            )}
            <span className={styles['contact-item']}>
                <img src={emailIcon} className={styles.icon} alt="Email icon"/>
                <a className={styles['page__link']} href={`mailto:${contact_email}`}>
                    {contact_email}
                </a>
            </span>
        </>
    );
};

const EnhancedComponent = withAccordion()(ContactUsItem);

const Footer = ({staticData}) => {
    const {text, navigation_links, social_links, contact_email, copyright} = staticData;
    const cx = classNames.bind(styles);
    const lgScreen = useMediaQuery({minWidth: 992});
    const {session: {session}, publicData: {homePage}, profile} = useSelector((state) => state);
    const language = session ? profile.language : homePage.language;
    const pageItemClasses = cx('page__item', {'normal': NOT_CAPITALIZE_LANGUAGES.includes(language)});

    const socialLinks = social_links.map(({id, url, img}) => {
        return (
            <a key={id} href={url} className={styles['link__item']} target="_blank" rel="noopener noreferrer">
                <div className={`${cx(img)} ${styles.icon}`}/>
            </a>
        );
    });

    const pagesLinks = navigation_links.map((item, idx) => {
        const {id, title, url, list} = item;

        const itemCreator = (id, title, url) => (
            <Link to={url} key={id} className={pageItemClasses}>{title}</Link>
        );

        if (!id && list) {
            return (
                <div key={idx} className={styles['pages__block']}>
                    {list.map(({id, title, url}) => itemCreator(id, title, url))}
                </div>
            );
        }

        if (id === 'contact_us') {
            return lgScreen
                ? (
                    <div key={idx} className={styles['contact-us']}>
                        <span className={pageItemClasses}>{title}</span>
                        <ContactUsItem staticData={staticData}/>
                    </div>
                )
                : <EnhancedComponent key={idx}
                                     staticData={staticData}
                                     label={title}
                                     labelClassNames={styles['contact-item-container']}
                                     contentClassNames={styles['contact-item-content']}/>;
        }

        if (idx === navigation_links.length - 1) {
            return <div key={idx} className={styles['social-links']}>{socialLinks}</div>;
        }

        return itemCreator(id, title, url);
    });

    return (
        <footer id={'footer'} className={styles.container}>
            <div className={styles['top-block']}>
                <div className={styles['logo-block']}>
                    <Logo href={staticURLs.home} classNames={styles.logo} onClick={() => window.scrollTo(0, 0)}/>
                    <div className={styles.contacts}>
                        <a className={styles['page__link']} href={`mailto:${contact_email}`}>{contact_email}</a>
                        <div className={styles['social-links']}>{socialLinks}</div>
                    </div>
                    <div className={styles.description}>{text}</div>
                </div>
                <div className={styles['pages']}>{pagesLinks}</div>
            </div>

            <div className={styles.copyright}>{copyright}</div>
        </footer>
    );
};

Footer.propTypes = {
    staticData: PropTypes.object,
};

ContactUsItem.propTypes = {
    staticData: PropTypes.object,
};

export default withDataService('footer')(Footer);