import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const REPLACE_ITEM_IN_RFS_REQUEST = 'REPLACE_ITEM_IN_RFS_REQUEST';
export const REPLACE_ITEM_IN_RFS_SUCCESS = 'REPLACE_ITEM_IN_RFS_SUCCESS';
export const REPLACE_ITEM_IN_RFS_FAILURE = 'REPLACE_ITEM_IN_RFS_FAILURE';

export const replaceItemRequest = createRequestHandler('REPLACE_ITEM_IN_RFS');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const replaceItemInRFS = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case REPLACE_ITEM_IN_RFS_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case REPLACE_ITEM_IN_RFS_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case REPLACE_ITEM_IN_RFS_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_REPLACE_ITEM_IN_RFS':
            return initialState;

        default:
            return state;
    }
};

export default replaceItemInRFS;