import React from 'react';
import PropTypes from 'prop-types';

import {withAccordionPages} from '../../../../common/hoc';
import {priceHandler} from '../../../../../../../utils';

import Preloader from '../../../../../../common/preloader';
import EContract from './components/e-contract';

import styles from './e-contracts-mobile.module.scss';

const EContractsMobile = ({staticData, contractsList, loading}) => {
    return !loading && contractsList ?
        typeof contractsList[0] !== 'string' ? (
            <div className={styles.container}>
                {contractsList.map(({id, number, row}, idx) => {
                    const total = row[row.length - 3].value;
                    const currency = row[row.length - 3].currency;

                    const labelBlock = (
                        <div className={styles.text}>
                            <div className={styles.number}>{number}</div>
                            <div className={styles.total}>{priceHandler(total)} {currency}</div>
                        </div>
                    );

                    return <EContract key={idx}
                                      staticData={staticData}
                                      label={labelBlock}
                                      containerClassNames={styles['accordion-container']}
                                      labelClassNames={styles.label}
                                      contentClassNames={styles.content}
                                      list={row}/>;
                })}
            </div>
        ) : <div className={styles.empty}>{contractsList[0]}</div>
        : (
            <div className={styles.empty}>
                <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
            </div>
        );
};

EContractsMobile.propTypes = {
    staticData: PropTypes.object,
    contractsList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    loading: PropTypes.bool,
};

export default withAccordionPages()(EContractsMobile);