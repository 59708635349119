import {createRequestHandler} from '../utils';
import {FETCH_SESSION_SUCCESS} from './session';
import {PROFILE_FAILURE, PROFILE_SUCCESS, PROFILE_UPDATING_FAILURE} from './profile';
import {INVITATION_FORM_PUBLIC_UPLOAD_SUCCESS} from './public-data/invitation/upload-data';
import {RESET_PASSWORD_FORM_PUBLIC_UPLOAD_SUCCESS} from "./public-data/reset-password/upload-data";

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const LOGIN_TWO_FACTOR_REQUEST = 'LOGIN_TWO_FACTOR_REQUEST';
export const LOGIN_TWO_FACTOR_SUCCESS = 'LOGIN_TWO_FACTOR_SUCCESS';
export const LOGIN_TWO_FACTOR_FAILURE = 'LOGIN_TWO_FACTOR_FAILURE';

export const OPEN_SIGN_IN = 'OPEN_SIGN_IN';
export const CLOSE_SIGN_IN = 'CLOSE_SIGN_IN';

export const loginRequest = createRequestHandler('LOGIN');
export const twoFactorRequest = createRequestHandler('LOGIN_TWO_FACTOR', {
    notAcceptableErrorRedirect: false,
});
export const logoutRequest = createRequestHandler('LOGOUT');

const initialState = {
    loggedIn: false,
    loading: false,
    error: null,
    signInActive: false,
    isTwoFactorActive: false,
    twoFactorData: null,
};

const login = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loggedIn: false,
                loading: true,
                error: null,
            };

        case LOGIN_TWO_FACTOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case LOGIN_SUCCESS:
        case FETCH_SESSION_SUCCESS:
        case INVITATION_FORM_PUBLIC_UPLOAD_SUCCESS:
        case LOGIN_TWO_FACTOR_SUCCESS:
        case RESET_PASSWORD_FORM_PUBLIC_UPLOAD_SUCCESS:
            if (payload) {
                const {url, form, is_two_factor} = payload;

                return {
                    ...state,
                    loading: false,
                    twoFactorData: {url, form},
                    isTwoFactorActive: is_two_factor,
                    error: null,
                };
            }

            return {
                ...state,
                loggedIn: true,
                error: null,
            };

        case PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                signInActive: false,
            };

        case LOGIN_FAILURE:
        case LOGIN_TWO_FACTOR_FAILURE:
            if (payload.message) {
                return {
                    ...state,
                    loading: false,
                    error: payload.message,
                };
            }

            return {
                ...state,
                loading: false,
                error: payload,
            };

        case OPEN_SIGN_IN:
            return {
                ...state,
                signInActive: true,
            };

        case CLOSE_SIGN_IN:
            return {
                ...state,
                signInActive: false,
                error: null,
            };

        case LOGOUT_REQUEST:
            return initialState;

        case PROFILE_FAILURE:
        case PROFILE_UPDATING_FAILURE:
            const {signInActive} = state;

            if (signInActive) {
                return {
                    ...state,
                    loading: false,
                    loggedIn: false,
                    error: payload,
                };
            }

            return initialState;

        default:
            return state;
    }
};

export default login;