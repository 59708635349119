import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';
import FormFieldCreator from '../../../../../common/handlers/form-field-creator';

import Preloader from '../../../../../../../common/preloader';
import FormFieldConstructor from '../../../../../../../common/form-field-constructor';
import TooltipCreator from '../../../../../../../common/tooltip-creator';
import Button from '../../../../../../../common/button';

import styles from './delivery-payment.module.scss';

const DeliveryPayment = (props) => {
    const {
        staticData, id, formFields: {loading, updating}, isDisabledSection,
        deliveryPaymentFields, regulatoryFrameworkFields, shipmentConditionsFields,
        packagingFields, dangerousFields, isSaveButtonDisabled,
        formError, onInputChange, onClickSelectItem, onGroupCheckboxChange,
        onRadioButtonChange, onSave, onSkip,
    } = props;

    const {
        sections: {
            delivery_and_payment: {
                subsection: {
                    delivery_payment,
                    regulatory_framework,
                    shipment_conditions,
                    packaging,
                    dangerous_goods_information,
                },
            },
        },
        required_field_error_message,
        button: {skip, save},
    } = staticData.activity_hub.place_product.from_form;

    const formFieldCreator = new FormFieldCreator();
    const formFieldHandler = (formFields, tooltipArray) => {
        const fields = formFieldCreator
            .getFormFields(formFields)
            .map((field, idx) => {
                if (Array.isArray(field)) {
                    let newField = [];

                    field.forEach(item => {
                        if (item.type !== 'checkbox') {
                            newField.push({
                                ...item,
                                tooltip: tooltipArray.tooltip[idx],
                            });
                        } else {
                            newField.push(item);
                        }
                    });

                    const isShown = newField[0].isShown !== undefined ? newField[0].isShown : true;

                    return {
                        fields: newField,
                        isShown,
                    };
                }

                return {
                    ...field,
                    tooltip: tooltipArray.tooltip[idx],
                };
            });

        return fields.map((field, idx) =>
            <FormFieldConstructor
                key={idx}
                field={field}
                onInputChange={onInputChange}
                onClickSelectItem={onClickSelectItem}
                onGroupCheckboxChange={onGroupCheckboxChange}
                onRadioButtonChange={onRadioButtonChange}
            />,
        );
    };

    const deliveryPaymentBlock = deliveryPaymentFields
        ? formFieldHandler(deliveryPaymentFields, delivery_payment)
        : [];

    const regulatoryFrameworkBlock = regulatoryFrameworkFields
        ? formFieldHandler(regulatoryFrameworkFields, regulatory_framework)
        : [];

    const shipmentConditionsBlock = shipmentConditionsFields
        ? formFieldHandler(shipmentConditionsFields, shipment_conditions)
        : [];

    const packagingBlock = packagingFields
        ? formFieldHandler(packagingFields, packaging)
        : [];
    const packagingBlockFirst = packagingBlock.slice(0, 3);
    const packagingBlockSecond = packagingBlock.slice(3);

    const dangerousBlock = dangerousFields
        ? formFieldHandler(dangerousFields, dangerous_goods_information)
        : [];
    const dangerousBlockFirst = dangerousBlock[0];
    const dangerousBlockSecond = dangerousBlock.slice(1);

    return (loading || updating)
        ? <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
        : (
            <form id={`${id}_form`} className={styles.form}>
                {deliveryPaymentBlock[0]}

                <div className={`${styles.group} ${styles['checkbox-group']}`}>{deliveryPaymentBlock[1]}</div>

                <div className={styles.title}>{regulatory_framework.title}</div>
                <div className={styles['grid-group']}>{regulatoryFrameworkBlock}</div>

                <div className={styles.title}>{shipment_conditions.title}</div>
                <div className={styles['grid-group']}>{shipmentConditionsBlock}</div>

                <div className={styles.title}>{packaging.title}</div>
                <div className={`${styles['grid-group']} ${styles['group-even-3']}`}>{packagingBlockFirst}</div>
                <div className={`${styles['grid-group']} ${styles['group-even-4']}`}>{packagingBlockSecond}</div>

                <div className={styles['title-block']}>
                    <div className={styles['title-in-block']}>{dangerous_goods_information.title}</div>
                    <TooltipCreator tooltip={dangerous_goods_information.tooltip} classNames={styles.tooltip}/>
                </div>
                <div className={`${styles.section} ${styles['dangerous-section']}`}>{dangerousBlockFirst}</div>
                <div className={`${styles['grid-group']} ${styles['group-even-3']}`}>
                    {dangerousBlockSecond}
                </div>

                {!isDisabledSection && (
                    <div className={styles['btn-group']}>
                        {formError && <div className={styles['error-text']}>{required_field_error_message}</div>}

                        <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                        <Button classNames={styles['btn-submit']}
                                isDisabled={isSaveButtonDisabled}
                                onClick={onSave}>
                            {save}
                        </Button>
                    </div>
                )}
            </form>
        );
};

DeliveryPayment.defaultProps = {
    isDisabledSection: false,
    formError: false,
    onInputChange: () => {
    },
    onClickSelectItem: () => {
    },
    onGroupCheckboxChange: () => {
    },
    onRadioButtonChange: () => {
    },
    onSave: () => {
    },
    onSkip: () => {
    },
};

DeliveryPayment.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.string,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    isDisabledSection: PropTypes.bool,
    deliveryPaymentField: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    regulatoryFrameworkFields: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    shipmentConditionsFields: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    packagingFields: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    dangerousFields: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    formError: PropTypes.bool,
    onInputChange: PropTypes.func,
    onClickSelectItem: PropTypes.func,
    onGroupCheckboxChange: PropTypes.func,
    onRadioButtonChange: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
};

export default withSectionPanel()(DeliveryPayment);