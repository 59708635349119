import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withServices} from '../service-context';
import {withDataService} from '../common/hoc';
import {newsDataRequest} from '../../reducers/public-data/news/data';
import {registrationAction, utilsAction} from '../../actions/common';

import HomePage from './home-page';

class HomePageContainer extends Component {
    componentDidMount() {
        this.props.setBaseAPIUrl(null);
        this.props.switchDepartmentID(null);

        if (this.props.session !== null) {
            this.props.fetchNews();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchNews();
        }
    }

    onSignUpOpen = () => {
        this.props.openSignUp();
    };

    render() {
        return <HomePage {...this.props} onSignUpOpen={this.onSignUpOpen}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        language: PropTypes.string,
        openSignUp: PropTypes.func,
        setBaseAPIUrl: PropTypes.func,
        switchDepartmentID: PropTypes.func,
        fetchNews: PropTypes.func,
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchNews: newsDataRequest(publicService.getNewsData),
});

const mapStateToProps = ({session: {session}, profile, publicData: {news, homePage}}) => ({
    session,
    news: news.data,
    language: session ? profile.language : homePage.language,
});

const mapDispatchToProps = (dispatch, {fetchNews}) => {
    const {setBaseAPIUrl, switchDepartmentID} = utilsAction;

    return bindActionCreators({
        openSignUp: registrationAction.openSignUp,
        setBaseAPIUrl,
        switchDepartmentID,
        fetchNews,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(HomePageContainer);