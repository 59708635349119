import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../common/hoc';
import {withAccordion} from '../../../../../common/hoc';
import {marketplaceURLs} from "../../../../../../services/api-urls";

import ProductImageSlider from '../components/product-image-slider';
import PopupImageSlider from '../components/popup-image-slider';
import ItemCounter from '../../../../../common/item-counter';
import Button from '../../../../../common/button';
import ProductDescription from '../components/product-description';
import DocumentsList from '../components/documents-list';
import OtherOffersLink from '../components/other-offers-link';
import ProductTabs from '../components/product-tabs';
import RequestAccessBlock from '../../../../../common/request-access-block';
import TooltipCreator from '../../../../../common/tooltip-creator';

import classnames from 'classnames/bind';
import styles from './product-page-mobile.module.scss';

const EnhancedComponent = withAccordion()(ProductDescription);

const ProductPageMobile = (props) => {
    const {
        staticData, details, arrayOfCurrencies, inCart, isOtherOffers,
        isPopupShown, isAddToQuotationButtonDisabled, onSwiperWrapperClick, onPopupClose,
        onQuantityChange, onAddToQuotation, addToQuotation, userInteraction,
        profile,
    } = props;

    const {
        images, name, available, price_data, other_offers_data,
        main_data, tabs, attachments, href: editHref,
    } = details;
    const {price, currency} = price_data;

    const session = useSelector(({session: {session}}) => session);

    const {
        price_label,
        details_accordion_label,
        button: {add_to_quotation, added_to_quotation, edit_product},
    } = staticData.marketplace.product_page;

    let isTrial = null;
    if (profile) {
        isTrial = profile.is_trial;
    }

    useEffect(() => {
        const customClassHandler = (method) => {
            const mainContainer = document.querySelector('[data-name="main-container"]');
            const breadcrumbs = document.querySelector('[data-name="breadcrumbs"]');

            if (mainContainer && breadcrumbs) {
                if (method === 'add') {
                    mainContainer.classList.add(`${styles['main-container-custom']}`);
                    breadcrumbs.classList.add(`${styles['breadcrumbs-custom']}`);
                } else {
                    mainContainer.classList.remove(`${styles['main-container-custom']}`);
                    breadcrumbs.classList.remove(`${styles['breadcrumbs-custom']}`);
                }
            }
        };

        customClassHandler('add');

        return () => {
            customClassHandler('remove');
        };
    });

    const swiperParams = {
        images: images,
        slidesPerView: 6,
        spaceBetween: 12,
        containerClass: 'swiper-container-popup-mobile',
        withTitleBlock: true,
        withLoop: true,
        withThumbsSlider: true,
    };

    const cx = classnames.bind(styles);

    let editProductButton = editHref && (editHref !== '' || editHref !== '#') && !isTrial
        ? (
            <Link to={editHref} className={styles['edit-product-link']}>
                <Button styleType="SECONDARY" classNames={styles['edit-product-btn']}>{edit_product}</Button>
            </Link>
        )
        : null;

    const purchaseBlockCreator = () => {
        if (userInteraction === 'importer' && !isTrial) {
            const {
                label: availableLabel,
                quantity: availableQuantity,
                value: availableValue,
                status: availableToAddToCart,
            } = available;
            const notAvailable = availableQuantity === 0 || availableValue === 'out_stock' || !availableToAddToCart;

            if (notAvailable) {
                const purchaseBlockClasses = cx(
                    'purchase-block',
                    'purchase-block-md',
                    'not-available-block',
                );

                const labelClasses = cx('availability-label', {
                    'out-of-stock': availableQuantity === 0 || availableValue === 'out_stock',
                });

                return (
                    <div className={purchaseBlockClasses}>
                        <div className={labelClasses}>{availableLabel}</div>
                        {isOtherOffers && <OtherOffersLink {...other_offers_data}
                                                           staticData={staticData}
                                                           classNames={styles.offers}/>}
                    </div>
                );
            }

            const purchaseBlockClasses = cx('purchase-block', {'purchase-block-up': inCart.status});

            const btnClasses = cx('btn', {'added-to-quotation': inCart.status});

            const addToQuotationBtn = !inCart.status
                ? (
                    <Button classNames={btnClasses}
                            dataLoading={addToQuotation.loading}
                            isDisabled={isAddToQuotationButtonDisabled}
                            onClick={() => !addToQuotation.loading && onAddToQuotation()}>
                        {add_to_quotation}
                    </Button>
                )
                : (
                    <Link to={marketplaceURLs.quotation} className={styles['btn-link']}>
                        <Button classNames={btnClasses}>{added_to_quotation}</Button>
                    </Link>
                );

            return (
                <>
                    {!inCart.status && <ItemCounter classNames={styles.counter}
                                                    maxValue={availableQuantity}
                                                    onCountChange={onQuantityChange}/>}

                    <div className={purchaseBlockClasses}>
                        <div className={styles['btn-block']}>
                            {addToQuotationBtn}
                        </div>
                        {isOtherOffers && <OtherOffersLink {...other_offers_data}
                                                           staticData={staticData}
                                                           classNames={styles.offers}/>}
                    </div>
                </>
            );
        } else {
            return session && isOtherOffers &&
                <OtherOffersLink {...other_offers_data}
                                 staticData={staticData}
                                 classNames={`${styles.offers} ${styles['single-block']}`}/>;
        }
    };

    const mainDataContainerClasses = cx('main-data-container', {'without-counter': inCart && inCart.status});

    return (
        <>
            <div className={mainDataContainerClasses}>
                <div className={styles.slider}>
                    <ProductImageSlider staticData={staticData}
                                        images={images}
                                        onSwiperWrapperClick={onSwiperWrapperClick}
                                        withLoop
                                        withPagination/>
                </div>

                <PopupImageSlider {...swiperParams}
                                  staticData={staticData}
                                  name={name}
                                  price={price}
                                  currency={currency}
                                  session={session}
                                  onClose={onPopupClose}
                                  onContainerClose={onPopupClose}
                                  isShown={isPopupShown}
                                  closeButton/>

                <div className={styles.title}>{name}</div>
                <div className={styles['price-block']}>
                    <div className={`${styles.title} ${styles['title-md']}`}>
                        {name}
                    </div>
                    {session && (
                        <>
                            <div className={styles.price}>
                                {price} {currency}

                                {arrayOfCurrencies.length > 0 &&
                                    <TooltipCreator tooltip={{text: arrayOfCurrencies}}
                                                    classNames={styles['tooltip-for-prices']}
                                                    contentClassNames={styles['tooltip-content']}
                                                    minWidth={200}/>}
                            </div>
                            <div className={styles.label}>{price_label}</div>
                        </>
                    )}
                    {editProductButton}
                </div>

                {purchaseBlockCreator()}

                {!session && session !== null && <RequestAccessBlock/>}
            </div>

            <EnhancedComponent description={main_data}
                               label={details_accordion_label}
                               containerClassNames={styles['accordion-container']}
                               contentClassNames={styles['accordion-content']}
                               labelClassNames={styles['accordion-label']}
                               accordionScreen={{maxWidth: 991.9}}/>

            <ProductTabs productName={name} tabs={tabs}/>
            {
                (attachments.length !== 0 && attachments.main !== undefined)
                && <DocumentsList {...attachments.main}
                                  contentClassNames={styles['accordion-content']}
                                  accordionScreen={{maxWidth: 991.9}}/>
            }
        </>
    );
};

ProductPageMobile.defaultProps = {
    isOtherOffers: true,
    isAddToQuotationButtonDisabled: false,
    onAddToQuotation: () => {
    },
};

ProductPageMobile.propTypes = {
    staticData: PropTypes.object,
    details: PropTypes.shape({
        name: PropTypes.string.isRequired,
        available: PropTypes.shape({
            label: PropTypes.string.isRequired,
            quantity: PropTypes.number.isRequired,
            value: PropTypes.string.isRequired,
            status: PropTypes.bool,
        }),
        price_data: PropTypes.object.isRequired,
        other_offers_data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    }),
    arrayOfCurrencies: PropTypes.array,
    inCart: PropTypes.shape({
        status: PropTypes.bool,
    }),
    isOtherOffers: PropTypes.bool,
    isAddToQuotationButtonDisabled: PropTypes.bool,
    addToQuotation: PropTypes.shape({
        loading: PropTypes.bool,
    }),
    userInteraction: PropTypes.string,
    profile: PropTypes.shape({
        is_trial: PropTypes.bool,
    }),
    onAddToQuotation: PropTypes.func,
};

export default withDataService()(ProductPageMobile);