import React, {useState} from 'react';
import PropTypes from 'prop-types';

import InfoPopUp from '../../../../../../../../common/info-pop-up';
import QuotationDescription from '../quotation-description';
import Tooltip from '../../../../../../../../common/tooltip';
import InfoMark from '../../../../../../../../common/info-mark';

import styles from './quotation.module.scss';

const Quotation = (props) => {
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const {staticData, rfq_number, row, classNames} = props;
    const total = row[row.length - 2].value;

    const {rfq_price_tooltip} = staticData.activity_hub.requests_for_quotations;

    return (
        <div className={`${styles.container} ${classNames}`}>
            <InfoPopUp
                switcher={
                    <div className={styles['number-block']}>
                        <div className={styles.number}
                             onClick={(e) => {
                                 e.stopPropagation();
                                 setIsPopUpOpen(!isPopUpOpen);
                             }}>
                            {rfq_number}
                        </div>
                    </div>
                }
                containerClassNames={styles.description}
                isOpen={isPopUpOpen}
                onClose={() => setIsPopUpOpen(false)}>
                <QuotationDescription staticData={staticData} description={row}/>
            </InfoPopUp>

            <div className={styles['total-block']}>
                <div className={styles.total}>{total}</div>
                <Tooltip mode={'manual'}
                         tooltip={rfq_price_tooltip}
                         contentClassNames={styles['info-text']}
                         visible={isTooltipOpen}
                         onClickOutside={() => setIsTooltipOpen(false)}
                         minWidth={260}
                         maxWidth={260}>
                    <span className={styles.info}
                          onClick={(e) => {
                              e.stopPropagation();
                              setIsTooltipOpen(!isTooltipOpen);
                          }}>
                         <InfoMark classNames={styles['info-icon']}/>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

Quotation.propTypes = {
    staticData: PropTypes.object,
    rfq_number: PropTypes.string,
    row: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    classNames: PropTypes.string,
};

export default Quotation;