import React from 'react';
import PropTypes from 'prop-types';

import FormFieldCreator from '../../../../common/handlers/form-field-creator';

import FormFieldConstructor from '../../../../../../common/form-field-constructor';
import Button from '../../../../../../common/button';

import classnames from 'classnames/bind';
import styles from './bank-account.module.scss';

const formFieldCreator = new FormFieldCreator();
const formFieldHandler = (formFields, listeners, pageType = 'create') => {
    const fields = formFieldCreator.getFormFields(formFields);

    return fields.map((field, idx) => {
        if (pageType === 'edit') {
            const {onSetDefault} = listeners;
            return <FormFieldConstructor {...listeners} key={idx} field={field}
                                         onButtonClick={() => onSetDefault(field.remote.url)}/>;
        }

        return <FormFieldConstructor {...listeners} key={idx} field={field}/>;
    });
};

const BankAccount = (props) => {
    const {
        staticData, pageType, list, accountDataLoading, setDefaultAccountLoading,
        isSaveButtonDisabled, onClickSelectItem, onInputChange,
        onSubmit, onCancel, onSetDefault,
    } = props;
    const {button: {save, cancel}} = staticData.activity_hub.profile;
    const cx = classnames.bind(styles);

    const listeners = {
        dataLoading: setDefaultAccountLoading,
        onClickSelectItem,
        onInputChange,
        onSetDefault,
    };

    let formFields = [],
        columnsBlock = [],
        ibanBlock = [],
        btnBlock = [];

    if (list.length > 0) {
        formFields = formFieldHandler(list, listeners, pageType);
        columnsBlock = formFields.slice(0, 7);
        ibanBlock = formFields.slice(7, 8);

        if (pageType === 'edit') {
            btnBlock = formFields.slice(8);
        }
    }
    return (
        <form onSubmit={onSubmit}>
            <div className={styles.content}>
                <div className={cx('group', 'group-small')}>
                    <div className={cx('grid-group', 'group-even-2')}>
                        {columnsBlock}
                    </div>

                    <div className={styles['grid-group']}>
                        {ibanBlock}
                    </div>

                    {btnBlock.length > 0 && (
                        <div className={cx('grid-group', 'group-even-2', 'btn-block')}
                             style={{pointerEvents: accountDataLoading || setDefaultAccountLoading ? 'none' : 'auto'}}>
                            {btnBlock}
                        </div>
                    )}
                </div>
            </div>

            <div className={styles['btn-group']} style={{
                pointerEvents: accountDataLoading || setDefaultAccountLoading
                    ? 'none' : 'auto',
            }}>
                <Button type={'submit'}
                        dataLoading={accountDataLoading}
                        classNames={styles['save-btn']}
                        isDisabled={isSaveButtonDisabled}>
                    {save}
                </Button>
                <Button styleType={'SECONDARY'}
                        classNames={styles['cancel-btn']}
                        onClick={onCancel}>
                    {cancel}
                </Button>
            </div>
        </form>
    );
};

BankAccount.defaultProps = {
    onClickSelectItem: () => {
    },
    onInputChange: () => {
    },
    onSubmit: () => {
    },
    onCancel: () => {
    },
    onSetDefault: () => {
    },
};

BankAccount.propTypes = {
    staticData: PropTypes.object,
    pageType: PropTypes.string,
    list: PropTypes.array,
    accountDataLoading: PropTypes.bool,
    setDefaultAccountLoading: PropTypes.bool,
    isSaveButtonDisabled: PropTypes.bool,
    onClickSelectItem: PropTypes.func,
    onInputChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onSetDefault: PropTypes.func,
};

export default BankAccount;