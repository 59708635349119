import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const SET_DEFAULT_BANK_ACCOUNT_REQUEST = 'SET_DEFAULT_BANK_ACCOUNT_REQUEST';
export const SET_DEFAULT_BANK_ACCOUNT_SUCCESS = 'SET_DEFAULT_BANK_ACCOUNT_SUCCESS';
export const SET_DEFAULT_BANK_ACCOUNT_FAILURE = 'SET_DEFAULT_BANK_ACCOUNT_FAILURE';

export const setDefaultBankAccountRequest = createRequestHandler('SET_DEFAULT_BANK_ACCOUNT');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const defaultBankAccount = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SET_DEFAULT_BANK_ACCOUNT_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case SET_DEFAULT_BANK_ACCOUNT_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case SET_DEFAULT_BANK_ACCOUNT_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                ...initialState,
                error,
            };

        case 'RESET_SET_DEFAULT_BANK_ACCOUNT':
            return initialState;

        default:
            return state;
    }
};

export default defaultBankAccount;