import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';

import Preloader from '../../../../../../../common/preloader';
import Input from '../../../../../../../common/input';
import Button from '../../../../../../../common/button';

import styles from './supply-set.module.scss';

const SupplySet = ({staticData, formFields: {loading, updating}, onInputChange, onSave, onSkip}) => {
    const {button: {skip, save}} = staticData.activity_hub.place_product.from_form;

    return (loading || updating)
        ? <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
        : (
            <div className={styles.container}>
                <form onSubmit={onSave}>
                    <div className={`${styles['grid-group']} ${styles['group-3']}`}>
                        <Input type={'text'}
                               inputName={'parameter-name'}
                               inputPlaceholder={'Parameter name'}
                               label={'Parameter name'}
                               onInputChange={onInputChange}/>
                        <Input type={'text'}
                               inputName={'parameter-value'}
                               inputPlaceholder={'Value'}
                               label={'Value'}
                               onInputChange={onInputChange}/>
                        <Input type={'text'}
                               inputName={'parameter-doq'}
                               inputPlaceholder={'DOQ'}
                               label={'DOQ'}
                               onInputChange={onInputChange}/>
                    </div>
                    <div className={styles['grid-group']}>
                        <label className={styles.checkbox} htmlFor="choose-from-products">
                            <input id="choose-from-products"
                                   type="checkbox"
                                   name="choose-from-products"
                                   value=""
                                   hidden/>
                            <span/>
                            Choose from products
                        </label>
                    </div>
                    <div className={styles['btn-single']}>
                        <Button styleType={'WITH_PLUS'}>Add item</Button>
                    </div>
                    <div className={styles['btn-group']}>
                        <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                        <Button type={'submit'}>{save}</Button>
                    </div>
                </form>
            </div>
        );
};

SupplySet.defaultProps = {
    onInputChange: () => {
    },
    onSave: () => {
    },
    onSkip: () => {
    },
};

SupplySet.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    onInputChange: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
};

export default withSectionPanel()(SupplySet);