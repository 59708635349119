import React from 'react';
import {useMediaQuery} from 'react-responsive';

export const withDeviceMode = (params) => (Wrapped) => {
    return (props) => {
        const {key, default_size, additional} = params;
        const modeActive = useMediaQuery({minWidth: default_size});
        const additionalModeActive = [];
        additional.forEach(item => {
            const {size} = item;
            const active = useMediaQuery({minWidth: size});
            additionalModeActive.push({...item, active});
        });

        return <Wrapped {...props}
                        deviceMode={{
                            key,
                            default_size,
                            active: modeActive,
                            additional: additionalModeActive
                        }}/>;
    };
};