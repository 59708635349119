import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import {onGetAlertResponse} from '../../../../../utils';
import sweetAlert from '../../../../common/sweet-alert';

import ProductPage from './product-page';
import Preloader from '../../../../common/preloader';

import styles from "../../../../../styles/page-container.module.scss";

class ProductPageContainer extends Component {
    state = {
        arrayOfCurrencies: [],
        currency: 'USD',
        isOtherOffers: true,
        productQuantity: 1,
        isError: false,
        isAddToQuotationButtonDisabled: false,
    };

    componentDidMount() {
        this.getProductDetails();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.details !== this.props.details) {
            if (this.props.details) {
                const {details} = this.props;
                const {price_data: {currency, price_values}, other_offers_data} = details;
                const currencies = Object.keys(price_values);
                const prices = [];
                for (let value of currencies) {
                    prices.push(<div key={value}>{`${price_values[value]} ${value}`}</div>);
                }
                const isOtherOffers = _.isObject(other_offers_data) && other_offers_data.length !== 0;
                this.setState({arrayOfCurrencies: prices, currency, isOtherOffers});
            }
        }
        if (prevProps.match.url !== this.props.match.url) {
            this.getProductDetails();
        }

        if (prevProps.error !== this.props.error && this.props.error) {
            this.setState({
                isError: true,
            });
        }

        if (prevProps.language !== this.props.language) {
            this.getProductDetails();
        }

        if (prevProps.addToQuotation !== this.props.addToQuotation) {
            const {status, text} = onGetAlertResponse(this.props.addToQuotation);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    this.props.resetAddToQuotation();
                });
            }
        }
    }

    getProductDetails = () => {
        const {slug, id} = this.props.match.params;

        this.props.fetchProductDetails(slug, id);
    };

    onQuantityChange = (quantity, error) => {
        if (quantity === '' && !error) {
            this.setState({isAddToQuotationButtonDisabled: true});

        } else {
            this.setState({isAddToQuotationButtonDisabled: error});
        }

        this.setState({productQuantity: quantity});
    };

    onAddToQuotation = () => {
        const productParams = {
            offer_id: this.props.details.id,
            quantity: this.state.productQuantity,
            currency: this.state.currency,
        };

        this.props.setProductCurrentCurrency(this.state.currency);
        this.props.fetchAddToQuotation(productParams);
    };

    render() {
        const {history, ...rest} = this.props;

        return (
            <div className={styles['page-container']}>
                {!this.props.loading && this.props.details
                    ? (
                        <ProductPage {...rest} {...this.state}
                                     onQuantityChange={this.onQuantityChange}
                                     onAddToQuotation={this.onAddToQuotation}
                                     withScroll={true}/>
                    ) : <Preloader/>
                }
            </div>
        );
    }

    static defaultProps = {
        fetchAddToQuotation: () => {
        },
    };

    static propTypes = {
        details: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        loading: PropTypes.bool,
        history: PropTypes.object,
        match: PropTypes.shape({
            params: PropTypes.shape({
                slug: PropTypes.string,
                id: PropTypes.string,
            }),
        }),
        language: PropTypes.string,
        addToQuotation: PropTypes.object,
        fetchProductDetails: PropTypes.func,
        fetchAddToQuotation: PropTypes.func,
        setProductCurrentCurrency: PropTypes.func,
        resetAddToQuotation: PropTypes.func,
        goBack: PropTypes.func,
    };
}

export default ProductPageContainer;