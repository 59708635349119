import React from 'react';
import PropTypes from 'prop-types';

import ContractDashboard from './components/contract-dashboard';
import ContractProgressBar from './components/contract-progress-bar';
import ContractInformation from './components/contract-information';
import ShipmentDocumentations from './components/shipment-documentations';

import styles from './contract-for-supply.module.scss';

const ContractForSupply = ({data}) => {
    const {subtitle, shortinfo, contragent, lots, precontract, progress, ...rest} = data;
    const dashboardTooltipZIndex = progress.list && progress.list.length > 0 ? progress.list.length + 1 : 2;

    let contractContent = null;
    /* case when user have lots, we get lots as object */
    if (!Array.isArray(lots)) {
        const {total, currency, list} = lots;

        /* show progress block when progress is an object with data, progress isn't empty array */
        const contractProgressBlock = !Array.isArray(progress)
            ? <ContractProgressBar {...rest} progress={progress} totalPrice={{total, currency}}/>
            : null;

        contractContent = (
            <>
                {contractProgressBlock}
                <ContractInformation documents={precontract}/>
                <ShipmentDocumentations list={list}/>
            </>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.number}>{subtitle}</div>
            <ContractDashboard data={{shortinfo, contragent}} tooltipZIndex={dashboardTooltipZIndex}/>
            {contractContent}
        </div>
    );
};

ContractForSupply.propTypes = {
    staticData: PropTypes.object,
    data: PropTypes.shape({
        subtitle: PropTypes.string,
        shortinfo: PropTypes.arrayOf(PropTypes.object),
        lots: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.shape({
                total: PropTypes.string,
                currency: PropTypes.string,
                list: PropTypes.array,
            })]),
        precontract: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        progress: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
};

export default ContractForSupply;