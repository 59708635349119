import DataService from '../data-service';
import {hubURLs, marketplaceURLs} from '../api-urls';

export default class HubMenuService {
    constructor(pageService) {
        this.pageID = pageService.pageID;
        this.quotation = {
            id: this.pageID.create_quotation,
            label_id: 'quotation',
            submenu: [
                {
                    label_id: 'quotation_request_for_supply',
                    href: marketplaceURLs.quotation,
                },
                {
                    label_id: 'quotation_request_for_overhaul',
                    isDisabled: true,
                },
            ],
        };
        this.activity_hub = {
            id: this.pageID.activity_hub,
            label_id: 'activity_hub',
            href: hubURLs.requests,
        };
        this.my_products = {
            id: this.pageID.my_products,
            label_id: 'my_products',
            href: hubURLs.products,
        };
        this.notifications = {
            id: this.pageID.notifications,
            label_id: 'notifications',
            href: hubURLs.notifications,
        };
        this.profile = {
            id: this.pageID.profile,
            label_id: 'profile',
            href: hubURLs.profile,
        };
        this.logOut = {
            id: 'log-out',
            label_id: 'log_out',
            href: '#',
        };
    }

    menuLinkLabelCreator = (array_of_links, array_of_labels) => {
        if (array_of_links.length !== 0) {
            return array_of_links.map((link) => {
                const {submenu, ...rest} = link;

                if (submenu) {
                    const newSubmenu = submenu.map((submenuLink) => ({
                        label: array_of_labels[submenuLink.label_id],
                        ...submenuLink,
                    }));

                    return {
                        label: array_of_labels[link.label_id],
                        submenu: newSubmenu,
                        ...rest,
                    };
                }

                return {
                    label: array_of_labels[link.label_id],
                    ...rest,
                };
            });
        }

        return null;
    };

    getHubMenuLinks = (lang, interaction, is_trial) => {
        const dataService = new DataService();
        const hubMenuLinksLabels = dataService
            .getStaticData(lang)
            .hub_menu
            .link_labels;

        let hubMenuLinks = [
            this.notifications,
            this.profile,
            this.logOut,
        ];

        if (interaction) {
            if (interaction === 'importer') {
                if (!is_trial) {
                    hubMenuLinks = [
                        this.quotation,
                        this.activity_hub,
                        ...hubMenuLinks,
                    ];
                }
            }

            if (interaction === 'exporter') {
                if (!is_trial) {
                    hubMenuLinks = [
                        this.activity_hub,
                        this.my_products,
                        ...hubMenuLinks,
                    ];
                }
            }
        }

        return this.menuLinkLabelCreator(hubMenuLinks, hubMenuLinksLabels);
    };
};