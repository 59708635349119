export const shopByBrandService = [
    {id: 'aircraft_plant_1', brand_logo: 'aircraft_plant_1.png'},
    {id: 'aircraft_plant_2', brand_logo: 'aircraft_plant_2.png'},
    {id: 'aircraft_plant_3', brand_logo: 'aircraft_plant_3.png'},
    {id: 'aircraft_plant_4', brand_logo: 'aircraft_plant_4.png'},
    {id: 'aircraft_plant_5', brand_logo: 'aircraft_plant_5.png'},
    {id: 'aircraft_plant_6', brand_logo: 'aircraft_plant_6.png'},
    {id: 'aircraft_plant_7', brand_logo: 'aircraft_plant_7.png'},
    {id: 'aircraft_plant_8', brand_logo: 'aircraft_plant_8.png'},
    {id: 'aircraft_plant_9', brand_logo: 'aircraft_plant_9.png'},
    {id: 'aircraft_plant_10', brand_logo: 'aircraft_plant_10.png'},
    {id: 'aircraft_plant_11', brand_logo: 'aircraft_plant_11.png'},
    {id: 'aircraft_plant_12', brand_logo: 'aircraft_plant_12.png'},
];