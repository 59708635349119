import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import styles from './action-step-card.module.scss';

const ActionStepCard = ({actionList}) => {
    useEffect(() => {
        const allRows = document.querySelectorAll(`.${styles.row}`);

        const onScroll = () => {
            allRows.forEach((row, idx) => {
                if (!row.classList.contains(`${styles.visible}`)) {
                    const windowHeight = window.innerHeight;
                    const windowTop = window.scrollY;
                    const windowBottom = windowTop + windowHeight;

                    const rowHeight = row.offsetHeight;
                    const rowTop = row.getBoundingClientRect().top + windowTop;
                    const coefficient = 3;

                    if (windowTop <= rowTop && windowBottom >= (rowTop + rowHeight / coefficient)) {
                        row.classList.add(`${styles.visible}`);
                    }

                    row.style.zIndex = allRows.length - idx;
                }
            });
        };

        onScroll();
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    });

    const content = actionList.map(({id, title, description, img, alt, assets_folder}, idx) => {
        const number = idx < 9 ? `0${idx + 1}` : idx + 1;
        const imgSrc = require(`../../../services/data-service/${assets_folder}/assets/${img}`).default;

        return (
            <div key={idx} className={styles.row}>
                <div className={styles['action-block']}>
                    <div className={styles.text}>
                        <div className={styles.number}>{number}</div>

                        <div className={styles['description-block']}>
                            {title && <div className={styles.title}>{title}</div>}
                            {description && <div className={styles.description}>{description}</div>}
                        </div>
                    </div>

                    <div className={styles.img}>
                        <img src={imgSrc} alt={alt}/>
                    </div>
                </div>

                <div className={styles['arrow-block']}>
                    <div className={styles.arrow}/>
                </div>
            </div>
        );
    });

    return (
        <div className={styles.container}>
            {content}
        </div>
    );
};

ActionStepCard.propTypes = {
    actionList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any,
        title: PropTypes.string,
        description: PropTypes.string,
        img: PropTypes.string,
        alt: PropTypes.string,
    })),
};

export default ActionStepCard;