import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import SortingDesktop from './sorting-desktop';
import SortingMobile from './sorting-mobile';

const Sorting = (props) => {
    const {staticData, options, screenChangeOn, onMobileFilterOpen, ...rest} = props;
    const {
        sorting_label,
        sorting_title,
    } = staticData.sorting;

    const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

    const onUpdateIsMobileFilterOpen = (value) => {
        setIsMobileFilterOpen(value);
        onMobileFilterOpen(value);
    };

    const desktopDevice = useMediaQuery({minWidth: screenChangeOn});

    return (
        desktopDevice
            ? !props.isDisabled ? <SortingDesktop {...rest} sortingOptions={options}/> : null
            : <SortingMobile
                {...rest}
                staticData={staticData}
                label={sorting_label}
                isOpenMenu={isMobileFilterOpen}
                setIsOpenMenu={onUpdateIsMobileFilterOpen}
                title={sorting_title}
                sortingOptions={options}
            />
    );
};

Sorting.defaultProps = {
    screenChangeOn: 768,
    onMobileFilterOpen: () => {
    },
};

Sorting.propTypes = {
    staticData: PropTypes.object,
    isDisabled: PropTypes.bool,
    screenChangeOn: PropTypes.number,
    onMobileFilterOpen: PropTypes.func,
};

export default Sorting;