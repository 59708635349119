import React from 'react';
import PropTypes from 'prop-types';

import {withModal} from '../../common/hoc';

import Logo from '../../common/logo';
import Input from '../../common/input';
import Button from '../../common/button';

import classnames from 'classnames/bind';
import styles from './sign-in.module.scss';

const SignIn = (props) => {
    const {
        staticData, emailError, passwordError, codeError, commonError,
        isVisiblePassword, isTwoFactorActive, isForgotPasswordForm,
        togglePasswordVisibility, onResetPassword, onSubmit, openSignUp,
        ...inputEventListeners
    } = props;

    const {
        welcome_title, description, title, two_factor_title, reset_password_title,
        email_placeholder, password_placeholder, two_factor_code_placeholder,
        request_access_question, two_factor_text, forgot_password_text,
        button: {request_access, sign_in, confirm, reset},
    } = staticData.authorization.sign_in;

    const emailInputParams = {
        inputContainerClass: styles.email,
        inputName: 'email',
        inputPlaceholder: email_placeholder,
        label: email_placeholder,
        isError: emailError !== null,
    };

    const twoFactorCodeParams = {
        inputContainerClass: styles.email,
        inputName: 'code',
        inputPlaceholder: two_factor_code_placeholder,
        label: two_factor_code_placeholder,
        isError: codeError !== null,
    }

    const passwordInputParams = {
        type: isVisiblePassword ? 'text' : 'password',
        inputName: 'password',
        inputPlaceholder: password_placeholder,
        label: password_placeholder,
        isVisiblePassword: isVisiblePassword,
        isError: passwordError !== null,
        withEyeIcon: true,
        onEyeClick: togglePasswordVisibility,
    };

    const signInStep = (
        <>
            <div className={styles['inputs-block']}>
                <Input {...emailInputParams} {...inputEventListeners}
                       errorMessage={emailError}/>
                <Input {...passwordInputParams} {...inputEventListeners}
                       errorMessage={passwordError}/>
            </div>

            <div className={styles['reset-password-label']} onClick={onResetPassword}>
                {reset_password_title}
            </div>
        </>
    );

    const forgotPasswordStep = (
        <div className={styles['inputs-block']}>
            <Input {...emailInputParams} {...inputEventListeners} errorMessage={emailError}/>
        </div>
    );

    const twoFactorStep = (
        <div className={styles['inputs-block']}>
            <Input {...twoFactorCodeParams} {...inputEventListeners} errorMessage={codeError}/>
        </div>
    );

    const requestAccessLink = (
        <div className={styles.link} onClick={openSignUp}>
            {request_access}
        </div>
    );

    let formTitle = title;
    let formText = (
        <div className={styles['request-access-link']}>
            {request_access_question} {requestAccessLink}
        </div>
    );
    let formStep = signInStep;
    let submitButton = sign_in;

    if (isTwoFactorActive) {
        formTitle = two_factor_title;
        formText = (
            <div className={styles['form-simple-text']}>
                {two_factor_text}
            </div>
        );
        formStep = twoFactorStep;
        submitButton = confirm;
    }

    if (isForgotPasswordForm) {
        formTitle = reset_password_title;
        formText = (
            <div className={styles['form-simple-text']}>
                {forgot_password_text}
            </div>
        );
        formStep = forgotPasswordStep;
        submitButton = reset;
    }

    const cx = classnames.bind(styles);
    return (
        <div className={styles.container}>
            <div className={styles['info-block']}>
                <div className={styles.title}>{welcome_title}</div>
                <ul className={styles.items}>
                    {description.map((item, idx) => {
                        return (
                            <li key={idx} className={styles.item}>
                                <span>{item}</span>
                            </li>
                        );
                    })}
                </ul>

                <Logo color={'WHITE'}/>
            </div>

            <div className={styles['form-block']}>
                <div className={styles.title}>{formTitle}</div>

                {formText}

                <div className={cx('error', {'show': commonError})}>{commonError}</div>

                <form id="sign-in-form" onSubmit={(e) => onSubmit(e)}>
                    {formStep}

                    <Button type="submit" dataLoading={props.loading} classNames={styles.btn}>
                        {submitButton}
                    </Button>
                </form>
            </div>
        </div>
    );
};

SignIn.propTypes = {
    staticData: PropTypes.object,
    emailError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    passwordError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    codeError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    commonError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isVisiblePassword: PropTypes.bool,
    isForgotPasswordForm: PropTypes.bool,
    isTwoFactorActive: PropTypes.bool,
    togglePasswordVisibility: PropTypes.func,
    openSignUp: PropTypes.func,
    onResetPassword: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default withModal()(SignIn);