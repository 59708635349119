import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    onInitSectionStateFromDataByServer,
    prevStateSectionSettingsHandler,
    sectionSettingsHandler,
} from '../../common/handlers';
import {
    formDataObjectHandler,
    onChangeFieldValue,
    onTransformFieldsWithSelectInputGroup,
    onUpdateSelectInputGroup,
} from '../../../../../common/handlers';

import {
    fetchSessionStorageItems,
    removeSessionStorageItemsAfterCheck,
    transformFieldData,
    getDataFromFields,
} from '../../../../../common/handlers/field-handlers';

import Resources from './resources';

class ResourcesContainer extends Component {
    state = {
        id: null,
        initForm: null,
        resourcesFields: null,
        warrantyFields: null,
        isDisabledSection: false,
        isDisabledAlwaysSection: false,
        isSaveButtonDisabled: true,
    };

    storageKey = 'resources';

    componentDidMount() {
        this.setState({id: this.props.id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (this.props.sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, this.props.sections);

                if (prevProps.sections.length > 0) {
                    const {isDisabled: prevDisabled} = prevStateSectionSettingsHandler(this.state.id, prevProps.sections);

                    if (this.props.pageMode !== 'EDIT') {
                        if (prevDisabled !== isDisabled && !prevDisabled && this.state.initForm) {
                            const {resourcesFields, warrantyFields} = this.state.initForm;
                            const {storageItem} = this.props;

                            removeSessionStorageItemsAfterCheck(storageItem, this.storageKey);

                            this.setState({
                                resourcesFields,
                                warrantyFields,
                            });

                            this.props.onUpdateSaveStatus(this.state.id, false);
                        }
                    }
                }

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }

        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                const {list, storageItem, pageMode} = this.props;
                const sessionStorageItems = fetchSessionStorageItems(storageItem, this.storageKey);

                let resources = list
                    .filter(({section}) => section.id === 'resources')
                    .map((field) => transformFieldData(field, sessionStorageItems));
                let warranty = list
                    .filter(({section}) => section.id === 'warranty')
                    .map((field) => transformFieldData(field, sessionStorageItems));

                if (resources.filter(({group}) => group).length > 0) {
                    resources = onTransformFieldsWithSelectInputGroup(resources);
                }

                if (warranty.filter(({group}) => group).length > 0) {
                    warranty = onTransformFieldsWithSelectInputGroup(warranty);
                }

                const allFields = [
                    ...resources,
                    ...warranty,
                ];

                let updatedState = {
                    resourcesFields: resources,
                    warrantyFields: warranty,
                };

                if (pageMode === 'EDIT') {
                    const isDisabled = onInitSectionStateFromDataByServer(allFields);
                    if (isDisabled !== this.state.isDisabledSection) {
                        this.props.updatedPlaceProductSectionDisabledStatus(this.state.id, isDisabled);
                    }

                } else {
                    updatedState = {
                        ...updatedState,
                        /* case for toggle disabled status and global form cleaning */
                        initForm: {
                            resourcesFields: resources,
                            warrantyFields: warranty,
                        },
                    };
                }

                this.setState((prevState) => ({
                    ...prevState,
                    ...updatedState,
                }));
            }
        }

        if (prevProps.fetchingDataFromSection !== this.props.fetchingDataFromSection) {
            const {fetchingDataFromSection} = this.props;

            if (fetchingDataFromSection && fetchingDataFromSection.currentSection === this.state.id) {
                this.onUploadDataFromSection();
            }
        }
    }

    onChangeSelectInputGroup = (fields) => {
        const {resourcesFields, warrantyFields} = this.state;
        const selectInputGroup = fields[0].group;
        const isResourcesSection = resourcesFields.findIndex((field) => field.group === selectInputGroup) !== -1;
        const isWarrantySection = warrantyFields.findIndex((field) => field.group === selectInputGroup) !== -1;

        if (isResourcesSection) {
            const updatedFields = onUpdateSelectInputGroup(fields, resourcesFields);
            this.setState({resourcesFields: updatedFields});
        }

        if (isWarrantySection) {
            const updatedFields = onUpdateSelectInputGroup(fields, warrantyFields);
            this.setState({warrantyFields: updatedFields});
        }

        this.onSaveButtonChange();
    };

    onInputChange = ({target: {name, value}}) => {
        const {resourcesFields, warrantyFields} = this.state;

        const currentFieldsChecking = (fields) => fields.findIndex((field) => field.name === name) !== -1;

        const isResourcesSection = currentFieldsChecking(resourcesFields);
        const isWarrantySection = currentFieldsChecking(warrantyFields);

        if (isResourcesSection) {
            const newResourcesFields = onChangeFieldValue(resourcesFields, name, value);
            this.setState({resourcesFields: newResourcesFields});
        }

        if (isWarrantySection) {
            const newWarrantyFields = onChangeFieldValue(warrantyFields, name, value);
            this.setState({warrantyFields: newWarrantyFields});
        }

        this.onSaveButtonChange();
    };

    onSaveButtonChange = () => {
        if (this.state.isSaveButtonDisabled && !this.state.isDisabledSection) {
            this.setState({isSaveButtonDisabled: false});
            this.props.onUpdateSaveStatus(this.state.id, false);
        }
    };

    onUploadDataFromSection = (action = 'auto-get') => {
        const {resourcesFields, warrantyFields} = this.state;
        const allFields = [...resourcesFields, ...warrantyFields];
        const form = document.getElementById(`${this.state.id}_form`);
        const formData = new FormData(form);

        const data = getDataFromFields(allFields);
        const object = formDataObjectHandler(formData, data, allFields);

        if (action === 'save' && this.props.pageMode !== 'EDIT') {
            this.props.onSave(this.state.id, object, `${this.props.storageItem}_${this.storageKey}`);

        } else {
            this.props.onSave(this.state.id, object);
        }

        this.setState({isSaveButtonDisabled: true});
    };

    onResourcesSave = () => {
        this.onUploadDataFromSection('save');
    };

    render() {
        const {resources} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {id, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;

        return <Resources {...this.props} {...rest}
                          id={id}
                          panelData={resources}
                          isDisabled={isDisabledSection}
                          isDisabledAlways={isDisabledAlwaysSection}
                          onChangeSelectInputGroup={this.onChangeSelectInputGroup}
                          onInputChange={this.onInputChange}
                          onClick={() => this.props.onClick(id)}
                          onSave={this.onResourcesSave}
                          onSkip={() => this.props.onSkip(id)}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onUpdateSaveStatus: () => {
        },
        onClick: () => {
        },
        onSave: () => {
        },
    };

    static propsTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        placeProductSections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        list: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        fetchingDataFromSection: PropTypes.object,
        onUpdateSaveStatus: PropTypes.func,
        updatedPlaceProductSectionDisabledStatus: PropTypes.func,
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
    };
}

export default ResourcesContainer;