import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './input.module.scss';

const Input = (props) => {
    const {
        id, type, inputContainerClass, inputName,
        inputPlaceholder, autoComplete, label,
        errorMessage, isVisiblePassword, isError, withEyeIcon,
        withStaticLabel, onInputChange, onInputFocus, onEyeClick,
    } = props;

    const cx = classnames.bind(styles);
    const inputContainerClasses = cx('container', inputContainerClass, {'error': isError});
    const eyeIconClasses = cx('eye-icon', {'active': isVisiblePassword});
    const labelClasses = cx('label', {'static': withStaticLabel});

    const basicParams = {
        id,
        type,
        name: inputName,
        placeholder: inputPlaceholder,
        className: styles.input,
        onChange: (e) => onInputChange(e),
        onFocus: (e) => onInputFocus(e),
        autoComplete: autoComplete ? 'on' : 'off',
    };

    return (
        <div className={inputContainerClasses}>
            <input {...basicParams}/>

            <span className={labelClasses}>{label}</span>
            <span className={styles['error-message']}>{errorMessage}</span>
            <span className={styles['error-icon']}/>
            {withEyeIcon && <div className={eyeIconClasses} onClick={onEyeClick}/>}
        </div>
    );
};

Input.defaultProps = {
    type: 'text',
    inputPlaceholder: '',
    autoComplete: true,
    isVisiblePassword: false,
    isError: false,
    withStaticLabel: false,
    withEyeIcon: false,
    onInputChange: () => {
    },
};

Input.propTypes = {
    id: PropTypes.any,
    type: PropTypes.oneOf(['text', 'password']),
    inputContainerClass: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string,
    autoComplete: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    errorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isVisiblePassword: PropTypes.bool,
    isError: PropTypes.bool,
    withStaticLabel: PropTypes.bool,
    withEyeIcon: PropTypes.bool,
    onInputChange: PropTypes.func,
    onInputFocus: PropTypes.func,
    onEyeClick: PropTypes.func,
};

export default Input;