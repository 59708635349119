import {createRequestHandler} from '../../../../utils';
import {CREATE_PACKING_PLACE_SUCCESS} from './create-packing-place';

export const PRODUCTS_LIST_FOR_PACKING_REQUEST = 'PRODUCTS_LIST_FOR_PACKING_REQUEST';
export const PRODUCTS_LIST_FOR_PACKING_SUCCESS = 'PRODUCTS_LIST_FOR_PACKING_SUCCESS';
export const PRODUCTS_LIST_FOR_PACKING_FAILURE = 'PRODUCTS_LIST_FOR_PACKING_FAILURE';

export const PRODUCTS_LIST_FOR_PACKING_UPDATING_REQUEST = 'PRODUCTS_LIST_FOR_PACKING_UPDATING_REQUEST';
export const PRODUCTS_LIST_FOR_PACKING_UPDATING_SUCCESS = 'PRODUCTS_LIST_FOR_PACKING_UPDATING_SUCCESS';
export const PRODUCTS_LIST_FOR_PACKING_UPDATING_FAILURE = 'PRODUCTS_LIST_FOR_PACKING_UPDATING_FAILURE';

export const productsListForPackingRequest = createRequestHandler('PRODUCTS_LIST_FOR_PACKING');
export const updatingProductsListForPackingRequest = createRequestHandler('PRODUCTS_LIST_FOR_PACKING_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    invoiceID: null,
    tabs: [],
    unpacked: null,
    packed: null,
    forPacking: [],
    dataForPacking: null,
    packedItemsCounter: 0,
    error: null,
};

const onInitState = (payload, state) => {
    const {unpacked, packed, invoice_id, ...rest} = payload;
    const {tabs, packedItemsCounter} = state;
    const initIDs = [{id: 'shipment_lot'}, {id: 'packing_list'}];

    const newTabs = initIDs.map((item, idx) => ({
        ...item,
        label: idx === 0 ? unpacked.tab : packed.tab,
        isActive: tabs.length === 0 ? idx === 0 : tabs[idx].isActive,
    }));

    let unpackedList = [];
    if (unpacked.list.length !== 0) {
        unpackedList = unpacked.list.map((item) => ({
            ...item,
            name: `offer[${item.id}]`,
            isChecked: 0,
        }));
    }

    const newUnpacked = {list: unpackedList, ...rest};
    const newPacked = {list: packed.list, ...rest};

    return {
        invoiceID: invoice_id,
        tabs: newTabs,
        unpacked: newUnpacked,
        packed: newPacked,
        packedItemsCounter,
    };
};

const onUpdateState = (payload, state) => {
    const {unpacked, packed, ...rest} = payload;
    const {
        tabs: prevTabs,
        unpacked: prevUnpacked,
        packed: prevPacked,
    } = state;

    const tabs = prevTabs.map((item, idx) => ({
        ...item,
        label: idx === 0 ? unpacked.tab : packed.tab,
    }));

    let unpackedList = prevUnpacked.list;
    if (unpacked.list.length !== 0) {
        if (unpackedList.length !== 0) {
            unpacked.list.forEach((item) => {
                const {id} = item;

                unpackedList = unpackedList.map((unpackedItem) => {
                    if (unpackedItem.id === id) {
                        return {
                            ...unpackedItem,
                            ...item,
                        };
                    }

                    return unpackedItem;
                });
            });
        }
    } else {
        unpackedList = [];
    }

    const forPacking = unpackedList.filter(({isChecked}) => isChecked);

    let dataForPacking = null;
    if (forPacking.length !== 0) {
        let object = {};

        forPacking.forEach(({name, quantity}) => {
            object = {
                ...object,
                [name]: quantity,
            };
        });

        dataForPacking = object;
    }

    const newUnpacked = {
        ...prevUnpacked,
        list: unpackedList,
        ...rest,
    };
    const newPacked = {
        ...prevPacked,
        list: packed.list,
        ...rest,
    };

    return {
        tabs,
        unpacked: newUnpacked,
        packed: newPacked,
        forPacking,
        dataForPacking,
    };
};

const onResetState = (state) => {
    const {unpacked, packed} = state;

    let unpackedList = unpacked.list.map((item) => ({
        ...item,
        isChecked: 0,
    }));

    const newUnpacked = {...unpacked, list: unpackedList};
    const newPacked = {...packed, list: packed.list};

    return {
        unpacked: newUnpacked,
        packed: newPacked,
    };
};

const productsList = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PRODUCTS_LIST_FOR_PACKING_REQUEST:
            return state;

        case PRODUCTS_LIST_FOR_PACKING_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case PRODUCTS_LIST_FOR_PACKING_SUCCESS:
            const initState = onInitState(payload, state);

            return {
                invoiceID: initState.invoiceID,
                tabs: initState.tabs,
                unpacked: initState.unpacked,
                packed: initState.packed,
                forPacking: [],
                dataForPacking: null,
                packedItemsCounter: initState.packedItemsCounter,
                loading: false,
                updating: false,
                error: null,
            };

        case PRODUCTS_LIST_FOR_PACKING_UPDATING_SUCCESS:
            const updatedState = onUpdateState(payload, state);

            return {
                ...state,
                tabs: updatedState.tabs,
                unpacked: updatedState.unpacked,
                packed: updatedState.packed,
                forPacking: updatedState.forPacking,
                dataForPacking: updatedState.dataForPacking,
                updating: false,
            };

        case PRODUCTS_LIST_FOR_PACKING_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case PRODUCTS_LIST_FOR_PACKING_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case CREATE_PACKING_PLACE_SUCCESS:
            const {packedItemsCounter: prevPackedItemsCounter} = state;
            return {
                ...state,
                packedItemsCounter: prevPackedItemsCounter + 1,
            };

        case 'UPDATE_TABS_FOR_PACKING':
            return {
                ...state,
                tabs: payload,
                packedItemsCounter: 0,
            };

        case 'UPDATE_PRODUCTS_LIST_FOR_PACKING':
            return {
                ...state,
                unpacked: payload.unpacked,
                forPacking: payload.forPacking,
                dataForPacking: payload.dataForPacking,
            };

        case 'RESET_PACKING_LIST_WIZARD_STATE_TO_INITIAL':
            const resetState = onResetState(state);
            return {
                ...state,
                unpacked: resetState.unpacked,
                packed: resetState.packed,
                forPacking: [],
                dataForPacking: null,
            };

        case 'RESET_PACKING_LIST_WIZARD_PAGE_WITHOUT_TABS':
            return {
                ...initialState,
                tabs: state.tabs,
                packedItemsCounter: state.packedItemsCounter,
            };

        case 'RESET_PACKING_LIST_WIZARD_PAGE':
            return initialState;

        default:
            return state;
    }
};

export default productsList;