export const filterDropdownHandler = (currentID, dropdowns) => {
    const currentDropdownIdx = dropdowns.findIndex(({id}) => id === currentID);
    const currentDropdown = dropdowns[currentDropdownIdx];

    return dropdowns.map(({id, isOpen, ...rest}) => {
        if (id === currentID) {
            return {
                id,
                ...rest,
                isOpen: !currentDropdown.isOpen,
            };
        }

        return {
            id,
            ...rest,
            isOpen: !currentDropdown.isOpen ? currentDropdown.isOpen : isOpen,
        };
    });
};