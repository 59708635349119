import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    onInitSectionStateFromDataByServer,
    sectionSettingsHandler,
    prevStateSectionSettingsHandler,
} from '../../common/handlers';
import {
    formDataObjectHandler,
    transformFieldsWithInputGroup,
} from '../../../../../common/handlers';
import {
    removeSessionStorageItemsAfterCheck,
} from '../../../../../common/handlers/field-handlers';
import sweetAlert from '../../../../../../../common/sweet-alert';

import MainTechnicalData from './main-technical-data';
import FormFieldCreator from '../../../../../common/handlers/form-field-creator';

class MainTechnicalDataContainer extends Component {
    state = {
        id: null,
        initForm: null,
        mainTechnicalDataFields: null,
        inputGroupFields: null,
        textareaField: null,
        isDisabledSection: false,
        isDisabledAlwaysSection: false,
        isSaveButtonDisabled: true,
    };

    storageKey = 'mtechdata';

    componentDidMount() {
        this.setState({id: this.props.id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (this.props.sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, this.props.sections);

                if (prevProps.sections.length > 0) {
                    const {isDisabled: prevDisabled} = prevStateSectionSettingsHandler(this.state.id, prevProps.sections);

                    if (this.props.pageMode !== 'EDIT') {
                        if (prevDisabled !== isDisabled && !prevDisabled && this.state.initForm) {
                            const {fields, inputGroupFields, textareaField} = this.state.initForm;
                            const {storageItem} = this.props;

                            removeSessionStorageItemsAfterCheck(storageItem, this.storageKey);

                            this.setState({
                                mainTechnicalDataFields: fields,
                                inputGroupFields,
                                textareaField,
                            });

                            this.props.onUpdateSaveStatus(this.state.id, false);
                        }
                    }
                }

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }

        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                const {list, storageItem, pageMode} = this.props;

                let mainTechnicalData = list.filter(({section}) => section.id === 'main_technical_data');
                const mainTechnicalDataFields = transformFieldsWithInputGroup(mainTechnicalData, `${storageItem}_${this.storageKey}`);

                const formFieldCreator = new FormFieldCreator();
                const fields = formFieldCreator.getFormFieldInputGroup(mainTechnicalDataFields);

                const inputGroupFields = fields.filter(item => Array.isArray(item));
                const textareaField = fields[fields.length - 1];

                let updatedState = {
                    mainTechnicalDataFields: fields,
                    inputGroupFields,
                    textareaField,
                };

                if (pageMode === 'EDIT') {
                    const isDisabled = onInitSectionStateFromDataByServer(mainTechnicalData);
                    if (isDisabled !== this.state.isDisabledSection) {
                        this.props.updatedPlaceProductSectionDisabledStatus(this.state.id, isDisabled);
                    }

                } else {
                    updatedState = {
                        ...updatedState,
                        /* case for toggle disabled status and global form cleaning */
                        initForm: {
                            fields,
                            inputGroupFields,
                            textareaField,
                        },
                    };
                }

                this.setState((prevState) => ({
                    ...prevState,
                    ...updatedState,
                }));
            }
        }

        if (prevProps.fetchingDataFromSection !== this.props.fetchingDataFromSection) {
            const {fetchingDataFromSection} = this.props;

            if (fetchingDataFromSection && fetchingDataFromSection.currentSection === this.state.id) {
                this.onUploadDataFromSection();
            }
        }
    }

    onAddInputBlock = () => {
        const {inputGroupFields, textareaField} = this.state;

        const newGroup = inputGroupFields[inputGroupFields.length - 1].map(field => {
            const regExp = /\d+/g;
            const groupIdx = parseInt(field.group.match(regExp)[0]);
            const nameIdx = parseInt(field.name.match(regExp)[0]);

            return {
                ...field,
                group: field.group.replace(groupIdx, groupIdx + 1),
                name: field.name.replace(nameIdx, nameIdx + 1),
                value: '',
            };
        });

        const newGroupFields = [
            ...inputGroupFields,
            newGroup,
        ];

        const newMainTechnicalDataFields = [
            ...newGroupFields,
            textareaField,
        ];

        this.setState({
            inputGroupFields: newGroupFields,
            mainTechnicalDataFields: newMainTechnicalDataFields,
        });
        this.onSaveButtonChange();
    };

    onRemoveInputBlock = (currentIdx) => {
        const {inputGroupFields, textareaField} = this.state;
        const {delete_item_question} = this.props.staticData.alert_modal;
        const withQuestion = inputGroupFields[currentIdx].find(({value}) => value !== '');

        const onRemoveAction = () => {
            const itemsAfterDeleted = inputGroupFields.slice(currentIdx + 1).map((fieldsGroup) => {
                return fieldsGroup.map((field) => {
                    const regExp = /\d+/g;
                    const groupIdx = parseInt(field.group.match(regExp)[0]);
                    const nameIdx = parseInt(field.name.match(regExp)[0]);

                    return {
                        ...field,
                        group: field.group.replace(groupIdx, groupIdx - 1),
                        name: field.name.replace(nameIdx, nameIdx - 1),
                    };
                });
            });

            const newInputGroupFields = [
                ...inputGroupFields.slice(0, currentIdx),
                ...itemsAfterDeleted,
            ];

            const newMainTechnicalDataFields = [
                ...newInputGroupFields,
                textareaField,
            ];

            this.setState({
                inputGroupFields: newInputGroupFields,
                mainTechnicalDataFields: newMainTechnicalDataFields,
            });
        };

        if (withQuestion) {
            sweetAlert('question', delete_item_question)
                .then((resp) => {
                    if (resp.isConfirmed) {
                        onRemoveAction();
                        this.onSaveButtonChange();
                    }
                });
        } else {
            onRemoveAction();
        }
    };

    onSaveButtonChange = () => {
        if (this.state.isSaveButtonDisabled && !this.state.isDisabledSection) {
            this.setState({isSaveButtonDisabled: false});
            this.props.onUpdateSaveStatus(this.state.id, false);
        }
    };

    onGetDataFromFields = () => {
        const {mainTechnicalDataFields} = this.state;
        let object = {};

        mainTechnicalDataFields.forEach(item => {
            if (Array.isArray(item)) {
                item.forEach(field => {
                    object = {
                        ...object,
                        [field.name]: field.value,
                    };
                });
            } else {
                object = {
                    ...object,
                    [item.name]: item.value,
                };
            }
        });

        return object;
    };

    onInputChange = ({target: {name, value}}) => {
        const {inputGroupFields, textareaField} = this.state;
        this.onSaveButtonChange();

        let newInputGroupFields = [];
        for (let i = 0; i < inputGroupFields.length; i++) {
            const idx = inputGroupFields[i].findIndex((field) => field.name === name);
            if (idx !== -1) {
                const currentField = inputGroupFields[i][idx];

                const newGroup = [
                    ...inputGroupFields[i].slice(0, idx),
                    {
                        ...currentField,
                        value,
                    },
                    ...inputGroupFields[i].slice(idx + 1),
                ];

                newInputGroupFields = [
                    ...inputGroupFields.slice(0, i),
                    newGroup,
                    ...inputGroupFields.slice(i + 1),
                ];
            }
        }

        const newMainTechnicalDataFields = [
            ...newInputGroupFields,
            textareaField,
        ];

        this.setState({
            inputGroupFields: newInputGroupFields,
            mainTechnicalDataFields: newMainTechnicalDataFields,
        });
    };

    onTextEditorDataChange = (text) => {
        const {inputGroupFields, textareaField} = this.state;
        this.onSaveButtonChange();

        const newTextareaField = {
            ...textareaField,
            value: text,
        };

        const newMainTechnicalDataFields = [
            ...inputGroupFields,
            newTextareaField,
        ];

        this.setState({
            textareaField: newTextareaField,
            mainTechnicalDataFields: newMainTechnicalDataFields,
        });
    };

    onUploadDataFromSection = (action = 'auto-get') => {
        const form = document.getElementById(`${this.state.id}_form`);
        const formData = new FormData(form);
        const data = this.onGetDataFromFields();
        let object = formDataObjectHandler(formData, data);

        const objectKeys = Object.keys(object);
        const emptyProperty = objectKeys.filter((key) => object[key] === '' && !key.includes('_text'));
        const emptyTextProperty = objectKeys.filter((key) => object[key] === '' && key.includes('_text'));

        const onUpdateObject = (key, object) => {
            const handler = (key1_substr, key2_substr) => {
                const secondKey = key.replace(key1_substr, key2_substr);
                if (!object[secondKey]) {
                    delete object[key];
                }
            };

            if (key.includes('[name]')) {
                handler('name', 'value');
            }

            if (key.includes('[value]')) {
                handler('value', 'name');
            }
        };

        if (emptyProperty.length > 0) {
            emptyProperty.forEach((key) => {
                onUpdateObject(key, object);
            });
        }

        if (Object.keys(object).length < 2) {
            if (object.hasOwnProperty(emptyTextProperty[0])) {
                delete object[emptyTextProperty[0]];
            }
        }

        if (action === 'save' && this.props.pageMode !== 'EDIT') {
            this.props.onSave(this.state.id, object, `${this.props.storageItem}_${this.storageKey}`);

        } else {
            this.props.onSave(this.state.id, object);
        }

        this.setState({isSaveButtonDisabled: true});
    };

    onMainTechnicalDataSave = () => {
        this.onUploadDataFromSection('save');
    };

    render() {
        const {main_technical_data} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {id, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;

        return <MainTechnicalData {...this.props} {...rest}
                                  id={id}
                                  panelData={main_technical_data}
                                  isDisabled={isDisabledSection}
                                  isDisabledAlways={isDisabledAlwaysSection}
                                  onAddInputBlock={this.onAddInputBlock}
                                  onRemoveInputBlock={this.onRemoveInputBlock}
                                  onInputChange={this.onInputChange}
                                  textEditorReadOnly={this.props.pageMode === 'EDIT'}
                                  onTextEditorDataChange={this.onTextEditorDataChange}
                                  onClick={() => this.props.onClick(id)}
                                  onSave={this.onMainTechnicalDataSave}
                                  onSkip={() => this.props.onSkip(id)}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onUpdateSaveStatus: () => {
        },
        onClick: () => {
        },
        onSave: () => {
        },
    };

    static propsTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        sections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        list: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        fetchingDataFromSection: PropTypes.object,
        onUpdateSaveStatus: PropTypes.func,
        updatedPlaceProductSectionDisabledStatus: PropTypes.func,
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
    };
}

export default MainTechnicalDataContainer;