export const challenges_fr = [
    {
        title: 'Accès limité à des plateformes de commerce électronique simples et intuitives, en particulier pour les PME',
        text: `Le manque de disponibilité et donc l'accès limité au système d'approvisionnement 
        électronique au niveau de l'entreprise et la complexité de l'utilisation de sorte qu'il n'est 
        utilisé que par les géants du transport aérien et terrestre.`,
        url: 'challenge_4.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Automatisation incomplète de la documentation d’exportation',
        text: `L'établissement incomplet des factures et des listes de colisage pour la préparation 
        de la documentation d'exportation complète. De même, le mélange de systèmes manuels et 
        électroniques dans les achats crée de la complexité et de la confusion.`,
        url: 'challenge_5.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Problèmes de confiance, en particulier de la part de nouveaux clients',
        text: `Le manque de confiance des clients lors de transactions en ligne crée de la peur, de 
        l'hésitation et des achats ratés. En outre, la bureaucratie au sein des sociétés d'État 
        entraîne des retards de paiement, ce qui entrave les relations commerciales entre les 
        consommateurs et les fournisseurs.`,
        url: 'challenge_7.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Irresponsabilité financière et absence de suivi des expéditions en temps réel',
        text: `Aucune mise à jour en temps réel sur l’état de l’expédition, associée à un manque de 
        responsabilité financière pour la logistique et la livraison en temps opportun des pièces de rechange.`,
        url: 'challenge_6.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Prix plus élevés des sociétés intermédiaires',
        text: `Avez-vous déjà été victime d' «intermédiaires» qui surfacturent souvent les pièces 
        de rechange, parfois jusqu’à trois fois le prix régulier?`,
        url: 'challenge_2.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Problèmes de sécurité',
        text: `Les transactions sont entachées d’activités frauduleuses telles que les livraisons en défaut, 
        la perte d’argent et la réception de produits défectueux.`,
        url: 'challenge_3.svg',
        alt: 'Challenge image',
    },
];