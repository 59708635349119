import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../../../../../../../../../common/checkbox';
import EllipsisTextTooltip from '../../../../../../../../../../../../common/ellipsis-text-tooltip';

import styles from './shipment-lot-item.module.scss';

const ShipmentLotItem = (props) => {
    const {
        staticData, id, number, name, isChecked: value, quantity,
        packed_quantity, row, classNames, onCheckboxChange,
    } = props;
    const {packed_status} = staticData.pages.shipment_lot;
    /* row[row.length - 1].value -> packing status (if packed, then readonly) */
    const isCheckboxReadonly = row[row.length - 1].value && quantity === 0;

    const rowData = row.map(({value}, idx) => {
        if (idx === row.length - 1 && value) {
            return (
                <div key={idx} className={`${styles.column} ${styles.packed}`}>
                    {packed_status} {(packed_quantity && packed_quantity !== 0) && `(${packed_quantity})`}
                </div>
            );
        }

        return (
            <div key={idx} className={styles.column}>
                {idx === 0 || idx === 1 ? <EllipsisTextTooltip tooltip={value}/> : value}
            </div>
        );
    });

    return (
        <div className={`${styles.container} ${classNames}`}>
            <Checkbox id={id}
                      name={name}
                      value={value}
                      classNames={styles.checkbox}
                      onChange={onCheckboxChange}
                      optionReadOnly={isCheckboxReadonly}
                      activeCheckboxList={value !== 0 ? [value] : []}/>

            <div className={styles.row}>
                <div className={styles.column}>{number}</div>
                {rowData}
            </div>
        </div>
    );
};

ShipmentLotItem.defaultProps = {
    onCheckboxChange: () => {
    },
};

ShipmentLotItem.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.number,
    number: PropTypes.number,
    name: PropTypes.string,
    isChecked: PropTypes.number,
    quantity: PropTypes.number,
    packed_quantity: PropTypes.number,
    row: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })),
    classNames: PropTypes.string,
    onCheckboxChange: PropTypes.func,
};

export default ShipmentLotItem;