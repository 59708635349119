import {createAlertResponse, createRequestHandler} from '../../../../utils';

const ACCESS_SETUP_DATA_UPLOAD_REQUEST = 'ACCESS_SETUP_DATA_UPLOAD_REQUEST';
const ACCESS_SETUP_DATA_UPLOAD_SUCCESS = 'ACCESS_SETUP_DATA_UPLOAD_SUCCESS';
const ACCESS_SETUP_DATA_UPLOAD_FAILURE = 'ACCESS_SETUP_DATA_UPLOAD_FAILURE';
export const RESET_ACCESS_SETUP_DATA_UPLOAD = 'RESET_ACCESS_SETUP_DATA_UPLOAD';

export const accessSetupDataUploadRequest = createRequestHandler('ACCESS_SETUP_DATA_UPLOAD', {
    notAcceptableErrorRedirect: false,
});

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const uploadFormData = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case ACCESS_SETUP_DATA_UPLOAD_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case ACCESS_SETUP_DATA_UPLOAD_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case ACCESS_SETUP_DATA_UPLOAD_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case RESET_ACCESS_SETUP_DATA_UPLOAD:
            return initialState;

        default:
            return state;
    }
};

export default uploadFormData;