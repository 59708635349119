import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withServices} from '../service-context';
import {loginRequest, twoFactorRequest} from '../../reducers/login';
import {registrationRequest} from '../../reducers/registration';
import {resetPasswordRequest} from "../../reducers/public-data/reset-password/request";
import {
    loginAction,
    registrationAction,
    resetRequestLock,
    setInitialResetPasswordReduxState,
} from '../../actions/common';
import {detectingPublicUrl, staticURLs} from '../../services/api-urls';

import SignIn from './sign-in';
import SignUp from './sign-up';

class AuthorizationModule extends Component {
    timerId = null;

    redirectHandler = () => {
        const {pathname} = this.props.location;
        const isPublicUrl = detectingPublicUrl(pathname);
        const redirectToHomePage = !isPublicUrl;

        if (redirectToHomePage) {
            this.props.history.push(staticURLs.home);
        }
    };

    onSignInSubmit = (credentials) => {
        this.resetServerError();
        this.props.onLogin(credentials);
    };

    onSignInTwoFactorSubmit = (url, credentials) => {
        this.resetServerError();
        this.props.onLoginTwoFactor(url, credentials);
    };

    onSignInResetPassword = (body) => {
        this.resetServerError();
        this.props.onResetPassword(body);
    };

    onSuccessResetPassword = () => {
        if (this.timerId !== null) {
            clearTimeout(this.timerId);
        }

        this.timerId = setTimeout(() => this.props.closeSignIn(), 600);
    };

    onSetInitialResetPasswordReduxState = () => {
        this.props.setInitialResetPasswordReduxState();
    };

    onSignUpSubmit = (data) => {
        this.resetServerError();
        this.props.onRegistration(data);
    };

    onSignInClose = () => {
        this.props.closeSignIn();
        this.redirectHandler();
    };

    onSignUpOpen = () => {
        this.props.closeSignIn();

        if (this.timerId !== null) {
            clearTimeout(this.timerId);
        }

        this.timerId = setTimeout(() => this.props.openSignUp(), 600);
    };

    onSignUpClose = () => {
        this.props.closeSignUp();
        this.redirectHandler();
    };

    resetServerError = () => {
        if (this.props.serverError || this.props.profileError) {
            this.props.resetRequestLock();
        }
    };

    componentWillUnmount() {
        clearTimeout(this.timerId);
    }

    render() {
        return (
            <>
                <SignUp onSubmit={this.onSignUpSubmit}
                        onContainerClose={this.onSignUpClose}
                        onClose={this.onSignUpClose}/>
                <SignIn onSubmit={this.onSignInSubmit}
                        onSubmitTwoFactor={this.onSignInTwoFactorSubmit}
                        onSubmitResetPassword={this.onSignInResetPassword}
                        onSuccessResetPassword={this.onSuccessResetPassword}
                        onSetInitialResetPasswordReduxState={this.onSetInitialResetPasswordReduxState}
                        onContainerClose={this.onSignInClose}
                        onClose={this.onSignInClose}
                        openSignUp={this.onSignUpOpen}/>
            </>
        );
    }

    static propTypes = {
        history: PropTypes.shape({
            location: PropTypes.shape({
                pathname: PropTypes.string,
            }),
            push: PropTypes.func,
        }),
        signInActive: PropTypes.bool,
        signUpActive: PropTypes.bool,
        profileError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        serverError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        onRegistration: PropTypes.func,
        onLogin: PropTypes.func,
        onLoginTwoFactor: PropTypes.func,
        onResetPassword: PropTypes.func,
        closeSignIn: PropTypes.func,
        openSignUp: PropTypes.func,
        closeSignUp: PropTypes.func,
        resetRequestLock: PropTypes.func,
        setInitialResetPasswordReduxState: PropTypes.func,
    };
}

const mapServicesToProps = ({userService, publicService}) => ({
    onLogin: loginRequest(userService.login),
    onLoginTwoFactor: twoFactorRequest(userService.twoFactor),
    onRegistration: registrationRequest(publicService.registration),
    onResetPassword: resetPasswordRequest(publicService.resetPasswordRequest),
});

const mapStateToProps = ({profile: {error}, utils: {serverError}}) => ({
    profileError: error,
    serverError,
});

const mapDispatchToProps = (dispatch, {onLogin, onLoginTwoFactor, onRegistration, onResetPassword}) => {
    const {closeSignIn} = loginAction;
    const {openSignUp, closeSignUp} = registrationAction;

    return bindActionCreators({
        onRegistration,
        onLogin,
        onLoginTwoFactor,
        onResetPassword,
        closeSignIn,
        openSignUp,
        closeSignUp,
        resetRequestLock,
        setInitialResetPasswordReduxState,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(AuthorizationModule);