import React from 'react';
import PropTypes from 'prop-types';

const InfoMark = ({status, classNames, markColor}) => {
    let fillColor = '#666666';
    switch (status) {
        case 'warning':
            fillColor = '#ff9d29';
            break;
        case 'error':
            fillColor = '#e63a3a';
            break;
        default:
            fillColor = '#666666';
    }

    fillColor = markColor && markColor !== '' ? markColor : fillColor;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
             className={classNames}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM7.25 4.25V5.75H8.75V4.25H7.25ZM8.75 11C8.75 11.4125 8.4125 11.75 8 11.75C7.5875 11.75 7.25 11.4125 7.25 11V8C7.25 7.5875 7.5875 7.25 8 7.25C8.4125 7.25 8.75 7.5875 8.75 8V11ZM2 8C2 11.3075 4.6925 14 8 14C11.3075 14 14 11.3075 14 8C14 4.6925 11.3075 2 8 2C4.6925 2 2 4.6925 2 8Z"
                  fill={fillColor}/>
        </svg>
    );
};

InfoMark.defaultProps = {
    status: 'info',
    markColor: false,
};

InfoMark.propTypes = {
    status: PropTypes.oneOf(['info', 'warning', 'error']),
    classNames: PropTypes.string,
    markColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default InfoMark;