import {createRequestHandler} from '../../../utils';

const INVITATION_FORM_PUBLIC_UPLOAD_REQUEST = 'INVITATION_FORM_PUBLIC_UPLOAD_REQUEST';
export const INVITATION_FORM_PUBLIC_UPLOAD_SUCCESS = 'INVITATION_FORM_PUBLIC_UPLOAD_SUCCESS';
const INVITATION_FORM_PUBLIC_UPLOAD_FAILURE = 'INVITATION_FORM_PUBLIC_UPLOAD_FAILURE';

export const invitationFormPublicUploadRequest = createRequestHandler('INVITATION_FORM_PUBLIC_UPLOAD', {
    notAcceptableErrorRedirect: false,
    setGlobalServerError: false,
});

const initialState = {
    loading: false,
    confirmed: false,
    error: null,
};

const uploadData = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case INVITATION_FORM_PUBLIC_UPLOAD_REQUEST:
            return {
                loading: true,
                confirmed: false,
                error: null,
            };

        case INVITATION_FORM_PUBLIC_UPLOAD_SUCCESS:
            return {
                loading: false,
                confirmed: true,
                error: null,
            };

        case INVITATION_FORM_PUBLIC_UPLOAD_FAILURE:
            return {
                loading: false,
                confirmed: false,
                error: payload,
            };

        case 'RESET_INVITATION_FORM_PUBLIC_UPLOAD':
            return initialState;

        default:
            return state;
    }
};

export default uploadData;