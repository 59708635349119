export default class PageService {
    constructor() {
        this.pageID = {
            create_quotation: 'create-quotation',
            activity_hub: 'hub',
            my_products: 'marketplace-products',
            notifications: 'hub-notifications',
            profile: 'hub-profile',
        };
    }
};