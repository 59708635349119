export default class ProfileService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/hub/profile';
    }

    sendRequest = (url, body) => {
        return body ? this._http.post(url, body) : this._http.get(url);
    };

    getProfileDataFields = () => {
        return this._http.get(`${this.baseUrl}/form`);
    };

    editProfile = (body) => {
        return this._http.post(`${this.baseUrl}/form/update`, body);
    };

    /* bank account (payment setup tab) */
    getBankAccounts = () => {
        return this._http.get(`${this.baseUrl}/bank/list`);
    };

    getBankAccountFormFields = (id, query, requestType = 'create') => {
        if (requestType === 'create') {
            return this._http.get(`${this.baseUrl}/bank/create`, query);
        }

        return this._http.get(`${this.baseUrl}/bank/update/${id}`);
    };

    createBankAccount = (body, query) => {
        return this._http.post(`${this.baseUrl}/bank/create`, body, query);
    };

    editBankAccount = (body, id) => {
        return this._http.post(`${this.baseUrl}/bank/update/${id}`, body);
    };

    setDefaultBankAccount = (url) => {
        return this._http.post(url);
    };

    removeBankAccount = (url) => {
        return this._http.post(url);
    };

    /* shipment setup tab */
    getShipmentList = () => {
        return this._http.get(`${this.baseUrl}/shipment/list`);
    };

    shipmentOrganizationForm = (organization_id, id, body, pageType = 'create') => {
        if (pageType === 'create') {
            const url = `${this.baseUrl}/${organization_id}/create`;
            return this.sendRequest(url, body);
        }

        const url = `${this.baseUrl}/${organization_id}/update/${id}`;
        return this.sendRequest(url, body);
    };

    setDefaultShipmentOrganization = (url) => {
        return this._http.post(url);
    };

    removeShipmentOrganization = (url) => {
        return this._http.post(url);
    };

    accessSetupForm = (body) => {
        const url = `${this.baseUrl}/access`;
        return body ? this._http.post(url, body) : this._http.get(url);
    };
};