import {createRequestHandler} from '../../../../utils';

export const SUGGEST_PLATFORMS_REQUEST = 'SUGGEST_PLATFORMS_REQUEST';
export const SUGGEST_PLATFORMS_SUCCESS = 'SUGGEST_PLATFORMS_SUCCESS';
export const SUGGEST_PLATFORMS_FAILURE = 'SUGGEST_PLATFORMS_FAILURE';

export const suggestPlatformsRequest = createRequestHandler('SUGGEST_PLATFORMS');

const initialState = {
    list: [],
    loading: false,
    error: null,
};

const suggestPlatforms = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SUGGEST_PLATFORMS_REQUEST:
            return {
                list: [],
                loading: true,
                error: null,
            };

        case SUGGEST_PLATFORMS_SUCCESS:
            return {
                list: payload.list,
                loading: false,
                error: null,
            };

        case SUGGEST_PLATFORMS_FAILURE:
            return {
                list: [],
                loading: false,
                error: payload,
            };

        case 'RESET_SUGGESTED_PLATFORMS':
            return initialState;

        default:
            return state;
    }
};

export default suggestPlatforms;