import {createRequestHandler} from '../../../../utils';
import {onInitForm, onUpdateForm} from './packing-form';

export const PACKING_EDITING_FORM_REQUEST = 'PACKING_EDITING_FORM_REQUEST';
export const PACKING_EDITING_FORM_SUCCESS = 'PACKING_EDITING_FORM_SUCCESS';
export const PACKING_EDITING_FORM_FAILURE = 'PACKING_EDITING_FORM_FAILURE';

export const PACKING_EDITING_FORM_UPDATING_REQUEST = 'PACKING_EDITING_FORM_UPDATING_REQUEST';
export const PACKING_EDITING_FORM_UPDATING_SUCCESS = 'PACKING_EDITING_FORM_UPDATING_SUCCESS';
export const PACKING_EDITING_FORM_UPDATING_FAILURE = 'PACKING_EDITING_FORM_UPDATING_FAILURE';

export const packingEditingFormRequest = createRequestHandler('PACKING_EDITING_FORM');
export const updatingPackingEditingFormRequest = createRequestHandler('PACKING_EDITING_FORM_UPDATING');

const initialState = {
    placeEditingID: null,
    list: null,
    data: null,
    loading: false,
    updating: false,
    error: null,
};

const packingEditingForm = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PACKING_EDITING_FORM_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case PACKING_EDITING_FORM_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case PACKING_EDITING_FORM_SUCCESS:
            const initForm = onInitForm(payload);

            return {
                ...state,
                list: initForm.list,
                data: initForm.data,
                loading: false,
                updating: false,
                error: null,
            };

        case PACKING_EDITING_FORM_UPDATING_SUCCESS:
            const updatedForm = onUpdateForm(payload, state);

            return {
                ...state,
                updating: false,
                list: updatedForm.list,
                data: updatedForm.data,
            };

        case PACKING_EDITING_FORM_FAILURE:
            return {
                placeEditingID: null,
                list: null,
                data: null,
                loading: false,
                updating: false,
                error: payload,
            };

        case PACKING_EDITING_FORM_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'UPDATE_PACKING_EDITING_FORM':
            return {
                ...state,
                list: payload.list,
                data: payload.data,
            };

        case 'UPDATE_PLACE_EDITING_ID':
            return {
                ...state,
                placeEditingID: payload,
            };

        case 'RESET_PACKING_EDITING_FORM':
        case 'RESET_PACKING_LIST_WIZARD_STATE_TO_INITIAL':
        case 'RESET_PACKING_LIST_WIZARD_PAGE_WITHOUT_TABS':
        case 'RESET_PACKING_LIST_WIZARD_PAGE':
            return initialState;

        default:
            return state;
    }
};

export default packingEditingForm;