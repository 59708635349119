export const about_core_services_fr = {
    title: 'À propos de nos services',
    subtitle: `Chez Exportery, nous offrons à nos clients des fonctionnalités et des services 
    inégalés qui font la différence dans le domaine du commerce industriel`,
    platform: {
        title: 'Caractéristiques de la plate-forme Exportery ™ e-trade',
        headings: ['Pour l\'exportateur', 'Pour l\'importateur'],
        list: [
            {
                text: `Adhésion GRATUITE à la plate-forme e-trade, liste GRATUITE des pièces et 
                composants de rechange et nombre illimité d'appels d'offres`,
                interaction: 'both',
            },
            {
                text: `Interface multilingue et assistance au service à la clientèle EN-FR-ES-RU`,
                interaction: 'both',
            },
            {
                text: `Processus avancé d'enregistrement et de vérification éliminant le risque 
                d'entrer des membres fantômes dans les rôles d'importateurs ou d'exportateurs`,
                interaction: 'both',
            },
            {
                text: `Prix par défaut selon les conditions commerciales FCA Origin Country Airport Incoterms 2020 
                pour tous les produits sur la place de marché`,
                interaction: 'both',
            },
            {
                text: `Tarification instantanée automatique basée sur le CIP Destination country airport Incoterms 
                2020 pour tous les importateurs lors de la création d'appel d'offres électronique`,
                interaction: 'importer',
            },
            {
                text: `Listes de produits confidentiels pour les exportateurs sur demande (masquage du nom, du 
                lieu de fabrication, etc.) pour des raisons de concurrence et de sécurité`,
                interaction: 'exporter',
            },
            {
                text: `Enregistrement de l'historique de l'appel d'offres avec une validité attribuée à chaque devis`,
                interaction: 'both',
            },
            {
                text: `Recherche de numéros de pièce, système de filtrage avancé, identification de plate-forme et 
                regroupement de titres par catalogues techniques d'origine pour faciliter la recherche`,
                interaction: 'both',
            },
            {
                text: `Fiche produit détaillée comprenant les principales données techniques, ressources affectées, 
                données du fabricant, données des usines de révision, TBO, conditions de garantie et paramètres de certification`,
                interaction: 'both',
            },
            {
                text: `Pièces de rechange et composants fonction de recherche d'interchangeabilité et offre de remplacement 
                analogique intégrée à ceux disponibles sur le marché selon les bulletins techniques et la documentation 
                d'un concepteur d'une plate-forme particulière`,
                interaction: 'both',
            },
            {
                text: `Téléchargement groupé (conversion automatique) pratique à partir de listes  Excel pour obtenir un 
                prix d'appel d'offres instantané (CIP) pour plusieurs pièces de rechange et composants en une minute`,
                interaction: 'importer',
            },
            {
                text: `Produits manuels placés sur la plate-forme électronique`,
                interaction: 'exporter',
            },
            {
                text: `Chargement de listes groupées pratiques (conversion automatique) à partir de listes 
                Excel pour placer des offres de masse sur la plate-forme e-trade`,
                interaction: 'exporter',
            },
            {
                text: `Modération manuelle de toutes les données de la plate-forme e-trade par des spécialistes 
                techniques de haut calibre d'Exportery ayant une expérience éprouvée dans transport Aviation, 
                hélicoptères, camions lourds, etc., pour s'assurer que toutes les données sont exemptes d'entrées 
                fantômes, de listes rubriques incorrectes et d'appels d'offres incorrects`,
                interaction: 'both',
            },
            {
                text: `Tarification et création de contrats électroniques et règlements financiers dans 
                différentes devises (USD, EUR, RUB, AED, CNY, CHF, RWF)`,
                interaction: 'both',
            },
        ],
    },
    document_management_system: {
        title: ['Services commerciaux exclusifs et ', 'fonctionnalités du système de gestion des documents'],
        headings: ['Pour l\'exportateur', 'Pour l\'importateur'],
        list: [
            {
                text: `Services holistiques, toutes les prises de risques y compris les risques de fluctuation de 
                devises, et la responsabilité ultime pour les marchandises en consignation de l’orignie FCA à la 
                livraison finale à la destination CIP`,
                interaction: 'both',
            },
            {
                text: `Système de gestion de contrat intuitif Contract Map ™`,
                interaction: 'both',
            },
            {
                text: `Établissement, soumission et signature électronique du certificat d'utilisateur final 
                par l'exploitant aérien, les exploitants de parcs ou les organismes gouvernementaux importateurs`,
                interaction: 'importer',
            },
            {
                text: `Guarantie de performance SWIFT pour les utilisateurs finaux de gouvernements pour les 
                contrats électroniques de plus de 250 000 USD`,
                interaction: 'importer',
            },
            {
                text: `Exportateur SWIFT avec garantie commerciale pour les expéditions de plus de 100 000 USD sur demande`,
                interaction: 'exporter',
            },
            {
                text: `Compte en fiducie pour l'importateur avec seulement 25% du dépôt total de garantie de 
                la valeur du e-Contract en devise locale`,
                interaction: 'importer',
            },
            {
                text: `Établissement d'un contrat électronique contenant les Conditions générales du contrat, 
                les Conditions spéciales du contrat et les spécifications`,
                interaction: 'both',
            },
            {
                text: `Entreposage par fiducie à notre premier point de service de transport en commun à Dar 
                es Salaam, en Tanzanie, pour la sécurité commerciale des importateurs et des exportateurs`,
                interaction: 'both',
            },
            {
                text: `Inspection technique indépendante et enregistrement de photos pour tous les envois et 
                établissement d'un rapport d'inspection pour les paramètres de quantité, de qualité et d'authentification 
                selon de nombreuses normes, par exemple ISO, GOST, MAK, L'AESA, etc., assurant la sécurité 
                opérationnelle tant pour l'importateur que pour l'exportateur`,
                interaction: 'both',
            },
            {
                text: `Création automatique d'une facture électronique et d'une liste d'emballage électronique intuitive 
                avec mécanisme intégré pour fonctionner avec plusieurs pièces de rechange et composants uniques, 
                en vrac, selon différents scénarios, réduisant ainsi le facteur d'erreur humaine`,
                interaction: 'exporter',
            },
            {
                text: `Fonction de fractionnement des lots d'expédition`,
                interaction: 'exporter',
            },
            {
                text: `Signature électronique DocuSign automatique pour tous les documents`,
                interaction: 'both',
            },
            {
                text: `E-AWB pour toutes les expéditions`,
                interaction: 'both',
            },
            {
                text: `Étiquetage automatique pour les envois à l'exportation`,
                interaction: 'exporter',
            },
            {
                text: `Établissement d'une entente trilatérale de type E-Give and Take entre la plate-forme 
                d'Exportery (agent), l'exportateur et l'importateur à la fin du rapport d'inspection pour la 
                relâche des marchandises et les règlements finaux`,
                interaction: 'both',
            },
            {
                text: `Système avancé d'exécution des étapes du contrat et de suivi des expéditions`,
                interaction: 'both',
            },
            {
                text: `Systèmes avancés de notification d'événements`,
                interaction: 'both',
            },
            {
                text: `Gestionnaire de contenu dédié pour l'exportateur`,
                interaction: 'exporter',
            },
            {
                text: `Gestionnaire d'exécution de contrats dédié pour l'exportateur et l'importateur`,
                interaction: 'both',
            },
            {
                text: `Service de réclamations et de retours au titre de la garantie`,
                interaction: 'importer',
            },
        ],
    },
};