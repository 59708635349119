import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const CLEAR_ALL_RFS_REQUEST = 'CLEAR_ALL_RFS_REQUEST';
export const CLEAR_ALL_RFS_SUCCESS = 'CLEAR_ALL_RFS_SUCCESS';
export const CLEAR_ALL_RFS_FAILURE = 'CLEAR_ALL_RFS_FAILURE';

export const clearAllRequest = createRequestHandler('CLEAR_ALL_RFS');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const clearAllRFS = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CLEAR_ALL_RFS_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case CLEAR_ALL_RFS_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case CLEAR_ALL_RFS_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'CLEAR_ALL_RFS':
            return initialState;

        default:
            return state;
    }
};

export default clearAllRFS;