import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {onInitSectionStateFromDataByServer, sectionSettingsHandler} from '../../common/handlers';
import {checkingEqualityOfFiles, dropzonePropsTransformer} from '../../../../../common/handlers';
import uploadFileHandler from '../../../../../../../../utils/upload-file-handler';

import sweetAlert from '../../../../../../../common/sweet-alert';

import Certification from './certification';

class CertificationContainer extends Component {
    state = {
        id: null,
        defaultCertifications: null,
        certifications: null,
        tmpCertifications: null,
        isDisabledSection: true,
        isDisabledAlwaysSection: false,
        isSaveButtonDisabled: true,
        deletedFile: null,
    };

    componentDidMount() {
        this.setState({id: this.props.id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sections !== this.props.sections) {
            if (this.props.sections) {
                const {isDisabled, isDisabledAlways} = sectionSettingsHandler(this.state.id, this.props.sections);

                this.setState({
                    isDisabledSection: isDisabled,
                    isDisabledAlwaysSection: isDisabledAlways,
                });
            }
        }

        if (prevProps.list !== this.props.list) {
            if (this.props.list) {
                const certificationsList = this.props.list.filter(({section}) => section.id === 'certifications');

                let defaultCertifications = {};
                let certifications = {};
                for (let item of certificationsList) {
                    const {name, props, ...rest} = item;
                    const propsArray = props.map(prop => dropzonePropsTransformer(prop));

                    let transformProps = {};
                    for (let item of propsArray) {
                        transformProps = {
                            ...transformProps,
                            ...item,
                        };
                    }

                    const newDefaultCertifications = {
                        ...defaultCertifications,
                        [name]: {
                            ...rest,
                            ...transformProps,
                            name,
                            files: [],
                            alreadyUploadedFiles: item.value,
                            rejectedFiles: [],
                        },
                    };

                    const newCertifications = {
                        ...certifications,
                        [name]: {
                            ...rest,
                            ...transformProps,
                            name,
                            files: [],
                            alreadyUploadedFiles: item.value,
                            rejectedFiles: [],
                            maxCount: transformProps.maxCount - item.value.length,
                        },
                    };

                    defaultCertifications = newDefaultCertifications;
                    certifications = newCertifications;
                }

                if (this.props.pageMode === 'EDIT') {
                    const isDisabled = onInitSectionStateFromDataByServer(certificationsList);
                    if (isDisabled !== this.state.isDisabledSection) {
                        this.props.updatedPlaceProductSectionDisabledStatus(this.state.id, isDisabled);
                    }
                }

                this.setState({
                    defaultCertifications,
                    certifications,
                    tmpCertifications: certifications,
                });
            }
        }

        if (prevState.certifications !== this.state.certifications) {
            if (this.state.certifications) {
                let isExistFiles = [];
                let isDisabled = true;

                const handler = (object) => {
                    let array = [];

                    for (let key in object) {
                        if (object.hasOwnProperty(key)) {
                            const files = object[key].files;
                            array.push(files.length > 0);
                        }
                    }

                    return array;
                };

                isExistFiles = handler(this.state.certifications);

                if (isExistFiles.filter(item => item).length > 0) {
                    isDisabled = false;

                } else {
                    if (prevState.certifications) {
                        isExistFiles = handler(prevState.certifications);

                        if (isExistFiles.filter(item => item).length > 0) {
                            isDisabled = false;
                        }
                    }
                }

                if (this.state.isSaveButtonDisabled !== isDisabled) {
                    this.setState({isSaveButtonDisabled: isDisabled});
                    this.props.onUpdateSaveStatus(this.state.id, isDisabled);
                }
            }
        }

        if (prevProps.fetchingDataFromSection !== this.props.fetchingDataFromSection) {
            const {fetchingDataFromSection} = this.props;

            if (fetchingDataFromSection && fetchingDataFromSection.currentSection === this.state.id) {
                this.onUploadDataFromSection();
            }
        }

        if (prevProps.removeAttachment.loading !== this.props.removeAttachment.loading) {
            if (prevProps.removeAttachment.loading && this.state.deletedFile) {
                this.setState({deletedFile: null});
            }
        }
    }

    certificationDataHandler = () => {
        let certificationData = {};
        for (let key in this.state.certifications) {
            if (this.state.certifications.hasOwnProperty(key)) {
                const files = this.state.certifications[key].files.map(({file}) => file);

                if (files.length !== 0) {
                    const newCertificationData = {
                        ...certificationData,
                        [key]: {files},
                    };

                    certificationData = newCertificationData;
                }
            }
        }

        return certificationData;
    };

    onAcceptedFiles = (acceptedFiles, id) => {
        const {same_name_message} = this.props.staticData.activity_hub.place_product.from_form.sections.certification;
        let currentCertificate = this.state.tmpCertifications[id];

        if (this.state.tmpCertifications[id].files.length !== 0) {
            const files = this.state.tmpCertifications[id].files.map(({file}) => file);
            const {
                files: newAcceptedFiles, infoData,
            } = checkingEqualityOfFiles(files, acceptedFiles);

            if (infoData && infoData.length !== 0) {
                const message = (
                    <div>
                        {same_name_message[0]}
                        <ul>{infoData.map((item, idx) => <li key={idx}>{`- ${item}`}</li>)}</ul>
                        {same_name_message[1]}
                    </div>
                );

                sweetAlert('info', message);
            }

            if (newAcceptedFiles.length !== 0) {
                uploadFileHandler(
                    newAcceptedFiles,
                    (file, progress) => this.onLoadingFile(file, progress, id),
                    (file) => this.onLoadedFile(file, id),
                );

                const newFiles = [
                    ...this.state.tmpCertifications[id].files,
                    ...newAcceptedFiles,
                ];

                currentCertificate = {
                    ...this.state.tmpCertifications[id],
                    files: newFiles,
                    maxCount: this.state.tmpCertifications[id].maxCount - newAcceptedFiles.length,
                };
            }

        } else {
            uploadFileHandler(
                acceptedFiles,
                (file, progress) => this.onLoadingFile(file, progress, id),
                (file) => this.onLoadedFile(file, id),
            );

            currentCertificate = {
                ...this.state.tmpCertifications[id],
                files: acceptedFiles,
                maxCount: this.state.certifications[id].maxCount - acceptedFiles.length,
            };
        }

        let newCertifications = {
            ...this.state.tmpCertifications,
            [id]: currentCertificate,
        };

        this.setState({tmpCertifications: newCertifications});
    };

    onRejectedFiles = (files, id) => {
        const {tmpCertifications, defaultCertifications} = this.state;
        const {quantity_message} = this.props.staticData.activity_hub.place_product.from_form.sections.certification;
        let currentCertificate = tmpCertifications[id];
        let rightFiles = files.filter(({errors}) => errors[0].code === 'too-many-files');

        if (rightFiles.length !== 0) {
            const {alreadyUploadedFiles, files} = currentCertificate;
            const maxAmountOfFiles = currentCertificate.maxCount;
            const currentItemCount = defaultCertifications[id].maxCount;

            let infoMessage = `${quantity_message[0]} ${currentItemCount} ${quantity_message[1]}`;
            const amountOfUploadedFiles = files.length + alreadyUploadedFiles.length;

            if (amountOfUploadedFiles !== 0) {
                infoMessage = `${quantity_message[0]} ${currentItemCount} ${quantity_message[1]}
                ${quantity_message[2]} ${currentItemCount - maxAmountOfFiles} ${quantity_message[3]}`;
            }

            sweetAlert('info', infoMessage);
        } else {
            if (tmpCertifications[id].rejectedFiles.length !== 0) {
                const newFiles = [
                    ...tmpCertifications[id].rejectedFiles,
                    ...files,
                ];

                currentCertificate = {
                    ...this.state.tmpCertifications[id],
                    rejectedFiles: newFiles,
                };

            } else {
                currentCertificate = {
                    ...this.state.tmpCertifications[id],
                    rejectedFiles: files,
                };
            }

            let newCertifications = {
                ...this.state.tmpCertifications,
                [id]: currentCertificate,
            };

            this.setState({
                tmpCertifications: newCertifications,
                certifications: newCertifications,
            });
        }
    };

    onLoadingFile = (file, progress, id) => {
        const {tmpCertifications} = this.state;
        const currentCertificate = tmpCertifications[id];
        const currentFileIdx = currentCertificate.files.findIndex((certificate) => certificate === file);
        const currentFile = currentCertificate.files[currentFileIdx];

        const newCertifications = {
            ...tmpCertifications,
            [id]: {
                ...currentCertificate,
                files: [
                    ...currentCertificate.files.slice(0, currentFileIdx),
                    {
                        file: currentFile,
                        progress,
                        isLoaded: false,
                    },
                    ...currentCertificate.files.slice(currentFileIdx + 1),
                ],
            },
        };

        this.setState({tmpCertifications: newCertifications});
    };

    onLoadedFile = (file, id) => {
        const {tmpCertifications} = this.state;
        const currentCertificate = tmpCertifications[id];
        const currentFileIdx = currentCertificate.files.findIndex((certificate) => certificate.file === file);
        const currentFile = currentCertificate.files[currentFileIdx];

        const newCertifications = {
            ...tmpCertifications,
            [id]: {
                ...currentCertificate,
                files: [
                    ...currentCertificate.files.slice(0, currentFileIdx),
                    {
                        ...currentFile,
                        isLoaded: true,
                    },
                    ...currentCertificate.files.slice(currentFileIdx + 1),
                ],
            },
        };

        this.setState({
            tmpCertifications: newCertifications,
            certifications: newCertifications,
        });
    };

    onDeleteFile = (id, currentIdx) => {
        const {tmpCertifications} = this.state;
        let currentCertificate = tmpCertifications[id];
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newCurrentCertificate = [
                        ...currentCertificate.files.slice(0, currentIdx),
                        ...currentCertificate.files.slice(currentIdx + 1),
                    ];

                    currentCertificate = {
                        ...tmpCertifications[id],
                        files: newCurrentCertificate,
                        maxCount: tmpCertifications[id].maxCount + 1,
                    };

                    const newCertifications = {
                        ...tmpCertifications,
                        [id]: currentCertificate,
                    };

                    this.setState({
                        tmpCertifications: newCertifications,
                        certifications: newCertifications,
                    });
                }
            });
    };

    onDeleteRejectedFile = (id, currentIdx) => {
        const {tmpCertifications} = this.state;
        let currentCertificate = tmpCertifications[id];
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    const newCurrentCertificate = [
                        ...currentCertificate.rejectedFiles.slice(0, currentIdx),
                        ...currentCertificate.rejectedFiles.slice(currentIdx + 1),
                    ];

                    currentCertificate = {
                        ...tmpCertifications[id],
                        rejectedFiles: newCurrentCertificate,
                    };

                    const newCertifications = {
                        ...tmpCertifications,
                        [id]: currentCertificate,
                    };

                    this.setState({
                        tmpCertifications: newCertifications,
                        certifications: newCertifications,
                    });
                }
            });
    };

    onDeleteAlreadyExistFile = (dropzoneName, id) => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.onDeleteAlreadyUploadedFile(dropzoneName, id);
                    this.setState({deletedFile: {dropzoneName, id}});
                }
            });
    };

    onUploadDataFromSection = () => {
        let object = null;
        const certificationData = this.certificationDataHandler();
        for (let key in certificationData) {
            if (certificationData.hasOwnProperty(key)) {
                object = {
                    ...object,
                    [key]: certificationData[key],
                };
            }
        }

        this.props.onSave(this.state.id, null, object);
        this.setState({isSaveButtonDisabled: true});
    };

    onCertificationSave = () => {
        this.onUploadDataFromSection();
    };

    render() {
        const {certification} = this.props.staticData.activity_hub.place_product.from_form.sections;
        const {removeAttachment: {loading}, ...restProps} = this.props;
        const {id, deletedFile, isDisabledSection, isDisabledAlwaysSection, ...rest} = this.state;
        const deleteAlreadyUploadedFile = deletedFile ? {...deletedFile, loading} : null;

        return <Certification {...restProps} {...rest}
                              id={id}
                              panelData={certification}
                              isDisabled={isDisabledSection}
                              isDisabledAlways={isDisabledAlwaysSection}
                              deleteAlreadyUploadedFile={deleteAlreadyUploadedFile}
                              onAcceptedFiles={this.onAcceptedFiles}
                              onRejectedFiles={this.onRejectedFiles}
                              onDeleteFile={this.onDeleteFile}
                              onDeleteRejectedFile={this.onDeleteRejectedFile}
                              onDeleteAlreadyExistFile={this.onDeleteAlreadyExistFile}
                              onClick={() => this.props.onClick(id)}
                              onSave={this.onCertificationSave}
                              onSkip={() => this.props.onSkip(id)}/>;
    }

    static defaultProps = {
        pageMode: 'NEW',
        onClick: () => {},
        onSave: () => {},
        onUpdateSaveStatus: () => {},
        onDeleteAlreadyUploadedFile: () => {},
    };

    static propTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        sections: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isDisabled: PropTypes.bool,
            isDisabledAlways: PropTypes.bool,
        })),
        fetchingDataFromSection: PropTypes.object,
        removeAttachment: PropTypes.shape({
            loading: PropTypes.bool,
        }),
        onClick: PropTypes.func,
        onSave: PropTypes.func,
        onSkip: PropTypes.func,
        onUpdateSaveStatus: PropTypes.func,
        onDeleteAlreadyUploadedFile: PropTypes.func,
        updatedPlaceProductSectionDisabledStatus: PropTypes.func,
    };
}

export default CertificationContainer;