import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {utilsAction} from '../../actions/common';

import ErrorIndicator from '../common/error-indicator';

class IndependentTechnicalSurveyingModule extends Component {
    componentDidMount() {
        const {baseAPIUrl, match: {path}} = this.props;

        if (baseAPIUrl !== path) {
            this.props.setBaseAPIUrl(path);
            this.props.switchDepartmentID(path.substring(1));
        }
    }

    render() {
        return <ErrorIndicator type="SERVICE_UNDER_DEVELOPMENT"/>;
    }

    static propTypes = {
        baseAPIUrl: PropTypes.string,
    };
}

const mapStateToProps = ({utils: {baseAPIUrl}}) => {
    return {
        baseAPIUrl,
    };
};

const mapDispatchToProps = (dispatch) => {
    const {setBaseAPIUrl, switchDepartmentID} = utilsAction;

    return bindActionCreators({
        setBaseAPIUrl,
        switchDepartmentID,
    }, dispatch);
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(IndependentTechnicalSurveyingModule);