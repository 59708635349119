import React from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../../../common/hoc';

import styles from './pg-draft.module.scss';

const PGDraft = ({staticData, type}) => {
    const {pg_draft} = staticData.activity_hub.profile.pages.payment_setup;
    const [title] = pg_draft;
    const text = pg_draft.slice(1);

    const draftText = text.map((paragraph, idx) => {
        const paragraphText = paragraph.map((str, idx) => {
            if (idx < paragraph.length - 1) {
                return `${str} ____________ `;
            }

            return str;
        });

        return <p key={idx} className={styles.paragraph}>{paragraphText}</p>;
    });

    return (
        <div className={styles.container}>
            <div className={styles.title}>{`${title[0]} ${type} ${title[1]}`}</div>
            <div className={styles.draft}>{draftText}</div>
        </div>
    );
};

PGDraft.propTypes = {
    staticData: PropTypes.object,
    type: PropTypes.string,
};

export default withDataService()(PGDraft);