import {createRequestHandler} from '../../../utils';

export const NEWS_DATA_REQUEST = 'NEWS_DATA_REQUEST';
export const NEWS_DATA_SUCCESS = 'NEWS_DATA_SUCCESS';
export const NEWS_DATA_FAILURE = 'NEWS_DATA_FAILURE';

export const NEWS_DATA_UPDATING_REQUEST = 'NEWS_DATA_UPDATING_REQUEST';
export const NEWS_DATA_UPDATING_SUCCESS = 'NEWS_DATA_UPDATING_SUCCESS';
export const NEWS_DATA_UPDATING_FAILURE = 'NEWS_DATA_UPDATING_FAILURE';

export const newsDataRequest = createRequestHandler('NEWS_DATA');
export const newsDataUpdatingRequest = createRequestHandler('NEWS_DATA_UPDATING');

const initialState = {
    list: [],
    options: null,
    loading: false,
    updating: false,
    error: null,
};

const data = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case NEWS_DATA_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case NEWS_DATA_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case NEWS_DATA_SUCCESS:
        case NEWS_DATA_UPDATING_SUCCESS:
            const {list, ...rest} = payload;
            return {
                list,
                options: {...rest},
                loading: false,
                updating: false,
                error: null,
            };

        case NEWS_DATA_FAILURE:
        case NEWS_DATA_UPDATING_FAILURE:
            return {
                ...initialState,
                error: payload,
            };

        default:
            return state;
    }
};

export default data;