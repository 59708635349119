import React from 'react';
import PropTypes from 'prop-types';

import ActionStepCard from '../../../common/action-step-card';

import styles from './about-marketplace-desktop.module.scss';

const AboutMarketplaceDesktop = ({pages, onTogglePage}) => {
    const labelsBlock = pages.map(({id, label, isActive}, idx) => {
        return (
            <div key={idx} className={styles.tab}>
                <input type="radio"
                       id={id}
                       checked={isActive}
                       onChange={onTogglePage}
                />
                <label htmlFor={id} className={styles.label}>
                    {label}
                </label>
            </div>
        );
    });

    const contentBlock = pages.map(({id, content, isActive}, idx) => {
        return isActive ? <ActionStepCard key={idx} actionList={content}/> : null;
    });

    return (
        <>
            <div className={styles['tabs-labels']}>
                {labelsBlock}
            </div>

            {contentBlock}
        </>
    );
};

AboutMarketplaceDesktop.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        isActive: PropTypes.bool,
    })),
    onToggleTab: PropTypes.func,
};

export default AboutMarketplaceDesktop;