import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';

import classnames from 'classnames/bind';
import styles from './logo.module.scss';

import logoBlack from './assets/logo_black.svg';
import logoWhite from './assets/logo_white.svg';

const Logo = ({staticData, color, classNames, href, onClick}) => {
    const {logo_alt} = staticData;

    const cx = classnames.bind(styles);
    const logoClasses = cx('logo', {[classNames]: !href});
    const logoLinkClasses = cx('logo-link', classNames);

    const logo = <img src={color === 'BLACK' ? logoBlack : logoWhite} alt={logo_alt} className={logoClasses}/>;

    return href ? <Link to={href} className={logoLinkClasses} onClick={onClick}>{logo}</Link> : logo;
};

Logo.defaultProps = {
    color: 'BLACK',
    onClick: () => {
    },
};

Logo.propTypes = {
    staticData: PropTypes.object,
    color: PropTypes.oneOf(['WHITE', 'BLACK']),
    classNames: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
};

export default withDataService()(Logo);