import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './status-indicator.module.scss';

const StatusIndicator = ({status, classNames}) => {
    if (!status) {
        return null;
    }

    const {label, color} = status;

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames);

    return (
        <div className={containerClasses}>
            <div style={{background: color}} className={styles['status-circle']}/>
            <div className={styles.text}>{label}</div>
        </div>
    );
};

StatusIndicator.propTypes = {
    status: PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string,
    }),
};

export default StatusIndicator;