import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../../../../../../common/tooltip';
import InfoMark from '../../../../../../../common/info-mark';

import classnames from 'classnames/bind';
import styles from './product-status.module.scss';

const ProductStatus = ({status, label, description, classNames}) => {
    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames);
    const infoClasses = cx('info', status);

    let infoStatus = null;
    switch (status) {
        case 'moderation':
            infoStatus = 'warning';
            break;
        case 'reject':
            infoStatus = 'error';
            break;
        default:
            infoStatus = null;
    }

    return (
        <div className={containerClasses}>
            {status !== 'approved' && status !== 'updated' && (
                <Tooltip tooltip={description}
                         contentClassNames={styles['info-content']}
                         placement={'top-start'}
                         minWidth={160}
                         maxWidth={300}>
                    <div className={infoClasses}>
                        <InfoMark status={infoStatus} classNames={styles['info-icon']}/>
                        {label}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

ProductStatus.propTypes = {
    status: PropTypes.oneOf(['approved', 'updated', 'moderation', 'reject']),
    label: PropTypes.string,
    description: PropTypes.string,
    classNames: PropTypes.string,
};

export default ProductStatus;