import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {withSideDropMenu} from '../../hoc';

import PartNumber from '../../part-number';
import FilterOptions from '../filter-desktop-options';
import Button from '../../button';

import classnames from 'classnames/bind';
import styles from './filters-mobile.module.scss';

const FiltersMobile = (props) => {
    const {staticData, options, inputTitle, toggleOptions, isUpdating, ...rest} = props;
    const [mobileOptions, setMobileOptions] = useState([]);

    const {
        platform_label,
        title_label,
        organization_label,
        button: {show_more, show_less},
    } = staticData.filters;

    useEffect(() => {
        const newMobileOptions = options.map((item) => {
            if (item.options.length > 4) {
                return {
                    ...item,
                    isShowMore: false,
                };
            }

            return item;
        });

        setMobileOptions(newMobileOptions);
    }, [options]);

    const toggleOptionsVisibility = (currentID) => {
        const newMobileOptions = mobileOptions.map((item) => {
            if (item.id === currentID) {
                return {
                    ...item,
                    isShowMore: !item.isShowMore,
                };
            }

            return item;
        });

        setMobileOptions(newMobileOptions);
    };

    const filterOptions = mobileOptions.map(({id, options, isShowMore}, idx) => {
        if (options.length !== 0) {
            const cx = classnames.bind(styles);
            const itemsClasses = cx('items', {
                'show-more-active': isShowMore,
            });

            const label = {
                title: title_label,
                platform: platform_label,
                organization: organization_label[0],
            };

            return (
                <div key={idx} className={styles.options}>
                    <div className={styles.title}>{label[id]}</div>
                    <div className={itemsClasses}>
                        <FilterOptions
                            filterID={id}
                            options={options}
                            isUpdating={isUpdating}
                            toggleOptions={toggleOptions}
                        />
                    </div>

                    {isShowMore !== undefined && (
                        <Button styleType={'SECONDARY'}
                                classNames={styles.btn}
                                onClick={() => toggleOptionsVisibility(id)}
                                isTransformText={false}
                        >
                            {!isShowMore ? show_more : show_less}
                        </Button>)}
                </div>
            );
        }

        return null;
    });

    return (
        <>
            <div className={`${styles.options} ${styles['input-block']}`}>
                <div className={styles.title}>{inputTitle}</div>
                <PartNumber
                    {...rest}
                    type="WITH_ICON"
                    id="filter-pnum-mobile"
                    labelClasses={styles['input-block']}
                />
            </div>
            {filterOptions}
        </>
    );
};

FiltersMobile.defaultProps = {
    toggleOptions: () => {
    },
};

FiltersMobile.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        options: PropTypes.array,
    })),
    inputTitle: PropTypes.string,
    toggleOptions: PropTypes.func,
    staticData: PropTypes.object,
};

export default withSideDropMenu()(FiltersMobile);