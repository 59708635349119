import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {getSelectedItemFromSelectList} from '../../../utils/form-field-builder/handlers';

import SelectOrInputField from '../selection-or-input-field/selection-or-input-field';
import Select from '../select';
import InputField from '../input-field';
import Checkbox from '../checkbox';
import RadioButton from '../radio-button';
import InputDate from '../input-date';
import SelectAutocomplete from '../select-autocomplete';
import TooltipCreator from '../tooltip-creator';
import Button from '../button';
import InputPhone from '../input-phone/input-phone';
import TextEditor from '../text-editor';
import Switcher from '../switcher';

import classnames from 'classnames/bind';
import styles from './form-field-constructor.module.scss';

const FormFieldConstructor = (props) => {
    const {
        field, selectAutoCompleteDisabled, selectAutoCompleteClearWhenFixedOptions, dataLoading, onClickSelectItem,
        onCheckboxChange, onSwitcherChange, onGroupCheckboxChange, onRadioButtonChange, onChangeSelectInputGroup,
        onSelectAutoCompleteOptionsChange, textEditorReadOnly, onTextEditorDataChange, onDateChange, onInputChange,
        onInputFocus, onButtonClick, onInputPhoneChange, onShowPassword,
    } = props;

    const cx = classnames.bind(styles);

    const [isSelectOpen, isSetSelectOpen] = useState(false);
    const {type, list, dependsFrom, isShown, ...rest} = field;

    if (!isShown && isShown !== undefined) {
        return null;
    }

    if (field.fields && Array.isArray(field.fields)) {
        return (
            <SelectOrInputField
                fields={field.fields}
                onChangeSelectInputGroup={onChangeSelectInputGroup}/>
        );
    }

    if (type === 'select') {
        const items = list.map(({label, value}, idx) => {
            let fieldValue = field.value;
            if (typeof value === 'number') {
                fieldValue = parseInt(field.value);
            }
            const className = cx({
                'item-in-list-active': value === fieldValue,
                'item-in-list-not-available': value === 'default',
            });

            return (
                <li key={idx}
                    className={className}
                    onClick={() => {
                        isSetSelectOpen(!isSelectOpen);
                        onClickSelectItem(field.name, {label, value});
                    }}>
                    {label}
                </li>
            );
        });

        const selectedItemLabel = getSelectedItemFromSelectList(list, field.value).label;
        const props = list.length > 10
            ? field.props ? [...field.props, 'search'] : ['search']
            : field.props || [];

        return (
            <div className={styles['select-container']}>
                <Select {...rest}
                        type={'SECONDARY'}
                        selectedItem={selectedItemLabel}
                        isOpenSelect={isSelectOpen}
                        props={props}
                        selectedItemClasses={styles['select-item']}
                        onToggleSelect={(isOpen) => isSetSelectOpen(isOpen)}
                        zIndex={2}
                        withLabel>
                    {items}
                </Select>
            </div>
        );
    }

    if (type === 'radio') {
        const groupReadOnly = field.props && field.props.includes('readonly');

        const radioButton = list.map((item, idx) => {
            let additionParams = {};
            if (field.tooltip && Array.isArray(field.tooltip)) {
                additionParams = {
                    tooltip: field.tooltip[idx],
                };
            }

            return <RadioButton key={idx}
                                {...item} {...additionParams}
                                name={field.name}
                                selectedValue={field.value}
                                readOnly={groupReadOnly}
                                onRadioChange={onRadioButtonChange}/>;
        });

        return (
            <>
                <div className={styles['radio-group-label']}>
                    {groupReadOnly && <div className={styles['hiding-container']}/>}
                    {field.label}
                    {field.props && field.props.includes('required') &&
                    <span className={styles['required-icon']}>*</span>}
                </div>
                <div className={styles['radio-group']}>
                    {radioButton}
                </div>
            </>
        );
    }

    if (type === 'checkbox') {
        const groupReadOnly = field.props && field.props.includes('readonly');

        if (!list) {
            return <Checkbox id={field.name}
                             label={field.label}
                             name={field.name}
                             value={field.value}
                             activeCheckboxList={field.value !== 0 ? [field.value] : []}
                             onChange={onCheckboxChange}/>;
        } else {
            const checkboxes = list.map(({value, label}, idx) => {
                const {currencyReadOnly} = field;
                let optionReadOnly = false;

                if (currencyReadOnly) {
                    const {value: currencyValue, label: currencyLabel} = currencyReadOnly;

                    if (currencyValue === value && currencyLabel === label) {
                        optionReadOnly = true;
                    }
                }

                return <Checkbox key={idx}
                                 id={`${field.name}:${value}`}
                                 label={label}
                                 name={field.name}
                                 value={value}
                                 activeCheckboxList={field.value}
                                 optionReadOnly={optionReadOnly}
                                 groupReadOnly={groupReadOnly}
                                 onChange={onGroupCheckboxChange}/>;
            });

            return (
                <>
                    <div className={styles['checkbox-group-label']}>
                        {groupReadOnly && <div className={styles['hiding-container']}/>}
                        {field.label}
                        {field.props && field.props.includes('required') &&
                        <span className={styles['required-icon']}>*</span>}
                        {field.tooltip && <TooltipCreator tooltip={field.tooltip} classNames={styles.tooltip}/>}
                    </div>
                    <div className={styles['checkbox-group']}>
                        {checkboxes}
                    </div>
                </>
            );
        }
    }

    if (type === 'switcher') {
        return <Switcher id={`${field.name}:${field.value}`}
                         label={field.label}
                         name={field.name}
                         value={field.value}
                         activeSwitcherList={field.value !== 0 ? [field.value] : []}
                         onChange={onSwitcherChange}/>;
    }

    if (type === 'textarea') {
        return <TextEditor {...rest}
                           containerClassNames={styles['textarea-block']}
                           textEditorReadOnly={textEditorReadOnly}
                           onTextEditorDataChange={onTextEditorDataChange}/>;
    }

    if (type === 'datepicker') {
        return <InputDate {...rest} onDateChange={onDateChange}/>;
    }

    if (type === 'select_autocomplete') {
        return <SelectAutocomplete {...rest}
                                   isClearWhenFixedOptions={selectAutoCompleteClearWhenFixedOptions}
                                   isDisabled={selectAutoCompleteDisabled || field.isDisabled}
                                   onSelectedOptionsChange={onSelectAutoCompleteOptionsChange}/>;
    }

    if (type === 'telephone') {
        return <InputPhone label={field.label}
                           name={field.name}
                           value={field.value}
                           props={field.props}
                           onInputPhoneChange={onInputPhoneChange}/>;
    }

    if (type === 'button') {
        return (
            <Button onClick={onButtonClick}
                    dataLoading={dataLoading}
                    isDisabled={!!field.value}>
                {field.label}
            </Button>
        );
    }

    if (type === 'hidden') {
        return null;
    }

    return <InputField type={type} {...rest}
                       onInputChange={onInputChange}
                       onShowPassword={onShowPassword}
                       onInputFocus={onInputFocus}/>;
};

FormFieldConstructor.defaultProps = {
    textEditorReadOnly: false,
    selectAutoCompleteDisabled: false,
    onGroupCheckboxChange: () => {
    },
    onRadioButtonChange: () => {
    },
    onChangeSelectInputGroup: () => {
    },
};

FormFieldConstructor.propTypes = {
    field: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({
        type: PropTypes.string,
        list: PropTypes.array,
        name: PropTypes.string,
        props: PropTypes.array,
        tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    })]),
    selectAutoCompleteDisabled: PropTypes.bool,
    selectAutoCompleteClearWhenFixedOptions: PropTypes.bool,
    dataLoading: PropTypes.bool,
    onClickSelectItem: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onSwitcherChange: PropTypes.func,
    onGroupCheckboxChange: PropTypes.func,
    onRadioButtonChange: PropTypes.func,
    textEditorReadOnly: PropTypes.bool,
    onTextEditorDataChange: PropTypes.func,
    onChangeSelectInputGroup: PropTypes.func,
    onSelectAutoCompleteOptionsChange: PropTypes.func,
    onButtonClick: PropTypes.func,
    onInputPhoneChange: PropTypes.func,
};

export default FormFieldConstructor;