import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../tooltip';
import InfoMark from '../info-mark';

import classnames from 'classnames/bind';
import styles from './tooltip-creator.module.scss';

const TooltipCreator = (props) => {
    const {
        tooltip: {
            title, text, classNamesTitle,
            classNamesText, classNamesContainer,
        },
        style, classNames, contentClassNames,
        minWidth, maxWidth, zIndex,
    } = props;
    const cx = classnames.bind(styles);
    const classes = cx('info', classNames);
    const contentClasses = cx('info-content', contentClassNames);

    return (
        <Tooltip tooltip={(
            <>
                {title && <div className={cx('info-title', classNamesTitle)}>{title}</div>}
                <div className={classNamesText}>{text}</div>
            </>
        )}
                 contentClassNames={cx(contentClasses, classNamesContainer)}
                 minWidth={minWidth}
                 maxWidth={maxWidth}
                 zIndex={zIndex}>
            <span style={style}
                  className={classes}
                  onClick={(e) => e.stopPropagation()}>
                <InfoMark classNames={styles['info-icon']}/>
            </span>
        </Tooltip>
    );
};

TooltipCreator.defaultProps = {
    minWidth: 400,
    maxWidth: 400,
    zIndex: 2,
};

TooltipCreator.propTypes = {
    title: PropTypes.string,
    text: PropTypes.any,
    classNamesTitle: PropTypes.string,
    classNamesText: PropTypes.string,
    classNamesContainer: PropTypes.string,
    style: PropTypes.object,
    classNames: PropTypes.string,
    contentClasses: PropTypes.string,
    contentClassNames: PropTypes.string,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    zIndex: PropTypes.number,
};

export default TooltipCreator;