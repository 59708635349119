import React from 'react';
import PropTypes from 'prop-types';

import FormFieldCreator from '../../../../common/handlers/form-field-creator';

import FormFieldConstructor from '../../../../../../common/form-field-constructor';
import Button from '../../../../../../common/button';

import classnames from 'classnames/bind';
import styles from './shipment-organization.module.scss';

const formFieldCreator = new FormFieldCreator();
const formFieldHandler = (formFields, listeners, pageType = 'create') => {
    const fields = formFieldCreator.getFormFields(formFields);

    return fields.map((field, idx) => {
        if (pageType === 'edit') {
            const {onSetDefault} = listeners;
            return <FormFieldConstructor {...listeners} key={idx} field={field}
                                         onButtonClick={() => onSetDefault(field.remote.url)}/>;
        }

        return <FormFieldConstructor {...listeners} key={idx} field={field}/>;
    });
};

const ShipmentOrganization = (props) => {
    const {
        staticData, pageType, list, actionLoading, setDefaultLoading,
        isDisabledFields, isSaveButtonDisabled, onClickSelectItem, onInputChange,
        onInputPhoneChange, onCheckboxChange, onCancel, onSubmit, onSetDefault,
    } = props;
    const {save, cancel} = staticData.activity_hub.profile.button;

    const cx = classnames.bind(styles);
    let sectionClasses = cx('grid-group', 'group-even-2', {
        'hiding-container': isDisabledFields,
    });
    const btnClasses = cx('grid-group', 'group-even-2', 'btn-block');

    const listeners = {
        dataLoading: setDefaultLoading,
        onClickSelectItem,
        onInputPhoneChange,
        onInputChange,
        onCheckboxChange,
        onSetDefault,
    };

    let formFields = [],
        checkboxBlock = [],
        firstSection = [],
        secondSection = [],
        btnBlock = [];

    if (list.length > 0) {
        formFields = formFieldHandler(list, listeners, pageType);
        checkboxBlock = formFields.slice(0, 1);
        firstSection = formFields.slice(1, 5);
        secondSection = formFields.slice(5, 12);

        if (pageType === 'edit') {
            btnBlock = formFields.slice(12);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className={styles.content}>
                <div className={styles['checkbox-group']}>{checkboxBlock}</div>
                <div className={sectionClasses}>{firstSection}</div>
                <hr className={styles.separator}/>
                <div className={sectionClasses}>{secondSection}</div>
                {btnBlock.length > 0 && (
                    <div className={btnClasses}
                         style={{
                             pointerEvents: actionLoading || setDefaultLoading ? 'none' : 'auto',
                         }}>
                        {btnBlock}
                    </div>
                )}
            </div>

            <div className={styles['btn-group']}
                 style={{
                     pointerEvents: actionLoading || setDefaultLoading ? 'none' : 'auto',
                 }}>
                <Button type={'submit'}
                        classNames={styles['save-btn']}
                        dataLoading={actionLoading}
                        isDisabled={isSaveButtonDisabled}>
                    {save}
                </Button>
                <Button styleType={'SECONDARY'}
                        classNames={styles['cancel-btn']}
                        onClick={onCancel}>
                    {cancel}
                </Button>
            </div>
        </form>
    );
};

ShipmentOrganization.defaultProps = {
    onClickSelectItem: () => {
    },
    onInputChange: () => {
    },
    onInputPhoneChange: () => {
    },
    onCheckboxChange: () => {
    },
    onCancel: () => {
    },
    onSubmit: () => {
    },
    onSetDefault: () => {
    },
};

ShipmentOrganization.propTypes = {
    staticData: PropTypes.object,
    pageType: PropTypes.string,
    list: PropTypes.array,
    actionLoading: PropTypes.bool,
    setDefaultLoading: PropTypes.bool,
    isDisabledFields: PropTypes.bool,
    isSaveButtonDisabled: PropTypes.bool,
    onClickSelectItem: PropTypes.func,
    onInputChange: PropTypes.func,
    onInputPhoneChange: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onSetDefault: PropTypes.func,
};

export default ShipmentOrganization;