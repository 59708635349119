import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';

import {withDataService} from '../../../../common/hoc';
import {withServices} from '../../../../service-context';
import {productsRequest, categoryProductsRequest} from '../../../../../reducers/marketplace/products';
import {searchAction} from '../../../../../actions/common';

import SearchPage from './search-page';

class SearchPageContainer extends Component {
    render() {
        return <SearchPage  {...this.props}/>;
    }
}

const mapServicesToProps = ({marketplaceService}) => ({
    fetchAllProducts: productsRequest(marketplaceService.getAllProducts),
    fetchCategoryProducts: categoryProductsRequest(marketplaceService.getCategoryProducts),
});

const mapStateToProps = ({profile: {language}, search: {slug}, marketplace: {products}}) => {
    return ({
        slug,
        products,
        language,
    });
};

const mapDispatchToProps = (dispatch, {fetchAllProducts, fetchCategoryProducts}) => {
    const {updateSearchCategorySlug} = searchAction;

    return bindActionCreators({
        fetchAllProducts,
        fetchCategoryProducts,
        updateSearchCategorySlug,
    }, dispatch);
};

export default compose(
    withDataService(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(SearchPageContainer);