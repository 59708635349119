import React from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {NOT_CAPITALIZE_LANGUAGES} from '../../services/data-service/constants';

import AboutMarketplaceDesktop from './components/about-marketplace-desktop';
import AboutMarketplaceMobile from './components/about-marketplace-mobile';
import RequestAccessZone from '../common/request-access-zone/request-access-zone';

import classnames from 'classnames/bind';
import styles from './about-marketplace.module.scss';

const AboutMarketplace = (props) => {
    const {staticData: {about_marketplace: {title}}, session, language} = props;
    const mdDevice = useMediaQuery({minWidth: 768});

    const cx = classnames.bind(styles);
    const notCapitalizeClass = cx({'not-capitalize-text': NOT_CAPITALIZE_LANGUAGES.includes(language)});

    return (
        <>
            <div className={styles.container}>
                <h1 className={notCapitalizeClass}>{title}</h1>

                {mdDevice
                    ? <AboutMarketplaceDesktop {...props}/>
                    : <AboutMarketplaceMobile {...props}/>}
            </div>
            {!session && <RequestAccessZone/>}
        </>
    );
};

AboutMarketplace.propTypes = {
    staticData: PropTypes.object,
    session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    language: PropTypes.string,
};

export default AboutMarketplace;