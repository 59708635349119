import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {marketplaceURLs} from '../../../../../../../../services/api-urls';

import Dropzone from '../../../../../../../common/dropzone';
import FileContainer from '../../../../../../../common/file-container/file-container';
import Button from '../../../../../../../common/button';
import UploadedFileInfo from '../../../../../../../common/uploaded-file-info';
import ExcelUploadFormOption from './excel-upload-form-option';

import styles from './excel-upload-page.module.scss';

const ExcelUploadPage = (props) => {
    const {
        staticData, formOptions, file, fileProgress, uploadXLSLoading,
        uploadXLSResponse, isFileLoaded, toQuotation, isResetFormOptions,
        onDeleteFile, onToggleExcelUploadFormOption, onSubmit, onCancel,
        onResetFormOptions, onClearAll, ...restProps
    } = props;

    const {
        excel_upload_form_text,
        follow_format_tip,
        attach_xls_label,
        button: {cancel, confirm_quotation, upload},
    } = staticData.marketplace.quotation.excel_upload_form;

    const optionsRow = formOptions && formOptions.map((option, idx) => (
        <ExcelUploadFormOption key={idx} option={option} isReset={isResetFormOptions}
                               onToggleOption={onToggleExcelUploadFormOption}
                               onReset={onResetFormOptions}/>
    ));

    return (
        <div className={styles.container}>
            <div className={styles.text}>{excel_upload_form_text}</div>

            <form className={styles['quotation-form']} onSubmit={(e) => onSubmit(e)}>
                <div className={styles.row}>
                    <div className={styles.label}>{attach_xls_label}</div>
                    <div className={styles['upload-container']}>
                        <div className={styles['dropzone-block']}>
                            <Dropzone {...restProps} className={styles.dropzone} isDisabled={file !== null}/>

                            <div className={styles.tip}>
                                {`${follow_format_tip[0]} `}
                                <Link to={marketplaceURLs.quotation_excel_format}
                                      className={styles.link}
                                >
                                    {follow_format_tip[1]}
                                </Link>
                            </div>
                        </div>

                        <div className={styles['progressbar-block']}>
                            {file && <FileContainer classNames={styles.progressbar}
                                                    file={file}
                                                    fileProgress={fileProgress.value}
                                                    isFileLoaded={isFileLoaded}
                                                    onDelete={onDeleteFile}/>}
                        </div>
                    </div>
                </div>

                <div className={styles['options-row']}>
                    {optionsRow}
                </div>

                <div className={styles.row}>
                    <UploadedFileInfo data={uploadXLSResponse.list}
                                      classNames={styles.info}
                                      onClear={onClearAll}/>
                </div>

                <div className={styles.row}>
                    <div className={styles['btn-group']}>
                        <Button styleType="SECONDARY" classNames={styles.cancel} onClick={onCancel}>{cancel}</Button>
                        <Button type={'submit'}
                                classNames={styles.proceed}
                                dataLoading={uploadXLSLoading}
                                isDisabled={!isFileLoaded}>
                            {toQuotation ? confirm_quotation : upload}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

ExcelUploadPage.propTypes = {
    staticData: PropTypes.object,
    formOptions: PropTypes.array,
    file: PropTypes.object,
    fileProgress: PropTypes.shape({
        value: PropTypes.number,
    }),
    uploadXLSLoading: PropTypes.bool,
    isFileLoaded: PropTypes.bool,
    toQuotation: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

export default ExcelUploadPage;