import {applyMiddleware, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import {createBrowserHistory} from 'history';
import rootReducer from './reducers';

export const history = createBrowserHistory();

/* next = dispatch (that means next dispatch in middleware chain) */
// const exampleMiddleware = (store) => (dispatch) => (action) => {
//     const {getState, dispatch} = store; // we can get only two functions from store
//     console.log(action.type, store.getState());
//     return dispatch(action);
// };

/* to the applyMiddleware function we MUST pass the function in the correct call order */
const store = createStore(
    rootReducer(history),
    composeWithDevTools(
        applyMiddleware(routerMiddleware(history), thunkMiddleware),
    ),
);

export default store;
