import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {marketplaceURLs} from '../../../services/api-urls';
import {withDataService} from '../hoc';
import {updatedUrlWithParams, resetAllUrlParams} from '../handlers';

import Sorting from './sorting';

class SortingContainer extends Component {
    state = {
        options: [],
    };

    componentDidMount() {
        if (this.props.sortingOptions) {
            this.updatedSortOptions();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sortingOptions !== this.props.sortingOptions) {
            if (this.props.sortingOptions) {
                this.updatedSortOptions();
            }
        }
    }

    updatedSortOptions = () => {
        const {session, location: {pathname}, sortingOptions} = this.props;
        let options = {};

        /* select categories for shown in the sorting block */
        for (let key in sortingOptions) {
            if (sortingOptions.hasOwnProperty(key)) {
                if (pathname.includes(marketplaceURLs.marketplace)) {
                    const keyExcluded = !session ? key !== 'date' && key !== 'price' : key !== 'date';

                    if (keyExcluded) {
                        options = {
                            ...options,
                            [key]: sortingOptions[key],
                        };
                    }

                } else {
                    options = {
                        ...options,
                        [key]: sortingOptions[key],
                    };
                }
            }
        }

        /* sort select categories by alphabet */
        let sortObjectByKey = {};
        Object.keys(options).sort().forEach(key => {
            sortObjectByKey = {
                ...sortObjectByKey,
                ...options[key],
            };
        });

        /* get value from object and add arrow icon if it needed */
        let optionsArray = [];
        for (let key in sortObjectByKey) {
            if (sortObjectByKey.hasOwnProperty(key)) {
                if (key.includes('desc')) {
                    optionsArray = [
                        ...optionsArray,
                        {...sortObjectByKey[key], arrow: 'down'},
                    ];

                } else if (key.includes('asc')) {
                    optionsArray = [
                        ...optionsArray,
                        {...sortObjectByKey[key], arrow: 'up'},
                    ];

                } else {
                    optionsArray = [
                        ...optionsArray,
                        sortObjectByKey[key],
                    ];
                }
            }
        }

        this.setState(({options: optionsArray}));
    };

    sortBy = (href) => {
        const {history, location} = this.props;
        const newHref = updatedUrlWithParams(location, href);

        history.push(newHref);
    };

    onResetAll = () => {
        const {history, location: {pathname, search}} = this.props;
        const newPath = resetAllUrlParams(search, pathname);

        history.push(newPath);
    };

    render() {
        const {
            staticData, updating, isSkeletonPreloader,
            isDisabled, screenChangeOn, onMobileFilterOpen,
        } = this.props;

        return (
            <Sorting
                {...this.state}
                staticData={staticData}
                isUpdating={updating}
                isSkeletonPreloader={isSkeletonPreloader}
                isDisabled={isDisabled}
                screenChangeOn={screenChangeOn}
                sortBy={this.sortBy}
                onReset={this.onResetAll}
                onMobileFilterOpen={onMobileFilterOpen}
            />
        );
    }

    static defaultProps = {
        isSkeletonPreloader: false,
    };

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        history: PropTypes.shape({
            push: PropTypes.func,
        }),
        pathname: PropTypes.string,
        search: PropTypes.string,
        sortingOptions: PropTypes.shape({
            condition: PropTypes.object,
            price: PropTypes.object,
        }),
        updating: PropTypes.bool,
        staticData: PropTypes.object,
        isSkeletonPreloader: PropTypes.bool,
        isDisabled: PropTypes.bool,
        onMobileFilterOpen: PropTypes.func,
    };
}

const mapStateToProps = ({session: {session}}) => ({
    session,
});

export default compose(
    withRouter,
    withDataService(),
    connect(mapStateToProps),
)(SortingContainer);