export const about_core_services_es = {
    title: 'Más sobre los servicios principales',
    subtitle: `En Exportery, ofrecemos a nuestros clientes características y servicios inigualables en 
    el ámbito del comercio industrial`,
    platform: {
        title: ['Características de la plataforma ', 'e-trade Exportery ™'],
        headings: ['Para el exportador', 'Para el importador'],
        list: [
            {
                text: `Afiliación GRATUITA a la plataforma de comercio electrónico, listado GRATUITO de las 
                piezas de recambio y los componentes y originación ilimitada de peticiones de oferta`,
                interaction: 'both',
            },
            {
                text: `Interfaz multilingüe y soporte del Servicio de Atención al Cliente EN-FR-ES-RU`,
                interaction: 'both',
            },
            {
                text: `Proceso avanzado de registro y verificación que elimina el riesgo de introducir miembros 
                fantasmas de los roles de importador o exportador`,
                interaction: 'both',
            },
            {
                text: `Precios por defecto según las condiciones comerciales de los Incoterms 2020 del aeropuerto 
                del país de origen de la FCA para todos los productos del mercado`,
                interaction: 'both',
            },
            {
                text: `Fijación de precios automática e instantánea basada en los Incoterms 2020 del aeropuerto 
                del país de destino para todos los importadores al crear una solicitud de presupuesto (RFQ) electrónica`,
                interaction: 'importer',
            },
            {
                text: `Listados de productos confidenciales para los exportadores que lo soliciten (ocultando el 
                nombre, el lugar de fabricación, etc.) en aras de la competencia leal y la seguridad`,
                interaction: 'exporter',
            },
            {
                text: `Registro del historial de peticiones de oferta con una validez asignada a cada cotización`,
                interaction: 'both',
            },
            {
                text: `Búsqueda por número de pieza, sistema de filtrado avanzado, identificación de plataformas 
                y agrupación de títulos por catálogos técnicos originales para facilitar la búsqueda`,
                interaction: 'both',
            },
            {
                text: `Ficha de producto detallada que incluye los principales datos técnicos, los recursos 
                asignados, los datos del fabricante, los datos de los centros de revisión, el TBO, las condiciones 
                de garantía y los parámetros de certificación`,
                interaction: 'both',
            },
            {
                text: `Recambios y componentes Función de búsqueda de intercambios y oferta de recambios 
                analógicos incorporados a los disponibles en el mercado según los boletines técnicos y la 
                documentación de un Diseñador de una plataforma concreta`,
                interaction: 'both',
            },
            {
                text: `Cómoda carga masiva (conversión automática) a partir de listas de archivos de Excel para 
                obtener al instante los precios de las peticiones de oferta (CIP) de múltiples recambios y 
                componentes en un minuto`,
                interaction: 'importer',
            },
            {
                text: `Colocación de productos manuales en la plataforma electrónica`,
                interaction: 'exporter',
            },
            {
                text: `Cómoda carga masiva de listados (conversión automática) desde listas de archivos Excel 
                para colocar ofertas masivas en la plataforma de comercio electrónico`,
                interaction: 'exporter',
            },
            {
                text: `Moderación manual de todos los datos en la plataforma de e-trade por parte de los especialistas 
                técnicos de alto calibre de Exportery, con un historial probado en aviación de transporte, helicópteros, 
                camiones pesados, etc., para garantizar que todos los datos están libres de entradas fantasma, listados 
                de rúbricas incorrectos y peticiones de oferta incorrectas`,
                interaction: 'both',
            },
            {
                text: `Fijación de precios y originación de contratos electrónicos y liquidaciones financieras 
                en varias divisas (USD, EUR, RUB, AED, CNY, CHF, RWF)`,
                interaction: 'both',
            },
        ],
    },
    document_management_system: {
        title: ['Servicios comerciales propios y ', 'características del sistema de gestión de documentos electrónicos'],
        headings: ['Para el exportador', 'Para el importador'],
        list: [
            {
                text: `Servicio holístico, toda la asunción de riesgos, incluidos los de fluctuación de divisas, 
                y la responsabilidad última de la mercancía consignada desde la recogida en origen FCA hasta la 
                entrega final en destino CIP`,
                interaction: 'both',
            },
            {
                text: `Mapa de contratos ™ sistema intuitivo de gestión de contratos`,
                interaction: 'both',
            },
            {
                text: `Originación, presentación y firma electrónica del Certificado de Usuario Final 
                del Operador Aéreo, Operadores de Flotas o Entidades Gubernamentales Importadoras`,
                interaction: 'importer',
            },
            {
                text: `Garantía de funcionamiento de SWIFT para usuarios finales de la Administración para 
                contratos electrónicos que superen los 250.000 USD`,
                interaction: 'importer',
            },
            {
                text: `Garantía comercial del exportador SWIFT para los envíos que superen los 100 000 USD, previa solicitud`,
                interaction: 'exporter',
            },
            {
                text: `Cuenta de custodia para el importador con sólo el 25% del depósito de garantía del 
                valor total del contrato electrónico en moneda local`,
                interaction: 'importer',
            },
            {
                text: `Establecimiento de un contrato electrónico que contiene las condiciones generales del 
                contrato, las condiciones especiales del contrato y un pliego de condiciones`,
                interaction: 'both',
            },
            {
                text: `Almacenamiento en custodia en nuestro primer punto de servicio de tránsito en Dar Es Salaam, 
                Tanzania, para la seguridad comercial de los importadores y exportadores`,
                interaction: 'both',
            },
            {
                text: `Inspección técnica independiente y registro fotográfico de todos los envíos y establecimiento 
                de un informe de inspección de la cantidad, la calidad y los parámetros de autentificación de acuerdo 
                con numerosas normas, por ejemplo, ISO, GOST, MAK, EASA y otras, garantizando la seguridad operativa 
                tanto para el importador como para el exportador`,
                interaction: 'both',
            },
            {
                text: `Establecimiento automático de la factura electrónica y de la lista intuitiva de embalaje electrónico 
                con un mecanismo integrado para operar con piezas de repuesto y componentes múltiples, individuales y a 
                granel, según los diferentes escenarios, minimizando el factor de error humano`,
                interaction: 'exporter',
            },
            {
                text: `Función de división de lotes de envío`,
                interaction: 'exporter',
            },
            {
                text: `Firma electrónica automática de DocuSign para todos los documentos`,
                interaction: 'both',
            },
            {
                text: `E-AWB para todos los envíos`,
                interaction: 'both',
            },
            {
                text: `Etiquetado automático para envíos de exportación`,
                interaction: 'exporter',
            },
            {
                text: `Establecimiento de un Acto Trilateral de Entrega y Recepción entre la plataforma de 
                Exportación (Agente), el Exportador y el Importador al finalizar el Informe de Inspección para la 
                liberación de la mercancía y las liquidaciones finales`,
                interaction: 'both',
            },
            {
                text: `Sistema avanzado de cumplimiento de las fases del contrato y seguimiento de los envíos`,
                interaction: 'both',
            },
            {
                text: `Sistemas avanzados de notificación de eventos`,
                interaction: 'both',
            },
            {
                text: `Gestor de contenidos dedicado al exportador`,
                interaction: 'exporter',
            },
            {
                text: `Gestor de Ejecución de Contratos dedicado al exportador y al importador`,
                interaction: 'both',
            },
            {
                text: `Servicio de reclamaciones y devoluciones en garantía`,
                interaction: 'importer',
            },
        ],
    },
};