const arrayOfItemsHandler = (arrayOfItems, splitRatio) => {
    let updatedArrayOfItems = [];
    let items = [];

    for (let i = 0; i < arrayOfItems.length; i++) {
        if (i % splitRatio === 0 && i !== 0) {
            updatedArrayOfItems = [...updatedArrayOfItems, items];

            // clear and create new items
            items = [];
            items.push(arrayOfItems[i]);
        } else {
            items.push(arrayOfItems[i]);
        }

        if (i === arrayOfItems.length - 1) {
            updatedArrayOfItems = [...updatedArrayOfItems, items];
            items = [];
        }
    }

    return updatedArrayOfItems;
};

export default arrayOfItemsHandler;