import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withServices} from '../service-context';
import {newsDataRequest, newsDataUpdatingRequest} from '../../reducers/public-data/news/data';
import {updatedPaginationParams} from '../common/hoc/with-pagination-products-page/pagination-handler';

import News from './news';

class NewsContainer extends Component {
    componentDidMount() {
        if (this.props.session !== null) {
            this.props.fetchNews();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.onUpdatingNewsData();
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.onUpdatingNewsData();
        }
    }

    onUpdatingNewsData = () => {
        const {location: {search: params}} = this.props;
        this.props.fetchNewsUpdating(params);
    };

    onSwitchPage = (page) => {
        const newUrl = updatedPaginationParams(this.props.location, page);
        this.props.history.push(newUrl);
    };

    render() {
        const loading = this.props.loading || this.props.updating;
        return <News {...this.props.options}
                     loading={loading}
                     list={this.props.list}
                     onSwitchPage={this.onSwitchPage}
                     withPagination/>;
    }

    static propTypes = {
        language: PropTypes.string,
        location: PropTypes.object,
        history: PropTypes.object,
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        list: PropTypes.array,
        options: PropTypes.object,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        fetchNews: PropTypes.func,
        fetchNewsUpdating: PropTypes.func,
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchNews: newsDataRequest(publicService.getNewsData),
    fetchNewsUpdating: newsDataUpdatingRequest(publicService.getNewsData),
});

const mapStateToProps = ({session: {session}, profile, publicData: {homePage, news: {data}}}) => {
    const {list, loading, updating, options} = data;
    return {
        list,
        options,
        loading,
        updating,
        session,
        language: session ? profile.language : homePage.language,
    };
};

const mapDispatchToProps = (dispatch, {fetchNews, fetchNewsUpdating}) => {
    return bindActionCreators({fetchNews, fetchNewsUpdating}, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(NewsContainer);