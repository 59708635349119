import React, {Component} from 'react';

import {textHandler} from '../../utils/text-handler';
import {withDataService, withScrollToTop} from '../common/hoc';

import classnames from 'classnames/bind';
import styles from './terms-and-conditions.module.scss';

const EnhancedComponent = withScrollToTop()(({content}) => content.map((item, idx) => {
    let {title, text, items_ol} = item;

    if (items_ol) {
        items_ol = (
            <ol className={styles['ordered-list']}>
                {items_ol.map((item, item_ol_idx) => (
                    <React.Fragment key={item_ol_idx}>
                        {item.title && <h4 className={styles.subtitle}>
                            {`${idx + 1}.${item_ol_idx + 1}`} {item.title}
                        </h4>}
                        {item.title
                            ? <div key={idx} className={styles.paragraph}>{textHandler(item.text, styles)}</div>
                            : (
                                <li className={styles['without-title']}>
                                    <span>
                                        {`${idx + 1}.${item_ol_idx + 1}`}
                                    </span>
                                    <span key={idx} className={styles.paragraph}>{textHandler(item.text, styles)}</span>
                                </li>
                            )}
                    </React.Fragment>
                ))}
            </ol>
        );
    }

    return (
        <div key={idx} id={`chapter_${idx + 1}`}>
            <h2 className={styles.title}>{idx + 1}. {title}</h2>
            <div key={idx} className={styles.paragraph}>{textHandler(text, styles)}</div>
            {items_ol}
        </div>
    );
}));

class TermsAndConditions extends Component {
    state = {
        menu: [],
        content: [],
        sideMenuHeight: 0,
        mobileMenuIsOpen: false,
    };

    sideMenuButtonRef = React.createRef();

    componentDidMount() {
        this.onResize();

        const {side_menu, content} = this.props.staticData.data;
        const menu = side_menu.map((item, idx) => ({
            id: `chapter_${idx + 1}`,
            label: item,
            active: idx === 0,
        }));
        this.setState({menu, content});

        window.addEventListener('resize', this.onResize);
    }

    setFirstItemInMenuActive = () => {
        const menu = this.state.menu.map((item, idx) => ({
            ...item,
            active: idx === 0,
        }));
        this.setState({menu});
    };

    onResize = () => {
        const height = window.innerHeight;
        const sideMenuHeight = height - 28 * height / 100;

        this.setFirstItemInMenuActive();
        this.setState({sideMenuHeight, mobileMenuIsOpen: false});

        window.scrollTo({top: 0});
    };

    onMenuLinkClick = (id) => {
        const menu = this.state.menu.map(item => ({
            ...item,
            active: item.id === id,
        }));

        if (id === 'chapter_1') {
            window.scrollTo({top: 0});
        } else {
            const element = document.getElementById(id);
            if (element) {
                const elementTop = element.getBoundingClientRect().top;

                if (window.innerWidth < 1200) {
                    const sideMenuButtonHeight = this.sideMenuButtonRef.current.offsetHeight;
                    let top = elementTop - sideMenuButtonHeight;

                    if (window.scrollY > 0) {
                        top = window.scrollY + elementTop - sideMenuButtonHeight;
                    }

                    window.scrollTo({top});

                } else {
                    element.scrollIntoView();
                }
            }
        }

        if (this.state.mobileMenuIsOpen) {
            this.onToggleMobileMenu();
        }

        this.setState({menu});
    };

    onToggleMobileMenu = () => {
        this.setState(({mobileMenuIsOpen}) => ({
            mobileMenuIsOpen: !mobileMenuIsOpen,
        }));
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    render() {
        const cx = classnames.bind(styles);
        const {menu, content} = this.state;
        const {staticData: {nav_menu}} = this.props;

        if (menu.length > 0 && content.length > 0) {
            const sideMenuItems = (
                <ol className={styles['ordered-list']}>
                    {menu.map((item, idx) => (
                        <li key={idx}
                            className={cx('item', {'active': item.active})}
                            onClick={() => this.onMenuLinkClick(item.id)}>
                            {item.label}
                        </li>
                    ))}
                </ol>
            );

            const contentBlock = (
                <EnhancedComponent
                    classNames={styles['scroll-to-top-arrow']}
                    content={content}
                    scrollMode={'fast'}
                    userMode
                    allScreen
                    onCallback={this.setFirstItemInMenuActive}/>
            );

            const mobileMenuControllerClasses = cx('side-menu-mobile-controller');
            const mobileMenuClasses = cx('side-menu-mobile', {'open': this.state.mobileMenuIsOpen});

            return (
                <div className={cx('container', 'page-container', {'hidden': this.state.mobileMenuIsOpen})}>
                    <div ref={this.sideMenuButtonRef}
                         className={mobileMenuControllerClasses}
                         onClick={this.onToggleMobileMenu}
                    >
                        <span className={styles.btn}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                    d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32
                                    32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7
                                    0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" fill="#776755"/>
                            </svg>
                        </span>
                        <span>{nav_menu}</span>
                    </div>
                    <nav className={mobileMenuClasses}>
                        <div className={styles['close-btn']} onClick={this.onToggleMobileMenu}/>
                        {sideMenuItems}
                    </nav>
                    <nav className={styles['side-menu']}>
                        <div className={styles.container} style={{maxHeight: `${this.state.sideMenuHeight}px`}}>
                            {sideMenuItems}
                        </div>
                    </nav>
                    <div className={styles.content}>{contentBlock}</div>
                </div>
            );
        }

        return null;
    }
}

export default withDataService('terms_and_conditions')(TermsAndConditions);