import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../common/hoc';
import {utilsAction} from '../../actions/common';

import Button from '../common/button';

import styles from './cookie-consent.module.scss';

const CookieConsent = ({staticData}) => {
    const {title, text, buttons: {reject_all, accept_all}} = staticData.cookie_consent;
    const cookieConsentKey = useSelector(({utils: {cookieConsent: {key: cookieConsentKey}}}) => cookieConsentKey);
    const dispatch = useDispatch();
    const ref = useRef();
    let timer = null;

    useEffect(() => {
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    });

    const cookiesHandler = () => {
        const now = new Date();
        const expires = new Date(new Date().setMonth(now.getMonth() + 1));

        const dateNow = now.toISOString();
        const expiresDate = expires.toUTCString();

        const obj = {
            [cookieConsentKey]: dateNow,
            samesite: 'lax',
            expires: expiresDate,
        };

        let str = '';
        for (let item in obj) {
            const value = obj[item];
            str += `${item}=${value};`;
        }

        document.cookie = str;
    };

    const onButtonClick = () => {
        cookiesHandler();

        if (ref.current) {
            if (timer !== null) {
                clearTimeout(timer);
            }

            ref.current.classList.remove(`${styles.shown}`);

            timer = setTimeout(() => {
                dispatch(utilsAction.setCookieConsent());
            }, 800);
        }
    };

    return (
        <div ref={ref} className={`${styles.container} ${styles.shown}`}>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
            <div className={styles['btn-block']}>
                <Button styleType={'SECONDARY'} classNames={styles.btn} onClick={onButtonClick}>{reject_all}</Button>
                <Button classNames={styles.btn} onClick={onButtonClick}>{accept_all}</Button>
            </div>
        </div>
    );
};

CookieConsent.propTypes = {
    staticData: PropTypes.object,
};

export default withDataService()(CookieConsent);