import {createRequestHandler} from '../utils';

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const OPEN_SIGN_UP = 'OPEN_SIGN_UP';
export const CLOSE_SIGN_UP = 'CLOSE_SIGN_UP';

export const registrationRequest = createRequestHandler('REGISTRATION');

const initialState = {
    loading: false,
    message: null,
    error: null,
    signUpActive: false,
};

const registration = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case REGISTRATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                message: payload.message,
            };

        case REGISTRATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        case OPEN_SIGN_UP:
            return {
                ...state,
                signUpActive: true,
            };

        case CLOSE_SIGN_UP:
            return initialState;

        default:
            return state;
    }
};

export default registration;