import React from 'react';

import AuthorizationModule from './module';

const AuthorizationContainer = () => {
    return  <AuthorizationModule/>;
};

export {
    AuthorizationContainer as Authorization,
};