import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './preloader.module.scss';

const Preloader = ({type, classNames}) => {
    const cx = classnames.bind(styles);
    const classes = cx('lds-spinner', classNames, {
        'primary': type === 'PRIMARY',
        'secondary': type === 'SECONDARY'
    });

    const numberOfPetals = 12;
    let petals = [];

    for (let i = 0; i < numberOfPetals; i++) {
        petals.push(<div key={i}/>);
    }

    return <div className={classes}>{petals}</div>;
};

Preloader.defaultProps = {
    type: 'PRIMARY',
};

Preloader.propTypes = {
    type: PropTypes.oneOf(['PRIMARY', 'SECONDARY']),
    classNames: PropTypes.string,
};

export default Preloader;