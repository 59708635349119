import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import PropTypes from 'prop-types';

import {withServices} from '../../../../service-context';
import {withDataService} from '../../../../common/hoc';
import {
    resetRequestOnButtonUrl,
    resetRemoveProductOffer,
    switchMenuActiveLinkID,
} from '../../../../../actions/activity-hub';
import {productsListRequest, updatingProductsListRequest} from '../../../../../reducers/activity-hub/my-products';
import {onButtonUrlRequest} from '../../../../../reducers/activity-hub/request-on-button-url';
import {onRemoveProductOfferRequest} from '../../../../../reducers/activity-hub/remove-product-offer';
import {toggleMyProductsFilter} from '../../../../../actions/activity-hub';
import {withHubSideMenu} from '../../common/hoc';
import {onGetAlertResponse} from '../../../../../utils';
import sweetAlert from '../../../../common/sweet-alert';

import MyProducts from './my-products';

class MyProductsContainer extends Component {
    state = {
        prevProducts: [],
        isLanguageUpdating: false,
        fileDownloading: false,
        offerDeleting: null,
        canResetPnumFilter: false,
    };

    componentDidMount() {
        const {
            pageID, menuActiveLinkID, switchMenuActiveLinkID,
            location: {search: params},
        } = this.props;

        if (params.includes('pnum')) {
            this.setState({canResetPnumFilter: true});
        }

        if (menuActiveLinkID !== pageID) {
            switchMenuActiveLinkID(pageID);
        }

        this.props.fetchProducts(params);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {location: {search: params}} = this.props;
            this.props.fetchUpdatingProducts(params);
            this.setState({isLanguageUpdating: true});
        }

        if (prevProps.location.search !== this.props.location.search) {
            const {location: {search: params}} = this.props;

            if (params.includes('pnum')) {
                this.setState({canResetPnumFilter: true});

            } else {
                if (this.state.canResetPnumFilter) {
                    this.setState({canResetPnumFilter: false});
                }
            }

            this.props.fetchUpdatingProducts(params);
        }

        if (prevProps.products.updating !== this.props.products.updating) {
            if (prevProps.products.updating) {
                if (this.state.isLanguageUpdating) {
                    this.setState({isLanguageUpdating: false});
                }

                /* this case will  execution after got list of products where product offer was removed */
                if (this.state.offerDeleting) {
                    const {
                        location: {pathname, search: params},
                        products: {products},
                        removeOfferResp: {message: {status, text}}
                    } = this.props;

                    const sweetAlertHandler = () => {
                        sweetAlert(status, text).then(() => {
                            this.setState({offerDeleting: null, prevProducts: []});
                            this.props.resetRemoveProductOffer();
                        });
                    }

                    if (products.length === 0 && params) {
                        if (params.includes('page=')) {
                            const regExpPageString = /page=\d+/i;
                            const regExpNumber = /\d+/;
                            const pageStringResult = params.match(regExpPageString);
                            const numberResult = pageStringResult[0].match(regExpNumber);

                            if (numberResult) {
                                const page = parseInt(numberResult[0]);

                                if (page > 1) {
                                    const updatedParams = params.replace(pageStringResult[0], `page=${page - 1}`);
                                    this.props.history.replace(`${pathname}${updatedParams}`);
                                }
                            }

                        } else {
                            this.props.history.replace(pathname);
                        }

                    } else {
                        sweetAlertHandler();
                    }
                }
            }
        }

        if (prevProps.downloadExcelFileResp !== this.props.downloadExcelFileResp) {
            const {message, error} = this.props.downloadExcelFileResp;
            if (message) {
                const {url, label = 'my_products_excel'} = message;
                this.fetchExcelFile(url, label);
            }

            if (error) {
                const {status, text} = error;
                sweetAlert(status, text).then(() => {
                    this.setState({fileDownloading: false});
                    this.props.resetRequestOnButtonUrl();
                });
            }
        }

        if (prevProps.removeOfferResp !== this.props.removeOfferResp) {
            const {status, text} = onGetAlertResponse(this.props.removeOfferResp);

            if (status !== '' && text !== '') {
                if (status === 'success') {
                    /* success status reset offerDeleting after products list updating */
                    const {products: {products}, location: {search: params}} = this.props;
                    this.setState({prevProducts: products});
                    this.props.fetchUpdatingProducts(params);

                } else {
                    sweetAlert(status, text).then(() => {
                        this.setState({offerDeleting: null});
                        this.props.resetRemoveProductOffer();
                    });
                }
            }
        }
    }

    async fetchExcelFile(url, label) {
        const file = await fetch(url);
        const fileBlob = await file.blob();
        const fileURL = URL.createObjectURL(fileBlob);

        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = label;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(fileURL);

        this.setState({fileDownloading: false});
        this.props.resetRequestOnButtonUrl();
    }

    onDownloadExcelFile = ({method, url}) => {
        const {fileDownloading, offerDeleting} = this.state;
        if (!fileDownloading && !offerDeleting) {
            if (method === 'POST') {
                this.props.onDownloadFileFormServer(url);
                this.setState({fileDownloading: true});
            }
        }
    };

    onRemoveProductOffer = (url, id) => {
        const {fileDownloading, offerDeleting} = this.state;
        if (!fileDownloading && !offerDeleting) {
            this.props.onRemoveProductOfferOnServer(url);
            this.setState({offerDeleting: id});
        }
    };

    render() {
        const {products, ...rest} = this.props;

        return <MyProducts {...rest}
                           {...this.state}
                           productPage={products}
                           onDownloadExcelFile={this.onDownloadExcelFile}
                           onRemoveProductOffer={this.onRemoveProductOffer}/>;
    }

    static propTypes = {
        language: PropTypes.string,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        products: PropTypes.object,
        downloadExcelFileResp: PropTypes.object,
        removeOfferResp: PropTypes.object,
        switchMenuActiveLinkID: PropTypes.func,
        fetchProducts: PropTypes.func,
        fetchUpdatingProducts: PropTypes.func,
        onDownloadFileFormServer: PropTypes.func,
        onRemoveProductOfferOnServer: PropTypes.func,
        resetRequestOnButtonUrl: PropTypes.func,
        resetRemoveProductOffer: PropTypes.func,
    };
}

const mapServicesToProps = ({myProductsService, activityHubService}) => ({
    fetchProducts: productsListRequest(myProductsService.getMyProducts),
    fetchUpdatingProducts: updatingProductsListRequest(myProductsService.getMyProducts),
    onDownloadFileFormServer: onButtonUrlRequest(activityHubService.sendPOSTRequestOnButtonUrl),
    onRemoveProductOfferOnServer: onRemoveProductOfferRequest(activityHubService.sendPOSTRequestOnButtonUrl),
});

const mapStateToProps = ({profile, activityHub: {myProducts, removeProductOffer, requestOnButtonUrl, utils}}) => ({
    menuActiveLinkID: utils.menuActiveLinkID,
    language: profile.language,
    products: myProducts,
    downloadExcelFileResp: requestOnButtonUrl,
    removeOfferResp: removeProductOffer,
});

const mapDispatchToProps = (dispatch, {
    fetchProducts,
    fetchUpdatingProducts,
    onDownloadFileFormServer,
    onRemoveProductOfferOnServer,
}) => {
    return bindActionCreators({
        switchMenuActiveLinkID,
        toggleMyProductsFilter,
        fetchProducts,
        fetchUpdatingProducts,
        onDownloadFileFormServer,
        onRemoveProductOfferOnServer,
        resetRequestOnButtonUrl,
        resetRemoveProductOffer,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(MyProductsContainer);