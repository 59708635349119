export default class PublicService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/pub';
    }

    registration = (body) => {
        return this._http.post(`${this.baseUrl}/registration`, body);
    };

    invitation = (id, body) => {
        const url = `${this.baseUrl}/invitation/${id}`;
        if (body) {
            return this._http.post(url, body);
        }

        return this._http.get(url);
    };

    resetPasswordRequest = (body) => {
        return this._http.post(`${this.baseUrl}/password/restore`, body);
    };

    resetPassword = (id, body) => {
        const url = `${this.baseUrl}/restore/${id}`;
        if (body) {
            return this._http.post(url, body);
        }

        return this._http.get(url);
    };

    getPublicData = () => {
        return this._http.get(`${this.baseUrl}/common`);
    };

    getNewsData = (query) => {
        return this._http.get(`${this.baseUrl}/news/list`, query);
    };

    getArticleDetails = (id) => {
        return this._http.get(`${this.baseUrl}/article/${id}/detail`);
    };

    getPublicProducts = (query) => {
        return this._http.get(`${this.baseUrl}/products/list`, query);
    };

    getPublicCategories = () => {
        return this._http.get(`${this.baseUrl}/categories/list`);
    };

    getPublicCategoryProducts = (slug, query) => {
        return this._http.get(`${this.baseUrl}/products/${slug}/list`, query);
    };

    getPublicProductDetails = (slug, id) => {
        return this._http.get(`${this.baseUrl}/product/${slug}/${id}/detail`);
    };

    getSearchData = (slug, query) => {
        if (slug) {
            return this._http.get(`${this.baseUrl}/search/${slug}`, query);
        }

        return this._http.get(`${this.baseUrl}/search`, query);
    };
}