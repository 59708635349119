import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import {errorURLs} from '../../../../../services/api-urls';

import ProductsContainer from '../../common/products-container/products-container';

class CategoryPage extends Component {
    componentDidMount() {
        if (this.props.categories.length > 0) {
            this.initCategoryProducts();
        } else {
            this.initCategories();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.resetMarketplaceProducts();
            this.initCategories();
        }

        if (prevProps.categories !== this.props.categories) {
            this.initCategoryProducts();
        }

        if (prevProps.location.search !== this.props.location.search) {
            const {match: {params: {category}}, location: {search: params}} = this.props;
            this.props.fetchUpdatingProducts(category, params);
        }

        if (prevProps.products.updating !== this.props.products.updating) {
            // need work
            const {updating} = this.props.products;
            document.body.style.pointerEvents = updating ? 'none' : '';
        }
    }

    /* when first render app start from category page or change language */
    initCategories = () => {
        this.props.fetchCategories();
    };

    initCategoryProducts = () => {
        const {match: {params: {category}}, location: {search: params}} = this.props;
        const categoryIdx = this.props.categories.findIndex(({slug}) => slug === category);
        const error = categoryIdx === -1;

        if (!error) {
            this.props.updateSearchCategorySlug({id: category});
            this.props.fetchCategoryProducts(category, params);
        }
    };

    componentWillUnmount() {
        const {products: list} = this.props.products;

        if (list.length > 0) {
            this.props.resetMarketplaceProducts();
        }
    }

    render() {
        const {match: {params: {category}}, history, location, products, categories} = this.props;
        let title = '';
        if (categories.length !== 0) {
            const categoryIdx = categories.findIndex(({slug}) => slug === category);
            if (categoryIdx === -1) {
                return <Redirect to={errorURLs.not_found}/>;
            } else {
                title = categories[categoryIdx].name;
            }
        }

        return (
            <ProductsContainer
                productPage={products}
                history={history}
                location={location}
                pageTitle={title}/>
        );
    }

    static propTypes = {
        language: PropTypes.string,
        category: PropTypes.array,
        products: PropTypes.object,
        match: PropTypes.shape({params: PropTypes.shape({category: PropTypes.string})}),
        history: PropTypes.object,
        location: PropTypes.shape({
            search: PropTypes.string,
        }),
        fetchCategories: PropTypes.func,
        fetchCategoryProducts: PropTypes.func,
        fetchUpdatingProducts: PropTypes.func,
        updateSearchCategorySlug: PropTypes.func,
        resetMarketplaceProducts: PropTypes.func,
    };
}

export default CategoryPage;