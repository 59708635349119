import React from 'react';
import PropTypes from 'prop-types';

import {priceHandler} from '../../../../../../../../../utils';

import StatusIndicator from '../../../../../../common/status-indicator';

import classnames from 'classnames/bind';
import styles from './e-contract.module.scss';

const EContract = ({classNames, number, row}) => {
    const cx = classnames.bind(styles);
    const rowData = row.map(({label, value, color, currency}, idx) => {
        if (currency) {
            return <div key={idx} className={styles.column}>{priceHandler(value)} {currency}</div>;
        }

        if (idx === row.length - 1) {
            return <StatusIndicator key={idx}
                                    status={{label, color}}
                                    classNames={cx('column', 'status')}/>;
        }

        return <div key={idx} className={styles.column}>{value}</div>;
    });

    return (
        <div className={cx('container', classNames)}>
            <div className={styles.column}>{number}</div>
            {rowData}
        </div>
    );
};

EContract.propTypes = {
    classNames: PropTypes.string,
    number: PropTypes.string,
    row: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
        currency: PropTypes.string,
    })),
};

export default EContract;