export const about_core_services_ru = {
    title: 'Подробнее об основных услугах',
    subtitle: `Exportery предлагает своим клиентам непревзойденные решения, которые меняют правила 
    игры на рынке торговли промышленных товаров`,
    platform: {
        title: 'Exportery ™ решения и услуги',
        headings: ['Для експортера', 'Для импортера'],
        list: [
            {
                text: `Бесплатный доступ к платформе электронной коммерции, 
                бесплатная подача заявок и котировка цен на все товары`,
                interaction: 'both',
            },
            {
                text: `Мультиязычный интерфейс и мультиязычная поддержка менеджерами 
                по работе с клиентами на следующих языках: EN-FR-ES-RU`,
                interaction: 'both',
            },
            {
                text: `Продвинутый процесс регистрации и валидации участников платформы 
                для устранения возможных рисков запуска на платформу несуществующих лиц 
                или недействующих структур, как для роли Импортера, так и для Экспортера`,
                interaction: 'both',
            },
            {
                text: `Котирование всех ценовых предложений на запасные части и компоненты 
                на условиях Инкотермс 2020 FCA Аэропорт страны Экспортера продукции`,
                interaction: 'both',
            },
            {
                text: `Автоматический просчет стоимости на все запасные части и компоненты после 
                создания электронной заявки для Импортеров на условиях Инкотермс 2020 CIP Аэропорт пункта назначения`,
                interaction: 'importer',
            },
            {
                text: `Режим конфиденциальности информации и котировок цен для Экспортеров 
                (Скрытие названия, локации производственных мощностей, итд)`,
                interaction: 'exporter',
            },
            {
                text: `Электронный реестр заявок и котировок с валидностью предложения`,
                interaction: 'both',
            },
            {
                text: `Поиск запчастей и компонентов по чертежному (децимальному) номеру, 
                продвинутая система фильтеринга информации, идентификации платформ, поиска 
                технических разделов согласно техническим каталогам разработчика изделия`,
                interaction: 'both',
            },
            {
                text: `Детальная карточка товара, включающая технические параметры, назначенные ресурсы 
                изделий, данные о производителе, ремонтном предприятии и сертификациях и др.`,
                interaction: 'both',
            },
            {
                text: `Информация о взаимозаменяемости запасных частей и компонентов на существующие 
                аналоги согласно рекомендациям и технической документации разработчиков`,
                interaction: 'both',
            },
            {
                text: `Удобная массовая выгрузка (Автоматическая конверсия) файлов Excel содержащих 
                заявку на большое количество изделий для получения моментального ценового предложения 
                на включающего доставку (CIP)`,
                interaction: 'importer',
            },
            {
                text: `Ручное размещение товаров на электронной площадке`,
                interaction: 'exporter',
            },
            {
                text: `Удобная массовая выгрузка (Автоматическая конверсия) файлов Excel содержащих 
                заявку на размещение большого количество изделий на площадке электронной торговли`,
                interaction: 'exporter',
            },
            {
                text: `Ручная модерация всех вводимых данных на торговую платформу высококвалифицированными 
                инженерами и специалистами для разных разделов, таких как Транспортная авиация, вертолеты, 
                наземная техника для предотвращения некорректных заявок и котировок`,
                interaction: 'both',
            },
            {
                text: `Котировка цен и возможность расчетов по Контрактам в разных 
                мировых валютах (USD, EUR, RUB, AED, CNY, CHF, RWF)`,
                interaction: 'both',
            },
        ],
    },
    document_management_system: {
        title: 'Предлагаемые торговые сервисы и функции системы международного электронного документооборота',
        headings: ['Для експортера', 'Для импортера'],
        list: [
            {
                text: `Комплексный сервис, включающий в себя финансовую ответственность по комплексной 
                логистике, расчетов и рисков по доставке товаров, рисков изменений валютных курсов 
                от точки отправки Экспортера FCA аэропорт страны экспорта до аэропорта конечного пользователя согласно условиям CIP`,
                interaction: 'both',
            },
            {
                text: `Contract Map ™ интуитивная система контрактного менеджмента`,
                interaction: 'both',
            },
            {
                text: `Создание, выпуск и электронное подписание сертификата конечного 
                пользователя от авиационных эксплуатантов, операторов наземного парка или государственных учреждений`,
                interaction: 'importer',
            },
            {
                text: `SWIFT Гарантия для государственных конечных пользователей на суммы контрактов превышающих 250 000 долл. США`,
                interaction: 'importer',
            },
            {
                text: `SWIFT Гарантия Экспортера для сумм контрактов превышающих 100 000 долл. США`,
                interaction: 'exporter',
            },
            {
                text: `Безопасный Ескроу-Счет в престижном международном банке для Импортера с 
                предварительным 25% депонированием средств в национальной валюте, согласно 
                е-Контракта для обеспечения гарантии выполнения сделки`,
                interaction: 'importer',
            },
            {
                text: `e-Контракт согласно международных требований содержащий Общие 
                контрактные условия, Специальные контрактные условия и Спецификацию`,
                interaction: 'both',
            },
            {
                text: `Эскроу-склад условного депонирования в первой сервисной точке 
                аэропорта Дар-Ес-Салаам, Танзания для безопасности выполнения сделки для Импортера и Экспортера`,
                interaction: 'both',
            },
            {
                text: `Независимая техническая инспекция и фотоотчет по всем отгрузкам, приемка и 
                составление независимого инспекционного Акта по параметрам количества, качества и 
                аутентичности поставляемой продукции согласно нормам и стандартов: ISO, ГОСТ, МАК, EASA и др.`,
                interaction: 'both',
            },
            {
                text: `Автоматическое создание, выпуск электронных инвойсов, упаковочных листов с 
                различными сценариями упаковки изделий в отгрузочные места (Комплект поставки, навалом и др.)`,
                interaction: 'exporter',
            },
            {
                text: `Разделение партий поставок (частичная отгрузка) согласно е-Контракта`,
                interaction: 'exporter',
            },
            {
                text: `Система электронной подписи DocuSign для всех документов`,
                interaction: 'both',
            },
            {
                text: `Электронная авианакладная для всех отгрузок`,
                interaction: 'both',
            },
            {
                text: `Автоматическое создание бирок и этикеток нат отгрузочные места`,
                interaction: 'exporter',
            },
            {
                text: `Выпуск трехстороннего Акта приёма-передачи товаров между платформой 
                Exportery (Агент), Экспортером, Импортером по завершению Акта инспекции 
                для передачи товаров согласно е-Контракта`,
                interaction: 'both',
            },
            {
                text: `Продвинутая система отслеживания и трекинга грузов`,
                interaction: 'both',
            },
            {
                text: `Система нотификаций событий`,
                interaction: 'both',
            },
            {
                text: `Персональный контент-менеджер для Экспортера`,
                interaction: 'exporter',
            },
            {
                text: `Персональный менеджер по сопровождению Контрактов для Ипортера и Экспортера`,
                interaction: 'both',
            },
            {
                text: `Система рекламаций и возвратов`,
                interaction: 'importer',
            },
        ],
    },
};