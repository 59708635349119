import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';
import {withDataService} from '../../../../../../../../common/hoc';
import {withServices} from '../../../../../../../../service-context';
import sweetAlert from '../../../../../../../../common/sweet-alert';
import {onButtonUrlRequest} from '../../../../../../../../../reducers/activity-hub/request-on-button-url';
import {onGetButtonMethod} from '../../../../../../../../common/button-constructor/handlers';
import {onGetAlertResponse} from '../../../../../../../../../utils';
import {resetRequestOnButtonUrl} from '../../../../../../../../../actions/activity-hub';

import StatusIndicator from '../../../../../../common/status-indicator';
import ButtonConstructor from '../../../../../../../../common/button-constructor';
import DocumentLink from '../../../../../../../../common/document-link';

import classnames from 'classnames/bind';
import styles from './documentations-information-item.module.scss';

class DocumentationsInformationItem extends Component {
    state = {
        btnUniqUrl: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * Next case work with straight request to back-end server,
         * Not work with redirect to another resource
         **/
        if (prevProps.requestOnButtonUrlResp !== this.props.requestOnButtonUrlResp) {
            const {status, text} = onGetAlertResponse(this.props.requestOnButtonUrlResp);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    this.props.resetRequestOnButtonUrl();
                    this.setState({btnUniqUrl: null});
                });
            }
        }
    }

    onButtonClick = ({method, url, target}) => {
        const {request_question} = this.props.staticData.activity_hub.e_contracts;
        if (method === 'POST') {
            sweetAlert('question', request_question)
                .then((resp) => {
                    if (resp.isConfirmed) {
                        this.setState({btnUniqUrl: url});
                        this.props.sendPOSTRequestOnButtonUrl(url);
                    }
                });
        } else {
            onGetButtonMethod(url, target, this.props.history);
        }
    };

    render() {
        const {btnUniqUrl} = this.state;
        const {label, date, status, buttons, href, requestOnButtonUrlResp: {loading}} = this.props;
        const cx = classnames.bind(styles);

        let buttonBlock = null;
        const buttonBlockClasses = cx('btn-block', {'content-center': buttons.length < 2});
        if (buttons.length !== 0) {
            buttonBlock = buttons
                .map((button, idx) => {
                    const btnClasses = cx({
                        'btn-short': buttons.length > 1,
                        'secondary': button.style.includes('border'),
                    });

                    const dataLoading = btnUniqUrl === button.remote.url && loading;

                    return <ButtonConstructor key={idx}
                                              {...button}
                                              dataLoading={dataLoading}
                                              classNames={btnClasses}
                                              onClick={this.onButtonClick}/>;
                })
                .reverse();
        }

        return (
            <div className={styles.container}>
                <DocumentLink data={{label, href, status}} classNames={styles.column}/>
                <div className={styles.column}>{date}</div>
                <StatusIndicator status={status} classNames={styles.column}/>
                <div className={buttonBlockClasses}>
                    {buttonBlock}
                </div>
            </div>
        );
    }

    static propTypes = {
        date: PropTypes.string,
        buttons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        history: PropTypes.shape({
            push: PropTypes.func,
        }),
        requestOnButtonUrlResp: PropTypes.object,
        sendPOSTRequestOnButtonUrl: PropTypes.func,
        resetRequestOnButtonUrl: PropTypes.func,
    };
}

const mapServiceToProps = ({activityHubService}) => ({
    sendPOSTRequestOnButtonUrl: onButtonUrlRequest(activityHubService.sendPOSTRequestOnButtonUrl),
});

const mapStateToProps = ({activityHub: {requestOnButtonUrl}}) => {
    return {requestOnButtonUrlResp: requestOnButtonUrl};
};

const mapDispatchToProps = (dispatch, {sendPOSTRequestOnButtonUrl}) => {
    return bindActionCreators({
        sendPOSTRequestOnButtonUrl,
        resetRequestOnButtonUrl,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService(),
    withServices(mapServiceToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(DocumentationsInformationItem);