import React from 'react';
import PropTypes from 'prop-types';
import {useMediaQuery} from 'react-responsive';

import {withAccordion} from '../../../../../../common/hoc';
import {documentIconHandler} from '../../../../../../../utils';

import styles from './documents-list.module.scss';

const DocumentsList = ({label, content, isMobileTitle}) => {
    const lgDevice = useMediaQuery({minWidth: 992});

    const documentsList = content.map(({name, size, type, href}, idx) => {
        const documentIcon = documentIconHandler(type);

        return (
            <div className={styles.item} key={idx}>
                <div className={styles.icon} style={{backgroundImage: `url(${documentIcon})`}}/>
                <a href={href} target="_blank" rel="noopener nofollow noreferrer" className={styles.name}>
                    {name}
                </a>
                <div className={styles.size}>{size}</div>
            </div>
        );
    });

    return lgDevice ? (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.label}>{label}</div>
                {documentsList}
            </div>
        </div>
    ) : (
        <>
            {isMobileTitle && <div className={styles['label-mobile']}>{label}</div>}
            {documentsList}
        </>
    );
};

DocumentsList.defaultProps = {
    isMobileTitle: false,
};

DocumentsList.propTypes = {
    label: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.string,
        type: PropTypes.string,
        href: PropTypes.string,
    })),
    isMobileTitle: PropTypes.bool,
};

export default withAccordion()(DocumentsList);