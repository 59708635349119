import React, {Component, createRef} from 'react';
import {Helmet} from 'react-helmet-async';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import {withDataService} from '../hoc';

import InputField from '../input-field';
import ButtonConstructor from '../button-constructor';
import PageToPrint from '../../page-to-print/page-to-print';
import Preloader from '../preloader';

import classnames from 'classnames/bind';
import styles from './document-viewer.module.scss';

class DocumentViewer extends Component {
    state = {
        numPages: 1,
        pageNumber: 1,
        pageNumberInputValue: 1,
        pageScale: 1.5,
        inputNumberError: false,
        documentLinkToPrint: '',
    };

    documentRef = createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pageNumberInputValue !== this.state.pageNumberInputValue) {
            const {pageNumberInputValue, numPages} = this.state;
            const value = parseInt(pageNumberInputValue);

            if (!isNaN(value)) {
                if (value > 0 && value <= numPages) {
                    this.setState({pageNumber: value});
                } else {
                    this.setState({inputNumberError: true});
                }
            }
        }
    }

    async onCreateObjectUrl(pdf) {
        const data = await pdf.getData();
        const blob = new Blob([data], {type: 'application/pdf'});

        const createObjectURL = (file) => {
            if (window.webkitURL) {
                return {url: window.webkitURL, file: window.webkitURL.createObjectURL(file)};
            }

            if (window.URL && window.URL.createObjectURL) {
                return {url: window.URL, file: window.URL.createObjectURL(file)};
            }

            return null;
        };

        return createObjectURL(blob);
    };

    onInputChange = ({target: {value}}) => {
        this.setState({pageNumberInputValue: value});

        if (this.state.inputNumberError) {
            this.setState({inputNumberError: false});
        }
    };

    onInputFocus = () => {
        this.setState({inputNumberError: false});
    };

    changePage = (offset) => {
        this.setState(({pageNumber: prevPageNumber, inputNumberError, ...rest}) => {
            const newPageNumber = prevPageNumber + offset;
            return {
                ...rest,
                pageNumberInputValue: newPageNumber,
                inputNumberError: false,
            };
        });
    };

    previousPage = () => {
        const {pageNumber} = this.state;
        if (pageNumber > 1) {
            this.changePage(-1);
        }
    };

    nextPage = () => {
        const {pageNumber, numPages} = this.state;
        if (pageNumber < numPages) {
            this.changePage(1);
        }
    };

    render() {
        const {
            numPages, pageNumber, pageNumberInputValue, pageScale,
            inputNumberError, documentLinkToPrint,
        } = this.state;
        const {
            staticData: {
                document_viewer: {
                    document_not_exist, document_error,
                    document_loading_failed, pdf_no_data,
                    page_error, page_no_data,
                },
                pagination: {button: {prev, next}},
            },
            location: {title},
            loading,
            updating,
            document,
            error,
            actionButton,
            apiTitle,
        } = this.props;

        const cx = classnames.bind(styles);
        const prevBtnClasses = cx('prev-btn', {'disabled': pageNumber <= 1});
        const nextBtnClasses = cx('next-btn', {'disabled': pageNumber >= numPages});

        if (loading || updating) {
            return <Preloader/>;
        }

        if (error) {
            return <div>{document_error}</div>;
        }

        if (document) {
            const {link, title: documentTitle, buttons = []} = document;
            const helmetTitle = documentTitle || title || apiTitle;

            return (
                <>
                    <Helmet>
                        <title>{helmetTitle}</title>
                    </Helmet>
                    <h1 className={styles.title}>{documentTitle || title}</h1>
                    <div className={styles.container}>
                        <div className={styles.viewer}>
                            <Document
                                ref={this.documentRef}
                                file={link}
                                className={styles.document}
                                loading={(
                                    <div className={styles['loading-block']}>
                                        <div className={styles.preloader}/>
                                    </div>
                                )}
                                error={document_loading_failed}
                                noData={pdf_no_data}
                                onLoadSuccess={(pdf) => {
                                    this.setState({numPages: pdf.numPages, pageNumber: 1});
                                    this.onCreateObjectUrl(pdf)
                                        .then((resp) => {
                                            if (resp) {
                                                const {url, file} = resp;
                                                this.setState({documentLinkToPrint: file});
                                                url.revokeObjectURL(file);
                                            } else {
                                                throw new Error('File doesn\'t exist');
                                            }
                                        }).catch(error => console.log(error));
                                }}
                                onContextMenu={(e) => e.preventDefault()}>
                                <Page key={pageNumber}
                                      pageNumber={pageNumber}
                                      className={styles.page}
                                      scale={pageScale}
                                      loading={(
                                          <div className={styles['loading-block']}>
                                              <div className={styles.preloader}/>
                                          </div>
                                      )}
                                      error={page_error}
                                      noData={page_no_data}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                      onRenderSuccess={this.onRenderSuccess}/>
                            </Document>
                        </div>

                        <div className={styles['sticky-block']}>
                            <div className={styles.navigation}>
                                <div className={styles['btn-group']}>
                                    <div className={prevBtnClasses} onClick={this.previousPage}>{prev}</div>
                                    <div className={nextBtnClasses} onClick={this.nextPage}>{next}</div>
                                </div>
                                <div className={styles['numbers-block']}>
                                    <InputField value={pageNumberInputValue}
                                                props={inputNumberError ? ['with-red-border'] : []}
                                                onInputChange={this.onInputChange}
                                                onInputFocus={this.onInputFocus}/>
                                    / {numPages}
                                </div>

                                <PageToPrint originUrl={link} printBlob>{documentLinkToPrint}</PageToPrint>

                                {buttons.length > 0 && (
                                    <div className={styles['btn-group-action']}>
                                        {buttons.map((button, idx) => {
                                            const btnClasses = cx('btn', {'multi-mode': buttons.length > 1});
                                            const loading = actionButton.loading && actionButton.idx === idx;

                                            return <ButtonConstructor key={idx}{...button}
                                                                      classNames={btnClasses}
                                                                      dataLoading={loading}
                                                                      onClick={(remoteParams) => this.props.onButtonClick(idx, remoteParams)}/>;
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        return <div>{document_not_exist}</div>;
    }

    static defaultProps = {
        onButtonClick: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        location: PropTypes.shape({
            title: PropTypes.string,
        }),
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        document: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
            link: PropTypes.string,
            title: PropTypes.string,
            buttons: PropTypes.array,
        })]),
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        actionButton: PropTypes.object,
        apiTitle: PropTypes.string,
        onButtonClick: PropTypes.func,
    };
}

const mapStateToProps = ({utils: {apiTitle}}) => ({apiTitle});

export default compose(
    withRouter,
    withDataService(),
    connect(mapStateToProps),
)(DocumentViewer);