import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import PropTypes from 'prop-types';

import {withHubSideMenu} from '../../../../common/hoc';
import {withServices} from '../../../../../../service-context';
import {withDataService} from '../../../../../../common/hoc';
import {
    bankAccountFormFieldsRequest,
    updatingBankAccountFormFieldsRequest,
} from '../../../../../../../reducers/activity-hub/profile/bank-account/bank-account-form-fields';
import {
    createBankAccountRequest,
    editBankAccountRequest,
} from '../../../../../../../reducers/activity-hub/profile/bank-account/bank-account-actions';
import {setDefaultBankAccountRequest} from '../../../../../../../reducers/activity-hub/profile/bank-account/default-bank-account';
import {profileBankAccountAction} from '../../../../../../../actions/activity-hub';
import {onChangeFieldValue} from '../../../../common/handlers';
import {transformFieldData, getDataFromFields} from '../../../../common/handlers/field-handlers';
import {onCreateFormData} from '../../../../../../common/handlers';
import {onGetAlertResponse} from '../../../../../../../utils';
import sweetAlert from '../../../../../../common/sweet-alert';

import BankAccount from './bank-account';
import Preloader from '../../../../../../common/preloader';

class BankAccountContainer extends Component {
    state = {
        list: [],
        isSaveButtonDisabled: true,
    };

    componentDidMount() {
        const {location: {search: params}, match: {params: {id}}, pageType = 'create'} = this.props;

        if (pageType === 'create') {
            this.props.fetchData(null, params, pageType);
        }

        if (pageType === 'edit') {
            this.props.fetchData(id, null, pageType);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {location: {search: params}, match: {params: {id}}, pageType = 'create'} = this.props;

            if (pageType === 'create') {
                this.props.fetchUpdatingData(null, params, pageType);
            }

            if (pageType === 'edit') {
                this.props.fetchUpdatingData(id, null, pageType);
            }
        }

        if (prevProps.fieldsList !== this.props.fieldsList) {
            const {fieldsList, pageType} = this.props;

            if (fieldsList) {
                let updatedList = fieldsList.map((field) => transformFieldData(field));

                if (pageType === 'create') {
                    updatedList = updatedList.filter(({type}) => type !== 'button');
                }

                this.setState({list: updatedList});
            }
        }

        if (prevProps.accountDataResponse !== this.props.accountDataResponse) {
            const {status, text} = onGetAlertResponse(this.props.accountDataResponse);

            if (status === 'success') {
                sweetAlert(status, text).then(() => {
                    this.props.history.push(this.props.basePath);
                    this.props.resetBankAccountActions();
                });
            }

            if (status === 'error') {
                const errorMessage = (
                    <ul>
                        {Object.values(text).map((item, idx) => {
                            return <li key={idx}>{`- ${item}`}</li>;
                        })}
                    </ul>
                );

                sweetAlert(
                    status, errorMessage,
                    false, false,
                    false, 3000,
                ).then(() => this.props.resetBankAccountActions());
            }
        }

        if (prevProps.setDefaultAccountResponse !== this.props.setDefaultAccountResponse) {
            const {status, text} = onGetAlertResponse(this.props.setDefaultAccountResponse);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    if (status === 'success') {
                        const {match: {params: {id}}, pageType} = this.props;
                        this.props.fetchUpdatingData(id, null, pageType);
                    }

                    this.props.resetSetDefaultBankAccount();
                });
            }
        }
    }

    onSaveButtonChange = () => {
        if (this.state.isSaveButtonDisabled) {
            this.setState({isSaveButtonDisabled: false});
        }
    };

    onSetDefault = (url) => {
        const {default_change_question} = this.props.staticData.activity_hub.profile.pages.bank_account;

        sweetAlert('question', default_change_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.onSetDefaultAccount(url);
                }
            });
    };

    onUpdateFieldsValue = (name, value) => {
        const list = onChangeFieldValue(this.state.list, name, value);
        this.setState({list});

        this.onSaveButtonChange();
    };

    onClickSelectItem = (fieldName, {value}) => this.onUpdateFieldsValue(fieldName, value);

    onInputChange = ({target: {name, value}}) => this.onUpdateFieldsValue(name, value);

    onSubmit = (e) => {
        e.preventDefault();
        const {list} = this.state;
        const {location: {search: params}, match: {params: {id}}, pageType} = this.props;

        const object = getDataFromFields(list);
        const formData = onCreateFormData(object);

        if (pageType === 'create') {
            this.props.onCreateAccount({formData}, params);
        }

        if (pageType === 'edit') {
            this.props.onEditAccount({formData}, id);
        }
    };

    onCancel = () => this.props.history.push(this.props.basePath);

    render() {
        const {
            staticData, pageType, loading, updating, error,
            accountDataLoading, setDefaultAccountLoading,
        } = this.props;

        const params = {
            staticData,
            pageType,
            accountDataLoading,
            setDefaultAccountLoading,
            onClickSelectItem: this.onClickSelectItem,
            onInputChange: this.onInputChange,
            onSetDefault: this.onSetDefault,
            onSubmit: this.onSubmit,
            onCancel: this.onCancel,
        };

        return !loading && !updating && !error
            ? <BankAccount {...params} {...this.state} />
            : <Preloader/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        pageType: PropTypes.string,
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        fieldsList: PropTypes.array,
        error: PropTypes.object,
        accountDataLoading: PropTypes.bool,
        accountDataResponse: PropTypes.object,
        setDefaultAccountLoading: PropTypes.bool,
        setDefaultAccountResponse: PropTypes.object,
        fetchData: PropTypes.func,
        fetchUpdatingData: PropTypes.func,
        onCreateAccount: PropTypes.func,
        onEditAccount: PropTypes.func,
        onSetDefaultAccount: PropTypes.func,
        resetBankAccountActions: PropTypes.func,
        resetSetDefaultBankAccount: PropTypes.func,
    };
}

const mapServicesToProps = ({profileService}) => {
    const {
        getBankAccountFormFields, createBankAccount,
        editBankAccount, setDefaultBankAccount,
    } = profileService;

    return {
        fetchData: bankAccountFormFieldsRequest(getBankAccountFormFields),
        fetchUpdatingData: updatingBankAccountFormFieldsRequest(getBankAccountFormFields),
        onCreateAccount: createBankAccountRequest(createBankAccount),
        onEditAccount: editBankAccountRequest(editBankAccount),
        onSetDefaultAccount: setDefaultBankAccountRequest(setDefaultBankAccount),
    };
};

const mapStateToProps = ({profile: {language}, activityHub: {profile: {bankAccount}}}) => ({
    language,
    loading: bankAccount.formFields.loading,
    updating: bankAccount.formFields.updating,
    fieldsList: bankAccount.formFields.list,
    error: bankAccount.formFields.error,
    accountDataLoading: bankAccount.actions.loading,
    accountDataResponse: bankAccount.actions,
    setDefaultAccountLoading: bankAccount.default.loading,
    setDefaultAccountResponse: bankAccount.default,
});

const mapDispatchToProps = (dispatch, {
    fetchData,
    fetchUpdatingData,
    onCreateAccount,
    onEditAccount,
    onSetDefaultAccount,
}) => {
    const {
        resetBankAccountActions,
        resetSetDefaultBankAccount,
    } = profileBankAccountAction;

    return bindActionCreators({
        fetchData,
        fetchUpdatingData,
        onCreateAccount,
        onEditAccount,
        onSetDefaultAccount,
        resetBankAccountActions,
        resetSetDefaultBankAccount,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(BankAccountContainer);