import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    onChangeFieldValue,
    onChangeRadioButtonValue,
    onChangeFieldDocuments,
} from '../../../../../../common/handlers';
import {transformFieldData} from '../../../../../../common/handlers/field-handlers';

import OrganizationProfile from './organization-profile';

class OrganizationProfileContainer extends Component {
    state = {
        list: null,
        isCanBeUpdated: true,
    };

    componentDidMount() {
        const updatedList = this.fieldHandler();
        this.setState({list: updatedList});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.list !== this.props.list) {
            if (this.state.isCanBeUpdated) {
                const updatedList = this.fieldHandler();
                this.setState({list: updatedList});
            }
        }

        if (prevState.list !== this.state.list) {
            if (this.state.isCanBeUpdated) {
                this.setState({isCanBeUpdated: false});

            } else {
                if (this.props.checkingFieldMode) {
                    const {type, name} = this.props.checkingFieldMode;
                    this.props.onCheckingRequiredField(type, name);
                }

                if (prevState.list.length !== 0 && this.state.list.length > 0) {
                    this.onUpdateReduxState();
                }
            }
        }

        if (prevProps.submitAction !== this.props.submitAction && this.props.submitAction) {
            this.onUpdateReduxState();
        }
    }

    fieldHandler = () => this.props.list.map((field) => transformFieldData(field));

    onUpdateFieldsValue = (name, value) => {
        const list = onChangeFieldValue(this.state.list, name, value);
        this.setState({list});
    };

    onRadioButtonChange = ({target: {id, name, value}}) => {
        let updatedFields = onChangeRadioButtonValue(this.state.list, id, name, value);
        updatedFields = onChangeFieldValue(updatedFields, name, value);
        this.setState({list: updatedFields});
    };

    onClickSelectItem = (fieldName, {value}) => {
        this.onUpdateFieldsValue(fieldName, value);
    };

    onInputChange = ({target: {name, value}}) => this.onUpdateFieldsValue(name, value);

    onInputPhoneChange = (name, value) => this.onUpdateFieldsValue(name, value);

    onDocumentsChange = (name, documents) => {
        const {files, rejectedFiles, alreadyUploadedFiles} = documents;
        let updatedList = [];
        if (!alreadyUploadedFiles) {
            updatedList = onChangeFieldDocuments(this.state.list, name, files, rejectedFiles);

        } else {
            updatedList = onChangeFieldDocuments(this.state.list, name, null, null, alreadyUploadedFiles);
        }

        this.setState({list: updatedList});
    };

    onUpdateReduxState = () => {
        this.props.onUpdateList(this.props.id, this.state.list);
    };

    render() {
        return <OrganizationProfile  {...this.state}
                                     onRadioButtonChange={this.onRadioButtonChange}
                                     onClickSelectItem={this.onClickSelectItem}
                                     onInputChange={this.onInputChange}
                                     onInputPhoneChange={this.onInputPhoneChange}
                                     onDocumentsChange={this.onDocumentsChange}/>;
    };

    static defaultProps = {
        onUpdateList: () => {
        },
        onCheckingRequiredField: () => {
        },
    };

    static propTypes = {
        id: PropTypes.string,
        list: PropTypes.array,
        checkingFieldMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        onUpdateList: PropTypes.func,
        onCheckingRequiredField: PropTypes.func,
    };
}

export default OrganizationProfileContainer;