import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './document-link.module.scss';

const DocumentLink = ({data: {href, label, status}, classNames}) => {
    const cx = classnames.bind(styles);
    let containerClasses = cx('container', classNames);

    if (status) {
        const {color} = status;
        containerClasses = containerClasses + ' ' + cx([`${color}`]);
    }

    return (
        <div className={containerClasses}>
            {href
                ? (
                    <Link to={{pathname: href, title: label}} className={styles.link}>
                        {label}
                    </Link>
                ) : label}
        </div>
    );
};

DocumentLink.propTypes = {
    data: PropTypes.shape({
        href: PropTypes.string,
        label: PropTypes.string,
        status: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOf([
                'pending', 'negotiation', 'partially',
                'completed', 'approved', 'expired', 'rejected',
                'signed', 'issued',
            ]),
        })]),
    }),
    classNames: PropTypes.string,
};

export default DocumentLink;