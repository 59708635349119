import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';
import {withHubSideMenu} from '../../common/hoc';

import {
    UserProfile, PaymentSetup,
    OrganizationProfile, ShipmentSetup,
} from './components/common/tabs-options';
import Preloader from '../../../../common/preloader';
import Button from '../../../../common/button';

import styles from './profile.module.scss';

const Profile = (props) => {
    const {
        staticData, loading, updating, list, tabsID,
        checkingFieldMode, submitAction, uploadFormLoading, removeAttachmentLoading,
        onToggleTab, onUpdateList, onSubmit, onCheckingRequiredField,
    } = props;

    const additionalParams = {
        checkingFieldMode,
        submitAction,
        onUpdateList,
        onCheckingRequiredField,
    };
    const {save} = staticData.activity_hub.profile.button;

    let labelsBlock = null, tabContentBlock = null;
    if (list.length > 0) {
        labelsBlock = list.map(({tab: {id, name, isActive}}, idx) => {
            return (
                <div className={styles.tab} key={idx}>
                    <input type="radio"
                           id={id}
                           checked={isActive}
                           onChange={onToggleTab}/>

                    <label className={styles.label} htmlFor={id}>
                        {name}
                    </label>
                </div>
            );
        });

        tabContentBlock = list.map((item, idx) => {
            const {tab: {id, isActive}, fields} = item;
            if (isActive) {
                if (id === tabsID.userProfile) {
                    return <UserProfile {...additionalParams} key={idx} id={id} list={fields}/>;
                }

                if (id === tabsID.organizationProfile) {
                    return <OrganizationProfile {...additionalParams} key={idx} id={id} list={fields}/>;
                }

                if (id === tabsID.paymentSetup) {
                    return <PaymentSetup {...additionalParams} key={idx} id={id} list={fields}/>;
                }

                if (id === tabsID.shipmentSetup) {
                    return <ShipmentSetup {...additionalParams} key={idx} id={id} list={fields}/>;
                }
            }

            return null;
        });
    }

    return loading || updating
        ? <Preloader/>
        : (
            <div className={styles.wrapper} style={{pointerEvents: removeAttachmentLoading ? 'none' : 'auto'}}>
                {removeAttachmentLoading && <div className={styles['hiding-container']}/>}
                <div className={styles['tabs-labels']}>
                    {labelsBlock}
                </div>

                {tabContentBlock}

                <div className={styles['btn-block']}>
                    <Button classNames={styles.btn}
                            dataLoading={uploadFormLoading}
                            isDisabled={submitAction}
                            onClick={onSubmit}>
                        {save}
                    </Button>
                </div>
            </div>
        );
};

Profile.defaultProps = {
    onToggleTab: () => {
    },
    onUpdateList: () => {
    },
    onCheckingRequiredField: () => {
    },
    onSubmit: () => {
    },
};

Profile.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.shape({
        tab: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            isActive: PropTypes.bool,
        }),
        list: PropTypes.array,
    })),
    tabsID: PropTypes.object,
    checkingFieldMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    submitAction: PropTypes.bool,
    uploadFormLoading: PropTypes.bool,
    removeAttachmentLoading: PropTypes.bool,
    onToggleTab: PropTypes.func,
    onUpdateList: PropTypes.func,
    onCheckingRequiredField: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default compose(
    withDataService(), // used in next hoc
    withHubSideMenu(),
)(Profile);