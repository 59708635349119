const updateLocalStorage = (name, value) => {
    localStorage.setItem(name, value);
};

const getDataFromLocalStorage = (name) => {
    const data = localStorage.getItem(name);

    return data;
};

export {
    updateLocalStorage,
    getDataFromLocalStorage,
};