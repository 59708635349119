import React, {useEffect, useRef} from 'react';
import sweetAlert from '../../sweet-alert';

import classnames from 'classnames/bind';
import styles from './modal.module.scss';

const withModal = () => (Wrapped) => {
    return (props) => {
        const {
            modalContainerClass = '', modalWrapperClass = '', modalClass = '',
            modalCloseBtnClass = '', staticData = null, isShown = false,
            withQuestionBeforeCloseModal = false, closeButton = false,
            closeBtnColorMobile = 'GREY', onClose, onContainerClose, ...otherProps
        } = props;

        const modalRef = useRef();

        let timer = null;

        useEffect(() => {
            if (modalRef.current) {
                if (isShown) {
                    document.body.style.overflowY = 'hidden';
                    modalRef.current.classList.add(`${styles.shown}`);
                }
            }

            return () => {
                document.body.style.overflowY = null;
            };
        }, [isShown]);

        const onModalClose = (func) => {
            const onCloseAction = () => {
                modalRef.current.classList.remove(`${styles.shown}`);

                if (timer !== null) {
                    clearTimeout(timer);
                }

                timer = setTimeout(() => func(), 300);
            };

            if (withQuestionBeforeCloseModal && staticData) {
                const {close_modal_window_question} = staticData.alert_modal;
                sweetAlert('question', close_modal_window_question)
                    .then(({isConfirmed}) => {
                        if (isConfirmed) {
                            onCloseAction();
                        }
                    });

            } else {
                onCloseAction();
            }
        };

        const cx = classnames.bind(styles);
        const containerClasses = cx('container', modalContainerClass);
        const wrapperClasses = cx('modal-wrapper', modalWrapperClass);
        const modalClasses = cx('modal', modalClass);
        const closeBtnClasses = cx('close-btn', modalCloseBtnClass, {'white': closeBtnColorMobile === 'WHITE'});

        return isShown && (
            <div id="modal" className={containerClasses}
                 onClick={({target}) => {
                     if (target.id === 'modal-wrapper' || target.id === 'modal') {
                         onModalClose(onContainerClose);
                     }
                 }}>
                <div id="modal-wrapper" className={wrapperClasses}>
                    <div ref={modalRef} className={modalClasses}>
                        {closeButton && <div className={closeBtnClasses} onClick={() => onModalClose(onClose)}/>}

                        <Wrapped {...otherProps}
                                 staticData={staticData}
                                 withQuestionBeforeCloseModal={withQuestionBeforeCloseModal}/>
                    </div>
                </div>
            </div>
        );
    };
};

export default withModal;