import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';

import Dropdown from '../../../../../../../../common/dropdown';

import classnames from 'classnames/bind';
import styles from './certification.module.scss';

const Certification = ({staticData, content}) => {
    const {certification_title} = staticData.marketplace.product_page;
    const cx = classnames.bind(styles);

    const [certificatesList, setCertificatesList] = useState([]);

    useEffect(() => {
        const newCertificatesList = content.map(({icon, name, list}, idx) => {
            return {
                id: `${idx}_${name}`,
                icon,
                name,
                list,
                isOpen: false,
            };
        });

        setCertificatesList(newCertificatesList);
    }, [content]);

    const toggleListMenu = (currentId) => {
        const newCertificatesList = certificatesList.map(({id, isOpen, ...rest}) => {
            if (id === currentId) {
                return {
                    id,
                    ...rest,
                    isOpen: !isOpen,
                };
            }

            return {
                id,
                ...rest,
                isOpen: false,
            };
        });

        setCertificatesList(newCertificatesList);
    };

    const certificates = certificatesList.map(({id, icon, name, list, isOpen}, idx) => {
        const dropdownLabel = (
            <div className={styles.label}>
                <div className={styles.icon} style={{backgroundImage: `url(${icon})`}}/>
                <div className={styles.name}>{name}</div>
            </div>
        );

        const listOfItems = list.map(({label, href}, idx) => (
            <a key={idx}
               href={href}
               className={styles.item}
               target="_blank" rel="noreferrer noopener nofollow"
               onClick={() => toggleListMenu(id)}
            >
                {label}
            </a>
        ));

        const wrapperClasses = cx(
            'certificate-wrapper',
            'col-4','col-xs-3', 'col-sm-2', 'col-lg-1',
        );

        return (
            <div key={idx} className={wrapperClasses}>
                <Dropdown label={dropdownLabel}
                          labelClassNames={styles.certificate}
                          itemsClassNames={styles['items-block']}
                          isOpen={isOpen}
                          onToggle={() => toggleListMenu(id)}
                >
                    {listOfItems}
                </Dropdown>
            </div>
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles.title}>{certification_title}</div>
            <div className={styles.certificates}>{certificates}</div>
        </div>
    );
};

Certification.propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.shape({
            href: PropTypes.string,
            label: PropTypes.string,
        })),
    })),
    staticData: PropTypes.object,
};

export default withDataService()(Certification);