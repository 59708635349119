import * as invoiceAction from './origin-invoice-wizard';
import * as labellingAction from './packing-list-labelling';
import * as contractConditionsAction from './contract-conditions';

import * as packingListAction from './packing-list-wizard/common';
import * as packingFormAction from './packing-list-wizard/packing-form';
import * as productListForPackingAction from './packing-list-wizard/products-list-for-packing';
import * as suggestStrategyAction from './packing-list-wizard/suggest-strategy';

export const resetContractDocumentUpload = () => {
    return {type: 'RESET_CONTRACT_DOCUMENT_UPLOAD_DATA'};
};

export {invoiceAction, labellingAction, contractConditionsAction};

export {
    packingListAction,
    packingFormAction,
    productListForPackingAction,
    suggestStrategyAction,
};