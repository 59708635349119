import React, {useEffect, useState} from 'react';
import * as _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';

import ProductDescription from '../../../product-description';

import styles from './main-technical-data.module.scss';

const MainTechnicalData = ({staticData, content, content: {description, table}}) => {
    const [tableData, setTableData] = useState([]);
    const [tableParameterLabel, tableValueLabel] = staticData.marketplace.product_page.main_technical_data_label;

    function transform(node) {
        if (node.type === 'tag') {
            node.attribs = {
                ...node.attribs,
                class: `${styles[`${node.name}`]}`,
            };
        }
    }

    useEffect(() => {
        const newTableData = table !== undefined
            ? table.map(({field, value}) => ({
                label: field,
                value,
            }))
            : [];

        setTableData(newTableData);
    }, [table]);

    const descriptionLine = ReactHtmlParser(description, {transform});

    return _.isObject(content) && content.length !== 0 ? (
        <div className={styles.container}>
            {description !== undefined && descriptionLine}

            {tableData.length !== 0 && (
                <>
                    <div className={styles['table-header']}>
                        <div className={styles['parameter-label']}>{tableParameterLabel}</div>
                        <div className={styles.value}>{tableValueLabel}</div>
                    </div>
                    <ProductDescription itemClassNames={styles['description-item']}
                                        description={tableData}
                                        isOrderList/>
                </>
            )}
        </div>
    ) : null;
};

MainTechnicalData.propTypes = {
    content: PropTypes.shape({
        description: PropTypes.string,
        table: PropTypes.arrayOf(PropTypes.shape({
            field: PropTypes.string,
            value: PropTypes.any,
        })),
    }),
    staticData: PropTypes.object,
};

export default withDataService()(MainTechnicalData);