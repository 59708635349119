import React from 'react';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {priceHandler} from '../../../../../../../utils';

import styles from './other-offers-link.module.scss';

const OtherOffersLink = (props) => {
    const basePath = useSelector(({utils: {baseAPIUrl: basePath}}) => (basePath));

    const {staticData, label, href, from, currency, classNames} = props;
    const {offers_from_text} = staticData.marketplace.product_page;

    const {slug: offersSlug} = useParams();

    const linkTo = {
        pathname: `${basePath}/search`,
        search: href,
        pageTitle: offersSlug,
    };

    if (from !== '' && from !== 0) {
        const newFrom = priceHandler(from);

        return (
            <div className={`${styles.container} ${classNames}`}>
                <Link to={linkTo} className={styles.link}>{label}</Link>
                <span> {offers_from_text} <span className={styles.price}>{newFrom} {currency}</span></span>
            </div>
        );
    }

    return null;
};

OtherOffersLink.defaultProps = {
    href: '#',
    from: '',
};

OtherOffersLink.propTypes = {
    staticData: PropTypes.object,
    label: PropTypes.string,
    href: PropTypes.string,
    from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
    classNames: PropTypes.string,
};

export default OtherOffersLink;