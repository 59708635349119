import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';
import {withServices} from '../../../../../../../../service-context';
import {
    bankAccountsListRequest,
    updatingBankAccountsListRequest,
} from '../../../../../../../../../reducers/activity-hub/profile/bank-account/bank-accounts-list';
import {removeBankAccountRequest} from '../../../../../../../../../reducers/activity-hub/profile/bank-account/remove-bank-account';
import {profileBankAccountAction} from '../../../../../../../../../actions/activity-hub';
import {
    onChangeFieldValue,
    onChangeRadioButtonValue,
    transformFieldWithDependsFrom,
} from '../../../../../../common/handlers';
import {transformFieldData} from '../../../../../../common/handlers/field-handlers';
import {onGetAlertResponse} from '../../../../../../../../../utils';
import sweetAlert from '../../../../../../../../common/sweet-alert';

import PaymentSetup from './payment-setup';

class PaymentSetupContainer extends Component {
    state = {
        list: null,
        accounts: [],
        removingAccount: null,
        isCanBeUpdated: true,
    };

    componentDidMount() {
        const updatedList = this.fieldHandler();
        this.setState({list: updatedList});

        this.props.fetchBankAccountsList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchUpdatingBankAccountsList();
        }

        if (prevProps.list !== this.props.list) {
            if (this.state.isCanBeUpdated) {
                const updatedList = this.fieldHandler();
                this.setState({list: updatedList});
            }
        }

        if (prevState.list !== this.state.list) {
            if (this.state.isCanBeUpdated) {
                this.setState({isCanBeUpdated: false});

            } else {
                if (this.props.checkingFieldMode) {
                    const {type, name} = this.props.checkingFieldMode;
                    this.props.onCheckingRequiredField(type, name);
                }

                if (prevState.list.length !== 0 && this.state.list.length > 0) {
                    this.onUpdateReduxState();
                }
            }
        }

        if (prevProps.submitAction !== this.props.submitAction && this.props.submitAction) {
            this.onUpdateReduxState();
        }

        if (prevProps.bankAccountsList !== this.props.bankAccountsList) {
            if (this.props.bankAccountsList) {
                const updatedList = this.props.bankAccountsList.map((item) => {
                    return {
                        ...item,
                        isOpen: item.list.length > 0,
                    };
                });

                this.setState({accounts: updatedList});
            }
        }
        if (prevProps.removeBankAccountResponse !== this.props.removeBankAccountResponse) {
            const {status, text} = onGetAlertResponse(this.props.removeBankAccountResponse);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    this.setState({removingAccount: null});
                    this.props.resetRemoveBankAccountState();
                });
            }
        }
    }

    fieldHandler = () => {
        return this.props.list
            .map((field) => transformFieldData(field))
            .map((field, index, arr) => (
                field.dependsFrom ? transformFieldWithDependsFrom(arr, field) : field
            ));
    };

    onToggleAccordion = (currentID) => {
        const updatedAccounts = this.state.accounts.map((item) => {
            if (currentID === item.id) {
                return {
                    ...item,
                    isOpen: !item.isOpen,
                };
            }

            return item;
        });

        this.setState({accounts: updatedAccounts});
    };

    onUpdateFieldsValue = (name, value) => {
        const list = onChangeFieldValue(this.state.list, name, value);
        this.setState({list});
    };

    onClickSelectItem = (fieldName, {value}) => this.onUpdateFieldsValue(fieldName, value);

    onInputChange = ({target: {name, value}}) => this.onUpdateFieldsValue(name, value);

    onRadioButtonChange = ({target: {id, name, value}}) => {
        let updatedFields = onChangeRadioButtonValue(this.state.list, id, name, value);
        updatedFields = onChangeFieldValue(updatedFields, name, value);
        this.setState({list: updatedFields});
    };

    onRemoveAccount = (id, href) => {
        this.setState({removingAccount: id});
        this.props.onRemoveBankAccount(href);
    };

    onUpdateReduxState = () => {
        this.props.onUpdateList(this.props.id, this.state.list);
    };

    render() {
        return <PaymentSetup {...this.props} {...this.state}
                             onClickSelectItem={this.onClickSelectItem}
                             onInputChange={this.onInputChange}
                             onRadioButtonChange={this.onRadioButtonChange}
                             onToggleAccordion={this.onToggleAccordion}
                             onRemoveAccount={this.onRemoveAccount}/>;
    };

    static propTypes = {
        id: PropTypes.string,
        list: PropTypes.array,
        language: PropTypes.string,
        checkingFieldMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        removeBankAccountResponse: PropTypes.object,
        fetchBankAccountsList: PropTypes.func,
        fetchUpdatingBankAccountsList: PropTypes.func,
        onRemoveBankAccount: PropTypes.func,
        resetRemoveBankAccountState: PropTypes.func,
        onUpdateList: PropTypes.func,
        onCheckingRequiredField: PropTypes.func,
    };
}

const mapServicesToProps = ({profileService}) => ({
    fetchBankAccountsList: bankAccountsListRequest(profileService.getBankAccounts),
    fetchUpdatingBankAccountsList: updatingBankAccountsListRequest(profileService.getBankAccounts),
    onRemoveBankAccount: removeBankAccountRequest(profileService.removeBankAccount),
});

const mapStateToProps = ({profile: {language, userInteraction}, activityHub: {profile: {bankAccount}}}) => ({
    language,
    userInteraction,
    bankAccountsListLoading: bankAccount.list.loading,
    bankAccountsListUpdating: bankAccount.list.updating,
    bankAccountsList: bankAccount.list.list,
    error: bankAccount.list.error,
    removeBankAccountLoading: bankAccount.remove.loading,
    removeBankAccountResponse: bankAccount.remove,
});

const mapDispatchToProps = (dispatch, {
    fetchBankAccountsList,
    fetchUpdatingBankAccountsList,
    onRemoveBankAccount,
}) => {
    const {resetRemoveBankAccountState} = profileBankAccountAction;

    return bindActionCreators({
        fetchBankAccountsList,
        fetchUpdatingBankAccountsList,
        onRemoveBankAccount,
        resetRemoveBankAccountState,
    }, dispatch);
};

export default compose(
    withDataService(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(PaymentSetupContainer);