import {staticURLs} from '../../../api-urls';

export const advantages_en = [
    {
        title: 'Direct Access to Supply and a Wide Range of Industrial Inventory',
        text: [
            `Exportery is the first e-trade platform with a pool of technical resources, components, and spare 
            parts for Eastern and European aircraft, land transport platforms, and various equipment, actively 
            used by African and Latin American private and government sectors.`,
            `As a one-stop shop, Exportery is outfitted with a proprietary electronic document 
            management system that elevates cross-border procurement efficacy and safety to a new 
            level, our core services ensure prompt delivery of spare parts and an easy procurement process.`,
        ],
        btn: 'How Exportery works',
        href: staticURLs.about_marketplace,
        img: 'marketplace.png',
        alt_img: 'Marketplace',
    },
    {
        title: 'Independent Technical Surveying',
        text: [
            `Quality assurance and technical control are integral parts of any procurement process. A correctly 
            performed quality assurance and control process ensure that the end-users get the spare parts 
            and components in time and with validated quality and technical conditions conforming 
            to the international standards of ISO, EASA and ICAO.`,
            `Previously, there have been many cases of supplies of repaired or substandard products 
            presented as new. At Exportery, we conduct an independent review of every international 
            shipment made through our e-platform to ensure the safety of end-users of goods. The 
            independent technical inspection is carried out at the bonded escrow warehouses in Ethiopia 
            and Tanzania by certified technical staff.`,
        ],
        btn: 'More about independent technical surveying',
        href: staticURLs.about_independent_technical_surveying,
        img: 'technical-surveying.png',
        alt_img: 'Technical surveying',
    },
    {
        title: 'Core Services',
        radio: [
            {
                title: 'FCA Shipping Point for Exporter',
                text: [
                    `All quotations and prices for listed spare parts and components on the e-trade 
                    platform are based on Incoterms 2020 FCA Airport Origin Country, making the evaluation 
                    and trade process transparent and convenient for the market participants. The Exporter 
                    only needs to pass customs clearance formalities at their export country.`,
                    `Exportery will take all the logistic burden and bear all financial risks of the 
                    dispatched goods from the Exporter's Origin Country through the agency of a trusted 
                    network of logistics operators, airlines, and agents.`,
                ],
                img: 'FCA.png',
                alt_img: 'Core services',
            },
            {
                title: 'CIP Destination Point for Importer',
                text: `Exportery automatically quotes all spare parts and components upon creation of e-RFQ for 
                our respected Importers based on the Incoterms 2020 CIP Destination Airport. It will allow the 
                Importer to get an instant price quotation within minutes and to access the price and 
                commercial appeal, which includes delivery and insurance to any of the remote 
                destinations in the world.`,
                img: 'CIP.png',
                alt_img: 'Core services',
            },
            {
                title: 'Automation of export-import documentation',
                text: [
                    `Our e-trade platform aims to transcend bureaucratic and time-consuming procedures 
                    in procurement processes and digitalize the establishment and exchange of end-user 
                    certificates, proof of delivery, and banking guarantees through the agency of 
                    implemented blockchain technology and partner entities.`,
                    `We already offer convenience to our customers, an unprecedented responsibility, 
                    and unrivaled support through the proprietary developed contract management system, 
                    which makes the automatic and intuitive release and flow of essential import-export 
                    documents such as e-Invoices, e-Give and Takes acts, e-Packing lists, e-Contracts and finally e-AWB.`,
                ],
                img: 'automation_of_documentation.png',
                alt_img: 'Core services',
            },
            {
                title: 'E-signatures on all documents',
                text: `At Exportery, all documents are proprietary and e-signed through the reputable 
                DocuSign system accepted in most countries. Other than making the clearance of 
                goods at customs an easy process, there is authenticity and ownership of all the 
                documentation, which creates trust in the e-trade platform by all parties.`,
                img: 'e-signatures.png',
                alt_img: 'Core services',
            },
            {
                title: 'Escrow Warehousing and Escrow Accounts',
                text: [
                    `We are pioneers in providing effective Escrow Warehousing methods in emerging markets to 
                    guarantee the deal execution between all the Parties. The goods will be released 
                    from Escrow Warehouse upon the conditions of the E-Contract are met.`,
                    `We are disrupting the market through our new escrow services and the unprecedented 
                    responsibility and support we offer all of our customers, offering escrow warehousing 
                    solutions in Tanzania and Ethiopia and financial escrow accounts in the reputable 
                    independent Pan-African banking institutions allowing our Importers to make any 
                    settlements in their local currencies.`,
                ],
                img: 'escrow-warehousing.png',
                alt_img: 'Core services',
            },
            {
                title: 'E-data Security and Confidentiality',
                text: `Unlike other e-platforms vulnerable to data and security breaches, Exportery has 
                a robust data and security architecture that protects both the Importers' 
                and Exporters' information from unauthorized access and pilferage. Our organization 
                upholds high confidentiality standards and does not share the contacts and operations 
                records of e-trading with third parties. Further, our databases and servers are 
                located in safe and neutral countries such as Switzerland and UAE, strengthening 
                our credibility and reliability.`,
                img: 'security.png',
                alt_img: 'Core services',
            },
        ],
        btn: 'More about core services',
        href: staticURLs.about_core_services,
    },
];