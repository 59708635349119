import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const CONFIRM_RFS_REQUEST = 'CONFIRM_RFS_REQUEST';
export const CONFIRM_RFS_SUCCESS = 'CONFIRM_RFS_SUCCESS';
export const CONFIRM_RFS_FAILURE = 'CONFIRM_RFS_FAILURE';

export const confirmRFSRequest = createRequestHandler('CONFIRM_RFS');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const confirmRFS = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CONFIRM_RFS_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case CONFIRM_RFS_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case CONFIRM_RFS_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_CONFIRM_RFS':
            return initialState;

        default:
            return state;
    }
};

export default confirmRFS;