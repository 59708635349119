import {createRequestHandler} from '../../../../utils';

export const SUGGEST_TITLES_REQUEST = 'SUGGEST_TITLES_REQUEST';
export const SUGGEST_TITLES_SUCCESS = 'SUGGEST_TITLES_SUCCESS';
export const SUGGEST_TITLES_FAILURE = 'SUGGEST_TITLES_FAILURE';

export const suggestTitlesRequest = createRequestHandler('SUGGEST_TITLES');

const initialState = {
    list: [],
    loading: false,
    error: null,
};

const suggestTitles = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SUGGEST_TITLES_REQUEST:
            return {
                list: [],
                loading: true,
                error: null,
            };

        case SUGGEST_TITLES_SUCCESS:
            return {
                list: payload.list,
                loading: false,
                error: null,
            };

        case SUGGEST_TITLES_FAILURE:
            return {
                list: [],
                loading: false,
                error: payload,
            };

        case 'RESET_SUGGESTED_TITLES':
            return initialState;

        default:
            return state;
    }
};

export default suggestTitles;