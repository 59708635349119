import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './checkbox.module.scss';

const Checkbox = (props) => {
    const {
        styleType, id, label, name, value, optionReadOnly, groupReadOnly,
        activeCheckboxList, classNames, onChange,
    } = props;
    const inputRef = useRef();

    const [checkboxValue, onSetCheckboxValue] = useState('');

    useEffect(() => {
        onSetCheckboxValue(value);

        if (inputRef.current) {
            inputRef.current.checked = activeCheckboxList.includes(value);

            if (optionReadOnly) {
                inputRef.current.checked = true;
            }
        }
    }, [value, activeCheckboxList, optionReadOnly]);

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames, {
        'without-text': !label,
        'read-only': optionReadOnly || groupReadOnly,
    });
    const checkboxClasses = cx('checkbox', {
        'secondary': styleType === 'SECONDARY',
        'with-icons': styleType === 'WITH_ICONS'
    });
    const labelClasses = cx('label', {'with-txt': label});

    return (
        <div className={containerClasses}>
            <input id={id}
                   ref={inputRef}
                   type="checkbox"
                   name={name}
                   value={checkboxValue}
                   readOnly={optionReadOnly || groupReadOnly}
                   className={checkboxClasses}
                   onChange={onChange}
            />
            <label htmlFor={id} className={labelClasses}>{label}</label>
        </div>
    );
};

Checkbox.defaultProps = {
    styleType: 'PRIMARY',
    value: 0,
    optionReadOnly: false,
    groupReadOnly: false,
    onChange: () => {
    },
};

Checkbox.propTypes = {
    styleType: PropTypes.oneOf(['PRIMARY', 'SECONDARY', 'WITH_ICONS']),
    id: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    optionReadOnly: PropTypes.bool,
    groupReadOnly: PropTypes.bool,
    activeCheckboxList: PropTypes.array,
    classNames: PropTypes.string,
    onChange: PropTypes.func,
};

export default Checkbox;