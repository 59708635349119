export const updatingDataAfterToggleCheckbox = (data, selectAllInvoiceItems = 1, isListBeSaved = true) => {
    const payload = {
        data,
        selectAllInvoiceItems,
        isInvoiceCanBeSaved: isListBeSaved,
    };

    return {type: 'UPDATE_ORIGIN_INVOICE_DATA', payload};
};

export const resetInvoiceToInitialState = () => {
    return {type: 'RESET_ORIGIN_INVOICE_DATA_TO_INITIAL_STATE'};
};

export const resetCreateOriginInvoice = () => {
    return {type: 'RESET_CREATE_ORIGIN_INVOICE'};
};