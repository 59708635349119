import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {switchMenuActiveLinkID} from '../../../../../actions/activity-hub';
import {withDataService} from '../../../../common/hoc';
import {withServices} from '../../../../service-context';
import {withHubSideMenu} from '../../common/hoc';
import {contractsListRequest} from '../../../../../reducers/activity-hub/e-contracts/contracts-list';
import {updatingContractsListRequest} from '../../../../../reducers/activity-hub/e-contracts/contracts-list';

import EContracts from './e-contracts';

class EContractsContainer extends Component {
    componentDidMount() {
        const {pageID, menuActiveLinkID} = this.props;
        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        this.props.fetchContractsList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchContractsListUpdating();
        }
    }

    contractPageUrl = (id) => `/hub/contract/${id}/map`;

    render() {
        return <EContracts {...this.props} contractPageUrl={this.contractPageUrl}/>;
    };

    static propTypes = {
        language: PropTypes.string,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        switchMenuActiveLinkID: PropTypes.func,
    };
}

const mapServicesToProps = ({eContractsService}) => ({
    fetchContractsList: contractsListRequest(eContractsService.getContractsList),
    fetchContractsListUpdating: updatingContractsListRequest(eContractsService.getContractsList),
});

const mapStateToProps = ({
                             profile: {language, userInteraction},
                             activityHub: {eContracts: {contractsList}, utils},
                         }) => ({
    language,
    userInteraction,
    menuActiveLinkID: utils.menuActiveLinkID,
    contractsList: contractsList.list,
    loading: contractsList.loading,
    updating: contractsList.updating,
    error: contractsList.error,
});

const mapDispatchToProps = (dispatch, {fetchContractsList, fetchContractsListUpdating}) => {
    return bindActionCreators({
        fetchContractsList,
        fetchContractsListUpdating,
        switchMenuActiveLinkID,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(EContractsContainer);