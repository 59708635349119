import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';
import FormFieldCreator from '../../../../../common/handlers/form-field-creator';

import Preloader from '../../../../../../../common/preloader';
import FormFieldConstructor from '../../../../../../../common/form-field-constructor';
import InputPnum from './components/input-pnum';
import Button from '../../../../../../../common/button';

import styles from './product-information.module.scss';

const ProductInformation = (props) => {
    const {
        staticData, id, formFields: {loading, updating},
        productInformationFields, loadingDataByPnum, isDisabledSection,
        isSaveButtonDisabled, formError, onInputChange, onDateChange,
        onClickSelectItem, onGroupCheckboxChange, onSelectAutoCompleteOptionsChange,
        onRadioButtonChange, onChangeSelectInputGroup, onSetPnumAutocomplete,
        onUpdateFieldsWithDataByPnum, onResetReadOnlyFieldsByPnum, onSave, onSkip,
    } = props;

    const {
        sections: {product_information: {sub_tooltip}},
        required_field_error_message,
        button: {save, skip},
    } = staticData.activity_hub.place_product.from_form;

    const formFieldCreator = new FormFieldCreator();
    const formFieldHandler = (formFields) => {
        const fields = formFieldCreator
            .getFormFields(formFields)
            .map((field, idx) => {
                if (Array.isArray(field)) {
                    let newField = [];

                    field.forEach(item => {
                        if (item.type !== 'checkbox') {
                            newField.push({
                                ...item,
                                tooltip: sub_tooltip[idx],
                            });
                        } else {
                            newField.push(item);
                        }
                    });

                    const isShown = newField[0].isShown !== undefined ? newField[0].isShown : true;

                    return {
                        fields: newField,
                        isShown,
                    };
                }

                return {
                    ...field,
                    tooltip: sub_tooltip[idx],
                };
            });

        /* case for category_ids[] fields */
        const checkboxGroupIdx = fields.findIndex(({type}) => type === 'checkbox');
        const checkboxGroupValues = fields[checkboxGroupIdx].value;

        return fields.map((field, idx) =>
            <FormFieldConstructor
                key={idx}
                field={field}
                onInputChange={onInputChange}
                onDateChange={onDateChange}
                onClickSelectItem={onClickSelectItem}
                selectAutoCompleteDisabled={checkboxGroupValues.length === 0}
                selectAutoCompleteClearWhenFixedOptions={true}
                onGroupCheckboxChange={onGroupCheckboxChange}
                onSelectAutoCompleteOptionsChange={onSelectAutoCompleteOptionsChange}
                onRadioButtonChange={onRadioButtonChange}
                onChangeSelectInputGroup={onChangeSelectInputGroup}/>,
        );
    };

    let pnumFields = null;
    let productInformationBlocks = [];

    if (productInformationFields) {
        pnumFields = productInformationFields.slice(0, 2);
        productInformationBlocks = formFieldHandler(productInformationFields.slice(2));
    }

    // section 1
    const partNumberBlock = <InputPnum fields={pnumFields}
                                       onSetPnumAutocomplete={onSetPnumAutocomplete}
                                       onUpdateFieldsWithDataByPnum={onUpdateFieldsWithDataByPnum}
                                       onResetReadOnlyFieldsByPnum={onResetReadOnlyFieldsByPnum}/>;
    const descriptionBlock = productInformationBlocks[0];
    const categoryBlock = productInformationBlocks[1];
    const selectAutocompleteBlock = productInformationBlocks.slice(2, 4);

    // section 2
    const conditionBlock = productInformationBlocks[4];
    const secondSectionFirstLine = productInformationBlocks.slice(5, 8);
    const secondSectionSecondLine = productInformationBlocks.slice(8, 11);
    const secondSectionThirdLine = productInformationBlocks.slice(11, 13);

    // section 3
    const thirdSectionFirstLine = productInformationBlocks.slice(13, 16);
    const thirdSectionSecondLine = productInformationBlocks.slice(16, 19);
    const thirdSectionThirdLine = productInformationBlocks.slice(19);

    return (loading || updating)
        ? <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
        : (
            <form id={`${id}_form`} className={styles.form}>
                {loadingDataByPnum && (
                    <div className={styles['section-hiding-container']}>
                        <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
                    </div>
                )}
                {/* section 1*/}
                <div className={styles.section}>
                    <div className={`${styles.group} ${styles['grid-group']} ${styles['group-even-2']}`}>
                        {partNumberBlock}
                    </div>
                    <div className={styles.group}>{descriptionBlock}</div>
                    <div className={styles['checkbox-group']}>{categoryBlock}</div>
                    <div className={styles.group}>{selectAutocompleteBlock[0]}</div>
                    <div className={styles.group}>{selectAutocompleteBlock[1]}</div>
                </div>

                {/* section 2*/}
                <div className={`${styles.section} ${styles['section-second']}`}>
                    <div className={styles['radio-group']}>{conditionBlock}</div>
                    <div className={`${styles['grid-group-of-three']} ${styles['group-2']}`}>
                        {secondSectionFirstLine}
                    </div>
                    <div className={`${styles['grid-group']} ${styles['group-even-3']}`}>{secondSectionSecondLine}</div>
                    <div className={`${styles['grid-group']} ${styles['group-2']}`}>{secondSectionThirdLine}</div>
                </div>

                {/* section 3*/}
                <div className={`${styles.group} ${styles['grid-group']} ${styles['group-even-3']}`}>
                    {thirdSectionFirstLine}
                </div>
                <div className={`${styles.group} ${styles['grid-group']} ${styles['group-even-3']}`}>
                    {thirdSectionSecondLine}
                </div>
                <div className={`${styles['grid-group']} ${styles['group-even-3']}`}>{thirdSectionThirdLine}</div>

                {!isDisabledSection && (
                    <div className={styles['btn-group']}>
                        {formError && <div className={styles['error-text']}>{required_field_error_message}</div>}

                        <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                        <Button classNames={styles['btn-submit']}
                                isDisabled={isSaveButtonDisabled}
                                onClick={onSave}>
                            {save}
                        </Button>
                    </div>
                )}
            </form>
        );
};

ProductInformation.defaultProps = {
    isDisabledSection: false,
    formError: false,
    onSave: () => {
    },
    onSkip: () => {
    },
};

ProductInformation.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.string,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    loadingDataByPnum: PropTypes.bool,
    isDisabledSection: PropTypes.bool,
    productInformationFields: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    isSaveButtonDisabled: PropTypes.bool,
    formError: PropTypes.bool,
    onInputChange: PropTypes.func,
    onDateChange: PropTypes.func,
    onClickSelectItem: PropTypes.func,
    onGroupCheckboxChange: PropTypes.func,
    onSelectAutoCompleteOptionsChange: PropTypes.func,
    onRadioButtonChange: PropTypes.func,
    onChangeSelectInputGroup: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
};

export default withSectionPanel()(ProductInformation);