import {createRequestHandler} from '../../../../utils';

export const CONDITION_DATA_REQUEST = 'CONDITION_DATA_REQUEST';
export const CONDITION_DATA_SUCCESS = 'CONDITION_DATA_SUCCESS';
export const CONDITION_DATA_FAILURE = 'CONDITION_DATA_FAILURE';

export const CONDITION_DATA_UPDATING_REQUEST = 'CONDITION_DATA_UPDATING_REQUEST';
export const CONDITION_DATA_UPDATING_SUCCESS = 'CONDITION_DATA_UPDATING_SUCCESS';
export const CONDITION_DATA_UPDATING_FAILURE = 'CONDITION_DATA_UPDATING_FAILURE';

export const conditionDataRequest = createRequestHandler('CONDITION_DATA');
export const updatingConditionDataRequest = createRequestHandler('CONDITION_DATA_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    data: null,
    error: null,
    review: {turnOn: false, clickable: 0},
};

const onInitCondition = (payload) => {
    const {condition} = payload;

    const updatedConditionList = condition.map(({list, ...rest}) => {
        const newList = list.map((item) => item.is_editable ? {...item, isActive: 1} : item);
        return {
            ...rest,
            list: newList,
        };
    });

    return {
        ...payload,
        condition: updatedConditionList,
    };
};

const onUpdatedCondition = (payload, data) => {
    const {condition} = payload;
    const {condition: prevCondition} = data;

    const updatedConditionList = prevCondition.map(({list: prevList}, idx) => {
        const {list, ...rest} = condition[idx]; // current list after updated
        const newList = prevList.map((item, idx) => ({...item, ...list[idx]}));
        return {
            ...rest,
            list: newList,
        };
    });

    return {
        ...payload,
        condition: updatedConditionList,
    };
};

const updateReviewClickableValue = ({clickable, ...rest}, isActive) => {
    /** original contract is contract with all active option
     when we change (turn off) option we need to set clickable + 1 (count changes)
     when we get clickable = 0 it means that contract did not changed
     **/
    return {
        ...rest,
        clickable: isActive ? clickable - 1 : clickable + 1,
    };
};

const onUpdatingConditionOption = ({id, isActive, listIdx}, state) => {
    const {data: {condition, ...dataRest}, review, ...rest} = state;

    const updatedList = condition.map((item, idx) => {
        if (idx === listIdx) {
            const {list, ...rest} = item;
            const newList = list.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isActive,
                    };
                }

                return item;
            });

            return {
                ...rest,
                list: newList,
            };
        }

        return item;
    });

    return {
        ...rest,
        data: {
            ...dataRest,
            condition: updatedList,
        },
        review: updateReviewClickableValue(review, isActive),
    };
};

const conditionData = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CONDITION_DATA_REQUEST:
            return initialState;

        case CONDITION_DATA_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case CONDITION_DATA_SUCCESS:
            return {
                ...initialState,
                data: onInitCondition(payload),
                loading: false,
            };

        case CONDITION_DATA_UPDATING_SUCCESS:
            return {
                ...state,
                data: onUpdatedCondition(payload, state.data),
                updating: false,
            };

        case CONDITION_DATA_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case CONDITION_DATA_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'TOGGLE_REVIEW_MODE':
            return {
                ...state,
                review: {
                    ...state.review,
                    turnOn: payload,
                },
            };

        case 'UPDATE_CONDITION_OPTION':
            return onUpdatingConditionOption(payload, state);

        default:
            return state;
    }
};

export default conditionData;