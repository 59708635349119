import React from 'react';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../common/hoc';
import {withAccordionPages} from '../../../../common/hoc';

import SubQuotation from './components/sub-quotation';
import Quotation from './components/quotation';
import Preloader from '../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './requests-for-quotations-mobile.module.scss';

const SubQuotationItemList = ({subQuotations, ...rest}) => {
    return subQuotations.map((item, idx) => {
        return <SubQuotation key={idx} {...item} {...rest}/>;
    });
};

const EnhancedComponent = withAccordion()(SubQuotationItemList);

const RequestsForQuotationsMobile = (props) => {
    const {
        staticData, loading: listLoading, quotationsList, removingItem,
        updatingItem, onButtonClick, onRemoveSubQuotationItem,
    } = props;
    const {empty_text} = staticData.activity_hub.requests_for_quotations;
    const cx = classnames.bind(styles);

    return !listLoading ?
        quotationsList
            ? (quotationsList.map(({id, list: subQuotations, ...rest}, idx) => {
                const quotationItemClasses = cx('item', {'expired-item': subQuotations.length === 0});
                const quotationItem = <Quotation {...rest} key={idx} staticData={staticData}
                                                 classNames={quotationItemClasses}/>;

                const commonSettings = {
                    key: idx,
                    subQuotations: subQuotations,
                    label: quotationItem,
                    containerActiveClassNames: styles['accordion-container-active'],
                    containerClassNames: styles['accordion-container'],
                    labelClassNames: styles['accordion-label'],
                    contentClassNames: styles['accordion-content'],
                    onRemoveSubQuotationItem: (offer_id) => onRemoveSubQuotationItem(id, offer_id),
                    onButtonClick: (...args) => onButtonClick(id, ...args),
                    withActiveContainer: true,
                };

                if (subQuotations.length !== 0) {
                    const additionalSettings = {
                        removingItem: removingItem && id === removingItem.rfq_id ? removingItem : null,
                        updatingItem: updatingItem && id === updatingItem.rfq_id ? updatingItem : null,
                    };

                    return <EnhancedComponent {...commonSettings} {...additionalSettings}/>;
                }

                return quotationItem;
            }))
            : <div className={styles.empty}>{empty_text}</div>
        : (
            <div className={styles.empty}>
                <Preloader type={'SECONDARY'} classNames={styles.preloader}/>
            </div>
        );
};

SubQuotationItemList.propTypes = {
    subQuotations: PropTypes.arrayOf(PropTypes.object),
};

RequestsForQuotationsMobile.propTypes = {
    staticData: PropTypes.object,
    listLoading: PropTypes.bool,
    quotationsList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    removingItem: PropTypes.shape({
        rfq_id: PropTypes.number,
    }),
};

export default withAccordionPages()(RequestsForQuotationsMobile);