import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';
import {priceStringToNumber} from '../../../../../../../../../utils';

import classnames from 'classnames/bind';
import styles from './contract-progress-bar.module.scss';

const ContractProgressBar = (props) => {
    const {progress: {amount, label, list}, totalPrice: {total, currency}, staticData} = props;
    const totalNumber = priceStringToNumber(total);
    const {total_label} = staticData.activity_hub.e_contracts.pages.contract_for_supply;

    const [totalSum, setTotalSum] = useState(0);
    const [progressList, setProgressList] = useState(null);
    const [progressLineWidth, setProgressLineWidth] = useState(null);

    useEffect(() => {
        let sum = 0;
        let kLeft = 0;
        const newProgress = list
            .map((lot) => {
                const {total} = lot;
                const lotTotalNumber = priceStringToNumber(total);
                sum += lotTotalNumber;

                return {
                    ...lot,
                    lotTotalNumber,
                };
            })
            .map((lot) => {
                const {lotTotalNumber} = lot;
                const shiftLeft = kLeft;
                let width = +(lotTotalNumber * 100 / totalNumber).toFixed(2);

                /* 10% + 90% = 100% of line */
                const k = 10;
                if (width <= 10 && width !== 0) {
                    width = width + k;
                }

                if (width >= 90 && width !== 100) {
                    width = width - k;
                }

                kLeft += width;

                return {
                    ...lot,
                    width,
                    shiftLeft,
                };
            });

        if (sum !== totalNumber) {
            // 100% of totalPrice - %sum of totalPrice = coefficientByTotalPrice
            const coefficientByTotalPrice = 100 - (sum * 100 / totalNumber);
            if (coefficientByTotalPrice <= 5) {
                setProgressLineWidth(95);
            }
        }

        setProgressList(newProgress);
        setTotalSum(sum);
    }, [list, totalNumber]);

    const createProgressBlock = () => {
        const cx = classnames.bind(styles);
        let stepBlock = null;
        let progressBlockClasses = cx('progress-base-line');

        if (list.length !== 0) {
            const progressBaseLineBeforeCircleStatus = list[0].status;
            const progressBaseLineBeforeCircleClass =
                progressBaseLineBeforeCircleStatus !== 'pending'
                    ? `before-circle-${progressBaseLineBeforeCircleStatus}`
                    : null;

            progressBlockClasses = cx('progress-base-line', progressBaseLineBeforeCircleClass);

            stepBlock = progressList && progressList.map((lot, idx) => {
                const {label, total, status, width, shiftLeft, currency} = lot;

                const stepClasses = cx('step', [`${status}`], {
                    'after-text-with-line': idx % 2 !== 0,
                    'filled': totalSum === totalNumber,
                });

                const zIndex = progressList.length - idx;

                return (
                    <div key={idx} className={stepClasses}
                         style={{width: `${width}%`, left: `${shiftLeft}%`, zIndex: zIndex}}>
                        <div className={styles.lot}>{label}</div>
                        <div className={styles.total}>{total} {currency}</div>
                    </div>
                );
            });
        }

        return (
            <div className={styles['progress-block']}>
                <div className={progressBlockClasses}>
                    <div className={styles['progress-line']} style={{width: `${progressLineWidth}%`}}>
                        {stepBlock}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles['title-block']}>
                <div>{label}</div>
                <div>{total_label}</div>
            </div>

            <div className={styles['total-block']}>
                <div>{amount}%</div>
                <div>{total} {currency}</div>
            </div>

            {createProgressBlock()}
        </div>
    );
};

ContractProgressBar.propTypes = {
    progress: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
            amount: PropTypes.number,
            label: PropTypes.string,
            list: PropTypes.arrayOf(PropTypes.shape({
                label: PropTypes.string,
                total: PropTypes.string,
                status: PropTypes.string,
                currency: PropTypes.string,
            })),
        })]),
    totalPrice: PropTypes.shape({
        total: PropTypes.string,
        currency: PropTypes.string,
    }),
    staticData: PropTypes.object,
};

export default withDataService()(ContractProgressBar);