export default class UserService {
    constructor(httpService) {
        this._http = httpService;
    }

    getSession = () => {
        return this._http.get('');
    };

    login = (credentials) => {
        return this._http.post('/login', credentials);
    };

    twoFactor = (url, credentials) => {
        return this._http.post('/two-factor', credentials);
    };

    getProfile = () => {
        return this._http.get('/profile');
    };

    logout = () => {
        return this._http.get('/logout');
    };
}