import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';

import {resetMarketplaceProducts} from '../../../../../actions/marketplace';
import {updateSearchCategorySlug} from '../../../../../actions/common/search';
import {withServices} from '../../../../service-context';
import {categoriesRequest} from '../../../../../reducers/marketplace/home/categories';
import {categoryProductsRequest, updatingProductsRequest} from '../../../../../reducers/marketplace/products';

import CategoryPage from './category-page';

class CategoryPageContainer extends Component {
    render() {
        return <CategoryPage {...this.props}/>;
    }
}

const mapServicesToProps = ({marketplaceService}) => ({
    fetchCategories: categoriesRequest(marketplaceService.getCategories),
    fetchCategoryProducts: categoryProductsRequest(marketplaceService.getCategoryProducts),
    fetchUpdatingProducts: updatingProductsRequest(marketplaceService.getCategoryProducts),
});

const mapStateToProps = ({profile: {language}, marketplace: {products, categories: {categories}}}) => {
    return ({
        products,
        categories,
        language,
    });
};

const mapDispatchToProps = (dispatch, {
    fetchCategories,
    fetchCategoryProducts,
    fetchUpdatingProducts,
}) => {
    return bindActionCreators({
        fetchCategories,
        fetchCategoryProducts,
        fetchUpdatingProducts,
        updateSearchCategorySlug,
        resetMarketplaceProducts,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(CategoryPageContainer);