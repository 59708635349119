import {combineReducers} from 'redux';

import {bankAccountReducer} from './bank-account';
import {shipmentOrganizationReducer} from './shipment-organization';
import {accessSetupReducer} from "./access-setup";
import dataList from './data-list';
import uploadProfileForm from './upload-profile-form';

export const profileReducer = combineReducers({
    bankAccount: bankAccountReducer,
    shipmentOrganization: shipmentOrganizationReducer,
    accessSetup: accessSetupReducer,
    dataList,
    uploadProfileForm,
});