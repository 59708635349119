import {createRequestHandler, createAlertResponse} from '../../../utils';

export const ADD_TO_QUOTATION_REQUEST = 'ADD_TO_QUOTATION_REQUEST';
export const ADD_TO_QUOTATION_SUCCESS = 'ADD_TO_QUOTATION_SUCCESS';
export const ADD_TO_QUOTATION_FAILURE = 'ADD_TO_QUOTATION_FAILURE';

export const addToQuotationRequest = createRequestHandler('ADD_TO_QUOTATION');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const addToQuotation = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case ADD_TO_QUOTATION_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case ADD_TO_QUOTATION_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case ADD_TO_QUOTATION_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_ADD_TO_QUOTATION':
            return initialState;

        default:
            return state;
    }
};

export default addToQuotation;