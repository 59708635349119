import {createRequestHandler} from '../../../utils';

export const NEWS_ARTICLE_REQUEST = 'NEWS_ARTICLE_REQUEST';
export const NEWS_ARTICLE_SUCCESS = 'NEWS_ARTICLE_SUCCESS';
export const NEWS_ARTICLE_FAILURE = 'NEWS_ARTICLE_FAILURE';

export const newsArticleRequest = createRequestHandler('NEWS_ARTICLE');

const initialState = {
    item: null,
    loading: false,
    error: null,
};

const article = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case NEWS_ARTICLE_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case NEWS_ARTICLE_SUCCESS:
            return {
                ...initialState,
                item: payload,
            };

        case NEWS_ARTICLE_FAILURE:
            return {
                ...initialState,
                error: payload,
            };

        default:
            return state;
    }
};

export default article;