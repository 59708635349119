import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const CREATE_ORIGIN_INVOICE_REQUEST = 'CREATE_ORIGIN_INVOICE_REQUEST';
export const CREATE_ORIGIN_INVOICE_SUCCESS = 'CREATE_ORIGIN_INVOICE_SUCCESS';
export const CREATE_ORIGIN_INVOICE_FAILURE = 'CREATE_ORIGIN_INVOICE_FAILURE';

export const createOriginInvoiceRequest = createRequestHandler('CREATE_ORIGIN_INVOICE');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const createOriginInvoice = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CREATE_ORIGIN_INVOICE_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case CREATE_ORIGIN_INVOICE_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case CREATE_ORIGIN_INVOICE_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_CREATE_ORIGIN_INVOICE':
            return initialState;

        default:
            return state;
    }
};

export default createOriginInvoice;