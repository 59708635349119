import React from 'react';
import PropTypes from 'prop-types';

import Chip from '../../chip';

import styles from './filter-options.module.scss';

const FilterOptions = ({filterID, options, isUpdating, toggleOptions}) => {
    const optionCreator = (option, idx) => {
        const {name, count, href, isActive} = option;

        return (
            <Chip
                key={idx}
                classNames={styles['option-item']}
                isActive={isActive}
                isDisable={isUpdating}
                onClick={(e) => {
                    e.stopPropagation();
                    toggleOptions(href);
                }}>
                {name} ({count})
            </Chip>
        );
    };

    if (filterID === 'title') {
        let filterTitleOptions = [];

        const numOfSections = options.map(option => option.section);
        const numOfSectionsMin = Math.min(...numOfSections);
        const numOfSectionsMax = Math.max(...numOfSections);

        for (let i = numOfSectionsMin; i <= numOfSectionsMax; i++) {
            filterTitleOptions.push(options.filter(option => option.section === i));
        }

        const existOptionsInSection = filterTitleOptions.filter(options => options.length !== 0);

        if (existOptionsInSection.length === 1) {
            return existOptionsInSection.map(options => options.map((option, idx) => optionCreator(option, idx)));
        }

        return (
            <div className={styles['options-block']}>
                {existOptionsInSection.map((options, idx) => {
                    return (
                        <div key={idx} className={styles.section}>
                            {options.map((option, idx) => optionCreator(option, idx))}
                        </div>
                    );
                })}
            </div>
        );
    }

    return options.length !== 0 ? options.map((option, idx) => optionCreator(option, idx)) : null;
};

FilterOptions.defaultProps = {
    isUpdating: false,
    toggleOptions: () => {
    },
};

FilterOptions.propTypes = {
    filterID: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        count: PropTypes.number,
        href: PropTypes.string,
        isActive: PropTypes.bool,
    })).isRequired,
    isUpdating: PropTypes.bool,
    toggleOptions: PropTypes.func,
};

export default FilterOptions;