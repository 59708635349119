const cookieStringToObject = () => {
    const allCookies = document.cookie;
    const allCookiesToArray = allCookies.split('; ');
    let newCookiesArray = {};

    for (let i = 0; i < allCookiesToArray.length; i++) {
        const arrayItem = allCookiesToArray[i].split('=');

        newCookiesArray = {
            ...newCookiesArray,
            [arrayItem[0]]: arrayItem[1],
        };
    }

    return newCookiesArray;
};

export default cookieStringToObject;