import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';
import {withServices} from '../../../../service-context';
import {productsRequest, updatingProductsRequest} from '../../../../../reducers/marketplace/products';
import {categoriesRequest} from '../../../../../reducers/marketplace/home/categories';

import MarketplaceHome from '../../../../marketplace/components/pages/marketplace-home/marketplace-home';

class MarketplaceHomePublicContainer extends Component {
    componentDidMount() {
        const {session, location: {search: params}} = this.props;
        if (!session && session !== null) {
            this.props.fetchCategories();
            this.props.fetchAllProducts(params);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {location: {search: params}} = this.props;
            this.props.fetchCategories();
            this.props.fetchAllProducts(params);
        }

        if (prevProps.location.search !== this.props.location.search) {
            const {location: {search: params}} = this.props;
            this.props.fetchUpdatingAllProducts(params);
        }
    }

    render() {
        return <MarketplaceHome {...this.props}/>;
    }

    static propTypes = {
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        language: PropTypes.string,
        location: PropTypes.shape({
            search: PropTypes.string,
        }),
        fetchCategories: PropTypes.func,
        fetchAllProducts: PropTypes.func,
        fetchUpdatingAllProducts: PropTypes.func,
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchCategories: categoriesRequest(publicService.getPublicCategories),
    fetchAllProducts: productsRequest(publicService.getPublicProducts),
    fetchUpdatingAllProducts: updatingProductsRequest(publicService.getPublicProducts),
});

const mapStateToProps = ({publicData: {homePage: {language}}, session: {session}, marketplace}) => {
    const {categories: {categories}, products} = marketplace;
    return {
        language,
        session,
        categories,
        products,
        loading: products.loading,
        list: products.products,
    };
};

const mapDispatchToProps = (dispatch, {
    fetchCategories,
    fetchAllProducts,
    fetchUpdatingAllProducts,
}) => {
    return bindActionCreators({
        fetchCategories,
        fetchAllProducts,
        fetchUpdatingAllProducts,
    }, dispatch);
};

export default compose(
    withDataService(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(MarketplaceHomePublicContainer);