import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import PropTypes from 'prop-types';

import {hubURLs} from '../../../../../services/api-urls';
import {withDataService} from '../../../../common/hoc';
import {withHubSideMenu} from '../../common/hoc';
import {withServices} from '../../../../service-context';
import {fileUploadRequest} from '../../../../../reducers/file-upload';
import {batchUploadFormRequest} from '../../../../../reducers/batch-upload-form';
import {uploadFileHandler} from '../../../../../utils';
import {switchMenuActiveLinkID} from '../../../../../actions/activity-hub';
import {resetFileUpload} from '../../../../../actions/common';
import sweetAlert from '../../../../common/sweet-alert';

import PlaceProductFromExcel from './place-product-from-excel';

class PlaceProductFromExcelContainer extends Component {
    initialState = {
        file: null,
        fileProgress: {
            file: '',
            value: 0,
        },
        isFileLoaded: false,
        toMyProducts: false,
    };

    state = this.initialState;

    componentDidMount() {
        const {pageID, menuActiveLinkID} = this.props;
        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        this.props.fetchBatchUploadForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchBatchUploadForm();
        }

        if (prevProps.uploadXLSResponse !== this.props.uploadXLSResponse) {
            const {list, error} = this.props.uploadXLSResponse;
            if (list) {
                this.setState({toMyProducts: true});
            }

            if (error) {
                sweetAlert('error', error).then(() => this.onInitPage());
            }
        }
    }

    onInitPage = () => {
        this.setState(this.initialState);
        this.onScrollToTop();
        this.onResetFileUploadResponse();
    };

    onResetFileUploadResponse = () => {
        const {list, error} = this.props.uploadXLSResponse;

        if (list || error) {
            this.props.resetFileUpload();
        }
    };

    onScrollToTop = () => {
        if (window.scrollY > 0) {
            window.scrollTo({top: 0});
        }
    };

    onAcceptedFiles = (acceptedFiles) => {
        this.setState({file: acceptedFiles[0]});
        uploadFileHandler(acceptedFiles, this.onLoadingFile, this.onLoadedFile);
    };

    onRejectedFiles = (rejectedFiles) => {
        if (rejectedFiles.length !== 0) {
            this.setState({file: rejectedFiles[0]});
        }
    };

    onLoadingFile = (file, progress) => this.setState({fileProgress: {file, value: progress}});

    onLoadedFile = () => this.setState({isFileLoaded: true});

    onDeleteFile = () => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.onInitPage();
                }
            });
    };

    onClearAll = () => {
        const {clear_all_question} = this.props.staticData.alert_modal;

        sweetAlert('question', clear_all_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.onInitPage();
                }
            });
    };

    onUploadXLSFile = ({method, url}) => {
        if (method === 'POST') {
            const {file} = this.state;
            this.props.uploadXLSFile(url, {fileData: file});
        }
    };

    onGoToProducts = () => this.props.history.push(hubURLs.products);

    componentWillUnmount() {
        this.onResetFileUploadResponse();
    }

    render() {
        return <PlaceProductFromExcel {...this.props} {...this.state}
                                      id="place-product-excel-upload"
                                      onDeleteFile={this.onDeleteFile}
                                      onAcceptedFiles={this.onAcceptedFiles}
                                      onRejectedFiles={this.onRejectedFiles}
                                      onClearAll={this.onClearAll}
                                      onGoToProducts={this.onGoToProducts}
                                      onUploadXLSFile={this.onUploadXLSFile}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        uploadXLSResponse: PropTypes.object,
        switchMenuActiveLinkID: PropTypes.func,
        fetchBatchUploadForm: PropTypes.func,
        uploadXLSFile: PropTypes.func,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        resetFileUpload: PropTypes.func,
    };
}

const mapServicesToProps = ({placeProductService}) => ({
    fetchBatchUploadForm: batchUploadFormRequest(placeProductService.uploadFileActions),
    uploadXLSFile: fileUploadRequest(placeProductService.uploadFileActions),
});

const mapStateToProps = ({activityHub: {utils}, profile: {language}, batchUploadForm, fileUpload}) => ({
    language,
    loading: batchUploadForm.loading,
    data: batchUploadForm.data,
    uploadXLSLoading: fileUpload.loading,
    uploadXLSResponse: fileUpload,
    menuActiveLinkID: utils.menuActiveLinkID,
});

const mapDispatchToProps = (dispatch, {
    fetchBatchUploadForm,
    uploadXLSFile,
}) => {
    return bindActionCreators({
        fetchBatchUploadForm,
        uploadXLSFile,
        switchMenuActiveLinkID,
        resetFileUpload,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(PlaceProductFromExcelContainer);