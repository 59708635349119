import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {onChangeFieldValue} from '../../activity-hub/components/common/handlers';
import {getSelectedItemFromSelectList} from '../../../utils/form-field-builder/handlers';

import Select from '../select';
import InputField from '../input-field';
import Checkbox from '../checkbox';

import classnames from 'classnames/bind';
import styles from './selection-or-input-field.module.scss';

class SelectOrInputField extends Component {
    state = {
        isSelectOpen: false,
    };

    onUpdatedSelectInputFieldTypes = (fields) => {
        const isSelectFieldActive = fields.find(({type}) => type === 'checkbox').value;

        if (!isSelectFieldActive) {
            return fields.map((field) => {
                const {type, ...rest} = field;

                if (type === 'select') {
                    return {
                        ...rest,
                        type: 'hidden',
                    };
                }

                if (type === 'hidden') {
                    return {
                        ...rest,
                        type: 'text',
                    };
                }

                return field;
            });

        } else {
            return fields.map((field) => {
                const {type, ...rest} = field;

                if (type === 'text') {
                    return {
                        ...rest,
                        type: 'hidden',
                    };
                }

                if (type === 'hidden') {
                    return {
                        ...rest,
                        type: 'select',
                    };
                }

                return field;
            });
        }
    };

    onInputChange = ({target: {name, value}}) => {
        const newFields = onChangeFieldValue(this.props.fields, name, value);
        this.props.onChangeSelectInputGroup(newFields);
    };

    onCheckboxChange = ({target: {checked}}) => {
        const {fields} = this.props;
        const checkboxIdx = fields.findIndex(({type}) => type === 'checkbox');
        let newCheckbox = fields[checkboxIdx];

        if (!checked) {
            newCheckbox = {
                ...fields[checkboxIdx],
                value: 1, // is select field active
            };
        } else {
            newCheckbox = {
                ...fields[checkboxIdx],
                value: 0, // is select field not active
            };
        }

        const newFields = [
            ...fields.slice(0, checkboxIdx),
            newCheckbox,
            ...fields.slice(checkboxIdx + 1),
        ];

        const transformFields = this.onUpdatedSelectInputFieldTypes(newFields, !checked);
        this.props.onChangeSelectInputGroup(transformFields);
    };

    onClickSelectItem = (fieldName, {value}) => {
        const newFields = onChangeFieldValue(this.props.fields, fieldName, value);
        this.props.onChangeSelectInputGroup(newFields);
    };

    render() {
        const {isSelectOpen} = this.state;
        const {fields} = this.props;
        const cx = classnames.bind(styles);

        return (
            <div className={styles.container}>
                {fields && fields.map((field, idx) => {
                    const {type, group, value, list, ...rest} = field;

                    if (type === 'select') {
                        if (!list || list.length === 0) {
                            return null;
                        }

                        const items = list.map(({label, value}, idx) => {
                            const className = cx({
                                'item-in-list-active': value === field.value,
                                'item-in-list-not-available': value === 'default',
                            });
                            return (
                                <li key={idx}
                                    className={className}
                                    onClick={() => {
                                        this.setState(({isSelectOpen}) => ({isSelectOpen: !isSelectOpen}));
                                        this.onClickSelectItem(field.name, {label, value});
                                    }}>
                                    {label}
                                </li>
                            );
                        });

                        const selectedItemLabel = getSelectedItemFromSelectList(list, field.value).label;
                        const props = list.length > 10
                            ? field.props ? [...field.props, 'search'] : ['search']
                            : field.props || [];

                        return (
                            <div key={idx} className={styles['select-container']}>
                                <Select {...rest}
                                        type={'SECONDARY'}
                                        selectedItem={selectedItemLabel}
                                        isOpenSelect={isSelectOpen}
                                        props={props}
                                        selectedItemClasses={styles['select-item']}
                                        onToggleSelect={(isOpen) => this.setState({isSelectOpen: isOpen})}
                                        zIndex={2}
                                        withLabel>
                                    {items}
                                </Select>
                            </div>
                        );
                    }

                    if (type === 'text') {
                        return <InputField key={idx} {...rest} value={value} onInputChange={this.onInputChange}/>;
                    }

                    if (type === 'checkbox') {
                        return (
                            <Checkbox
                                key={idx}
                                {...rest}
                                id={group}
                                classNames={styles.checkbox}
                                // if value = 1 - select active, but checkbox not checked
                                activeCheckboxList={value !== 1 ? [value] : []}
                                value={value}
                                optionReadOnly={field.hidden || false}
                                onChange={this.onCheckboxChange}/>
                        );
                    }

                    return null;
                })}
            </div>
        );
    }

    static defaultProps = {
        onChangeSelectInputGroup: () => {
        },
    };

    static propTypes = {
        fields: PropTypes.array,
        onChangeSelectInputGroup: PropTypes.func,
    };
}

export default SelectOrInputField;