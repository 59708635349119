export const togglePrintAllLabelsMode = (payload) => {
    return {type: 'TOGGLE_PRINT_ALL_LABELS_MODE', payload};
};

export const toggleLabelCheckbox = (payload) => {
    return {type: 'TOGGLE_LABEL_CHECKBOX', payload};
};

export const togglePrintLabelsMode = (payload) => {
    return {type: 'TOGGLE_PRINT_LABELS_WITH_LINE_MODE', payload};
};