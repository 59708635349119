import {onGetDataFromPackingFormFields} from '../../../../reducers/activity-hub/contract-maps/packing-list-wizard/packing-form';

export const updatePackingForm = (newList, formMode) => {
    const data = onGetDataFromPackingFormFields(newList);
    const payload = {list: newList, data};

    if (formMode === 'EDIT') {
        return {
            type: 'UPDATE_PACKING_EDITING_FORM',
            payload,
        };
    } else {
        return {
            type: 'UPDATE_PACKING_FORM',
            payload,
        };
    }
};

export const updatePlaceEditingID = (newID) => {
    return {
        type: 'UPDATE_PLACE_EDITING_ID',
        payload: newID,
    };
};

export const resetPackingForm = () => {
    return {type: 'RESET_PACKING_FORM'};
};

export const resetPackingFormUpdating = () => {
    return {type: 'RESET_PACKING_FORM_UPDATING'};
};

export const resetCreatePackingPlace = () => {
    return {type: 'RESET_CREATE_PACKING_PLACE'};
};

export const resetPackingEditingForm = () => {
    return {type: 'RESET_PACKING_EDITING_FORM'};
};

export const resetEditPackingPlace = () => {
    return {type: 'RESET_EDIT_PACKING_PLACE'};
};