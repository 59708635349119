import {languages} from '../../services/language-service';

const initialState = {
    language: 'en',
    languagesList: languages,
};

const updateLanguagesList = (prevList, code) => {
    const newList = prevList.map((item) => {
        if (item.code === code) {
            return {
                ...item,
                isActive: true,
            };
        }

        return {
            ...item,
            isActive: false,
        };
    });

    return newList;
}

const homePage = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case 'SWITCH_HOME_PAGE_LANGUAGE':
            const newLanguagesList = updateLanguagesList(state.languagesList, payload);

            return {
                language: payload,
                languagesList: newLanguagesList
            };

        default:
            return state;
    }
};

export default homePage;