import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import {editorConfiguration} from './plugins';
import './translations';

import classnames from 'classnames/bind';
import styles from './text-editor.module.scss';

class TextEditor extends Component {
    state = {
        editor: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.editor !== this.state.editor) {
            if (this.state.editor) {
                const {value} = this.props;
                if (value && value !== '') {
                    this.state.editor.setData(value);
                }
            }
        }
    }

    onChange = () => {
        const data = this.state.editor.getData();
        this.props.onTextEditorDataChange(data);
    };

    onError = (error, details) => {
        console.log(error);
        console.log(details.phase, `Editor will restart itself: ${details.willEditorRestart}`);
    };

    componentWillUnmount() {
        this.setState({editor: null});
    }

    render() {
        const {containerClassNames, label, textEditorReadOnly, language} = this.props;

        const cx = classnames.bind(styles);
        const containerClasses = cx('container', containerClassNames, {'read-only': textEditorReadOnly});

        return (
            <div className={containerClasses}>
                <div className={styles.label}>{label}</div>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        ...editorConfiguration,

                        // editor ui language
                        language,
                        // content language
                        content: language,
                    }}
                    disabled={textEditorReadOnly}
                    onReady={(editor) => {
                        this.setState({editor});
                        // console.log(Array.from(editor.ui.componentFactory.names()));
                    }}
                    onChange={this.onChange}
                    onError={this.onError}
                />
            </div>
        );
    }

    static defaultProps = {
        label: false,
        textEditorReadOnly: false,
    };

    static propTypes = {
        containerClassNames: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        textEditorReadOnly: PropTypes.bool,
        value: PropTypes.string,
    };
}

const mapStateToProps = ({profile: {language}}) => ({
    language,
});

export default connect(mapStateToProps)(TextEditor);