import React from 'react';

const ServiceContext = React.createContext();

const {
    Provider: ServiceProvider,
    Consumer: ServiceConsumer,
} = ServiceContext;

const withServices = (mapServicesToProps) => (Wrapped) => {
    return (props) => {
        return (
            <ServiceConsumer>
                {
                    (services) => {
                        const serviceProps = mapServicesToProps(services);

                        return (
                            <Wrapped {...props} {...serviceProps} />
                        );
                    }
                }
            </ServiceConsumer>
        );
    };
};

export {
    ServiceProvider,
    ServiceConsumer,
    ServiceContext,
    withServices,
};