import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import BreadcrumbsConstructor from '../../../services/breadcrumbs';

import SkeletonPreloader from '../skeleton-preloader';

import classNames from 'classnames/bind';
import styles from './breadcrumbs.module.scss';

const Breadcrumbs = ({staticData: breadcrumbs}) => {
    let {
        router: {location}, breadcrumbs: {loading, list}, errors,
    } = useSelector(({
                         router,
                         utils: {
                             breadcrumbs, serverError,
                             notFoundError, notAcceptableError,
                         },
                     }) => ({
        router,
        breadcrumbs,
        errors: {serverError, notAcceptableError},
    }));

    list = new BreadcrumbsConstructor()
        .checkingBreadcrumbsStatic(location, breadcrumbs, list, errors);

    const cx = classNames.bind(styles);
    const items = list.map(({label, href, isActive}, idx) => {
        const linkClasses = cx('link', {'link-active': isActive});
        const delimiterClasses = cx('delimiter', {
            'delimiter-active': isActive && idx !== list.length - 2,
        });

        if (!isActive) {
            return <div key={idx} className={styles.link}>{label}</div>;
        }

        return (
            <div key={idx}>
                <Link to={href} className={linkClasses}>{label}</Link>
                <span className={delimiterClasses}>/</span>
            </div>
        );
    });

    return (
        <div className={cx('breadcrumbs', {'loading': loading})} data-name="breadcrumbs">
            {loading
                ? (
                    <>
                        <SkeletonPreloader loading={loading} classNames={styles.skeleton}/>
                        <span className={styles.delimiter}>/</span>
                        <SkeletonPreloader loading={loading} classNames={styles.skeleton}/>
                    </>
                ) : items}
        </div>
    );
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.object,
};

export default withDataService('breadcrumbs')(Breadcrumbs);