import React, {Component} from 'react';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

import {withServices} from "../../service-context";
import {withDataService} from '../../common/hoc';
import {errorURLs, marketplaceURLs, staticURLs} from "../../../services/api-urls";
import {resetPasswordFormPublicRequest} from "../../../reducers/public-data/reset-password/form";
import {resetPasswordFormPublicUploadRequest} from "../../../reducers/public-data/reset-password/upload-data";
import {setInitialResetPasswordFormPublicUpload} from "../../../actions/common";
import FormFieldCreator from "../../activity-hub/components/common/handlers/form-field-creator";
import {onCreateFormData} from "../../common/handlers";
import {
    getDataFromFields,
    onChangeFieldValue,
    onCheckingPasswordFields,
    transformFieldData
} from "../../activity-hub/components/common/handlers/field-handlers";

import FormFieldConstructor from "../../common/form-field-constructor";
import Preloader from "../../common/preloader";
import Button from "../../common/button";

import styles from './reset-password-page.module.scss';

class ResetPasswordPage extends Component {
    state = {
        id: null,
        title: null,
        list: null,
        passwordError: false,
        errors: null,
    };

    formFieldCreator = new FormFieldCreator();

    componentDidMount() {
        /* noAccessRight = true after session_expired */
        const {match: {params: {id}}, session, noAccessRight} = this.props;
        this.setState({id});

        if (!session && session !== null && !noAccessRight) {
            this.props.fetchResetPasswordForm(id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {formData: {data, list}} = this.props;
            if (data && list) {
                this.props.fetchResetPasswordForm(this.state.id);
            }
        }

        if (prevProps.formData !== this.props.formData) {
            const {data, list} = this.props.formData;
            if (data && list) {
                const transformList = list.map((field) => transformFieldData(field));

                this.setState({
                    title: data.title,
                    list: transformList,
                });
            }
        }

        if (prevState.passwordError !== this.state.passwordError) {
            const fieldsName = [];
            this.state.list.forEach(({name}) => {
                if (name.includes('password')) {
                    fieldsName.push(name);
                }
            });
            this.onSetResetError(fieldsName, this.state.passwordError);
        }

        if (prevProps.uploadData.error !== this.props.uploadData.error) {
            if (this.props.uploadData.error) {
                const {error} = this.props.uploadData;
                if (typeof error !== 'string') {
                    const fieldNamesWithError = Object.keys(error);
                    const passwordError = fieldNamesWithError.find(name => name.includes('password')) !== undefined;
                    const errors = [];

                    if (!this.state.passwordError && passwordError) {
                        this.setState({passwordError});
                    }

                    fieldNamesWithError.forEach(name => {
                        errors.push(...error[name]);
                    });

                    this.setState({errors});

                } else {
                    this.setState({errors: [error]});
                }
            }
        }
    }

    formFieldHandler = (formFields, listeners) => {
        const fields = this.formFieldCreator.getFormFields(formFields);
        return fields.map((field, idx) => <FormFieldConstructor {...listeners} key={idx} field={field}/>);
    };

    onInputChange = ({target: {name, value}}) => {
        if (this.state.passwordError) {
            this.setState({passwordError: false});
        }

        /* this case checking separately from first case because call only on input change */
        if (this.state.errors) {
            this.setState({errors: null});
        }

        let list = onChangeFieldValue(this.state.list, name, value);
        list = onCheckingPasswordFields(list, name);
        this.setState({list});
    };

    onInputFocus = () => {
        if (this.state.passwordError) {
            this.setState({passwordError: false});
        }
    }

    onSetResetError = (names, error = true) => {
        for (let name of names) {
            const element = document.querySelector(`[name='${name}']`);

            if (error) {
                element.parentNode.classList.add(styles.required);
            } else {
                element.parentNode.classList.remove(styles.required);
            }
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {list} = this.state;

        const passwordField = list.find(({name}) =>
            name.includes('password') && !name.includes('password_confirmation'));
        const passwordConfirmationField = list.find(({name}) => name.includes('password_confirmation'));
        const {correct} = passwordConfirmationField;

        /* password confirmation must be true, null or undefined
        * if password confirmation null or undefined then check password value === '' */
        let passwordValid = true;
        if (correct !== null && correct !== undefined) {
            passwordValid = correct;

        } else {
            if (passwordField.value) {
                passwordValid = false;
            }
        }

        if (passwordValid) {
            const data = getDataFromFields(list);
            const formData = onCreateFormData(data);
            this.props.onUploadResetPasswordForm(this.state.id, {formData});
        } else {
            this.setState({passwordError: true});
        }
    };

    componentWillUnmount() {
        const {message, error} = this.props.uploadData;
        if (message || error) {
            this.props.setInitialResetPasswordFormPublicUpload();
        }
    }

    render() {
        const {
            session, formData, uploadData,
            staticData: {
                authorization: {
                    sign_in: {
                        reset_password: {
                            subtitle,
                            button: {cancel, confirm},
                        }
                    }
                },
                password_requirements,
            },
        } = this.props;
        const {list, errors} = this.state;
        let {title} = this.state;
        title = title ? title.split(' ') : null;
        let titleText = '', titleEmail = '';
        if (title) {
            titleText = title.slice(0, title.length - 1).join(' ');
            titleEmail = title[title.length - 1];
        }

        const listeners = {
            onInputChange: this.onInputChange,
            onInputFocus: this.onInputFocus,
        };

        let fields = [];

        if (list) {
            fields = this.formFieldHandler(list, listeners);
        }

        return !session
            ? (
                <div className={styles['page-container']}>
                    {formData.loading
                        ? <Preloader/>
                        : list ? (
                            <form id={this.state.id} className={styles.container} onSubmit={this.onSubmit}>
                                <div className={styles.title}>
                                    {title && (
                                        <>
                                            <span>{titleText}</span>
                                            <span>{titleEmail}</span>
                                        </>
                                    )}
                                </div>
                                <div className={styles.subtitle}>{subtitle}</div>

                                <div className={styles.content}>
                                    <div className={styles.passwords}>{fields}</div>
                                    {errors && (
                                        <ul className={styles['error-block']}>
                                            {errors.map((value, idx) =>
                                                <li key={idx} className={styles.item}>{value}</li>
                                            )}
                                        </ul>
                                    )}
                                    <div className={styles.description}>
                                        <ul className={styles.list}>
                                            {password_requirements.text}
                                            {password_requirements.list.map((item, idx) => <li key={idx}>{item}</li>)}
                                        </ul>
                                    </div>
                                </div>

                                <div className={styles['buttons-container']}>
                                    <Button styleType={'SECONDARY'}
                                            classNames={styles['cancel-btn']}
                                            onClick={() => this.props.history.replace(staticURLs.home)}>
                                        {cancel}
                                    </Button>
                                    <Button type={'submit'}
                                            classNames={styles['confirm-btn']}
                                            dataLoading={uploadData.loading}>
                                        {confirm}
                                    </Button>
                                </div>
                            </form>
                        ) : null}
                </div>
            ) : !uploadData.confirmed
                ? <Redirect to={errorURLs.not_found}/>
                : <Redirect to={marketplaceURLs.marketplace}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        noAccessRight: PropTypes.bool,
        language: PropTypes.string,
        formData: PropTypes.shape({
            loading: PropTypes.bool,
            data: PropTypes.object,
            list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        }),
        uploadData: PropTypes.shape({
            loading: PropTypes.bool,
            confirmed: PropTypes.bool,
            error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
        fetchResetPasswordForm: PropTypes.func,
        onUploadResetPasswordForm: PropTypes.func,
        setInitialResetPasswordFormPublicUpload: PropTypes.func,
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchResetPasswordForm: resetPasswordFormPublicRequest(publicService.resetPassword),
    onUploadResetPasswordForm: resetPasswordFormPublicUploadRequest(publicService.resetPassword),
});

const mapStateToProps = (props) => {
    const {
        session: {session, noAccessRight},
        publicData: {homePage: {language}, resetPassword},
    } = props;

    return {
        language,
        session,
        noAccessRight,
        formData: resetPassword.form,
        uploadData: resetPassword.uploadData,
    };
};

const mapDispatchToProps = (dispatch, {
    fetchResetPasswordForm,
    onUploadResetPasswordForm,
}) => {
    return bindActionCreators({
        fetchResetPasswordForm,
        onUploadResetPasswordForm,
        setInitialResetPasswordFormPublicUpload,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(ResetPasswordPage);