import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {compose} from 'redux';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withDataService, withPaginationProductsPage, withScrollToTop} from '../../../../common/hoc';
import {withProductsHandler} from '../../../../common/hoc';
import {utilsAction} from '../../../../../actions/common';

import Preloader from '../../../../common/preloader';
import {GridView, ListView} from '../../../../common/views';
import Filters from '../filters';
import SortingContainer from '../../../../common/sorting';
import {ProductCard, ShortProductCard} from '../product-card';
import ProductPanel from '../product-panel';

import classnames from 'classnames/bind';
import styles from './products-container.module.scss';

const ProductsContainer = (props) => {
    const {pageTitle, productPage, filterTurnOn, staticData, paginationBlock} = props;
    const {loading, products, isFilterOpen} = productPage;
    const {updating, sorting, message} = useSelector(({marketplace: {products}}) => (products));
    const {category_page: {empty_category_message}, product_panel: {header}} = staticData.marketplace;

    const {session, view} = useSelector(({session: {session}, utils: {view}}) => ({session, view}));
    const dispatch = useDispatch();
    const mediumDevice = useMediaQuery({minWidth: 768});
    const xlDevice = useMediaQuery({minWidth: 1200});

    const categoryItemsRef = useRef(null);

    useEffect(() => {
        const initProductImageHeight = () => {
            if (products.length !== 0) {
                if (view === 'GRID' && categoryItemsRef.current) {
                    if (categoryItemsRef.current.childNodes.length !== 0) {
                        const productCard = categoryItemsRef.current.childNodes[0];
                        const image = productCard.firstChild;
                        const imageWidth = image.offsetWidth;
                        const imageHeight = imageWidth * .8;

                        for (let child of categoryItemsRef.current.childNodes) {
                            const firstRow = xlDevice && imageHeight > 210 ? '210px' : `${imageHeight}px`;
                            child.style.gridTemplateRows = `${firstRow} auto 1fr`;
                        }
                    }
                }
            }
        };

        initProductImageHeight();
        window.addEventListener('resize', initProductImageHeight);

        return () => {
            window.removeEventListener('resize', initProductImageHeight);
        };
    }, [xlDevice, products, view, categoryItemsRef]);

    const isProductsList = products.length > 0;

    const panelSwitchBlock = (
        <div className={styles.views}>
            <ListView
                isActive={view === 'LIST'}
                onClick={() => dispatch(utilsAction.switchView('LIST'))}/>
            <GridView
                isActive={view === 'GRID'}
                onClick={() => dispatch(utilsAction.switchView('GRID'))}/>
        </div>
    );

    const createProductsList = () => {
        const cx = classnames.bind(styles);
        const itemsClass = cx({
            'grid-items': view === 'GRID',
            'list-items': view === 'LIST',
        });

        const listHeaderClasses = cx('list-header', {
            'with-price': session,
        });

        const listOfItems = isProductsList ? products.map((item, idx) => {
            const productCard =
                item.offer.length !== 0
                    ? <ProductCard
                        key={idx}
                        idx={idx}
                        {...item}
                        totalItems={products.length}
                        session={session}
                    />
                    : <ShortProductCard key={idx} {...item}/>;

            return view === 'GRID'
                ? productCard
                : <ProductPanel key={idx} {...item} session={session}/>;
        }) : [];

        const productsBlock = isProductsList ? (
            <>
                {view === 'LIST' && (
                    <div className={listHeaderClasses}>
                        <div className={styles.column}>{header.item_label}</div>
                        <div className={styles.column}>{header.condition_label}</div>
                        <div className={cx('column', 'price')}>{header.price_label}</div>
                    </div>
                )}
                <div ref={categoryItemsRef} className={itemsClass}>
                    {listOfItems}
                </div>
                {paginationBlock}
            </>
        ) : <div className={styles['empty-products-block']}>{message ? message : empty_category_message}</div>;

        const onMobileFilterOpen = (value) => {
            if (value) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        };

        return (
            <>
                <div className={cx('filters-and-sorting-block', {'sorting-only': !filterTurnOn})}>
                    {filterTurnOn && <Filters onMobileFilterOpen={onMobileFilterOpen}/>}
                    <SortingContainer sortingOptions={sorting}
                                      updating={updating}
                                      isDisabled={!isProductsList}
                                      onMobileFilterOpen={onMobileFilterOpen}/>
                </div>

                <div className={styles['products-block']}
                     style={{pointerEvents: (isFilterOpen && mediumDevice) ? 'none' : ''}}>
                    {productsBlock}
                </div>
            </>
        );
    };

    return (
        <div className={styles['page-container']}>
            <div className={styles['title-container']}>
                <h1>{pageTitle}</h1>
                {(!loading && isProductsList) && panelSwitchBlock}
            </div>
            {!loading ? createProductsList() : <Preloader/>}
        </div>
    );
};

ProductsContainer.defaultProps = {
    filterTurnOn: true,
};

ProductsContainer.propTypes = {
    productPage: PropTypes.shape({
        products: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.shape({
                offer: PropTypes.object,
            }))]),
        loading: PropTypes.bool,
        isFilterOpen: PropTypes.bool,
    }),
    pageTitle: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
    filterTurnOn: PropTypes.bool,
    staticData: PropTypes.object,
    paginationBlock: PropTypes.node,
};

export default compose(
    withDataService(),
    withProductsHandler(),
    withPaginationProductsPage(),
    withScrollToTop(),
)(ProductsContainer);