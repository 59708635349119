import React from 'react';

import Dropdown from '../../dropdown';
import Button from '../../button';

import classnames from 'classnames/bind';
import styles from './side-drop-menu.module.scss';

const withSideDropMenu = () => (Wrapped) => {
    return (props) => {
        const {
            staticData, label, isOpenMenu, setIsOpenMenu,
            isDisabled = false, onReset = () => {
            },
        } = props;
        const {show_result, reset_all} = staticData.filters_sorting_button;

        const cx = classnames.bind(styles);
        const containerClasses = cx('container', {'disabled': isDisabled});

        return (
            <div className={containerClasses}>
                <Dropdown
                    type="FILTER"
                    label={label}
                    isOpen={isOpenMenu}
                    labelClassNames={styles['drop-menu']}
                    onToggle={() => setIsOpenMenu(true)}>
                    <div className={styles.content}>
                        <div className={styles.label} onClick={() => setIsOpenMenu(false)}>
                            {label}
                        </div>

                        <Wrapped {...props} staticData={staticData} setIsOpenMenu={setIsOpenMenu}/>

                        <div className={styles['btn-block']}>
                            <Button
                                classNames={styles['show-result']}
                                onClick={() => setIsOpenMenu(false)}>
                                {show_result}
                            </Button>

                            <Button
                                styleType="SECONDARY"
                                isTransformText={false}
                                classNames={styles['reset-all']}
                                onClick={onReset}>
                                {reset_all}
                            </Button>
                        </div>
                    </div>
                </Dropdown>
            </div>
        );
    };
};

export default withSideDropMenu;