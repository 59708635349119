import {createRequestHandler} from '../../../../utils';

export const SHIPMENT_ORGANIZATION_FORM_FIELDS_REQUEST = 'SHIPMENT_ORGANIZATION_FORM_FIELDS_REQUEST';
export const SHIPMENT_ORGANIZATION_FORM_FIELDS_SUCCESS = 'SHIPMENT_ORGANIZATION_FORM_FIELDS_SUCCESS';
export const SHIPMENT_ORGANIZATION_FORM_FIELDS_FAILURE = 'SHIPMENT_ORGANIZATION_FORM_FIELDS_FAILURE';

export const SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_REQUEST = 'SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_REQUEST';
export const SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_SUCCESS = 'SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_SUCCESS';
export const SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_FAILURE = 'SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_FAILURE';

export const shipmentOrganizationFormFieldsRequest = createRequestHandler('SHIPMENT_ORGANIZATION_FORM_FIELDS');
export const updatingShipmentOrganizationFormFieldsRequest = createRequestHandler('SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    list: [],
    error: null,
};

const shipmentOrganizationFormFields = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SHIPMENT_ORGANIZATION_FORM_FIELDS_REQUEST:
            return initialState;

        case SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case SHIPMENT_ORGANIZATION_FORM_FIELDS_SUCCESS:
        case SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_SUCCESS:
            return {
                list: payload.list,
                loading: false,
                updating: false,
                error: null,
            };

        case SHIPMENT_ORGANIZATION_FORM_FIELDS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case SHIPMENT_ORGANIZATION_FORM_FIELDS_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default shipmentOrganizationFormFields;