import {createRequestHandler} from '../../../utils';

export const CONTRACT_DOCUMENT_UPLOAD_DATA_REQUEST = 'CONTRACT_DOCUMENT_UPLOAD_DATA_REQUEST';
export const CONTRACT_DOCUMENT_UPLOAD_DATA_SUCCESS = 'CONTRACT_DOCUMENT_UPLOAD_DATA_SUCCESS';
export const CONTRACT_DOCUMENT_UPLOAD_DATA_FAILURE = 'CONTRACT_DOCUMENT_UPLOAD_DATA_FAILURE';

export const contractDocumentUploadDataRequest = createRequestHandler('CONTRACT_DOCUMENT_UPLOAD_DATA');

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const contractDocumentUpload = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CONTRACT_DOCUMENT_UPLOAD_DATA_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case CONTRACT_DOCUMENT_UPLOAD_DATA_SUCCESS:
            return {
                loading: false,
                data: payload,
                error: null,
            };

        case CONTRACT_DOCUMENT_UPLOAD_DATA_FAILURE:
            return {
                loading: false,
                data: null,
                error: payload,
            };

        case 'RESET_CONTRACT_DOCUMENT_UPLOAD_DATA':
            return initialState;

        default:
            return state;
    }
};

export default contractDocumentUpload;