const checkingEqualityOfFiles = (array1, array2) => {
    let array3 = array2;
    let infoData = [];

    for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array2.length; j++) {
            const obj1 = array1[i];
            const obj2 = array2[j];

            if (obj1.name === obj2.name && obj1.type === obj2.type) {
                infoData.push(array2[j].name);

                const newArray2 = [
                    ...array2.slice(0, j),
                    ...array2.slice(j + 1),
                ];

                array2 = newArray2;
                array3 = array2;
                j = 0;
            }
        }
    }

    if (infoData.length !== 0) {
        return {files: array3, infoData};
    }

    return {files: array3};
};

export default checkingEqualityOfFiles;