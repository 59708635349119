import React from 'react';
import PropTypes from 'prop-types';

const ListView = ({isActive, onClick}) => {
    const fillColor = isActive ? '#5e4834b2' : '#d6cab4';

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             onClick={onClick}>
            <g clipPath="url(#clip0)">
                <path d="M0 2.4375H5.31061V7.74811H0V2.4375Z" fill={fillColor}/>
                <path d="M6.99829 2.4375H24V7.74811H6.99829V2.4375Z" fill={fillColor}/>
                <path d="M0 9.32812H5.31061V14.6387H0V9.32812Z" fill={fillColor}/>
                <path d="M6.99829 9.32812H24V14.6387H6.99829V9.32812Z" fill={fillColor}/>
                <path d="M0 16.2188H5.31061V21.5295H0V16.2188Z" fill={fillColor}/>
                <path d="M6.99829 16.2188H24V21.5295H6.99829V16.2188Z" fill={fillColor}/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

ListView.defaultProps = {
    isActive: false,
    onClick: () => {
    },
};

ListView.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ListView;