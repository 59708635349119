export const CONTACT_DATA = {
    address: ['Exportery LLC,', 'Paata Datuashvili str. N23, apartment 76,', '0158 Tbilisi, Georgia'],
    address_short: ['Exportery LLC,', 'Tbilisi, Georgia, zip: 0158'],
    email: 'feedback@exportery.com',
    phone: '+995 706 001 010',
    linkedin: 'https://www.linkedin.com/company/exportery/',
    facebook: 'https://www.facebook.com/Exportery-104488742360268',
    twitter: 'https://twitter.com/exportery',
};

export const BANNER_TEXT_LESS_TOP = ['fr', 'es'];

export const NOT_CAPITALIZE_LANGUAGES = ['ru'];