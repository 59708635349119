import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import FiltersDesktop from './filters-desktop';
import FiltersActive from './filters-active';
import FiltersMobile from './filters-mobile';

const Filter = (props) => {
    const {staticData, screenChangeOn, onMobileFilterOpen, ...rest} = props;
    const {
        filters_label,
        part_number_label,
        part_number_label_mobile,
        search_label,
    } = staticData.filters;

    const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

    const onUpdateIsMobileFilterOpen = (value) => {
        setIsMobileFilterOpen(value);
        onMobileFilterOpen(value);
    };

    const desktopDevice = useMediaQuery({minWidth: screenChangeOn});

    return desktopDevice
        ? (
            <>
                <FiltersDesktop {...rest} inputPlaceholder={part_number_label}/>
                <FiltersActive {...rest} staticData={staticData} filterOptions={props.options}/>
            </>
        )
        : <FiltersMobile
            {...rest}
            staticData={staticData}
            label={filters_label}
            isOpenMenu={isMobileFilterOpen}
            setIsOpenMenu={onUpdateIsMobileFilterOpen}
            inputTitle={part_number_label_mobile}
            inputPlaceholder={search_label}
        />;
};

Filter.defaultProps = {
    screenChangeOn: 768,
    onMobileFilterOpen: () => {
    },
};

Filter.propTypes = {
    staticData: PropTypes.object,
    screenChangeOn: PropTypes.number,
    onMobileFilterOpen: PropTypes.func,
};

export default Filter;