import {createRequestHandler, createAlertResponse} from '../../../../utils';

export const COUNT_OF_SELECTED_ITEM_UPDATING_REQUEST = 'COUNT_OF_SELECTED_ITEM_UPDATING_REQUEST';
export const COUNT_OF_SELECTED_ITEM_UPDATING_SUCCESS = 'COUNT_OF_SELECTED_ITEM_UPDATING_SUCCESS';
export const COUNT_OF_SELECTED_ITEM_UPDATING_FAILURE = 'COUNT_OF_SELECTED_ITEM_UPDATING_FAILURE';

export const numberOfItemsRequest = createRequestHandler('COUNT_OF_SELECTED_ITEM_UPDATING');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const countOfItem = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case COUNT_OF_SELECTED_ITEM_UPDATING_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case COUNT_OF_SELECTED_ITEM_UPDATING_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case COUNT_OF_SELECTED_ITEM_UPDATING_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_UPDATED_THE_NUMBER_OF_ITEMS_STATUS':
            return initialState;

        default:
            return state;
    }
};

export default countOfItem;