import React from 'react';

export const textHandler = (text, styles) => {
    const transformObjectToText = (item) => {
        const keys = Object.keys(item);
        let paragraph = [];
        let content = '';
        keys.forEach((key, idx) => {
            if (typeof item[key] === 'string' && item[key] !== '') {
                content = <span>{item[key]}</span>;
            }

            if (item[key] === '') {
                content = ' ';
            }

            if (item[key].href) {
                const {text, href, target = '_blank', rel = ''} = item[key];
                content = (
                    <a href={href} target={target} rel={rel} className={styles.link}>
                        {text}
                    </a>
                );
            }

            if (item[key].strong && item[key].italic) {
                content = <strong><i>{item[key].text}</i></strong>;
            }

            if (item[key].strong && !item[key].italic) {
                content = <strong>{item[key].text}</strong>;
            }

            if (key.includes('items')) {
                const keyAsArray = key.split('_');
                const listOrderType = keyAsArray[1];
                let listStyleProp = '';
                let listStyleType = '';

                if (keyAsArray.length > 2) {
                    listStyleProp = keyAsArray[2];
                    listStyleType = keyAsArray.slice(3).join('-');
                }

                const items = item[key].map((item, idx) => {
                    if (typeof item === 'object') {
                        return <li key={idx}>{transformObjectToText(item)}</li>;
                    }

                    return <li key={idx}>{item}</li>;
                });

                if (listOrderType === 'ul') {
                    if (listStyleProp === 'type') {
                        content = <ul type={listStyleType}>{items}</ul>;
                    } else {
                        content = (
                            <ul type={'disc'} style={{[`${listStyleProp}`]: listStyleType}}>
                                {items}
                            </ul>
                        );
                    }
                }

                if (listOrderType === 'ol') {
                    if (listStyleProp === 'type') {
                        content = <ol type={listStyleType}>{items}</ol>;
                    } else {
                        content = (
                            <ol style={{listStyleType: listStyleType}}>
                                {items}
                            </ol>
                        );
                    }
                }
            }

            if (key === 'text') {
                content = <span>{textHandler(item[key], styles)}</span>;
            }

            if (key === 'table') {
                content = (
                    <div className={styles['table-container']}>
                        <table className={styles.table}>
                            <thead>
                            <tr>
                                <th colSpan={2}>{item[key].header}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {item[key].rows.map((item, idx) => (
                                <tr key={idx}>
                                    <td>{item[0]}</td>
                                    <td>{textHandler(item[1], styles)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                );
            }

            paragraph.push(<React.Fragment key={idx}>{content}</React.Fragment>);
        });

        return paragraph;
    };

    if (Array.isArray(text)) {
        return text.map((item, idx) => {
            if (typeof item === 'object') {
                const paragraph = transformObjectToText(item);

                return <div key={idx} className={styles.paragraph}>{paragraph}</div>;
            }

            return <div key={idx} className={styles.paragraph}>{item}</div>;
        });

    } else if (typeof text === 'object' && Object.keys(text).length > 0) {
        return transformObjectToText(text);

    } else {
        return text;
    }
};