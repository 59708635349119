import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import PropTypes from 'prop-types';

import {withDataService} from '../common/hoc';
import {withServices} from '../service-context';
import {
    detectingPublicUrl,
    detectingStaticUrl,
    detectingHiddenUrlForTrialUser,
    detectingErrorUrl,
    withRedirectFromPubToPrivate,
    errorURLs, staticURLs,
} from '../../services/api-urls';
import {sessionRequest} from '../../reducers/session';
import {profileRequest, updatingProfileRequest} from '../../reducers/profile';
import {notificationsRequest, updatingNotificationsRequest} from '../../reducers/activity-hub/notifications';
import {logoutRequest} from '../../reducers/login';
import {
    loginAction,
    utilsAction,
    redirectAfterDenialOfAccess,
} from '../../actions/common';
import sweetAlert from '../common/sweet-alert';

import App from './app';

class AppContainer extends Component {
    timerID = null;
    notificationIntervalId = null;

    componentDidMount() {
        this.props.fetchSession();
    }

    onDetectingPrivateUrlHandler = () => {
        const {session, location: {pathname}} = this.props;

        const isPublicUrl = detectingPublicUrl(pathname);
        const needSignIn = !isPublicUrl;

        if (!session && session !== null && needSignIn) {
            this.timerID = setTimeout(() => this.props.openSignIn(), 400);
        }
    };

    onDetectingHiddenUrlsForTrialUser = () => {
        const {profile, location: {pathname}} = this.props;

        const hiddenUrlForTrialUser = detectingHiddenUrlForTrialUser(pathname);

        if (hiddenUrlForTrialUser && profile && profile.is_trial) {
            this.props.history.replace(errorURLs.not_found);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.session !== this.props.session) {
            /* first checking after load api */
            this.onDetectingPrivateUrlHandler();

            if (this.props.session && prevProps.login.loading) {
                const {pathname} = this.props.location;
                const {redirect, substring, path} = withRedirectFromPubToPrivate(pathname);
                if (redirect) {
                    if (path) {
                        this.props.history.replace(path);
                    } else {
                        const redirectPath = pathname.replace(substring, '');
                        this.props.history.push(redirectPath);
                    }
                }
            }
        }

        if (prevProps.login.loggedIn !== this.props.login.loggedIn) {
            if (this.props.login.loggedIn) {
                this.props.fetchProfile();
            }
        }

        if (prevProps.profile !== this.props.profile) {
            /* first download of notification data */
            if (!prevProps.profile) {
                this.props.fetchNotifications();
                this.onDetectingHiddenUrlsForTrialUser();
            }
        }

        if (prevProps.profileData.error !== this.props.profileData.error) {
            if (this.props.profileData.error && !this.props.login.signInActive) {
                this.props.history.replace(staticURLs.home);
                sweetAlert('info', this.props.profileData.error);
            }
        }

        if (prevProps.sessionData.noAccessRight !== this.props.sessionData.noAccessRight) {
            if (prevProps.session && this.props.sessionData.noAccessRight) {
                // need test
                this.props.history.replace(staticURLs.home);

                if (this.timerID !== null) {
                    clearTimeout(this.timerID);
                }

                sweetAlert('info', this.props.staticData.alert_modal.session_expired_message)
                    .then(() => this.timerID = setTimeout(() => this.props.openSignIn(), 400));
            }
        }

        if (prevProps.serverError !== this.props.serverError) {
            const {pathname} = this.props.location;
            if (this.props.serverError && !detectingStaticUrl(pathname) && !detectingErrorUrl(pathname)) {
                if (!this.props.registration.signUpActive && !this.props.login.signInActive) {
                    this.props.history.replace(errorURLs.internal_server_error);
                }
            }
        }

        if (prevProps.notFoundError !== this.props.notFoundError && this.props.notFoundError) {
            this.props.history.replace(errorURLs.not_found);
        }

        if (prevProps.notAcceptableError !== this.props.notAcceptableError && this.props.notAcceptableError) {
            this.props.history.replace(errorURLs.not_acceptable);
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.onDetectingPrivateUrlHandler();
            this.onDetectingHiddenUrlsForTrialUser();
        }

        if (prevProps.profileData.language !== this.props.profileData.language && this.props.session) {
            if (prevProps.profile) {
                this.props.fetchUpdatingProfile();
                this.props.fetchUpdatingNotifications();
            }
        }

        if (prevProps.notifications !== this.props.notifications) {
            if (this.notificationIntervalId !== null) {
                clearInterval(this.notificationIntervalId);
            }

            this.notificationIntervalId = setInterval(() => {
                /* updating notifications every 5 min = 300 000 ms */
                this.props.fetchUpdatingNotifications();
            }, 300000);
        }

        // need work
        if (prevProps.redirectAccess !== this.props.redirectAccess && this.props.redirectAccess) {
            sweetAlert('info', this.props.staticData.alert_modal.permission_denied)
                .then(() => {
                    let pathnameToRedirect = staticURLs.home;

                    if (this.props.session) {
                        if (this.props.prevLocation) {
                            pathnameToRedirect = this.props.prevLocation;

                        } else {
                            const reqExp = /\/\w+/;
                            const {location: {pathname}} = this.props;
                            pathnameToRedirect = pathname.match(reqExp)[0] || staticURLs.home;
                        }
                    }

                    this.props.history.replace(pathnameToRedirect);
                    this.props.redirectAfterDenialOfAccess(false);
                });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timerID);
        clearInterval(this.notificationIntervalId);
    }

    render() {
        const {login: {signInActive}, registration: {signUpActive}} = this.props;

        const modalActive = signInActive || signUpActive;

        const params = {
            session: this.props.sessionData,
            profile: this.props.profileData,
            cookieConsent: this.props.cookieConsent,
            location: this.props.location,
            serverError: this.props.serverError,
            notFoundError: this.props.notFoundError,
            notAcceptableError: this.props.notAcceptableError,
            resetErrorIndicatorSwitcher: this.props.resetErrorIndicatorSwitcher,
            apiTitle: this.props.apiTitle,
            modalActive,
        };

        return (
            <HelmetProvider>
                <App {...params}/>
            </HelmetProvider>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        sessionData: PropTypes.object,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        login: PropTypes.object,
        profileData: PropTypes.object,
        profile: PropTypes.object,
        notifications: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        history: PropTypes.shape({
            replace: PropTypes.func,
            push: PropTypes.func,
        }),
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
        prevLocation: PropTypes.string,
        redirectAccess: PropTypes.bool,
        serverError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        notFoundError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        notAcceptableError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        fetchSession: PropTypes.func,
        fetchProfile: PropTypes.func,
        fetchNotifications: PropTypes.func,
        fetchUpdatingProfile: PropTypes.func,
        fetchUpdatingNotifications: PropTypes.func,
        fetchLogout: PropTypes.func,
        redirectAfterDenialOfAccess: PropTypes.func,
    };
}

const mapServicesToProps = ({userService, notificationsService}) => ({
    fetchSession: sessionRequest(userService.getSession),
    fetchProfile: profileRequest(userService.getProfile),
    fetchUpdatingProfile: updatingProfileRequest(userService.getProfile),
    fetchNotifications: notificationsRequest(notificationsService.getNotificationsList),
    fetchUpdatingNotifications: updatingNotificationsRequest(notificationsService.getNotificationsList),
    fetchLogout: logoutRequest(userService.logout),
});

const mapStateToProps = ({session, registration, login, profile, utils, activityHub: {notifications}}) => {
    return ({
        sessionData: session,
        session: session.session,
        redirectAccess: session.redirectAfterDenialOfAccess,
        login,
        registration,
        profileData: profile,
        profile: profile.profile,
        notifications: notifications.initList,
        cookieConsent: utils.cookieConsent,
        serverError: utils.serverError,
        notFoundError: utils.notFoundError,
        notAcceptableError: utils.notAcceptableError,
        apiTitle: utils.apiTitle,
        prevLocation: utils.prevLocation,
    });
};

const mapDispatchToProps = (dispatch, serviceAction) => {
    return bindActionCreators({
        fetchSession: serviceAction.fetchSession,
        fetchProfile: serviceAction.fetchProfile,
        fetchUpdatingProfile: serviceAction.fetchUpdatingProfile,
        fetchNotifications: serviceAction.fetchNotifications,
        fetchUpdatingNotifications: serviceAction.fetchUpdatingNotifications,
        fetchLogout: serviceAction.fetchLogout,
        openSignIn: loginAction.openSignIn,
        resetErrorIndicatorSwitcher: utilsAction.resetErrorIndicatorSwitcher,
        redirectAfterDenialOfAccess,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(AppContainer);