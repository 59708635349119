import {combineReducers} from 'redux';

import countries from './countries';
import homePage from './home-page';
import {invitationReducer} from './invitation';
import {newsReducer} from './news';
import {resetPasswordReducer} from "./reset-password";

export const publicReducer = combineReducers({
    invitation: invitationReducer,
    resetPassword: resetPasswordReducer,
    news: newsReducer,
    countries,
    homePage,
});