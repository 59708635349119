import React from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../common/hoc';

import Image from '../../../../../../../common/image';
import Button from '../../../../../../../common/button';

import styles from './offer-card.module.scss';

const OfferCard = (props) => {
    const {
        staticData, id, name, part_number, condition_value,
        offer, preview_image, onReplaceItem,
    } = props;

    const {price, currency, organization_name, formatted_supply_condition} = offer;

    const {
        other_offers_block: {
            offer: {
                condition_label,
                delivery_time_label,
                delivery_time_value,
                exporter_label,
                supply_conditions_label,
            },
            button: {replace},
        },
        product_image_alt,
    } = staticData;

    const mdDevice = useMediaQuery({maxWidth: 767.9});

    const descriptionBlock = (
        <div className={styles['description-block']}>
            <div className={styles.description}>
                <div className={styles.item}>
                    <div className={styles.label}>{condition_label}{mdDevice && ':'}</div>
                    <div className={styles.value}>{condition_value}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>{delivery_time_label}{mdDevice && ':'}</div>
                    <div className={styles.value}>{delivery_time_value}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>{exporter_label}{mdDevice && ':'}</div>
                    <div className={styles.value}>{organization_name}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>{supply_conditions_label}{mdDevice && ':'}</div>
                    <div className={styles.value}>{formatted_supply_condition}</div>
                </div>
            </div>

            <Button styleType="TEXT" classNames={styles['replace-btn']}
                    onClick={() => onReplaceItem(id)}
                    withTextEllipsis>
                {replace}
            </Button>
        </div>
    );

    return (
        <div className={styles.container}>
            <Image source={preview_image} alt={product_image_alt} classNames={`${styles.img} ${styles['img-lg']}`}/>

            <div className={styles['data-block']}>
                <Image source={preview_image} alt={product_image_alt} classNames={styles.img}/>

                <div className={styles['title-row']}>
                    <div className={styles['title-block']}>
                        <div className={styles.title}>
                            {name} <span className={styles.subtitle}>{part_number}</span>
                        </div>
                        <div className={styles.price}>{price} {currency}</div>
                    </div>

                    <div className={styles['price-row-md']}>
                        <div className={styles.price}>{price} {currency}</div>
                        <Button styleType="TEXT" classNames={styles['replace-btn']}
                                onClick={() => onReplaceItem(id)}
                                withTextEllipsis>
                            {replace}
                        </Button>
                    </div>
                </div>

                {descriptionBlock}
            </div>

            {descriptionBlock}
        </div>
    );
};

OfferCard.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.number,
    name: PropTypes.string,
    part_number: PropTypes.string,
    condition_value: PropTypes.string,
    offer: PropTypes.shape({
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        currency: PropTypes.string,
        organization_name: PropTypes.string,
        formatted_supply_condition: PropTypes.string,
    }),
    preview_image: PropTypes.object,
    onReplaceItem: PropTypes.func,
};

export default withDataService('marketplace.quotation')(OfferCard);