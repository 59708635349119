/* There is we create our ServiceError class, which inherit from the built-in Error class */

export default class ServiceError extends Error {
    constructor(url, resp) {
        /* we call the parent constructor with super(message) and sets the message property */
        super(`Could not call ${url}, ` +
            `server returned ${resp.status} [${resp.statusText}]`);

        /* Current call stack: a string with information about the sequence of nested calls that led to the error. Used for debugging purposes. */
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServiceError);
        }

        this.url = url;
        this.resp = resp;
    }
}