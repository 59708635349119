import {NumericFormat} from 'react-number-format';

export const priceHandler = (value) => {
    if (typeof value === 'string') {
        const regExpSpace = / /g;
        const regExpComa = /,/g;
        const regExpPoint = /\./g;
        const matchSpace = value.match(regExpSpace);
        const matchComa = value.match(regExpComa);
        const matchPoint = value.match(regExpPoint);

        if (matchSpace && (matchComa || matchPoint)) {
            return value;
        }
    }

    if (typeof value === 'number') {
        value = value.toFixed(2);
    }

    return <NumericFormat value={value} displayType={'text'} thousandSeparator={' '}/>;
};

export const priceStringToNumber = (value) => {
    if (typeof value === 'string') {
        const regExpEmptySubstr = / /g;
        const matchResult = value.match(regExpEmptySubstr);
        if (matchResult) {
            value = value.replace(regExpEmptySubstr, '');
        }

        return parseFloat(value);
    }

    return value;
};