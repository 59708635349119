import {createRequestHandler} from '../../../utils';

export const PACKING_LIST_LABELS_REQUEST = 'PACKING_LIST_LABELS_REQUEST';
export const PACKING_LIST_LABELS_SUCCESS = 'PACKING_LIST_LABELS_SUCCESS';
export const PACKING_LIST_LABELS_FAILURE = 'PACKING_LIST_LABELS_FAILURE';

export const PACKING_LIST_LABELS_UPDATING_REQUEST = 'PACKING_LIST_LABELS_UPDATING_REQUEST';
export const PACKING_LIST_LABELS_UPDATING_SUCCESS = 'PACKING_LIST_LABELS_UPDATING_SUCCESS';
export const PACKING_LIST_LABELS_UPDATING_FAILURE = 'PACKING_LIST_LABELS_UPDATING_FAILURE';

export const packingListLabelsRequest = createRequestHandler('PACKING_LIST_LABELS');
export const updatingPackingListLabelsRequest = createRequestHandler('PACKING_LIST_LABELS_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    list: null,
    listToPrint: [],
    title: null,
    error: null,
    selectedAll: false,
    withCutLine: true,
};

const onInitList = (list) => {
    return list.map((label, idx) => {
        return {
            ...label,
            id: `pLabel_${idx}`,
            isChecked: false,
        };
    });
};

const onUpdateList = (list, prevList) => {
    return prevList.map((item, idx) => {
        return {
            ...item,
            ...list[idx],
        };
    });
};

const onSelectAll = (checked, {list}) => {
    const updatedList = list.map((item) => ({
        ...item,
        isChecked: checked,
    }));

    return {
        selectedAll: checked,
        list: updatedList,
        listToPrint: checked ? updatedList : [],
    };
};

const onCheckboxChange = ({id, checked}, {list, listToPrint}) => {
    const idx = list.findIndex((label) => label.id === id);
    const currentList = list[idx];

    const newCurrentLabel = {
        ...currentList,
        isChecked: checked,
    };

    const newList = [
        ...list.slice(0, idx),
        newCurrentLabel,
        ...list.slice(idx + 1),
    ];

    let newListToPrint = [];
    const idxInListToPrint = listToPrint.findIndex(label => label.id === id);
    if (idxInListToPrint !== -1) {
        newListToPrint = [
            ...listToPrint.slice(0, idxInListToPrint),
            ...listToPrint.slice(idxInListToPrint + 1),
        ];
    } else {
        newListToPrint = [
            ...listToPrint,
            newCurrentLabel,
        ];
    }

    return {
        list: newList,
        listToPrint: newListToPrint,
    };
};

const packingListLabels = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PACKING_LIST_LABELS_REQUEST:
            return initialState;

        case PACKING_LIST_LABELS_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case PACKING_LIST_LABELS_SUCCESS:
            return {
                ...initialState,
                list: onInitList(payload.list),
                title: payload.title,
                loading: false,
            };

        case PACKING_LIST_LABELS_UPDATING_SUCCESS:
            return {
                ...state,
                list: onUpdateList(payload.list, state.list),
                title: payload.title,
                updating: false,
            };

        case PACKING_LIST_LABELS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case PACKING_LIST_LABELS_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'TOGGLE_PRINT_ALL_LABELS_MODE':
            return {
                ...state,
                ...onSelectAll(payload, state),
            };

        case 'TOGGLE_LABEL_CHECKBOX':
            return {
                ...state,
                ...onCheckboxChange(payload, state),
            };

        case 'TOGGLE_PRINT_LABELS_WITH_LINE_MODE':
            return {
                ...state,
                withCutLine: payload,
            };

        default:
            return state;
    }
};

export default packingListLabels;