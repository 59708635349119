const tabNameCreator = (string) => {
    const array = string.split('-');
    let transformArray = array;

    for (let i = 1; i < array.length; i++) {
        const newItem = array[i].charAt(0).toUpperCase() + array[i].slice(1);
        transformArray = [
            ...transformArray.slice(0, i),
            newItem,
            ...transformArray.slice(i + 1),
        ];
    }

    return transformArray.join('');
};

export default tabNameCreator;