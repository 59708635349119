import React from 'react';
import PropTypes from 'prop-types';

import styles from './brand-logo.module.scss';

const BrandLogo = ({brand_logo}) => {
    const imgUrl = require(`../../../services/data-service/home-page/assets/brands/${brand_logo}`).default;

    return <div className={styles.container} style={{backgroundImage: `url(${imgUrl})`}}/>;
};

BrandLogo.propTypes = {
    brand_logo: PropTypes.string.isRequired,
};

export default BrandLogo;