import React from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../common/hoc';

import ContractInformationItem from '../contract-information-item';

import classnames from 'classnames/bind';
import styles from './contract-information.module.scss';

const ContractInformation = ({staticData, documents}) => {
    const {
        contract_information_label,
        information_table_labels,
    } = staticData.activity_hub.e_contracts.pages.contract_for_supply;
    const cx = classnames.bind(styles);

    /* case when user don't have any documents, we get documents = [] */
    if (Array.isArray(documents)) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{contract_information_label}</div>
            <div className={styles['table-head']}>
                {information_table_labels.map((label, idx) =>
                    <div key={idx}
                         className={cx('column', {'days': idx === information_table_labels.length - 2})}>
                        {label}
                    </div>,
                )}
            </div>
            {Object.values(documents).map((item, idx) => <ContractInformationItem key={idx} {...item} />)}
        </div>
    );
};

ContractInformation.propTypes = {
    staticData: PropTypes.object,
    documents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default withDataService()(ContractInformation);