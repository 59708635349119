import {about_core_services_en} from './en';
import {about_core_services_ru} from './ru';
import {about_core_services_es} from './es';
import {about_core_services_fr} from './fr';

export {
    about_core_services_en,
    about_core_services_ru,
    about_core_services_es,
    about_core_services_fr,
};