import React from 'react';
import {compose} from 'redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService, withRedirectAction} from '../../../../common/hoc';
import {errorURLs, hubURLs} from '../../../../../services/api-urls';

import ProductPageContainer from './pages/product-page-container';

const PlaceProductFromFormModule = ({match: {path: basePath}}) => {
    return (
        <Switch>
            <Route path={`${basePath}`} exact>
                <Redirect to={hubURLs.products}/>
            </Route>

            <Route path={`${basePath}/create`} exact render={(props) => (
                <ProductPageContainer {...props} pageMode={'NEW'}/>
            )}/>

            <Route path={`${basePath}/update/:id`} exact render={(props) => (
                <ProductPageContainer {...props} pageMode={'EDIT'}/>
            )}/>

            <Route>
                <Redirect to={errorURLs.not_found}/>
            </Route>
        </Switch>
    );
};

PlaceProductFromFormModule.propTypes = {
    basePath: PropTypes.string,
};

export default compose(
    withDataService(),
    withRedirectAction(),
    withRouter,
)(PlaceProductFromFormModule);