import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../common/hoc';
import {textHandler} from '../../../../utils/text-handler';
import {NOT_CAPITALIZE_LANGUAGES} from '../../../../services/data-service/constants';

import Button from '../../../common/button';

import classnames from 'classnames/bind';
import styles from './advantage-item.module.scss';

const EnhancedComponent = withAccordion()(({contents}) => contents);

const AdvantageItem = (props) => {
    const {title, text, radio, btn, href, img, alt_img, language, classNames} = props;
    const cx = classnames.bind(styles);
    const lgScreen = useMediaQuery({minWidth: 992});
    const notCapitalizeClass = cx({'not-capitalize-text': NOT_CAPITALIZE_LANGUAGES.includes(language)});
    const titleClasses = `${styles.title} ${notCapitalizeClass}`;
    const titleSmClasses = `${styles['title-sm']} ${notCapitalizeClass}`;

    const [radioList, setRadioList] = useState([]);

    useEffect(() => {
        if (radio) {
            const transformRadio = radio.map((item, idx) => ({
                ...item,
                id: `tab${idx + 1}`,
                active: idx === 0,
                opened: false,
            }));

            setRadioList(transformRadio);
        }
    }, [radio]);

    const onChange = (id) => {
        const changedList = radioList.map((item) => ({
            ...item,
            active: item.id === id,
        }));

        setRadioList(changedList);
    };

    const onToggleAccordion = (id) => {
        const changedList = radioList.map((item) => {
            if (item.id === id && item.opened) {
                return ({
                    ...item,
                    opened: false,
                });
            }

            return ({
                ...item,
                opened: item.id === id,
            });
        });

        setRadioList(changedList);
    };

    if (radio) {
        const contentsHTMLHandler = ({text, active, img}, idx) => {
            const iconPath = require(`../../../../services/data-service/home-page/assets/advantages/${img}`).default;
            return (
                <div key={idx} className={cx('tab', {'active': active})}>
                    <div>
                        <img src={iconPath} alt={alt_img} className={styles.img}/>
                        <div className={styles.text}>{textHandler(text, styles)}</div>
                    </div>
                </div>
            );
        };

        return (
            <div className={styles.wrapper}>
                <div className={cx(classNames, {'radio-block-lg': lgScreen, 'radio-block': !lgScreen})}>
                    {lgScreen ? (
                        <>
                            <div className={styles['labels-block']}>
                                <h1 className={titleClasses}>{title}</h1>
                                {radioList.map(({id, title, active}, idx) => (
                                    <label key={idx} htmlFor={id} className={cx('label-container', {'active': active})}>
                                        <input id={id}
                                               type="radio"
                                               checked={active}
                                               onChange={() => onChange(id)}
                                        />
                                        {title}
                                        <div className={styles.icon}/>
                                    </label>
                                ))}
                                <Link to={href} className={styles['btn-block']}>
                                    <Button styleType="SECONDARY" classNames={styles.btn}>
                                        {btn}
                                    </Button>
                                </Link>
                            </div>

                            {radioList.map((item, idx) => contentsHTMLHandler(item, idx))}
                        </>
                    ) : (
                        <>
                            <h1 className={titleClasses}>{title}</h1>
                            {radioList.map((item, idx) =>
                                <EnhancedComponent{...props} key={idx}
                                                  isOpenManually={item.opened}
                                                  label={item.title}
                                                  contents={contentsHTMLHandler(item, idx)}
                                                  containerClassNames={styles['accordion-container']}
                                                  labelClassNames={styles['accordion-label']}
                                                  labelTextClassNames={styles['accordion-label-text']}
                                                  contentClassNames={styles['accordion-content']}
                                                  onToggleAccordion={() => onToggleAccordion(item.id)}
                                                  manuallyMode
                                                  turnOffAnimationOnClose
                                />)}
                            <Link to={href} className={styles['btn-block']}>
                                <Button styleType="SECONDARY" classNames={styles.btn}>
                                    {btn}
                                </Button>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        );
    }

    const iconPath = require(`../../../../services/data-service/home-page/assets/advantages/${img}`).default;
    return (
        <div className={styles.wrapper}>
            <div className={cx('container', classNames)}>
                <div className={styles.text}>
                    <div>
                        <h1 className={titleClasses}>{title}</h1>
                        <div className={styles.description}>
                            {textHandler(text, styles)}
                        </div>
                        <img src={iconPath} alt={alt_img} className={styles.img}/>
                        <Link to={href} className={styles['btn-block']}>
                            <Button styleType="SECONDARY" classNames={styles.btn}>
                                {btn}
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className={styles['image-block']}>
                    <img src={iconPath} alt={alt_img} className={styles.img}/>
                    <h1 className={titleSmClasses}>{title}</h1>
                </div>
            </div>
        </div>
    );
};

AdvantageItem.propTypes = {
    title: PropTypes.string,
    text: PropTypes.array,
    btn: PropTypes.string,
    href: PropTypes.string,
    img: PropTypes.string,
    alt_img: PropTypes.string,
    language: PropTypes.string,
    classNames: PropTypes.string,
};

export default AdvantageItem;