import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import PageService from '../../services/page-service';
import {withDataService} from '../common/hoc';
import {utilsAction} from '../../actions/common';
import {switchMenuActiveLinkID} from '../../actions/activity-hub';
import {errorURLs, hubURLs} from '../../services/api-urls';

import {
    RequestsForQuotations,
    EContracts,
    MyProducts,
    Notifications,
    PlaceProductFromExcel,
    PlaceProductFromForm,
    Profile,
} from './components/pages';
import {
    ContractForSupply,
    ContractConditions,
    OriginInvoiceWizard,
    PackingListWizard,
    PackingListLabelling,
    UploadDocumentContainer,
} from './components/pages/contract-maps';

class ActivityHubModule extends Component {
    componentDidMount() {
        const {baseAPIUrl, match: {path}} = this.props;

        if (baseAPIUrl !== path) {
            this.props.setBaseAPIUrl(path);
            this.props.switchDepartmentID(null);
        }
    }

    routePropsHandler = (props) => {
        const {activity_hub} = this.props.staticData;
        const {match: {params: {pages}}} = props;
        const pageService = new PageService();
        const pageIDs = pageService.pageID;

        return {
            pages,
            data: activity_hub,
            pageIDs,
        };
    };

    commonRouteHandler = (props) => {
        const {pages, data, pageIDs} = this.routePropsHandler(props);

        if (pages === 'requests') {
            return <RequestsForQuotations {...props}
                                          title={data.requests_for_quotations.title}
                                          pageID={pageIDs.activity_hub}/>;
        }

        if (pages === 'e-contracts') {
            return <EContracts {...props}
                               title={data.e_contracts.title}
                               pageID={pageIDs.activity_hub}/>;
        }

        if (pages === 'products') {
            return <MyProducts {...props} pageID={pageIDs.my_products}/>;
        }

        if (pages === 'notifications') {
            return <Notifications {...props}
                                  title={data.notifications.title}
                                  pageID={pageIDs.notifications}/>;
        }
    };

    contractRouteHandler = (props) => {
        const {pages, data, pageIDs} = this.routePropsHandler(props);
        const commonProps = {
            redirectUrl: hubURLs.e_contracts,
            pageID: pageIDs.activity_hub,
            withRedirect: true,
        };

        if (pages === 'map') {
            return <ContractForSupply {...props} {...commonProps}
                                      title={data.e_contracts.pages.contract_for_supply.title}/>;
        }

        if (pages === 'invoice/create') {
            return <OriginInvoiceWizard {...props} {...commonProps}
                                        title={data.e_contracts.pages.origin_invoice.title}/>;
        }

        if (pages === 'condition') {
            return <ContractConditions {...props} {...commonProps}/>;
        }

        const uploadDocumentPage = [
            'end-user-certificate',
            'performance-guarantee',
        ].find(item => item === pages);

        if (uploadDocumentPage) {
            const {data} = this.props.contractDocumentUpload;
            const title = data ? data.title || data.e_contracts.pages.upload_document.title : '';
            return <UploadDocumentContainer {...props} {...commonProps} title={title}/>;
        }
    };

    contractPackingRouteHandler = (props) => {
        const {pages, data, pageIDs} = this.routePropsHandler(props);

        if (pages === 'packing') {
            return <PackingListWizard {...props}
                                      title={data.e_contracts.pages.packing_list_wizard.title}
                                      pageID={pageIDs.activity_hub}
                                      redirectUrl={hubURLs.e_contracts}
                                      withRedirect/>;
        }

        if (pages === 'packing/labels') {
            return <PackingListLabelling {...props}/>;
        }
    };

    render() {
        const {match: {path: basePath}} = this.props;
        const pageService = new PageService();
        const pageIDs = pageService.pageID;
        const contractPages = 'map|invoice/create|condition|end-user-certificate|performance-guarantee';

        return (
            <Switch>
                <Route path={`${basePath}`} exact>
                    <Redirect to={`${basePath}/requests`}/>
                </Route>

                <Route exact
                       path={`${basePath}/:pages(requests|e-contracts|products|notifications)`}
                       render={(props) =>
                           this.commonRouteHandler(props)
                       }/>

                <Route exact
                       path={`${basePath}/products/upload`}
                       render={(props) =>
                           <PlaceProductFromExcel {...props}
                                                  pageID={pageIDs.my_products}
                                                  redirectUrl={hubURLs.products}
                                                  withRedirect/>
                       }/>

                <Route path={`${basePath}/product`}
                       render={(props) =>
                           <PlaceProductFromForm {...props}/>
                       }/>

                <Route path={`${basePath}/profile`}
                       render={(props) =>
                           <Profile {...props}
                                    pageID={pageIDs.profile}
                                    redirectUrl={hubURLs.hub}
                                    withRedirect/>
                       }/>

                <Route exact
                       path={`${basePath}/contract/:id/:pages(${contractPages})`}
                       render={(props) =>
                           this.contractRouteHandler(props)
                       }/>

                <Route exact
                       path={`${basePath}/contract/invoice/:id/:pages(packing|packing/labels)`}
                       render={(props) =>
                           this.contractPackingRouteHandler(props)
                       }/>

                <Route>
                    <Redirect to={errorURLs.not_found}/>
                </Route>
            </Switch>
        );
    }

    static propTypes = {
        language: PropTypes.string,
        baseAPIUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        match: PropTypes.shape({
            path: PropTypes.string,
        }),
        staticData: PropTypes.object,
        contractDocumentUpload: PropTypes.object,
        setBaseAPIUrl: PropTypes.func,
        switchDepartmentID: PropTypes.func,
        switchMenuActiveLinkID: PropTypes.func,
    };
}

const mapStateToProps = (props) => {
    const {
        profile: {language},
        activityHub: {contractMaps: {contractDocumentUpload}, utils},
        utils: {baseAPIUrl},
    } = props;
    return {
        language,
        contractDocumentUpload,
        baseAPIUrl,
        menuActiveLinkID: utils.menuActiveLinkID,
    };
};

const mapDispatchToProps = (dispatch) => {
    const {setBaseAPIUrl, switchDepartmentID} = utilsAction;

    return bindActionCreators({
        setBaseAPIUrl,
        switchDepartmentID,
        switchMenuActiveLinkID,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(ActivityHubModule);