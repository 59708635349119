const initialState = {
    list: [],
};

const formSections = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case 'INITIALIZE_PLACE_PRODUCT_SECTIONS':
        case 'UPDATE_PLACE_PRODUCT_SECTION_SAVE_STATUS':
        case 'UPDATE_PLACE_PRODUCT_SECTION_DISABLED_STATUS':
            return {
                list: payload,
            };

        case 'RESET_FORM_FIELDS' :
            return initialState;

        default:
            return state;
    }
};

export default formSections;