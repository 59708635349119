import {terms_and_conditions_en} from './en';
import {terms_and_conditions_es} from './es';
import {terms_and_conditions_fr} from './fr';
import {terms_and_conditions_ru} from './ru';

export {
    terms_and_conditions_en,
    terms_and_conditions_es,
    terms_and_conditions_fr,
    terms_and_conditions_ru,
};