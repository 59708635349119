import {createRequestHandler} from '../../../utils';
import {ADD_TO_QUOTATION_SUCCESS} from './add-to-quotation';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAILURE = 'PRODUCT_DETAILS_FAILURE';

export const detailsRequest = createRequestHandler('PRODUCT_DETAILS');

const initialState = {
    details: null,
    loading: true,
    error: null,
    isDocumentsShown: true,
    imageSliderPopup: false, // need work
    inCart: {
        status: false,
    },
};

const productDetails = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PRODUCT_DETAILS_REQUEST:
            return initialState;

        case PRODUCT_DETAILS_SUCCESS:
            return {
                ...initialState,
                details: payload.detail,
                loading: false,
                inCart: payload.detail.inCart,
            };

        case PRODUCT_DETAILS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case ADD_TO_QUOTATION_SUCCESS:
            return {
                ...state,
                inCart: {
                    ...state.inCart,
                    status: true,
                },
            };

        case 'SET_PRODUCT_CURRENT_CURRENCY':
            return {
                ...state,
                inCart: {
                    ...state.inCart,
                    currency: payload,
                },
            };

        case 'PRODUCT_DOCUMENTS_SHOWN':
            return {
                ...state,
                isDocumentsShown: payload,
            };

        default:
            return state;
    }
};

export default productDetails;