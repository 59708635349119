export const URLs = {
    pub: '/pub',
    news: '/pub/news',
    invitation: '/pub/invitation',
    reset_password: '/pub/restore',
    independent_technical_surveying: '/independent-technical-surveying',
    core_services: '/core-services',
};

export const marketplaceURLs = {
    marketplace: '/marketplace',
    marketplace_search: '/marketplace/search',
    quotation: '/marketplace/quotation',
    quotation_excel: '/marketplace/quotation/upload',
    quotation_excel_format: '/marketplace/quotation/upload/excel-format',
};

export const marketplaceBreadcrumbsStaticURLs = [
    marketplaceURLs.quotation_excel,
    marketplaceURLs.quotation_excel_format,
];

export const hubURLs = {
    hub: '/hub',
    requests: '/hub/requests',
    e_contracts: '/hub/e-contracts',
    notifications: '/hub/notifications',
    products: '/hub/products',
    product_create_manual: '/hub/product/create',
    products_excel: '/hub/products/upload',
    profile: '/hub/profile',
    profile_bank: '/hub/profile/bank',
    profile_consignee: '/hub/profile/consignee',
    profile_exporterforwarder: '/hub/profile/exporterforwarder',
    profile_importerforwarder: '/hub/profile/importerforwarder',
    profile_notifyparty: '/hub/profile/notifyparty',
    profile_shipper: '/hub/profile/shipper',
    access_setup: '/hub/profile/access',
};

export const hubBreadcrumbsStaticURLs = [
    hubURLs.notifications,
    hubURLs.profile,
    hubURLs.access_setup,
    `${hubURLs.profile_bank}/create`,
    `${hubURLs.profile_consignee}/create`,
    `${hubURLs.profile_exporterforwarder}/create`,
    `${hubURLs.profile_importerforwarder}/create`,
    `${hubURLs.profile_notifyparty}/create`,
    `${hubURLs.profile_shipper}/create`,
];

export const hubBreadcrumbsStaticURLsWithIdParams = [
    `${hubURLs.profile_bank}/update`,
    `${hubURLs.profile_consignee}/update`,
    `${hubURLs.profile_exporterforwarder}/update`,
    `${hubURLs.profile_importerforwarder}/update`,
    `${hubURLs.profile_notifyparty}/update`,
    `${hubURLs.profile_shipper}/update`,
];

export const staticURLs = {
    home: '/',
    about_marketplace: '/about-marketplace',
    about_independent_technical_surveying: '/about-independent-technical-surveying',
    about_core_services: '/about-core-services',
    terms_and_conditions: '/terms-and-conditions',
    privacy_policy: '/privacy-policy',
    careers: '/careers',
    faq: '/faq',
    request_access: '/request-access',
};

export const errorURLs = {
    not_found: '/404',
    not_acceptable: '/406',
    internal_server_error: '/500',
};

export const publicURLs = [
    ...Object.values(errorURLs),
    ...Object.values(staticURLs),
    URLs.news,

    /* next page need redirect to request access public url */
    URLs.independent_technical_surveying,
    URLs.core_services,
];

export const breadcrumbsStaticURLs = [
    /**
     We don't have breadcrumbs on some of this pages,
     but we don't need to start updating breadcrumbs when we on this pages.
     That's why we included them for list.
     */
    ...marketplaceBreadcrumbsStaticURLs,
    ...hubBreadcrumbsStaticURLs,
    ...Object.values(staticURLs),
    ...Object.values(errorURLs),
    URLs.independent_technical_surveying,
    URLs.core_services,
];

export const publicSubstring = [URLs.pub, URLs.invitation, URLs.reset_password];
export const publicONLYSubstring = [URLs.news, URLs.invitation, URLs.reset_password];
export const hiddenUrlsForTrialUser = [
    // importer
    marketplaceURLs.quotation,
    marketplaceURLs.quotation_excel,
    marketplaceURLs.quotation_excel_format,

    // exporter
    hubURLs.products,
    hubURLs.product_create_manual,
    hubURLs.products_excel,

    // both
    hubURLs.hub,
    hubURLs.requests,
    hubURLs.e_contracts,
];

export const mainPageUrl = (session) => session ? marketplaceURLs.marketplace : staticURLs.home;

export const detectingPublicUrl = (pathname) => {
    return publicURLs.find(item => pathname === item || publicSubstring.find(item => pathname.includes(item)));
};

export const detectingStaticUrl = (pathname) => {
    return Object.values(staticURLs).find(item => pathname === item);
};

export const detectingHiddenUrlForTrialUser = (pathname) => {
    return hiddenUrlsForTrialUser.find(item => pathname === item);
};

export const detectingErrorUrl = (pathname) => {
    return Object.values(errorURLs).find(item => pathname === item);
};

export const withRedirectFromPubToPrivate = (pathname) => {
    const pubPage = publicSubstring.find(item => pathname.includes(item));
    const pubONLYPage = publicONLYSubstring.find(item => pathname.includes(item));

    return {
        redirect: pubPage && !pubONLYPage,
        substring: pubPage || '',
    };
};

