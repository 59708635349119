import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Redirect, withRouter} from 'react-router-dom';
import {withServices} from '../service-context';
import {withDataService} from '../common/hoc';
import {errorURLs, marketplaceURLs, staticURLs} from '../../services/api-urls';
import {invitationFormPublicRequest} from '../../reducers/public-data/invitation/form';
import {invitationFormPublicUploadRequest} from '../../reducers/public-data/invitation/upload-data';
import {
    getDataFromFields,
    onChangeFieldValue,
    onChangeRadioButtonValue,
    onCheckingRequiredFieldData,
    onResetFormError,
    transformFieldData,
} from '../activity-hub/components/common/handlers/field-handlers';
import {onCreateFormData} from '../common/handlers';
import {resetInvitationFormPublicUpload} from '../../actions/common';
import FormFieldCreator from '../activity-hub/components/common/handlers/form-field-creator';
import FormFieldConstructor from '../common/form-field-constructor';
import sweetAlert from "../common/sweet-alert";

import Preloader from '../common/preloader';
import Button from '../common/button';

import classnames from 'classnames/bind';
import styles from './invitation-page.module.scss';

class InvitationPage extends Component {
    state = {
        id: null,
        title: null,
        list: null,
        organization: null,
        formError: false,
        fieldsWithError: null,
        removeFromErrorList: false,
        passwordConfirmationError: false,
        userError: null,
        organizationError: null,
    };

    formFieldCreator = new FormFieldCreator();

    componentDidMount() {
        /* noAccessRight = true after session_expired */
        const {match: {params: {id}}, session, noAccessRight} = this.props;
        this.setState({id});

        if (!session && session !== null && !noAccessRight) {
            this.props.fetchInvitationForm(id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {formData: {data, list}} = this.props;
            if (data && list) {
                this.props.fetchInvitationForm(this.state.id);
            }
        }

        if (prevProps.formData !== this.props.formData) {
            const {data, list} = this.props.formData;
            if (data && list) {
                const transformList = list.map((field) => transformFieldData(field));

                this.setState({
                    title: data.title,
                    list: transformList,
                    organization: data.organization,
                });
            }
        }

        if (prevState.fieldsWithError !== this.state.fieldsWithError) {
            if (this.state.fieldsWithError) {
                if (!this.state.removeFromErrorList) {
                    this.state.fieldsWithError.forEach((fieldName) => {
                        this.onSetResetError(fieldName);
                    });

                } else {
                    this.setState({removeFromErrorList: false});
                }
            }
        }

        if (prevState.passwordConfirmationError !== this.state.passwordConfirmationError) {
            this.onSetResetError('user[password_confirmation]', this.state.passwordConfirmationError);
        }

        if (prevProps.uploadData.error !== this.props.uploadData.error) {
            const {error} = this.props.uploadData;
            if (error) {
                if (typeof error === 'string') {
                    sweetAlert('error', error);

                } else {
                    const keys = Object.keys(error);
                    const userError = [];
                    const organizationError = [];
                    const fieldsWithError = [];

                    keys.forEach(key => {
                        const array = key.split('.');
                        const fieldName = `${array[0]}[${array[1]}]`;
                        const currentField = this.state.list.find(({name}) => name === fieldName);
                        if (currentField) {
                            const props = currentField.props;

                            if (!props || !props.includes('readonly')) {
                                fieldsWithError.push(fieldName);
                            }

                            if (array[0] !== 'organization') {
                                userError.push({key, value: error[key]});
                            } else {
                                organizationError.push({key, value: error[key]});
                            }
                        }
                    });

                    this.setState({userError, organizationError, fieldsWithError});
                }
            }
        }
    }

    formFieldHandler = (formFields, listeners) => {
        const fields = this.formFieldCreator.getFormFields(formFields);
        const {password_requirements: {text, list}} = this.props.staticData;

        return fields.map((field, idx) => {
            if (field.name.includes('password')) {
                const tooltipText = (
                    <ul className={styles.list}>
                        {text}
                        {list.map((item, idx) => <li key={idx}>{item}</li>)}
                    </ul>
                );

                field = {
                    ...field,
                    tooltip: {
                        text: tooltipText,
                        classNamesText: styles['tooltip-text'],
                        classNamesContainer: styles.tooltip,
                    },
                };
            }

            return <FormFieldConstructor {...listeners} key={idx} field={field}/>;
        });
    };

    onSetResetError = (elementName, error = true) => {
        const element = document.querySelector(`[name='${elementName}']`);

        if (error) {
            element.parentNode.classList.add(styles.required);
        } else {
            element.parentNode.classList.remove(styles.required);
        }
    };

    onUpdateFieldsValue = (name, value) => {
        let list = onChangeFieldValue(this.state.list, name, value);

        const passwordField = list.find(({name}) => name === 'user[password]');
        const passwordConfirmationFieldIdx = list.findIndex(({name}) => name === 'user[password_confirmation]');
        let passwordConfirmationField = list[passwordConfirmationFieldIdx];

        if (name === 'user[password]') {
            if (passwordConfirmationField.correct !== null) {
                passwordConfirmationField = {
                    ...passwordConfirmationField,
                    correct: null,
                };
            }

            if (passwordConfirmationField.value) {
                if (passwordField.value === passwordConfirmationField.value) {
                    passwordConfirmationField = {
                        ...passwordConfirmationField,
                        correct: true,
                    };
                }
            }
        }

        if (name === 'user[password_confirmation]') {
            if (this.state.passwordConfirmationError) {
                this.setState({passwordConfirmationError: false});
            }

            passwordConfirmationField = {
                ...passwordConfirmationField,
                correct: passwordConfirmationField.value
                    ? passwordField.value === passwordConfirmationField.value
                    : null,
            };
        }

        const {fieldsWithError} = this.state;
        if (fieldsWithError && fieldsWithError.includes(name)) {
            this.onSetResetError(name, false);

            const array = name.slice(0, -1).split('[');
            const errorKey = `${array[0]}.${array[1]}`;
            const organizationError = array[0] === 'organization';

            const handler = (error, stateKey) => {
                const idx = error.findIndex(({key}) => key === errorKey);
                let updatedError = error;

                if (idx !== -1) {
                    updatedError = [
                        ...error.slice(0, idx),
                        ...error.slice(idx + 1),
                    ];

                    this.setState({[stateKey]: updatedError});
                }
            };

            if (organizationError) {
                handler(this.state.organizationError, 'organizationError');

            } else {
                handler(this.state.userError, 'userError');
            }

            const idx = fieldsWithError.findIndex(fieldName => fieldName === name);
            const updatedArray = [
                ...fieldsWithError.slice(0, idx),
                ...fieldsWithError.slice(idx + 1),
            ];
            let updatedError = null;

            if (updatedArray.length > 0) {
                updatedError = updatedArray;
                this.setState({removeFromErrorList: true});
            }

            this.setState({fieldsWithError: updatedError});
        }

        list = [
            ...list.slice(0, passwordConfirmationFieldIdx),
            passwordConfirmationField,
            ...list.slice(passwordConfirmationFieldIdx + 1),
        ];

        this.setState({list});
        this.onResetFormFieldError();
    };

    onRadioButtonChange = ({target: {id, name, value}}) => {
        let updatedFields = onChangeRadioButtonValue(this.state.list, id, name, value);
        updatedFields = onChangeFieldValue(updatedFields, name, value);
        this.setState({list: updatedFields});
        this.onResetFormFieldError();
    };

    onClickSelectItem = (fieldName, {value}) => this.onUpdateFieldsValue(fieldName, value);

    onInputChange = ({target: {name, value}}) => this.onUpdateFieldsValue(name, value);

    onInputPhoneChange = (name, value) => this.onUpdateFieldsValue(name, value);

    onResetFormFieldError = () => {
        if (this.state.formError) {
            onResetFormError(this.state.id);
            this.setState({formError: false});
        }
    };

    onConfirm = (e) => {
        e.preventDefault();
        const {target: form} = e;
        const {list} = this.state;

        let fieldsWithError = [];

        if (this.state.fieldsWithError) {
            fieldsWithError = [...this.state.fieldsWithError];
        }

        const emailFields = list.filter(({name}) => name.includes('[email]'));
        emailFields.forEach(({name, value}) => {
            if (!value.includes('@')) {
                fieldsWithError.push(name);
            }
        });

        let passwordValid = true;
        /* password confirmation must be true */
        const passwordConfirmationField = list.find(({name}) => name === 'user[password_confirmation]');
        if (!passwordConfirmationField.correct) {
            passwordValid = false;
        }

        const requiredFieldFilled = onCheckingRequiredFieldData(list, form);

        const valid = requiredFieldFilled && passwordValid && fieldsWithError.length === 0;

        if (valid) {
            const data = getDataFromFields(list);
            const formData = onCreateFormData(data);
            this.props.onUploadInvitationForm(this.state.id, {formData});
        }

        if (!requiredFieldFilled) {
            this.setState({formError: true});
        }

        if (fieldsWithError.length > 0) {
            this.setState({fieldsWithError});
        }

        if (!passwordValid) {
            this.setState({passwordConfirmationError: true});
        }
    };

    componentWillUnmount() {
        if (this.props.uploadData.confirmed) {
            this.props.resetInvitationFormPublicUpload();
        }
    }

    render() {
        const {
            session,
            formData, uploadData,
            staticData: {
                invitation: {
                    subtitle,
                    user_section_title,
                    organization_section_title,
                    button: {cancel, confirm},
                },
            },
        } = this.props;
        const {list, organization, userError, organizationError} = this.state;
        let {title} = this.state;
        title = title ? title.split(',') : null;

        const loading = formData.loading;

        const listeners = {
            onRadioButtonChange: this.onRadioButtonChange,
            onClickSelectItem: this.onClickSelectItem,
            onInputChange: this.onInputChange,
            onInputPhoneChange: this.onInputPhoneChange,
        };

        let userFields = [],
            organizationFields = [],
            organizationFieldsFirstBlock = [],
            organizationFieldsSecondBlock = [],
            organizationThirdFirstBlock = [];

        if (list) {
            userFields = list.filter(({sectionId}) => sectionId === 'user_details');

            if (userFields.length > 0) {
                userFields = this.formFieldHandler(userFields, listeners);
            }

            if (!organization) {
                organizationFields = list.filter(({sectionId}) => sectionId === 'organization_details');

                if (organizationFields.length > 0) {
                    const radioFieldIdx = organizationFields.findIndex(({type}) => type === 'radio');
                    organizationFields = this.formFieldHandler(organizationFields, listeners);
                    organizationFieldsFirstBlock = organizationFields.slice(0, radioFieldIdx);
                    organizationFieldsSecondBlock = organizationFields.slice(radioFieldIdx, radioFieldIdx + 1);
                    organizationThirdFirstBlock = organizationFields.slice(radioFieldIdx + 1);
                }
            }
        }

        if (organization) {
            const keys = Object.keys(organization);
            organizationFields = keys.map((key, idx) =>
                <div key={idx} className={styles['section-info']}>{organization[key]}</div>,
            );
        }

        const errorBlockHandler = (errorArray) => {
            return (
                <ul className={styles['error-block']}>
                    {errorArray.map(({value}, idx) => {
                        if (Array.isArray(value) && value.length > 1) {
                            return (
                                <li key={idx} className={styles.item}>
                                    {value.map((item, idx) => <div key={idx}>{item}</div>)}
                                </li>
                            );
                        }

                        return <li key={idx} className={styles.item}>{value}</li>;
                    })}
                </ul>
            );
        };

        const cx = classnames.bind(styles);

        return !session
            ? (
                <div className={styles['page-container']}>
                    {loading
                        ? <Preloader/>
                        : list ? (
                            <form id={this.state.id} className={styles.container} onSubmit={this.onConfirm}>
                                <div className={styles.title}>
                                    {title && (
                                        <>
                                            <span>{`${title[0]},`}</span>
                                            <span>{title[1]}</span>
                                        </>
                                    )}
                                </div>
                                <div className={styles.subtitle}>{subtitle}</div>

                                <div className={styles['sections-container']}>
                                    <div>
                                        <div className={styles['section-title']}>{user_section_title}</div>
                                        <div className={cx('fields', 'fields-revert')}>{userFields}</div>
                                        {userError && errorBlockHandler(userError)}
                                    </div>

                                    {organizationFields.length > 0 && (
                                        <div className={styles['organization-section']}>
                                            <div className={styles['section-title']}>{organization_section_title}</div>
                                            {!organization ? (
                                                <div className={styles.fields}>
                                                    {organizationFieldsFirstBlock}
                                                    <div
                                                        className={styles['radio-group']}>{organizationFieldsSecondBlock}</div>
                                                    {organizationThirdFirstBlock}
                                                </div>
                                            ) : organizationFields}
                                            {organizationError && errorBlockHandler(organizationError)}
                                        </div>
                                    )}
                                </div>

                                <div className={styles['buttons-container']}>
                                    <Button styleType={'SECONDARY'}
                                            classNames={styles['cancel-btn']}
                                            onClick={() => this.props.history.replace(staticURLs.home)}>
                                        {cancel}
                                    </Button>
                                    <Button type={'submit'}
                                            classNames={styles['confirm-btn']}
                                            dataLoading={uploadData.loading}>
                                        {confirm}
                                    </Button>
                                </div>
                            </form>
                        ) : null}
                </div>
            ) : !uploadData.confirmed
                ? <Redirect to={errorURLs.not_found}/>
                : <Redirect to={marketplaceURLs.marketplace}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        noAccessRight: PropTypes.bool,
        language: PropTypes.string,
        formData: PropTypes.shape({
            loading: PropTypes.bool,
            data: PropTypes.object,
            list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        }),
        uploadData: PropTypes.shape({
            loading: PropTypes.bool,
            confirmed: PropTypes.bool,
            error: PropTypes.object,
        }),
        fetchInvitationForm: PropTypes.func,
        onUploadInvitationForm: PropTypes.func,
        resetInvitationFormPublicUpload: PropTypes.func,
    };
}

const mapServicesToProps = ({publicService}) => ({
    fetchInvitationForm: invitationFormPublicRequest(publicService.invitation),
    onUploadInvitationForm: invitationFormPublicUploadRequest(publicService.invitation),
});

const mapStateToProps = ({session: {session, noAccessRight}, publicData: {homePage: {language}, invitation}}) => ({
    language,
    session,
    noAccessRight,
    formData: invitation.form,
    uploadData: invitation.uploadData,
});

const mapDispatchToProps = (dispatch, {
    fetchInvitationForm,
    onUploadInvitationForm,
}) => {
    return bindActionCreators({
        fetchInvitationForm,
        onUploadInvitationForm,
        resetInvitationFormPublicUpload,
    }, dispatch);
};

export default compose(
    withRouter,
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(InvitationPage);