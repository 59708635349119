export const challenges_ru = [
    {
        title: `Ограниченный доступ к простым платформам электронной коммерции с 
        интуитивно понятным интерфейсом, особенно для малого и среднего бизнеса`,
        text: `Низкая доступность и, следовательно, ограниченный доступ к электронной системе закупок 
        корпоративного уровня, а также сложность ее использования, которой пользуются только 
        крупнейшие операторы воздушного и наземного транспорта.`,
        url: 'challenge_4.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Неполная автоматизация экспортной документации',
        text: `Неполное оформление счетов-фактур и упаковочных листов для составления полного комплекта 
        экспортной документации. Кроме того, смешение ручных и электронных систем в процессе закупок 
        создает сложности и путаницу.`,
        url: 'challenge_5.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Вопросы доверия, особенно у новых клиентов',
        text: `Отсутствие доверия со стороны клиентов при совершении сделок в Интернете порождает страх, 
        нерешительность и неудачные покупки. Кроме того, бюрократия в государственных корпорациях 
        приводит к задержкам платежей, что затрудняет деловые отношения между потребителями и поставщиками.`,
        url: 'challenge_7.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Финансовая безответственность и отсутствие возможности отслеживать грузы в режиме реального времени',
        text: `Отсутствие возможности получать информацию о состоянии груза в режиме реального времени в сочетании 
        с отсутствием финансовой ответственности за логистику и своевременную доставку запасных частей.`,
        url: 'challenge_6.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Высокие цены от компаний-посредников',
        text: `Приходилось ли вам когда-нибудь становиться жертвой «посредников», которые часто 
        завышают цены на запчасти, иногда даже втрое по сравнению с обычной ценой?`,
        url: 'challenge_2.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Вопросы безопасности',
        text: `Сделки сопровождаются мошенническими действиями, например, неисполнением обязательств 
        по поставкам, потерей денег и получением дефектной продукции.`,
        url: 'challenge_3.svg',
        alt: 'Challenge image',
    },
];