import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import getReduxStore from '../../../utils/get-redux-store';
import DataService from '../../../services/data-service';

import classnames from 'classnames/bind';
import styles from './sweet-alert.module.scss';
import btnStyles from '../button/button.module.scss';

const sweetAlert = (
    type, data,
    isConfirmButtonText = false, isConfirmButton = false,
    isCancelButton = false, customTimer = 1500) => {
    /*
        Available types of alert:
        - 'success',
        - 'error',
        - 'warning',
        - 'info',
        - 'question'.

        Format of data parameter:
        - data as 'string' -> ex. 'some text'
        - data as object with text for title -> ex. {title: 'Title', text: 'Text'}
        - data as array of strings -> ex. ['text_1', 'text_2']
        - data as node -> ex. const someNode = <div>I`m a node</div>; sweetAlert('type_name', someNode).then(() => {})
    */

    let title = false;
    let text = data;

    if (!data || Object.keys(data).length === 0) {
        text = '';
    }

    if (data && Object.keys(data).length !== 0) {
        if (data.hasOwnProperty('title')) {
            title = data.title;
            text = data.text;
        }
    }

    if (Array.isArray(data)) {
        text = data.join(' ');
    }

    const {session: {session}, profile: {language}} = getReduxStore();
    const dataService = new DataService();
    const staticData = dataService.getStaticData(language, session);

    const {ok, cancel} = staticData.alert_modal.button;

    if (!isConfirmButtonText) {
        isConfirmButtonText = ok;
    }

    if (type === 'question') {
        isConfirmButton = true;
        isCancelButton = true;
    }

    if (type === 'info' || type === 'warning') {
        isConfirmButton = true;
    }

    let iconColor;

    switch (type) {
        case 'error':
        case 'warning':
        case 'question':
            iconColor = '#e63a3a';
            break;

        default:
            iconColor = '#098448'; // for success and info
    }

    const cx = classnames.bind(styles);
    const cx2 = classnames.bind(btnStyles);

    const modalClasses = cx('modal', `modal-${type}`, {
        'with-btn': isConfirmButton,
    });

    const titleClasses = cx('title', {'error': type === 'error'});

    const confirmBtnClasses = cx2('btn', 'primary');
    const cancelBtnClasses = cx2('btn', 'secondary');

    const params = {
        icon: type,
        iconColor,
        titleText: title,
        html: text,
        customClass: {
            container: styles.container,
            popup: modalClasses,
            icon: styles.icon,
            title: titleClasses,
            htmlContainer: styles[`${type}-text`],
            actions: styles['btn-block'],
            confirmButton: `${confirmBtnClasses} ${styles.btn} ${styles['btn-confirm']}`,
            cancelButton: `${cancelBtnClasses} ${styles.btn} ${styles['btn-cancel']}`,
        },
        showClass: {
            popup: styles['modal-show'],
        },
        hideClass: {
            popup: styles['modal-hide'],
        },
        showConfirmButton: isConfirmButton, // primary button type
        showCancelButton: isCancelButton, // secondary button type,
        confirmButtonText: isConfirmButtonText,
        cancelButtonText: (
            <>
                <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%"/>
                </svg>
                {cancel}
            </>
        ),
        reverseButtons: true,
        buttonsStyling: false,
        timer: !isConfirmButton && !isCancelButton ? customTimer : false, // 1.5 sec
    };

    return withReactContent(Swal).fire(params);
};

export default sweetAlert;