import React from 'react';
import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';

import styles from './info-pop-up.module.scss';

const InfoPopUp = (props) => {
    const {switcher, isOpen, arrow, placement, children, containerClassNames, onClose} = props;

    return (
        <Tippy
            content={(
                <div className={`${styles.container} ${containerClassNames}`}>
                    <div className={styles['close-btn']}
                         onClick={(e) => {
                             e.stopPropagation();
                             onClose();
                         }}/>
                    {children}
                </div>
            )}
            popperOptions={{modifiers: [{name: 'flip', enabled: false}]}}
            animation={'shift-away'}
            arrow={arrow}
            appendTo={'parent'}
            interactive={true}
            maxWidth="none"
            role="pop-up"
            offset={[0, 0]}
            placement={placement}
            visible={isOpen}
        >
            {switcher}
        </Tippy>
    );
};

InfoPopUp.defaultProps = {
    arrow: false,
    placement: 'bottom',
};

InfoPopUp.propTypes = {
    switcher: PropTypes.node,
    isOpen: PropTypes.bool,
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    children: PropTypes.node,
    containerClassNames: PropTypes.string,
    onClose: PropTypes.func,
};

export default InfoPopUp;