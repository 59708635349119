import * as registrationAction from './registration';
import * as loginAction from './login';
import * as modalAction from './modal';
import * as searchAction from './search';
import * as utilsAction from './utils';

export const switchLanguage = (newLanguage) => {
    return {
        type: 'SWITCH_LANGUAGE',
        payload: newLanguage,
    };
};

export const switchHomePageLanguage = (newLanguage) => {
    return {
        type: 'SWITCH_HOME_PAGE_LANGUAGE',
        payload: newLanguage,
    };
};

export const resetFileUpload = () => {
    return {type: 'RESET_FILE_UPLOAD'};
};

export const redirectAfterDenialOfAccess = (newStatus) => {
    return {
        type: 'REDIRECT_AFTER_DENIAL_OF_ACCESS',
        payload: newStatus,
    };
};

export const resetDocument = () => {
    return {type: 'RESET_DOCUMENT'};
};

export const resetRequestLock = () => {
    return {type: 'RESET_REQUEST_LOCK'};
};

export const resetInvitationFormPublicUpload = () => {
    return {type: 'RESET_INVITATION_FORM_PUBLIC_UPLOAD'};
};

export const setInitialResetPasswordReduxState = () => {
    return {type: 'SET_INITIAL_RESET_PASSWORD_REDUX_STATE'};
};

export const setInitialResetPasswordFormPublicUpload = () => {
    return {type: 'SET_INITIAL_RESET_PASSWORD_FORM_PUBLIC_UPLOAD'};
};

export {registrationAction, loginAction, modalAction, searchAction, utilsAction};