import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../common/hoc';
import {rfq_statuses} from '../../../../../../common/status-indicator/statuses';

import SubQuotationItem from '../sub-quotation-item';
import ButtonConstructor from '../../../../../../../../common/button-constructor';

import styles from './sub-quotation.module.scss';

const EnhancedComponent = withAccordion()(SubQuotationItem);

const SubQuotation = (props) => {
    const {
        rfq_sub_number,
        rfq_sub_id,
        organization,
        total,
        status,
        buttons,
        updatingItem,
        onButtonClick,
        ...rest
    } = props;
    const {userInteraction} = useSelector(({profile}) => profile);

    let isViewContractButton = false;
    if (buttons.length !== 0) {
        const {method} = buttons[0].remote;
        isViewContractButton = method === 'GET';
    }

    const createContractLoading = updatingItem && updatingItem.rfq_sub_id === rfq_sub_id ? updatingItem.loading : false;
    const createContractBtn = buttons.length > 0 && (userInteraction === 'importer' || isViewContractButton) ? (
        <div className={`${styles.column} ${styles['btn-block']}`}>
            <ButtonConstructor {...buttons[0]}
                               classNames={styles.btn}
                               preloaderClassNames={styles['btn-preloader']}
                               dataLoading={createContractLoading}
                               onClick={(...args) => onButtonClick(rfq_sub_id, ...args)}/>
        </div>
    ) : null;

    const subQuotationItem = (
        <div className={styles.container}>
            <div className={styles.column}>{rfq_sub_number}</div>
            <div className={`${styles.column} ${styles.organization}`}>{organization}</div>
            <div className={styles.column}>{total}</div>
            {createContractBtn}
        </div>
    );

    return (
        <EnhancedComponent {...rest}
                           label={subQuotationItem}
                           containerActiveClassNames={styles['accordion-container-active']}
                           containerClassNames={styles['accordion-container']}
                           labelClassNames={styles['accordion-label']}
                           contentClassNames={styles['accordion-body']}
                           withActiveContainer
                           withDeleteItem={status.value !== rfq_statuses.contracted.value}
        />
    );
};

SubQuotation.propTypes = {
    rfq_sub_number: PropTypes.string,
    organization: PropTypes.string,
    total: PropTypes.string,
    status: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
    buttons: PropTypes.arrayOf(PropTypes.object),
    updatingItem: PropTypes.shape({
        loading: PropTypes.bool,
        rfq_sub_id: PropTypes.number,
    }),
    onButtonClick: PropTypes.func,
};

export default SubQuotation;