import {createRequestHandler} from '../../../utils';
import FormFieldBuilder from '../../../utils/form-field-builder/form-field-builder';

export const FORM_FIELDS_REQUEST = 'FORM_FIELDS_REQUEST';
export const FORM_FIELDS_SUCCESS = 'FORM_FIELDS_SUCCESS';
export const FORM_FIELDS_FAILURE = 'FORM_FIELDS_FAILURE';

export const FORM_FIELDS_UPDATING_REQUEST = 'FORM_FIELDS_UPDATING_REQUEST';
export const FORM_FIELDS_UPDATING_SUCCESS = 'FORM_FIELDS_UPDATING_SUCCESS';
export const FORM_FIELDS_UPDATING_FAILURE = 'FORM_FIELDS_UPDATING_FAILURE';

export const formFieldsRequest = createRequestHandler('FORM_FIELDS', {language: true});
export const updatingFormFieldsRequest = createRequestHandler('FORM_FIELDS_UPDATING', {language: true});

const formFieldBuilder = new FormFieldBuilder();

const initialState = {
    loading: true,
    updating: false,
    list: null,
    error: null,
};

const onInitFields = ({list, language}) => {
    return list.map((item) => {
        const {type, list, value} = item;

        if (type === 'select') {
            let transformValue = value;
            let transformList = [];

            if (list && list.length > 0) {
                if (!value && list.length === 1) {
                    transformValue = list[0].value;
                }

                transformList = formFieldBuilder.transformSelectFieldList(list, language);
            }

            return {
                ...item,
                list: transformList,
                value: transformValue,
            };
        }

        return item;
    });
};

const formFields = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case FORM_FIELDS_REQUEST:
            return initialState;

        case FORM_FIELDS_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case FORM_FIELDS_SUCCESS:
        case FORM_FIELDS_UPDATING_SUCCESS:
            return {
                list: onInitFields(payload),
                loading: false,
                updating: false,
                error: null,
            };

        case FORM_FIELDS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case FORM_FIELDS_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'RESET_FORM_FIELDS':
            return initialState;

        default:
            return state;
    }
};

export default formFields;