import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './skeleton-preloader.module.scss';

const SkeletonPreloader = ({type, children, loading, classNames}) => {
    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames, {
        'loading': loading,
        'text': type === 'TEXT',
    });

    return (
        <div className={containerClasses}>
            <div className={styles.skeleton}/>
            {children}
        </div>
    );
};

SkeletonPreloader.defaultProps = {
    type: 'CONTAINER',
    loading: false,
};

SkeletonPreloader.propTypes = {
    type: PropTypes.oneOf(['CONTAINER', 'TEXT']),
    children: PropTypes.node,
    loading: PropTypes.bool,
    classNames: PropTypes.string,
};

export default SkeletonPreloader;