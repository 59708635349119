import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import FormFieldCreator from '../../../../../../common/handlers/form-field-creator';

import Preloader from '../../../../../../../../common/preloader';
import CurrencySection from './components/currency-section';
import FormFieldConstructor from '../../../../../../../../common/form-field-constructor';
import Button from '../../../../../../../../common/button';
import PGDraft from './components/pg-draft/pg-draft';

import styles from './payment-setup.module.scss';

const formFieldCreator = new FormFieldCreator();
const formFieldHandler = (formFields, listeners) => {
    const fields = formFieldCreator.getFormFields(formFields);

    return fields.map((field, idx) =>
        <FormFieldConstructor {...listeners} key={idx} field={field}/>,
    );
};

const PaymentSetup = (props) => {
    const {
        staticData, userInteraction, list,
        bankAccountsListLoading: loading, bankAccountsListUpdating: updating, accounts,
        removingAccount, removeBankAccountLoading,
        onInputChange, onClickSelectItem, onRadioButtonChange,
        onToggleAccordion, onRemoveAccount,
    } = props;

    const {pages: {payment_setup: {bank_accounts_title, button: {add_account}}}} = staticData.activity_hub.profile;

    if (!list) {
        return null;
    }

    const filterFields = (id) => list.filter(({sectionId}) => sectionId === id);

    const performanceGuaranteeFields = filterFields('performance_guarantee');
    const bankDetailsFields = filterFields('bank_details');
    const pgDetailsFields = filterFields('pg_details');

    const bankAccountsTabBlock = loading || updating
        ? <Preloader classNames={styles.preloader} type={'SECONDARY'}/>
        : accounts.map((account, idx) => {
            const {id, label, href, list, isOpen} = account;
            const labelBlock = (
                <div className={styles['currency-title']}>
                    {label}
                    <Link to={href.add}>
                        <Button styleType={'WITH_PLUS'} classNames={styles['add-btn']}>{add_account}</Button>
                    </Link>
                </div>
            );

            return <CurrencySection key={idx}
                                    staticData={staticData}
                                    containerClassNames={styles.currency}
                                    labelClassNames={styles.label}
                                    contentClassNames={styles.content}
                                    label={labelBlock}
                                    banksList={list}
                                    isOpenManually={isOpen}
                                    onToggleAccordion={() => onToggleAccordion(id)}
                                    onRemoveAccount={onRemoveAccount}
                                    removeBankAccount={{
                                        id: removingAccount,
                                        loading: removeBankAccountLoading,
                                    }}
                                    manuallyMode/>;
        });

    let performanceGuaranteeTabBlock = null;
    if (userInteraction === 'importer') {
        const listeners = {
            onInputChange,
            onClickSelectItem,
            onRadioButtonChange,
        };

        let performanceGuaranteeBlock = [],
            bankDetailsBlock = [],
            pgDetailsBlock = [],
            pgDetailsFirstBlock = [],
            pgDetailsSecondBlock = [],
            pgDetailsLastBlock = [],
            draftType = '';

        if (performanceGuaranteeFields.length > 0) {
            performanceGuaranteeBlock = formFieldHandler(performanceGuaranteeFields, listeners);
            const performanceGuaranteeRadioGroup = performanceGuaranteeFields.find(({type}) => type === 'radio');
            const valueAsArray = performanceGuaranteeRadioGroup.value.split('_');
            draftType = valueAsArray[valueAsArray.length - 1];
        }

        if (bankDetailsFields.length > 0) {
            bankDetailsBlock = formFieldHandler(bankDetailsFields, listeners);
        }

        if (pgDetailsFields.length > 0) {
            const blockShown = pgDetailsFields.find(({isShown}) => isShown);
            if (blockShown) {
                pgDetailsBlock = formFieldHandler(pgDetailsFields, listeners);
                pgDetailsFirstBlock = pgDetailsBlock.slice(0, 1);
                pgDetailsSecondBlock = pgDetailsBlock.slice(1, 4);
                pgDetailsLastBlock = pgDetailsBlock.slice(4);
            }
        }

        performanceGuaranteeTabBlock = (
            <>
                {bankDetailsBlock.length > 0 && (
                    <div className={styles['bank-details-block']}>
                        <div className={styles.title}>
                            {bankDetailsFields[0].section}
                        </div>

                        <div className={styles['grid-group']}>
                            {bankDetailsBlock}
                        </div>
                    </div>
                )}

                {performanceGuaranteeBlock.length > 0 && (
                    <>
                        <div className={`${styles.title} ${styles['offset-top']}`}>
                            {performanceGuaranteeFields[0].section}
                        </div>

                        {performanceGuaranteeBlock}
                    </>
                )}

                {pgDetailsBlock.length > 0 && (
                    <div className={styles['pg-details-block']}>
                        <div className={styles.title}>
                            {pgDetailsFields[0].section}
                        </div>

                        <div className={styles['grid-group']}>
                            {pgDetailsFirstBlock}

                            <div className={`${styles['grid-group']} ${styles['group-3']}`}>
                                {pgDetailsSecondBlock}
                            </div>

                            {pgDetailsLastBlock}
                        </div>

                        {draftType && <PGDraft type={draftType}/>}
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{bank_accounts_title}</div>
            <div className={styles['accounts-block']}>
                {bankAccountsTabBlock}
            </div>

            {performanceGuaranteeTabBlock}
        </div>
    );
};

PaymentSetup.defaultProps = {
    onInputChange: () => {
    },
    onClickSelectItem: () => {
    },
    onRadioButtonChange: () => {
    },
    onToggleAccordion: () => {
    },
};

PaymentSetup.propTypes = {
    staticData: PropTypes.object,
    userInteraction: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    accounts: PropTypes.array,
    removingAccount: PropTypes.number,
    removeBankAccountLoading: PropTypes.bool,
    onInputChange: PropTypes.func,
    onClickSelectItem: PropTypes.func,
    onRadioButtonChange: PropTypes.func,
    onToggleAccordion: PropTypes.func,
};

export default PaymentSetup;