import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './switcher.module.scss';

const Switcher = (props) => {
    const {
        id, label, name, value, readOnly,
        activeSwitcherList, classNames, onChange
    } = props;
    const inputRef = useRef();

    const [switcherValue, onSetSwitcherValue] = useState('');

    useEffect(() => {
        onSetSwitcherValue(value);

        if (inputRef.current) {
            inputRef.current.checked = activeSwitcherList.includes(value);
        }
    }, [value, activeSwitcherList]);

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames, {
        'no-transition': switcherValue === '',
        'read-only': readOnly,
    });

    return (
        <label className={containerClasses} htmlFor={id}>
            <input id={id}
                   ref={inputRef}
                   type="checkbox"
                   name={name}
                   value={switcherValue}
                   readOnly={readOnly}
                   className={styles.checkbox}
                   onChange={onChange}
            />
            <span className={styles.switch}/>
            <span className={styles.label}>{label}</span>
        </label>
    );
};

Switcher.defaultProps = {
    readOnly: false,
    onChange: () => {
    },
};

Switcher.propTypes = {
    id: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    activeSwitcherList: PropTypes.array,
    classNames: PropTypes.string,
    onChange: PropTypes.func,
};

export default Switcher;