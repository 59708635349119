import React from 'react';
import PropTypes from 'prop-types';

import StatusIndicator from '../../../../../../common/status-indicator';
import Tooltip from '../../../../../../../../common/tooltip';
import InfoMark from '../../../../../../../../common/info-mark';

import styles from './quotation.module.scss';

import saveIcon from '../../../../../../../../common/assets/documents-icons/save_icon.svg';

const Quotation = (props) => {
    const {staticData, rfq_number, row, documentLink, classNames} = props;
    const {rfq_price_tooltip} = staticData.activity_hub.requests_for_quotations;

    const rowData = row.map(({label, value, color}, idx) => {
        if (idx === row.length - 2) {
            return (
                <div key={idx} className={`${styles.column} ${styles.total}`}>
                    <div className={styles.price}>{value}</div>
                    <Tooltip tooltip={rfq_price_tooltip}
                             contentClassNames={styles['info-text']}
                             minWidth={400}
                             maxWidth={400}
                             arrow>
                        <span className={styles.info} onClick={(e) => e.stopPropagation()}>
                            <InfoMark classNames={styles['info-icon']}/>
                        </span>
                    </Tooltip>
                </div>
            );
        }

        if (idx === row.length - 1) {
            return <StatusIndicator key={idx} status={{label, color}} classNames={styles.column}/>;
        }

        return <div key={idx} className={styles.column}>{value}</div>;
    });

    return (
        <div className={`${styles.container} ${classNames}`}>
            <div className={styles.column}>{rfq_number}</div>

            {rowData}

            <div className={styles.column}>
                <a href={documentLink} download><img src={saveIcon} alt="link" className={styles.link}/></a>
            </div>
        </div>
    );
};

Quotation.propTypes = {
    staticData: PropTypes.object,
    rfq_number: PropTypes.string,
    row: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
    })),
    documentLink: PropTypes.string,
    classNames: PropTypes.string,
};

export default Quotation;