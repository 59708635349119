export default class PackingListWizardService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/contract/invoice';
    }

    getProductsListForPacking = (id) => {
        return this._http.get(`${this.baseUrl}/${id}/packing`);
    };

    getSuggestedStrategy = (body) => {
        return this._http.post('/suggest/packing/strategy', body);
    };

    getPackingForm = (id, body) => {
        return this._http.post(`${this.baseUrl}/${id}/packing/form`, body);
    };

    createPackingPlace = (id, body) => {
        return this._http.post(`${this.baseUrl}/${id}/packing/create`, body);
    };

    getPackingEditingForm = (id) => {
        return this._http.get(`${this.baseUrl}/packing/update/${id}`);
    };

    editPackingPlace = (id, body) => {
        return this._http.post(`${this.baseUrl}/packing/update/${id}`, body);
    };

    unpackPackedProduct = (id) => {
        return this._http.post(`${this.baseUrl}/packing/unpack/${id}`);
    };

    confirmPackingList = (id) => {
        return this._http.post(`${this.baseUrl}/${id}/packing/confirm`);
    };

    getPackingListLabels = (id) => {
        return this._http.get(`${this.baseUrl}/${id}/packing/labels`);
    };
}