import React, {Component} from 'react';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import {filterDropdownHandler, resetAllFilterOptions} from './filter-handlers';
import {updatedUrlWithParams} from '../handlers';

import Filter from './filter';

class FiltersContainer extends Component {
    state = {
        isDropdownOpen: false,
        dropdowns: [],
        options: [],
    };

    componentDidMount() {
        const {staticData, filters} = this.props;

        let initDropdowns = [];
        let initOptions = [];

        if (filters) {
            for (let key in filters) {
                if (filters.hasOwnProperty(key)) {
                    if (!Array.isArray(filters[key])) {
                        const {label = 'label', list} = filters[key];
                        initDropdowns = [
                            ...initDropdowns,
                            {id: key, label, isOpen: false},
                        ];

                        initOptions = [
                            ...initOptions,
                            {id: key, options: list},
                        ];
                    }
                }
            }

        } else {
            /* set default filters */
            const {platform_label, title_label} = staticData.filters;

            initDropdowns = [
                {id: 'titles', label: title_label, isOpen: false, isDisable: true},
                {id: 'platforms', label: platform_label, isOpen: false, isDisable: true},
            ];

            initOptions = [
                {id: 'titles', options: []},
                {id: 'platforms', options: []},
            ];
        }

        this.setState(({
            dropdowns: initDropdowns,
            options: initOptions,
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filters !== this.props.filters) {
            if (this.props.filters) {
                const {filters} = this.props;
                const {dropdowns} = this.state;
                let updatedDropdowns = [];
                let updatedOptions = [];

                for (let key in filters) {
                    if (filters.hasOwnProperty(key)) {
                        if (!Array.isArray(filters[key])) {
                            const {label, list} = filters[key];

                            const filter = dropdowns.find(({id}) => id === key);

                            if (filter) {
                                updatedDropdowns = [
                                    ...updatedDropdowns,
                                    {...filter, label},
                                ];

                            } else {
                                updatedDropdowns = [
                                    ...updatedDropdowns,
                                    {id: key, label, isOpen: false},
                                ];
                            }

                            updatedOptions = [
                                ...updatedOptions,
                                {id: key, options: list},
                            ];
                        }
                    }
                }

                this.setState(({
                    dropdowns: updatedDropdowns,
                    options: updatedOptions,
                }));
            }
        }

        if (prevState.isDropdownOpen !== this.state.isDropdownOpen) {
            const {isDropdownOpen} = this.state;

            this.props.onToggleFilter(isDropdownOpen);
        }
    }

    updateFilterDropdowns = (currentID) => {
        const {dropdowns} = this.state;
        const newFilterDropdowns = filterDropdownHandler(currentID, dropdowns);
        const isDropdownOpen = newFilterDropdowns.findIndex(({isOpen}) => isOpen) !== -1;

        this.setState(({isDropdownOpen: isDropdownOpen}));
        this.setState(({dropdowns: newFilterDropdowns}));
    };

    updateFilterOptions = (optionHref) => {
        const {history, location} = this.props;
        const newHref = updatedUrlWithParams(location, optionHref);

        history.push(newHref);
    };

    onResetAll = (canResetPnum = false) => {
        const {history, location: {pathname, search}} = this.props;
        const newPath = resetAllFilterOptions(search, pathname, canResetPnum);

        history.push(newPath);
    };

    render() {
        const {
            staticData, updating, isSkeletonPreloader,
            screenChangeOn, canResetPnumFilter, onMobileFilterOpen,
        } = this.props;

        return (
            <Filter
                {...this.state}
                staticData={staticData}
                isUpdating={updating}
                isSkeletonPreloader={isSkeletonPreloader}
                screenChangeOn={screenChangeOn}
                canResetPnumFilter={canResetPnumFilter}
                toggleDropdown={this.updateFilterDropdowns}
                toggleOptions={this.updateFilterOptions}
                onReset={this.onResetAll}
                onMobileFilterOpen={onMobileFilterOpen}
            />
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        filters: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
        }),
        canResetPnumFilter: PropTypes.bool,
        onToggleFilter: PropTypes.func,
        onMobileFilterOpen: PropTypes.func,
    };
}

export default compose(
    withRouter,
    withDataService(),
)(FiltersContainer);