export const challenges_es = [
    {
        title: 'Acceso limitado a las plataformas simples de comercio electrónico con la interfaz intuitivamente comprendida, sobre todo para las pequeñas y medianas empresas',
        text: `La baja disponibilidad y, en consecuencia, el acceso limitado al sistema electrónico de compras 
        de nivel corporativo, como también la dificultad de su uso, la cual usan sólo los operadores 
        más grandes del transporte aéreo y terrestre.`,
        url: 'challenge_4.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Automatización incompleta de la documentación de exportación',
        text: `Tramitación incompleta de facturas y listas de empaque para componer el paquete completo 
        de documentos de importación. Además,  la mezcla de sistemas a mano y los electrónicos en el 
        proceso de compras provoca dificultades y confusión.`,
        url: 'challenge_5.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Problemas de confianza, sobre todo de parte de los nuevos clientes',
        text: `La falta de confianza de parte de los clientes al concluir los negocios en Internet provoca el miedo, 
        la inseguridad y las compras falladas.  Además, la burocracia en las corporaciones estatales lleva a 
        las demoras de los pagos, lo que hace más complicadas las relaciones comerciales entre los consumidores 
        y los abastecedores.`,
        url: 'challenge_7.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Irresponsabilidad financiera e imposibilidad de supervisar las cargas en tiempo real',
        text: `Imposibilidad de obtener la información sobre el estado de la carga en tiempo real 
        en combinación con falta de responsabilidad financiera por la logística y por el suministro 
        puntual de piezas de repuesto.`,
        url: 'challenge_6.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Altos precios de las empresas intermediarias',
        text: `¿Se ha hecho alguna vez víctima de los “intermediarios” que a menudo exageran los 
        precios de piezas de repuesto, a veces hasta tres veces más del precio ordinario?`,
        url: 'challenge_2.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Problemas de seguridad',
        text: `Las transacciones vienen acompañadas por las acciones fraudulentas, tales como 
        inclumplimiento de las obligaciones de las entregas, pérdidas de dinero y obtención de 
        productos defectuosos.`,
        url: 'challenge_3.svg',
        alt: 'Challenge image',
    },
];