import {createRequestHandler} from '../utils';

export const PART_NUMBER_SEARCH_DATA_REQUEST = 'PART_NUMBER_SEARCH_DATA_REQUEST';
export const PART_NUMBER_SEARCH_DATA_SUCCESS = 'PART_NUMBER_SEARCH_DATA_SUCCESS';
export const PART_NUMBER_SEARCH_DATA_FAILURE = 'PART_NUMBER_SEARCH_DATA_FAILURE';

export const partNumberRequest = createRequestHandler('PART_NUMBER_SEARCH_DATA', {
    setGlobalServerError: false,
});

const initialState = {
    loading: false,
    list: null,
    error: null,
};

const partNumber = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PART_NUMBER_SEARCH_DATA_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case PART_NUMBER_SEARCH_DATA_SUCCESS:
            return {
                ...initialState,
                list: payload,
            };

        case PART_NUMBER_SEARCH_DATA_FAILURE:
            return {
                ...initialState,
                error: payload,
            };

        case 'RESET_PART_NUMBER_SEARCH_DATA':
            return initialState;

        default:
            return state;
    }
};

export default partNumber;