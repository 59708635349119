import React from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import RequestsForQuotationsDesktop from './components/requests-for-quotations-desktop';
import RequestsForQuotationsMobile from './components/requests-for-quotations-mobile';

const RequestsForQuotations = (props) => {
    const xlDevice = useMediaQuery({minWidth: 1200});
    const {pages} = props.staticData.activity_hub;

    return xlDevice
        ? <RequestsForQuotationsDesktop {...props} label={pages[0]}/>
        : <RequestsForQuotationsMobile {...props} label={pages[0]}/>;
};

RequestsForQuotations.propTypes = {
    staticData: PropTypes.object,
};

export default RequestsForQuotations;