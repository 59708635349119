import {createRequestHandler} from '../../../utils';

export const E_CONTRACTS_LIST_REQUEST = 'E_CONTRACTS_LIST_REQUEST';
export const E_CONTRACTS_LIST_SUCCESS = 'E_CONTRACTS_LIST_SUCCESS';
export const E_CONTRACTS_LIST_FAILURE = 'E_CONTRACTS_LIST_FAILURE';

export const E_CONTRACTS_LIST_UPDATING_REQUEST = 'E_CONTRACTS_LIST_UPDATING_REQUEST';
export const E_CONTRACTS_LIST_UPDATING_SUCCESS = 'E_CONTRACTS_LIST_UPDATING_SUCCESS';
export const E_CONTRACTS_LIST_UPDATING_FAILURE = 'E_CONTRACTS_LIST_UPDATING_FAILURE';

export const contractsListRequest = createRequestHandler('E_CONTRACTS_LIST');
export const updatingContractsListRequest = createRequestHandler('E_CONTRACTS_LIST_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    list: null,
    error: null,
};

const contractsList = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case E_CONTRACTS_LIST_REQUEST:
            return initialState;

        case E_CONTRACTS_LIST_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case E_CONTRACTS_LIST_SUCCESS:
        case E_CONTRACTS_LIST_UPDATING_SUCCESS:
            return {
                list: payload.list,
                loading: false,
                updating: false,
                error: null,
            };

        case E_CONTRACTS_LIST_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case E_CONTRACTS_LIST_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default contractsList;