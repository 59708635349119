import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Chip from '../../../../../../../../common/chip';

import styles from '../excel-upload-page.module.scss';

class ExcelUploadFormOption extends Component {
    state = {
        optionItems: [],
        readOnlyMode: false,
    };

    componentDidMount() {
        const {option: {content}} = this.props;
        const readOnlyOptionItems = content.list.filter(item => item.isActive).length <= 1;

        const newItems = content.list.map((item, idx) => {
            if (readOnlyOptionItems) {
                return {
                    id: `${idx}_${item.label}`,
                    ...item,
                    isSelected: item.isActive,
                };
            }

            return {
                id: `${idx}_${item.label}`,
                ...item,
                isSelected: false,
            };
        });

        this.setState({
            optionItems: newItems,
            readOnlyMode: readOnlyOptionItems,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isReset !== this.props.isReset) {
            if (this.props.isReset) {
                if (!this.state.readOnlyMode) {
                    const newOptions = this.state.optionItems.map(option => ({
                        ...option,
                        isSelected: false,
                    }));

                    this.setState({optionItems: newOptions});
                }

                this.props.onReset();
            }
        }
    }

    onToggleItem = (id, value) => {
        const currentItemIdx = this.state.optionItems.findIndex((item) => item.id === id);

        const newListOptions = this.state.optionItems.map((item, idx) => {
            if (idx === currentItemIdx) {
                return {
                    ...item,
                    isSelected: !item.isSelected,
                };
            }

            return {
                ...item,
                isSelected: false,
            };
        });

        this.setState({optionItems: newListOptions});
        this.props.onToggleOption(this.props.option.value, value);
    };

    render() {
        const {option: {label}} = this.props;
        const {optionItems, readOnlyMode} = this.state;

        return (
            <div className={styles.row}>
                <div className={styles.label}>{label}</div>
                <div className={styles.items}>
                    {optionItems.map(({id, label, value, isActive, isSelected}, idx) => (
                        <Chip key={idx}
                              classNames={styles.chip}
                              isActive={isSelected}
                              isDisable={!isActive}
                              onClick={() => !readOnlyMode && this.onToggleItem(id, value)}>
                            {label}
                        </Chip>
                    ))}
                </div>
            </div>
        );
    }

    static defaultProps = {
        onReset: () => {
        },
    };

    static propTypes = {
        option: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            content: PropTypes.shape({
                list: PropTypes.arrayOf(PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.string,
                    isActive: PropTypes.bool,
                })),
            }),
        }),
        isReset: PropTypes.bool,
        onReset: PropTypes.func,
        onToggleOption: PropTypes.func,
    };
}

export default ExcelUploadFormOption;