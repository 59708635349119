import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import ProductDescription from '../../../product-description';

import styles from './supply-set.module.scss';

const SupplySet = ({content, title}) => {
    const basePath = useSelector(({utils: {baseAPIUrl: basePath}}) => (basePath));
    const {slug: offersSlug} = useParams();

    const supplySetContent = content.map(({name, quantity, href}) => {
        const newHref = href !== '' ? {
            pathname: `${basePath}/search`,
            search: href,
            pageTitle: offersSlug,
        } : href;

        return {
            label: name,
            value: quantity,
            href: newHref,
        };
    });

    return (
        <div className={styles.container}>
            <ProductDescription description={supplySetContent}
                                title={`${title}:`}
                                itemClassNames={styles['description-item']}
                                isTitle/>
        </div>
    );
};

SupplySet.propTypes = {
    content: PropTypes.array,
    title: PropTypes.string,
};

export default SupplySet;