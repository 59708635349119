const onInitSectionStateFromDataByServer = (sectionFields) => {
    const isDisabled = sectionFields
        .map(({section: {isDisable}}) => isDisable)
        .filter((isDisable) => !isDisable).length === 0;

    return isDisabled;
};

const nextSectionIndexHandler = (currentID, sections) => {
    const currentSectionIdx = sections.findIndex(({id}) => id === currentID);
    const nextSectionIdx = currentSectionIdx + 1;

    if (nextSectionIdx < sections.length) {
        if (sections[nextSectionIdx].isDisabled) {
            if (nextSectionIdx < sections.length - 1) { // second last section
                return nextSectionIndexHandler(sections[nextSectionIdx].id, sections);
            }

            return null;

        } else {
            return sections[nextSectionIdx].id;
        }
    }

    return null;
};

export {
    onInitSectionStateFromDataByServer,
    nextSectionIndexHandler,
};