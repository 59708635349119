import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {hubURLs} from "../../../../../../../../../services/api-urls";
import {withDataService} from '../../../../../../../../common/hoc';
import {onChangeFieldValue} from '../../../../../../common/handlers';
import {transformFieldData} from '../../../../../../common/handlers/field-handlers';
import FormFieldCreator from '../../../../../../common/handlers/form-field-creator';

import FormFieldConstructor from '../../../../../../../../common/form-field-constructor';

import Button from '../../../../../../../../common/button';

import styles from './user-profile.module.scss';

class UserProfile extends Component {
    state = {
        list: null,
        isCanBeUpdated: true,
    };

    componentDidMount() {
        const updatedList = this.fieldHandler();
        this.setState({list: updatedList});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.list !== this.props.list) {
            if (this.state.isCanBeUpdated) {
                const updatedList = this.fieldHandler();
                this.setState({list: updatedList});
            }
        }

        if (prevState.list !== this.state.list) {
            if (this.state.isCanBeUpdated) {
                this.setState({isCanBeUpdated: false});

            } else {
                if (this.props.checkingFieldMode) {
                    const {type, name} = this.props.checkingFieldMode;
                    this.props.onCheckingRequiredField(type, name);
                }

                if (prevState.list && this.state.list.length > 0) {
                    this.onUpdateReduxState();
                }
            }
        }

        if (prevProps.submitAction !== this.props.submitAction && this.props.submitAction) {
            this.onUpdateReduxState();
        }
    }

    fieldHandler = () => this.props.list.map((field) => transformFieldData(field));

    onChangeValue = (name, value) => {
        const updatedList = onChangeFieldValue(this.state.list, name, value);
        this.setState({list: updatedList});
    };

    onInputChange = ({target: {name, value}}) => this.onChangeValue(name, value);

    onInputPhoneChange = (name, value) => this.onChangeValue(name, value);

    onUpdateReduxState = () => {
        this.props.onUpdateList(this.props.id, this.state.list);
    };

    render() {
        const {list} = this.state;
        const {staticData: {access_setup}} = this.props;

        if (!list) {
            return null;
        }

        const formFieldCreator = new FormFieldCreator();
        const formFieldHandler = (formFields) => {
            const fields = formFieldCreator.getFormFields(formFields);

            return fields.map((field, idx) => <FormFieldConstructor key={idx} field={field}
                                                                    onInputChange={this.onInputChange}
                                                                    onInputPhoneChange={this.onInputPhoneChange}/>);
        };

        const userProfile = list.length > 0 ? formFieldHandler(list) : [];
        const radioBlock = userProfile.slice(0, 1);
        const restBlock = userProfile.slice(1);

        return (
            <div className={styles.container}>
                <div className={`${styles['grid-group']} ${styles['group-even-2']}`}>
                    <div className={styles['radio-group']}>{radioBlock}</div>
                    {restBlock}
                </div>

                <Link to={hubURLs.access_setup} className={styles['access-btn']}>
                    <Button styleType="SECONDARY">{access_setup}</Button>
                </Link>
            </div>
        );
    };

    static defaultProps = {
        onUpdateList: () => {
        },
        onCheckingRequiredField: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        id: PropTypes.string,
        list: PropTypes.array,
        checkingFieldMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        onUpdateList: PropTypes.func,
        onCheckingRequiredField: PropTypes.func,
    };
}

export default withDataService('activity_hub.profile.button')(UserProfile);