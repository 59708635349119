import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import Tippy from '@tippyjs/react';

import 'tippy.js/animations/shift-away.css';
import classnames from 'classnames/bind';
import styles from './ellipsis-text-tooltip.module.scss';

const EllipsisTextTooltip = ({tooltip, contentClassNames, labelClassNames}) => {
    const [withTooltip, setWithTooltip] = useState(false);
    const textRef = useRef(null);
    const cx = classnames.bind(styles);

    useEffect(() => {
        const onResize = () => {
            if (textRef.current) {
                if (textRef.current.scrollWidth > textRef.current.clientWidth) {
                    setWithTooltip(true);
                } else {
                    setWithTooltip(false);
                }
            }
        };
        onResize();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return withTooltip ? (
        <Tippy content={
            <div className={cx('content', contentClassNames)}>
                {tooltip}
            </div>
        }
               arrow={false}
               appendTo={'parent'}
               animation={'shift-away'}
               role={'ellipsis-text-tooltip'}
               interactive={true}
               placement={'top-start'}
        >
            <div ref={textRef} className={cx('text', labelClassNames)}>
                {tooltip}
            </div>
        </Tippy>
    ) : (
        <div ref={textRef} className={cx('text', labelClassNames)}>
            {tooltip}
        </div>
    );
};

EllipsisTextTooltip.propTypes = {
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    contentClassNames: PropTypes.string,
    labelClassNames: PropTypes.string,
};

export default EllipsisTextTooltip;