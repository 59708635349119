import {createRequestHandler} from '../../../utils';

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';

export const categoriesRequest = createRequestHandler('CATEGORIES');

const initialState = {
    loading: false,
    categories: [],
};

const categories = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CATEGORIES_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: payload.list,
            };

        case CATEGORIES_FAILURE:
            return initialState;

        default:
            return state;
    }
};

export default categories;