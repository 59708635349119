import {createRequestHandler} from '../../../utils';

export const CONTRACT_MAP_REQUEST = 'CONTRACT_MAP_REQUEST';
export const CONTRACT_MAP_SUCCESS = 'CONTRACT_MAP_SUCCESS';
export const CONTRACT_MAP_FAILURE = 'CONTRACT_MAP_FAILURE';

export const CONTRACT_MAP_UPDATING_REQUEST = 'CONTRACT_MAP_UPDATING_REQUEST';
export const CONTRACT_MAP_UPDATING_SUCCESS = 'CONTRACT_MAP_UPDATING_SUCCESS';
export const CONTRACT_MAP_UPDATING_FAILURE = 'CONTRACT_MAP_UPDATING_FAILURE';

export const contractMapRequest = createRequestHandler('CONTRACT_MAP');
export const updatingContractMapRequest = createRequestHandler('CONTRACT_MAP_UPDATING');

const initialState = {
    loading: true,
    updating: false,
    data: null,
    error: null,
};

const contractMap = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CONTRACT_MAP_REQUEST:
            return initialState;

        case CONTRACT_MAP_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case CONTRACT_MAP_SUCCESS:
        case CONTRACT_MAP_UPDATING_SUCCESS:
            return {
                data: payload,
                loading: false,
                updating: false,
                error: null,
            };

        case CONTRACT_MAP_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case CONTRACT_MAP_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default contractMap;