import React from 'react';

import MarketplacePublicModule from './module';

const MarketplacePublicContainer = () => {
    return <MarketplacePublicModule/>;
};

export {
    MarketplacePublicContainer as MarketplacePublic,
};