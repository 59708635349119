import {staticURLs} from '../../../api-urls';

export const advantages_es = [
    {
        title: 'Acceso directo al suministro y gran variedad de productos manufacturados ',
        text: [
            `Exportery – es la primera plataforma industrial comercial electrónica con el pool de 
            recursos técnicos, componentes y piezas de repuesto para aviones, helicópteros, 
            vehículos terrestres y para diferentes equipos de producción europea y oriental 
            activamente usados por los sectores privados y públicos africanos y latinoamericanos.`,
            `Como servicio universal la plataforma Exportery dispone del propio sistema de flujo de documentos 
            electrónico que lleva a un nuevo nivel la eficacia y la seguridad de las compras internacionales. 
            Ofrecemos resoluciones modernas que garantizan la entrega puntual de piezas de repuesto y el 
            procedimiento fácil de compras.`,
        ],
        btn: 'Cómo funciona exportery',
        href: staticURLs.about_marketplace,
        img: 'marketplace.png',
        alt_img: 'Marketplace',
    },
    {
        title: 'Control técnico independiente',
        text: [
            `El seguro de calidad y el control técnico son parte integrante de cualquier procedimiento de 
            compras. El procedimiento de seguro de calidad y de control bien organizado garantiza que 
            los consumidores finales obtendrán las piezas de repuesto y los componentes a tiempo, 
            siendo confirmadas la calidad y las condiciones técnicas que corresponden a los estándares 
            internacionales ISO, EASA e ICAO.`,
            `Antes se ha mencionado una serie de casos de entrega de la producción reparada o la 
            de baja calidad que haciéndola pasar por nueva. Los especialistas de Exportery realizan 
            una inspección independiente de cada envío internacional realizado mediante nuestra 
            plataforma electrónica para garantizar la seguridad para los usuarios finales de las 
            mercancías. La inspección técnica independiente se realiza por el personal certificado 
            en los depósitos de almacenaje de garantía en Etiopia y Tanzania.`,
        ],
        btn: 'Más sobre la inspección técnica independiente',
        href: staticURLs.about_independent_technical_surveying,
        img: 'technical-surveying.png',
        alt_img: 'Technical surveying',
    },
    {
        title: 'Servicios básicos',
        radio: [
            {
                title: 'Punto de salida FCA para el exportador',
                text: [
                    `Todos los precios de piezas de repuesto y de componentes en la plataforma de comercio 
                    electrónico se basan en las condiciones de Incoterms 2020 FCA Aeropuerto del país de 
                    origen, lo que hace el procedimiento de valoración y de comercio transparente y cómodo 
                    para los participantes del mercado. El exportador sólo debe cumplir las formalidades del 
                    despacho aduanero en el país de exportación.`,
                    `Exportery asume toda la carga logística y corre todo riesgo financiero 
                    relacionado con el envío de las mercancías del país de origen del 
                    exportador mediante la red verificada de operadores logísticos, aerolíneas y agentes.`,
                ],
                img: 'FCA.png',
                alt_img: 'Core services',
            },
            {
                title: 'Punto de entrada CIP para el importador',
                text: `Habiéndose creado un encargo electrónico para los importadores en las condiciones 
                Incoterms 2020 CIP Aeropuerto del punto de entrada, Exportery cálcula automáticamente los 
                precios de todas las piezas de repuesto y todos los componentes. De tal manera, el 
                importador podrá obtener en unos minutos una oferta de precios completa que incluirá 
                el suministro a cualquier destino lejano en el mundo, incluso el seguro.`,
                img: 'CIP.png',
                alt_img: 'Core services',
            },
            {
                title: 'Automatización de la documentación de exportación e importación',
                text: [
                    `Nuestra plataforma de comercio electrónico está concentrada en la superación de los 
                    procedimientos burocrácicos y laboriosos en los procesos de compras y en la conversión 
                    del procedimiento de creación e intercambio de certificados de usuario final, de 
                    confirmaciones de la entrega de la mercancía y de las garantías bancarias en el 
                    formato digital mediante la tecnología introducida de blockchain y las organizaiones asociadas.`,
                    `Ya ofrecemos a nuestros clientes la comodidad, la responsabilidad sin precedentes y el 
                    apoyo insuperable gracias al sistema de gestión de contratos desarrolado por nosotros, que 
                    garantiza la emisión y el flujo automático, intuitivamente comprendido,  de los documentos 
                    de importación y exportación más importantes, tales como facturas electrónicas, certificados 
                    de aceptación electrónicos, listas de empaque electrónicas, contratos electrónicos y, por fin, 
                    los conocimientos de embarque electrónicos.`,
                ],
                img: 'automation_of_documentation.png',
                alt_img: 'Core services',
            },
            {
                title: 'Firmas digitales en todos los documentos ',
                text: `En la plataforma Exportery todos los documentos están protegidos y se suscriben 
                con la firma electrónica mediante el sistema seguro DocuSign reconocido en la mayoría 
                de los países del mundo. Además de la simplificación del procedimiento de despacho 
                aduanero de las mercancías, se gatantiza también la autenticidad y el derecho de 
                propiedad a toda la documentación, lo que fomenta la confianza en la plataforma de 
                comercio electrónico de parte de todos los participantes.`,
                img: 'e-signatures.png',
                alt_img: 'Core services',
            },
            {
                title: 'Sistema de almacenaje de garantía y de cuenta escrow',
                text: [
                    `Somos los pioneros de la aplicación de métodos efectivos de almacenaje de garantía 
                    en los mecados emergentes para garantizar la ejecusión de la transacción entre todas 
                    las partes. La mercancía será embarcada y enviada del almacén de garantía sólo después 
                    de que sean cumplidas todas las condiciones del contrato electrónico.`,
                    `Introducimos cambios cualitativos en el mercado internacional de piezas de repuesto 
                    mediante nuestros nuevos servicios de almacenaje de garantía, nuestra responsabilidad y 
                    apoyo sin precedente que ofrecemos a todos nuestros clientes. Todas las mercancías 
                    contratadas en la plataforma comercial pasan el procedimiento de almacenaje de 
                    garantías en Tanzania a fin de cumplir las garantías de la transacción, y los 
                    recursos de los Importadores están protegidos en las cuentas esrow en las 
                    instituciones bancarias panafricanas independientes creíbles, lo que permite a 
                    nuestros importadores realizar toda clase de pagos en la divisa local de modo seguro y efectivo.`,
                ],
                img: 'escrow-warehousing.png',
                alt_img: 'Core services',
            },
            {
                title: 'Seguridad y confidencialidad de los datos electrónicos',
                text: `A diferencia de otras plataformas eletrónicas vulnerables a las violaciones de 
                la seguridad de los datos, Exportery tiene la arquitectura fiable de garantías de la 
                seguridad de los datos, la cual protege la información tanto de los importadores, como 
                de los exportadores contra el acceso no autorizado y contra el robo. Nuestra organización 
                respeta los altos estándares  de confidencialidad y no comunica a los terceros los 
                contactos ni las inscripciones de las operaciones del comercio electrónico. Además, 
                nuestras bases de datos y servidores están situados en los países seguros y neutros, 
                tales como Suiza y  EAU, lo que fomenta la confianza en nosotros y en nuestra seguridad.`,
                img: 'security.png',
                alt_img: 'Core services',
            },
        ],
        btn: 'Más sobre los servicios principales',
        href: staticURLs.about_core_services,
    },
];