import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './product-description.module.scss';

const ProductDescription = (props) => {
    const {description, title, isTitle, isMobileTitle, isOrderList, itemClassNames, itemBlockClassNames} = props;

    const cx = classnames.bind(styles);
    const titleClasses = cx('title', {
        'mobile-title': isMobileTitle,
    });
    const itemClasses = cx('item', itemClassNames);

    const descriptionList = description.map(({label, value, href = ''}, idx) => {
        return (
            <li className={cx('item-block', itemBlockClassNames)} key={idx}>
                <div className={itemClasses}>
                    <div className={styles.label}>
                        {
                            href !== ''
                                ? <Link to={href} className={styles.link}>{label}</Link>
                                : label
                        }
                    </div>
                    <div className={styles.dots}/>
                    <div className={styles.value}>{value}</div>
                </div>
            </li>
        );
    });

    return (
        <>
            {isTitle && <div className={titleClasses}>{title}</div>}
                {isOrderList
                    ? <ol className={styles['item-list-ol']}>{descriptionList}</ol>
                    : <ul className={styles['item-list-ul']}>{descriptionList}</ul>}
        </>
    );
};

ProductDescription.defaultProps = {
    isTitle: false,
    isMobileTitle: false,
    isOrderList: false,
};

ProductDescription.propTypes = {
    description: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
        href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })),
    title: PropTypes.string,
    isTitle: PropTypes.bool,
    isMobileTitle: PropTypes.bool,
    isOrderList: PropTypes.bool,
    itemClassNames: PropTypes.string,
    itemBlockClassNames: PropTypes.string,
};

export default ProductDescription;