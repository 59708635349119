export const about_marketplace_fr = {
    title: 'Comment fonctionne Exportery',
    pages: [
        {
            id: 'for_exporter',
            label: 'Pour l\'exportateur',
            content: [
                {
                    title: 'S\'INSCRIRE ET VALIDER',
                    description: 'gratuitement votre organisation sur la place de marché industrielle.',
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'PLACEZ VOS PRODUITS',
                    description: 'dans la section spécifique via des outils de marché en ligne pratiques, y compris le téléchargement par lots pour plusieurs articles.',
                    img: 'man.svg',
                    alt: 'Man icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'RECEVEZ DES RFQ D\'IMPORTATEURS DU MONDE ENTIER',
                    description: 'y compris les agences gouvernementales avec des prix absolument transparents sur notre e-marché.',
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'OBTENEZ UN CERTIFICAT D\'UTILISATEUR FINAL AVEC UNE SIGNATURE ÉLECTRONIQUE',
                    description: 'effectuer les procédures de contrôle des exportations dans le pays d\'expédition des marchandises.',
                    img: 'certificate.svg',
                    alt: 'Certificate icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SIGNEZ VOTRE E-CONTRAT',
                    description: 'aux conditions FCA.',
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'E-PLATFORM VOUS PERMET DE FORMER TOUTE LA DOCUMENTATION AUTOMATIQUEMENT',
                    description: 'pour le dédouanement et l\'exportation de vos marchandises : factures, listes de colisage, documents de transport et étiquettes d\'expédition.',
                    img: 'package.svg',
                    alt: 'Package icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SOUMETTRE VOTRE FRET AU TERMINAL DE FRET DE L\'AÉROPORT DÉDIÉ SELON LES TERMES FCA (INCOTERMS 2020) DANS VOTRE PAYS',
                    description: 'et e-platform Exportery se chargera de réserver l\'envoi auprès du transporteur, de payer le fret, d\'émettre la lettre de transport aérien et de fournir une assurance transport international.',
                    img: 'shipment.svg',
                    alt: 'Shipment icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'VOTRE CARGAISON ARRIVERA À L\'ENTREPÔT DE TRANSIT DE L\'AÉROPORT INTERNATIONAL DANS UN PAYS NEUTRE',
                    description: 'Nous effectuerons une inspection technique indépendante de la qualité et de la quantité de votre cargaison et la préparerons pour le transfert à l\'utilisateur final. Vos marchandises seront en sécurité dans l\'entrepôt de séquestre jusqu\'à la fin du règlement.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'OBTENEZ DES FONDS SUR VOTRE COMPTE DE RÈGLEMENT',
                    description: 'selon les termes de votre e-Contrat.',
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SUIVEZ CHAQUE ÉTAPE DE VOTRE LIVRAISON DE FRET',
                    description: 'depuis l\'origine de l\'expédition dans votre pays jusqu\'à la destination finale du destinataire.',
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
        {
            id: 'for_importer',
            label: 'Pour l\'importateur',
            content: [
                {
                    title: 'S\'INSCRIRE ET VALIDER',
                    description: 'gratuitement votre organisation sur la place de marché industrielle.',
                    img: 'sign-up.svg',
                    alt: 'Sign up icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'OBTENEZ DES OFFRES POUR VOS RFQ AVEC LE PRIX LE PLUS BAS ET LA LIVRAISON LA PLUS RAPIDE',
                    description: 'directement auprès des fabricants ou des exportateurs de produits.',
                    img: 'rfq.svg',
                    alt: 'RFQ icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SOUMETTRE VOTRE CERTIFICAT D\'UTILISATEUR FINAL',
                    description: 'grâce à nos outils électroniques pour se conformer aux exigences de contrôle des exportations dans le pays d\'origine des marchandises et obtenir une garantie d\'exécution des obligations contractuelles.',
                    img: 'accept.svg',
                    alt: 'Accept icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SIGNEZ VOTRE CONTRAT ÉLECTRONIQUE',
                    description: 'aux conditions CIP pour votre destination.',
                    img: 'contract.svg',
                    alt: 'Contract icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'ACCEPTER TOUS LES DOCUMENTS D\'EXPÉDITION NÉCESSAIRES SOUS FORME ÉLECTRONIQUE DE L\'EXPORTATEUR',
                    description: 'et recevez toutes les informations sur le suivi des marchandises à l\'aide de notre panneau électronique.',
                    img: 'delivery.svg',
                    alt: 'Delivery icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'VOTRE CARGAISON ARRIVERA À L\'ENTREPÔT DE TRANSIT DE L\'AÉROPORT INTERNATIONAL DANS UN PAYS NEUTRE',
                    description: 'Nous effectuerons une inspection technique indépendante de la qualité et de la quantité de votre cargaison et la préparerons pour le transfert à l\'utilisateur final. Vos marchandises seront en sécurité dans l\'entrepôt de séquestre jusqu\'à la fin du règlement.',
                    img: 'part.svg',
                    alt: 'Part icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'TRANSFÉRER VOS FONDS',
                    description: 'Après avoir signé le certificat d\'acceptation, vous transférez des fonds sur le compte conformément au contrat électronique, et en même temps, notre agence enverra les marchandises à votre adresse depuis l\'entrepôt de transit.',
                    img: 'money.svg',
                    alt: 'Money icon',
                    assets_folder: 'about-marketplace',
                },
                {
                    title: 'SUIVEZ CHAQUE ÉTAPE DE VOTRE LIVRAISON DE FRET',
                    description: 'jusqu\'à la destination finale, y compris tous les documents de transport et l\'avis de livraison.',
                    img: 'tablet.svg',
                    alt: 'Tablet icon',
                    assets_folder: 'about-marketplace',
                },
            ],
        },
    ],
};