import {getCurrentYear} from '../../../utils';
import {CONTACT_DATA} from '../constants';
import {hubURLs, staticURLs, URLs} from '../../api-urls';
import {terms_and_conditions_fr} from '../terms-and-conditions';
import {challenges_fr} from '../home-page/challenges';
import {advantages_fr} from '../home-page/advantages';
import {about_marketplace_fr} from '../about-marketplace';
import {about_independent_technical_surveying_fr} from '../about-independent-technical-surveying';
import {about_core_services_fr} from '../about-core-services';
import BreadcrumbsConstructor from '../../breadcrumbs';

const fr = (session) => ({
    meta_tags: {
        title: 'Exportery',
        description: 'E-marketplace véritablement industrielle',
    },

    home_page: {
        banner_text: ['E-marketplace véritablement', 'industrielle'],
        image_alt: 'Banner image',
        main_title: `Exportery est une plateforme de commerce électronique B2B qui établit les 
        liens de coopération entre les fabricants OEM de pièces de rechange mondiaux et les 
        utilisateurs finaux dans les industries de l'aviation et du transport terrestre`,
        advantages_content: advantages_fr,
        challenges_subtitle: 'Défis',
        challenges_text: `Les utilisateurs finaux, les importateurs, les organismes d'entretien technique 
        et de réparation, les exploitants aériens, les utilisateurs finaux gouvernementaux et les 
        exportateurs, y compris les équipementiers et les entreprises de réparation sont confrontés 
        à un «défi» dans la chaîne d'approvisionnement des pièces de rechange et des composants.`,
        challenges_list: challenges_fr,
        video_content: {
            title: ['Welcome to ', 'the new world of ', 'cross-border business'],
            // className: 'normal',
        },
        red_zone_title: 'Demander l\'accès maintenant',
        red_zone_text: `L'accès à notre plateforme de commerce électronique Exportery est GRATUIT pour 
        les exportateurs et les importateurs. Pour évaluer la fonctionnalité du système et son contenu 
        dans son intégralité, veuillez fournir quelques détails en cliquant sur le bouton ci-dessous.`,
        button: {
            request_access_btn: 'Demande d\'accès',
            play_video: 'Lire la vidéo',
            public_marketplace_btn: 'Essayez maintenant',
        },
    },

    about_marketplace: about_marketplace_fr,

    about_independent_technical_surveying: about_independent_technical_surveying_fr,

    about_core_services: about_core_services_fr,

    news: {
        title: 'Nouvelles, articles et analyses sur les études de marché',
        page: URLs.news,
        no_articles: 'Aucun article à afficher',
        image_alt: 'Image de l\'article',
        button: {
            view: 'Lire la suite',
            all_news: 'Aller aux nouvelles',
        },
    },

    section: {
        news: {
            title: 'Nouvelles, articles et analyses sur les études de marché',
            page: URLs.news,
            no_articles: 'Aucun article à afficher',
        },
        shop_by_brand: {
            title: 'Source de pièces de rechange et de composants conçus par:',
        },
        no_content: 'Aucun élément à afficher',
        button: {
            more_btn: 'montre plus',
            less_btn: 'мontrer moins',
        },
    },

    terms_and_conditions: {
        nav_menu: 'Navigation dans les chapitres',
        data: terms_and_conditions_fr,
    },

    privacy_policy: {
        title: 'Politique de confidentialité',
        updated: 'Last updated: June 21, 2022',
        text: [
            [`This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of 
                Your information when You use the Service and tells You about Your privacy rights and how the law
                protects You.`],
            [`We use Your Personal data to provide and improve the Service. By using the Service, You agree to the 
                collection and use of information in accordance with this Privacy Policy.`],
            {tag: 'h1', text: 'Interpretation and Definitions'},
            {tag: 'h2', text: 'Interpretation'},
            [`The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear
                in singular or in plural.`],
            {tag: 'h2', text: 'Definitions'},
            [`For the purposes of this Privacy Policy:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Account '},
                        {
                            tag: 'span',
                            text: 'means a unique account created for You to access our Service or parts of our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Business'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), 
                            refers to the Company as the legal entity that collects Consumers' personal information and
                            determines the purposes and means of the processing of Consumers' personal information, or
                            on behalf of which such information is collected and that alone, or jointly with others,
                            determines the purposes and means of the processing of consumers' personal information, that
                            does business in the State of California.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Company '},
                            {
                                tag: 'span',
                                text: `(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ${CONTACT_DATA.address[0]} ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                            },
                        ],
                        ['For the purpose of the GDPR, the Company is the Data Controller.'],
                    ],
                    [
                        {tag: 'strong', text: 'Consumer'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act),
                            means a natural person who is a California resident. A resident, as defined in the law,
                            includes (1) every individual who is in the USA for other than a temporary or transitory
                            purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a
                            temporary or transitory purpose.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Cookies '},
                        {
                            tag: 'span',
                            text: `are small files that are placed on Your computer, mobile device or
                            any other device by a website, containing the details of Your browsing history on that
                            website among its many uses.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Country '},
                        {tag: 'span', text: 'refers to: Georgia.'},
                    ],
                    [
                        {tag: 'strong', text: 'Data Controller'},
                        {
                            tag: 'span',
                            text: `, for the purposes of the GDPR (General Data Protection
                            Regulation), refers to the Company as the legal person which alone or jointly with others
                            determines the purposes and means of the processing of Personal Data.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Device '},
                        {
                            tag: 'span',
                            text: 'means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Do Not Track '},
                        {
                            tag: 'span',
                            text: `(DNT) is a concept that has been promoted by US regulatory
                            authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
                            industry to develop and implement a mechanism for allowing internet users to control the
                            tracking of their online activities across websites.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Personal Data '},
                            {
                                tag: 'span',
                                text: 'is any information that relates to an identified or identifiable individual.',
                            },
                        ],
                        [`For the purposes of GDPR, Personal Data means any information relating to You such as a name,
                            an identification number, location data, online identifier or to one or more factors
                            specific to the physical, physiological, genetic, mental, economic, cultural or social
                            identity.`],
                        [`For the purposes of the CCPA, Personal Data means any information that identifies, relates
                            to, describes or is capable of being associated with, or could reasonably be linked,
                            directly or indirectly, with You.`],
                    ],
                    [
                        {tag: 'strong', text: 'Sale'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), means
                            selling, renting, releasing, disclosing, disseminating, making available, transferring, or
                            otherwise communicating orally, in writing, or by electronic or other means, a Consumer's
                            personal information to another business or a third party for monetary or other valuable
                            consideration.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Service '},
                        {tag: 'span', text: 'refers to the Website.'},
                    ],
                    [
                        {tag: 'strong', text: 'Service Provider '},
                        {
                            tag: 'span',
                            text: `means any natural or legal person who processes the data on
                            behalf of the Company. It refers to third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the Service on behalf of the Company, to
                            perform services related to the Service or to assist the Company in analyzing how the
                            Service is used.
                            For the purpose of the GDPR, Service Providers are considered Data Processors.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Usage Data '},
                        {
                            tag: 'span',
                            text: `refers to data collected automatically, either generated by the
                            use of the Service or from the Service infrastructure itself (for example, the duration of a
                            page visit).`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Website '},
                        {tag: 'span', text: 'refers to Exportery, accessible from '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        [
                            {tag: 'strong', text: 'You '},
                            {
                                tag: 'span',
                                text: `means the individual accessing or using the Service, or the company, or
                                other legal entity on behalf of which such individual is accessing or using the Service, as
                                applicable.`,
                            },
                        ],
                        [`Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject
                            or as the User as you are the individual using the Service.`],
                    ],
                ],
            },
            {tag: 'h1', text: 'Collecting and Using Your Personal Data'},
            {tag: 'h2', text: 'Types of Data Collected'},
            {tag: 'h3', text: 'Personal Data'},
            [`While using Our Service, We may ask You to provide Us with certain personally identifiable
                information that can be used to contact or identify You. Personally identifiable information may
                include, but is not limited to:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Email address.',
                    'First name and last name.',
                    'Phone number.',
                    'Address, State, Province, ZIP/Postal code, City.',
                    'Bank account information in order to pay for products and/or services within the Service.',
                    'Usage Data.',
                ],
            },
            [`When You pay for a product and/or a service via bank transfer, We may ask You to provide information
                to facilitate this transaction and to verify Your identity. Such information may include, without
                limitation:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Date of birth.',
                    'Passport or National ID card.',
                    'Bank card statement.',
                    'Other information linking You to an address.',
                ],
            },
            {tag: 'h3', text: 'Usage Data'},
            ['Usage Data is collected automatically when using the Service.'],
            [`Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your
                visit, the time spent on those pages, unique device identifiers and other diagnostic data.`],
            [`When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.`],
            [`We may also collect information that Your browser sends whenever You visit our Service or when You
                access the Service by or through a mobile device.`],
            {tag: 'h3', text: 'Tracking Technologies and Cookies'},
            [`We use Cookies and similar tracking technologies to track the activity on Our Service and store
                certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
                information and to improve and analyze Our Service. The technologies We use may include:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Cookies or Browser Cookies. '},
                        {
                            tag: 'span',
                            text: `A cookie is a small file placed on Your Device. You
                            can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                            However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                            Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may
                            use Cookies.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Flash Cookies. '},
                        {
                            tag: 'span',
                            text: `Certain features of our Service may use local stored objects (or
                            Flash Cookies) to collect and store information about Your preferences or Your activity on our
                            Service. Flash Cookies are not managed by the same browser settings as those used for Browser
                            Cookies. For more information on how You can delete Flash Cookies, please read "Where can I
                            change the settings for disabling, or deleting local shared objects?" available at `,
                        },
                        {
                            tag: 'a',
                            href: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                            rel: 'external nofollow noopener noreferrer',
                            target: '_blank',
                            text: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        {tag: 'strong', text: 'Web Beacons. '},
                        {
                            tag: 'span',
                            text: `Certain sections of our Service and our emails may contain small
                            electronic files known as web beacons (also referred to as clear gifs, pixel tags, and
                            single-pixel gifs) that permit the Company, for example, to count users who have visited those
                            pages or opened an email and for other related website statistics (for example, recording the
                            popularity of a certain section and verifying system and server integrity).`,
                        },
                    ],
                ],
            },
            [
                {
                    tag: 'span',
                    text: `Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on
                    Your personal computer or mobile device when You go offline, while Session Cookies are deleted as
                    soon as You close Your web browser. Learn more about cookies on the `,
                },
                {
                    tag: 'a',
                    href: 'https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking',
                    rel: 'external nofollow noopener noreferrer',
                    target: '_blank',
                    text: 'Privacy Policies website',
                },
                {tag: 'span', text: ' article.'},
            ],
            ['We use both Session and Persistent Cookies for the purposes set out below:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Necessary / Essential Cookies.'},
                        ['Type: Session Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies are essential to provide You with services available through the
                            Website and to enable You to use some of its features. They help to authenticate users and
                            prevent fraudulent use of user accounts. Without these Cookies, the services that You have
                            asked for cannot be provided, and We only use these Cookies to provide You with those
                            services.`],
                    ],
                    [
                        {tag: 'strong', text: 'Cookies Policy / Notice Acceptance Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        ['Purpose: These Cookies identify if users have accepted the use of cookies on the Website.'],
                    ],
                    [
                        {tag: 'strong', text: 'Functionality Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies allow us to remember choices You make when You use the Website, such
                            as remembering your login details or language preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to avoid You having to re-enter your
                            preferences every time You use the Website.`],
                    ],
                    [
                        {tag: 'strong', text: 'Targeting and Advertising Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Third-Parties.'],
                        [`Purpose: These Cookies track your browsing habits to enable Us to show advertising which is
                            more likely to be of interest to You. These Cookies use information about your browsing
                            history to group You with other users who have similar interests. Based on that information,
                            and with Our permission, third party advertisers can place Cookies to enable them to show
                            adverts which We think will be relevant to your interests while You are on third party
                            websites.`],
                    ],
                ],
            },
            [`For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.`],
            {tag: 'h2', text: 'Use of Your Personal Data'},
            ['The Company may use Personal Data for the following purposes:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'To provide and maintain our Service'},
                        {tag: 'span', text: ', including to monitor the usage of our Service.'},
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your Account: '},
                        {
                            tag: 'span',
                            text: `to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You access to different functionalities of
                            the Service that are available to You as a registered user.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For the performance of a contract: '},
                        {
                            tag: 'span',
                            text: `the development, compliance and
                            undertaking of the purchase contract for the products, items or services You have purchased
                            or of any other contract with Us through the Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To contact You. '},
                        {
                            tag: 'span',
                            text: `To contact You by email, telephone calls, SMS, or other
                            equivalent forms of electronic communication, such as a mobile application's push
                            notifications regarding updates or informative communications related to the
                            functionalities, products or contracted services, including the security updates, when
                            necessary or reasonable for their implementation.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To provide You '},
                        {
                            tag: 'span',
                            text: `with news, special offers and general information about other
                            goods, services and events which we offer that are similar to those that you have already
                            purchased or enquired about unless You have opted not to receive such information.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your requests. '},
                        {
                            tag: 'span',
                            text: 'To attend and manage Your requests to Us.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer
                            of some or all of Our assets, whether as a going concern or as part of bankruptcy,
                            liquidation, or similar proceeding, in which Personal Data held by Us about our Service
                            users is among the assets transferred.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For other purposes. '},
                        {
                            tag: 'span',
                            text: `We may use Your information for other purposes, such as
                            data analysis, identifying usage trends, determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service, products, services, marketing and your
                            experience.`,
                        },
                    ],
                ],
            },
            ['We may share Your personal information in the following situations:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'With Service Providers. '},
                        {
                            tag: 'span',
                            text: `We may share Your personal information with Service
                            Providers to monitor and analyze the use of our Service, to show advertisements to You to help
                            support and maintain Our Service, for payment processing, to contact You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may share or transfer Your personal information in
                            connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                            acquisition of all or a portion of Our business to another company.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Affiliates. '},
                        {
                            tag: 'span',
                            text: `We may share Your information with Our affiliates, in which
                            case we will require those affiliates to honor this Privacy Policy. Affiliates include Our
                            parent company and any other subsidiaries, joint venture partners or other companies that We
                            control or that are under common control with Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With business partners. '},
                        {
                            tag: 'span',
                            text: 'We may share Your information with Our business partners to offer You certain products, services or promotions.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With other users: '},
                        {
                            tag: 'span',
                            text: `when You share personal information or otherwise interact in
                            the public areas with other users, such information may be viewed by all users and may be
                            publicly distributed outside.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Your consent. '},
                        {
                            tag: 'span',
                            text: 'We may disclose Your personal information for any other purpose with Your consent.',
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Retention of Your Personal Data'},
            [`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out
                in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply
                with our legal obligations (for example, if we are required to retain your data to comply with
                applicable laws), resolve disputes, and enforce our legal agreements and policies.`],
            [`The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
                retained for a shorter period of time, except when this data is used to strengthen the security or
                to improve the functionality of Our Service, or We are legally obligated to retain this data for
                longer time periods.`],
            {tag: 'h2', text: 'Transfer of Your Personal Data'},
            [`Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this
                information may be transferred to — and maintained on — computers located outside of Your state,
                province, country or other governmental jurisdiction where the data protection laws may differ than
                those from Your jurisdiction.`],
            [`Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.`],
            [`The Company will take all steps reasonably necessary to ensure that Your data is treated securely and
                in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other personal information.`],
            {tag: 'h2', text: 'Disclosure of Your Personal Data'},
            {tag: 'h3', text: 'Business Transactions'},
            [`If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                transferred. We will provide notice before Your Personal Data is transferred and becomes subject to
                a different Privacy Policy.`],
            {tag: 'h3', text: 'Law enforcement'},
            [`Under certain circumstances, the Company may be required to disclose Your Personal Data if required
                to do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).`],
            {tag: 'h3', text: 'Other legal requirements'},
            ['The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Comply with a legal obligation.',
                    'Protect and defend the rights or property of the Company.',
                    'Prevent or investigate possible wrongdoing in connection with the Service.',
                    'Protect the personal safety of Users of the Service or the public.',
                    'Protect against legal liability.',
                ],
            },
            {tag: 'h2', text: 'Security of Your Personal Data'},
            [`The security of Your Personal Data is important to Us, but remember that no method of transmission
                over the Internet, or method of electronic storage is 100% secure. While We strive to use
                commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute
                security.`],
            {tag: 'h1', text: 'Detailed Information on the Processing of Your Personal Data'},
            [`The Service Providers We use may have access to Your Personal Data. These third-party vendors
                collect, store, use, process and transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.`],
            {tag: 'h2', text: 'Advertising'},
            ['We may use Service Providers to show advertisements to You to help support and maintain Our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Google AdSense & DoubleClick Cookie.'},
                        [`Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of
                            the DoubleClick cookie enables it and its partners to serve ads to our users based on their
                            visit to our Service or other websites on the Internet.`],
                        [
                            {
                                tag: 'span',
                                text: `You may opt out of the use of the DoubleClick Cookie for interest-based advertising by
                                visiting the Google Ads Settings web page: `,
                            },
                            {
                                tag: 'a',
                                href: 'http://www.google.com/ads/preferences/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'http://www.google.com/ads/preferences/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                    [
                        {tag: 'strong', text: 'AdMob by Google.'},
                        ['AdMob by Google is provided by Google Inc.'],
                        [
                            {
                                tag: 'span',
                                text: 'You can opt-out from the AdMob by Google service by following the instructions described by Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://support.google.com/ads/answer/2662922?hl=en',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://support.google.com/ads/answer/2662922?hl=en',
                            },
                            {tag: 'span', text: '.'},
                        ],
                        [
                            [`For more information on how Google uses the collected information, please visit the "How
                                Google uses data when you use our partners' sites or app" page: `],
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/technologies/partner-sites',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/technologies/partner-sites',
                            },
                            {tag: 'span', text: ' or visit the Privacy Policy of Google: '},
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/privacy',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/privacy',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Email Marketing'},
            [`We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We
                send or by contacting Us.`],
            ['We may use Email Marketing Service Providers to manage and send emails to You.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Postmark'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://postmarkapp.com/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://postmarkapp.com/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Payments'},
            [`We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing (e.g. payment processors).`],
            [`We will not store or collect Your payment card details. That information is provided directly to Our
                third-party payment processors whose use of Your personal information is governed by their Privacy
                Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
                Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American
                Express and Discover. PCI-DSS requirements help ensure the secure handling of payment
                information.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Bilderlings'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://bilderlings.com',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://bilderlings.com',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            [`When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide
                information to facilitate this transaction and to verify Your identity.`],
            {tag: 'h2', text: 'Usage, Performance and Miscellaneous'},
            ['We may use third-party Service Providers to provide better improvement of our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Invisible reCAPTCHA'},
                        ['We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.'],
                        ['The reCAPTCHA service may collect information from You and from Your Device for security purposes.'],
                        [
                            {
                                tag: 'span',
                                text: 'The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://www.google.com/intl/en/policies/privacy/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://www.google.com/intl/en/policies/privacy/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h1', text: 'GDPR Privacy'},
            {tag: 'h2', text: 'We may process Personal Data under the following conditions:'},
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Consent. '},
                        {
                            tag: 'span',
                            text: 'You have given Your consent for processing Personal Data for one or more specific purposes.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Performance of a contract. '},
                        {
                            tag: 'span',
                            text: 'Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legal obligations. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Vital interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Public interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legitimate interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.',
                        },
                    ],
                ],
            },
            [`In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.`],
            {tag: 'h2', text: 'Your Rights under the GDPR'},
            ['The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.'],
            ['You have the right under this Privacy Policy, and by law if You are within the EU, to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Request access to Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `The right to access, update or delete the
                            information We have on You. Whenever made possible, you can access, update or request deletion
                            of Your Personal Data directly within Your account settings section. If you are unable to
                            perform these actions yourself, please contact Us to assist You. This also enables You to
                            receive a copy of the Personal Data We hold about You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request correction of the Personal Data that We hold about You. '},
                        {
                            tag: 'span',
                            text: 'You have the right to have any incomplete or inaccurate information We hold about You corrected.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Object to processing of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `This right exists where We are
                            relying on a legitimate interest as the legal basis for Our processing and there is something
                            about Your particular situation, which makes You want to object to our processing of Your
                            Personal Data on this ground. You also have the right to object where We are processing Your
                            Personal Data for direct marketing purposes.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request erasure of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: 'You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request the transfer of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `We will provide to You, or to a
                            third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable
                            format. Please note that this right only applies to automated information which You initially
                            provided consent for Us to use or where We used the information to perform a contract with You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Withdraw Your consent. '},
                        {
                            tag: 'span',
                            text: `You have the right to withdraw Your consent on using
                            your Personal Data. If You withdraw Your consent, We may not be able to provide You with access
                            to certain specific functionalities of the Service.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising of Your GDPR Data Protection Rights'},
            [`You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                Please note that we may ask You to verify Your identity before responding to such requests. If You
                make a request, We will try our best to respond to You as soon as possible.`],
            [`You have the right to complain to a Data Protection Authority about Our collection and use of Your
                Personal Data. For more information, if You are in the European Economic Area (EEA), please contact
                Your local data protection authority in the EEA.`],
            {tag: 'h1', text: 'CCPA Privacy'},
            [`This privacy notice section for California residents supplements the information contained in Our
                Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of
                California.`],
            {tag: 'h2', text: 'Categories of Personal Information Collected'},
            [`We collect information that identifies, relates to, describes, references, is capable of being
                associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer
                or Device. The following is a list of categories of personal information which we may collect or may
                have been collected from California residents within the last twelve (12) months.`],
            [`Please note that the categories and examples provided in the list below are those defined in the
                CCPA. This does not mean that all examples of that category of personal information were in fact
                collected by Us, but reflects our good faith belief to the best of our knowledge that some of that
                information from the applicable category may be and may have been collected. For example, certain
                categories of personal information would only be collected if You provided such personal information
                directly to Us.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Category A: Identifiers.'},
                        [`Examples: A real name, alias, postal address, unique personal identifier, online identifier,
                        Internet Protocol address, email address, account name, driver's license number, passport
                        number, or other similar identifiers.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                        },
                        [`Examples: A name, signature, Social Security number, physical characteristics or description,
                        address, telephone number, passport number, driver's license or state identification card
                        number, insurance policy number, education, employment, employment history, bank account
                        number, credit card number, debit card number, or any other financial information, medical
                        information, or health insurance information. Some personal information included in this
                        category may overlap with other categories.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category C: Protected classification characteristics under California or federal law.',
                        },
                        [`Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship,
                        religion or creed, marital status, medical condition, physical or mental disability, sex
                        (including gender, gender identity, gender expression, pregnancy or childbirth and related
                        medical conditions), sexual orientation, veteran or military status, genetic information
                        (including familial genetic information).`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category D: Commercial information.',
                        },
                        ['Examples: Records and history of products or services purchased or considered.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category E: Biometric information.',
                        },
                        [`Examples: Genetic, physiological, behavioral, and biological characteristics, or activity
                        patterns used to extract a template or other identifier or identifying information, such as,
                        fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other
                        physical patterns, and sleep, health, or exercise data.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category F: Internet or other similar network activity.',
                        },
                        ['Examples: Interaction with our Service or advertisement.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category G: Geolocation data.',
                        },
                        ['Examples: Approximate physical location.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category H: Sensory data.',
                        },
                        ['Examples: Audio, electronic, visual, thermal, olfactory, or similar information.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category I: Professional or employment-related information.',
                        },
                        ['Examples: Current or past job history or performance evaluations.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).',
                        },
                        [`Examples: Education records directly related to a student maintained by an educational
                        institution or party acting on its behalf, such as grades, transcripts, class lists, student
                        schedules, student identification codes, student financial information, or student
                        disciplinary records.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category K: Inferences drawn from other personal information.',
                        },
                        [`Examples: Profile reflecting a person's preferences, characteristics, psychological trends,
                        predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.`],
                        ['Collected: No.'],
                    ],
                ],
            },
            ['Under CCPA, personal information does not include:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Publicly available information from government records.',
                    'Deidentified or aggregated consumer information.',
                    [
                        {tag: 'span', text: 'Information excluded from the CCPA\'s scope, such as:'},
                        {
                            tag: 'ul',
                            className: 'second-nesting',
                            items: [
                                `Health or medical information covered by the Health Insurance Portability and
                                Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                                Information Act (CMIA) or clinical trial data.`,
                                `Personal Information covered by certain sector-specific privacy laws, including the Fair
                                Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial
                                Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.`,
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Sources of Personal Information'},
            ['We obtain the categories of personal information listed above from the following categories of sources:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Directly from You. '},
                        {
                            tag: 'span',
                            text: `For example, from the forms You complete on our Service,
                            preferences You express or provide through our Service, or from Your purchases on our Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Indirectly from You. '},
                        {
                            tag: 'span',
                            text: 'For example, from observing Your activity on our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Automatically from You. '},
                        {
                            tag: 'span',
                            text: 'For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'From Service Providers. '},
                        {
                            tag: 'span',
                            text: `For example, third-party vendors to provide advertising
                            on our Service, third-party vendors for payment processing, or other third-party vendors that We
                            use to provide the Service to You.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Use of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose personal information We collect for "business
            purposes" or "commercial purposes" (as defined under the CCPA), which may include the
            following examples:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'To operate our Service and provide You with our Service.',
                    `To provide You with support and to respond to Your inquiries, including to investigate and
                    address Your concerns and monitor and improve our Service.`,
                    `To fulfill or meet the reason You provided the information. For example, if You share Your
                    contact information to ask a question about our Service, We will use that personal information
                    to respond to Your inquiry. If You provide Your personal information to purchase a product or
                    service, We will use that information to process Your payment and facilitate delivery.`,
                    `To respond to law enforcement requests and as required by applicable law, court order, or
                    governmental regulations.`,
                    `As described to You when collecting Your personal information or as otherwise set forth in the
                    CCPA.`,
                    'For internal administrative and auditing purposes.',
                    `To detect security incidents and protect against malicious, deceptive, fraudulent or illegal
                    activity, including, when necessary, to prosecute those responsible for such activities.`,
                ],
            },
            [`Please note that the examples provided above are illustrative and not intended to be exhaustive. For
            more details on how we use this information, please refer to the "Use of Your Personal
            Data" section.`],
            [`If We decide to collect additional categories of personal information or use the personal information
            We collected for materially different, unrelated, or incompatible purposes We will update this
            Privacy Policy.`],
            {tag: 'h2', text: 'Disclosure of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose and may have used or disclosed in the last twelve (12) months the following
            categories of personal information for business or commercial purposes:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    `Category B: Personal information categories listed in the California Customer Records statute
                    (Cal. Civ. Code § 1798.80(e)).`,
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            [`Please note that the categories listed above are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact disclosed, but reflects our good
            faith belief to the best of our knowledge that some of that information from the applicable category
            may be and may have been disclosed.`],
            [`When We disclose personal information for a business purpose or a commercial purpose, We enter a
            contract that describes the purpose and requires the recipient to both keep that personal
            information confidential and not use it for any purpose except performing the contract.`],
            {tag: 'h2', text: 'Sale of Personal Information'},
            [`As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing,
            disclosing, disseminating, making available, transferring, or otherwise communicating orally, in
            writing, or by electronic or other means, a consumer's personal information by the business to a
            third party for valuable consideration. This means that We may have received some kind of benefit in
            return for sharing personal information, but not necessarily a monetary benefit.`],
            [`Please note that the categories listed below are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact sold, but reflects our good faith
            belief to the best of our knowledge that some of that information from the applicable category may
            be and may have been shared for value in return.`],
            ['We may sell and may have sold in the last twelve (12) months the following categories of personal information:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            {tag: 'h2', text: 'Share of Personal Information'},
            ['We may share Your personal information identified in the above categories with the following categories of third parties:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Service Providers.',
                    'Payment processors.',
                    'Our affiliates.',
                    'Our business partners.',
                    'Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You.',
                ],
            },
            {tag: 'h2', text: 'Sale of Personal Information of Minors Under 16 Years of Age'},
            [`We do not knowingly collect personal information from minors under the age of 16 through our Service,
                although certain third party websites that we link to may do so. These third-party websites have
                their own terms of use and privacy policies and we encourage parents and legal guardians to monitor
                their children's Internet usage and instruct their children to never provide information on other
                websites without their permission.`],
            [`We do not sell the personal information of Consumers We actually know are less than 16 years of age,
                unless We receive affirmative authorization (the "right to opt-in") from either the
                Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13
                years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales
                at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a
                request to Us by contacting Us.`],
            [`If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal
                information, please contact Us with sufficient detail to enable Us to delete that information.`],
            {tag: 'h2', text: 'Your Rights under the CCPA'},
            [`The CCPA provides California residents with specific rights regarding their personal information. If
                You are a resident of California, You have the following rights:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'The right to notice. '},
                        {
                            tag: 'span',
                            text: `You have the right to be notified which categories of
                            Personal Data are being collected and the purposes for which the Personal Data is being used.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to request. '},
                        {
                            tag: 'span',
                            text: `Under CCPA, You have the right to request that We
                            disclose information to You about Our collection, use, sale, disclosure for business purposes
                            and share of personal information. Once We receive and confirm Your request, We will disclose to
                            You:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'The categories of personal information We collected about You.',
                                'The categories of sources for the personal information We collected about You.',
                                'Our business or commercial purpose for collecting or selling that personal information.',
                                'The categories of third parties with whom We share that personal information.',
                                'The specific pieces of personal information We collected about You.',
                                [
                                    {
                                        tag: 'span',
                                        text: 'If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:',
                                    },
                                    {
                                        tag: 'ul', className: 'third-nesting', items: [
                                            'The categories of personal information categories sold.',
                                            'The categories of personal information categories disclosed.',
                                        ],
                                    },
                                ],
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to say no to the sale of Personal Data (opt-out). '},
                        {
                            tag: 'span',
                            text: `You have the right to direct Us to not sell Your personal information. 
                            To submit an opt-out request please contact Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to delete Personal Data. '},
                        {
                            tag: 'span',
                            text: `You have the right to request the deletion
                            of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request,
                            We will delete (and direct Our Service Providers to delete) Your personal information from our
                            records, unless an exception applies. We may deny Your deletion request if retaining the
                            information is necessary for Us or Our Service Providers to:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                `Complete the transaction for which We collected the personal information, provide a good
                                or service that You requested, take actions reasonably anticipated within the context of
                                our ongoing business relationship with You, or otherwise perform our contract with You.`,
                                `Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
                                activity, or prosecute those responsible for such activities.`,
                                `Debug products to identify and repair errors that impair existing intended
                                functionality.`,
                                `Exercise free speech, ensure the right of another consumer to exercise their free speech
                                rights, or exercise another right provided for by law.`,
                                `Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546
                                et. seq.).`,
                                `Engage in public or peer-reviewed scientific, historical, or statistical research in the
                                public interest that adheres to all other applicable ethics and privacy laws, when the
                                information's deletion may likely render impossible or seriously impair the research's
                                achievement, if You previously provided informed consent.`,
                                `Enable solely internal uses that are reasonably aligned with consumer expectations based
                                on Your relationship with Us.`,
                                'Comply with a legal obligation.',
                                `Make other internal and lawful uses of that information that are compatible with the
                                context in which You provided it.`,
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right not to be discriminated against. '},
                        {
                            tag: 'span',
                            text: `You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'Denying goods or services to You.',
                                `Charging different prices or rates for goods or services, including the use of discounts
                                or other benefits or imposing penalties.`,
                                'Providing a different level or quality of goods or services to You.',
                                'Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.',
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising Your CCPA Data Protection Rights'},
            ['In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
            [`Only You, or a person registered with the California Secretary of State that You authorize to act on
                Your behalf, may make a verifiable request related to Your personal information.`],
            ['Your request to Us must:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `Provide sufficient information that allows Us to reasonably verify You are the person about whom
                    We collected personal information or an authorized representative.`,
                    `Describe Your request with sufficient detail that allows Us to properly understand, evaluate,
                    and respond to it.`,
                ],
            },
            ['We cannot respond to Your request or provide You with the required information if we cannot:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Verify Your identity or authority to make the request.',
                    'And confirm that the personal information relates to You.',
                ],
            },
            [`We will disclose and deliver the required information free of charge within 45 days of receiving Your
                verifiable request. The time period to provide the required information may be extended once by an
                additional 45 days when reasonably necessary and with prior notice.`],
            [`Any disclosures We provide will only cover the 12-month period preceding the verifiable request's
                receipt.`],
            [`For data portability requests, We will select a format to provide Your personal information that is
                readily usable and should allow You to transmit the information from one entity to another entity
                without hindrance.`],
            {tag: 'h2', text: 'Do Not Sell My Personal Information'},
            [`You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a
                verifiable consumer request from You, we will stop selling Your personal information. To exercise
                Your right to opt-out, please contact Us.`],
            {
                tag: 'h1',
                text: '"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)',
            },
            ['Our Service does not respond to Do Not Track signals.'],
            [`However, some third party websites do keep track of Your browsing activities. If You are visiting
                such websites, You can set Your preferences in Your web browser to inform websites that You do not
                want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of
                Your web browser.`],
            {tag: 'h1', text: 'Children\'s Privacy'},
            [`Our Service does not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from anyone under the age of 13. If You are a parent or guardian and You
                are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware
                that We have collected Personal Data from anyone under the age of 13 without verification of
                parental consent, We take steps to remove that information from Our servers.`],
            [`If We need to rely on consent as a legal basis for processing Your information and Your country
                requires consent from a parent, We may require Your parent's consent before We collect and use that
                information.`],
            {tag: 'h1', text: 'Your California Privacy Rights (California\'s Shine the Light law)'},
            [`Under California Civil Code Section 1798 (California's Shine the Light law), California residents
                with an established business relationship with us can request information once a year about sharing
                their Personal Data with third parties for the third parties' direct marketing purposes.`],
            [`If you'd like to request more information under the California Shine the Light law, and if You are a
                California resident, You can contact Us using the contact information provided below.`],
            {
                tag: 'h1',
                text: 'California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)',
            },
            [`California Business and Professions Code Section 22581 allows California residents under the age of
                18 who are registered users of online sites, services or applications to request and obtain removal
                of content or information they have publicly posted.`],
            [`To request removal of such data, and if You are a California resident, You can contact Us using the
                contact information provided below, and include the email address associated with Your account.`],
            [`Be aware that Your request does not guarantee complete or comprehensive removal of content or
                information posted online and that the law may not permit or require removal in certain
                circumstances.`],
            {tag: 'h1', text: 'Links to Other Websites'},
            [`Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit.`],
            [`We have no control over and assume no responsibility for the content, privacy policies or practices
                of any third party sites or services.`],
            {tag: 'h1', text: 'Changes to this Privacy Policy'},
            [`We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the
                new Privacy Policy on this page.`],
            [`We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the "Last updated" date at the top of this Privacy Policy.`],
            [`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.`],
            {tag: 'h1', text: 'Contact Us'},
            ['If you have any questions about this Privacy Policy, You can contact us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
        ],
    },

    invitation: {
        subtitle: 'S\'il vous plaît remplir le formulaire ci-dessous',
        user_section_title: 'Informations sur l\'utilisateur',
        organization_section_title: 'Informations sur l\'entreprise',
        button: {
            cancel: 'Annuler',
            confirm: 'Сonfirmer',
        },
    },

    password_requirements: {
        text: 'Le mot de passe doit:',
        list: [
            'comporter entre 8 et 255 caractères.',
            'contenir que des lettres latines.',
            'inclure au moins une lettre majuscule.',
            // 'inclure au moins un chiffre.',
            // 'inclure au moins un symbole spécial (! ? @ # $ % & [ ]).',
        ],
    },

    authorization: {
        request_access_text: 'Connectez-vous ou indiquez vos coordonnées ci-dessous pour savoir comment vous pouvez accéder à la plateforme',

        sign_in: {
            welcome_title: 'Bienvenue à Exportery',
            description: [
                'E-marketplace véritablement industrielle',
                'Contrôle technique indépendant',
                'Solutions de financement du commerce basées sur la blockchain',
            ],
            title: 'S\'identifier',
            two_factor_title: 'Authentification à deux facteurs',
            reset_password_title: 'Réinitialiser le mot de passe',
            email_placeholder: 'Nom d\'utilisateur',
            password_placeholder: 'Le mot de passe',
            two_factor_code_placeholder: 'Code',
            request_access_question: 'Vous n\'avez pas de compte ?',
            required_field_error_message: 'Champs requis',
            two_factor_text: 'Veuillez saisir le code qui vous a été envoyé par e-mail',
            forgot_password_text: 'S\'il vous plaît, entrez l\'e-mail et nous vous enverrons un lien',
            security_code: {
                text: 'Please, enter code from your security token',
                placeholder: 'Security code',
                required_field_error_message: 'Champs requis',
            },
            reset_password: {
                subtitle: 'S\'il vous plaît remplir le formulaire ci-dessous',
                button: {
                    cancel: 'Annuler',
                    confirm: 'Сonfirmer',
                },
            },
            button: {
                request_access: 'Demande d\'accès',
                sign_in: 'S\'identifier',
                confirm: 'Сonfirmer',
                reset: 'Effacer ',
            },
        },

        sign_up: {
            welcome_title: 'Bienvenue à Exportery',
            description: [
                'Veuillez utiliser le formulaire d\'inscription ci-dessous pour accéder à la place de marché industrielle en mode visualisation. Nous accorderons un accès complet à votre organisation après l\'accomplissement du processus de validation guidé par notre équipe de service client.',
                'Nous espérons que vous apprécierez la simplicité, la transparence et le système de gestion de documents propriétaire de notre plateforme qui fait passer l\'approvisionnement international de biens et services industriels à un nouveau niveau d\'automatisation.',
            ],
            title: 'S\'inscrire',
            first_name_placeholder: 'Prénom',
            last_name_placeholder: 'Nom',
            email_placeholder: 'E-mail',
            organization_name_placeholder: 'Organisation',
            country_placeholder: 'De campagne',
            telephone_placeholder: 'Téléphone',
            email_field_error_message: 'L\'email n\'est pas correct',
            required_field_error_message: 'Champs requis',
            tick_the_areas_label: 'Veuillez cocher les domaines qui vous intéressent:',
            role: ['Mon rôle:', 'Importateur', 'Exportateur'],
            interest_status: ['Adhésion à la place de marché électronique', 'Étude technique indépendante', 'Services commerciaux'],
            privacy_policy_text: ['En cochant cette case et en cliquant sur "Soumettre", j\'accepte que le traitement ait lieu conformément à ce formulaire et à la ', 'politique de confidentialité', '.'],
            button: {
                submit: 'Soumettre',
                request_access_btn: 'Demande d\'accès',
            },
        },
    },

    marketplace: {
        home: {
            all_products_title: 'tous les produits',
        },

        category_page: {
            empty_category_message: 'Nous travaillons activement à la création de la base!',
        },

        product_card: {
            available_status_sold: {value: 'sold', label: 'vendu'},
            button: {
                view_product: 'voir le produit',
            },
        },

        product_panel: {
            header: {
                item_label: 'Produit',
                condition_label: 'Etat',
                price_label: 'Le prix',
            },
            delivery_time_label: 'Heure de livraison',
            exporter_label: 'Exportateur',
            supply_conditions_label: 'Сonditions d\'approvisionnement',
            button: {
                view_product: 'voir le produit',
            },
        },

        product_page: {
            image_alt: 'Product image',
            currency_array: ['USD', 'EUR', 'RUB', 'AED', 'CNY'],
            price_label: '/ La pièce',
            offers_from_text: 'de',
            message_about_not_available_currency: 'Veuillez sélectionner une autre devise',
            main_technical_data_label: ['Le nom du paramètre', 'Valeur'],
            details_accordion_label: 'Détails',
            interchangeability_text: 'est entièrement interchangeable avec:',
            certification_title: 'Certificats',
            attachment_accordion_label: 'Guides et documents de produits',
            available_currency_table_labels: ['Devise', 'Taux de change usd'],
            button: {
                add_to_quotation: 'Ajouter au devis',
                added_to_quotation: 'Ajouté à la demande',
                edit_product: 'Modifier',
            },
        },

        quotation: {
            other_offers_title: 'Autres offres',
            excel_upload_form: {
                excel_upload_form_text: 'Veuillez télécharger le fichier excel et remplir le formulaire pour obtenir le devis instantané',
                follow_format_tip: ['Pour télécharger Excel,', 'suivez le format'],
                attach_xls_label: 'Joindre xls',
                button: {
                    cancel: 'Annuler',
                    upload: 'Télécharger',
                    confirm_quotation: 'Confirmer le devis',
                },
            },

            request_for_supply: {
                title: 'Demande de fourniture',
                find_other_offers_text: 'Trouver d\'autres offres',
                price_label: '/ La pièce',
            },

            other_offers_block: {
                other_offers_title: 'Autres offres',
                replace_message: 'Êtes-vous sûr de vouloir remplacer l\'article?',
                offer: {
                    condition_label: 'Etat',
                    delivery_time_label: 'Heure de livraison',
                    delivery_time_value: '60 jours',
                    exporter_label: 'Exportateur',
                    supply_conditions_label: 'Сonditions d\'approvisionnement',
                },
                button: {
                    replace: 'Remplacer',
                },
            },

            order_summary_block: {
                title: 'Récapitulatif de la commande',
                button: {
                    confirm_quotation: 'confirmer le devis',
                },
            },

            empty_request_list: {
                text: 'Il n\'y a pas encore d\'article',
            },

            product_image_alt: 'Product image',

            button: {
                add: 'Ajouter un produit',
                upload: 'Télécharger xls',
                delete_btn: 'Effacer',
                clear_all: 'Tout effacer',
            },
        },

        search_page: {
            title: 'Résultats de recherche',
        },
    },

    activity_hub: {
        pages: ['Demandes de devis', 'E-Contrats'],
        requests_for_quotations: {
            title: 'Pôle d\'activités',
            rfq_price_tooltip: 'Veuillez noter que l\'appel d\'offres cité contient un ou plusieurs sous-appels d\'offres libellés dans différentes devises autres que le dollar américain. Le taux de change est fixé dans la durée de validité donnée du devis.',
            empty_text: 'Il n\'y a pas encore d\'appel d\'offres',
            table_labels: ['№', 'Taper', 'Date', 'Validité jusqu\'au', 'Jours restants', 'Le total', 'Statut'],
            table_row_type_column: {label: 'Taper', value: 'La fourniture'},
            status_row_label: 'Statut',
            create_contract_question: 'Êtes-vous sûr de vouloir créer un contrat?',
        },
        e_contracts: {
            title: 'Pôle d\'activités',
            empty_text: 'Il n\'y a pas encore de contrats électroniques',
            table_labels_exporter: ['№', 'Taper', 'Date', 'Importateur', 'Validité jusqu\'au', 'Le total', 'Modalités de paiement', 'Statut'],
            table_labels_importer: ['№', 'Taper', 'Date', 'Exportateur', 'Validité jusqu\'au', 'Le total', 'Modalités de paiement', 'Statut'],
            status_row_label: 'Statut',
            request_question: 'Voulez-vous vraiment faire cela?',
            pages: {
                contract_for_supply: {
                    title: 'Contrat de fourniture',
                    status_label: 'Statut',
                    total_label: 'Le total',
                    contract_information_label: 'Informations sur le contrat',
                    information_table_labels: ['Document', 'Date', 'Statut', 'Avant expiré, jours', 'Action'],
                    table_labels: ['Document', 'Date', 'Statut', 'Action'],
                    condition_page: {
                        condition_table_labels: {name: 'Titre', value: 'Information'},
                        table_labels: {
                            index_number: '№',
                            part_number: 'Numéro d\'article',
                            name: 'La description',
                            quantity: 'Q-té',
                            doq: 'P-ty',
                            price: 'Prix,',
                            total: 'Somme,',
                        },
                        total_label: 'Le total',
                        button: {
                            cancel: 'Annuler',
                            review: 'Aperçu',
                            open_dispute: 'Ouvrir une contestation',
                        },
                    },
                },
                origin_invoice: {
                    title: 'Constructeur de facture d\'expédition',
                    message: 'Veuillez sélectionner les articles et leur quantité que vous souhaitez expédier',
                    table_labels: ['№', 'Numéro d\'article', 'La description', 'Quantité', 'Prix', 'Somme'],
                    total_label: 'Le total',
                    button: {
                        save: 'Enregistrer',
                        cancel: 'Annuler',
                    },
                },
                packing_list_wizard: {
                    title: 'Constructeur de liste de colisage',
                    delete_place_question: 'Voulez-vous vraiment supprimer le lieu?',
                    pages: {
                        shipment_lot: {
                            place_label: 'Créer un lieu',
                            packed_status: 'Emballé',
                            empty_message: 'Il n\'y a pas encore de produit déballé',
                            select_data: {
                                label: 'Sélectionnez la méthode',
                                default_item_label: '- Sélectionner -',
                                tooltip: '',
                            },
                            button: {
                                add_serial_number: 'Ajouter s/n',
                                remove_serial_number: 'Effacer s/n',
                                add_place: 'Ajouter un lieu',
                                remove_place: 'Effacer un lieu',
                                save: 'Enregistrer',
                                cancel: 'Annuler',
                            },
                        },
                        packing_list: {
                            table_labels: ['№ Lieu', ['La description', 'Numéro de série', 'Q-té', 'P-ty', 'Poids net par unité, kg', 'Poids brut par unité, kg'], 'Caractéristiques du lieu'],
                            empty_message: 'Il n\'y a pas encore de produit emballé',
                            unpack_question: 'Voulez-vous vraiment supprimer le lieu?',
                            total_places_label: 'Nombre total d\'emplacements',
                            total_net_weight_label: 'Poids net total',
                            total_gross_weight_label: 'Poids brut total',
                            confirm_question: 'Êtes-vous sûr de vouloir créer une liste de colisage?',
                            warning_message: 'Veuillez emballer tous les articles de votre lot pour continuer',
                            unpacking_tooltip: 'Déballage',
                            button: {
                                add_place: 'Ajouter un lieu',
                                remove_place: 'Effacer un lieu',
                                save: 'Enregistrer',
                                cancel: 'Annuler',
                                create_packing_list: 'Créer une liste de colisage',
                            },
                        },
                        labelling: {
                            title: 'Étiquetage',
                            checkbox_label: 'Sélectionner toutes les étiquettes',
                            switcher_label: 'Imprimer avec ligne de coupe',
                            row_labels: {
                                carrier: 'Transporteur',
                                consignee: 'Destinataire',
                                content: 'Contenu',
                                contract: 'Сontrat',
                                destination: 'Destination',
                                dims: 'DIMS, cm',
                                gross_weight: 'Placez le poids brut',
                                net_weight: 'Placez le poids net',
                                packing_date: 'Date d\'emballage',
                                shipper: 'Expéditeur',
                                waybill: 'Voyage non',
                            },
                        },
                    },
                },
                upload_document: {
                    title: 'Téléchargement de documents',
                    text: 'Veuillez télécharger le document au format PDF:',
                    button: {
                        cancel: 'Annuler',
                        create: 'Créer',
                        btn_text: 'ou',
                    },
                },
            },
        },
        place_product: {
            warranty_title: 'Garantie',
            from_excel: {
                text: 'Veuillez télécharger un fichier excel pour placer vos produits:',
                button: {
                    cancel: 'Annuler',
                    confirm: 'Aller à mes produits',
                },
            },
            from_form: {
                title: 'Placer le produit',
                edit_title: 'Modifier le produit',
                sections: {
                    product_information: {
                        label: 'Information produit',
                        sub_tooltip: [
                            null, null, null, null,
                            [
                                {
                                    title: 'Neuf',
                                    text: 'Le produit est neuf, les dernières années de fabrication, emballé dans la couverture d\'origine du fabricant (si le producteur a prévu une telle couverture), est accompagné de tous les documents d\'accompagnement, par ex. passeports originaux, étiquettes, journaux de bord. Les duplicata des documents ne sont pas acceptés. Le produit ne présente aucun signe de démontage et d\'installation sur l\'objet. Les scellés du fabricant doivent rester intacts. La durée de stockage attribuée doit être valable au moins deux mois. La garantie du fabricant est complète et spécifiée dans la documentation.',
                                },
                                {
                                    title: 'Révisé',
                                    text: 'Révisé - le produit révisé par l\'usine ou les ateliers de réparation agréés OH, emballé dans la couverture assurant son transport en toute sécurité par n\'importe quel moyen de transport, a eu tous les documents d\'accompagnement, par exemple, les passeports originaux, les étiquettes, les journaux de bord. Les duplicata des documents sont acceptés si un organisme agréé OH les délivre. Le produit peut présenter des signes de démontage et d\'installation sur l\'objet mais doit contenir des scellés de l\'organisation OH. La durée de stockage attribuée doit être valable au moins deux mois. La garantie OH est complète et correctement spécifiée dans la documentation.',
                                },
                                {
                                    title: 'Utilisé',
                                    text: 'Utilisé - le produit est utilisé, mais doit avoir tous les signes d\'intégrité structurelle, emballé dans la couverture assurant son transport en toute sécurité par n\'importe quel moyen de transport, a tous les documents d\'accompagnement, par exemple, les passeports originaux, les étiquettes, les journaux de bord.',
                                },
                                {
                                    title: 'Du stockage',
                                    text: `Du stockage - Le produit possède toutes les fonctionnalités spécifiées dans l'option Nouveau mais a expiré le temps de stockage attribué et/ou la garantie du fabricant. Exemple : Hyrohorizon AGB-3K fabriqué en 1990 entreposé selon toutes les conditions techniques, dans la couverture d'origine du fabricant.`,
                                },
                            ],
                            {
                                title: 'Usine de refonte',
                                text: 'Veuillez choisir parmi les noms présélectionnés ou en saisir de nouveaux.',
                            },
                            {
                                title: 'Date de révision',
                                text: 'Veuillez préciser la date de révision indiquée dans les documents d\'accompagnement, par exemple, passeports, journaux de bord, étiquettes.',
                            },
                            {
                                title: 'Nombre de révisions',
                                text: 'Veuillez spécifier le nombre de révisions, par exemple, qu\'il s\'agisse du 1er OH ou du 2e OH pendant la durée de vie du produit.',
                            },
                            {
                                title: 'Garantie de l\'exportateur',
                                text: 'La garantie fournie uniquement par l\'Exportateur stipule que la réclamation éventuelle sera remplie pour l\'Exportateur dans le délai de validité spécifié.',
                            },
                            {
                                title: 'Date du colis',
                                text: 'Veuillez préciser la date d\'emballage indiquée sur l\'étiquette du produit si elle existe.',
                            },
                            {
                                title: 'Conservation jusqu\'à',
                                text: 'Désigne le délai jusqu\'à l\'expiration de la durée de stockage du produit.',
                            },
                            {
                                title: 'Fabricant',
                                text: 'Veuillez choisir parmi les noms présélectionnés ou en saisir de nouveaux.',
                            },
                            {
                                title: 'Date de fabrication',
                                text: 'Veuillez préciser la date d\'emballage indiquée sur l\'étiquette du produit si elle existe.',
                            },
                            {
                                title: 'Conditions d\'approvisionnement',
                                text: 'Le produit répertorié doit refléter la livraison à nos points de livraison conformément aux Incoterms 2020. Actuellement, nous opérons via le terminal de fret de l\'aéroport de Borispol, en Ukraine, de sorte que le seul mode d\'expédition disponible est le terminal de fret de l\'aéroport FCA Borispol, en Ukraine.',
                            },
                            null,
                            {
                                title: 'Délai de livraison',
                                text: 'Il s\'agit des délais d\'approvisionnement en jours, depuis la date d\'engagement d\'une transaction jusqu\'à la date indiquée dans la déclaration en douane d\'exportation à l\'origine ou dans toute autre facture de transport. Veuillez évaluer attentivement ce délai afin d\'éviter des sanctions pour retard de livraison.',
                            },
                            {
                                title: 'Quantité disponible',
                                text: 'Veuillez spécifier la quantité disponible que vous pouvez fournir dans le délai de livraison valide.',
                            },
                            {
                                title: 'Quantité minimale de commande',
                                text: 'Minimum qouantity of goods you can ship to the transit warehouse',
                            },
                            {
                                title: 'DOQ "Dénomination de la quantité"',
                                text: 'Veuillez sélectionner un seul article (pc) ou un ensemble contenant plusieurs blocs, unités, etc., dans votre produit.',
                            },
                            {
                                title: 'Prix',
                                text: 'Devrait être spécifié par 1 unité.',
                            },
                            {
                                title: 'Devise',
                                text: 'Veuillez noter que toutes vos annonces doivent être cotées en dollars américains par défaut sur le marché de l\'exportation, même si vous supprimez les dollars américains comme moyen de paiement pour vos produits dans votre configuration de paiement.',
                            },
                            {
                                title: 'Validité de l\'offre',
                                text: 'Durée en jours pendant laquelle votre offre sera valable sur le marché selon les paramètres cités, y compris le prix. Dans ce délai, vous ne pouvez pas ajuster les conditions de base de ce bloc.',
                            },
                        ],
                    },
                    delivery_and_payment: {
                        label: 'Livraison et Paiement',
                        subsection: {
                            delivery_payment: {
                                tooltip: [
                                    {
                                        title: 'Moyens de paiement',
                                        text: 'Actuellement, nous fournissons un moyen de paiement - un paiement contre un récépissé d\'entrepôt bloqué garanti indiquant l\'arrivée des marchandises dans notre entrepôt sous douane de transit dans le terminal de fret de l\'aéroport d\'Abu Dhabi. Ce mode de paiement est simple : une fois que vos marchandises arrivent à AbuDhabi, vous serez payé selon les conditions du contrat.',
                                    },
                                    {
                                        title: 'Devise',
                                        text: 'Vous pouvez cocher les devises dans lesquelles vous pouvez vendre votre produit sur Exportery. Veuillez noter que vous pouvez sélectionner autant de devises que vous le souhaitez, mais le règlement n\'est disponible que dans une seule devise lorsque la cotation est établie. Exemple : Vous avez coté tous vos produits sur le marché en CNY et en EUR. Lorsque votre partie contractante sélectionne l\'un de vos produits en CNY, les autres produits ne peuvent être vendus qu\'en CNY.',
                                    },
                                ],
                            },
                            regulatory_framework: {
                                title: 'Cadre réglementaire',
                                tooltip: [
                                    {
                                        title: 'Requis de l\'importateur',
                                        text: 'Veuillez évaluer et sélectionner les documents obligatoires que vous devrez soumettre aux autorités de contrôle des exportations à l\'origine.\n' +
                                            '\n' +
                                            'Trois options sont disponibles:\n' +
                                            '\n' +
                                            'Certificat d\'utilisateur final du ministère de la Défense du pays de destination\n' +
                                            'Certificat d\'utilisateur final de l\'opérateur aérien civil\n' +
                                            'Les certificats d\'utilisateur final ne sont pas nécessaires pour exporter des marchandises depuis le pays d\'origine',
                                    },
                                ],
                            },
                            shipment_conditions: {
                                title: 'Сonditions d\'expédition',
                                tooltip: [
                                    {
                                        title: 'Mode d\'expédition',
                                        text: 'Veuillez sélectionner le mode d\'expédition désigné que vous souhaitez expédier vos marchandises',
                                    },
                                ],
                            },
                            packaging: {
                                title: 'Emballage',
                                tooltip: [
                                    null, null,
                                    {
                                        title: 'Type d\'emballage',
                                        text: 'Veuillez sélectionner le matériau de votre emballage',
                                    },
                                    null, null, null,
                                    {
                                        title: 'Mesure',
                                        text: 'Veuillez vérifier les unités de mesure de vos marchandises',
                                    },
                                ],
                            },
                            dangerous_goods_information: {
                                title: 'Informations sur les marchandises dangereuses',
                                tooltip: {
                                    title: 'Información sobre mercancías peligrosas',
                                    text: 'Veuillez remplir attentivement cette section et vérifier la certification des marchandises dangereuses pour vos marchandises ou contacter notre représentant du service client.',
                                },
                            },
                        },
                    },
                    photo: {
                        label: 'Photo',
                        same_name_message: ['Des photos avec ces noms ont déjà été ajoutées:', 'Veuillez les renommer ou en ajouter d\'autres!'],
                        quantity_message: ['Une quantité maximale de', 'pièces.', 'Dont', 'ont déjà été ajoutés!'],
                        size_message: ['Les tailles des photos sont supérieures à', 'Mo:'],
                        invalid_types_label: 'Type de fichiers non valide:',
                        rejected_files_label: 'Fichiers rejetés',
                        alt_label: 'Image alt',
                        tooltip: {
                            title: 'Photo',
                            text: 'Veuillez télécharger les photos de votre produit, une quantité maximum de 10 pièces. La plateforme peut accepter des photos aux formats suivants : .jpeg, .jpg, .png d\'une taille maximale de 10 Mo chacune.',
                        },
                    },
                    main_technical_data: {
                        label: 'Principales données techniques',
                        tooltip: {
                            title: 'Données techniques principales',
                            text: 'Veuillez renseigner le nom et la valeur du paramètre de cellule si vous souhaitez télécharger le contenu technique de votre produit sous la forme d\'un tableau organisé. Vous pouvez également télécharger votre contenu en texte brut via l\'agence de la zone de texte.',
                        },
                        button: {
                            add: 'Ajouter un item',
                            remove: 'Effacer item',
                        },
                    },
                    resources: {
                        label: 'Ressources',
                        warranty_title: 'Garantie',
                        tooltip: {
                            title: 'Ressources',
                            text: 'Veuillez remplir les données réelles des cellules de ressources reflétées dans les documents techniques du produit. Remplissez la valeur de la garantie du fabricant ou de la garantie de l\'organisme de révision',
                        },
                    },
                    supply_set: {
                        label: 'Ensemble de fournitures',
                        tooltip: {
                            title: 'Ensemble d\'approvisionnement',
                            text: 'Veuillez spécifier chaque numéro de pièce qui pose l\'ensemble de fourniture pour le produit particulier. Vous pouvez choisir le numéro de pièce de chaque article dans notre vaste base de données (veuillez cocher "choisir parmi les produits" dans ce cas).',
                        },
                    },
                    certification: {
                        label: 'Certificat',
                        same_name_message: ['Des fichiers portant ces noms ont déjà été ajoutés:', 'Veuillez les renommer ou en ajouter d\'autres!'],
                        quantity_message: ['Une quantité maximale de', 'fichiers pour chaque type.', 'Dont', 'ont déjà été ajoutés à ce type de certificat!'],
                        tooltip: {
                            title: 'Certificat',
                            text: 'Veuillez télécharger les certificats de votre produit, une quantité maximum de 10 fichiers. La plateforme peut accepter des fichiers aux formats suivants : .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg d\'une taille maximale de 10 Mo chacun.',
                        },
                    },
                    product_guides_and_documents: {
                        label: 'Guides et documents de produits',
                        same_name_message: ['Des fichiers portant ces noms ont déjà été ajoutés:', 'Veuillez les renommer ou en ajouter d\'autres!'],
                        quantity_message: ['Une quantité maximale de', 'fichiers.', 'Dont', 'ont déjà été ajoutés!'],
                        tooltip: {
                            title: 'Guides et documents produits',
                            text: 'Veuillez télécharger les guides produits et les documents de vos marchandises, une quantité maximale de 10 fichiers. La plateforme peut accepter des fichiers aux formats suivants : .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg d\'une taille maximale de 10 Mo chacun.',
                        },
                    },
                    shipment_guides_and_documents: {
                        label: 'Guides et documents d\'expédition',
                        same_name_message: ['Des fichiers portant ces noms ont déjà été ajoutés:', 'Veuillez les renommer ou en ajouter d\'autres!'],
                        quantity_message: ['Une quantité maximale de', 'fichiers.', 'Dont', 'ont déjà été ajoutés!'],
                        tooltip: {
                            title: 'Guides et documents d\'expédition',
                            text: 'Veuillez télécharger les guides d\'expédition et les documents de vos marchandises, une quantité maximale de 10 fichiers. La plateforme peut accepter des fichiers aux formats suivants : .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg d\'une taille maximale de 10 Mo chacun.',
                        },
                    },
                },
                required_message: ['Les champs avec', '*', 'sont obligatoires'],
                required_field_error_message: 'Veuillez remplir tous les champs requis',
                no_documents_text: 'Aucun document à afficher',
                button: {
                    skip: 'Sauter',
                    save: 'Enregistrer',
                    cancel: 'Annuler',
                    place_product: 'Placer le produit',
                    edit_product: 'Mettre à jour le produit',
                    clear_form: 'Forme claire',
                },
            },
        },
        my_products: {
            title: 'Mes produits',
            device_info_message: ['Veuillez mettre à jour votre profil et utiliser l\'appareil de bureau pour créer ou modifier des produits'],
            product_panel: {
                header: {
                    id_label: 'Id',
                    item_label: 'Produit',
                    condition_label: 'Etat',
                    price_label: 'Le prix',
                },
                views_label: 'Vues',
            },
            product_card: {
                id_label: 'ID',
                views: 'Vues',
            },
            empty_my_products_message: 'Vous n\'avez encore rien ajouté',
            delete_offer_question: 'Voulez-vous vraiment supprimer cette offre?',
            button: {
                upload_xls: 'télécharger xls',
                upload_from_form: 'remplir le formulaire',
            },
        },
        profile: {
            title: 'Profil',
            pages: {
                payment_setup: {
                    bank_accounts_title: 'Comptes bancaires',
                    no_accounts_message: 'Il n\'y a pas encore de comptes',
                    default_account: 'Compte par défaut',
                    set_as_default: 'Définir par défaut',
                    pg_draft: [
                        ['PG DRAFT SWIFT MT', '(NARRATIVE FIELD 79)', 'THE BANK GUARANTEE NO', 'DD', 'WAS ISSUED BY RAS BANK, UAE BY THE ORDER OF EXPORTERY LTD, ABU DHABI, UAE, ID CODE 01001), IN FAVOUR OF', 'FOR AMOUNT', 'WITH THE FOLLOWING CONTENT:'],
                        ['DATE:'],
                        ['CONTRACT NUMBER AND TITLE', ': FOR THE SUPPLY OF SPARE PARTS TO'],
                        ['BANK\'S BRANCH OR OFFICE:'],
                        ['BENEFICIARY:'],
                        ['WE HAVE BEEN INFORMED THAT EXPORTERY LTD  (HEREINAFTER CALLED THE “SELLER”) HAS ENTERED INTO CONTRACT NO', 'DD', 'WITH YOU, FOR THE SUPPLY OF SPARE PARTS TO', '(HEREINAFTER CALLED \'THE CONTRACT\'). FURTHERMORE, WE UNDERSTAND THAT, ACCORDING TO THE CONDITIONS OF THE CONTRACT, A PERFORMANCE GUARANTEE IS REQUIRED.'],
                        ['AT THE REQUEST OF THE SUPPLIER, WE HEREBY IRREVOCABLY UNDERTAKE TO PAY YOU ANY SUM(S) NOT EXCEEDING', 'UPON RECEIPT BY US OF YOUR FIRST DEMAND IN WRITING DECLARING THE SUPPLIER TO BE IN DEFAULT UNDER THE CONTRACT, WITHOUT CAVIL OR ARGUMENT, OR YOUR NEEDING TO PROVE OR TO SHOW GROUNDS OR REASONS FOR YOUR DEMAND OR THE SUM SPECIFIED THEREIN.'],
                        ['THIS GUARANTEE SHALL ENTER INTO FORCE ON THE TERMS SET OUT IN THE CONTRACT OF BANK GUARANTEE NO', 'OF', 'AND WILL BE VALID UNTIL THE'],
                        ['THIS GUARANTEE SHALL EXPIRE, NO LATER THAN THE OF', 'AND ANY DEMAND FOR PAYMENT UNDER IT MUST BE RECEIVED BY US AT THIS OFFICE ON OR BEFORE THAT DATE. HOWEVER, BEFORE THAT EXPIRATION DATE, IF THE PLANNED CONTRACT EXECUTION PERIOD HAS BEEN DELAYED OR EXTENDED, OR ITS VALUE INCREASED, THE EXPORTER SHALL RESPECTIVELY EXTEND THE VALIDITY PERIOD OF THIS PERFORMANCE SECURITY OR INCREASE ITS AMOUNT ACCORDINGLY.'],
                        ['THIS GUARANTEE IS SUBJECT TO THE UNIFORM RULES FOR DEMAND GUARANTEES, ICC PUBLICATION NO. 458, EXCEPT THAT SUBPARAGRAPH (II) OF SUB-ARTICLE 19(A) IS HEREBY EXCLUDED.'],
                        ['THANKS FOR COOPERATION,'],
                        ['BEST REGARDS,'],
                    ],
                    button: {
                        add_account: 'Ajouter un compte bancaire',
                    },
                },
                shipment_setup: {
                    default_label: 'Défaut',
                    set_as_default: 'Définir par défaut',
                    no_items_message: 'Il n\'y a encore rien ici',
                    button: {
                        consignee: 'Ajouter un destinataire',
                        notifyparty: 'Ajouter une partie à notifier',
                        shipper: 'Ajouter un expéditeur',
                        importerforwarder: 'Ajouter un transitaire',
                        exporterforwarder: 'Ajouter un transitaire',
                    },
                },
                shipment_organization: {
                    create_consignee_title: 'Créer les détails du destinataire',
                    create_notify_title: 'Créer une partie à notifier',
                    create_forwarder: 'Créer un transitaire',
                    create_shipper_title: 'Créer un expéditeur',
                    edit_consignee_title: 'Modifier les détails du destinataire',
                    edit_notify_title: 'Modifier la partie à notifier',
                    edit_forwarder: 'Modifier le transitaire',
                    edit_shipper_title: 'Modifier l\'expéditeur',
                    default_change_question: 'Êtes-vous sûr de vouloir modifier l\'élément par défaut?',
                },
                bank_account: {
                    create_account_title: 'Créer un compte bancaire',
                    edit_account_title: 'Modifier le compte bancaire',
                    default_change_question: 'Êtes-vous sûr de vouloir modifier le compte bancaire par défaut ?',
                },
                access_setup: {
                    title: 'Configuration de l\'accès',
                },
            },
            button: {
                access_setup: 'Configuration de l\'accès',
                save: 'Enregistrer',
                cancel: 'Annuler',
            },
        },
        notifications: {
            title: 'Avis',
            filters: ['Tout', 'Non lu'],
            no_notifications_message: {
                all: 'Vous n\'avez pas encore de notifications',
                unread: 'Vous ñ\'avez pas notification non lue',
            },
            delete_current_notification_question: 'Voulez-vous vraiment supprimer cette notification?',
            delete_question: 'Voulez-vous vraiment supprimer les notifications sélectionnées?',
            button: {
                select: 'Sélectionner',
                cancel: 'Annuler',
                delete_btn: 'Effacer',
                show_more: 'Montre plus',
            },
        },
        upload_documents: {
            same_name_message: ['Des fichiers portant ces noms ont déjà été ajoutés:', 'Veuillez les renommer ou en ajouter d\'autres!'],
            quantity_message: ['Une quantité maximale de', 'fichiers.', 'Dont', 'ont déjà été ajoutés!'],
        },
    },

    hub_menu: {
        link_labels: {
            quotation: 'Le devis',
            quotation_request_for_supply: 'Demande de fourniture',
            quotation_request_for_overhaul: 'Demande de révision',
            activity_hub: 'Pôle d\'activités',
            my_products: 'Mes produits',
            notifications: 'Avis',
            profile: 'Profil',
            log_out: 'Se déconnecter',
        },
        user_name: 'Utilisateur',
        profile_img_alt: 'Profile icon',
    },

    search: {
        input_placeholder: 'Rechercher',
        result_titles: ['Plate-forme', 'Titre'],
    },

    header: {
        button_menu: {
            importer: {
                btn_label: 'le devis',
                menu: [
                    {
                        label: 'Demande de fourniture',
                        href: '/marketplace/quotation',
                        isDisabled: false,
                    },
                    {
                        label: 'Demande de révision',
                        isDisabled: true,
                    },
                ],
            },
            exporter: {
                btn_label: 'Placer le produit',
                menu: [
                    {
                        label: 'Téléchargement par lots',
                        href: hubURLs.products_excel,
                    },
                    {
                        label: 'Téléchargement manuel',
                        href: hubURLs.product_create_manual,
                    },
                ],
            },
        },
        trial_identifier: {
            label: 'Mode d\'essai',
            tooltip: {
                text: `Veuillez remplir les données de profil ou contacter le service client 
                pour obtenir un accès complet.`,
            },
        },
        button: {
            sign_in: 'S\'identifier',
        },
    },

    footer: {
        text: 'EXPORTERY est une plate-forme de commerce électronique industrielle sécurisée englobant un marché mondial de pièces de rechange et de composants pour les utilisateurs finaux et les exportateurs des secteurs de l’aviation et du transport terrestre, équipée d’un système exclusif de gestion électronique des documents qui élève l’efficacité et la sécurité des achats transfrontaliers à un nouveau niveau.',
        navigation_links: [
            {
                id: 'contact_us',
                title: 'Contactez-nous',
                url: '/contact_us',
            },
            {
                list: [
                    {
                        id: 'about-marketplace',
                        title: 'Comment fonctionne Exportery',
                        url: staticURLs.about_marketplace,
                    },
                    {
                        id: 'about-independent-technical-surveying',
                        title: 'étude technique indépendante',
                        url: staticURLs.about_independent_technical_surveying,
                    },
                    {
                        id: 'about-core-services',
                        title: 'Les services de base',
                        url: staticURLs.about_core_services,
                    },
                    {
                        id: 'news',
                        title: 'Nouvelles',
                        url: URLs.news,
                    },
                    {
                        id: 'terms_and_conditions',
                        title: 'termes et conditions',
                        url: staticURLs.terms_and_conditions,
                    },
                    {
                        id: 'privacy_policy',
                        title: 'politique de confidentialité',
                        url: staticURLs.privacy_policy,
                    },
                ],
            },
            {},
        ],
        social_links: [
            {
                id: 'linkedin',
                url: CONTACT_DATA.linkedin,
                img: 'linkedin',
            },
            {
                id: 'facebook',
                url: CONTACT_DATA.facebook,
                img: 'facebook',
            },
            {
                id: 'twitter',
                url: CONTACT_DATA.twitter,
                img: 'twitter',
            },
        ],
        contact_address: CONTACT_DATA.address_short,
        contact_email: CONTACT_DATA.email,
        contact_phone: CONTACT_DATA.phone,
        copyright: `Droits d’auteurs © ${getCurrentYear()} EXPORTERY, tous droits réservés.`,
    },

    filters: {
        filters_label: 'Filtres',
        part_number_label: 'Entrer le numéro de pièce',
        part_number_label_mobile: 'Numéro d\'article',
        title_label: 'Titre',
        platform_label: 'Plate-forme',
        organization_label: ['Exportateur', 'Importateur'],
        search_label: 'Rechercher',
        button: {
            show_more: 'montre plus',
            show_less: 'montrer moins',
        },
    },

    sorting: {
        sorting_label: 'Tri',
        sorting_title: 'Trier par:',
    },

    filters_sorting_button: {
        show_result: 'afficher le résultat',
        reset_all: 'Effacer tout',
        reset_pnum: 'Effacer le filtre de numéro de pièce',
    },

    breadcrumbs: new BreadcrumbsConstructor()
        .creator({
            notifications: ['Avis'],
            profile: ['Profil'],
            access_setup: ['Profil', 'Configuration de l\'accès'],
            bank_account_create: ['Profil', 'Créer un compte bancaire'],
            bank_account_update: ['Profil', 'Modifier le compte bancaire'],
            consignee_details_create: ['Profil', 'Créer les détails du destinataire'],
            consignee_details_update: ['Profil', 'Modifier les détails du destinataire'],
            exporter_forwarder_create: ['Profil', 'Créer un transitaire'],
            exporter_forwarder_update: ['Profil', 'Modifier le transitaire'],
            importer_forwarder_create: ['Profil', 'Créer un transitaire'],
            importer_forwarder_update: ['Profil', 'Modifier le transitaire'],
            notify_party_create: ['Profil', 'Créer une partie à notifier'],
            notify_party_update: ['Profil', 'Modifier la partie à notifier'],
            shipper_create: ['Profil', 'Créer un expéditeur'],
            shipper_update: ['Profil', 'Modifier l\'expéditeur'],
            quotation_excel: ['Place du marché', 'Demande de fourniture', 'Télécharger XLS'],
            quotation_excel_format: ['Place du marché', 'Demande de fourniture', 'Télécharger XLS', 'Le format'],
            independent_technical_surveying: ['Étude technique indépendante'],
            core_services: ['Les services de base'],
            terms_and_conditions: ['Termes et conditions'],
            privacy_policy: ['Politique de confidentialité'],
            request_access_page: ['Demande d\'accès'],
            not_found_page: ['Page non trouvée'],
            not_acceptable_page: ['Page non acceptable'],
            internal_server_error_page: ['Erreur interne du serveur'],
            contract_for_supply_invoice: ['Créer une facture d\'expédition'],
            contract_for_supply_packing_list: ['Assistant liste de colisage'],
            contract_for_supply_labelling: ['Étiquetage'],
            document_page: ['Document'],
        }, 'Maison', session),

    error_page: {
        title: 'Désolé',
        route_not_found: 'page introuvable',
        service_under_development: 'la page est en construction',
        internal_server_error: [
            'Eh bien, c\'est inattendu...',
            'Code d\'erreur: 500',
            'Une erreur s\'est produite et nous nous efforçons de résoudre le problème.',
        ],
        button: {
            go_back: 'Retourner',
            go_to_homepage: 'Aller à la page d\'accueil',
        },
    },

    alert_modal: {
        delete_item_question: 'Êtes-vous sûr de vouloir supprimer l\'élément?',
        delete_last_item_question: 'Êtes-vous sûr de vouloir supprimer le dernier élément?',
        delete_all_items_question: 'Êtes-vous sûr de vouloir supprimer tous les éléments?',
        clear_all_question: 'Êtes-vous sûr de vouloir tout supprimer?',
        clear_form_question: 'Êtes-vous sûr de vouloir tout supprimer?',
        session_expired_message: 'Cher utilisateur, votre session a expiré. Veuillez vous reconnecter!',
        redirect_disclaimer: 'Veuillez utiliser la version de bureau pour bénéficier de toutes les fonctionnalités du produit',
        permission_denied: 'Autorisation refusée pour votre utilisateur!',
        close_modal_window_question: 'Les données non enregistrées seront perdues ! Voulez-vous fermer le formulaire?',
        button: {
            ok: 'ok',
            cancel: 'Annuler',
        },
    },

    item_counter: {
        empty_error_message: 'Ne peux pas être vide!',
        less_error_message: 'La quantité ne peut pas être inférieure à 1 pièce',
        more_error_message: ['La quantité ne peut pas dépasser', 'pièce'],
    },

    dropzone: {
        text: ['Déposez votre fichier ici ou', 'parcourez', 'Le fichier doit être'],
        not_for_desktop_text: ['Browse', 'the file from your device'],
    },

    file_container: {
        error_message: 'Le fichier doit être',
        button: {
            delete_btn: 'Effacer',
        },
    },

    excel_response_info: {
        row_id: 'Ligne Excel',
        filters: ['Tout', 'Seulement des erreurs'],
        no_errors: 'Aucune erreur à afficher',
        button: {
            clear_all: 'Tout effacer',
        },
    },

    excel_format_page: {
        quotation_text: 'Veuillez vous conformer à l\'exemple de demande de devis au format .xls pour obtenir votre devis instantané',
        place_product_text: 'Veuillez suivre l\'exemple ci-dessous pour placer vos produits sur la place de marché électronique :',
        quotation_tip: 'Veuillez télécharger un exemple du fichier .xls RFQ',
        place_product_tip: 'Veuillez télécharger un exemple du fichier .xls',
        quotation_table: {
            headers: ['Numéro d\'article', 'La description', 'DDLQ', 'Quantité'],
            body: [
                {
                    part_number: 'LFS-PS27-450',
                    description: 'Lampe',
                    doq: 'pc',
                    quantity: '5',
                },
                {
                    part_number: 'АGB-3К Series 3',
                    description: 'Horizon gyroscopique',
                    doq: 'pc',
                    quantity: '1',
                },
                {
                    part_number: '463B',
                    description: 'Pompe à carburant',
                    doq: 'pc',
                    quantity: '2',
                },
                {
                    part_number: 'RU-19А-300',
                    description: 'APU',
                    doq: 'le kit',
                    quantity: '1',
                },
                {
                    part_number: 'KURS-MP70',
                    description: 'Système de navigation et d\'atterrissage',
                    doq: 'le kit',
                    quantity: '1',
                },
                {
                    part_number: 'GA-192T',
                    description: 'Soupape',
                    doq: 'pc',
                    quantity: '2',
                },
                {
                    part_number: '12-SAM-28',
                    description: 'Batterie',
                    doq: 'pc',
                    quantity: '2',
                },
                {
                    part_number: '1300x480',
                    description: 'Pneu principal',
                    doq: 'pc',
                    quantity: '2',
                },
            ],
        },
        place_product_table: {
            headers: ['Numéro d\'article', 'Numéro d\'article(Сyrillique)', 'La description', 'Section', 'DDLQ', 'Quantité', 'Etat', 'Prix, USD', 'Devise', 'Date de fabrication/révision', 'Validité de l\'offre, jours', 'Délai de livraison, jours', 'Requis de l\'importateur', 'Conditions de fourniture(Incoterms 2020)', 'Point d\'approvisionnement'],
            body: [
                {
                    part_number: 'VR-14',
                    part_number_cyrillic: 'ВР-14',
                    description: 'Boîte de vitesses',
                    section: 'Hélicoptères',
                    doq: 'pc',
                    quantity: '3',
                    condition: 'NOUVEAU',
                    price: '700000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9800-00',
                    part_number_cyrillic: '8АТ-9800-00',
                    description: 'Barre de remorquage',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '2000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9803-00',
                    part_number_cyrillic: '8АТ-9803-00',
                    description: 'TAPIS POUR TRAVAUX SOUS FUSELAGE',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '400',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9924-00',
                    part_number_cyrillic: '8АТ-9924-00',
                    description: 'ÉLINGUE POUR LEVAGE DE LA BARRE DE QUEUE',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '2100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-9923-00',
                    part_number_cyrillic: '8-9923-00',
                    description: 'CARTER D\'HUILE',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '300',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3901-000',
                    part_number_cyrillic: '246-3901-000',
                    description: 'HÉLICE ANTI-COUPLE',
                    section: 'Hélicoptères, L\'aviation de transport',
                    doq: 'set',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '180000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-1960-000',
                    part_number_cyrillic: '8-1960-000',
                    description: 'PLATEAU OSCILLANT',
                    section: 'Hélicoptères',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '80000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3922-00',
                    part_number_cyrillic: '246-3922-00',
                    description: 'LAMES POUR ANTI-COUPLE',
                    section: 'Hélicoptères',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '60000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '610200А',
                    part_number_cyrillic: '610200А',
                    description: 'VANNE ÉLECTROMAGNÉTIQUE',
                    section: 'Hélicoptères',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '1100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8AT-2710-000',
                    part_number_cyrillic: '8AT-2710-000',
                    description: 'PALES DU ROTOR PRINCIPAL',
                    section: 'Hélicoptères',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NOUVEAU',
                    price: '190000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'les documents de l\'utilisateur final ne sont pas requis',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
            ],
        },
        button: {
            back: 'Arrière',
        },
    },

    input_phone: {
        search_placeholder: 'Chercher',
        search_not_found: 'Aucune entrée à afficher',
    },

    input_field: {
        input_type_message: 'Veuillez entrer',
        input_type: {
            'int': 'un nombre entier positif',
            'float': 'un entier positif ou un nombre décimal',
        },
    },

    select: {
        search_field_placeholder: 'Chercher',
        search_option_not_found: 'Aucune entrée à afficher',
        default_selected_label: '- Sélectionner -',
    },

    select_autocomplete: {
        no_options_message: 'aucune option',
    },

    pagination: {
        button: {
            prev: 'Préc',
            next: 'Suiv',
        },
    },

    page_to_print: {
        title: 'Page à imprimer',
        button: {
            cancel: 'Annuler',
            print: 'Imprimer',
        },
        print_blob_button_alt: 'Imprimer',
    },

    document_viewer: {
        document_not_exist: 'Il ñ\'y a aucun document à afficher!',
        document_error: 'Une erreur s\'est produite lors du téléchargement du fichier!',
        document_loading_failed: 'Échec du chargement du fichier PDF.',
        pdf_no_data: 'Aucun fichier PDF spécifié.',
        page_error: 'Impossible de charger la page.',
        page_no_data: 'Aucune page spécifiée.',
    },

    cookie_consent: {
        title: 'Consentement aux cookies',
        text: 'Ce site utilise des cookies qui aident le site à fonction et aussi pour suivre la façon dont vous interagissez avec notre site Internet. Mais pour nous de fournir le meilleur utilisateur expérience, activez les cookies spécifiques de Paramètres, puis cliquez sur Accepter.',
        buttons: {
            reject_all: 'Tout rejeter',
            accept_all: 'Accepter tout',
        },
    },

    sweet_alert_static_data: {
        error_response_place_product: 'Les erreurs:',
    },

    logo_alt: 'Logo',
});

export default fr;