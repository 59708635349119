import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import onRedirect from '../../../../../common/handlers/redirect';
import {HubSideMenu} from '../../../../../common/hub-menu';

import styles from './with-hub-side-menu.module.scss';

/* HOC use staticData
 before use withHubSideMenu wrap Component to withDataService HOC */
const withHubSideMenu = () => (Wrapped) => {
    return (props) => {
        const {title = null, withRedirect = false, mainID = 'hub-side-main', redirectUrl, ...rest} = props;
        const xlDevice = useMediaQuery({minWidth: 1200});
        const [redirectAction, setRedirectAction] = useState(true);

        // need work for normal redirect in all situations
        useEffect(() => {
            if (redirectAction) {
                if (!xlDevice && withRedirect) {
                    const {redirect_disclaimer} = props.staticData.alert_modal;
                    onRedirect(props.history, redirectUrl, redirect_disclaimer);
                }

                setRedirectAction(false);
            }
        }, [xlDevice, props.staticData, props.history, redirectAction, redirectUrl, withRedirect]);

        return (
            <div className={styles.container}>
                <HubSideMenu/>

                <div id={mainID} className={styles.main}>
                    {xlDevice || (!xlDevice && !withRedirect) ? (
                        <>
                            {title && <div className={styles.title}>{title}</div>}
                            <Wrapped {...rest}/>
                        </>
                    ) : null}
                </div>
            </div>
        );
    };
};

export default withHubSideMenu;