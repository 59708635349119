import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../common/hoc';
import {onGetButtonMethod} from "../../../../../../../common/button-constructor/handlers";
import sweetAlert from '../../../../../../../common/sweet-alert';

import Select from '../../../../../../../common/select';
import ButtonConstructor from "../../../../../../../common/button-constructor";

import classnames from 'classnames/bind';
import styles from './options-menu.module.scss';

const OptionsMenu = ({staticData, buttons, menuClassNames, isDisabledDelete, onRemoveProductOffer}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory();
    const {delete_offer_question} = staticData;
    const xlDevice = useMediaQuery({minWidth: 1200});
    const cx = classnames.bind(styles);

    const onButtonClick = ({method, target, url}) => {
        if (method === 'POST') {
            if (!isDisabledDelete) {
                sweetAlert('question', delete_offer_question)
                    .then((resp) => {
                        if (resp.isConfirmed) {
                            onRemoveProductOffer(url);
                        }
                    });
            }

        } else {
            onGetButtonMethod(url, target, history);
        }
    }

    let menuBody = null;
    if (buttons) {
        menuBody = buttons.map((button, idx) => {
            if (idx === 0 && !xlDevice) {
                return null;
            }

            const classNames = cx('item', {'disabled': idx === 1 && isDisabledDelete});

            return <ButtonConstructor key={idx} {...button} classNames={classNames} onClick={onButtonClick}/>
        })
            .filter(item => item);
    }

    return menuBody && menuBody.length > 0
        ? (
            <Select selectedItem={<div className={styles['list-button']}/>}
                    isOpenSelect={isMenuOpen}
                    placement={'bottom-end'}
                    selectedItemClasses={menuClassNames}
                    optionsClassName={styles['options-block']}
                    onToggleSelect={() => setIsMenuOpen(!isMenuOpen)}
                    withArrow
            >
                {menuBody}
            </Select>
        ) : null;
};

OptionsMenu.defaultProps = {
    isDisabledDelete: false,
    onRemoveProductOffer: () => {
    },
};

OptionsMenu.propTypes = {
    staticData: PropTypes.object,
    menuClassNames: PropTypes.string,
    buttons: PropTypes.array,
    isDisabledDelete: PropTypes.bool,
    onRemoveProductOffer: PropTypes.func,
};

export default withDataService('activity_hub.my_products')(OptionsMenu);