export const rfq_statuses = {
    pending: {label: 'Ready for contracting', value: 'pending'},
    contracted: {label: 'Fully contracted', value: 'contracted'},
    expired: {label: 'Expired', value: 'expired'},
    partially: {label: 'Partially contracted', value: 'partially'},
};

export const e_contract_statuses = {
    pending: {label: 'Pending', value: 'pending'},
    negotiation: {label: 'Negotiation', value: 'negotiation'},
    expired: {label: 'Expired', value: 'expired'},
    completed: {label: 'Completed', value: 'completed'},
    approved: {label: 'Approved', value: 'approved'},
    rejected: {label: 'Rejected', value: 'rejected'},
    signed: {label: 'Signed', value: 'signed'},
    awaiting: {label: 'Awaiting Documents', value: 'awaiting'},
    issued: {label: 'Issued', value: 'issued'},
    submitted: {label: 'Document submited', value: 'submitted'},
    draft: {label: 'Wait for sign', value: 'draft'},
};
