import DataService from '../../services/data-service';
import {
    getInitItemFromSelectList,
    getSelectedItemFromSelectList,
    getCheckboxValue,
} from './handlers';

export default class FormFieldBuilder {
    constructor() {
        this.dataService = new DataService();
        this.sessionStorage = window.sessionStorage;
    }

    fetchStaticData = (language) => this.dataService.getStaticData(language);

    fetchSessionStorageItems = (item, key) => JSON.parse(this.sessionStorage.getItem(`${item}_${key}`));

    transformSelectFieldList = (list, language = 'en') => {
        const {default_selected_label} = this.fetchStaticData(language).select;
        const defaultSelectValue = {value: 'default', label: default_selected_label};
        let newList = [defaultSelectValue, ...list];

        const defaultItemIdx = list.findIndex((item) => item.value === 'default');
        if (defaultItemIdx >= 0) {
            newList = [
                ...list.slice(0, defaultItemIdx),
                defaultSelectValue,
                ...list.slice(defaultItemIdx + 1),
            ];
        }

        return newList;
    };

    initFieldData = (field) => {
        const {type, name, list, value, group} = field;

        if (type === 'select_autocomplete' || (type === 'checkbox' && list)) {
            field = {
                ...field,
                name: `${name}[]`,
            };
        }

        if (!value) {
            if (type === 'select_autocomplete') {
                return {
                    ...field,
                    fixedOptions: [],
                    value: [],
                };
            }

            if (type === 'select') {
                if (list && list.length > 0) {
                    return {
                        ...field,
                        value: getInitItemFromSelectList(list).value,
                    };
                }

                return field;
            }

            if ((type === 'checkbox' && !list && !group) || type === 'switcher') {
                return {
                    ...field,
                    value: 0,
                };
            }

            if (type === 'radio') {
                const newList = list.map((item, idx) => ({
                    ...item,
                    isChecked: idx === 0,
                }));

                const value = newList.find(({isChecked}) => isChecked).value;

                return {
                    ...field,
                    list: newList,
                    value,
                };
            }

            /* checkbox group or dropzone */
            if ((type === 'checkbox' && list) || type === 'dropzone') {
                return {
                    ...field,
                    value: [],
                };
            }

            /* select-input group (select is active (1) or input (0)) */
            if (type === 'checkbox' && !list && group) {
                return {
                    ...field,
                    value: 1,
                };
            }

            return {
                ...field,
                value: '',
            };

        } else {
            if (type === 'select_autocomplete') {
                return {
                    ...field,
                    fixedOptions: value.map(({id, name}) => ({value: id, label: name, isFixed: true})),
                    value: value.map(({id, name}) => ({value: id, label: name})),
                };
            }

            if (type === 'select') {
                if (list && list.length > 0) {
                    if (!getSelectedItemFromSelectList(list, value)) {
                        return {
                            ...field,
                            value: getInitItemFromSelectList(list).value,
                        };
                    }
                }

                return field;
            }

            if (type === 'checkbox' && list) {
                return {
                    ...field,
                    value: value.map(({value}) => value),
                };
            }

            if ((type === 'checkbox' && !list) || type === 'switcher') {
                return {
                    ...field,
                    value: parseInt(value),
                };
            }

            if (type === 'radio') {
                const newList = list.map((item) => ({
                    ...item,
                    isChecked: item.value === value,
                }));

                return {
                    ...field,
                    list: newList,
                };
            }

            return field;
        }
    };

    transformFieldData = (field, sessionStorageItems, sessionFilledByPnumItems) => {
        if (sessionStorageItems) {
            let newField = this.initFieldData(field);
            const {type, name, list, props} = newField;

            if (sessionStorageItems.hasOwnProperty(name)) {
                if (sessionFilledByPnumItems && sessionFilledByPnumItems.includes(name)) {
                    const additionalProps = ['readonly', 'autocomplete_by_pnum'];
                    const additionalPropsForPnum = ['autocomplete_pnum'];
                    newField = {
                        ...newField,
                        props: name !== 'part_number'
                            ? props ? [...props, ...additionalProps] : additionalProps
                            : props ? [...props, ...additionalPropsForPnum] : additionalPropsForPnum,
                    };
                }

                if (type === 'radio') {
                    let value = sessionStorageItems[name];

                    const newList = list.map((item) => {
                        if (typeof item.value === 'number') {
                            value = parseInt(value);
                        }

                        return {
                            ...item,
                            isChecked: value === item.value,
                        };
                    });

                    value = newList.find(({isChecked}) => isChecked).value;

                    return {
                        ...newField,
                        list: newList,
                        value,
                    };
                }

                if ((type === 'checkbox' && !list) || type === 'switcher') {
                    return {
                        ...newField,
                        value: parseInt(sessionStorageItems[name]),
                    };
                }

                if (type === 'checkbox' && list) {
                    let field = {};
                    if (Array.isArray(sessionStorageItems[name])) {
                        field = {
                            ...newField,
                            value: sessionStorageItems[name],
                        };
                    } else {
                        field = {
                            ...newField,
                            value: [sessionStorageItems[name]],
                        };
                    }

                    const newValues = getCheckboxValue(field);
                    return {
                        ...field,
                        value: newValues,
                    };
                }

                return {
                    ...newField,
                    value: sessionStorageItems[name],
                };
            }

            return newField;

        } else {
            return this.initFieldData(field);
        }
    };

    onCheckingPasswordFields = (list, name) => {
        /* check password confirmation status (correct/doesn't correct) */
        /* filter password and passwordConfirmation field */
        const passwordField = list.find(field =>
            field.name.includes('password') && !field.name.includes('password_confirmation'));
        const passwordConfirmationFieldIdx = list.findIndex(field =>
            field.name.includes('password_confirmation'));
        let passwordConfirmationField = list[passwordConfirmationFieldIdx];

        if (passwordField.name === name) {
            /* checking change in the password field */
            /* firstly reset passwordConfirmation 'correct' status to null,
            if passwordConfirmation doesn't have 'correct' status (undefined), the 'correct' sets to null */
            if (passwordConfirmationField.correct !== null) {
                passwordConfirmationField = {
                    ...passwordConfirmationField,
                    correct: null,
                };
            }

            /* checking if passwordConfirmation isn't empty and equal to password,
            then return to 'correct' status */
            if (passwordConfirmationField.value) {
                passwordConfirmationField = {
                    ...passwordConfirmationField,
                    correct: passwordField.value === passwordConfirmationField.value,
                };
            }
        }

        if (passwordConfirmationField.name === name) {
            passwordConfirmationField = {
                ...passwordConfirmationField,
                correct: passwordConfirmationField.value
                    ? passwordField.value === passwordConfirmationField.value
                    : null,
            };
        }

        return [
            ...list.slice(0, passwordConfirmationFieldIdx),
            passwordConfirmationField,
            ...list.slice(passwordConfirmationFieldIdx + 1),
        ];
    }

    getDataFromFields = (list) => {
        let data = {};
        list.forEach(({type, name, value, list}) => {
            if (type === 'radio') {
                const activeRadioIdx = list.findIndex(({isChecked}) => isChecked);
                value = list[activeRadioIdx].value;
            }

            if (type === 'text' && value && value.floatValue) {
                value = value.floatValue;
            }

            data = {
                ...data,
                [name]: value,
            };
        });

        return data;
    };

    removeSessionStorageItems = (item, key) => {
        this.sessionStorage.removeItem(`${item}_${key}`);
    };
}