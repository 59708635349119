export const languages = [
    {
        code: 'en',
        isActive: true,
        label: 'English',
    },
    {
        code: 'fr',
        isActive: false,
        label: 'Français',
    },
    {
        code: 'es',
        isActive: false,
        label: 'Español',
    },
    {
        code: 'ru',
        isActive: false,
        label: 'Русский',
    },
];