import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import {NOT_CAPITALIZE_LANGUAGES} from '../../../services/data-service/constants';
import {registrationAction} from '../../../actions/common';

import Button from '../button';

import classnames from 'classnames/bind';
import styles from './request-access-zone.module.scss';

const RequestAccessZone = ({staticData}) => {
    const {red_zone_title, red_zone_text, button: {request_access_btn}} = staticData;

    const language = useSelector(({
                                      session: {session},
                                      profile,
                                      publicData: {homePage},
                                  }) => session ? profile.language : homePage.language);
    const dispatch = useDispatch();

    const cx = classnames.bind(styles);
    const notCapitalizeClass = cx({'not-capitalize-text': NOT_CAPITALIZE_LANGUAGES.includes(language)});
    const redZoneTitleClasses = `${styles.title} ${notCapitalizeClass}`;

    return (
        <div className={styles.block}>
            <div className={styles.container}>
                <div className={redZoneTitleClasses}>{red_zone_title}</div>
                <div className={styles.text}>{red_zone_text}</div>
                <Button classNames={styles.btn}
                        onClick={() => dispatch(registrationAction.openSignUp())}>
                    {request_access_btn}
                </Button>
            </div>
        </div>
    );
};

RequestAccessZone.propTypes = {
    staticData: PropTypes.object,
};

export default withDataService('home_page')(RequestAccessZone);