export default class PlaceProductService {
    constructor(httpService) {
        this._http = httpService;
    }

    getFormFields = () => {
        return this._http.get('/hub/product/form');
    };

    getEditFormFields = (id) => {
        return this._http.get(`/product/update/${id}`);
    };

    uploadFileActions = (url = '/products/upload/action', body) => {
        if (body) {
            return this._http.post(url, body);
        }

        return this._http.get(url);
    };

    uploadProductData = (body) => {
        return this._http.post('/product/create', body);
    };

    uploadEditProductData = (id, body) => {
        return this._http.post(`/product/update/${id}`, body);
    };
}