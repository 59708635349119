import React from 'react';
import PropTypes from 'prop-types';

import {priceHandler} from '../../../../../../../../../utils';

import Checkbox from '../../../../../../../../common/checkbox';
import ItemCounter from '../../../../../../../../common/item-counter';
import EllipsisTextTooltip from '../../../../../../../../common/ellipsis-text-tooltip';

import styles from './invoice-item.module.scss';

const InvoiceItem = (props) => {
    const {
        initialItem: {quantity: initQuantity},
        id, number, name, quantity, value, row,
        onQuantityChange, onChangeCheckbox,
    } = props;

    const rowData = row.map((item, idx) => {
        let {value} = item;
        if (idx === row.length - 1 || idx === row.length - 2) {
            return <div key={idx} className={`${styles.column} ${styles.end}`}>{priceHandler(value)}</div>;
        }

        if (idx === row.length - 3) {
            return <ItemCounter key={idx}
                                styleType={'SECONDARY'}
                                classNames={styles.column}
                                maxValue={initQuantity}
                                value={quantity}
                                onCountChange={(quantity, error) => onQuantityChange(quantity, id, error)}/>;
        }

        return (
            <div key={idx} className={styles.column}>
                {idx === 0 || idx === 1 ? <EllipsisTextTooltip tooltip={value}/> : value}
            </div>
        );
    });

    return (
        <div className={styles.container}>
            <Checkbox id={id}
                      classNames={styles.checkbox}
                      name={name}
                      value={value}
                      activeCheckboxList={value !== 0 ? [value] : []}
                      onChange={onChangeCheckbox}/>

            <div className={styles.row}>
                <div className={styles.column}>{number}</div>
                {rowData}
            </div>
        </div>
    );
};

InvoiceItem.defaultProps = {
    onChangeCheckbox: () => {
    },
};

InvoiceItem.propTypes = {
    initialItem: PropTypes.shape({
        quantity: PropTypes.number,
    }),
    id: PropTypes.number,
    number: PropTypes.number,
    name: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.number,
    row: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    onQuantityChange: PropTypes.func,
    onChangeCheckbox: PropTypes.func,
};

export default InvoiceItem;