import React from 'react';

import Tooltip from '../../../../../../../../common/tooltip';
import InfoMark from '../../../../../../../../common/info-mark';

import classnames from 'classnames/bind';
import styles from './with-section-panel.module.scss';

const withSectionPanel = () => (Wrapped) => {
    return (props) => {
        const {
            panelData: {label, tooltip = null},
            id,
            labelClassNames,
            labelTextClassNames,
            contentClassNames,
            isDisabled = false,
            isDisabledAlways = false,
            ...rest
        } = props;

        const cx = classnames.bind(styles);
        const labelClasses = cx('label', labelClassNames);
        const labelTextClasses = cx('label-text', labelTextClassNames);
        const contentClasses = cx('content', contentClassNames);

        return (
            <div id={id} className={styles.container}>
                {!isDisabledAlways
                    ? (
                        <>
                            {isDisabled && <div className={styles['hiding-container']}/>}
                            <div className={labelClasses}>
                                <div className={labelTextClasses}>{label}</div>
                                {tooltip && (
                                    <Tooltip tooltip={(
                                        <>
                                            <div className={styles['info-title']}>{tooltip.title}</div>
                                            <div>{tooltip.text}</div>
                                        </>
                                    )}
                                             contentClassNames={styles['info-content']}
                                             minWidth={400}
                                             maxWidth={400}
                                             zIndex={2}>
                                    <span className={styles.info} onClick={(e) => e.stopPropagation()}>
                                        <InfoMark classNames={styles['info-icon']}/>
                                    </span>
                                    </Tooltip>
                                )}
                            </div>
                            <div className={contentClasses}>
                                <Wrapped {...rest} isDisabledSection={isDisabled} id={id}/>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles['hiding-container']}/>
                            <div className={labelClasses}>
                                <div className={labelTextClasses}>{label}</div>
                                <span className={styles.info}/>
                            </div>
                        </>
                    )}
            </div>
        );
    };
};

export default withSectionPanel;