import {getCurrentYear} from '../../../utils';
import {CONTACT_DATA} from '../constants';
import {hubURLs, staticURLs, URLs} from '../../api-urls';
import {terms_and_conditions_es} from '../terms-and-conditions';
import {challenges_es} from '../home-page/challenges';
import {advantages_es} from '../home-page/advantages';
import {about_marketplace_es} from '../about-marketplace';
import {about_independent_technical_surveying_es} from '../about-independent-technical-surveying';
import {about_core_services_es} from '../about-core-services';
import BreadcrumbsConstructor from '../../breadcrumbs';

const es = (session) => ({
    meta_tags: {
        title: 'Exportery',
        description: 'Mercado electrónico verdaderamente industrial',
    },

    home_page: {
        banner_text: ['Mercado electrónico', 'verdaderamente industrial'],
        image_alt: 'Banner image',
        main_title: `Nosotros establece las relaciones de cooperación entre los productores globales 
        de piezas de repuesto OEM y sus usuarios finales tanto en la rama de la aviación como 
        en el sector del transporte terrestre`,
        advantages_content: advantages_es,
        challenges_subtitle: 'Desafíos',
        challenges_text: `Los usuarios finales, los importadores, las organizaciones ocupadas del 
        mantenimiento técnico y reparación, los expotadores de aeronaves, los usuarios finales y 
        exportadores gubernamentales,  incluso los fabricantes OEM, como también las empresas de 
        reparación, enfrentan el "problema" en la cadena de suministros de las piezas de 
        repuesto y de los componentes.`,
        challenges_list: challenges_es,
        video_content: {
            title: ['Welcome to ', 'the new world of ', 'cross-border business'],
            // className: 'normal',
        },
        red_zone_title: 'Enviar un demanda al acceso ahora',
        red_zone_text: `El acceso a la plataforma de comercio electrónico Exportery es GRATUITO para los 
        Exportadores e Importadores. Para apreciar completamente el funcional del sistema y su contenido 
        indique una serie de datos pulsando el botón más abajo.`,
        button: {
            request_access_btn: 'Demanda del acceso',
            play_video: 'Reproduce el video',
            public_marketplace_btn: 'Probar ahora',
        },
    },

    about_marketplace: about_marketplace_es,

    about_independent_technical_surveying: about_independent_technical_surveying_es,

    about_core_services: about_core_services_es,

    news: {
        title: 'Noticias, artículos y análisis de investigación de mercado',
        page: URLs.news,
        no_articles: 'No hay artículos para mostrar',
        image_alt: 'Imagen del artículo',
        button: {
            view: 'Lee mas',
            all_news: 'Ir a noticias',
        },
    },

    section: {
        news: {
            title: 'Noticias, artículos y análisis de investigación de mercado',
            page: URLs.news,
            no_articles: 'No hay artículos para mostrar',
        },
        shop_by_brand: {
            title: 'Fuente de repuestos y componentes diseñados por:',
        },
        no_content: 'No hay artículos para mostrar',
        button: {
            more_btn: 'mostrar más',
            less_btn: 'мuestra menos',
        },
    },

    terms_and_conditions: {
        nav_menu: 'Navegación por capítulos',
        data: terms_and_conditions_es,
    },

    privacy_policy: {
        title: 'Política de privacidad',
        updated: 'Last updated: June 21, 2022',
        text: [
            [`This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of 
                Your information when You use the Service and tells You about Your privacy rights and how the law
                protects You.`],
            [`We use Your Personal data to provide and improve the Service. By using the Service, You agree to the 
                collection and use of information in accordance with this Privacy Policy.`],
            {tag: 'h1', text: 'Interpretation and Definitions'},
            {tag: 'h2', text: 'Interpretation'},
            [`The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear
                in singular or in plural.`],
            {tag: 'h2', text: 'Definitions'},
            [`For the purposes of this Privacy Policy:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Account '},
                        {
                            tag: 'span',
                            text: 'means a unique account created for You to access our Service or parts of our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Business'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), 
                            refers to the Company as the legal entity that collects Consumers' personal information and
                            determines the purposes and means of the processing of Consumers' personal information, or
                            on behalf of which such information is collected and that alone, or jointly with others,
                            determines the purposes and means of the processing of consumers' personal information, that
                            does business in the State of California.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Company '},
                            {
                                tag: 'span',
                                text: `(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ${CONTACT_DATA.address[0]} ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                            },
                        ],
                        ['For the purpose of the GDPR, the Company is the Data Controller.'],
                    ],
                    [
                        {tag: 'strong', text: 'Consumer'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act),
                            means a natural person who is a California resident. A resident, as defined in the law,
                            includes (1) every individual who is in the USA for other than a temporary or transitory
                            purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a
                            temporary or transitory purpose.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Cookies '},
                        {
                            tag: 'span',
                            text: `are small files that are placed on Your computer, mobile device or
                            any other device by a website, containing the details of Your browsing history on that
                            website among its many uses.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Country '},
                        {tag: 'span', text: 'refers to: Georgia.'},
                    ],
                    [
                        {tag: 'strong', text: 'Data Controller'},
                        {
                            tag: 'span',
                            text: `, for the purposes of the GDPR (General Data Protection
                            Regulation), refers to the Company as the legal person which alone or jointly with others
                            determines the purposes and means of the processing of Personal Data.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Device '},
                        {
                            tag: 'span',
                            text: 'means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Do Not Track '},
                        {
                            tag: 'span',
                            text: `(DNT) is a concept that has been promoted by US regulatory
                            authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
                            industry to develop and implement a mechanism for allowing internet users to control the
                            tracking of their online activities across websites.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Personal Data '},
                            {
                                tag: 'span',
                                text: 'is any information that relates to an identified or identifiable individual.',
                            },
                        ],
                        [`For the purposes of GDPR, Personal Data means any information relating to You such as a name,
                            an identification number, location data, online identifier or to one or more factors
                            specific to the physical, physiological, genetic, mental, economic, cultural or social
                            identity.`],
                        [`For the purposes of the CCPA, Personal Data means any information that identifies, relates
                            to, describes or is capable of being associated with, or could reasonably be linked,
                            directly or indirectly, with You.`],
                    ],
                    [
                        {tag: 'strong', text: 'Sale'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), means
                            selling, renting, releasing, disclosing, disseminating, making available, transferring, or
                            otherwise communicating orally, in writing, or by electronic or other means, a Consumer's
                            personal information to another business or a third party for monetary or other valuable
                            consideration.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Service '},
                        {tag: 'span', text: 'refers to the Website.'},
                    ],
                    [
                        {tag: 'strong', text: 'Service Provider '},
                        {
                            tag: 'span',
                            text: `means any natural or legal person who processes the data on
                            behalf of the Company. It refers to third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the Service on behalf of the Company, to
                            perform services related to the Service or to assist the Company in analyzing how the
                            Service is used.
                            For the purpose of the GDPR, Service Providers are considered Data Processors.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Usage Data '},
                        {
                            tag: 'span',
                            text: `refers to data collected automatically, either generated by the
                            use of the Service or from the Service infrastructure itself (for example, the duration of a
                            page visit).`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Website '},
                        {tag: 'span', text: 'refers to Exportery, accessible from '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        [
                            {tag: 'strong', text: 'You '},
                            {
                                tag: 'span',
                                text: `means the individual accessing or using the Service, or the company, or
                                other legal entity on behalf of which such individual is accessing or using the Service, as
                                applicable.`,
                            },
                        ],
                        [`Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject
                            or as the User as you are the individual using the Service.`],
                    ],
                ],
            },
            {tag: 'h1', text: 'Collecting and Using Your Personal Data'},
            {tag: 'h2', text: 'Types of Data Collected'},
            {tag: 'h3', text: 'Personal Data'},
            [`While using Our Service, We may ask You to provide Us with certain personally identifiable
                information that can be used to contact or identify You. Personally identifiable information may
                include, but is not limited to:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Email address.',
                    'First name and last name.',
                    'Phone number.',
                    'Address, State, Province, ZIP/Postal code, City.',
                    'Bank account information in order to pay for products and/or services within the Service.',
                    'Usage Data.',
                ],
            },
            [`When You pay for a product and/or a service via bank transfer, We may ask You to provide information
                to facilitate this transaction and to verify Your identity. Such information may include, without
                limitation:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Date of birth.',
                    'Passport or National ID card.',
                    'Bank card statement.',
                    'Other information linking You to an address.',
                ],
            },
            {tag: 'h3', text: 'Usage Data'},
            ['Usage Data is collected automatically when using the Service.'],
            [`Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your
                visit, the time spent on those pages, unique device identifiers and other diagnostic data.`],
            [`When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.`],
            [`We may also collect information that Your browser sends whenever You visit our Service or when You
                access the Service by or through a mobile device.`],
            {tag: 'h3', text: 'Tracking Technologies and Cookies'},
            [`We use Cookies and similar tracking technologies to track the activity on Our Service and store
                certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
                information and to improve and analyze Our Service. The technologies We use may include:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Cookies or Browser Cookies. '},
                        {
                            tag: 'span',
                            text: `A cookie is a small file placed on Your Device. You
                            can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                            However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                            Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may
                            use Cookies.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Flash Cookies. '},
                        {
                            tag: 'span',
                            text: `Certain features of our Service may use local stored objects (or
                            Flash Cookies) to collect and store information about Your preferences or Your activity on our
                            Service. Flash Cookies are not managed by the same browser settings as those used for Browser
                            Cookies. For more information on how You can delete Flash Cookies, please read "Where can I
                            change the settings for disabling, or deleting local shared objects?" available at `,
                        },
                        {
                            tag: 'a',
                            href: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                            rel: 'external nofollow noopener noreferrer',
                            target: '_blank',
                            text: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        {tag: 'strong', text: 'Web Beacons. '},
                        {
                            tag: 'span',
                            text: `Certain sections of our Service and our emails may contain small
                            electronic files known as web beacons (also referred to as clear gifs, pixel tags, and
                            single-pixel gifs) that permit the Company, for example, to count users who have visited those
                            pages or opened an email and for other related website statistics (for example, recording the
                            popularity of a certain section and verifying system and server integrity).`,
                        },
                    ],
                ],
            },
            [
                {
                    tag: 'span',
                    text: `Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on
                    Your personal computer or mobile device when You go offline, while Session Cookies are deleted as
                    soon as You close Your web browser. Learn more about cookies on the `,
                },
                {
                    tag: 'a',
                    href: 'https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking',
                    rel: 'external nofollow noopener noreferrer',
                    target: '_blank',
                    text: 'Privacy Policies website',
                },
                {tag: 'span', text: ' article.'},
            ],
            ['We use both Session and Persistent Cookies for the purposes set out below:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Necessary / Essential Cookies.'},
                        ['Type: Session Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies are essential to provide You with services available through the
                            Website and to enable You to use some of its features. They help to authenticate users and
                            prevent fraudulent use of user accounts. Without these Cookies, the services that You have
                            asked for cannot be provided, and We only use these Cookies to provide You with those
                            services.`],
                    ],
                    [
                        {tag: 'strong', text: 'Cookies Policy / Notice Acceptance Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        ['Purpose: These Cookies identify if users have accepted the use of cookies on the Website.'],
                    ],
                    [
                        {tag: 'strong', text: 'Functionality Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies allow us to remember choices You make when You use the Website, such
                            as remembering your login details or language preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to avoid You having to re-enter your
                            preferences every time You use the Website.`],
                    ],
                    [
                        {tag: 'strong', text: 'Targeting and Advertising Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Third-Parties.'],
                        [`Purpose: These Cookies track your browsing habits to enable Us to show advertising which is
                            more likely to be of interest to You. These Cookies use information about your browsing
                            history to group You with other users who have similar interests. Based on that information,
                            and with Our permission, third party advertisers can place Cookies to enable them to show
                            adverts which We think will be relevant to your interests while You are on third party
                            websites.`],
                    ],
                ],
            },
            [`For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.`],
            {tag: 'h2', text: 'Use of Your Personal Data'},
            ['The Company may use Personal Data for the following purposes:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'To provide and maintain our Service'},
                        {tag: 'span', text: ', including to monitor the usage of our Service.'},
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your Account: '},
                        {
                            tag: 'span',
                            text: `to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You access to different functionalities of
                            the Service that are available to You as a registered user.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For the performance of a contract: '},
                        {
                            tag: 'span',
                            text: `the development, compliance and
                            undertaking of the purchase contract for the products, items or services You have purchased
                            or of any other contract with Us through the Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To contact You. '},
                        {
                            tag: 'span',
                            text: `To contact You by email, telephone calls, SMS, or other
                            equivalent forms of electronic communication, such as a mobile application's push
                            notifications regarding updates or informative communications related to the
                            functionalities, products or contracted services, including the security updates, when
                            necessary or reasonable for their implementation.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To provide You '},
                        {
                            tag: 'span',
                            text: `with news, special offers and general information about other
                            goods, services and events which we offer that are similar to those that you have already
                            purchased or enquired about unless You have opted not to receive such information.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your requests. '},
                        {
                            tag: 'span',
                            text: 'To attend and manage Your requests to Us.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer
                            of some or all of Our assets, whether as a going concern or as part of bankruptcy,
                            liquidation, or similar proceeding, in which Personal Data held by Us about our Service
                            users is among the assets transferred.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For other purposes. '},
                        {
                            tag: 'span',
                            text: `We may use Your information for other purposes, such as
                            data analysis, identifying usage trends, determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service, products, services, marketing and your
                            experience.`,
                        },
                    ],
                ],
            },
            ['We may share Your personal information in the following situations:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'With Service Providers. '},
                        {
                            tag: 'span',
                            text: `We may share Your personal information with Service
                            Providers to monitor and analyze the use of our Service, to show advertisements to You to help
                            support and maintain Our Service, for payment processing, to contact You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may share or transfer Your personal information in
                            connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                            acquisition of all or a portion of Our business to another company.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Affiliates. '},
                        {
                            tag: 'span',
                            text: `We may share Your information with Our affiliates, in which
                            case we will require those affiliates to honor this Privacy Policy. Affiliates include Our
                            parent company and any other subsidiaries, joint venture partners or other companies that We
                            control or that are under common control with Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With business partners. '},
                        {
                            tag: 'span',
                            text: 'We may share Your information with Our business partners to offer You certain products, services or promotions.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With other users: '},
                        {
                            tag: 'span',
                            text: `when You share personal information or otherwise interact in
                            the public areas with other users, such information may be viewed by all users and may be
                            publicly distributed outside.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Your consent. '},
                        {
                            tag: 'span',
                            text: 'We may disclose Your personal information for any other purpose with Your consent.',
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Retention of Your Personal Data'},
            [`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out
                in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply
                with our legal obligations (for example, if we are required to retain your data to comply with
                applicable laws), resolve disputes, and enforce our legal agreements and policies.`],
            [`The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
                retained for a shorter period of time, except when this data is used to strengthen the security or
                to improve the functionality of Our Service, or We are legally obligated to retain this data for
                longer time periods.`],
            {tag: 'h2', text: 'Transfer of Your Personal Data'},
            [`Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this
                information may be transferred to — and maintained on — computers located outside of Your state,
                province, country or other governmental jurisdiction where the data protection laws may differ than
                those from Your jurisdiction.`],
            [`Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.`],
            [`The Company will take all steps reasonably necessary to ensure that Your data is treated securely and
                in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other personal information.`],
            {tag: 'h2', text: 'Disclosure of Your Personal Data'},
            {tag: 'h3', text: 'Business Transactions'},
            [`If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                transferred. We will provide notice before Your Personal Data is transferred and becomes subject to
                a different Privacy Policy.`],
            {tag: 'h3', text: 'Law enforcement'},
            [`Under certain circumstances, the Company may be required to disclose Your Personal Data if required
                to do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).`],
            {tag: 'h3', text: 'Other legal requirements'},
            ['The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Comply with a legal obligation.',
                    'Protect and defend the rights or property of the Company.',
                    'Prevent or investigate possible wrongdoing in connection with the Service.',
                    'Protect the personal safety of Users of the Service or the public.',
                    'Protect against legal liability.',
                ],
            },
            {tag: 'h2', text: 'Security of Your Personal Data'},
            [`The security of Your Personal Data is important to Us, but remember that no method of transmission
                over the Internet, or method of electronic storage is 100% secure. While We strive to use
                commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute
                security.`],
            {tag: 'h1', text: 'Detailed Information on the Processing of Your Personal Data'},
            [`The Service Providers We use may have access to Your Personal Data. These third-party vendors
                collect, store, use, process and transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.`],
            {tag: 'h2', text: 'Advertising'},
            ['We may use Service Providers to show advertisements to You to help support and maintain Our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Google AdSense & DoubleClick Cookie.'},
                        [`Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of
                            the DoubleClick cookie enables it and its partners to serve ads to our users based on their
                            visit to our Service or other websites on the Internet.`],
                        [
                            {
                                tag: 'span',
                                text: `You may opt out of the use of the DoubleClick Cookie for interest-based advertising by
                                visiting the Google Ads Settings web page: `,
                            },
                            {
                                tag: 'a',
                                href: 'http://www.google.com/ads/preferences/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'http://www.google.com/ads/preferences/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                    [
                        {tag: 'strong', text: 'AdMob by Google.'},
                        ['AdMob by Google is provided by Google Inc.'],
                        [
                            {
                                tag: 'span',
                                text: 'You can opt-out from the AdMob by Google service by following the instructions described by Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://support.google.com/ads/answer/2662922?hl=en',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://support.google.com/ads/answer/2662922?hl=en',
                            },
                            {tag: 'span', text: '.'},
                        ],
                        [
                            [`For more information on how Google uses the collected information, please visit the "How
                                Google uses data when you use our partners' sites or app" page: `],
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/technologies/partner-sites',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/technologies/partner-sites',
                            },
                            {tag: 'span', text: ' or visit the Privacy Policy of Google: '},
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/privacy',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/privacy',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Email Marketing'},
            [`We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We
                send or by contacting Us.`],
            ['We may use Email Marketing Service Providers to manage and send emails to You.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Postmark'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://postmarkapp.com/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://postmarkapp.com/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Payments'},
            [`We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing (e.g. payment processors).`],
            [`We will not store or collect Your payment card details. That information is provided directly to Our
                third-party payment processors whose use of Your personal information is governed by their Privacy
                Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
                Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American
                Express and Discover. PCI-DSS requirements help ensure the secure handling of payment
                information.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Bilderlings'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://bilderlings.com',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://bilderlings.com',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            [`When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide
                information to facilitate this transaction and to verify Your identity.`],
            {tag: 'h2', text: 'Usage, Performance and Miscellaneous'},
            ['We may use third-party Service Providers to provide better improvement of our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Invisible reCAPTCHA'},
                        ['We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.'],
                        ['The reCAPTCHA service may collect information from You and from Your Device for security purposes.'],
                        [
                            {
                                tag: 'span',
                                text: 'The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://www.google.com/intl/en/policies/privacy/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://www.google.com/intl/en/policies/privacy/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h1', text: 'GDPR Privacy'},
            {tag: 'h2', text: 'We may process Personal Data under the following conditions:'},
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Consent. '},
                        {
                            tag: 'span',
                            text: 'You have given Your consent for processing Personal Data for one or more specific purposes.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Performance of a contract. '},
                        {
                            tag: 'span',
                            text: 'Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legal obligations. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Vital interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Public interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legitimate interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.',
                        },
                    ],
                ],
            },
            [`In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.`],
            {tag: 'h2', text: 'Your Rights under the GDPR'},
            ['The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.'],
            ['You have the right under this Privacy Policy, and by law if You are within the EU, to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Request access to Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `The right to access, update or delete the
                            information We have on You. Whenever made possible, you can access, update or request deletion
                            of Your Personal Data directly within Your account settings section. If you are unable to
                            perform these actions yourself, please contact Us to assist You. This also enables You to
                            receive a copy of the Personal Data We hold about You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request correction of the Personal Data that We hold about You. '},
                        {
                            tag: 'span',
                            text: 'You have the right to have any incomplete or inaccurate information We hold about You corrected.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Object to processing of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `This right exists where We are
                            relying on a legitimate interest as the legal basis for Our processing and there is something
                            about Your particular situation, which makes You want to object to our processing of Your
                            Personal Data on this ground. You also have the right to object where We are processing Your
                            Personal Data for direct marketing purposes.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request erasure of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: 'You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request the transfer of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `We will provide to You, or to a
                            third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable
                            format. Please note that this right only applies to automated information which You initially
                            provided consent for Us to use or where We used the information to perform a contract with You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Withdraw Your consent. '},
                        {
                            tag: 'span',
                            text: `You have the right to withdraw Your consent on using
                            your Personal Data. If You withdraw Your consent, We may not be able to provide You with access
                            to certain specific functionalities of the Service.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising of Your GDPR Data Protection Rights'},
            [`You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                Please note that we may ask You to verify Your identity before responding to such requests. If You
                make a request, We will try our best to respond to You as soon as possible.`],
            [`You have the right to complain to a Data Protection Authority about Our collection and use of Your
                Personal Data. For more information, if You are in the European Economic Area (EEA), please contact
                Your local data protection authority in the EEA.`],
            {tag: 'h1', text: 'CCPA Privacy'},
            [`This privacy notice section for California residents supplements the information contained in Our
                Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of
                California.`],
            {tag: 'h2', text: 'Categories of Personal Information Collected'},
            [`We collect information that identifies, relates to, describes, references, is capable of being
                associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer
                or Device. The following is a list of categories of personal information which we may collect or may
                have been collected from California residents within the last twelve (12) months.`],
            [`Please note that the categories and examples provided in the list below are those defined in the
                CCPA. This does not mean that all examples of that category of personal information were in fact
                collected by Us, but reflects our good faith belief to the best of our knowledge that some of that
                information from the applicable category may be and may have been collected. For example, certain
                categories of personal information would only be collected if You provided such personal information
                directly to Us.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Category A: Identifiers.'},
                        [`Examples: A real name, alias, postal address, unique personal identifier, online identifier,
                        Internet Protocol address, email address, account name, driver's license number, passport
                        number, or other similar identifiers.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                        },
                        [`Examples: A name, signature, Social Security number, physical characteristics or description,
                        address, telephone number, passport number, driver's license or state identification card
                        number, insurance policy number, education, employment, employment history, bank account
                        number, credit card number, debit card number, or any other financial information, medical
                        information, or health insurance information. Some personal information included in this
                        category may overlap with other categories.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category C: Protected classification characteristics under California or federal law.',
                        },
                        [`Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship,
                        religion or creed, marital status, medical condition, physical or mental disability, sex
                        (including gender, gender identity, gender expression, pregnancy or childbirth and related
                        medical conditions), sexual orientation, veteran or military status, genetic information
                        (including familial genetic information).`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category D: Commercial information.',
                        },
                        ['Examples: Records and history of products or services purchased or considered.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category E: Biometric information.',
                        },
                        [`Examples: Genetic, physiological, behavioral, and biological characteristics, or activity
                        patterns used to extract a template or other identifier or identifying information, such as,
                        fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other
                        physical patterns, and sleep, health, or exercise data.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category F: Internet or other similar network activity.',
                        },
                        ['Examples: Interaction with our Service or advertisement.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category G: Geolocation data.',
                        },
                        ['Examples: Approximate physical location.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category H: Sensory data.',
                        },
                        ['Examples: Audio, electronic, visual, thermal, olfactory, or similar information.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category I: Professional or employment-related information.',
                        },
                        ['Examples: Current or past job history or performance evaluations.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).',
                        },
                        [`Examples: Education records directly related to a student maintained by an educational
                        institution or party acting on its behalf, such as grades, transcripts, class lists, student
                        schedules, student identification codes, student financial information, or student
                        disciplinary records.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category K: Inferences drawn from other personal information.',
                        },
                        [`Examples: Profile reflecting a person's preferences, characteristics, psychological trends,
                        predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.`],
                        ['Collected: No.'],
                    ],
                ],
            },
            ['Under CCPA, personal information does not include:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Publicly available information from government records.',
                    'Deidentified or aggregated consumer information.',
                    [
                        {tag: 'span', text: 'Information excluded from the CCPA\'s scope, such as:'},
                        {
                            tag: 'ul',
                            className: 'second-nesting',
                            items: [
                                `Health or medical information covered by the Health Insurance Portability and
                                Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                                Information Act (CMIA) or clinical trial data.`,
                                `Personal Information covered by certain sector-specific privacy laws, including the Fair
                                Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial
                                Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.`,
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Sources of Personal Information'},
            ['We obtain the categories of personal information listed above from the following categories of sources:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Directly from You. '},
                        {
                            tag: 'span',
                            text: `For example, from the forms You complete on our Service,
                            preferences You express or provide through our Service, or from Your purchases on our Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Indirectly from You. '},
                        {
                            tag: 'span',
                            text: 'For example, from observing Your activity on our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Automatically from You. '},
                        {
                            tag: 'span',
                            text: 'For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'From Service Providers. '},
                        {
                            tag: 'span',
                            text: `For example, third-party vendors to provide advertising
                            on our Service, third-party vendors for payment processing, or other third-party vendors that We
                            use to provide the Service to You.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Use of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose personal information We collect for "business
            purposes" or "commercial purposes" (as defined under the CCPA), which may include the
            following examples:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'To operate our Service and provide You with our Service.',
                    `To provide You with support and to respond to Your inquiries, including to investigate and
                    address Your concerns and monitor and improve our Service.`,
                    `To fulfill or meet the reason You provided the information. For example, if You share Your
                    contact information to ask a question about our Service, We will use that personal information
                    to respond to Your inquiry. If You provide Your personal information to purchase a product or
                    service, We will use that information to process Your payment and facilitate delivery.`,
                    `To respond to law enforcement requests and as required by applicable law, court order, or
                    governmental regulations.`,
                    `As described to You when collecting Your personal information or as otherwise set forth in the
                    CCPA.`,
                    'For internal administrative and auditing purposes.',
                    `To detect security incidents and protect against malicious, deceptive, fraudulent or illegal
                    activity, including, when necessary, to prosecute those responsible for such activities.`,
                ],
            },
            [`Please note that the examples provided above are illustrative and not intended to be exhaustive. For
            more details on how we use this information, please refer to the "Use of Your Personal
            Data" section.`],
            [`If We decide to collect additional categories of personal information or use the personal information
            We collected for materially different, unrelated, or incompatible purposes We will update this
            Privacy Policy.`],
            {tag: 'h2', text: 'Disclosure of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose and may have used or disclosed in the last twelve (12) months the following
            categories of personal information for business or commercial purposes:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    `Category B: Personal information categories listed in the California Customer Records statute
                    (Cal. Civ. Code § 1798.80(e)).`,
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            [`Please note that the categories listed above are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact disclosed, but reflects our good
            faith belief to the best of our knowledge that some of that information from the applicable category
            may be and may have been disclosed.`],
            [`When We disclose personal information for a business purpose or a commercial purpose, We enter a
            contract that describes the purpose and requires the recipient to both keep that personal
            information confidential and not use it for any purpose except performing the contract.`],
            {tag: 'h2', text: 'Sale of Personal Information'},
            [`As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing,
            disclosing, disseminating, making available, transferring, or otherwise communicating orally, in
            writing, or by electronic or other means, a consumer's personal information by the business to a
            third party for valuable consideration. This means that We may have received some kind of benefit in
            return for sharing personal information, but not necessarily a monetary benefit.`],
            [`Please note that the categories listed below are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact sold, but reflects our good faith
            belief to the best of our knowledge that some of that information from the applicable category may
            be and may have been shared for value in return.`],
            ['We may sell and may have sold in the last twelve (12) months the following categories of personal information:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            {tag: 'h2', text: 'Share of Personal Information'},
            ['We may share Your personal information identified in the above categories with the following categories of third parties:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Service Providers.',
                    'Payment processors.',
                    'Our affiliates.',
                    'Our business partners.',
                    'Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You.',
                ],
            },
            {tag: 'h2', text: 'Sale of Personal Information of Minors Under 16 Years of Age'},
            [`We do not knowingly collect personal information from minors under the age of 16 through our Service,
                although certain third party websites that we link to may do so. These third-party websites have
                their own terms of use and privacy policies and we encourage parents and legal guardians to monitor
                their children's Internet usage and instruct their children to never provide information on other
                websites without their permission.`],
            [`We do not sell the personal information of Consumers We actually know are less than 16 years of age,
                unless We receive affirmative authorization (the "right to opt-in") from either the
                Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13
                years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales
                at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a
                request to Us by contacting Us.`],
            [`If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal
                information, please contact Us with sufficient detail to enable Us to delete that information.`],
            {tag: 'h2', text: 'Your Rights under the CCPA'},
            [`The CCPA provides California residents with specific rights regarding their personal information. If
                You are a resident of California, You have the following rights:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'The right to notice. '},
                        {
                            tag: 'span',
                            text: `You have the right to be notified which categories of
                            Personal Data are being collected and the purposes for which the Personal Data is being used.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to request. '},
                        {
                            tag: 'span',
                            text: `Under CCPA, You have the right to request that We
                            disclose information to You about Our collection, use, sale, disclosure for business purposes
                            and share of personal information. Once We receive and confirm Your request, We will disclose to
                            You:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'The categories of personal information We collected about You.',
                                'The categories of sources for the personal information We collected about You.',
                                'Our business or commercial purpose for collecting or selling that personal information.',
                                'The categories of third parties with whom We share that personal information.',
                                'The specific pieces of personal information We collected about You.',
                                [
                                    {
                                        tag: 'span',
                                        text: 'If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:',
                                    },
                                    {
                                        tag: 'ul', className: 'third-nesting', items: [
                                            'The categories of personal information categories sold.',
                                            'The categories of personal information categories disclosed.',
                                        ],
                                    },
                                ],
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to say no to the sale of Personal Data (opt-out). '},
                        {
                            tag: 'span',
                            text: `You have the right to direct Us to not sell Your personal information. 
                            To submit an opt-out request please contact Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to delete Personal Data. '},
                        {
                            tag: 'span',
                            text: `You have the right to request the deletion
                            of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request,
                            We will delete (and direct Our Service Providers to delete) Your personal information from our
                            records, unless an exception applies. We may deny Your deletion request if retaining the
                            information is necessary for Us or Our Service Providers to:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                `Complete the transaction for which We collected the personal information, provide a good
                                or service that You requested, take actions reasonably anticipated within the context of
                                our ongoing business relationship with You, or otherwise perform our contract with You.`,
                                `Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
                                activity, or prosecute those responsible for such activities.`,
                                `Debug products to identify and repair errors that impair existing intended
                                functionality.`,
                                `Exercise free speech, ensure the right of another consumer to exercise their free speech
                                rights, or exercise another right provided for by law.`,
                                `Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546
                                et. seq.).`,
                                `Engage in public or peer-reviewed scientific, historical, or statistical research in the
                                public interest that adheres to all other applicable ethics and privacy laws, when the
                                information's deletion may likely render impossible or seriously impair the research's
                                achievement, if You previously provided informed consent.`,
                                `Enable solely internal uses that are reasonably aligned with consumer expectations based
                                on Your relationship with Us.`,
                                'Comply with a legal obligation.',
                                `Make other internal and lawful uses of that information that are compatible with the
                                context in which You provided it.`,
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right not to be discriminated against. '},
                        {
                            tag: 'span',
                            text: `You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'Denying goods or services to You.',
                                `Charging different prices or rates for goods or services, including the use of discounts
                                or other benefits or imposing penalties.`,
                                'Providing a different level or quality of goods or services to You.',
                                'Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.',
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising Your CCPA Data Protection Rights'},
            ['In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
            [`Only You, or a person registered with the California Secretary of State that You authorize to act on
                Your behalf, may make a verifiable request related to Your personal information.`],
            ['Your request to Us must:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `Provide sufficient information that allows Us to reasonably verify You are the person about whom
                    We collected personal information or an authorized representative.`,
                    `Describe Your request with sufficient detail that allows Us to properly understand, evaluate,
                    and respond to it.`,
                ],
            },
            ['We cannot respond to Your request or provide You with the required information if we cannot:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Verify Your identity or authority to make the request.',
                    'And confirm that the personal information relates to You.',
                ],
            },
            [`We will disclose and deliver the required information free of charge within 45 days of receiving Your
                verifiable request. The time period to provide the required information may be extended once by an
                additional 45 days when reasonably necessary and with prior notice.`],
            [`Any disclosures We provide will only cover the 12-month period preceding the verifiable request's
                receipt.`],
            [`For data portability requests, We will select a format to provide Your personal information that is
                readily usable and should allow You to transmit the information from one entity to another entity
                without hindrance.`],
            {tag: 'h2', text: 'Do Not Sell My Personal Information'},
            [`You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a
                verifiable consumer request from You, we will stop selling Your personal information. To exercise
                Your right to opt-out, please contact Us.`],
            {
                tag: 'h1',
                text: '"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)',
            },
            ['Our Service does not respond to Do Not Track signals.'],
            [`However, some third party websites do keep track of Your browsing activities. If You are visiting
                such websites, You can set Your preferences in Your web browser to inform websites that You do not
                want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of
                Your web browser.`],
            {tag: 'h1', text: 'Children\'s Privacy'},
            [`Our Service does not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from anyone under the age of 13. If You are a parent or guardian and You
                are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware
                that We have collected Personal Data from anyone under the age of 13 without verification of
                parental consent, We take steps to remove that information from Our servers.`],
            [`If We need to rely on consent as a legal basis for processing Your information and Your country
                requires consent from a parent, We may require Your parent's consent before We collect and use that
                information.`],
            {tag: 'h1', text: 'Your California Privacy Rights (California\'s Shine the Light law)'},
            [`Under California Civil Code Section 1798 (California's Shine the Light law), California residents
                with an established business relationship with us can request information once a year about sharing
                their Personal Data with third parties for the third parties' direct marketing purposes.`],
            [`If you'd like to request more information under the California Shine the Light law, and if You are a
                California resident, You can contact Us using the contact information provided below.`],
            {
                tag: 'h1',
                text: 'California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)',
            },
            [`California Business and Professions Code Section 22581 allows California residents under the age of
                18 who are registered users of online sites, services or applications to request and obtain removal
                of content or information they have publicly posted.`],
            [`To request removal of such data, and if You are a California resident, You can contact Us using the
                contact information provided below, and include the email address associated with Your account.`],
            [`Be aware that Your request does not guarantee complete or comprehensive removal of content or
                information posted online and that the law may not permit or require removal in certain
                circumstances.`],
            {tag: 'h1', text: 'Links to Other Websites'},
            [`Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit.`],
            [`We have no control over and assume no responsibility for the content, privacy policies or practices
                of any third party sites or services.`],
            {tag: 'h1', text: 'Changes to this Privacy Policy'},
            [`We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the
                new Privacy Policy on this page.`],
            [`We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the "Last updated" date at the top of this Privacy Policy.`],
            [`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.`],
            {tag: 'h1', text: 'Contact Us'},
            ['If you have any questions about this Privacy Policy, You can contact us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
        ],
    },

    invitation: {
        subtitle: 'Por favor, rellene el siguiente formulario',
        user_section_title: 'Información sobre el usuario',
        organization_section_title: 'Información sobre la empresa',
        button: {
            cancel: 'Сancelar',
            confirm: 'Сonfirmar',
        },
    },

    password_requirements: {
        text: 'La contraseña debe:',
        list: [
            'tener entre 8 y 255 caracteres.',
            'contener solo letras latinas.',
            'incluir al menos una letra mayúscula.',
            // 'incluir al menos un número.',
            // 'incluir al menos un símbolo especial (! ? @ # $ % & [ ]).',
        ],
    },

    authorization: {
        request_access_text: 'Regístrese o proporcione sus datos de contacto a continuación para saber cómo puede acceder a la plataforma',

        sign_in: {
            welcome_title: 'Bienvenido a Exportery',
            description: [
                'Mercado electrónico verdaderamente industrial',
                'Inspección técnica independiente',
                'Soluciones de Trade Finance impulsadas por blockchain',
            ],
            title: 'Registrarse',
            two_factor_title: 'Autenticación de dos factores',
            reset_password_title: 'Restablecer la contraseña',
            email_placeholder: 'Nombre de usuario',
            password_placeholder: 'Clave',
            two_factor_code_placeholder: 'Código',
            request_access_question: '¿No tienes una cuenta?',
            required_field_error_message: 'Campo requerido',
            two_factor_text: 'Por favor, introduzca el código que se ha enviado a su correo electrónico',
            forgot_password_text: 'Por favor, ingrese su correo electrónico y le enviaremos un vínculo',
            security_code: {
                text: 'Please, enter code from your security token',
                placeholder: 'Security code',
                required_field_error_message: 'Campo requerido',
            },
            reset_password: {
                subtitle: 'Por favor, rellene el siguiente formulario',
                button: {
                    cancel: 'Сancelar',
                    confirm: 'Сonfirmar',
                },
            },
            button: {
                request_access: 'Solicitar acceso',
                sign_in: 'Registrarse',
                confirm: 'Сonfirmar',
                reset: 'Resetear',
            },
        },

        sign_up: {
            welcome_title: 'Bienvenido a Exportery',
            description: [
                'Utilice el siguiente formulario de registro para acceder al mercado electrónico industrial en modo de vista. Le otorgaremos acceso completo a su organización después de la realización del proceso de validación guiado por nuestro equipo de servicio al cliente.',
                'Esperamos que disfrute de la simplicidad, la transparencia y el sistema de gestión de documentos patentado de nuestra plataforma que lleva la adquisición internacional de bienes y servicios industriales a un nuevo nivel de automatización.',
            ],
            title: 'Inscribirse',
            first_name_placeholder: 'Nombre',
            last_name_placeholder: 'Apellido',
            email_placeholder: 'E-mail',
            organization_name_placeholder: 'Organización',
            country_placeholder: 'País',
            telephone_placeholder: 'Teléfono',
            email_field_error_message: 'El correo electrónico no es correcto',
            required_field_error_message: 'Campo requerido',
            tick_the_areas_label: 'Marque las áreas que le interesan:',
            role: ['Mi papel:', 'Importador(a)', 'Exportador(a)'],
            interest_status: ['Membresía del mercado electrónico', 'Técnica independiente', 'Servicios comerciales'],
            privacy_policy_text: ['Al hacer clic en esta casilla y hacer clic en "Enviar", acepto que el procesamiento se lleve a cabo de acuerdo con este formulario y la ', 'política de privacidad', '.'],
            button: {
                submit: 'Enviar',
                request_access_btn: 'Solicitar acceso',
            },
        },
    },

    marketplace: {
        home: {
            all_products_title: 'todos los productos',
        },

        category_page: {
            empty_category_message: 'Estamos trabajando activamente en la creación de la base!',
        },

        product_card: {
            available_status_sold: {value: 'sold', label: 'vendido'},
            button: {
                view_product: 'ver el producto',
            },
        },

        product_panel: {
            header: {
                item_label: 'Producto',
                condition_label: 'Expresar',
                price_label: 'Precio',
            },
            delivery_time_label: 'El tiempo de entrega',
            exporter_label: 'Exportador',
            supply_conditions_label: 'Сondiciones de suministro',
            button: {
                view_product: 'ver el producto',
            },
        },

        product_page: {
            image_alt: 'Product image',
            currency_array: ['USD', 'EUR', 'RUB', 'AED', 'CNY'],
            price_label: '/ La pieza',
            offers_from_text: 'desde',
            message_about_not_available_currency: 'Por favor seleccione otra moneda',
            main_technical_data_label: ['Nombre del parámetro', 'Valor'],
            details_accordion_label: 'Detalles',
            interchangeability_text: 'es totalmente intercambiable con:',
            certification_title: 'Certificados',
            attachment_accordion_label: 'Guías de productos y documentos',
            available_currency_table_labels: ['Divisa', 'Tipo de cambio usd'],
            button: {
                add_to_quotation: 'Añadir a la cotización',
                added_to_quotation: 'Agregado a la aplicación',
                edit_product: 'Editar',
            },
        },

        quotation: {
            other_offers_title: 'Otras ofertas',
            excel_upload_form: {
                excel_upload_form_text: 'Cargue el archivo de Excel y complete el formulario para obtener la cotización instantánea',
                follow_format_tip: ['Para subir Excel', 'siga el formato'],
                attach_xls_label: 'Adjuntar xls',
                button: {
                    cancel: 'Сancelar',
                    upload: 'Subir',
                    confirm_quotation: 'Confirmar cotización',
                },
            },

            request_for_supply: {
                title: 'Solicitud de suministro',
                find_other_offers_text: 'Encontrar otras ofertas',
                price_label: '/ La pieza',
            },

            other_offers_block: {
                other_offers_title: 'Otras ofertas',
                replace_message: 'Estás seguro de que quieres reemplazar el artículo?',
                offer: {
                    condition_label: 'Expresar',
                    delivery_time_label: 'El tiempo de entrega',
                    delivery_time_value: '60 días',
                    exporter_label: 'Exportador',
                    supply_conditions_label: 'Сondiciones de suministro',
                },
                button: {
                    replace: 'Reemplazar',
                },
            },

            order_summary_block: {
                title: 'Resumen del pedido',
                button: {
                    confirm_quotation: 'confirmar cotización',
                },
            },

            empty_request_list: {
                text: 'Todavía no hay ningún artículo',
            },

            product_image_alt: 'Product image',

            button: {
                add: 'Agregar producto',
                upload: 'Subir xls',
                delete_btn: 'Eliminar',
                clear_all: 'Limpiar todo',
            },
        },

        search_page: {
            title: 'Resultados de la búsqueda',
        },
    },

    activity_hub: {
        pages: ['Solicitudes de cotizaciones', 'Contratos electrónicos'],
        requests_for_quotations: {
            title: 'Centro de actividades',
            rfq_price_tooltip: 'Tenga en cuenta que la RFQ cotizada contiene una o más Sub-RFQ denominadas en diferentes monedas distintas al dólar estadounidense. El tipo de cambio se fija dentro del plazo de validez dado de la cotización.',
            empty_text: 'Aún no hay una solicitud de cotización',
            table_labels: ['№', 'Escribe', 'Fecha', 'Validez hasta', 'Días restantes', 'Total', 'Estado'],
            table_row_type_column: {label: 'Escribe', value: 'Suministro'},
            status_row_label: 'Estado',
            create_contract_question: 'Estás seguro de que quieres crear un contrato?',
        },
        e_contracts: {
            title: 'Centro de actividades',
            empty_text: 'Todavía no hay contratos electrónicos',
            table_labels_exporter: ['№', 'Escribe', 'Fecha', 'Importador', 'Validez hasta', 'Total', 'Términos de pago', 'Estado'],
            table_labels_importer: ['№', 'Escribe', 'Fecha', 'Exportador', 'Validez hasta', 'Total', 'Términos de pago', 'Estado'],
            status_row_label: 'Estado',
            request_question: '¿Realmente quieres hacer esto?',
            pages: {
                contract_for_supply: {
                    title: 'Contrato de suministro',
                    status_label: 'Estado',
                    total_label: 'Total',
                    contract_information_label: 'Información del contrato',
                    information_table_labels: ['Documento', 'Fecha', 'Estado', 'Antes de expirar, días', 'Acción'],
                    table_labels: ['Documento', 'Fecha', 'Estado', 'Acción'],
                    condition_page: {
                        condition_table_labels: {name: 'Título', value: 'Información'},
                        table_labels: {
                            index_number: '№',
                            part_number: 'Numero de parte',
                            name: 'Descripción',
                            quantity: 'Cant.',
                            doq: 'P-ty',
                            price: 'Precio,',
                            total: 'Suma,',
                        },
                        total_label: 'Total',
                        button: {
                            cancel: 'Cancelar',
                            review: 'Avance',
                            open_dispute: 'Disputa abierta',
                        },
                    },
                },
                origin_invoice: {
                    title: 'Constructor de factura de envío',
                    message: 'Seleccione los artículos y la cantidad que desea enviar',
                    table_labels: ['№', 'Numero de parte', 'Descripción', 'Cantidad', 'Precio', 'Suma'],
                    total_label: 'Total',
                    button: {
                        save: 'Ahorrar',
                        cancel: 'Cancelar',
                    },
                },
                packing_list_wizard: {
                    title: 'Constructor de lista de empaque',
                    delete_place_question: 'Estás seguro de eliminar el lugar?',
                    pages: {
                        shipment_lot: {
                            place_label: 'Crear lugar',
                            packed_status: 'Lleno',
                            empty_message: 'Todavía no hay ningún producto desempaquetado',
                            select_data: {
                                label: 'Seleccionar método',
                                default_item_label: '- Seleccione -',
                                tooltip: '',
                            },
                            button: {
                                add_serial_number: 'Añadir s/n',
                                remove_serial_number: 'Eliminar s/n',
                                add_place: 'Añadir lugar',
                                remove_place: 'Eliminar lugar',
                                save: 'Ahorrar',
                                cancel: 'Cancelar',
                            },
                        },
                        packing_list: {
                            table_labels: ['№ Lugar', ['Descripción', 'Número de serie', 'Cant.', 'P-ty', 'Peso neto por unidad, kg', 'Peso bruto por unidad, kg'], 'Características del lugar'],
                            empty_message: 'Aún no hay producto envasado',
                            unpack_question: 'Estás seguro de eliminar el lugar?',
                            total_places_label: 'Plazas totales',
                            total_net_weight_label: 'Peso neto total',
                            total_gross_weight_label: 'Peso bruto total',
                            confirm_question: 'Está seguro de que desea crear una lista de empaque?',
                            warning_message: 'Empaque todos los artículos en su lote para continuar',
                            unpacking_tooltip: 'Desempacar',
                            button: {
                                add_place: 'Añadir lugar',
                                remove_place: 'Eliminar lugar',
                                save: 'Ahorrar',
                                cancel: 'Cancelar',
                                create_packing_list: 'Crear lista de empaque',
                            },
                        },
                        labelling: {
                            title: 'Etiquetado',
                            checkbox_label: 'Seleccionar todas las etiquetas',
                            switcher_label: 'Imprimir con línea de corte',
                            row_labels: {
                                carrier: 'Transportador',
                                consignee: 'Consignatario',
                                content: 'Contenido',
                                contract: 'Сontrato',
                                destination: 'Destino',
                                dims: 'DIMS, cm',
                                gross_weight: 'Peso bruto del lugar',
                                net_weight: 'Coloque el peso neto',
                                packing_date: 'Fecha de empaque',
                                shipper: 'Expedidor',
                                waybill: 'Viaje no',
                            },
                        },
                    },
                },
                upload_document: {
                    title: 'Carga de documentos',
                    text: 'Cargue el documento en formato PDF:',
                    button: {
                        cancel: 'Cancelar',
                        create: 'Crear',
                        btn_text: 'o',
                    },
                },
            },
        },
        place_product: {
            warranty_title: 'Garantía',
            from_excel: {
                text: 'Sube un archivo de Excel para colocar tus productos:',
                button: {
                    cancel: 'Cancelar',
                    confirm: 'Ir a mis productos',
                },
            },
            from_form: {
                title: 'Colocar producto',
                edit_title: 'Editar producto',
                sections: {
                    product_information: {
                        label: 'Información del Producto',
                        sub_tooltip: [
                            null, null, null, null,
                            [
                                {
                                    title: 'Nuevo',
                                    text: 'El producto es nuevo, años recientes de fabricación, empacado en la cobertura del fabricante original (si el productor previó dicha cobertura), tiene toda la documentación que lo acompaña, p. pasaportes originales, etiquetas, bitácoras. No se aceptan los duplicados de los documentos. El producto no tiene signos de desmontaje e instalación en el objeto. Los sellos del fabricante deben permanecer intactos. El tiempo de almacenamiento asignado debe ser válido por al menos dos meses. La garantía del fabricante es completa y se especifica en la documentación.',
                                },
                                {
                                    title: 'Revisado',
                                    text: 'Revisado – el producto revisado por la planta o talleres de reparación autorizados de OH, embalado en la cobertura que garantiza su transporte seguro por cualquier medio de transporte, ha tenido toda la documentación que lo acompaña, por ejemplo, pasaportes originales, etiquetas, libros de registro. Los duplicados de los documentos son aceptados si los emite una organización OH autorizada. El producto puede tener signos de desmontaje e instalación en el objeto, pero debe contener sellos de la organización OH. El tiempo de almacenamiento asignado debe ser válido por al menos dos meses. La garantía de OH es completa y está debidamente especificada en la documentación.',
                                },
                                {
                                    title: 'Usado',
                                    text: 'Usado - el producto está usado, pero debe tener todos los signos de integridad estructural, embalado en la cobertura que garantice su transporte seguro por cualquier medio de transporte, tiene toda la documentación que lo acompaña, por ejemplo, pasaportes originales, etiquetas, libros de registro.',
                                },
                                {
                                    title: 'Desde almacenamiento',
                                    text: `“Desde almacenamiento – El producto cuenta con todas las características especificadas en la opción Nuevo pero ha vencido el tiempo de almacenamiento asignado y/o la garantía del fabricante. Ejemplo: Hyrohorizon AGB-3K fabricado en 1990 almacenamiento según todas las condiciones técnicas, en la cobertura del fabricante original.`,
                                },
                            ],
                            {
                                title: 'Planta de reacondicionamiento',
                                text: 'Elija entre los nombres preseleccionados o ingrese otros nuevos.',
                            },
                            {
                                title: 'Fecha de revisión',
                                text: 'Especifique la fecha de revisión indicada en los documentos adjuntos, por ejemplo, pasaportes, libros de registro, etiquetas.',
                            },
                            {
                                title: 'Recuento de revisiones',
                                text: 'Especifique el número de revisiones, por ejemplo, si es el 1.er OH o el 2.º OH durante la vida útil del producto.',
                            },
                            {
                                title: 'Garantía del exportador',
                                text: 'La garantía proporcionada únicamente por el Exportador estipula que la posible reclamación se completará para el Exportador dentro del plazo de validez especificado.',
                            },
                            {
                                title: 'Fecha del paquete',
                                text: 'Por favor, especifique la fecha de envasado que se muestra en la etiqueta del producto, si existe.',
                            },
                            {
                                title: 'Conservación hasta',
                                text: 'Significa tiempo de espera hasta que vence el tiempo de almacenamiento del producto.',
                            },
                            {
                                title: 'Fabricante',
                                text: 'Elija entre los nombres preseleccionados o ingrese otros nuevos.',
                            },
                            {
                                title: 'Fecha de manufactura',
                                text: 'Especifique la fecha de envasado que se muestra en la etiqueta del producto, si existe.',
                            },
                            {
                                title: 'Condiciones de suministro',
                                text: 'El producto enumerado debe reflejar la entrega en nuestros puntos de entrega según Incoterms 2020. Actualmente, estamos operando a través de la terminal de carga del aeropuerto de Borispol, Ucrania, por lo que el único modo de envío disponible es la terminal de carga del aeropuerto de Borispol FCA, Ucrania.',
                            },
                            null,
                            {
                                title: 'Plazo de entrega',
                                text: 'Esto significa los plazos de entrega del suministro en días, desde la fecha de compromiso hasta la fecha especificada en la declaración aduanera de exportación en el origen o cualquier otra factura de transporte. Evalúe cuidadosamente ese tiempo para evitar sanciones por la entrega tardía.',
                            },
                            {
                                title: 'Cantidad disponible',
                                text: 'Especifique la cantidad disponible que puede suministrar dentro del plazo de entrega válido.',
                            },
                            {
                                title: 'Cantidad mínima de pedido',
                                text: 'Minimum qouantity of goods you can ship to the transit warehouse',
                            },
                            {
                                title: 'DOС "Denominación de la Cantidad"',
                                text: 'Seleccione un solo artículo (pc) o un conjunto que contenga múltiples bloques, unidades, etc., en su producto.',
                            },
                            {
                                title: 'Precio',
                                text: 'Debe especificarse por 1 unidad.',
                            },
                            {
                                title: 'Divisa',
                                text: 'Tenga en cuenta que todos sus listados deben cotizarse en dólares estadounidenses de forma predeterminada en el mercado de exportación, incluso si elimina los dólares estadounidenses como medio de pago para sus productos en su Configuración de pago.',
                            },
                            {
                                title: 'Validez de la oferta',
                                text: 'Plazo en días dentro del cual su oferta será válida en el mercado según los parámetros cotizados, incluido el precio. Dentro de ese plazo, no puede ajustar las condiciones básicas de ese bloque.',
                            },
                        ],
                    },
                    delivery_and_payment: {
                        label: 'Entrega y pago',
                        subsection: {
                            delivery_payment: {
                                tooltip: [
                                    {
                                        title: 'Medios de pago',
                                        text: 'Actualmente, proporcionamos un medio de pago: un pago contra un recibo de depósito en garantía garantizado que indica la llegada de las mercancías a nuestro depósito de tránsito en la terminal del aeropuerto de carga de Abu Dhabi. Ese método de pago es simple: una vez que sus bienes lleguen a AbuDhabi, se le pagará de acuerdo con las condiciones del Contrato.',
                                    },
                                    {
                                        title: 'Divisa',
                                        text: 'Puede marcar las monedas en las que puede vender su producto en Exportery. Tenga en cuenta que puede seleccionar tantas monedas como desee, pero la liquidación solo está disponible en una moneda cuando se establece la Cotización. Ejemplo: Ha cotizado todos sus productos en el mercado en CNY y EUR. Cuando su parte contractual selecciona uno de sus productos en CNY, los otros productos solo se pueden vender a través de CNY.',
                                    },
                                ],
                            },
                            regulatory_framework: {
                                title: 'Marco normativo',
                                tooltip: [
                                    {
                                        title: 'Requerido del Importador',
                                        text: 'Evalúe y seleccione los documentos obligatorios que deberá presentar a las autoridades de control de exportaciones en el origen.\n' +
                                            '\n' +
                                            'Hay tres opciones disponibles:\n' +
                                            '\n' +
                                            'Certificado de usuario final del Ministerio de Defensa del país de destino\n' +
                                            'Certificado de Usuario Final del Operador Aéreo Civil\n' +
                                            'No se requieren certificados de usuario final para exportar mercancías desde el país de origen',
                                    },
                                ],
                            },
                            shipment_conditions: {
                                title: 'Condiciones de envío',
                                tooltip: [
                                    {
                                        title: 'Modo de envío',
                                        text: 'Seleccione el modo de envío designado que desea enviar sus productos',
                                    },
                                ],
                            },
                            packaging: {
                                title: 'Embalaje',
                                tooltip: [
                                    null, null,
                                    {
                                        title: 'Tipo de embalaje',
                                        text: 'Seleccione el material de su embalaje',
                                    },
                                    null, null, null,
                                    {
                                        title: 'Medición',
                                        text: 'Por favor, compruebe las unidades de medida de sus productos.',
                                    },
                                ],
                            },
                            dangerous_goods_information: {
                                title: 'Información sobre mercancías peligrosas',
                                tooltip: {
                                    title: 'Informations sur les marchandises dangereuses',
                                    text: 'Complete esa sección cuidadosamente y verifique la certificación de mercancías peligrosas para sus mercancías o comuníquese con nuestro representante de servicio al cliente',
                                },
                            },
                        },
                    },
                    photo: {
                        label: 'Foto',
                        same_name_message: ['Ya se han agregado fotos con estos nombres:', 'Cambie el nombre de ellos o agregue otros!'],
                        quantity_message: ['Una cantidad máxima de', 'piezas.', 'De los cuales', '¡ya se han añadido!'],
                        size_message: ['Los tamaños de las fotos son mayores que', 'Mb:'],
                        invalid_types_label: 'Tipo de archivo no válido:',
                        rejected_files_label: 'Archivos rechazados',
                        alt_label: 'Image alt',
                        tooltip: {
                            title: 'Foto',
                            text: 'Por favor suba las fotos de su producto, una cantidad máxima de 10 piezas. La plataforma puede aceptar fotografías en los siguientes formatos: .jpeg, .jpg, .png en un tamaño máximo de 10 Mb cada una.',
                        },
                    },
                    main_technical_data: {
                        label: 'Datos técnicos principales',
                        tooltip: {
                            title: 'Principales datos técnicos',
                            text: 'Complete el nombre y el valor del parámetro de las celdas si desea cargar el contenido técnico de su producto en forma de tabla organizada. También puede cargar su contenido como texto sin formato a través de la agencia del área de texto.',
                        },
                        button: {
                            add: 'Añadir artículo',
                            remove: 'Eliminar artículo',
                        },
                    },
                    resources: {
                        label: 'Recursos',
                        warranty_title: 'Garantía',
                        tooltip: {
                            title: 'Recursos',
                            text: 'Por favor, rellene las celdas de recursos con los datos reales que se reflejan en los documentos técnicos del producto. Complete el valor de la garantía del fabricante o la garantía de la organización de revisión',
                        },
                    },
                    supply_set: {
                        label: 'Conjunto de suministros',
                        tooltip: {
                            title: 'Conjunto de suministro',
                            text: 'Por favor, especifique cada número de pieza que posea el conjunto de suministro para el producto en particular. Puede elegir el número de pieza de cada artículo de nuestra amplia base de datos (marque "elegir entre productos" en ese caso).',
                        },
                    },
                    certification: {
                        label: 'Sertificación',
                        same_name_message: ['Ya se han agregado archivos con estos nombres:', 'Cambie el nombre de ellos o agregue otros!'],
                        quantity_message: ['Una cantidad máxima de archivos', 'para cada tipo.', '¡De los cuales', 'ya se han añadido a este tipo de certificado!'],
                        tooltip: {
                            title: 'Sertificación',
                            text: 'Cargue los certificados de su producto, una cantidad máxima de 10 archivos. La plataforma puede aceptar archivos en los siguientes formatos: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg en un tamaño máximo de 10 Mb cada uno.',
                        },
                    },
                    product_guides_and_documents: {
                        label: 'Guías y documentos de productos',
                        same_name_message: ['Ya se han agregado archivos con estos nombres:', 'Cambie el nombre de ellos o agregue otros!'],
                        quantity_message: ['Una cantidad máxima de', 'archivos.', 'De los cuales', '¡ya se han añadido!'],
                        tooltip: {
                            title: 'Guías y documentos de productos',
                            text: 'Cargue las guías de productos y los documentos de sus productos, una cantidad máxima de 10 archivos. La plataforma puede aceptar archivos en los siguientes formatos: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg en un tamaño máximo de 10 Mb cada uno.',
                        },
                    },
                    shipment_guides_and_documents: {
                        label: 'Guías y documentos de envío',
                        same_name_message: ['Ya se han agregado archivos con estos nombres:', 'Cambie el nombre de ellos o agregue otros!'],
                        quantity_message: ['Una cantidad máxima de', 'archivos.', 'De los cuales', ' ¡ya se han añadido!'],
                        tooltip: {
                            title: 'Guías y documentos de envío',
                            text: 'Por favor cargue las Guías de Envío y documentos de sus mercancías, una cantidad máxima de 10 archivos. La plataforma puede aceptar archivos en los siguientes formatos: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg en un tamaño máximo de 10 Mb cada uno.',
                        },
                    },
                },
                required_message: ['Los campos con', '*', 'son obligatorios'],
                required_field_error_message: 'Por favor, rellene todos los campos obligatorios',
                no_documents_text: 'No hay documentos para mostrar',
                button: {
                    skip: 'Saltar',
                    save: 'Ahorrar',
                    cancel: 'Cancelar',
                    place_product: 'Colocar producto',
                    edit_product: 'Actualizar producto',
                    clear_form: 'Forma clara',
                },
            },
        },
        my_products: {
            title: 'Mis productos',
            device_info_message: ['Actualice su perfil y use el dispositivo de escritorio para crear o editar productos'],
            product_panel: {
                header: {
                    id_label: 'Id',
                    item_label: 'Producto',
                    condition_label: 'Expresar',
                    price_label: 'Precio',
                },
                views_label: 'Puntos de vista',
            },
            product_card: {
                id_label: 'ID',
                views: 'Puntos de vista',
            },
            empty_my_products_message: 'Aún no has añadido nada',
            delete_offer_question: '¿Está seguro de que desea eliminar esta oferta?',
            button: {
                upload_xls: 'subir xls',
                upload_from_form: 'rellene el formulario',
            },
        },
        profile: {
            title: 'Perfil',
            pages: {
                payment_setup: {
                    bank_accounts_title: 'Cuentas bancarias',
                    no_accounts_message: 'Aún no hay cuentas',
                    default_account: 'Cuenta predeterminada',
                    set_as_default: 'Establecer por defecto',
                    pg_draft: [
                        ['PG DRAFT SWIFT MT', '(NARRATIVE FIELD 79)', 'THE BANK GUARANTEE NO', 'DD', 'WAS ISSUED BY RAS BANK, UAE BY THE ORDER OF EXPORTERY LTD, ABU DHABI, UAE, ID CODE 01001), IN FAVOUR OF', 'FOR AMOUNT', 'WITH THE FOLLOWING CONTENT:'],
                        ['DATE:'],
                        ['CONTRACT NUMBER AND TITLE', ': FOR THE SUPPLY OF SPARE PARTS TO'],
                        ['BANK\'S BRANCH OR OFFICE:'],
                        ['BENEFICIARY:'],
                        ['WE HAVE BEEN INFORMED THAT EXPORTERY LTD  (HEREINAFTER CALLED THE “SELLER”) HAS ENTERED INTO CONTRACT NO', 'DD', 'WITH YOU, FOR THE SUPPLY OF SPARE PARTS TO', '(HEREINAFTER CALLED \'THE CONTRACT\'). FURTHERMORE, WE UNDERSTAND THAT, ACCORDING TO THE CONDITIONS OF THE CONTRACT, A PERFORMANCE GUARANTEE IS REQUIRED.'],
                        ['AT THE REQUEST OF THE SUPPLIER, WE HEREBY IRREVOCABLY UNDERTAKE TO PAY YOU ANY SUM(S) NOT EXCEEDING', 'UPON RECEIPT BY US OF YOUR FIRST DEMAND IN WRITING DECLARING THE SUPPLIER TO BE IN DEFAULT UNDER THE CONTRACT, WITHOUT CAVIL OR ARGUMENT, OR YOUR NEEDING TO PROVE OR TO SHOW GROUNDS OR REASONS FOR YOUR DEMAND OR THE SUM SPECIFIED THEREIN.'],
                        ['THIS GUARANTEE SHALL ENTER INTO FORCE ON THE TERMS SET OUT IN THE CONTRACT OF BANK GUARANTEE NO', 'OF', 'AND WILL BE VALID UNTIL THE'],
                        ['THIS GUARANTEE SHALL EXPIRE, NO LATER THAN THE OF', 'AND ANY DEMAND FOR PAYMENT UNDER IT MUST BE RECEIVED BY US AT THIS OFFICE ON OR BEFORE THAT DATE. HOWEVER, BEFORE THAT EXPIRATION DATE, IF THE PLANNED CONTRACT EXECUTION PERIOD HAS BEEN DELAYED OR EXTENDED, OR ITS VALUE INCREASED, THE EXPORTER SHALL RESPECTIVELY EXTEND THE VALIDITY PERIOD OF THIS PERFORMANCE SECURITY OR INCREASE ITS AMOUNT ACCORDINGLY.'],
                        ['THIS GUARANTEE IS SUBJECT TO THE UNIFORM RULES FOR DEMAND GUARANTEES, ICC PUBLICATION NO. 458, EXCEPT THAT SUBPARAGRAPH (II) OF SUB-ARTICLE 19(A) IS HEREBY EXCLUDED.'],
                        ['THANKS FOR COOPERATION,'],
                        ['BEST REGARDS,'],
                    ],
                    button: {
                        add_account: 'Agregar cuenta bancaria',
                    },
                },
                shipment_setup: {
                    default_label: 'Defecto',
                    set_as_default: 'Establecer por defecto',
                    no_items_message: 'No hay nada aquí todavía',
                    button: {
                        consignee: 'Agregar destinatario',
                        notifyparty: 'Agregar fiesta de notificación',
                        shipper: 'Agregar remitente',
                        importerforwarder: 'Agregar promotor',
                        exporterforwarder: 'Agregar promotor',
                    },
                },
                shipment_organization: {
                    create_consignee_title: 'Crear detalles del destinatario',
                    create_notify_title: 'Crear fiesta de notificación',
                    create_forwarder: 'Crear promotor',
                    create_shipper_title: 'Crear remitente',
                    edit_consignee_title: 'Editar los detalles del destinatario',
                    edit_notify_title: 'Editar notificar a la fiesta',
                    edit_forwarder: 'Editar reenviador',
                    edit_shipper_title: 'Editar remitente',
                    default_change_question: 'Está seguro de que desea cambiar el elemento predeterminado?',
                },
                bank_account: {
                    create_account_title: 'Crear cuenta bancaria',
                    edit_account_title: 'Editar cuenta bancaria',
                    default_change_question: '¿Está seguro de que desea cambiar la cuenta bancaria predeterminada?',
                },
                access_setup: {
                    title: 'Configuración de acceso',
                },
            },
            button: {
                access_setup: 'Configuración de acceso',
                save: 'Ahorrar',
                cancel: 'Cancelar',
            },
        },
        notifications: {
            title: 'Notificaciones',
            filters: ['Todos', 'No leído'],
            no_notifications_message: {
                all: 'Aún no tienes notificaciones',
                unread: 'No tienes ninguna notificación sin leer',
            },
            delete_current_notification_question: 'Está seguro de que desea eliminar esta notificación?',
            delete_question: 'Está seguro de que desea eliminar las notificaciones seleccionadas?',
            button: {
                select: 'Seleccione',
                cancel: 'Cancelar',
                delete_btn: 'Eliminar',
                show_more: 'Mostrar más',
            },
        },
        upload_documents: {
            same_name_message: ['Ya se han agregado archivos con estos nombres:', 'Cambie el nombre de ellos o agregue otros!'],
            quantity_message: ['Una cantidad máxima de', 'archivos.', 'De los cuales', ' ¡ya se han añadido!'],
        },
    },

    hub_menu: {
        link_labels: {
            quotation: 'Cotización',
            quotation_request_for_supply: 'Solicitud de suministro',
            quotation_request_for_overhaul: 'Solicitud de revisión',
            activity_hub: 'Centro de actividades',
            my_products: 'Mis productos',
            notifications: 'Notificaciones',
            profile: 'Perfil',
            log_out: 'Сerrar sesión',
        },
        user_name: 'Usuario',
        profile_img_alt: 'Profile icon',
    },

    search: {
        input_placeholder: 'Buscar',
        result_titles: ['Plataforma', 'Título'],
    },

    header: {
        button_menu: {
            importer: {
                btn_label: 'cotización',
                menu: [
                    {
                        label: 'Solicitud de suministro',
                        href: '/marketplace/quotation',
                        isDisabled: false,
                    },
                    {
                        label: 'Solicitud de revisión',
                        isDisabled: true,
                    },
                ],
            },
            exporter: {
                btn_label: 'Colocar producto',
                menu: [
                    {
                        label: 'Carga por lotes',
                        href: hubURLs.products_excel,
                    },
                    {
                        label: 'Carga manual',
                        href: hubURLs.product_create_manual,
                    },
                ],
            },
        },
        trial_identifier: {
            label: 'Modo de prueba',
            tooltip: {
                text: `Complete los datos del perfil o comuníquese con el servicio al cliente para 
                obtener un acceso a escala completa.`,
            },
        },
        button: {
            sign_in: 'Registrarse',
        },
    },

    footer: {
        text: 'EXPORTERY es una plataforma de comercio electrónico industrial segura que abarca un mercado global de repuestos y componentes para usuarios finales y exportadores en las industrias de la aviación y el transporte terrestre equipado con un sistema de gestión de documentos electrónicos patentado que eleva la eficacia y la seguridad de las adquisiciones transfronterizas a un nuevo nivel.',
        navigation_links: [
            {
                id: 'contact_us',
                title: 'contacta con nosotros',
                url: '/contact_us',
            },
            {
                list: [
                    {
                        id: 'about-marketplace',
                        title: 'Cómo funciona Exportery',
                        url: staticURLs.about_marketplace,
                    },
                    {
                        id: 'about-independent-technical-surveying',
                        title: 'Inspección técnica independiente',
                        url: staticURLs.about_independent_technical_surveying,
                    },
                    {
                        id: 'about-core-services',
                        title: 'Servicios principales',
                        url: staticURLs.about_core_services,
                    },
                    {
                        id: 'news',
                        title: 'Noticias',
                        url: URLs.news,
                    },
                    {
                        id: 'terms_and_conditions',
                        title: 'términos y condiciones',
                        url: staticURLs.terms_and_conditions,
                    },
                    {
                        id: 'privacy_policy',
                        title: 'política de privacidad',
                        url: staticURLs.privacy_policy,
                    },
                ],
            },
            {},
        ],
        social_links: [
            {
                id: 'linkedin',
                url: CONTACT_DATA.linkedin,
                img: 'linkedin',
            },
            {
                id: 'facebook',
                url: CONTACT_DATA.facebook,
                img: 'facebook',
            },
            {
                id: 'twitter',
                url: CONTACT_DATA.twitter,
                img: 'twitter',
            },
        ],
        contact_address: CONTACT_DATA.address_short,
        contact_email: CONTACT_DATA.email,
        contact_phone: CONTACT_DATA.phone,
        copyright: `Copyright © ${getCurrentYear()} EXPORTERY todos los derechos reservados`,
    },

    filters: {
        filters_label: 'Filtros',
        part_number_label: 'Ingrese el número de pieza',
        part_number_label_mobile: 'Número de pieza',
        title_label: 'Título',
        platform_label: 'Plataforma',
        organization_label: ['Exportador', 'Importador'],
        search_label: 'Buscar',
        button: {
            show_more: 'mostrar más',
            show_less: 'muestra menos',
        },
    },

    sorting: {
        sorting_label: 'Clasificación',
        sorting_title: 'Ordenar por:',
    },

    filters_sorting_button: {
        show_result: 'mostrar resultado',
        reset_all: 'Resetear todo',
        reset_pnum: 'Resetear el filtro de número de parte',
    },

    breadcrumbs: new BreadcrumbsConstructor()
        .creator({
            notifications: ['Notificaciones'],
            profile: ['Perfil'],
            access_setup: ['Perfil', 'Configuración de acceso'],
            bank_account_create: ['Perfil', 'Crear cuenta bancaria'],
            bank_account_update: ['Perfil', 'Editar cuenta bancaria'],
            consignee_details_create: ['Perfil', 'Crear detalles del destinatario'],
            consignee_details_update: ['Perfil', 'Editar los detalles del destinatario'],
            exporter_forwarder_create: ['Perfil', 'Crear promotor'],
            exporter_forwarder_update: ['Perfil', 'Editar reenviador'],
            importer_forwarder_create: ['Perfil', 'Crear promotor'],
            importer_forwarder_update: ['Perfil', 'Editar reenviador'],
            notify_party_create: ['Perfil', 'Crear fiesta de notificación'],
            notify_party_update: ['Perfil', 'Editar notificar a la fiesta'],
            shipper_create: ['Perfil', 'Crear remitente'],
            shipper_update: ['Perfil', 'Editar remitente'],
            quotation_excel: ['Mercado', 'Solicitud de suministro', 'Subir XLS'],
            quotation_excel_format: ['Mercado', 'Solicitud de suministro', 'Subir XLS', 'Formato'],
            independent_technical_surveying: ['Inspección técnica independiente'],
            core_services: ['Servicios principales'],
            terms_and_conditions: ['Términos y condiciones'],
            privacy_policy: ['Política de privacidad'],
            request_access_page: ['Solicitar acceso'],
            not_found_page: ['Página no encontrada'],
            not_acceptable_page: ['Página no aceptable'],
            internal_server_error_page: ['Error de servidor interno'],
            contract_for_supply_invoice: ['Crear factura de envío'],
            contract_for_supply_packing_list: ['Asistente de lista de empaque'],
            contract_for_supply_labelling: ['Etiquetado'],
            document_page: ['Documento'],
        }, 'Casa', session),

    error_page: {
        title: 'Lo sentimos',
        route_not_found: 'página no encontrada',
        service_under_development: 'la pagina esta en construccion',
        internal_server_error: [
            'Bueno, esto es inesperado...',
            'Código de error: 500',
            'Se ha producido un error y estamos trabajando para solucionar el problema.',
        ],
        button: {
            go_back: 'Regresa',
            go_to_homepage: 'Ir a la pagina principal',
        },
    },

    alert_modal: {
        delete_item_question: 'Estás seguro de que quieres eliminar el artículo?',
        delete_last_item_question: 'Estás seguro de que quieres eliminar el último elementos?',
        delete_all_items_question: 'Está seguro de que desea eliminar todos los elementos?',
        clear_all_question: 'Estás seguro de que quieres eliminarlo todo?',
        clear_form_question: 'Estás seguro de que quieres eliminarlo todo?',
        session_expired_message: 'Estimado usuario, su sesión ha expirado. ¡Inicia sesión de nuevo!',
        redirect_disclaimer: 'Utilice la versión de escritorio para beneficiarse de todas las funciones del producto.',
        permission_denied: 'Permiso denegado para su usuario!',
        close_modal_window_question: 'Los datos no guardados se perderan! ¿Quieres cerrar el formulario?',
        button: {
            ok: 'ok',
            cancel: 'Cancelar',
        },
    },

    item_counter: {
        empty_error_message: 'No puede estar vacío!',
        less_error_message: 'La cantidad no puede ser inferior a 1 pieza',
        more_error_message: ['La cantidad no puede ser más de', 'pieza'],
    },

    dropzone: {
        text: ['Suelta tu archivo aquí o', 'navega', 'El archivo debe ser'],
        not_for_desktop_text: ['Browse', 'the file from your device'],
    },

    file_container: {
        error_message: 'El archivo debe ser',
        button: {
            delete_btn: 'Eliminar',
        },
    },

    excel_response_info: {
        row_id: 'Fila de Excel',
        filters: ['Todos', 'Solo errores'],
        no_errors: 'No hay errores para mostrar',
        button: {
            clear_all: 'Limpiar todo',
        },
    },

    excel_format_page: {
        quotation_text: 'Siga el ejemplo de RFQ en formato .xls para obtener su cotización instantánea',
        place_product_text: 'Siga el ejemplo siguiente para colocar sus productos en el mercado electrónico:',
        quotation_tip: 'Descargue un ejemplo del archivo .xls RFQ',
        place_product_tip: 'Descargue un ejemplo del archivo .xls',
        quotation_table: {
            headers: ['Numero de parte', 'Descripción', 'DDLC', 'Cantidad'],
            body: [
                {
                    part_number: 'LFS-PS27-450',
                    description: 'Lámpara',
                    doq: 'pz',
                    quantity: '5',
                },
                {
                    part_number: 'АGB-3К Series 3',
                    description: 'Horizonte giroscópico',
                    doq: 'pz',
                    quantity: '1',
                },
                {
                    part_number: '463B',
                    description: 'Bomba de combustible',
                    doq: 'pz',
                    quantity: '2',
                },
                {
                    part_number: 'RU-19А-300',
                    description: 'APU',
                    doq: 'colocar',
                    quantity: '1',
                },
                {
                    part_number: 'KURS-MP70',
                    description: 'Sistema de navegación y aterrizaje',
                    doq: 'colocar',
                    quantity: '1',
                },
                {
                    part_number: 'GA-192T',
                    description: 'Válvula',
                    doq: 'pz',
                    quantity: '2',
                },
                {
                    part_number: '12-SAM-28',
                    description: 'Batería',
                    doq: 'pz',
                    quantity: '2',
                },
                {
                    part_number: '1300x480',
                    description: 'Neumático principal',
                    doq: 'pz',
                    quantity: '2',
                },
            ],
        },
        place_product_table: {
            headers: ['Numero de parte', 'Numero de parte(Cirílico)', 'Descripción', 'Sección', 'DDLC', 'Cantidad', 'Expresar', 'Precio, USD', 'Divisa', 'Fecha de fabricación/revisión', 'Validez de la oferta, días', 'Plazo de entrega, días', 'Requerido del Importador', 'Condiciones de suministro(Incoterms 2020)', 'Punto de suministro'],
            body: [
                {
                    part_number: 'VR-14',
                    part_number_cyrillic: 'ВР-14',
                    description: 'Caja de cambios',
                    section: 'Helicópteros',
                    doq: 'pc',
                    quantity: '3',
                    condition: 'NUEVO',
                    price: '700000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9800-00',
                    part_number_cyrillic: '8АТ-9800-00',
                    description: 'Barra de remolque',
                    section: 'Equipo de tierra',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '2000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9803-00',
                    part_number_cyrillic: '8АТ-9803-00',
                    description: 'COLCHONETA PARA TRABAJAR BAJO FUSELAJE',
                    section: 'Equipo de tierra',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '400',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9924-00',
                    part_number_cyrillic: '8АТ-9924-00',
                    description: 'ESLINGA PARA ELEVACIÓN DE LA PLUMA DE COLA',
                    section: 'Equipo de tierra',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '2100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-9923-00',
                    part_number_cyrillic: '8-9923-00',
                    description: 'COLECTOR DE ACEITE',
                    section: 'Equipo de tierra',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '300',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3901-000',
                    part_number_cyrillic: '246-3901-000',
                    description: 'HÉLICE ANTITORQUE',
                    section: 'Helicópteros, Transporte aviación',
                    doq: 'set',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '180000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-1960-000',
                    part_number_cyrillic: '8-1960-000',
                    description: 'PLACA DE LAVADO',
                    section: 'Helicópteros',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '80000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3922-00',
                    part_number_cyrillic: '246-3922-00',
                    description: 'CUCHILLAS PARA ANTITORQUE',
                    section: 'Helicópteros',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '60000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '610200А',
                    part_number_cyrillic: '610200А',
                    description: 'VÁLVULA ELECTROMAGNÉTICA',
                    section: 'Helicópteros',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '1100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8AT-2710-000',
                    part_number_cyrillic: '8AT-2710-000',
                    description: 'PALAS DEL ROTOR PRINCIPAL',
                    section: 'Helicópteros',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NUEVO',
                    price: '190000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'no se requieren documentos de usuario final',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
            ],
        },
        button: {
            back: 'Atrás',
        },
    },

    input_phone: {
        search_placeholder: 'Buscar',
        search_not_found: 'No hay entradas para mostrar',
    },

    input_field: {
        input_type_message: 'Por favor, introduzca',
        input_type: {
            'int': 'un número entero positivo',
            'float': 'un número entero positivo o decimal',
        },
    },

    select: {
        search_field_placeholder: 'Buscar',
        search_option_not_found: 'No hay entradas para mostrar',
        default_selected_label: '- Seleccione -',
    },

    select_autocomplete: {
        no_options_message: 'sin opciones',
    },

    pagination: {
        button: {
            prev: 'Prev',
            next: 'Sig',
        },
    },

    page_to_print: {
        title: 'Pagina para imprimir',
        button: {
            cancel: 'Сancelar',
            print: 'Impresión',
        },
        print_blob_button_alt: 'Impresión',
    },

    document_viewer: {
        document_not_exist: '¡No hay ningún documento para mostrar!',
        document_error: '¡Ocurrió un error al descargar el archivo!',
        document_loading_failed: 'No se pudo cargar el archivo PDF.',
        pdf_no_data: 'No se especificó ningún archivo PDF.',
        page_error: 'Error al cargar la página.',
        page_no_data: 'Ninguna página especificada.',
    },

    cookie_consent: {
        title: 'Consentimiento de cookies',
        text: 'Este sitio web utiliza cookies que ayudan al sitio web a función y también para realizar un seguimiento de cómo interactúa con nuestro sitio web. Pero para nosotros proporcionar el mejor usuario experiencia, habilite las cookies específicas de Configuración y haga clic en Aceptar.',
        buttons: {
            reject_all: 'Rechazar todo',
            accept_all: 'Aceptar todo',
        },
    },

    sweet_alert_static_data: {
        error_response_place_product: 'Errores:',
    },

    logo_alt: 'Logo',
});

export default es;