import React from 'react';

import CoreServicesModule from './module';

const CoreServicesContainer = () => {
    return <CoreServicesModule/>;
};

export {
    CoreServicesContainer as CoreServices,
};