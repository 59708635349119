import {combineReducers} from 'redux';

import {invoiceReducer} from './origin-invoice-wizard';
import {packingListWizardReducer} from './packing-list-wizard';
import {conditionReducer} from './contract-condition';
import contractMap from './contract-map';
import packingListLabels from './packing-list-labels';
import contractDocumentUpload from './contract-document-upload';

export const contractMapsReducer = combineReducers({
    invoice: invoiceReducer,
    packingListWizard: packingListWizardReducer,
    condition: conditionReducer,
    contractMap,
    packingListLabels,
    contractDocumentUpload,
});