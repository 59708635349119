import {createRequestHandler} from '../utils';
import {PROFILE_FAILURE, PROFILE_SUCCESS, PROFILE_UPDATING_FAILURE} from './profile';
import {LOGOUT_REQUEST, OPEN_SIGN_IN} from './login';
import {INVITATION_FORM_PUBLIC_UPLOAD_SUCCESS} from './public-data/invitation/upload-data';
import {RESET_PASSWORD_FORM_PUBLIC_UPLOAD_SUCCESS} from "./public-data/reset-password/upload-data";

export const FETCH_SESSION_REQUEST = 'FETCH_SESSION_REQUEST';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAILURE = 'FETCH_SESSION_FAILURE';

export const sessionRequest = createRequestHandler('FETCH_SESSION');

const initialState = {
    session: null,
    loading: false,
    noAccessRight: false,
    redirectAfterDenialOfAccess: false,
};

const session = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case FETCH_SESSION_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case FETCH_SESSION_SUCCESS:
        case PROFILE_SUCCESS:
        case INVITATION_FORM_PUBLIC_UPLOAD_SUCCESS:
        case RESET_PASSWORD_FORM_PUBLIC_UPLOAD_SUCCESS:
            return {
                ...initialState,
                session: true,
            };

        case LOGOUT_REQUEST:
        case FETCH_SESSION_FAILURE:
        case PROFILE_FAILURE:
        case PROFILE_UPDATING_FAILURE:
            return {
                ...initialState,
                session: false,
            };

        case 'SESSION_EXPIRED':
            return {
                ...initialState,
                session: false,
                noAccessRight: true,
            };

        case 'REDIRECT_AFTER_DENIAL_OF_ACCESS':
            return {
                ...state,
                redirectAfterDenialOfAccess: payload,
            };

        case OPEN_SIGN_IN:
            if (state.noAccessRight) {
                return {
                    ...state,
                    noAccessRight: false,
                };
            }

            return state;

        default:
            return state;
    }
};

export default session;