import React from 'react';
import PropTypes from 'prop-types';

import {withSectionPanel} from '../../common/hoc';

import Preloader from '../../../../../../../common/preloader';
import FileContainer from '../../../../../../../common/file-container/file-container';
import Button from '../../../../../../../common/button';
import Dropzone from '../../../../../../../common/dropzone';

import styles from './certification.module.scss';

const Certification = (props) => {
    const {
        staticData, formFields: {loading, updating}, isDisabledSection,
        certifications, isSaveButtonDisabled, defaultCertifications,
        deleteAlreadyUploadedFile, onDeleteFile, onDeleteRejectedFile,
        onDeleteAlreadyExistFile, onSave, onSkip, onAcceptedFiles, onRejectedFiles,
    } = props;
    const {skip, save} = staticData.activity_hub.place_product.from_form.button;

    let certificatesBlock = [];

    if (certifications) {
        for (let key in certifications) {
            if (certifications.hasOwnProperty(key)) {
                const maxAmountOfFiles = defaultCertifications[key].maxCount;
                const {
                    label, icon, alt, alreadyUploadedFiles,
                    files, rejectedFiles, maxCount, maxSize, mimes,
                } = certifications[key];
                const amountOfUploadedFiles = alreadyUploadedFiles.length + files.length;

                certificatesBlock.push(
                    <div className={styles['item-block']} key={key}>
                        <div className={styles.certificate}>
                            <div className={styles['certificate-logo']}>
                                <div className={styles['logo-img']}>
                                    <img alt={alt} src={icon}/>
                                </div>
                                <div className={styles['logo-name']}>
                                    {label}
                                </div>
                            </div>
                            <Dropzone id={key}
                                      containerClassNames={styles.dropzone}
                                      fileTypes={mimes}
                                      maxFiles={maxCount}
                                      maxSize={maxSize}
                                      onAcceptedFiles={(files) => onAcceptedFiles(files, key)}
                                      onRejectedFiles={(files) => onRejectedFiles(files, key)}
                                      isDisabled={amountOfUploadedFiles >= maxAmountOfFiles}
                                      multiple/>
                        </div>

                        <div className={styles['files-block']}>
                            {alreadyUploadedFiles.length !== 0 && alreadyUploadedFiles.map((file, idx) => {
                                let fileDeleting = false;
                                if (deleteAlreadyUploadedFile) {
                                    const {dropzoneName, id, loading} = deleteAlreadyUploadedFile;
                                    fileDeleting = loading && key === dropzoneName && file.id === id;
                                }

                                return (
                                    <FileContainer key={idx}
                                                   classNames={styles.file}
                                                   uploadedFile={file}
                                                   fileTypes={mimes}
                                                   fileSize={maxSize}
                                                   fileDeleting={fileDeleting}
                                                   onDelete={() => onDeleteAlreadyExistFile(key, file.id)}
                                                   canDelete={!isDisabledSection}
                                                   canDownload={!isDisabledSection}/>
                                );
                            })}
                            {files.length !== 0 && files.map((file, idx) => {
                                const fileObj = file.progress ? file.file : file;
                                return (
                                    <FileContainer key={idx}
                                                   classNames={styles.file}
                                                   file={fileObj}
                                                   fileTypes={mimes}
                                                   fileSize={maxSize}
                                                   fileProgress={file.progress}
                                                   isFileLoaded={file.isLoaded}
                                                   onDelete={() => onDeleteFile(key, idx)}
                                    />
                                );
                            })}
                            {rejectedFiles.length !== 0 && rejectedFiles.map((file, idx) => {
                                return (
                                    <FileContainer key={idx}
                                                   classNames={styles.file}
                                                   file={file}
                                                   fileTypes={mimes}
                                                   fileSize={maxSize}
                                                   onDelete={() => onDeleteRejectedFile(key, idx)}
                                    />
                                );
                            })}
                        </div>
                    </div>,
                );
            }
        }
    }

    return (loading || updating)
        ? <Preloader type={'SECONDARY'} classNames={styles['main-preloader']}/>
        : (
            <div className={styles.container}>
                <div className={styles.certificates}>
                    {certificatesBlock}
                </div>

                {!isDisabledSection && (
                    <div className={styles['btn-group']}>
                        <Button styleType={'SECONDARY'} onClick={onSkip}>{skip}</Button>
                        <Button onClick={onSave} isDisabled={isSaveButtonDisabled}>{save}</Button>
                    </div>
                )}
            </div>
        );
};

Certification.defaultProps = {
    isDisabledSection: false,
    onSave: () => {
    },
    onDeleteFile: () => {
    },
};

Certification.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    updating: PropTypes.bool,
    isDisabledSection: PropTypes.bool,
    certifications: PropTypes.object,
    defaultCertifications: PropTypes.shape({
        maxCount: PropTypes.number,
    }),
    isSaveButtonDisabled: PropTypes.bool,
    deleteAlreadyUploadedFile: PropTypes.object,
    onDeleteFile: PropTypes.func,
    onDeleteRejectedFile: PropTypes.func,
    onDeleteAlreadyExistFile: PropTypes.func,
    onSave: PropTypes.func,
    onSkip: PropTypes.func,
    onAcceptedFiles: PropTypes.func,
    onRejectedFiles: PropTypes.func,
};

export default withSectionPanel()(Certification);