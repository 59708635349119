import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import fileMimeTypesHandler from '../../../utils/file-mime-types-handler';

import Button from '../button';

import classnames from 'classnames/bind';
import styles from './dropzone.module.scss';

const Dropzone = (props) => {
    const {
        staticData, id, containerClassNames, fileTypes, maxFiles, multiple,
        isDisabled, onlyButtonZone, children, buttonStyleType, buttonClassNames,
        onAcceptedFiles, onRejectedFiles,
    } = props;

    let {maxSize} = props;

    if (maxSize !== 'Infinity') {
        maxSize = maxSize * Math.pow(1024, 2); // mb to byte
    }

    const {text, not_for_desktop_text} = staticData.dropzone;

    const dragAndDrop = useMediaQuery({minWidth: 1200});
    const acceptedFiles = fileTypes.join(', ');
    const fileMimeTypes = fileTypes.map((fileType) => fileMimeTypesHandler(fileType)).join();

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length !== 0) {
            onAcceptedFiles(acceptedFiles);
        }

        if (rejectedFiles.length !== 0) {
            onRejectedFiles(rejectedFiles);
        }
    }, [onAcceptedFiles, onRejectedFiles]);

    const {
        getRootProps, getInputProps,
        open, isDragActive, isDragReject,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        multiple,
        maxFiles,
        maxSize,
        disabled: isDisabled,
        accept: fileMimeTypes,
        onDrop,
        noDrag: !dragAndDrop,
    });

    if (onlyButtonZone) {
        return (
            <div {...getRootProps()} className={containerClassNames}>
                <input {...getInputProps({id})} />
                <Button styleType={buttonStyleType} classNames={buttonClassNames} onClick={open}>{children}</Button>
            </div>
        );
    }

    const cx = classnames.bind(styles);
    const classes = cx('container', containerClassNames, {
        'active': isDragActive,
        'disabled': isDisabled,
        'error': isDragReject,
    });

    return (
        <div {...getRootProps()} className={classes}>
            <input {...getInputProps({id})} />
            <div className={styles.block}>
                <div className={styles.text}>
                    {dragAndDrop
                        ? <>{text[0]} <span className={styles['browse-btn']} onClick={open}>{text[1]}</span></>
                        : <><span className={styles['browse-btn']}
                                  onClick={open}>{not_for_desktop_text[0]}</span> {not_for_desktop_text[1]}</>
                    }
                </div>
                <div className={styles['sub-text']}>{`${text[2]} ${acceptedFiles}`}</div>
            </div>
        </div>
    );
};

Dropzone.defaultProps = {
    id: 'dropzone',
    fileTypes: ['.xls', '.xlsx'],
    disabled: false,
    multiple: false,
    maxSize: 'Infinity',
    onlyButtonZone: false,
};

Dropzone.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.any,
    containerClassNames: PropTypes.string,
    fileTypes: PropTypes.arrayOf(PropTypes.string),
    maxFiles: PropTypes.number,
    maxSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // get in mb
    multiple: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onlyButtonZone: PropTypes.bool,
    children: PropTypes.node,
    buttonStyleType: PropTypes.string,
    buttonClassNames: PropTypes.string,
    onAcceptedFiles: PropTypes.func,
    onRejectedFiles: PropTypes.func,
};

export default withDataService()(Dropzone);