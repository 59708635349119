import {createRequestHandler} from '../utils';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

export const fileUploadRequest = createRequestHandler(
    'FILE_UPLOAD', {
        notAcceptableErrorRedirect: false,
        forbiddenErrorRedirect: false,
    });

const initialState = {
    loading: false,
    list: null,
    error: null,
};

const fileUpload = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case FILE_UPLOAD_REQUEST:
            return {
                loading: true,
                list: null,
                error: null,
            };

        case FILE_UPLOAD_SUCCESS:
            const {list} = payload;
            if (list) {
                return {
                    loading: false,
                    list: payload.list,
                    error: null,
                };
            }

            return {
                loading: false,
                list: null,
                error: null,
            };

        case FILE_UPLOAD_FAILURE:
            return {
                loading: false,
                list: null,
                error: payload,
            };

        case 'RESET_FILE_UPLOAD':
            return initialState;

        default:
            return state;
    }
};

export default fileUpload;