import {about_marketplace_en} from './en';
import {about_marketplace_ru} from './ru';
import {about_marketplace_es} from './es';
import {about_marketplace_fr} from './fr';

export {
    about_marketplace_en,
    about_marketplace_ru,
    about_marketplace_es,
    about_marketplace_fr,
};