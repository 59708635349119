import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';

import {toggleMarketplaceFilter} from '../../../../../actions/marketplace';

import FiltersContainer from '../../../../common/filters';

class Filters extends Component {
    render() {
        return <FiltersContainer {...this.props}/>;
    }
}

const mapStateToProps = ({marketplace: {products: {filters, updating}}}) => {
    return ({filters, updating});
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onToggleFilter: toggleMarketplaceFilter,
    }, dispatch);
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(Filters);