import {advantages_en} from './en';
import {advantages_es} from './es';
import {advantages_fr} from './fr';
import {advantages_ru} from './ru';

export {
    advantages_en,
    advantages_es,
    advantages_fr,
    advantages_ru,
};