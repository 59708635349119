import React from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withAccordion, withDataService, withScrollToTop} from '../common/hoc';
import {NOT_CAPITALIZE_LANGUAGES} from '../../services/data-service/constants';

import RequestAccessZone from '../common/request-access-zone/request-access-zone';

import classnames from 'classnames/bind';
import styles from './about-core-services.module.scss';

const EnhancedComponent = withAccordion()(({contents}) => contents);

const AboutCoreServices = withScrollToTop()(({staticData}) => {
    const {title, subtitle, platform, document_management_system} = staticData;
    const lgScreen = useMediaQuery({minWidth: 992});
    const language = useSelector(({
                                      session: {session},
                                      profile,
                                      publicData: {homePage},
                                  }) => session ? profile.language : homePage.language);
    const cx = classnames.bind(styles);

    const titleHandler = (title) => {
        if (Array.isArray(title)) {
            title = title.map((item, idx) => <span key={idx}>{item}</span>);
        }

        return title;
    };

    const mobileHandler = ({title, headings, list}) => {
        title = titleHandler(title);

        return (
            <>
                <h3 className={styles['mobile-title']}>{title}</h3>
                {headings.map((heading, idx) => {
                    const transformList = list
                        .filter((item) => {
                            const {interaction} = item;
                            return idx === 0
                                ? interaction === 'both' || interaction === 'exporter'
                                : interaction === 'both' || interaction === 'importer';
                        })
                        .map(({text}, idx) => (<div key={idx} className={styles.item}>{text}</div>));

                    return <EnhancedComponent key={idx}
                                              label={heading}
                                              contents={transformList}
                                              containerClassNames={styles['accordion-container']}
                                              labelClassNames={styles['accordion-label']}
                                              labelTextClassNames={styles['accordion-label-text']}
                                              contentClassNames={styles['accordion-content']}/>;
                })}
            </>
        );
    };

    const desktopHandler = ({title, headings, list}) => {
        title = titleHandler(title);

        return (
            <div className={styles.table}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{title}</h2>
                    {headings.map((heading, idx) =>
                        <div key={idx} className={cx(`heading${idx + 1}`)}>{heading}</div>,
                    )}
                </div>

                {list.map(({text, interaction}, idx) => {
                    const exporterOptionClasses = cx('tick', {
                        'active': interaction === 'both' || interaction === 'exporter',
                    });
                    const importerOptionClasses = cx('tick', {
                        'active': interaction === 'both' || interaction === 'importer',
                    });

                    return (
                        <div key={idx} className={styles.row}>
                            <div className={styles.text}>{text}</div>
                            <div className={exporterOptionClasses}/>
                            <div className={importerOptionClasses}/>
                        </div>
                    );
                })}
            </div>
        );
    };

    const notCapitalizeClass = cx({'not-capitalize-text': NOT_CAPITALIZE_LANGUAGES.includes(language)});
    const titleClasses = `${styles.title} ${notCapitalizeClass}`;
    const subtitleClasses = `${styles.subtitle} ${notCapitalizeClass}`;

    return (
        <>
            <div className={styles['title-block']}>
                <h1 className={titleClasses}>{title}</h1>
                <div className={subtitleClasses}>{subtitle}</div>
            </div>

            {[platform, document_management_system].map((content, idx) => (
                <div key={idx} className={cx('section', {'sand-theme': idx === 0})}>
                    {lgScreen ? desktopHandler(content) : mobileHandler(content)}
                </div>
            ))}
        </>
    );
});

const AboutCoreServicesContainer = (props) => {
    const session = useSelector(({session: {session}}) => session);
    return (
        <>
            <div className={styles.container}>
                <AboutCoreServices {...props} classNames={styles['scroll-to-top']}/>
            </div>
            {!session && <RequestAccessZone/>}
        </>
    );
};

AboutCoreServices.propTypes = {
    staticData: PropTypes.object,
};

export default withDataService('about_core_services')(AboutCoreServicesContainer);