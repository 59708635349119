import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import PropTypes from 'prop-types';

import {withRedirectAction, withDataService} from '../../../../../../../common/hoc';
import {withServices} from '../../../../../../../service-context';
import {xlsFormOptionsRequest} from '../../../../../../../../reducers/marketplace/quotation-page/xls-form-options';
import {fileUploadRequest} from '../../../../../../../../reducers/file-upload';
import {uploadFileHandler} from '../../../../../../../../utils';
import {resetFileUpload} from '../../../../../../../../actions/common';
import sweetAlert from '../../../../../../../common/sweet-alert';
import {marketplaceURLs} from '../../../../../../../../services/api-urls';

import ExcelUploadPage from './excel-upload-page';
import Preloader from '../../../../../../../common/preloader';

class ExcelUploadPageContainer extends Component {
    initialState = {
        file: null,
        fileProgress: {
            file: '',
            value: 0,
        },
        isFileLoaded: false,
        toQuotation: false,
        isResetFormOptions: false,
    };

    state = {
        ...this.initialState,
        selectedFormOptions: {},
    };

    componentDidMount() {
        this.props.fetchXLSFormOptions();

        if (this.props.uploadXLSResponse) {
            this.props.resetFileUpload();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.uploadXLSResponse !== this.props.uploadXLSResponse) {
            const {list, error} = this.props.uploadXLSResponse;

            if (list) {
                this.setState({toQuotation: true});
            }

            if (error) {
                sweetAlert('error', error).then(() => this.cleaningHandler());
            }
        }

        if (prevProps.language !== this.props.language) {
            this.props.fetchXLSFormOptions();
        }
    }

    cleaningHandler = () => {
        this.setState({
            ...this.initialState,
            selectedFormOptions: {},
            isResetFormOptions: true,
        });
        this.props.resetFileUpload();
    };

    onAcceptedFiles = (acceptedFiles) => {
        this.setState({file: acceptedFiles[0]});
        uploadFileHandler(acceptedFiles, this.onLoadingFile, this.onLoadedFile);
    };

    onRejectedFiles = (rejectedFiles) => {
        if (rejectedFiles.length !== 0) {
            this.setState({file: rejectedFiles[0]});
        }
    };

    onLoadingFile = (file, progress) => this.setState({fileProgress: {file, value: progress}});

    onLoadedFile = () => this.setState({isFileLoaded: true});

    onDeleteFile = () => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.setState(this.initialState);

                    if (this.props.uploadXLSResponse) {
                        this.cleaningHandler();
                    }
                }
            });
    };

    onToggleExcelUploadFormOption = (key, value) => {
        const {selectedFormOptions} = this.state;
        let newSelectedOptions = {};

        if (Object.keys(selectedFormOptions).length !== 0) {
            const hasOption = selectedFormOptions.hasOwnProperty(key);

            if (hasOption && value === selectedFormOptions[key]) {
                newSelectedOptions = selectedFormOptions;
                delete newSelectedOptions[key];
            } else {
                newSelectedOptions = {
                    ...selectedFormOptions,
                    [key]: value,
                };
            }
        } else {
            newSelectedOptions = {
                ...selectedFormOptions,
                [key]: value,
            };
        }

        this.setState({selectedFormOptions: newSelectedOptions});
    };

    onResetFormOptionsCallback = () => this.setState({isResetFormOptions: false});

    onClearAll = () => {
        const {clear_all_question} = this.props.staticData.alert_modal;

        sweetAlert('question', clear_all_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.cleaningHandler();

                    window.scrollTo({top: 0, behavior: 'smooth'});
                }
            });
    };

    onCancel = () => {
        if (this.props.uploadXLSResponse) {
            this.props.resetFileUpload();
        }

        this.props.history.push(marketplaceURLs.quotation);
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.state.toQuotation) {
            const {file, selectedFormOptions} = this.state;

            if (Object.keys(selectedFormOptions).length !== 0) {
                this.props.uploadXLSForm({fileData: file}, selectedFormOptions);
            } else {
                this.props.uploadXLSForm({fileData: file});
            }

        } else {
            this.props.resetFileUpload();
            this.props.history.push(marketplaceURLs.quotation);
        }
    };

    render() {
        const {xlsFormOptionsLoading, ...restProps} = this.props;

        return !xlsFormOptionsLoading
            ? <ExcelUploadPage {...restProps} {...this.state}
                               id="quotation-excel-upload"
                               onDeleteFile={this.onDeleteFile}
                               onAcceptedFiles={this.onAcceptedFiles}
                               onRejectedFiles={this.onRejectedFiles}
                               onToggleExcelUploadFormOption={this.onToggleExcelUploadFormOption}
                               onClearAll={this.onClearAll}
                               onResetFormOptions={this.onResetFormOptionsCallback}
                               onCancel={this.onCancel}
                               onSubmit={this.onSubmit}/>
            : <Preloader/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        xlsFormOptionsLoading: PropTypes.bool,
        uploadXLSLoading: PropTypes.bool,
        uploadXLSResponse: PropTypes.object,
        language: PropTypes.string,
        fetchXLSFormOptions: PropTypes.func,
        uploadXLSForm: PropTypes.func,
        resetFileUpload: PropTypes.func,
    };
}

const mapServicesToProps = ({quotationService}) => ({
    fetchXLSFormOptions: xlsFormOptionsRequest(quotationService.uploadFormOptions),
    uploadXLSForm: fileUploadRequest(quotationService.uploadFormActions),
});

const mapStateToProps = ({marketplace: {quotation: {xlsFormOptions}}, fileUpload, profile: {language}}) => ({
    formOptions: xlsFormOptions.options,
    xlsFormOptionsLoading: xlsFormOptions.loading,
    uploadXLSLoading: fileUpload.loading,
    uploadXLSResponse: fileUpload,
    language,
});

const mapDispatchToProps = (dispatch, {fetchXLSFormOptions, uploadXLSForm}) => {
    return bindActionCreators({
        fetchXLSFormOptions,
        uploadXLSForm,
        resetFileUpload,
    }, dispatch);
};

export default compose(
    withDataService(),
    withRedirectAction(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ExcelUploadPageContainer);