import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';

import Preloader from '../preloader';

import './select-autocomplete.scss';

const SelectAutocomplete = (props) => {
    const {
        staticData, optionsLoading, name, label,
        fixedOptions, options, value, isDisabled, props: fieldProps,
        isClearWhenFixedOptions, onSelectedOptionsChange,
    } = props;
    const {no_options_message: noOptionsMessage} = staticData.select_autocomplete;
    const [selectedValue, onChange] = useState(value);

    useEffect(() => {
        let selectOptions = value;
        if (fixedOptions.length !== 0) {
            if (isClearWhenFixedOptions) {
                selectOptions = fixedOptions;
            } else {
                selectOptions = [...fixedOptions, ...value];
            }
        }

        onChange(selectOptions);
    }, [value, fixedOptions, isClearWhenFixedOptions]);

    let mainSettings = {
        options,
        placeholder: '',
        name,
        classNamePrefix: 'select-autocomplete',
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        isClearable: selectedValue.filter((value) => !value.isFixed),
        isDisabled: isDisabled || (fieldProps && fieldProps.includes('readonly')) || optionsLoading,
        noOptionsMessage: () => noOptionsMessage,
        onChange: (value, actionMeta) => {
            switch (actionMeta.action) {
                case 'remove-value':
                case 'pop-value':
                    if (actionMeta.removedValue.isFixed) {
                        return;
                    }
                    break;
                case 'clear':
                    value = selectedValue.filter((value) => value.isFixed);
                    break;
                default:
                    break;
            }

            onSelectedOptionsChange(name, value);
            onChange(value);
        },
        styles: {
            multiValueLabel: (base, state) => {
                return state.data.isFixed ? {
                    ...base,
                    paddingRight: '12px !important',
                    backgroundColor: '#ccc !important',
                } : base;
            },
            multiValueRemove: (base, state) => {
                return state.data.isFixed ? {...base, display: 'none'} : base;
            },
        },
        theme: (theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: '#F0F2EE',
                primary: '#00000033',
                dangerLight: 'transparent',
            },
        }),
    };

    return (
        <div className={'select-autocomplete__wrapper'}>
            <Select {...mainSettings} value={selectedValue}/>
            <span className={'select-autocomplete__label'}>{label}</span>
            {optionsLoading &&
            <Preloader type="SECONDARY" classNames={'select-autocomplete__preloader'}/>}
        </div>
    );
};

SelectAutocomplete.defaultProps = {
    value: '',
    optionsLoading: false,
    options: [],
    isDisabled: false,
    isClearWhenFixedOptions: false,
};

SelectAutocomplete.propTypes = {
    staticData: PropTypes.object,
    optionsLoading: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    fixedOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    isDisabled: PropTypes.bool,
    isClearWhenFixedOptions: PropTypes.bool,
    noOptionsMessage: PropTypes.string,
    onSelectedOptionsChange: PropTypes.func,
};

export default withDataService()(SelectAutocomplete);