import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './menu-item.module.scss';

const MenuItem = (props) => {
    const {label_id, label, href, classNames, isDisabled, withIcon, onClick} = props;
    const isNotificationItem = label_id === 'notifications';
    const unreadNotifications = useSelector(({activityHub: {notifications}}) => notifications.unread);

    const cx = classnames.bind(styles);
    const itemClasses = cx('item', classNames, {
        'disabled': isDisabled,
        [`with-icon-${withIcon}`]: typeof withIcon === 'string',
        'with-icon-notification': isNotificationItem,
    });

    return href
        ? (
            <Link to={href} className={itemClasses} onClick={onClick}>
                {label}
                {isNotificationItem && unreadNotifications > 0 &&
                <span className={styles.icon}>
                    {unreadNotifications > 99 ? `99+` : unreadNotifications}
                </span>}
            </Link>
        ) : <span className={itemClasses} onClick={onClick}>{label}</span>;
};

MenuItem.defaultProps = {
    onClick: () => {
    },
};

MenuItem.propTypes = {
    label_id: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
    classNames: PropTypes.string,
    isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    withIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onClick: PropTypes.func,
};

export default MenuItem;