import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../common/hoc';
import {withServices} from '../../../../../../service-context';
import {
    formFieldsRequest,
    updatingFormFieldsRequest,
} from '../../../../../../../reducers/activity-hub/place-product/form-fields';
import {uploadFormDataRequest} from '../../../../../../../reducers/activity-hub/place-product/upload-form-data';

import FormPage from '../../components/form-page';

class EditProductPage extends Component {
    state = {
        id: null,
    };

    storageItem = 'edit_product';

    componentDidMount() {
        const {match: {params: {id}}} = this.props;
        this.props.onInitSections();
        this.props.fetchFormFields(id);
        this.setState({id});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.onInitSections();
            this.props.fetchUpdatingFormFields(this.state.id);
        }
    }

    onEditProduct = () => {
        this.props.onUploadData(
            this.props.uploadProductData,
            this.storageItem,
            this.state.id,
        );
    };

    render() {
        const {onSectionClick, ...rest} = this.props;

        return <FormPage {...rest}
                         storageItem={this.storageItem}
                         onClick={onSectionClick}
                         onUpload={this.onEditProduct}
                         onCancel={() => this.props.onCancel(this.storageItem)}/>;
    }

    static propTypes = {
        language: PropTypes.string,
        onInitSections: PropTypes.func,
        fetchFormFields: PropTypes.func,
        fetchUpdatingFormFields: PropTypes.func,
        uploadProductData: PropTypes.func,
        onUploadData: PropTypes.func,
        onCancel: PropTypes.func,
    };
}

const mapServicesToProps = ({placeProductService}) => ({
    fetchFormFields: formFieldsRequest(placeProductService.getEditFormFields),
    fetchUpdatingFormFields: updatingFormFieldsRequest(placeProductService.getEditFormFields),
    uploadProductData: uploadFormDataRequest(placeProductService.uploadEditProductData),
});

const mapStateToProps = ({profile: {language}}) => ({
    language,
});

const mapDispatchToProps = (dispatch, {
    fetchFormFields,
    fetchUpdatingFormFields,
    uploadProductData,
}) => {
    return bindActionCreators({
        fetchFormFields,
        fetchUpdatingFormFields,
        uploadProductData,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(EditProductPage);