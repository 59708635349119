import React, {useEffect, useRef, useState} from 'react';
import parse, {attributesToProps, domToReact} from 'html-react-parser';
import PropTypes from 'prop-types';

import Preloader from '../../../../common/preloader';
import Dropzone from '../../../../common/dropzone/dropzone';
import FileContainer from '../../../../common/file-container/file-container';
import UploadedFileInfo from '../../../../common/uploaded-file-info/uploaded-file-info';
import ButtonConstructor from '../../../../common/button-constructor';
import Button from '../../../../common/button';
import Accordion from '../../../../common/accordion';

import classnames from 'classnames/bind';
import styles from './place-product-from-excel.module.scss';

const PlaceProductFromExcel = (props) => {
    const cx = classnames.bind(styles);

    const {
        staticData, loading, data, file, fileProgress, isFileLoaded, uploadXLSLoading,
        uploadXLSResponse, toMyProducts, onUploadXLSFile, onGoToProducts, onDeleteFile,
        onClearAll, ...restProps
    } = props;
    const {text, button: {cancel, confirm}} = staticData.activity_hub.place_product.from_excel;

    const exampleAccordionWidthRef = useRef(null);
    const [isExampleOpen, setExampleOpen] = useState(false);
    const [exampleTableWidth, setExampleTableWidth] = useState(876);

    useEffect(() => {
        const onResize = () => {
            if (exampleAccordionWidthRef.current) {
                const width = exampleAccordionWidthRef.current.offsetWidth;
                setExampleTableWidth(width);
            }
        };

        onResize();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    });

    if (loading || !data) {
        return <Preloader/>;
    }

    const {title, list, buttons} = data;
    const descriptions = list.filter(({value}) => value !== 'table' && value !== 'file');
    const excelTable = list.find(({value}) => value === 'table');
    const excelFile = list.find(({value}) => value === 'file');

    const options = {
        replace: ({name, attribs, children}) => {
            if (name === 'table') {
                const props = attribs ? attributesToProps(attribs) : {};
                return (
                    <table {...props} className={styles.table}>
                        {domToReact(children, options)}
                    </table>
                );
            }

            if (name === 'tr') {
                const props = attribs ? attributesToProps(attribs) : {};
                return (
                    <tr {...props} className={styles.tr}>
                        {domToReact(children, options)}
                    </tr>
                );
            }

            if (name === 'td') {
                const props = attribs ? attributesToProps(attribs) : {};
                return (
                    <td {...props} className={styles.td}>
                        {domToReact(children, options)}
                    </td>
                );
            }
        },
    };

    return (
        <>
            <div className={styles.title}>{title}</div>
            <div className={styles.container}>
                <div className={styles.text}>{text}</div>

                <div className={styles['upload-container']}>
                    <div className={styles['dropzone-block']}>
                        <Dropzone {...restProps} containerClassNames={styles.dropzone} isDisabled={file !== null}/>
                    </div>

                    <div className={styles['progressbar-block']}>
                        {file && <FileContainer file={file}
                                                fileProgress={fileProgress.value}
                                                isFileLoaded={isFileLoaded}
                                                onDelete={onDeleteFile}/>}
                    </div>
                </div>

                <UploadedFileInfo data={uploadXLSResponse.list} onClear={onClearAll} withScroll/>

                <div className={styles['example-block']} ref={exampleAccordionWidthRef}>
                    <div className={styles['example-table']}>
                        {excelTable && (
                            <Accordion
                                label={(
                                    <div>
                                        <span>{excelTable.label}</span>
                                        <span className={styles.icon}/>
                                    </div>)}
                                labelClassNames={styles['accordion-label']}
                                labelTextClassNames={styles['accordion-label-text']}
                                contentClassNames={styles['accordion-content']}
                                onToggle={() => setExampleOpen(!isExampleOpen)}
                                isOpen={isExampleOpen}>
                                <div className={styles['table-container']}
                                     style={{minWidth: `${exampleTableWidth}px`}}>
                                    {parse(excelTable.content, options)}
                                </div>
                            </Accordion>
                        )}
                    </div>

                    {excelFile && (
                        <div className={styles['example-file']}>
                            {excelFile.label && <div className={styles.label}>{excelFile.label}</div>}
                            <FileContainer uploadedFile={excelFile.content}
                                           blockClassNames={styles.file}
                                           canDelete={false}
                                           canDownload/>
                        </div>
                    )}
                </div>

                <div className={styles['description-table']}>
                    {descriptions.map(({label, list, value}, idx) => {
                        let classNames = cx('items-column');
                        let items = null;

                        if (typeof list === 'string') {
                            items = parse(list);
                        }

                        if (Array.isArray(list)) {
                            classNames = cx('items-column', {
                                'col-2': list.length >= 4,
                            });

                            items = list.map((item, idx) => <div key={idx}>{parse(item)}</div>);
                        }

                        return (
                            <div key={idx} className={styles.row}>
                                <div>{label}</div>
                                <div className={classNames}>{items}</div>
                            </div>
                        );
                    })}
                </div>

                <div className={styles['btn-group']}>
                    <Button styleType="SECONDARY" classNames={styles.cancel} onClick={onGoToProducts}>{cancel}</Button>
                    {toMyProducts
                        ? <Button classNames={styles.proceed} onClick={onGoToProducts}>{confirm}</Button>
                        : <ButtonConstructor
                            {...buttons[0]}
                            classNames={cx('proceed', 'upload')}
                            dataLoading={uploadXLSLoading}
                            isDisabled={!isFileLoaded}
                            onClick={onUploadXLSFile}/>
                    }
                </div>
            </div>
        </>
    );
};

PlaceProductFromExcel.propTypes = {
    staticData: PropTypes.object,
    loading: PropTypes.bool,
    data: PropTypes.object,
    file: PropTypes.object,
    fileProgress: PropTypes.shape({
        value: PropTypes.number,
    }),
    isFileLoaded: PropTypes.bool,
    response: PropTypes.array,
    onUploadXLSFile: PropTypes.func,
    onGoToProducts: PropTypes.func,
    onDeleteFile: PropTypes.func,
    onClearAll: PropTypes.func,
};

export default PlaceProductFromExcel;