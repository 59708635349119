import {createRequestHandler} from '../../../utils';

export const XLS_FORM_OPTIONS_REQUEST = 'XLS_FORM_OPTIONS_REQUEST';
export const XLS_FORM_OPTIONS_SUCCESS = 'XLS_FORM_OPTIONS_SUCCESS';
export const XLS_FORM_OPTIONS_FAILURE = 'XLS_FORM_OPTIONS_FAILURE';

export const xlsFormOptionsRequest = createRequestHandler('XLS_FORM_OPTIONS');

const initialState = {
    loading: false,
    options: null,
    error: null,
};

const xlsFormOptions = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case XLS_FORM_OPTIONS_REQUEST:
            return {
                loading: true,
                options: null,
                error: null,
            };

        case XLS_FORM_OPTIONS_SUCCESS:
            return {
                loading: false,
                options: payload.list,
                error: null,
            };

        case XLS_FORM_OPTIONS_FAILURE:
            return {
                loading: false,
                options: null,
                error: payload,
            };

        default:
            return state;
    }
};

export default xlsFormOptions;