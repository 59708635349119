import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {IDs} from '../form-sections-id';
import {hubURLs} from '../../../../../../services/api-urls';
import {withServices} from '../../../../../service-context';
import {withDataService} from '../../../../../common/hoc';
import {placeProductAction, resetRemoveAttachmentFileState} from '../../../../../../actions/activity-hub';
import {onCreateFormData} from '../../../../../common/handlers';
import {onGetAlertResponse} from '../../../../../../utils';
import * as commonHandlers from '../components/common/handlers';
import sweetAlert from '../../../../../common/sweet-alert';
import {removeAttachmentRequest} from '../../../../../../reducers/activity-hub/remove-attachment';

import EditProductPage from './edit-product-page';
import NewProductPage from './new-product-page';

class ProductPageContainer extends Component {
    state = {
        list: [],
        productData: null,
        activeSections: null,
        isFetchingData: false,
        fetchingDataFromSection: null,
        fetchDataContinuousWithId: null,
        isUploadButtonDisabled: false,
        uploadAction: null,
        activeSectionId: null,
        isClearForm: false,
        deletedFile: null,
    };

    timerID = null;
    storage = window.sessionStorage;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formFields.list !== this.props.formFields.list) {
            this.setState({list: this.props.formFields.list});
        }

        if (prevProps.formFields.updating !== this.props.formFields.updating) {
            if (prevProps.formFields.updating && this.state.isClearForm) {
                if (window.scrollY > 0) {
                    window.scrollTo({top: 0});
                }

                this.setState({isClearForm: false});
            }
        }

        if (prevProps.sections !== this.props.sections) {
            /* not first render */
            if (prevProps.sections.length > 0) {
                let object = {};
                let disabledSections = this.props.sections.filter(({isDisabled}) => isDisabled);
                const activeSections = this.props.sections.filter(({isDisabled}) => !isDisabled);

                if (disabledSections.length > 0) {
                    disabledSections = disabledSections.map(({id}) => id);
                    for (const key in this.state.productData) {
                        if (this.state.productData.hasOwnProperty(key)) {
                            if (!disabledSections.includes(key)) {
                                object = {
                                    ...object,
                                    [key]: this.state.productData[key],
                                };
                            }
                        }
                    }
                }

                activeSections.forEach(({id}) => {
                    if (!this.state.productData.hasOwnProperty(id)) {
                        object = {
                            ...object,
                            [id]: null,
                        };
                    }
                });

                this.setState({productData: object, activeSections});

            } else {
                if (this.props.pageMode !== 'EDIT') {
                    const activeSections = this.props.sections.filter(({isDisabled}) => !isDisabled);
                    this.setState({activeSections});
                }
            }
        }

        if (prevState.productData !== this.state.productData) {
            if (this.state.fetchDataContinuousWithId) {
                this.onFetchingDataFromSectionCallback(this.state.fetchDataContinuousWithId);
                this.setState({fetchDataContinuousWithId: null});
            }
        }

        if (prevState.fetchingDataFromSection !== this.state.fetchingDataFromSection) {
            /* not first call */
            if (prevState.fetchingDataFromSection) {
                const {fetchingDataFromSection, activeSections, uploadAction} = this.state;
                if (!fetchingDataFromSection) {
                    /* take sections because activeSections will update after this action */
                    const unsavedSections = activeSections.filter(({isDisabled, isSaved}) => !isDisabled && !isSaved);

                    if (unsavedSections.length === 0) {
                        if (uploadAction) {
                            const {func, storageKeyFromPage, productID} = uploadAction;
                            this.onUploadData(func, storageKeyFromPage, productID, false);
                            this.setState({uploadAction: null});
                        }
                    } else {
                        const scrollToSection = unsavedSections[0].id;
                        this.onScrollToSection(scrollToSection);
                    }

                    this.setState({isFetchingData: false});
                }
            }
        }

        if (prevProps.uploadFormData !== this.props.uploadFormData) {
            const {status, text} = onGetAlertResponse(this.props.uploadFormData);
            const {uploadFormData: {error}, language} = this.props;

            if (status !== '' && text !== '') {
                if (status === 'success') {
                    sweetAlert(status, text).then(() => {
                        this.props.history.push(hubURLs.products);
                        this.props.resetPlaceProductFormData();
                    });

                } else {
                    const data = Object
                        .keys(error.text)
                        .map((key, idx, array) => {
                            const regExp = /_/g;
                            const transformKey = key.replace(regExp, ' ');
                            const value = error.text[key];
                            return `${value} (${transformKey})${idx === array.length - 1 ? '.' : ';'}`;
                        });

                    const errorMessage = commonHandlers.errorResponseHandler(data, language);

                    sweetAlert(status, errorMessage, null, true)
                        .then(() => this.props.resetPlaceProductFormData());
                }
            }
        }

        if (prevProps.removeAttachment !== this.props.removeAttachment && this.props.pageMode === 'EDIT') {
            if (prevProps.removeAttachment.loading) {
                if (this.state.deletedFile) {
                    const {deletedFile, list} = this.state;
                    const {status, text} = onGetAlertResponse(this.props.removeAttachment);

                    if (status !== '' && text !== '') {
                        if (status === 'success') {
                            const currentFieldIdx = list.findIndex(({name}) => name === deletedFile.fieldName);

                            if (currentFieldIdx !== -1) {
                                const currentField = list[currentFieldIdx];
                                const idx = currentField.value.findIndex(({id}) => id === deletedFile.fileId);

                                if (idx !== -1) {
                                    const updatedValue = [
                                        ...currentField.value.slice(0, idx),
                                        ...currentField.value.slice(idx + 1),
                                    ];

                                    const updatedList = [
                                        ...list.slice(0, currentFieldIdx),
                                        {
                                            ...currentField,
                                            value: updatedValue,
                                        },
                                        ...list.slice(currentFieldIdx + 1),
                                    ];

                                    this.setState({
                                        list: updatedList,
                                        deletedFile: null,
                                    });
                                }
                            }

                        } else {
                            this.setState({deletedFile: null});
                        }

                        sweetAlert(status, text).then(() => this.props.resetRemoveAttachmentFileState());
                    }
                }
            }
        }
    }

    // we can use it for scrolling, this method call before render()
    // static getDerivedStateFromProps(props, state) {}

    onInitSections = () => {
        const sectionsIDs = IDs;
        const sectionsList = [];
        for (let sectionID of sectionsIDs) {
            const sectionParams = sectionID.split(':');
            const id = sectionParams[0];
            const isRequired = !!sectionParams[1];
            const isDisabled = !!sectionParams[2];
            const isDisabledAlways = !!sectionParams[3];
            const isSaved = false; // always false when first render
            sectionsList.push({id, isRequired, isDisabled, isDisabledAlways, isSaved});
        }

        const {sections: sectionsFromStaticData} = this.props.staticData;
        const sections = sectionsList.map(({id, ...rest}) => ({
            id,
            label: sectionsFromStaticData[id].label,
            ...rest,
        }));

        this.onInitProductData(sections);
        this.props.initializePlaceProductSections(sections);
    };

    onInitProductData = (sections) => {
        let productData = {};
        sections.forEach((section) => {
            const {id, isDisabled} = section;

            if (!isDisabled) {
                productData = {...productData, [id]: null};
            }
        });

        this.setState({productData});
    };

    onUpdateNextSection = (currentID) => {
        const {sections} = this.props;
        const nextSectionID = commonHandlers.nextSectionIndexHandler(currentID, sections);
        this.onScrollToSection(nextSectionID, currentID);
        this.setState({activeSectionId: nextSectionID});
    };

    onChangeData = (sectionID, data) => {
        let newProductData = {
            ...this.state.productData,
            [sectionID]: data,
        };

        this.setState({
            productData: newProductData,
            fetchDataContinuousWithId: sectionID,
        });
    };

    onChangeFilesData = (sectionID, data, formDataMultiDropzone) => {
        if (!data) {
            if (formDataMultiDropzone) {
                let object = {};
                for (let key in formDataMultiDropzone) {
                    if (formDataMultiDropzone.hasOwnProperty(key)) {
                        object = {
                            ...object,
                            [key]: formDataMultiDropzone[key],
                        };
                    }
                }

                data = object;
            }

            this.onChangeData(sectionID, data);

        } else {
            const keys = Object.keys(data);
            const isFiles = keys
                .map((key) => data[key].files.length !== 0)
                .filter(item => item).length !== 0;

            if (isFiles) {
                this.onChangeData(sectionID, data);
            }
        }
    };

    onUpdateSaveStatus = (id, status) => {
        const currentSection = this.props.sections.find((section) => section.id === id);

        if (currentSection.isSaved !== status) {
            this.props.updatedPlaceProductSectionSaveStatus(id, status);
        }
    };

    onDeleteAlreadyUploadedFile = (fieldName, fileId) => {
        this.props.onRemoveFileFromServer(fileId);
        this.setState({deletedFile: {fieldName, fileId}});
    };

    onSaveFiles = (sectionID, data, dataMultiDropzone) => {
        this.onChangeFilesData(sectionID, data, dataMultiDropzone);
        this.onUpdateSaveStatus(sectionID, true);
    };

    onSave = (sectionID, data, storageKey = null) => {
        if (storageKey) {
            if (Object.keys(data).length > 0) {
                this.storage.setItem(storageKey, JSON.stringify(data));
            } else {
                this.storage.removeItem(storageKey);
            }
        }

        if (Object.keys(data).length === 0) {
            data = null;
        }

        this.onChangeData(sectionID, data);
        this.onUpdateSaveStatus(sectionID, true);
    };

    onSkip = (currentID) => this.onUpdateNextSection(currentID);

    onClearSessionStorage = (storageKey) => {
        const dataInStorage = Object
            .keys(this.storage)
            .filter(key => key.includes(`${storageKey}`));

        dataInStorage.forEach(key => {
            this.storage.removeItem(key);
        });
    };

    onCancel = (storageKey) => {
        this.onClearSessionStorage(storageKey);
        this.props.history.goBack();
    };

    onClearForm = (storageKey, fetchUpdatingFormFields) => {
        this.setState({isClearForm: true});

        this.onClearSessionStorage(storageKey);
        this.onInitSections();
        fetchUpdatingFormFields();
    };

    onScrollToSection = (id, prevId = null) => {
        const {sections} = this.props;

        if (!prevId) {
            prevId = id;
        }

        const elementPrev = document.getElementById(`${prevId}`);
        const elementCurrent = document.getElementById(`${id}`);

        const elementCurrentParams = elementCurrent.getBoundingClientRect();

        const style = elementPrev.currentStyle || window.getComputedStyle(elementPrev);
        const elementPrevMarginBottom = parseInt(style.marginBottom);

        const currentSectionIdx = sections.findIndex((section) => section.id === id);
        const defaultIndent = 4;
        const indent = elementPrevMarginBottom - defaultIndent;
        let top = 0;

        if (currentSectionIdx !== 0) {
            top = window.scrollY + elementCurrentParams.top - indent;
        }

        window.scrollTo({top});
    };

    // need to add code for work with sections - detect one of sections was changed
    setUploadButtonEnable = () => this.setState({isUploadButtonDisabled: false});
    //

    onFetchingDataFromSectionCallback = (sectionId, status = 'success') => {
        const {fetchingDataFromSection} = this.state;

        if (fetchingDataFromSection) {
            const {list} = fetchingDataFromSection;
            const idx = list.findIndex(id => id === sectionId);

            if (idx !== -1) {
                if (status !== 'success') {
                    const nextIdx = idx + 1;

                    if (nextIdx < list.length) {
                        const nextCurrentSection = list[nextIdx];
                        const updatedObject = {
                            ...fetchingDataFromSection,
                            currentSection: nextCurrentSection,
                        };

                        this.setState({fetchingDataFromSection: updatedObject});

                    } else {
                        this.setState({fetchingDataFromSection: null});
                    }

                } else {
                    const updatedArray = [
                        ...list.slice(0, idx),
                        ...list.slice(idx + 1),
                    ];
                    const nextIdx = idx + 1;

                    if (updatedArray.length > 0 && nextIdx < list.length) {
                        const nextCurrentSection = list[nextIdx];
                        this.setState({
                            fetchingDataFromSection: {
                                list: updatedArray,
                                currentSection: nextCurrentSection,
                            },
                        });

                    } else {
                        this.setState({fetchingDataFromSection: null});
                    }
                }
            }
        }
    };

    onUploadData = (uploadFunction, storageKey, id, withCheckSaveSection = true) => {
        const {productData, activeSections} = this.state;
        let valid = true;

        if (withCheckSaveSection) {
            /* look at unsaved sections and get data from them */
            let unsavedSections = activeSections.filter(({isSaved}) => !isSaved);
            if (unsavedSections.length > 0) {
                valid = false;
                unsavedSections = unsavedSections.map(({id}) => id);
                this.setState({isFetchingData: true});

                if (this.timerID !== null) {
                    clearTimeout(this.timerID);
                }

                this.timerID = setTimeout(() => {
                    this.setState({
                        fetchingDataFromSection: {
                            list: unsavedSections,
                            currentSection: unsavedSections[0],
                        },
                        uploadAction: {
                            func: uploadFunction,
                            storageKeyFromPage: storageKey,
                            productID: id,
                        },
                    });
                    /* set timeout 300 because animation on button .2s */
                }, 300);
            }
        }

        if (valid) {
            /* delete data from not required section if it is null */
            let dataObject = {};
            let notRequiredSections = activeSections.filter(({isRequired}) => !isRequired);
            if (notRequiredSections.length > 0) {
                notRequiredSections = notRequiredSections.map(({id}) => id);
                for (const key in productData) {
                    if (productData.hasOwnProperty(key)) {
                        if (notRequiredSections.includes(key)) {
                            if (productData[key]) {
                                dataObject = {
                                    ...dataObject,
                                    [key]: productData[key],
                                };
                            }

                        } else {
                            dataObject = {
                                ...dataObject,
                                [key]: productData[key],
                            };
                        }
                    }
                }
            }

            const productKeys = Object.keys(dataObject);
            let updatedDataObject = {};

            productKeys.forEach(key => {
                updatedDataObject = {
                    ...updatedDataObject,
                    ...dataObject[key],
                };
            });

            for (let key in updatedDataObject) {
                /* change value === 'default' to value === '' */
                if (updatedDataObject[key] === 'default') {
                    updatedDataObject = {
                        ...updatedDataObject,
                        [key]: '',
                    };
                }
            }

            const formData = onCreateFormData(updatedDataObject);

            if (this.props.pageMode === 'EDIT') {
                uploadFunction(id, {formData});

            } else {
                uploadFunction({formData});
            }

            this.onClearSessionStorage(storageKey);
        }
    };

    /* don't call when go to page from the edit product page */
    componentWillUnmount() {
        this.props.resetFormFields();
        if (this.timerID !== null) {
            clearTimeout(this.timerID);
        }
    }

    render() {
        const params = {
            list: this.state.list,
            productData: this.state.productData,
            isFetchingData: this.state.isFetchingData,
            fetchingDataFromSection: this.state.fetchingDataFromSection,
            isUploadButtonDisabled: this.state.isUploadButtonDisabled,
            storage: this.storage,
            activeSectionId: this.state.activeSectionId,
            onInitSections: this.onInitSections,
            onChangeData: this.onChangeData,
            onUpdateSaveStatus: this.onUpdateSaveStatus,
            onDeleteAlreadyUploadedFile: this.onDeleteAlreadyUploadedFile,
            onSave: this.onSave,
            onSkip: this.onSkip,
            onSaveFiles: this.onSaveFiles,
            onClearSessionStorage: this.onClearSessionStorage,
            onCancel: this.onCancel,
            onUploadData: this.onUploadData,
            onClearForm: this.onClearForm,
            onScrollToSection: this.onScrollToSection,
            setUploadButtonEnable: this.setUploadButtonEnable,
            onFetchingDataFromSectionCallback: this.onFetchingDataFromSectionCallback,
        };

        return this.props.pageMode === 'EDIT'
            ? <EditProductPage  {...this.props} {...params} />
            : <NewProductPage {...this.props} {...params} />;
    }

    static propTypes = {
        staticData: PropTypes.object,
        pageMode: PropTypes.oneOf(['NEW', 'EDIT']),
        formFields: PropTypes.object,
        sections: PropTypes.array,
        uploadFormData: PropTypes.object,
        language: PropTypes.string,
        removeAttachment: PropTypes.shape({
            loading: PropTypes.bool,
            message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
        initializePlaceProductSections: PropTypes.func,
        updatedPlaceProductSectionSaveStatus: PropTypes.func,
        onRemoveFileFromServer: PropTypes.func,
        resetPlaceProductFormData: PropTypes.func,
        resetFormFields: PropTypes.func,
        resetRemoveAttachmentFileState: PropTypes.func,
    };
}

const mapServiceToProps = ({activityHubService}) => ({
    onRemoveFileFromServer: removeAttachmentRequest(activityHubService.removeAttachmentFile),
});

const mapStateToProps = ({activityHub: {placeProduct, removeAttachment}, profile: {language}}) => {
    const {formFields, formSections, uploadFormData} = placeProduct;

    return {
        formFields,
        sections: formSections.list,
        uploadFormData,
        language,
        removeAttachment,
    };
};

const mapDispatchToProps = (dispatch, {onRemoveFileFromServer}) => {
    const {
        initializePlaceProductSections,
        updatedPlaceProductSectionDisabledStatus,
        updatedPlaceProductSectionSaveStatus,
        resetPlaceProductFormData,
        resetFormFields,
    } = placeProductAction;

    return bindActionCreators({
        onRemoveFileFromServer,
        initializePlaceProductSections,
        updatedPlaceProductSectionDisabledStatus,
        updatedPlaceProductSectionSaveStatus,
        resetPlaceProductFormData,
        resetFormFields,
        resetRemoveAttachmentFileState,
    }, dispatch);
};

export default compose(
    withServices(mapServiceToProps),
    withDataService('activity_hub.place_product.from_form'),
    connect(mapStateToProps, mapDispatchToProps),
)(ProductPageContainer);