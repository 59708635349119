import {combineReducers} from 'redux';

import {suggestReducer} from './suggest';
import formFields from './form-fields';
import formSections from './form-sections';
import uploadFormData from './upload-form-data';

export const placeProductReducer = combineReducers({
    suggest: suggestReducer,
    formFields,
    formSections,
    uploadFormData,
});