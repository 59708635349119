import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withAccordion} from '../../../../../../../../common/hoc';
import {withDataService} from '../../../../../../../../common/hoc';

import DocumentationsInformationItem from '../documentations-information-item';

import styles from './documentations-information.module.scss';

const DocumentationsInformation = (props) => {
    const {staticData, list} = props;
    const {table_labels} = staticData.activity_hub.e_contracts.pages.contract_for_supply;

    return (
        <div className={styles.container}>
            <div className={styles['table-head']}>
                {table_labels.map((label, idx) => (
                    <div key={idx} className={styles.column}>{label}</div>
                ))}
            </div>

            {list.map((item, idx) => (
                item ? <DocumentationsInformationItem key={idx} {...item}/> : null
            ))}
        </div>
    );
};

DocumentationsInformation.propTypes = {
    staticData: PropTypes.object,
    list: PropTypes.array,
};

export default compose(
    withAccordion(),
    withDataService(),
)(DocumentationsInformation);