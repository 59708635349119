import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import {withRouter} from 'react-router-dom';
import {withDataService} from '../../common/hoc';
import {withServices} from '../../service-context';
import {searchRequest} from '../../../reducers/search';
import {searchAction} from '../../../actions/common';

import Search from '../../common/search';
import InputSearch from '../../common/input-search';

import styles from './search-field.module.scss';

class SearchField extends Component {
    state = {
        inputID: 'search-input',
        slug: null,
        searchData: {
            loading: false,
            list: null,
        },
        prevValue: '',
        isEmpty: true,
    };

    timerId = null;

    componentDidMount() {
        const {slug} = this.props.search;
        this.setState({slug: slug});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            /* Clear all if user changed the pathname */
            this.onClearInputSearch();
        }

        if (prevProps.search !== this.props.search) {
            if (prevProps.search.slug !== this.props.search.slug) {
                const {slug} = this.props.search;
                this.setState({slug: slug});
                this.onClearInputSearch();
            }

            if (this.props.search.loading) {
                /*
                    There is searchData list always is null.
                    Start search if we have prevValue,
                    if we haven't, then abort the action.
                */
                if (this.state.prevValue) {
                    this.setState({searchData: {loading: true, list: null}});

                } else {
                    this.setState({searchData: {loading: false, list: null}});
                }

            } else {
                const {searchData} = this.state;
                const {list, error} = this.props.search;

                if (!searchData.loading) {
                    /* check this case only after pre-checking the searchData.loading */
                    if (list || error) {
                        /* field lost focus before get data */
                        this.onReset();
                    }

                } else {
                    let searchList = list;

                    if (list) {
                        if (Array.isArray(list) && list.length > 0) {
                            searchList = list[0];
                        }
                    }

                    if (error) {
                        searchList = error;
                    }

                    this.setState({searchData: {loading: false, list: searchList}});
                }
            }
        }
    }

    onInputChange = ({target: {value}, type}) => {
        /* start checking only on mouse click or touch (mobile) */
        if (type !== 'focus') {
            if (this.state.prevValue !== value) {
                if (this.timerId !== null) {
                    clearTimeout(this.timerId);
                }

                this.setState({isEmpty: value.length < 1});

                if (value === '' || value.length <= 1) {
                    if (this.state.searchData.list) {
                        this.onReset();
                    }
                }

                if (value.length > 1) {
                    this.timerId = setTimeout(() => {
                        const {slug: {id}} = this.state;

                        if (this.props.session) {
                            this.props.fetchSearchData(id, {phrase: value});
                        } else {
                            this.props.fetchSearchDataPublic(id, {phrase: value});
                        }
                    }, 1000);
                }

                this.setState({prevValue: value, isEmpty: value.length < 1});
            }
        }
    };

    onInputBlur = () => {
        if (this.state.prevValue) {
            /* case if input lost focus before search started */
            this.setState({prevValue: ''});
        }
    };

    onClearInputSearch = () => {
        const inputElement = document.getElementById(this.state.inputID);
        if (inputElement && inputElement.value !== '') {
            inputElement.value = '';
        }

        this.setState({isEmpty: true});
        this.onReset();
    };

    onHiddenResultBeard = (onClickOption = false) => {
        if (onClickOption === true) {
            /*
                Clear search-input field after click on the selected option
                before redirect to the search page
            */
            this.onClearInputSearch();

            if (this.state.slug.id) {
                this.props.updateSearchCategorySlug({
                    id: this.state.slug.id,
                    used: true,
                });
            }

        } else {
            this.onReset();
        }
    };

    onReset = () => {
        const {searchData, prevValue} = this.state;
        const {list, error} = this.props.search;
        const isReset = list || error;

        if (isReset) {
            this.props.resetSearchData();
        }

        if (prevValue) {
            this.setState({prevValue: ''});
        }

        if (searchData.loading || searchData.list) {
            this.setState({searchData: {loading: false, list: null}});
        }
    };

    componentWillUnmount() {
        clearTimeout(this.timerId);
    }

    render() {
        const {searchInputClasses, staticData: {search: {input_placeholder}}} = this.props;
        const {inputID, searchData: {loading, list}, isEmpty} = this.state;

        return (
            <Search searchResultClasses={styles['result-block']}
                    searchInputClasses={searchInputClasses}
                    searchList={list}
                    visible={loading || list !== null}
                    loading={loading}
                    onHidden={this.onHiddenResultBeard}>
                <InputSearch
                    type="WITH_ICON"
                    id={inputID}
                    inputPlaceholder={input_placeholder}
                    inputClasses={styles.field}
                    isEmpty={isEmpty}
                    onInputChange={this.onInputChange}
                    onInputBlur={this.onInputBlur}
                    onClearInputSearch={this.onClearInputSearch}/>
            </Search>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        searchInputClasses: PropTypes.string,
        search: PropTypes.object,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }),
        fetchSearchData: PropTypes.func,
        fetchSearchDataPublic: PropTypes.func,
        updateSearchCategorySlug: PropTypes.func,
        resetSearchData: PropTypes.func,
    };
}

const mapServicesToProps = ({searchService, publicService}) => ({
    fetchSearchData: searchRequest(searchService.getSearchData),
    fetchSearchDataPublic: searchRequest(publicService.getSearchData),
});

const mapStateToProps = ({session: {session}, search}) => {
    return ({session, search});
};

const mapDispatchToProps = (dispatch, {fetchSearchData, fetchSearchDataPublic}) => {
    const {updateSearchCategorySlug, resetSearchData} = searchAction;

    return bindActionCreators({
        fetchSearchData,
        fetchSearchDataPublic,
        updateSearchCategorySlug,
        resetSearchData,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(SearchField);