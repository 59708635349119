import React, {Component} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../../common/hoc';
import {withModal} from '../../../../../../../../../common/hoc';
import sweetAlert from '../../../../../../../../../common/sweet-alert';

import InputField from '../../../../../../../../../common/input-field';
import Button from '../../../../../../../../../common/button';

import classnames from 'classnames/bind';
import styles from './serial-number-form.module.scss';

class SerialNumberForm extends Component {
    state = {
        initFields: [],
        fields: [],
        maxNumOfSerialNumbers: 0,
        isSaveDisabled: true,
    };

    componentDidMount() {
        const {quantity, value} = this.props.offerData;
        const serialNumbers = value ? value.split(',') : [];

        let fields = [{id: 0, name: 'number[]', value: ''}];
        if (serialNumbers.length > 0) {
            fields = serialNumbers.map((serialNumber, idx) => {
                return {
                    id: idx,
                    name: 'number[]',
                    value: serialNumber,
                };
            });
        }

        const maxNumOfSerialNumbers = quantity - fields.length;
        this.setState({initFields: fields, fields, maxNumOfSerialNumbers});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.fields !== this.state.fields) {
            /* not first render after componentDidMount */
            if (prevState.fields.length !== 0 && prevState.initFields.length !== 0) {
                const {initFields, fields} = this.state;
                let withQuestionBeforeClose = true;

                const checkArraySimilarity = () => {
                    let isIdenticalArrays = [];

                    /* compare element by element */
                    let maxCount = fields.length;
                    const lengthDifferenceIn = fields.length - initFields.length;

                    if (lengthDifferenceIn > 0) {
                        maxCount = initFields.length;
                    }

                    for (let i = 0; i < maxCount; i++) {
                        if (fields[i].id === initFields[i].id) {
                            isIdenticalArrays.push(fields[i].value === initFields[i].value);
                        } else {
                            isIdenticalArrays.push(false);
                        }
                    }

                    return isIdenticalArrays;
                };

                if (fields.length === initFields.length) {
                    const isIdenticalArrays = checkArraySimilarity().findIndex((item) => !item) === -1;
                    withQuestionBeforeClose = !isIdenticalArrays;

                } else {
                    const isIdenticalArrays = checkArraySimilarity().findIndex((item) => !item) === -1;
                    if (isIdenticalArrays) {
                        const lengthDifferenceIn = fields.length - initFields.length;
                        let arrayOfValues = [];

                        if (lengthDifferenceIn > 0) {
                            for (let i = 0; i < lengthDifferenceIn; i++) {
                                /* check new elements in the fields array for equality to emptiness */
                                const baseIdx = initFields.length;
                                const value = fields[baseIdx + i].value;
                                arrayOfValues.push(value);
                            }

                            /* if value is not empty, then we ask a question before closing the form */
                            withQuestionBeforeClose = arrayOfValues.findIndex((item) => item !== '') !== -1;

                        } else {
                            withQuestionBeforeClose = true;
                        }
                    }
                }

                if (this.props.withQuestionBeforeCloseModal !== withQuestionBeforeClose) {
                    this.props.toggleStateWithQuestionBeforeCloseModal(withQuestionBeforeClose);
                }
            }
        }
    }

    onAddFields = () => {
        const {fields} = this.state;
        const newField = {
            id: fields[fields.length - 1].id + 1,
            name: 'number[]',
            value: '',
        };

        const newFields = [
            ...fields,
            newField,
        ];

        this.setState((prevState) => {
            const {maxNumOfSerialNumbers} = prevState;
            const newMaxNum = maxNumOfSerialNumbers - 1;

            return {
                ...prevState,
                fields: newFields,
                maxNumOfSerialNumbers: newMaxNum,
            };
        });
    };

    onRemoveFields = (currentIdx) => {
        const {delete_item_question} = this.props.staticData.alert_modal;
        const {fields} = this.state;
        const withQuestion = fields[currentIdx].value !== '';

        const onRemoveAction = () => {
            const newFields = [
                ...fields.slice(0, currentIdx),
                ...fields.slice(currentIdx + 1),
            ];

            this.setState((prevState) => {
                const {maxNumOfSerialNumbers} = prevState;
                const newMaxNum = maxNumOfSerialNumbers + 1;

                return {
                    ...prevState,
                    fields: newFields,
                    maxNumOfSerialNumbers: newMaxNum,
                    isSaveDisabled: false,
                };
            });
        };

        if (withQuestion) {
            sweetAlert('question', delete_item_question)
                .then((resp) => {
                    if (resp.isConfirmed) {
                        onRemoveAction();
                    }
                });
        } else {
            onRemoveAction();
        }
    };

    onInputChange = ({target: {id, value}}) => {
        const {fields} = this.state;
        const newFields = fields.map((field) => {
            if (field.id === +id) {
                return {
                    ...field,
                    value,
                };
            }

            return field;
        });

        this.setState({fields: newFields});

        if (this.state.isSaveDisabled) {
            this.setState({isSaveDisabled: false});
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {name} = this.props.offerData;
        const notEmptyFields = this.state.fields.filter(({value}) => value !== '');
        let offerSerialNumbers = [];
        notEmptyFields.forEach(({value}) => {
            offerSerialNumbers.push(value);
        });
        offerSerialNumbers = offerSerialNumbers.join(',');

        this.props.onUpdateSerialNumbers({name, value: offerSerialNumbers});
    };

    render() {
        const {fields, maxNumOfSerialNumbers, isSaveDisabled} = this.state;
        const {staticData} = this.props;

        const {
            add_serial_number, save,
        } = staticData.activity_hub.e_contracts.pages.packing_list_wizard.pages.shipment_lot.button;
        const cx = classnames.bind(styles);
        const inputBlockClasses = cx('inputs-block', {'with-scroll': fields.length > 7});

        const fieldsList = fields.map(({id, name, value}, idx) => {
            return (
                <div key={idx} className={styles['input-container']}>
                    <div className={styles['input-index']}>{`${idx + 1}.`}</div>
                    <InputField id={id} name={name} value={value}
                                props={fields.length > 1 && ['with-remove-field-button']}
                                onInputChange={this.onInputChange}
                                onRemoveInputField={() => this.onRemoveFields(idx)}/>
                </div>
            );
        });

        return (
            <div className={styles.container}>
                <form className={styles.form} onSubmit={this.onSubmit}>
                    <div className={inputBlockClasses}>
                        {fieldsList}
                    </div>

                    <Button styleType={'WITH_PLUS'}
                            classNames={styles.add}
                            onClick={this.onAddFields}
                            isDisabled={maxNumOfSerialNumbers === 0}>
                        {add_serial_number}
                    </Button>

                    <Button type={'submit'}
                            classNames={styles.save}
                            isDisabled={isSaveDisabled}>
                        {save}
                    </Button>
                </form>
            </div>
        );
    }

    static defaultProps = {
        formMode: 'CREATE',
        toggleStateWithQuestionBeforeCloseModal: () => {
        },
        onUpdateSerialNumbers: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        withQuestionBeforeCloseModal: PropTypes.bool,
        formMode: PropTypes.oneOf(['CREATE', 'EDIT']),
        toggleStateWithQuestionBeforeCloseModal: PropTypes.func,
        onUpdateSerialNumbers: PropTypes.func,
    };
}

export default compose(
    withDataService(),
    withModal(),
)(SerialNumberForm);