import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';
import styles from './notification-item.module.scss';

const NotificationItem = ({classNames, id, date, content, viewed, onOpenNotification}) => {
    const {subject, hello, body} = content;

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames, {'open': 'isOpen'});
    const topicClasses = cx('topic', {'unread': !viewed});

    return (
        <div id={id} className={containerClasses} onClick={() => onOpenNotification(id, viewed)}>
            <div className={styles['topic-block']}>
                <div className={topicClasses}>{subject}</div>
                <div className={styles.date}>{date}</div>
            </div>

            <div className={styles.message}>{hello} {parse(body)}</div>
        </div>
    );
};

NotificationItem.propTypes = {
    classNames: PropTypes.string,
    id: PropTypes.any,
    date: PropTypes.string,
    content: PropTypes.shape({
        subject: PropTypes.string,
        hello: PropTypes.string,
        body: PropTypes.string,
    }),
    viewed: PropTypes.bool,
    onOpenNotification: PropTypes.func,
};

export default NotificationItem;