const createAlertResponse = (status, text) => {
    return {
        status,
        text,
    };
};

const onGetAlertResponse = (state) => {
    const {message, error} = state;

    let status = '', text = '';

    if (message || error) {
        status = message ? message.status : error.status;
        text = message ? message.text : error.text;
    }

    return {
        status,
        text,
    };
};

export {createAlertResponse, onGetAlertResponse};