export const about_independent_technical_surveying_fr = {
    title: 'About independent technical surveying',
    subtitle: `At Exportery, we inspect every consignment or shipping lot of spare parts and act as 
    independent unbiased judges to ensure the ultimate safety of our customers`,
    content: [
        {
            description: `Checking a content shipment lot per the e-packing list for quantity conformity.`,
            img: 'quantity.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Checking the availability of technical log books, passports, and their 
            conformity to GOST, MAK, ICAO, and EASA PART-145, ISO standards.`,
            img: 'technical-books.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Checking the storage and conservation times for expiration.`,
            img: 'exp-date.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Photo registration of all items in the photo report, including the serial 
            number photograph of each item and passport.`,
            img: 'photo.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Examining each new item in the shipping lot to identify the status, 
            traces of previous use, repainting, refurbishing, and traces of damage. By 
            extension, checking the originality of conservation and packaging.`,
            img: 'checking.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Spare parts and components authentication process with a particular inquiry to the manufacturer in exceptional cases.`,
            img: 'authentication.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Obligatory Inspection Report compilation by Exportery's team of technical specialists 
            for future approval/endorsement by the concerned importer.`,
            img: 'report.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
    ],
};