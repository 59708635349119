import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';

import {withDataService, withPagination, withRedirectAction} from '../../../../../../common/hoc';
import {withServices} from '../../../../../../service-context';
import {
    packingListLabelsRequest,
    updatingPackingListLabelsRequest,
} from '../../../../../../../reducers/activity-hub/contract-maps/packing-list-labels';
import {utilsAction} from '../../../../../../../actions/common';
import {labellingAction} from '../../../../../../../actions/activity-hub/contract-maps';
import {updatingBreadcrumbsOnComponentAction} from '../../components/common/handlers/updating-breadcrumbs';
// import arrayOfItemsHandler from './handlers/array-of-items-handler';

import PageToPrint from '../../../../../../page-to-print';
import PackingListLabelling from './packing-list-labelling';
import Preloader from '../../../../../../common/preloader';

const EnhancedComponent = withPagination()(PackingListLabelling);

class PackingListLabellingContainer extends Component {
    state = {
        updatingBreadcrumbs: true,
        currentPage: 1,
        numOfPages: 1,
    };

    componentDidMount() {
        const {match: {params: {id}}} = this.props;
        this.props.fetchPackingListLabels(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {match: {params: {id}}} = this.props;
            this.props.fetchPackingListLabelsUpdating(id);
            this.setState({updatingBreadcrumbs: true});
        }

        if (prevProps.breadcrumbs !== this.props.breadcrumbs) {
            if (this.state.updatingBreadcrumbs) {
                const {staticData: {breadcrumbs: {contract_for_supply_labelling}}, breadcrumbs: {list}} = this.props;
                const updatedList = updatingBreadcrumbsOnComponentAction(list, contract_for_supply_labelling.list);
                this.props.updateBreadcrumbs(updatedList);
                this.setState({updatingBreadcrumbs: false});
            }
        }

        if (prevProps.labelsList !== this.props.labelsList) {
            /** page mode with pagination (this case need work in future)
             if (this.props.labelsList) {
                const labelsToView = arrayOfItemsHandler(labels, 8);
                // 8 labels set to view on a one Labelling (current component) page
                const numOfPages = Math.ceil(labelsList.length / 8);
                this.setState({labelsToView, numOfPages});
               }
             **/
        }
    }

    onCheckboxChange = ({target: {id, checked}}) => this.props.toggleLabelCheckbox({id, checked});

    onSelectAllCheckbox = ({target: {checked}}) => this.props.togglePrintAllLabelsMode(checked);

    onSwitchPrintCutLine = ({target: {checked}}) => this.props.togglePrintLabelsMode(checked);

    // onSwitchPage = (page) => this.setState({currentPage: page});

    render() {
        const {
            staticData, loading, updating, title: helmetTitle, apiTitle,
            labelsList, labelsToPrint,
            isSelectAllLabels, isPrintCutLine,
        } = this.props;
        const {currentPage, numOfPages} = this.state;
        const {title} = staticData.activity_hub.e_contracts.pages.packing_list_wizard.pages.labelling;

        /** when page mode with pagination
         const labelsIdx = currentPage - 1; // for 1 page idx = 0
         const currentLabelsToView = labelsToView.length !== 0 ? labelsToView[labelsIdx] : [];
         **/
        const currentLabelsToView = labelsList;

        const contentToPrint = <PackingListLabelling labels={labelsToPrint}
                                                     isPrintCutLine={isPrintCutLine}
                                                     isPrintContent/>;

        return (
            <>
                <Helmet>
                    <title>{helmetTitle || apiTitle}</title>
                </Helmet>
                <PageToPrint title={title}
                             contentToPrint={contentToPrint}
                             isPrintBtnDisabled={!labelsToPrint.length}
                             documentTitle={helmetTitle || apiTitle}
                             isCreateContentBeforePrint>
                    {(!loading && !updating && labelsList)
                        ? <EnhancedComponent
                            labels={currentLabelsToView}
                            isSelectAll={isSelectAllLabels}
                            isPrintCutLine={isPrintCutLine}
                            onCheckboxChange={this.onCheckboxChange}
                            onSelectAllCheckbox={this.onSelectAllCheckbox}
                            onSwitchPrintCutLine={this.onSwitchPrintCutLine}

                            // props for pagination, now work without pagination
                            staticData={staticData}
                            total_pages={numOfPages}
                            current_page={currentPage}
                            withPagination={false}
                            // withPagination={numOfPages > 1}
                            onSwitchPage={this.onSwitchPage}
                        />
                        : <Preloader/>}
                </PageToPrint>
            </>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        breadcrumbs: PropTypes.shape({
            loading: PropTypes.bool,
            list: PropTypes.array,
        }),
        apiTitle: PropTypes.string,
        title: PropTypes.string,
        labelsList: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        isSelectAllLabels: PropTypes.bool,
        isPrintCutLine: PropTypes.bool,
        fetchPackingListLabels: PropTypes.func,
        fetchPackingListLabelsUpdating: PropTypes.func,
        updateBreadcrumbs: PropTypes.func,
        togglePrintAllLabelsMode: PropTypes.func,
        toggleLabelCheckbox: PropTypes.func,
        togglePrintLabelsMode: PropTypes.func,
    };
}

const mapServicesToProps = ({packingListWizardService}) => ({
    fetchPackingListLabels: packingListLabelsRequest(packingListWizardService.getPackingListLabels),
    fetchPackingListLabelsUpdating: updatingPackingListLabelsRequest(packingListWizardService.getPackingListLabels),
});

const mapStateToProps = ({profile: {language}, utils: {breadcrumbs, apiTitle}, activityHub: {contractMaps}}) => {
    const {packingListLabels} = contractMaps;
    return {
        language,
        breadcrumbs,
        apiTitle,
        title: packingListLabels.title,
        loading: packingListLabels.loading,
        updating: packingListLabels.updating,
        labelsList: packingListLabels.list,
        labelsToPrint: packingListLabels.listToPrint,
        isSelectAllLabels: packingListLabels.selectedAll,
        isPrintCutLine: packingListLabels.withCutLine,
    };
};

const mapDispatchToProps = (dispatch, {fetchPackingListLabels, fetchPackingListLabelsUpdating}) => {
    const {togglePrintAllLabelsMode, toggleLabelCheckbox, togglePrintLabelsMode} = labellingAction;
    return bindActionCreators({
        fetchPackingListLabels,
        fetchPackingListLabelsUpdating,
        updateBreadcrumbs: utilsAction.updateBreadcrumbs,
        togglePrintAllLabelsMode,
        toggleLabelCheckbox,
        togglePrintLabelsMode,
    }, dispatch);
};

export default compose(
    withServices(mapServicesToProps),
    withDataService(),
    withRedirectAction(),
    connect(mapStateToProps, mapDispatchToProps),
)(PackingListLabellingContainer);