import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import {shopByBrandService} from '../../services/data-service/home-page/brands-service';
import {marketplaceURLs, URLs} from '../../services/api-urls';
import {BANNER_TEXT_LESS_TOP, NOT_CAPITALIZE_LANGUAGES} from '../../services/data-service/constants';

import Banner from './components/banner';
import AdvantageItem from './components/advantage-item';
import Section from '../common/section/section';
import ArticleCard from '../news/components/common/article-card/article-card';
import BrandLogo from '../common/brand-logo';
import RequestAccessZone from '../common/request-access-zone/request-access-zone';
import Button from '../common/button';

import classnames from 'classnames/bind';
import styles from './home-page.module.scss';

import video from '../../services/data-service/home-page/assets/video.mp4';
import lightImage from '../../services/data-service/home-page/assets/light-video-image.png';

const HomePage = ({staticData, session, news, language, onSignUpOpen}) => {
    const videoRef = useRef(null);
    const controlButtonRef = useRef(null);

    const [state, setState] = useState({
        videoLight: lightImage,
        playing: true,
    });

    useEffect(() => {
        const onResize = () => {
            if (videoRef.current) {
                const width = videoRef.current.wrapper.offsetWidth;
                const height = 0.56 * width;

                const element = document.querySelector(`.${styles.video}`);
                if (element) {
                    element.style.height = `${height}px`;
                }
            }
        };

        onResize();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [videoRef]);

    useEffect(() => {
        if (videoRef.current.player) {
            controlButtonRef.current.classList.add(`${styles.animation}`);
        }
    }, [state.playing]);

    const onTurnOnOffVideo = () => {
        setState(state => ({...state, playing: !state.playing}));
    };

    const {
        home_page: {
            advantages_content,
            main_title, challenges_subtitle,
            challenges_text, challenges_list, video_content,
            banner_text, button: {request_access_btn, public_marketplace_btn},
        },
        section: {news: newsStaticData, shop_by_brand},
    } = staticData;

    const cx = classnames.bind(styles);
    const bannerTextContainerClasses = cx('banner-text-container', {'less-top': BANNER_TEXT_LESS_TOP.includes(language)});
    const notCapitalizeClass = cx({'not-capitalize-text': NOT_CAPITALIZE_LANGUAGES.includes(language)});
    const mainTextTitleClasses = `${styles.title} ${notCapitalizeClass}`;
    const videoBlockClasses = `${styles['video-block']} ${notCapitalizeClass}`;
    const videoControlButton = cx('control-button', {
        'pause-button': !state.playing,
        'play-button': state.playing,
    });
    const sectionNewsClasses = `${cx('section', 'container', 'news')} ${notCapitalizeClass}`;
    const sectionClasses = `${cx('section', 'container')} ${notCapitalizeClass}`;

    return (
        <div className={styles.wrapper}>
            <div className={styles['banner-container']}>
                <Banner/>
            </div>

            <div className={bannerTextContainerClasses}>
                <div className={styles['banner-text']}>
                    {banner_text[0]}
                    <div>{banner_text[1]}</div>
                </div>

                <Link to={session ? marketplaceURLs.marketplace : `${URLs.pub}${marketplaceURLs.marketplace}`}
                      className={styles['btn-link']}>
                    <Button classNames={styles.btn}>{public_marketplace_btn}</Button>
                </Link>
            </div>

            <div className={cx('main-text')}>
                <div className={styles.container}>
                    <div className={mainTextTitleClasses}>{main_title}</div>
                    {!session && (
                        <Button classNames={styles['request-access-btn']}
                                onClick={onSignUpOpen}>
                            {request_access_btn}
                        </Button>
                    )}
                </div>
            </div>

            <div className={cx('advantage-items')}>
                {advantages_content.map((content, idx) => (
                    <AdvantageItem key={idx} {...content}
                                   language={language}
                                   classNames={styles.container}/>
                ))}
            </div>

            <div className={styles.challenges}>
                <div className={styles.container}>
                    <div className={styles.subtitle}>{challenges_subtitle}</div>
                    <div className={styles['subtitle-text']}>{challenges_text}</div>
                    <div className={styles['challenges-content']}>
                        {challenges_list.map(({title, text, url}, idx) => {
                            const imageUrl = require(`../../services/data-service/home-page/assets/challenges/${url}`).default;
                            return (
                                <div key={idx} className={styles.block}>
                                    <div className={styles.image} style={{backgroundImage: `url(${imageUrl})`}}/>
                                    <div className={styles.title}>{title}</div>
                                    <div className={styles.text}>{text}</div>
                                </div>
                            );
                        })}
                    </div>
                    <Button classNames={styles['request-access-btn']}
                            onClick={onSignUpOpen}>
                        {request_access_btn}
                    </Button>
                </div>
            </div>

            <div className={videoBlockClasses}>
                <div className={styles.container}>
                    <div className={styles.video}>
                        <ReactPlayer url={video} controls
                                     ref={videoRef}
                                     width={'100%'}
                                     height={'100%'}
                                     playing={state.playing}
                                     stopOnUnmount={false}
                                     light={state.videoLight}
                                     onPlay={() => setState(state => ({...state, playing: true}))}
                                     onPause={() => setState(state => ({...state, playing: false}))}
                                     config={{
                                         file: {
                                             attributes: {
                                                 controlsList: 'nodownload',
                                             },
                                         },
                                     }}/>
                        {videoRef.current && !videoRef.current.state.showPreview && (
                            <div ref={controlButtonRef}
                                 className={videoControlButton}
                                 onClick={onTurnOnOffVideo}>
                                <div className={styles.icon}/>
                            </div>
                        )}
                    </div>
                    <div className={cx('content', [`${video_content.className}`])}>
                        <div className={styles.title}>
                            {video_content.title.map((item, idx) => (
                                <div key={idx} className={styles['title-item']}>{item}</div>))}
                        </div>
                    </div>
                </div>
            </div>

            <Section title={newsStaticData.title}
                     page={newsStaticData.page}
                     sectionClassNames={sectionNewsClasses}
                     baseView={[3, 4, 6]}
                     dataLoading={news.loading}
                     noContentMessage={newsStaticData.no_articles}
                     sectionGridSize="auto"
                     cardContent>
                {news.list.map((item, idx) =>
                    <ArticleCard key={idx} idx={idx} {...item}/>,
                )}
            </Section>

            <Section title={shop_by_brand.title}
                     sectionClassNames={sectionClasses}
                     iconsContent>
                {shopByBrandService.map((item, idx) => <BrandLogo key={idx} {...item}/>)}
            </Section>

            <RequestAccessZone/>
        </div>
    );
};

HomePage.defaultProps = {
    onSignUpOpen: () => {
    },
};

HomePage.propTypes = {
    staticData: PropTypes.object,
    session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onSignUpOpen: PropTypes.func,
};

export default HomePage;