import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from 'prop-types';

import {
    detectingPublicUrl, detectingStaticUrl,
    errorURLs, marketplaceURLs, staticURLs, URLs, hubURLs,
} from '../../services/api-urls';

import HomePage from '../home-page';
import {News} from '../news';
import InvitationPage from '../invitation-page/invitation-page';
import ResetPasswordPage from "../authorization/reset-password";
import {MarketplacePublic} from '../marketplace-public';
import {Marketplace} from '../marketplace';
import {ActivityHub} from '../activity-hub';
import DocumentPage from '../document-page';
import {IndependentTechnicalSurveying} from '../independent-technical-surveying';
import {CoreServices} from '../core-services';
import AboutMarketplace from '../about-marketplace';
import AboutIndependentTechnicalSurveying from '../about-independent-technical-surveying';
import AboutCoreServices from '../about-core-services';
import PrivacyPolicy from '../privacy-policy/privacy-policy';
import TermsAndConditions from '../terms-and-conditions';
import Careers from '../careers';
import Faq from '../faq';
import RequestAccessPage from '../request-access-page';
import TemplatesContainer from '../../templates';
import {Header} from '../header';
import Footer from '../footer';
import LoadingIndicator from '../common/loading-indicator';
import ErrorIndicator from '../common/error-indicator';
import Alert from '../common/alert';
import {Authorization} from '../authorization';
import Modal from '../common/modal';
import Breadcrumbs from '../common/breadcrumbs/breadcrumbs';
import CookieConsent from '../cookie-consent';

import styles from './app.module.scss';

const App = (props) => {
    smoothscroll.polyfill();

    const {
        session: {loading: sessionLoading, session},
        profile: {loading: profileLoading},
        cookieConsent: {isActive: cookieConsent},
        location, modalActive, serverError,
        notFoundError, notAcceptableError,
        resetErrorIndicatorSwitcher, apiTitle,
    } = props;

    let serverErrorAlert = null;

    const renderRoute = (Component = () => null, properties = {}) => {
        if (!session && !detectingPublicUrl(location.pathname)) {
            return <HomePage/>;
        }

        return (
            <main className={styles['main-container']} data-name="main-container">
                <Breadcrumbs/>
                <Component {...properties}/>
            </main>
        );
    };

    if (sessionLoading || (session && profileLoading)) {
        return <LoadingIndicator/>;
    }

    if (serverError) {
        if (!modalActive && detectingStaticUrl(location.pathname)) {
            serverErrorAlert = (
                <Alert onResetAlert={resetErrorIndicatorSwitcher}>{serverError}</Alert>
            );
        }
    }

    const pages = (
        <Switch>
            <Route path={staticURLs.home} exact>
                {session ? <Redirect to={marketplaceURLs.marketplace}/> : <HomePage/>}
            </Route>

            <Route path={URLs.news}>
                {renderRoute(News)}
            </Route>

            <Route path={`${URLs.invitation}/:id`} exact>
                <InvitationPage/>
            </Route>

            <Route path={`${URLs.reset_password}/:id`} exact>
                <ResetPasswordPage/>
            </Route>

            <Route path={`${URLs.pub}${marketplaceURLs.marketplace}`}>
                {renderRoute(MarketplacePublic)}
            </Route>

            <Route path={marketplaceURLs.marketplace}>
                {renderRoute(Marketplace)}
            </Route>

            <Route path={hubURLs.hub}>
                {renderRoute(ActivityHub)}
            </Route>

            <Route path={'/doc/:id'} exact>
                {renderRoute(DocumentPage)}
            </Route>

            <Route path={URLs.independent_technical_surveying} exact>
                {!session && session !== null
                    ? <Redirect to={staticURLs.request_access}/>
                    : renderRoute(IndependentTechnicalSurveying)}
            </Route>

            <Route path={URLs.core_services} exact>
                {!session && session !== null
                    ? <Redirect to={staticURLs.request_access}/>
                    : renderRoute(CoreServices)}
            </Route>

            <Route path={staticURLs.about_marketplace} exact>
                <AboutMarketplace/>
            </Route>

            <Route path={staticURLs.about_independent_technical_surveying} exact>
                <AboutIndependentTechnicalSurveying/>
            </Route>

            <Route path={staticURLs.about_core_services} exact>
                <AboutCoreServices/>
            </Route>

            <Route path={staticURLs.privacy_policy} exact>
                {renderRoute(PrivacyPolicy)}
            </Route>

            <Route path={staticURLs.terms_and_conditions} exact>
                {renderRoute(TermsAndConditions)}
            </Route>

            <Route path={staticURLs.careers} exact>
                <Careers/>
            </Route>

            <Route path={staticURLs.faq} exact>
                <Faq/>
            </Route>

            <Route path="/templates" exact>
                {renderRoute(TemplatesContainer)}
            </Route>

            <Route path={staticURLs.request_access} exact>
                {renderRoute(RequestAccessPage)}
            </Route>

            <Route path={errorURLs.internal_server_error}>
                {renderRoute(ErrorIndicator, {type: serverError ? 'INTERNAL_SERVER_ERROR' : 'ROUTE_NOT_FOUND'})}
            </Route>

            <Route path={errorURLs.not_found}>
                {renderRoute(ErrorIndicator, {type: notFoundError ? 'NOT_FOUND' : 'ROUTE_NOT_FOUND'})}
            </Route>

            <Route path={errorURLs.not_acceptable}>
                {renderRoute(ErrorIndicator, {type: notAcceptableError ? 'NOT_ACCEPTABLE' : 'ROUTE_NOT_FOUND'})}
            </Route>

            <Route>
                <Redirect to={errorURLs.not_found}/>
            </Route>
        </Switch>
    );

    return (
        <>
            <Helmet>
                <title>{apiTitle}</title>
            </Helmet>

            <div className={styles.app}>
                <Header/>
                {pages}
                <Footer/>

                <Modal><Authorization/></Modal>
                {serverErrorAlert}
                {cookieConsent && <CookieConsent/>}
            </div>
        </>
    );
};

App.propTypes = {
    session: PropTypes.shape({
        loading: PropTypes.bool,
        session: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    }),
    profile: PropTypes.shape({
        loading: PropTypes.bool,
    }),
    cookieConsent: PropTypes.shape({
        isActive: PropTypes.bool,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    modalActive: PropTypes.bool,
    apiTitle: PropTypes.string,
    serverError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    notFoundError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    notAcceptableError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    resetErrorIndicatorSwitcher: PropTypes.func,
};

export default App;