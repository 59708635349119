import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import documentIconHandler from '../../../utils/document-icon-handler';

import EllipsisTextTooltip from '../ellipsis-text-tooltip';
import Preloader from '../preloader';

import classnames from 'classnames/bind';
import styles from './file-container.module.scss';

const FileContainer = (props) => {
    const {
        staticData, classNames, blockClassNames, file, uploadedFile, fileTypes, fileSize,
        fileProgress, fileDeleting, isFileLoaded, canDownload, canDelete, onDelete,
    } = props;
    const {error_message, button: {delete_btn}} = staticData.file_container;

    const inputRangeRef = useRef();

    useEffect(() => {
        if (inputRangeRef.current) {
            inputRangeRef.current.value = fileProgress;
        }
    }, [fileProgress]);

    let name = '', size = '', type = '', href = '';

    if (file) {
        if (file.errors) {
            name = file.file.name;
            size = file.file.size;
            type = file.file.type;
            href = file.file.href;

        } else {
            name = file.name;
            size = file.size;
            type = file.type;
            href = file.href;
        }

        const k = 1024 * 1024; // coefficient, decimal 2, b -> mb
        size = `${Math.round(size / k * 100) / 100} MB`;
    }

    if (uploadedFile) {
        name = uploadedFile.name;
        size = uploadedFile.size;
        type = uploadedFile.type;
        href = uploadedFile.href;
    }

    const cx = classnames.bind(styles);
    const containerClasses = cx('container', classNames, {
        'invalid-file': file && file.errors,
        'download-file': canDownload,
    });
    const blockClasses = cx('file-block', blockClassNames);
    const progressbarClasses = cx('progressbar', {
        'hidden': isFileLoaded || uploadedFile,
        'removed': file && file.errors,
    });

    const acceptedFiles = fileTypes.join(', ');
    const documentIcon = documentIconHandler(type);

    const progressbar = (
        <>
            {isFileLoaded && <div className={`${styles['status-icon']} ${styles['success-icon']}`}/>}
            {file && file.errors && <div className={`${styles['status-icon']} ${styles['error-icon']}`}/>}

            <div className={progressbarClasses}>
                <input type="range" min="0" max="100" ref={inputRangeRef} step="0.01" readOnly/>
            </div>
        </>
    );

    return (
        <div className={containerClasses}>
            <div className={blockClasses}>
                <div className={styles.icon} style={{backgroundImage: `url(${documentIcon})`}}/>
                <div className={styles.name}>
                    <EllipsisTextTooltip tooltip={name} contentClassNames={styles['name-tooltip']}/>
                </div>
                <div className={styles.size}>{size}</div>
                {canDelete ?
                    !fileDeleting
                        ? (
                            <div className={styles['file-delete']} onClick={onDelete}>
                                <span className={styles.delete}>{delete_btn}</span>
                            </div>
                        ) : (
                            <div className={styles['file-delete-loading']}>
                                <Preloader type="SECONDARY" classNames={styles['delete-preloader']}/>
                            </div>
                        )
                    : null}
                {canDownload && <a href={href} className={styles.download} download>
                    <div/>
                </a>}
                {!canDownload && progressbar}
            </div>

            {
                file && file.errors &&
                <div className={styles['error-message']}>
                    {file.errors[0].code === 'file-invalid-type' && `${error_message} ${acceptedFiles}`}
                    {file.errors[0].code === 'file-too-large' && `File is larger than ${fileSize} Mb`}
                </div>
            }
        </div>
    );
};

FileContainer.defaultProps = {
    fileTypes: ['.xls', '.xlsx'],
    canDownload: false,
    canDelete: true,
};

FileContainer.propTypes = {
    staticData: PropTypes.object,
    classNames: PropTypes.string,
    blockClassNames: PropTypes.string,
    file: PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string,
        href: PropTypes.string,
    }),
    uploadedFile: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        href: PropTypes.string,
    }),
    fileSize: PropTypes.number,
    fileProgress: PropTypes.number,
    fileTypes: PropTypes.arrayOf(PropTypes.string),
    fileDeleting: PropTypes.bool,
    isFileLoaded: PropTypes.bool,
    canDownload: PropTypes.bool,
    canDelete: PropTypes.bool,
    onDelete: PropTypes.func,
};

export default withDataService()(FileContainer);