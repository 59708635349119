import {createRequestHandler} from '../../../utils';

export const SECTION_PRODUCTS_REQUEST = 'SECTION_PRODUCTS_REQUEST';
export const SECTION_PRODUCTS_SUCCESS = 'SECTION_PRODUCTS_SUCCESS';
export const SECTION_PRODUCTS_FAILURE = 'SECTION_PRODUCTS_FAILURE';

export const sectionRequest = createRequestHandler('SECTION_PRODUCTS');

const initialState = {
    products: [],
    loading: true,
};

const section = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case SECTION_PRODUCTS_REQUEST:
            return initialState;

        case SECTION_PRODUCTS_SUCCESS:
            return {
                products: payload.products.list,
                loading: false,
            };

        case SECTION_PRODUCTS_FAILURE:
            return {
                products: [],
                loading: false,
            };

        default:
            return state;
    }
};

export default section;