import React from 'react';
import PropTypes from 'prop-types';

import Preloader from '../preloader';

import classnames from 'classnames/bind';
import styles from './button.module.scss';

const Button = (props) => {
    const cx = classnames.bind(styles);
    const {
        children, type, styleType, isTransformText, isDisabled,
        classNames, labelClassNames, preloaderClassNames,
        withTextEllipsis, dataLoading, onClick,
    } = props;

    const btnClasses = cx(
        'btn', classNames, {
            'primary': styleType === 'PRIMARY',
            'secondary': styleType === 'SECONDARY',
            'text': styleType === 'TEXT',
            'with-plus': styleType === 'WITH_PLUS',
            'with-minus': styleType === 'WITH_MINUS',
            'underline': styleType === 'UNDERLINE',
            'data-loading': dataLoading,
            'uppercase': isTransformText,
            'disabled': isDisabled,
            'label-overflow-ellipsis': withTextEllipsis,
        });

    const labelClasses = cx('label', labelClassNames);

    const preloaderClasses = cx('preloader', preloaderClassNames, {
        'type-text': styleType === 'TEXT',
        'type-secondary': styleType === 'SECONDARY',
    });

    return (
        <button type={type}
                className={btnClasses}
                onClick={onClick}
                disabled={isDisabled}>
            {styleType === 'SECONDARY' && (
                <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%"/>
                </svg>
            )}
            {(styleType === 'WITH_PLUS' || styleType === 'WITH_MINUS') && <div className={styles.icon}/>}

            <div className={styles['label-block']}>
                {dataLoading && (
                    <Preloader type="SECONDARY" classNames={preloaderClasses}/>
                )}
                <span className={labelClasses}>{children}</span>
            </div>
        </button>
    );
};

Button.defaultProps = {
    type: 'button',
    styleType: 'PRIMARY',
    isTransformText: true,
    dataLoading: false,
    withTextEllipsis: false,
    onClick: () => {
    },
};

Button.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    styleType: PropTypes.oneOf(['PRIMARY', 'SECONDARY', 'WITH_PLUS', 'WITH_MINUS', 'UNDERLINE', 'TEXT']),
    isTransformText: PropTypes.bool,
    isDisabled: PropTypes.bool,
    classNames: PropTypes.string,
    labelClassNames: PropTypes.string,
    preloaderClassNames: PropTypes.string,
    dataLoading: PropTypes.bool,
    withTextEllipsis: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Button;