import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {uploadFileHandler} from '../../../utils';

import Button from '../button';
import Dropzone from '../dropzone/dropzone';

import classnames from 'classnames/bind';
import styles from './button-constructor.module.scss';

const DropzoneButton = ({id, type, styleType, classNames, children}) => {
    const [state, setState] = useState({file: null, progress: 0, loaded: false});

    const onAcceptedFiles = (files) => {
        setState(state => ({...state, file: files[0]}));
        uploadFileHandler(files, onLoadingFile, onLoadedFile);
    };

    const onRejectedFiles = (files) => {
        if (files.length !== 0) {
            setState(state => ({...state, file: files[0]}));
        }
    };

    const onLoadingFile = (file, progress) => setState(state => ({...state, progress}));

    const onLoadedFile = () => setState(state => ({...state, loaded: true}));

    return !state.file
        ? (
            <Dropzone id={`${id}-${type}`}
                      containerClassNames={styles['dropzone-button']}
                      buttonStyleType={styleType}
                      buttonClassNames={classNames}
                      onAcceptedFiles={onAcceptedFiles}
                      onRejectedFiles={onRejectedFiles}
                      onlyButtonZone>
                {children}
            </Dropzone>
        )
        : null;
};

const ButtonConstructor = (props) => {
    const {type, label, remote, href, style, classNames, onClick, ...rest} = props;

    let buttonStyle = '';
    switch (style && style.length > 0) {
        case style.includes('solid'):
            buttonStyle = 'PRIMARY';
            break;

        case style.includes('border'):
            buttonStyle = 'SECONDARY';
            break;

        case style.includes('text'):
            buttonStyle = 'TEXT';
            break;

        default:
            buttonStyle = 'PRIMARY';
    }

    const cx = classnames.bind(styles);
    const buttonClasses = cx(classNames, {
        'btn': buttonStyle !== 'TEXT',
        'btn-success': style.includes('success'),
    });

    let baseParams = {
        styleType: buttonStyle,
        classNames: buttonClasses,
    };

    if (type === 'button') {
        if (remote) {
            return (
                <Button {...rest} {...baseParams}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClick(remote);
                        }}>
                    {label}
                </Button>
            );
        }
    }

    if (type === 'dropzone-button') {
        baseParams = {...baseParams, id: props.id, type};
        return <DropzoneButton {...baseParams}>{label}</DropzoneButton>;
    }

    return <Button {...rest} {...baseParams}>{label}</Button>;
};

DropzoneButton.propTypes = {
    id: PropTypes.any,
    type: PropTypes.string,
    styleType: PropTypes.string,
    classNames: PropTypes.string,
    children: PropTypes.node,
};

ButtonConstructor.defaultProps = {
    onClick: () => {
    },
};

ButtonConstructor.propTypes = {
    type: PropTypes.string,
    id: PropTypes.any,
    label: PropTypes.string,
    remote: PropTypes.shape({
        method: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string,
    }),
    style: PropTypes.arrayOf(PropTypes.oneOf(['solid', 'border', 'text', 'success'])),
    classNames: PropTypes.string,
    onClick: PropTypes.func,
};

export default ButtonConstructor;