import {about_independent_technical_surveying_en} from './en';
import {about_independent_technical_surveying_ru} from './ru';
import {about_independent_technical_surveying_es} from './es';
import {about_independent_technical_surveying_fr} from './fr';

export {
    about_independent_technical_surveying_en,
    about_independent_technical_surveying_ru,
    about_independent_technical_surveying_es,
    about_independent_technical_surveying_fr,
};