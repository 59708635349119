import {useMediaQuery} from 'react-responsive';

const useElementParameters = () => {
    const smDevice = useMediaQuery({minWidth: 576});
    const smDevice700 = useMediaQuery({minWidth: 700});
    const mdDevice = useMediaQuery({minWidth: 768});
    const mdDevice900 = useMediaQuery({minWidth: 900});
    const lgDevice = useMediaQuery({minWidth: 992});
    const lgDevice1100 = useMediaQuery({minWidth: 1100});
    const xlDevice = useMediaQuery({minWidth: 1200});
    const xlDevice1600 = useMediaQuery({minWidth: 1600});

    let carParallaxStyleObject = {
        position: 'absolute',
        zIndex: -2,
        right: 0,
        bottom: -1.7 + '%',
        width: 155 + '%',
        maxWidth: 630 + 'px',
    };

    let helicopterParallaxStyleObject = {
        position: 'absolute',
        right: 5 + '%',
        bottom: 70 + '%',
        zIndex: -1,
        width: 68 + '%',
        maxWidth: 280 + 'px',
    };

    let mountainParallaxStyleObject = {
        position: 'absolute',
        right: -12.5 + '%',
        bottom: 17 + '%',
        zIndex: -4,
        width: 100 + '%',
        maxWidth: 420 + 'px',
    };

    let radarParallaxStyleObject = {
        display: 'none',
    };

    /* from 576px to 767px */
    if (smDevice) {
        carParallaxStyleObject = {
            ...carParallaxStyleObject,
            width: 170 + '%',
            maxWidth: 700 + 'px',
        };

        helicopterParallaxStyleObject = {
            ...helicopterParallaxStyleObject,
            right: 10 + '%',
            bottom: 68 + '%',
            maxWidth: 330 + 'px',
        };

        mountainParallaxStyleObject = {
            ...mountainParallaxStyleObject,
            maxWidth: 490 + 'px',
        };
    }

    if (smDevice700) {
        helicopterParallaxStyleObject = {
            ...helicopterParallaxStyleObject,
            maxWidth: 330 + 'px',
        };

        mountainParallaxStyleObject = {
            ...mountainParallaxStyleObject,
            maxWidth: 490 + 'px',
        };
    }

    /* from 768px to 899px */
    if (mdDevice) {
        carParallaxStyleObject = {
            ...carParallaxStyleObject,
            bottom: 0,
            maxWidth: 750 + 'px',
        };

        helicopterParallaxStyleObject = {
            ...helicopterParallaxStyleObject,
            right: 16 + '%',
            bottom: 65 + '%',
            maxWidth: 340 + 'px',
        };

        mountainParallaxStyleObject = {
            ...mountainParallaxStyleObject,
            right: -6 + '%',
            bottom: 19 + '%',
            maxWidth: 500 + 'px',
        };

        radarParallaxStyleObject = {
            ...radarParallaxStyleObject,
            position: 'absolute',
            bottom: 38 + '%',
            right: 50 + '%',
            display: 'block',
            width: 50 + '%',
            maxWidth: 400 + 'px',
        };
    }

    /* from 900px to 992px */
    if (mdDevice900) {
        helicopterParallaxStyleObject = {
            ...helicopterParallaxStyleObject,
            bottom: 63 + '%',
        };

        radarParallaxStyleObject = {
            ...radarParallaxStyleObject,
            right: 53 + '%',
            bottom: 40 + '%',
        };
    }

    /* from 992px to 1099px */
    if (lgDevice) {
        carParallaxStyleObject = {
            ...carParallaxStyleObject,
            maxWidth: 920 + 'px',
        };

        helicopterParallaxStyleObject = {
            ...helicopterParallaxStyleObject,
            right: 18 + '%',
            bottom: 67 + '%',
            maxWidth: 380 + 'px',
        };

        mountainParallaxStyleObject = {
            ...mountainParallaxStyleObject,
            bottom: 22 + '%',
            maxWidth: 600 + 'px',
        };

        radarParallaxStyleObject = {
            ...radarParallaxStyleObject,
            maxWidth: 470 + 'px',
        };
    }

    if (lgDevice1100) {
        carParallaxStyleObject = {
            ...carParallaxStyleObject,
            maxWidth: 1000 + 'px',
        };

        mountainParallaxStyleObject = {
            ...mountainParallaxStyleObject,
            bottom: 24 + '%',
            maxWidth: 630 + 'px',
        };

        radarParallaxStyleObject = {
            ...radarParallaxStyleObject,
            maxWidth: 520 + 'px',
        };
    }

    /* from 1200px */
    if (xlDevice) {
        carParallaxStyleObject = {
            ...carParallaxStyleObject,
            maxWidth: 1150 + 'px',
        };

        helicopterParallaxStyleObject = {
            ...helicopterParallaxStyleObject,
            maxWidth: 450 + 'px',
        };

        mountainParallaxStyleObject = {
            ...mountainParallaxStyleObject,
            right: -3 + '%',
            maxWidth: 670 + 'px',
        };

        radarParallaxStyleObject = {
            ...radarParallaxStyleObject,
            right: 55 + '%',
            maxWidth: 550 + 'px',
        };
    }

    /* from 1600px */
    if (xlDevice1600) {
        carParallaxStyleObject = {
            ...carParallaxStyleObject,
            maxWidth: 1300 + 'px',
        };

        helicopterParallaxStyleObject = {
            ...helicopterParallaxStyleObject,
            right: 20 + '%',
            bottom: 65 + '%',
            maxWidth: 550 + 'px',
        };

        mountainParallaxStyleObject = {
            ...mountainParallaxStyleObject,
            maxWidth: 765 + 'px',
        };

        radarParallaxStyleObject = {
            ...radarParallaxStyleObject,
            bottom: 42 + '%',
            maxWidth: 625 + 'px',
        };
    }

    return {
        carParallaxStyleObject,
        helicopterParallaxStyleObject,
        mountainParallaxStyleObject,
        radarParallaxStyleObject,
    };
};

export {useElementParameters};