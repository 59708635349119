import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../hoc';
import {utilsAction} from '../../../actions/common';
import {staticURLs} from '../../../services/api-urls';

import Button from '../button';
import Plane from './svg-components/plane';
import Gears from './svg-components/gears';

import classnames from 'classnames/bind';
import styles from './error-indicator.module.scss';

class ErrorIndicator extends Component {
    render() {
        const {
            staticData, type, position, notFoundError,
            notAcceptableError, outsideAction, onClick,
        } = this.props;

        const cx = classnames.bind(styles);
        let currentType = 'not_found';
        let imageClasses = cx('image', 'plane-svg');
        let image = <Plane classNames={imageClasses}/>;

        switch (type) {
            case 'SERVICE_UNDER_DEVELOPMENT':
                currentType = 'service_under_development';
                imageClasses = cx('image', 'gears-svg');
                image = <Gears classNames={imageClasses}/>;
                break;

            case 'INTERNAL_SERVER_ERROR':
                currentType = 'internal_server_error';
                imageClasses = cx('image', 'gears-svg');
                image = <Gears classNames={imageClasses}/>;
                break;

            case 'ROUTE_NOT_FOUND':
            case 'NOT_ACCEPTABLE':
                currentType = 'route_not_found';
                break;

            default:
                break;
        }

        let text = staticData.error_page[currentType];
        let {title, button: {go_to_homepage}} = staticData.error_page;
        let btn_text = go_to_homepage;
        if (type === 'INTERNAL_SERVER_ERROR') {
            text = text.map((item, idx) => {
                const classes = cx('text', {'subtext': idx !== 0});
                return <div key={idx} className={classes}>{item}</div>;
            });
        }
        if (type === 'NOT_FOUND') {
            text = notFoundError;
        }

        if (type === 'NOT_ACCEPTABLE') {
            text = notAcceptableError;
        }

        const pageClasses = cx('container', {
            'not-found': type === 'ROUTE_NOT_FOUND' || type === 'NOT_FOUND' || type === 'NOT_ACCEPTABLE',
            'internal-server-error': type === 'INTERNAL_SERVER_ERROR',
            'position-container': position === 'CONTAINER',
        });

        return (
            <div className={pageClasses}>
                <div className={styles.content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.text}>{text}</div>
                    <Button classNames={styles.btn} onClick={() => {
                        if (outsideAction) {
                            onClick();
                        } else {
                            this.props.history.replace(staticURLs.home);
                        }
                    }}>
                        {btn_text}
                    </Button>
                    {image}
                </div>
            </div>
        );
    }

    static defaultProps = {
        type: 'ROUTE_NOT_FOUND',
        position: 'CONTAINER',
        outsideAction: false,
        onClick: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        type: PropTypes.oneOf([
            'ROUTE_NOT_FOUND',
            'NOT_FOUND',
            'NOT_ACCEPTABLE',
            'SERVICE_UNDER_DEVELOPMENT',
            'INTERNAL_SERVER_ERROR',
        ]),
        position: PropTypes.oneOf(['BODY', 'CONTAINER']),
        outsideAction: PropTypes.bool,
        notFoundError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        notAcceptableError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        onClick: PropTypes.func,
        updateBreadcrumbs: PropTypes.func,
    };
}

const mapStateToProps = ({utils}) => ({
    notFoundError: utils.notFoundError,
    notAcceptableError: utils.notAcceptableError,
});

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        updateBreadcrumbs: utilsAction.updateBreadcrumbs,
    }, dispatch)
);

export default compose(
    withRouter,
    withDataService(),
    connect(mapStateToProps, mapDispatchToProps),
)(ErrorIndicator);