import {errorURLs, hubURLs, mainPageUrl, marketplaceURLs, staticURLs, URLs} from './api-urls';

export const breadcrumbsURLs = {
    notifications: [hubURLs.notifications],
    profile: [hubURLs.profile],
    access_setup: [hubURLs.profile, hubURLs.access_setup],
    bank_account_create: [hubURLs.profile, `${hubURLs.profile_bank}/create`],
    bank_account_update: [hubURLs.profile, `${hubURLs.profile_bank}/update`],
    consignee_details_create: [hubURLs.profile, `${hubURLs.profile_consignee}/create`],
    consignee_details_update: [hubURLs.profile, `${hubURLs.profile_consignee}/update`],
    exporter_forwarder_create: [hubURLs.profile, `${hubURLs.profile_exporterforwarder}/create`],
    exporter_forwarder_update: [hubURLs.profile, `${hubURLs.profile_exporterforwarder}/update`],
    importer_forwarder_create: [hubURLs.profile, `${hubURLs.profile_importerforwarder}/create`],
    importer_forwarder_update: [hubURLs.profile, `${hubURLs.profile_importerforwarder}/update`],
    notify_party_create: [hubURLs.profile, `${hubURLs.profile_notifyparty}/create`],
    notify_party_update: [hubURLs.profile, `${hubURLs.profile_notifyparty}/update`],
    shipper_create: [hubURLs.profile, `${hubURLs.profile_shipper}/create`],
    shipper_update: [hubURLs.profile, `${hubURLs.profile_shipper}/update`],
    quotation_excel: [marketplaceURLs.marketplace, marketplaceURLs.quotation, marketplaceURLs.quotation_excel],
    quotation_excel_format: [marketplaceURLs.marketplace, marketplaceURLs.quotation, marketplaceURLs.quotation_excel, marketplaceURLs.quotation_excel_format],
    independent_technical_surveying: [URLs.independent_technical_surveying],
    core_services: [URLs.core_services],
    terms_and_conditions: [staticURLs.terms_and_conditions],
    privacy_policy: [staticURLs.privacy_policy],
    request_access_page: [staticURLs.request_access],
    not_found_page: [errorURLs.not_found],
    not_acceptable_page: [errorURLs.not_acceptable],
    internal_server_error_page: [errorURLs.internal_server_error],
    contract_for_supply_invoice: ['#'],
    contract_for_supply_packing_list: ['#'],
    contract_for_supply_labelling: ['#'],
    document_page: ['#'],
};

export default class BreadcrumbsConstructor {
    checkingBreadcrumbsStatic = (location, routes, currentList, errors) => {
        const {
            bank_account_update,
            consignee_details_update,
            exporter_forwarder_update,
            importer_forwarder_update,
            notify_party_update,
            shipper_update, ...restRoutes
        } = routes;

        const profileRoutesWithIdParams = {
            bank_account_update,
            consignee_details_update,
            exporter_forwarder_update,
            importer_forwarder_update,
            notify_party_update,
            shipper_update,
        };

        let list = currentList;
        let result = false;

        /* Is it url from profileRoutesWithIdParams? */
        const transformPathname = location.pathname.split('/').slice(0, -1).join('/');
        for (let key in profileRoutesWithIdParams) {
            if (profileRoutesWithIdParams.hasOwnProperty(key)) {
                result = transformPathname === profileRoutesWithIdParams[key].href;

                if (result) {
                    return profileRoutesWithIdParams[key].list;
                }
            }
        }

        /* Is it static page? */
        for (let key in restRoutes) {
            if (restRoutes.hasOwnProperty(key)) {
                result = location.pathname === restRoutes[key].href;

                if (result) {
                    if (key === 'not_acceptable_page') {
                        return errors.notAcceptableError ? restRoutes[key].list : restRoutes['not_found_page'].list;
                    }

                    if (key === 'internal_server_error_page') {
                        return errors.serverError ? restRoutes[key].list : restRoutes['not_found_page'].list;
                    }

                    return restRoutes[key].list;
                }
            }
        }

        return list;
    };

    objectHandler = ({href, labels}, firstLinkLabel, session) => {
        session = session || false;
        labels = [firstLinkLabel, ...labels];
        const list = labels.map((item, idx) => ({
            label: idx === 0 ? firstLinkLabel : item,
            href: idx === 0 ? mainPageUrl(session) : href[idx - 1],
            isActive: idx !== labels.length - 1,
        }));

        return {href: href[href.length - 1], list};
    };

    creator(obj, firstLinkLabel, session) {
        const keys = Object.keys(obj);
        let transformObj = {};

        keys.forEach(key => {
            const value = this.objectHandler({
                href: breadcrumbsURLs[key],
                labels: obj[key],
            }, firstLinkLabel, session);

            transformObj = {
                ...transformObj,
                [key]: value,
            };
        });

        return transformObj;
    }
}