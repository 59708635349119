import {createRequestHandler} from '../utils';
import {RESET_REQUEST_LOCK} from './index';
import {CLOSE_SIGN_IN, LOGIN_REQUEST} from './login';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export const PROFILE_UPDATING_REQUEST = 'PROFILE_UPDATING_REQUEST';
export const PROFILE_UPDATING_SUCCESS = 'PROFILE_UPDATING_SUCCESS';
export const PROFILE_UPDATING_FAILURE = 'PROFILE_UPDATING_FAILURE';

export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';

export const profileRequest = createRequestHandler('PROFILE');
export const updatingProfileRequest = createRequestHandler('PROFILE_UPDATING');

const initialState = {
    language: 'en',
    languagesList: [],
    userInteraction: null,
    profile: null,
    loading: true,
    updating: false,
    error: null,
};

const updateLanguage = (payload) => {
    const {language: languages} = payload;

    const selectedLanguageIdx = languages.findIndex(({isActive}) => isActive);
    return languages[selectedLanguageIdx].code;
};

const updateInteraction = (payload) => {
    const {user} = payload;

    let interaction = null;
    if (user && user.interaction) {
        interaction = user.interaction;
    }

    return interaction;
};

const profile = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case PROFILE_REQUEST:
            return initialState;

        case PROFILE_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case PROFILE_SUCCESS:
        case PROFILE_UPDATING_SUCCESS:
            const newLanguage = updateLanguage(payload);
            const userInteraction = updateInteraction(payload);

            return {
                ...initialState,
                language: newLanguage,
                languagesList: payload.language,
                profile: payload.user,
                userInteraction: userInteraction,
                loading: false,
            };

        case PROFILE_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case PROFILE_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case CLOSE_SIGN_IN:
        case LOGIN_REQUEST:
        case RESET_REQUEST_LOCK:
            return {
                ...state,
                error: null,
            };

        case SWITCH_LANGUAGE:
            return {
                ...state,
                language: payload,
            };

        default:
            return state;
    }
};

export default profile;