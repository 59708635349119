import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({type, isActive, isDisable}) => {
    const fillColor = isActive
        ? isDisable ? '#c4c4c4' : '#333333'
        : isDisable ? '#c4c4c4' : '#000000';

    const fillOpacity = isActive ? "1" : "0.6";

    if (type === 'DOWN') {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.3333 8.00065L12.3933 7.06065L8.66665 10.7807V2.66732H7.33331L7.33331 10.7807L3.61331 7.05398L2.66665 8.00065L7.99998 13.334L13.3333 8.00065Z"
                      fill={fillColor} fillOpacity={fillOpacity}/>
            </svg>
        );
    }

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.66669 7.99935L3.60669 8.93935L7.33335 5.21935V13.3327H8.66669V5.21935L12.3867 8.94602L13.3334 7.99935L8.00002 2.66602L2.66669 7.99935Z"
                  fill={fillColor} fillOpacity={fillOpacity}/>
        </svg>
    );
};

Arrow.defaultProps = {
    type: 'UP',
    isActive: false,
    isDisable: false,
};

Arrow.propTypes = {
    type: PropTypes.string,
    isActive: PropTypes.bool,
    isDisable: PropTypes.bool,
};

export default Arrow;