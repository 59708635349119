import {combineReducers} from 'redux';

import rfsProducts from './request-for-supply/rfs-products';
import removeItemFromRFS from './request-for-supply/remove-item-from-rfs';
import replaceItemInRFS from './request-for-supply/replace-item-in-rfs';
import countOfItem from './request-for-supply/сount-of-item';
import confirmRFS from './request-for-supply/confirm-rfs';
import clearAllRFS from './request-for-supply/clear-all-rfs';
import xlsFormOptions from './xls-form-options';

const quotationReducer = combineReducers({
    rfsProducts,
    removeItemFromRFS,
    replaceItemInRFS,
    countOfItem,
    confirmRFS,
    clearAllRFS,
    xlsFormOptions,
});

export default quotationReducer;