import React, {forwardRef} from 'react';
import {useMediaQuery} from 'react-responsive';
import PropTypes from 'prop-types';

import {withOtherOffers} from '../hoc';

import ItemCounter from '../../../../../../../common/item-counter';
import Button from '../../../../../../../common/button';
import Image from '../../../../../../../common/image';
import Preloader from '../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './request-item.module.scss';

const RequestItem = forwardRef((props, ref) => {
    const {
        staticData, itemNumber, id, name, image,
        price, total, currency, quantity, maxQuantity, main_data,
        otherOffers, isFindOtherOffersOpen, onFindOtherOffers,
        removingItem, onRemoveItem, replacingItem,
        countingItem, onQuantityChange, resetItemCounter,
    } = props;

    const {
        product_image_alt,
        request_for_supply: {find_other_offers_text, price_label},
        button: {delete_btn},
    } = staticData.marketplace.quotation;

    const onUpdateQuantity = (quantity) => {
        if (quantity) {
            onQuantityChange({
                offer_id: id,
                quantity,
                currency,
            });
        }
    };

    const cx = classnames.bind(styles);
    const findOtherOfferClasses = cx('show-offers', {'active': isFindOtherOffersOpen});

    const mdDevice = useMediaQuery({minWidth: 768});

    const priceBlock = (
        <div className={styles['price-block']}>
            <div className={styles['subtotal-block']}>
                <div className={styles.price}>
                    {price} {currency} <span className={styles.mark}>{price_label}</span>
                </div>

                <ItemCounter classNames={styles.counter}
                             value={quantity}
                             maxValue={maxQuantity}
                             tooltipPlacement={mdDevice ? 'bottom' : 'bottom-start'}
                             onCountChange={onUpdateQuantity}
                             resetItemCounter={resetItemCounter}
                             updateDataOnChange
                />
            </div>

            <div className={styles['total-block']}>
                <div className={styles.total}>{total} {currency}</div>
                {otherOffers && (
                    <Button styleType="TEXT" classNames={findOtherOfferClasses}
                            onClick={onFindOtherOffers}
                            withTextEllipsis>
                        {find_other_offers_text}
                    </Button>
                )}
            </div>
        </div>
    );

    const deleteBtnBlock = (
        <div className={styles['delete-btn-block']}>
            {removingItem.loading && removingItem.id === id && (
                <Preloader type="SECONDARY"
                           classNames={styles['delete-preloader']}/>)}

            <Button styleType="UNDERLINE" classNames={styles.delete}
                    onClick={() => onRemoveItem(id)}>
                {mdDevice
                    ? (<>
                        <div className={styles['cross-icon']}/>
                        {delete_btn}
                    </>)
                    : <div className={styles['trash-icon']}/>}
            </Button>
        </div>
    );

    const requestItemClasses = cx('container', {
        'loading': (replacingItem.loading && id === replacingItem.id)
            || (countingItem.loading && id === countingItem.id),
        'hiding-items': (replacingItem.loading && id !== replacingItem.id)
            || (countingItem.loading && id !== countingItem.id),
    });

    return (
        <div ref={ref} className={requestItemClasses}>
            <div className={styles.hiding}>
                <Preloader type="SECONDARY"
                           classNames={styles.preloader}/>
            </div>
            <div className={`${styles.number} ${styles['number-lg']}`}>{`${itemNumber}.`}</div>
            <Image source={image}
                   classNames={`${styles.img} ${styles['img-lg']}`}
                   skeletonClassNames={`${styles['img-skeleton']} ${styles['img-skeleton-lg']}`}
                   alt={product_image_alt}
            />

            <div className={styles['data-column']}>
                <div className={styles['title-row']}>
                    <div className={styles.number}>{`${itemNumber}.`}</div>
                    <Image source={image} classNames={styles.img}
                           skeletonClassNames={styles['img-skeleton']}
                           alt={product_image_alt}/>
                    <div className={styles.title}>{name}</div>

                    {priceBlock}
                    {deleteBtnBlock}
                </div>

                <div className={styles['data-row']}>
                    {priceBlock}
                    <div className={styles.description}>
                        {main_data.map(({field, label, value}, idx) => {
                            return (
                                <div className={styles.item} key={idx}>
                                    <div className={styles.label}>{field || label}</div>
                                    <div className={styles.value}>{value}</div>
                                </div>
                            );
                        })}
                    </div>

                    <div className={styles['btn-block']}>
                        {otherOffers && (
                            <Button styleType="TEXT" classNames={findOtherOfferClasses}
                                    onClick={onFindOtherOffers}
                                    withTextEllipsis>
                                {find_other_offers_text}
                            </Button>
                        )}

                        {deleteBtnBlock}
                    </div>
                </div>
            </div>
        </div>
    );
});

RequestItem.defaultProps = {
    onFindOtherOffers: () => {
    },
};

RequestItem.propTypes = {
    staticData: PropTypes.object,
    itemNumber: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.object,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
    quantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    main_data: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    otherOffers: PropTypes.bool,
    isFindOtherOffersOpen: PropTypes.bool,
    onFindOtherOffers: PropTypes.func,
    removingItem: PropTypes.shape({
        id: PropTypes.number,
        loading: PropTypes.bool,
    }),
    replacingItem: PropTypes.shape({
        id: PropTypes.number,
        loading: PropTypes.bool,
    }),
    countingItem: PropTypes.shape({
        id: PropTypes.number,
        loading: PropTypes.bool,
    }),
    onRemoveItem: PropTypes.func,
    onQuantityChange: PropTypes.func,
};

export default withOtherOffers()(RequestItem);