import React from 'react';
import PropTypes from 'prop-types';

import Dropzone from '../../../../common/dropzone';
import FileContainer from '../../../../common/file-container/file-container';

import styles from './upload-documents.module.scss';

const UploadDocuments = (props) => {
    const {
        dropzoneName,
        documents,
        deleteAlreadyUploadedFile,
        onDeleteFile,
        onDeleteRejectedFile,
        onDeletePartUploadedFileFile,
        onDeleteAlreadyExistFile,
        ...rest
    } = props;
    let uploadDocumentsForm = [];

    if (documents) {
        const {
            sectionId, section, defaultProps, maxCount, maxSize,
            files, rejectedFiles, partUploadedFiles, alreadyUploadedFiles,
        } = documents;

        const documentTypes = defaultProps.mimes;
        const amountOfUploadedFiles = files.length + alreadyUploadedFiles.length + partUploadedFiles.length;

        let alreadyUploaded = null;
        let partUploaded = null;
        let accepted = null;
        let rejected = null;

        if (alreadyUploadedFiles.length > 0 || partUploadedFiles.length > 0) {
            alreadyUploaded = alreadyUploadedFiles.map((file, idx) => {
                let fileDeleting = false;

                if (deleteAlreadyUploadedFile) {
                    if (deleteAlreadyUploadedFile.dropzoneName === dropzoneName) {
                        const {id, loading} = deleteAlreadyUploadedFile;
                        fileDeleting = loading && file.id === id;
                    }
                }

                // need work for href on server
                file = {
                    ...file,
                    href: `${process.env.REACT_APP_API_URL}${file.href}`,
                };

                return (
                    <FileContainer key={`already_uploaded_${idx}`}
                                   classNames={styles.file}
                                   uploadedFile={file}
                                   fileTypes={documentTypes}
                                   fileSize={maxSize}
                                   fileDeleting={fileDeleting}
                                   onDelete={() => onDeleteAlreadyExistFile(file.id)}
                                   canDownload/>
                );
            });
        }

        if (partUploadedFiles.length > 0) {
            partUploaded = partUploadedFiles.map((file, idx) => {
                return (
                    <FileContainer key={`partially_uploaded_${idx}`}
                                   classNames={styles.file}
                                   uploadedFile={file}
                                   fileTypes={documentTypes}
                                   fileSize={maxSize}
                                   onDelete={() => onDeletePartUploadedFileFile(idx)}
                                   isFileLoaded/>
                );
            });
        }

        if (files.length > 0) {
            accepted = files.map((file, idx) => {
                let params = {
                    key: `accepted_to_upload_${idx}`,
                    classNames: styles.file,
                    fileTypes: documentTypes,
                    fileSize: maxSize,
                    file: file.file,
                    fileProgress: file.progress,
                    isFileLoaded: file.isLoaded,
                    onDelete: () => onDeleteFile(idx),
                };

                return <FileContainer {...params}/>;
            });
        }

        if (rejectedFiles.length > 0) {
            rejected = rejectedFiles.map((file, idx) => {
                return (
                    <FileContainer key={`rejected_to_upload_${idx}`}
                                   classNames={styles.file}
                                   file={file}
                                   fileTypes={documentTypes}
                                   fileSize={maxSize}
                                   onDelete={() => onDeleteRejectedFile(idx)}
                    />
                );
            });
        }

        uploadDocumentsForm = (
            <>
                <div className={styles.title}>{section}</div>
                <div className={styles.container}>
                    <Dropzone {...rest}
                              id={sectionId}
                              fileTypes={documentTypes}
                              maxFiles={maxCount}
                              maxSize={maxSize}
                              isDisabled={amountOfUploadedFiles >= defaultProps.maxCount}
                              multiple/>
                    <div>
                        {alreadyUploaded}
                        {partUploaded}
                        {accepted}
                        {rejected}
                    </div>
                </div>
            </>
        );
    }

    return uploadDocumentsForm;
};

UploadDocuments.defaultProps = {
    onDeleteFile: () => {
    },
    onDeleteRejectedFile: () => {
    },
    onDeletePartUploadedFileFile: () => {
    },
    onDeleteAlreadyExistFile: () => {
    },
};

UploadDocuments.propTypes = {
    dropzoneName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    documents: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
        sectionId: PropTypes.string,
        section: PropTypes.string,
        defaultProps: PropTypes.shape({
            maxCount: PropTypes.number,
            mimes: PropTypes.array,
        }),
        maxCount: PropTypes.number,
        maxSize: PropTypes.number,
        files: PropTypes.array,
        rejectedFiles: PropTypes.array,
        alreadyUploadedFiles: PropTypes.array,
    })]),
    deleteAlreadyUploadedFile: PropTypes.object,
    onDeleteFile: PropTypes.func,
    onDeleteRejectedFile: PropTypes.func,
    onDeletePartUploadedFileFile: PropTypes.func,
    onDeleteAlreadyExistFile: PropTypes.func,
};

export default UploadDocuments;