export default class OriginInvoiceService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/contract';
    }

    getOriginInvoice = (id) => {
        return this._http.get(`${this.baseUrl}/${id}/invoice/create`);
    };

    createOriginInvoice = (id, body) => {
        return this._http.post(`${this.baseUrl}/${id}/invoice/create`, body);
    };
}