import {FORBIDDEN, NOT_FOUND, SERVER_ERROR, NOT_ACCEPTABLE} from '../services/http-service';
import {utilsAction, redirectAfterDenialOfAccess} from '../actions/common';

const onSuccess = (dispatch, getState, requestName, response, options) => {
    const {data, request_args} = response;
    const stateBefore = getState();

    if (data && data.error) {
        throw response;
    }

    if (data && data.temporary_redirect) {
        window.location.assign(data.url);
    }

    if (data && data.breadcrumbs) {
        dispatch(utilsAction.updateBreadcrumbs(data.breadcrumbs));
    }

    if (stateBefore.utils.serverError && stateBefore.utils.prevLocation) {
        dispatch(utilsAction.setServerError());
    }

    if (data) {
        let payload = data;
        if (!Array.isArray(data) && typeof data === 'object') {
            const {breadcrumbs, ...rest} = data;
            payload = rest;

            /** in case where we get some list of item sometimes
             * we need to use current language for changing data */
            if (options.language) {
                payload = {...rest, language: options.language};
            }

            if (request_args) {
                payload = {
                    ...payload,
                    request_args,
                };
            }

        } else {
            if (request_args) {
                payload = {
                    data,
                    request_args,
                };
            }
        }

        dispatch({
            type: `${requestName}_SUCCESS`,
            payload,
        });

    } else {
        if (request_args) {
            dispatch({
                type: `${requestName}_SUCCESS`,
                payload: {request_args},
            });

        } else {
            dispatch({type: `${requestName}_SUCCESS`});
        }
    }
};

const onError = (dispatch, getState, requestName, error, options) => {
    const {status_code, data, access_right, error: dataError, request_args} = error;
    const stateBefore = getState();

    if (!access_right && access_right !== null && access_right !== undefined) {
        dispatch({type: 'SESSION_EXPIRED'});

    } else {
        let payloadData = data ? data.error : dataError || error;

        if (request_args) {
            payloadData = {
                error: payloadData,
                request_args,
            };
        }

        // need work with error and add logic with options params
        const needCheckWithServerError = ['REGISTRATION'];
        const notNeedCheckWithServerError = [
            'COUNTRIES_PUBLIC',
            'PROFILE', 'PROFILE_UPDATING',
            'NOTIFICATIONS', 'NOTIFICATIONS_UPDATING',
            'ON_VIEW_NOTIFICATION', 'ON_DELETE_NOTIFICATION',
        ];

        const excludedRequestNames = [
            ...needCheckWithServerError,
            ...notNeedCheckWithServerError,
        ];

        if (!excludedRequestNames.includes(requestName)) {
            // need work, because this case work not correctly with session expired and simple forbidden response when session is active
            if (status_code === FORBIDDEN && options.forbiddenErrorRedirect) {
                dispatch(redirectAfterDenialOfAccess(true));
            }

            if (status_code === NOT_FOUND) {
                dispatch(utilsAction.setNotFoundError(payloadData));
            }

            if (status_code === NOT_ACCEPTABLE && options.notAcceptableErrorRedirect) {
                dispatch(utilsAction.setNotAcceptableError(payloadData));
            }
        }

        if (!notNeedCheckWithServerError.includes(requestName) && options.setGlobalServerError) {
            const {serverError} = stateBefore.utils;
            if (status_code === SERVER_ERROR) {
                if (!serverError) {
                    dispatch(utilsAction.setServerError(payloadData));
                }

            } else {
                if (serverError) {
                    dispatch(utilsAction.setServerError());
                }
            }
        }

        if (options.language) {
            dispatch({
                type: `${requestName}_FAILURE`,
                payload: {data: payloadData, language: options.language},
            });

        } else {
            dispatch({
                type: `${requestName}_FAILURE`,
                payload: payloadData,
            });
        }
    }
};

const createRequestHandler = (requestName, options) => {
    return (request) => (...args) => (dispatch, getState) => {
        const stateBefore = getState();
        const {
            session: {session},
            publicData: {homePage: {language: homeLanguage}},
            profile: {language: profileLanguage, error: profileError},
            utils: {serverError}
        } = stateBefore;

        const defaultOptions = {
            language: null,
            notAcceptableErrorRedirect: true,
            setGlobalServerError: true,
            forbiddenErrorRedirect: true, // need work
        };

        if (options && options.language) {
            const language = session ? profileLanguage : homeLanguage;
            options = {...options, language};
        }

        options = options ? {...defaultOptions, ...options} : defaultOptions;

        if ((!profileError && !serverError) || requestName === 'LOGOUT') {
            dispatch({type: `${requestName}_REQUEST`});

            request(...args)
                .then((payload) => onSuccess(dispatch, getState, requestName, payload, options))
                .catch((error) => onError(dispatch, getState, requestName, error, options));
        }
    };
};

export default createRequestHandler;