import React, {Component} from 'react';

import ErrorIndicator from '../common/error-indicator';

class Careers extends Component {
    render() {
        return <ErrorIndicator type="SERVICE_UNDER_DEVELOPMENT"/>;
    }
}

export default Careers;