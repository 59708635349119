export default class ActivityHubService {
    constructor(httpService) {
        this._http = httpService;
    }

    sendPOSTRequestOnButtonUrl = (url) => {
        return this._http.post(url);
    };

    removeAttachmentFile = (id) => {
        return this._http.post(`/remove/attach/${id}`);
    };
}