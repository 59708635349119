import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import store, {history} from './store';
import getReduxStore from './utils/get-redux-store';

import ServiceRegistry from './components/common/service-registry';
import ErrorBoundry from './components/common/error-boundry';
import {App} from './components/app';

import './styles/index.scss';

history.listen((location, action) => {
    let {utils: {errorBoundry}} = getReduxStore();

    if (errorBoundry) {
        window.location.reload();
    } else {
        if (action !== 'POP') {
            window.scrollTo(0, 0);
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ErrorBoundry>
                <ServiceRegistry>
                    <ConnectedRouter history={history}>
                        <App/>
                    </ConnectedRouter>
                </ServiceRegistry>
            </ErrorBoundry>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

/*
    <Provider /> - sets up a subscription to the store and commits all changes,
     so that we know when to update our application
*/
