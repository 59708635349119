import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classnames from 'classnames/bind';
import styles from './card-slider.module.scss';

const CardSliderArrow = ({side, className, style, withCloseBtn, onClick}) => {
    const cx = classnames.bind(styles);
    const arrowClasses = cx('arrow', className, {
        'arrow-left': side === 'left',
        'arrow-left-shift': side === 'left' && withCloseBtn,
        'arrow-right-shift': side === 'right' && withCloseBtn,
    });

    return <div className={arrowClasses}
                style={{...style}}
                onClick={onClick}/>;
};

const CardSlider = (props) => {
    const {
        title, containerClassNames, titleClassNames, breakpoints, children,
        closeBtnClassNames, withCloseBtn, onCloseSlider,
    } = props;

    const cx = classnames.bind(styles);

    const responsiveBreakpoints = breakpoints.map((item) => {
        return {
            breakpoint: item.screenSize,
            settings: {
                slidesToShow: item.slidesToShow,
                slidesToScroll: item.slidesToScroll,
            },
        };
    });

    const settings = {
        slidesToShow: 2.5,
        slidesToScroll: 2,
        infinite: false,
        swipeToSlide: true,
        nextArrow: <CardSliderArrow withCloseBtn={withCloseBtn}/>,
        prevArrow: <CardSliderArrow side="left" withCloseBtn={withCloseBtn}/>,
        responsive: responsiveBreakpoints,
    };

    const containerClasses = cx('container', containerClassNames);
    const titleClasses = cx('title', titleClassNames);
    const closeBtnClasses = cx('container-close-btn', closeBtnClassNames);

    return (
        <div className={containerClasses}>
            {withCloseBtn && <div className={closeBtnClasses} onClick={onCloseSlider}/>}
            <div className={titleClasses}>{title}</div>
            <Slider {...settings} className={styles.slider}>
                {children}
            </Slider>
        </div>
    );
};

CardSliderArrow.defaultProps = {
    side: 'right',
    withCloseBtn: false,
};

CardSliderArrow.propTypes = {
    side: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
    style: PropTypes.object,
    withCloseBtn: PropTypes.bool,
    onClick: PropTypes.func,
};

CardSlider.defaultProps = {
    withCloseBtn: false,
    onCloseSlider: () => {
    },
};

CardSlider.propTypes = {
    title: PropTypes.string,
    containerClassNames: PropTypes.string,
    titleClassNames: PropTypes.string,
    breakpoints: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.node,
    closeBtnClassNames: PropTypes.string,
    withCloseBtn: PropTypes.bool,
    onCloseSlider: PropTypes.func,
};

export default CardSlider;