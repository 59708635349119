import {createRequestHandler, createAlertResponse} from '../../../utils';

export const CREATE_E_CONTRACT_REQUEST = 'CREATE_E_CONTRACT_REQUEST';
export const CREATE_E_CONTRACT_SUCCESS = 'CREATE_E_CONTRACT_SUCCESS';
export const CREATE_E_CONTRACT_FAILURE = 'CREATE_E_CONTRACT_FAILURE';

export const createContractRequest = createRequestHandler('CREATE_E_CONTRACT');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const createContract = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case CREATE_E_CONTRACT_REQUEST:
            return {
                loading: true,
                message: null,
                error: null,
            };

        case CREATE_E_CONTRACT_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case CREATE_E_CONTRACT_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                loading: false,
                message: null,
                error,
            };

        case 'RESET_CREATE_E_CONTRACT':
            return initialState;

        default:
            return state;
    }
};

export default createContract;