import React from 'react';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../common/hoc';
import {priceHandler} from '../../../../../../../../utils';
import {withGridArea} from '../../../../../../../marketplace/components/common/hoc';

import Image from '../../../../../../../common/image';
import Tooltip from '../../../../../../../common/tooltip';
import OptionsMenu from '../options-menu';
import ProductStatus from '../product-status';
import InfoMark from '../../../../../../../common/info-mark';
import Preloader from '../../../../../../../common/preloader';

import classnames from 'classnames/bind';
import styles from './product-card.module.scss';

import {colors} from '../../../../../../../common/colors';
import defaultProductImage from '../../../../../../../common/assets/other-icons/product_image_default.svg';

const ProductCard = (props) => {
    const {
        staticData, id, offer_number, preview_image, part_number, name,
        condition_value, main_data, offer, stat, href, buttons, review,
        offerDeleting, offerOptionsMenuDisabled, onRemoveProductOffer,
    } = props;
    const {price, currency, currency_list} = offer;
    const {reserved, total, viewed} = stat;
    const {id_label, views} = staticData.activity_hub.my_products.product_card;
    const cx = classnames.bind(styles);

    const productImage = href
        ? (
            <Link to={href}>
                <Image source={preview_image} alt={name}/>
            </Link>
        ) : <Image source={preview_image} alt={name}/>;

    const productPartNumber = href
        ? <Link to={href} className={styles.title}>{part_number}</Link>
        : <div className={styles.title}>{part_number}</div>;

    return (
        <div id={id} className={cx('container', {'deleting': offerDeleting})}>
            {offerDeleting && (
                <div className={styles['hiding-container']}>
                    <Preloader classNames={styles.preloader}/>
                </div>
            )}

            {productImage}

            <div className={styles.condition}>{condition_value}</div>

            <OptionsMenu buttons={buttons}
                         menuClassNames={styles['options-menu']}
                         isDisabledDelete={offerOptionsMenuDisabled}
                         onRemoveProductOffer={onRemoveProductOffer}
            />

            <div className={styles.content}>
                {productPartNumber}
                <div className={styles.id}>{id_label}: {offer_number}</div>
                <div className={styles.name}>{name}</div>
                <div className={styles.delivery}>{main_data[0].label}: {main_data[0].value}</div>
                <div className={styles['supply-conditions']}>{main_data[1].value}</div>
                <ProductStatus {...review} classNames={styles.status}/>
                <div className={styles.price}>{priceHandler(price)} {currency}
                    {currency_list.length !== 0 && (
                        <Tooltip tooltip={
                            <div>{currency_list.join(', ')}</div>
                        }
                                 contentClassNames={styles['info-content']}
                                 placement={'top'}
                                 minWidth={50}
                                 maxWidth={150}>
                            <span className={styles.info} onClick={(e) => e.stopPropagation()}>
                                <InfoMark classNames={styles['info-icon']} markColor={colors.darkGrey}/>
                            </span>
                        </Tooltip>
                    )}
                </div>
                <div className={styles['stock-count']}>{total}</div>
            </div>

            <div className={styles.stickers}>
                {reserved && (
                    <div className={`${styles.sticker} ${styles.reserved}`}>
                        {reserved}
                    </div>
                )}
                <div className={`${styles.sticker} ${styles.views}`}>
                    {views}: {viewed}
                </div>
            </div>
        </div>
    );
};

ProductCard.defaultProps = {
    preview_image: defaultProductImage,
    isOnModeration: false,
    offerDeleting: false,
};

ProductCard.propTypes = {
    staticData: PropTypes.object,
    id: PropTypes.any,
    offer_number: PropTypes.string,
    preview_image: PropTypes.shape({
        xs: PropTypes.shape({
            jpg: PropTypes.string,
            webp: PropTypes.string,
        }),
    }),
    part_number: PropTypes.string,
    name: PropTypes.string,
    condition_value: PropTypes.string,
    main_data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    offer: PropTypes.shape({
        price: PropTypes.string,
        currency: PropTypes.string,
        currencyList: PropTypes.arrayOf(PropTypes.string),
    }),
    stat: PropTypes.shape({
        reserved: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        total: PropTypes.string,
        viewed: PropTypes.number,
    }),
    href: PropTypes.string,
    buttons: PropTypes.array,
    review: PropTypes.object,
    offerDeleting: PropTypes.bool,
};

export default compose(
    withDataService(),
    withGridArea(),
)(ProductCard);