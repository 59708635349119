export const challenges_en = [
    {
        title: 'Limited access to simple with intuitive interface e-trade platforms, especially for SMEs',
        text: `Lack of affordability and hence access to corporate level electronic 
                procurement system and complexity of use, which giant air and land transport operators only utilize.`,
        url: 'challenge_4.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Incomplete automation of export documentation',
        text: `There is an incomplete compilation of invoices and packaging lists for comprehensive 
                export documentation. Equally, the mixture of manual and electronic systems in procurements 
                creates complexity and confusion.`,
        url: 'challenge_5.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Trust issues, especially from new customers',
        text: `Lack of trust from customers when engaging in online transactions creates fear, 
                hesitation, and failed purchases. Also, bureaucracy within state-owned corporations causes 
                delayed payments, which hampers consumer-vendor business relationships.`,
        url: 'challenge_7.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Financial irresponsibility and lack of real-time consignment tracking',
        text: `No real-time updates on the shipment status, coupled with a 
                lack of financial responsibility for the logistics and timely delivery of spare parts.`,
        url: 'challenge_6.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Higher pricing by mediator companies',
        text: `Ever been a victim of "middlemen" that often overcharge spare parts, 
                sometimes up to triple the price of the regular price?`,
        url: 'challenge_2.svg',
        alt: 'Challenge image',
    },
    {
        title: 'Security issues',
        text: `Transactions are marred with fraudulent activities such as defaulted deliveries, 
                loss of money, and receipt of defective products.`,
        url: 'challenge_3.svg',
        alt: 'Challenge image',
    },
];