import React from 'react';
import PropTypes from 'prop-types';

import {priceHandler} from '../../../../../../../../../../../../../utils';

import Button from '../../../../../../../../../../../../common/button';

import styles from './packing-list-summary.module.scss';

const PackingListSummary = (props) => {
    const {
        staticData, classNames, total_place, total_net_weight, total_gross_weight,
        confirmPackingListLoading, isConfirmedDisabled, isUnpackedProduct, onConfirm,
    } = props;
    const {
        total_places_label, total_net_weight_label, total_gross_weight_label, warning_message,
        button: {create_packing_list},
    } = staticData;

    return (
        <div className={`${styles.container} ${classNames}`}>
            <div className={styles['total-blocks']}>
                <div className={styles['total-block']}>
                    <div className={styles.label}>{total_places_label}</div>
                    <div className={styles.value}>{total_place}</div>
                </div>
                <div className={styles['total-block']}>
                    <div className={styles.label}>{total_net_weight_label}</div>
                    <div className={styles.value}>
                        {priceHandler(total_net_weight.value)} {total_net_weight.unit}
                    </div>
                </div>
                <div className={styles['total-block']}>
                    <div className={styles.label}>{total_gross_weight_label}</div>
                    <div className={styles.value}>
                        {priceHandler(total_gross_weight.value)} {total_gross_weight.unit}
                    </div>
                </div>
            </div>

            {isUnpackedProduct && <div className={styles.warning}>{warning_message}</div>}

            <Button classNames={styles.btn}
                    dataLoading={confirmPackingListLoading}
                    isDisabled={isConfirmedDisabled}
                    onClick={onConfirm}>
                {create_packing_list}
            </Button>
        </div>
    );
};

PackingListSummary.defaultProps = {
    onConfirm: () => {
    },
};

PackingListSummary.propTypes = {
    staticData: PropTypes.object,
    classNames: PropTypes.string,
    total_place: PropTypes.string,
    total_net_weight: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
    }),
    total_gross_weight: PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
    }),
    confirmPackingListLoading: PropTypes.bool,
    isConfirmedDisabled: PropTypes.bool,
    isUnpackedProduct: PropTypes.bool,
    onConfirm: PropTypes.func,
};

export default PackingListSummary;