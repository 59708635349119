import React from 'react';
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';
import PropTypes from 'prop-types';

import {useElementParameters, useParallaxDirection} from './hooks';
import {withDataService} from '../../../common/hoc';

import styles from './banner.module.scss';

import car from './assets/sand_theme/car_with_road.svg';
import helicopter from './assets/sand_theme/Mi-8.svg';
import mountains from './assets/sand_theme/mountains_right.svg';
import radar from './assets/sand_theme/radar_and_moutains.svg';

const Banner = ({staticData}) => {
    const {image_alt} = staticData.home_page;

    const {
        carParallaxStyleObject,
        helicopterParallaxStyleObject,
        mountainParallaxStyleObject,
        radarParallaxStyleObject,
    } = useElementParameters();

    const {
        carParallaxDirection,
        helicopterParallaxDirection,
        mountainParallaxDirection,
        radarParallaxDirection,
    } = useParallaxDirection();

    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <ParallaxProvider>
                    <Parallax {...carParallaxDirection}
                              styleInner={carParallaxStyleObject}
                              className={styles['banner-img']}>
                        <img src={car} alt={image_alt}/>
                    </Parallax>

                    <Parallax {...helicopterParallaxDirection}
                              styleInner={helicopterParallaxStyleObject}
                              className={styles['banner-img']}>
                        <img src={helicopter} alt={image_alt}/>
                    </Parallax>

                    <Parallax {...mountainParallaxDirection}
                              styleInner={mountainParallaxStyleObject}
                              className={styles['banner-img']}>
                        <img src={mountains} alt={image_alt}/>
                    </Parallax>

                    <Parallax {...radarParallaxDirection}
                              styleInner={radarParallaxStyleObject}
                              className={styles['banner-img']}>
                        <img src={radar} alt={image_alt}/>
                    </Parallax>
                </ParallaxProvider>
            </div>
        </div>
    );
};

Banner.propTypes = {
    staticData: PropTypes.object,
};

export default withDataService()(Banner);