export const updateTabsForPacking = (newTabs) => {
    return {
        type: 'UPDATE_TABS_FOR_PACKING',
        payload: newTabs,
    };
};

export const updateProductsListForPacking = (unpacked, forPacking) => {
    let dataForPacking = null;

    if (forPacking.length !== 0) {
        let object = {};

        forPacking.forEach(({name, quantity}) => {
            object = {
                ...object,
                [name]: quantity,
            };
        });

        dataForPacking = object;
    }

    const payload = {unpacked, forPacking, dataForPacking};

    return {
        type: 'UPDATE_PRODUCTS_LIST_FOR_PACKING',
        payload,
    };
};