import FormFieldBuilder from '../../../../../utils/form-field-builder/form-field-builder';
import styles from '../scss/required.module.scss';

export const formFieldBuilder = new FormFieldBuilder();

export const fetchSessionStorageItems = (item, key) => formFieldBuilder.fetchSessionStorageItems(item, key);

export const removeSessionStorageItems = (item, key) => formFieldBuilder.removeSessionStorageItems(item, key);

export const removeSessionStorageItemsAfterCheck = (item, key) => {
    const sessionStorageItems = fetchSessionStorageItems(item, key);

    if (sessionStorageItems) {
        removeSessionStorageItems(item, key);
    }
};

export const transformFieldData = (field, storageItems1, storageItems2) => formFieldBuilder.transformFieldData(field, storageItems1, storageItems2);

export const onCheckingPasswordFields = (list, name) => formFieldBuilder.onCheckingPasswordFields(list, name);

export const getDataFromFields = (list) => formFieldBuilder.getDataFromFields(list);

const transformFieldsWithInputGroup = (array, storageItem) => {
    const sessionStorage = window.sessionStorage;
    const sessionStorageItems = JSON.parse(sessionStorage.getItem(storageItem));

    const nameFieldParamsIdx = array.findIndex(({type, name}) => type === 'text' && name.includes('name'));
    const nameFieldParams = array[nameFieldParamsIdx];

    const valueFieldParamsIdx = array.findIndex(({type, name}) => type === 'text' && name.includes('value'));
    const valueFieldParams = array[valueFieldParamsIdx];

    if (sessionStorageItems) {
        const itemKeys = Object.keys(sessionStorageItems);
        return itemKeys.map(key => {
            const fieldIdx = array.findIndex(({name}) => name === key);

            if (fieldIdx !== -1) {
                return {
                    ...array[fieldIdx],
                    value: sessionStorageItems[key],
                };
            } else {
                const regExp = /\d+/g;
                const nameFieldIdx = parseInt(nameFieldParams.group.match(regExp)[0]);
                const valueFieldIdx = parseInt(valueFieldParams.group.match(regExp)[0]);

                if (key.includes('name')) {
                    const idxFromKey = parseInt(key.match(regExp)[0]);

                    return {
                        ...nameFieldParams,
                        group: nameFieldParams.group.replace(nameFieldIdx, idxFromKey + 1),
                        name: key,
                        value: sessionStorageItems[key],
                    };
                }

                if (key.includes('value')) {
                    const idxFromKey = parseInt(key.match(regExp)[0]);

                    return {
                        ...valueFieldParams,
                        group: nameFieldParams.group.replace(valueFieldIdx, idxFromKey + 1),
                        name: key,
                        value: sessionStorageItems[key],
                    };
                }

                return null;
            }
        });
    } else {
        return array.map((item) => formFieldBuilder.initFieldData(item));
    }
};

const transformFieldWithDependsFrom = (fields, currentField, withNameKeyword = false) => {
    const dependsRadioGroupIdx = fields.findIndex(({type}) => type === 'radio');
    const dependsRadioGroup = fields[dependsRadioGroupIdx];
    let dependsRadioGroupName = dependsRadioGroup.name;

    if (withNameKeyword) {
        // finds all characters inside square brackets
        const regExp = /[^\][]+(?=])/gi;
        const array = dependsRadioGroup.name.match(regExp);
        dependsRadioGroupName = array[array.length - 1];
    }

    const activeRadioIdx = dependsRadioGroup.list.findIndex(({isChecked}) => isChecked);
    const activeRadioValue = dependsRadioGroup.list[activeRadioIdx].value;

    if (`${dependsRadioGroupName}:${activeRadioValue}` === currentField.dependsFrom) {
        return {
            ...currentField,
            isShown: true,
        };
    } else {
        return {
            ...currentField,
            isShown: false,
        };
    }
};

const onChangeFieldValue = (fields, fieldName, value, type) => {
    const currentFieldIdx = fields.findIndex(({name}) => name === fieldName);
    const currentField = fields[currentFieldIdx];
    let newCurrentField = {
        ...currentField,
        value,
    };

    if (type) {
        newCurrentField = {
            ...currentField,
            type,
            value,
        };
    }

    return [
        ...fields.slice(0, currentFieldIdx),
        newCurrentField,
        ...fields.slice(currentFieldIdx + 1),
    ];
};

const onChangeFieldDocuments = (fields, fieldName, files, rejectedFiles, alreadyUploadedFiles = null) => {
    const currentFieldIdx = fields.findIndex(({name}) => name === fieldName);
    const currentField = fields[currentFieldIdx];

    let newCurrentField = {};

    if (!alreadyUploadedFiles) {
        const {documents, rejectedDocuments, ...rest} = currentField;
        newCurrentField = {...rest};

        if (files.length > 0) {
            newCurrentField = {
                ...newCurrentField,
                documents: files,
            };
        }

        if (rejectedFiles.length > 0) {
            newCurrentField = {
                ...newCurrentField,
                rejectedDocuments: rejectedFiles,
            };
        }

    } else {
        const {value, ...rest} = currentField;
        newCurrentField = {
            ...rest,
            value: alreadyUploadedFiles.length > 0 ? alreadyUploadedFiles : [],
        };
    }

    return [
        ...fields.slice(0, currentFieldIdx),
        newCurrentField,
        ...fields.slice(currentFieldIdx + 1),
    ];
};

const onTransformFieldsWithSelectInputGroup = (fields) => {
    let transformFields = fields;

    const checkboxFieldsFromGroup = fields.filter(({type, group}) => type === 'checkbox' && group);

    checkboxFieldsFromGroup.forEach(({name, group, value}) => {
        const setCheckboxHidden = (fields) => {
            const idx = fields.findIndex((field) => field.name === name);

            return [
                ...fields.slice(0, idx),
                {
                    ...fields[idx],
                    value: 0,
                    hidden: true,
                },
                ...fields.slice(idx + 1),
            ];
        };

        if (typeof value === 'string') {
            value = parseInt(value);
        }

        const anotherFieldsFromGroup = transformFields
            .filter((field) => field.group === group && field.type !== 'checkbox');

        const select = anotherFieldsFromGroup.find(({type}) => type === 'select');

        if (!value && select) {
            if (!select.list || select.list.length === 0) {
                transformFields = setCheckboxHidden(transformFields);
            }

            anotherFieldsFromGroup.forEach((field) => {
                const idx = transformFields.findIndex(({name}) => field.name === name);
                const currentField = transformFields[idx];
                let newField = currentField;

                if (currentField.type === 'select') {
                    newField = {
                        ...newField,
                        type: 'hidden',
                    };
                }

                if (currentField.type === 'hidden') {
                    newField = {
                        ...newField,
                        type: 'text',
                    };
                }

                transformFields = [
                    ...transformFields.slice(0, idx),
                    newField,
                    ...transformFields.slice(idx + 1),
                ];
            });
        }

        if (value) {
            if (select && (!select.list || select.list.length === 0)) {
                transformFields = setCheckboxHidden(transformFields);

                anotherFieldsFromGroup.forEach((field) => {
                    const idx = transformFields.findIndex(({name}) => field.name === name);
                    const currentField = transformFields[idx];
                    let newField = currentField;

                    if (currentField.type === 'select') {
                        newField = {
                            ...newField,
                            type: 'hidden',
                        };
                    }

                    if (currentField.type === 'hidden') {
                        newField = {
                            ...newField,
                            type: 'text',
                        };
                    }

                    transformFields = [
                        ...transformFields.slice(0, idx),
                        newField,
                        ...transformFields.slice(idx + 1),
                    ];
                });
            }

            if (anotherFieldsFromGroup.find(({type}) => type === 'text')) {
                anotherFieldsFromGroup.forEach((field) => {
                    const idx = transformFields.findIndex(({name}) => field.name === name);
                    const currentField = transformFields[idx];
                    let newField = currentField;

                    if (currentField.type === 'text') {
                        newField = {
                            ...newField,
                            type: 'hidden',
                        };
                    }

                    if (currentField.type === 'hidden') {
                        newField = {
                            ...newField,
                            type: 'select',
                        };
                    }

                    transformFields = [
                        ...transformFields.slice(0, idx),
                        newField,
                        ...transformFields.slice(idx + 1),
                    ];
                });
            }
        }
    });

    return transformFields;
};

const onUpdateSelectInputGroup = (currentFields, fields) => {
    let updatedFields = fields;

    currentFields.forEach(({name, value, type}) => {
        updatedFields = onChangeFieldValue(updatedFields, name, value, type);
    });

    return updatedFields;
};

const onChangeCheckboxGroupValue = (fields, name, value) => {
    const idx = fields.findIndex((field) => field.name === name);
    const currentField = fields[idx];

    let newCurrentFieldValue = [
        ...currentField.value,
        value,
    ];

    if (currentField.value.includes(value)) {
        const valueIdx = currentField.value.findIndex(item => item === value);
        newCurrentFieldValue = [
            ...currentField.value.slice(0, valueIdx),
            ...currentField.value.slice(valueIdx + 1),
        ];
    }

    return [
        ...fields.slice(0, idx),
        {
            ...currentField,
            value: newCurrentFieldValue,
        },
        ...fields.slice(idx + 1),
    ];
};

const onChangeRadioButtonValue = (fields, id, name, value, withNameKeyword = false) => {
    const currentRadioGroupIdx = fields.findIndex((field) => field.name === name);
    const currentRadioGroup = fields[currentRadioGroupIdx];

    let updatedValue = '';
    const newRadioGroupList = currentRadioGroup.list.map(item => {
        const currentValue = typeof item.value === 'number' ? parseInt(value) : value;
        if (item.value === currentValue) {
            updatedValue = currentValue;

            return {
                ...item,
                isChecked: true,
            };
        }

        return {
            ...item,
            isChecked: false,
        };
    });

    return [
        ...fields.slice(0, currentRadioGroupIdx),
        {
            ...currentRadioGroup,
            list: newRadioGroupList,
            value: updatedValue,
        },
        ...fields.slice(currentRadioGroupIdx + 1),
    ]
        .map(field => {
            if (field.dependsFrom) {
                let keyword = id;

                if (withNameKeyword) {
                    /* finds all characters inside square brackets and a string with a colon */
                    const regExp = /[^\][]+(?=])|:.*/gi;
                    const array = id.match(regExp);

                    keyword = `${array[array.length - 2]}${array[array.length - 1]}`;
                }

                if (field.dependsFrom === keyword) {
                    return {
                        ...field,
                        isShown: true,
                    };
                } else {
                    return {
                        ...field,
                        isShown: false,
                    };
                }
            }

            return field;
        });
};

const selectAutocompleteValue = (key, value, fields) => {
    const currentOptionIdx = fields.findIndex(({type, name}) => (
        type === 'select_autocomplete' && name === key
    ));

    const currentOptionItemIdx = fields[currentOptionIdx].options.findIndex((item) => item.value === parseInt(value));
    if (currentOptionItemIdx !== -1) {
        value = {
            value: fields[currentOptionIdx].options[currentOptionItemIdx].value,
            label: fields[currentOptionIdx].options[currentOptionItemIdx].label,
        };
    }

    return value;
};

const onCheckingRequiredFieldData = (fields, form) => {
    const requiredFields = fields
        .filter(({type, props, isShown = true}) =>
            props && props.includes('required') && type !== 'radio' && type !== 'hidden' && isShown)
        .filter(({value}) => value === '' || value === 'default' || value.length === 0);

    if (requiredFields.length > 0) {
        requiredFields.forEach((field) => {
            let {type, name} = field;
            let element = null;
            const requiredClass = styles.required;

            if (type === 'datepicker') {
                element = form.querySelector(`[name='${name}']`);

                if (element) {
                    element = element.closest(`[class*='input-date_container']`);
                    element.classList.add(requiredClass);
                }
            }

            if (type === 'select') {
                element = form.querySelector(`[data-name='${name}']`);
                element.classList.add(requiredClass);
            }

            if (type === 'telephone') {
                element = form.querySelector(`[name='${name}']`);

                if (element) {
                    element.parentNode.parentNode.classList.add(requiredClass);
                }
            }

            if (type !== 'select' && type !== 'datepicker' && type !== 'telephone') {
                element = form.querySelectorAll(`[name*='${name}']`);

                if (element) {
                    element.forEach(el => el.parentNode.classList.add(requiredClass));
                }
            }
        });
    }

    return requiredFields.length === 0;
};

const onResetFormError = (id) => {
    const form = document.getElementById(id);
    const elements = form.querySelectorAll(`.${styles.required}`);
    elements.forEach(el => el.classList.remove(`${styles.required}`));
};

const onUpdateDataWithValueType = (fields, data) => {
    const dataKeys = Object.keys(data);
    let updatedData = {};

    dataKeys.forEach(key => {
        const idx = fields.findIndex(({name}) => name === key);
        const field = fields[idx];
        const {type, props} = field;
        let value = data[key];

        if (value && value !== '') {
            if (props && props.includes('int')) {
                if (type === 'text') {
                    value = parseInt(value);
                }
            }

            if (props && props.includes('float')) {
                if (type === 'text') {
                    if (value.includes(' ')) {
                        const regExp = / /g;
                        value = value.replace(regExp, '');
                    }

                    if (value.includes(',')) {
                        const regExp = /,/g;
                        value = value.replace(regExp, '.');
                    }

                    value = parseFloat(value);
                }
            }
        }

        updatedData = {
            ...updatedData,
            [key]: value,
        };
    });

    return updatedData;
};

const formDataObjectHandler = (formData, data = {}, allFields = []) => {
    // for (let pair of formData.entries()) {
    //     console.log(pair[0], pair[1]);
    // }

    let object = {};
    formData.forEach(function (value, key) {
        // without telephone, because formData set telephone key with indents and brackets
        const addToObject = key !== 'day' && key !== 'month' && key !== 'year' && key !== 'telephone';

        if (addToObject) {
            if (key === 'platform[]' || key === 'title[]') {
                value = selectAutocompleteValue(key, value, allFields);
                if (value === '') {
                    value = [];
                } else {
                    value = [value];
                }
            }

            if (object[key]) {
                if (Array.isArray(object[key])) {
                    if (Array.isArray(value)) {
                        object[key] = [...object[key], ...value];
                    } else {
                        object[key] = [...object[key], value];
                    }
                } else {
                    if (Array.isArray(value)) {
                        object[key] = [object[key], ...value];
                    } else {
                        object[key] = [object[key], value];
                    }
                }

            } else {
                object[key] = value;
            }
        }
    });

    if (data) {
        let additionalData = {};
        let additionalNumberData = {};

        const numberFields = allFields.filter(({type, props}) =>
            type === 'text' && props && (props.includes('int') || props.includes('float'))
        );

        for (let key in data) {
            additionalData = {
                ...additionalData,
                [key]: data[key],
            };
        }

        if (numberFields.length > 0) {
            numberFields.forEach(({name}) => {
                additionalNumberData = {
                    ...additionalNumberData,
                    [name]: additionalData[name],
                };
            });
        }

        object = {
            ...additionalData,
            ...object,
            ...additionalNumberData,
        };
    }

    return object;
};

export {
    transformFieldWithDependsFrom,
    transformFieldsWithInputGroup,
    onChangeFieldValue,
    onChangeFieldDocuments,
    onTransformFieldsWithSelectInputGroup,
    onUpdateSelectInputGroup,
    onChangeCheckboxGroupValue,
    onChangeRadioButtonValue,
    onCheckingRequiredFieldData,
    onResetFormError,
    onUpdateDataWithValueType,
    formDataObjectHandler,
};