import React from 'react';
import PropTypes from 'prop-types';

import Chip from '../../chip';
import Arrow from '../../arrow';
import SkeletonPreloader from '../../skeleton-preloader';

import classnames from 'classnames/bind';
import styles from './sorting-desktop.module.scss';

const SortingDesktop = ({sortingOptions, isUpdating, isSkeletonPreloader, sortBy}) => {
    const cx = classnames.bind(styles);

    return (
        <div className={styles.container}>
            {sortingOptions.map(({name, href, isActive, arrow = ''}, idx) => (
                    <Chip key={idx}
                          type="SECONDARY"
                          isActive={isActive}
                          isDisable={isUpdating}
                          classNames={styles.option}
                          onClick={() => sortBy(href)}>
                        <SkeletonPreloader type={'TEXT'}
                                           loading={isSkeletonPreloader}
                                           classNames={cx({'skeleton-preloader': isSkeletonPreloader})}>
                            {name}
                            {arrow === 'up' && <Arrow isActive={isActive} isDisable={isUpdating}/>}
                            {arrow === 'down' && <Arrow type="DOWN" isActive={isActive} isDisable={isUpdating}/>}
                        </SkeletonPreloader>
                    </Chip>
                ),
            )}
        </div>
    );
};

SortingDesktop.defaultProps = {
    isSkeletonPreloader: false,
    sortBy: () => {},
};

SortingDesktop.propTypes = {
    sortingOptions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        arrow: PropTypes.string,
    })),
    isSkeletonPreloader: PropTypes.bool,
    sortBy: PropTypes.func,
};

export default SortingDesktop;