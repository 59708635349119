import {createRequestHandler} from '../../../utils';

const RESET_PASSWORD_FORM_PUBLIC_REQUEST = 'RESET_PASSWORD_FORM_PUBLIC_REQUEST';
const RESET_PASSWORD_FORM_PUBLIC_SUCCESS = 'RESET_PASSWORD_FORM_PUBLIC_SUCCESS';
const RESET_PASSWORD_FORM_PUBLIC_FAILURE = 'RESET_PASSWORD_FORM_PUBLIC_FAILURE';

export const resetPasswordFormPublicRequest = createRequestHandler('RESET_PASSWORD_FORM_PUBLIC');

const initialState = {
    loading: true,
    data: null,
    list: null,
    error: null,
};

const form = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case RESET_PASSWORD_FORM_PUBLIC_REQUEST:
            return initialState;

        case RESET_PASSWORD_FORM_PUBLIC_SUCCESS:
            const {form, ...rest} = payload;
            return {
                loading: false,
                data: rest,
                list: form,
                error: null,
            };

        case RESET_PASSWORD_FORM_PUBLIC_FAILURE:
            return {
                loading: false,
                data: null,
                list: null,
                error: payload,
            };

        default:
            return state;
    }
};

export default form;