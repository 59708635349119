import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withServices} from '../../../../../../service-context';
import {withHubSideMenu} from '../../../../common/hoc';
import {withDataService} from '../../../../../../common/hoc';
import {switchMenuActiveLinkID, resetRequestOnButtonUrl} from '../../../../../../../actions/activity-hub';
import {contractConditionsAction} from '../../../../../../../actions/activity-hub/contract-maps';
import {
    conditionDataRequest,
    updatingConditionDataRequest,
} from '../../../../../../../reducers/activity-hub/contract-maps/contract-condition/condition-data';
import {onButtonUrlRequest} from '../../../../../../../reducers/activity-hub/request-on-button-url';
import {onGetButtonMethod} from '../../../../../../common/button-constructor/handlers';
import {onGetAlertResponse} from '../../../../../../../utils';
import sweetAlert from '../../../../../../common/sweet-alert';

import ContractConditions from './contract-conditions';
import Preloader from '../../../../../../common/preloader';

import styles from '../../contracts/contract-for-supply/contract-for-supply.module.scss';

class ContractConditionsContainer extends Component {
    state = {
        editableElementID: 'contract_condition_editable',
        signLoading: false,
    };

    componentDidMount() {
        const {match: {params: {id}}, pageID, menuActiveLinkID} = this.props;

        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        this.props.fetchContractCondition(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            const {match: {params: {id}}} = this.props;
            this.props.fetchContractConditionUpdating(id);
        }

        /**
         * Next case work with straight request to back-end server,
         * Not work with redirect to another resource
         **/
        if (prevProps.requestOnButtonUrlResp !== this.props.requestOnButtonUrlResp) {
            const {status, text} = onGetAlertResponse(this.props.requestOnButtonUrlResp);

            if (status !== '' && text !== '') {
                sweetAlert(status, text).then(() => {
                    this.props.resetRequestOnButtonUrl();
                    this.setState({signLoading: false});
                });
            }
        }
    }

    onChangeCheckbox = ({target: {id, checked}}, listIdx = 0) => {
        const isActive = +checked;
        this.props.updateContractCondition(id, isActive, listIdx);
    };

    onReview = (payload = false) => {
        let scrollTo = 0;

        if (payload) {
            const element = document.getElementById(`${this.state.editableElementID}_1`);
            const elementTop = Math.abs(element.getBoundingClientRect().top);
            scrollTo = window.scrollY - elementTop - 16; // 16 is indent between condition block
        }

        window.scrollTo({top: scrollTo, behavior: 'smooth'});
        this.props.toggleReviewMode(payload);
    };

    onOpenDispute = () => {
        console.log('opened dispute');
    };

    onSign = ({method, url, target}) => {
        const {request_question} = this.props.staticData.activity_hub.e_contracts;
        if (method === 'POST') {
            sweetAlert('question', request_question)
                .then((resp) => {
                    if (resp.isConfirmed) {
                        this.setState({signLoading: true});
                        this.props.sendPOSTRequestOnButtonUrl(url);
                    }
                });
        } else {
            onGetButtonMethod(url, target, this.props.history);
        }
    };

    render() {
        const {data, loading, updating, review} = this.props;

        return (data && !loading && !updating)
            ? <ContractConditions {...this.state}
                                  data={data}
                                  review={review}
                                  onChangeCheckbox={this.onChangeCheckbox}
                                  onReview={this.onReview}
                                  onOpenDispute={this.onOpenDispute}
                                  onSign={this.onSign}/>
            : <Preloader classNames={styles.preloader}/>;
    }

    static propTypes = {
        data: PropTypes.object,
        loading: PropTypes.bool,
        updating: PropTypes.bool,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        language: PropTypes.string,
        requestOnButtonUrlResp: PropTypes.object,
        fetchContractCondition: PropTypes.func,
        fetchContractConditionUpdating: PropTypes.func,
        sendPOSTRequestOnButtonUrl: PropTypes.func,
        toggleReviewMode: PropTypes.func,
        switchMenuActiveLinkID: PropTypes.func,
        resetRequestOnButtonUrl: PropTypes.func,
    };
}

const mapServicesToProps = ({eContractsService, activityHubService}) => ({
    fetchContractCondition: conditionDataRequest(eContractsService.getContractCondition),
    fetchContractConditionUpdating: updatingConditionDataRequest(eContractsService.getContractCondition),
    sendPOSTRequestOnButtonUrl: onButtonUrlRequest(activityHubService.sendPOSTRequestOnButtonUrl),
});

const mapStateToProps = ({
                             activityHub: {requestOnButtonUrl, contractMaps: {condition: {conditionData}}, utils},
                             profile: {language},
                         }) => ({
    conditionData,
    data: conditionData.data,
    loading: conditionData.loading,
    updating: conditionData.updating,
    review: conditionData.review,
    menuActiveLinkID: utils.menuActiveLinkID,
    requestOnButtonUrlResp: requestOnButtonUrl,
    language,
});

const mapDispatchToProps = (dispatch, {
    fetchContractCondition,
    fetchContractConditionUpdating,
    sendPOSTRequestOnButtonUrl,
}) => {
    const {toggleReviewMode, updateContractCondition} = contractConditionsAction;
    return bindActionCreators({
        fetchContractCondition,
        fetchContractConditionUpdating,
        sendPOSTRequestOnButtonUrl,
        toggleReviewMode,
        updateContractCondition,
        switchMenuActiveLinkID,
        resetRequestOnButtonUrl,
    }, dispatch);
};

export default compose(
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ContractConditionsContainer);