import {createRequestHandler} from '../../utils';
import DataService from '../../services/data-service';

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

export const NOTIFICATIONS_UPDATING_REQUEST = 'NOTIFICATIONS_UPDATING_REQUEST';
export const NOTIFICATIONS_UPDATING_SUCCESS = 'NOTIFICATIONS_UPDATING_SUCCESS';
export const NOTIFICATIONS_UPDATING_FAILURE = 'NOTIFICATIONS_UPDATING_FAILURE';

export const ON_VIEW_NOTIFICATION_REQUEST = 'ON_VIEW_NOTIFICATION_REQUEST';
export const ON_VIEW_NOTIFICATION_SUCCESS = 'ON_VIEW_NOTIFICATION_SUCCESS';
export const ON_VIEW_NOTIFICATION_FAILURE = 'ON_VIEW_NOTIFICATION_FAILURE';

export const ON_DELETE_NOTIFICATION_REQUEST = 'ON_DELETE_NOTIFICATION_REQUEST';
export const ON_DELETE_NOTIFICATION_SUCCESS = 'ON_DELETE_NOTIFICATION_SUCCESS';
export const ON_DELETE_NOTIFICATION_FAILURE = 'ON_DELETE_NOTIFICATION_FAILURE';

export const notificationsRequest = createRequestHandler('NOTIFICATIONS', {language: true});
export const updatingNotificationsRequest = createRequestHandler('NOTIFICATIONS_UPDATING', {language: true});
export const onViewNotificationRequest = createRequestHandler('ON_VIEW_NOTIFICATION', {language: true});
export const onDeleteNotificationRequest = createRequestHandler('ON_DELETE_NOTIFICATION', {language: true});

const dataService = new DataService();

const initialState = {
    loading: true,
    updating: false,
    initList: null,
    list: null,
    selectedList: [],
    selectedAll: 0,
    unread: 0,
    total: 0,
    filters: null,
    error: null,
};

const onGetFiltersStaticData = (language) => {
    return dataService.getStaticData(language).activity_hub.notifications.filters;
};

const onUpdatingListWithFilter = (list, filters) => {
    if (filters.find(({isActive}) => isActive).id === 'unread') {
        return list.filter((item) => !item.viewed);
    }

    return list;
};

const onInitData = ({list, unread_count, total_count, language}, state) => {
    const filters = onGetFiltersStaticData(language);
    const initList = list.map(item => ({...item, checked: 0, opened: false}));

    return {
        ...state,
        loading: false,
        initList,
        list: initList,
        filters: [
            {id: 'all', label: filters[0], isActive: true},
            {id: 'unread', label: filters[1], isActive: false},
        ],
        unread: unread_count,
        total: total_count,
    };
};

const onUpdatedData = ({list, unread_count, total_count, language}, state) => {
    const {initList: prevInitList, filters: prevFilters} = state;

    const updatedInitList = prevInitList.map((prevItem) => {
        // finding with item.id because order can be changed
        const currentItem = list.find((item) => item.id === prevItem.id);
        return {
            ...prevItem,
            ...currentItem,
        };
    });

    const updatedList = onUpdatingListWithFilter(updatedInitList, prevFilters);

    let data = {
        ...state,
        updating: false,
        initList: updatedInitList,
        list: updatedList,
        unread: unread_count,
        total: total_count,
    };

    const filters = onGetFiltersStaticData(language);
    data = {
        ...data,
        filters: prevFilters.map((item, idx) => ({...item, label: filters[idx]})),
    };

    return data;
};

const onUpdateDataAfterAction = ({list, unread_count, total_count}, state, action) => {
    const {initList: prevInitList, filters: prevFilters} = state;
    // init list after delete action
    let initList = list.map(item => ({...item, checked: 0, opened: false}));

    if (action === 'view') {
        const openedItem = prevInitList.find(({opened}) => opened);
        initList = list.map(item => {
            return {...item, checked: 0, opened: item.id === openedItem.id};
        });
    }

    const updatedList = onUpdatingListWithFilter(initList, prevFilters);

    return {
        ...state,
        initList: initList,
        list: updatedList,
        unread: unread_count,
        total: total_count,
    };
};

const onUpdatingDataFromComponent = (payload, state) => {
    /* case when notifications want to update after logout (state = initialState) */
    if (!state.filters) {
        return state;
    }

    const {filters: prevFilters} = state;
    let data = state;
    let filters = prevFilters;

    let updatedInitList = [];
    if (Array.isArray(payload)) {
        updatedInitList = payload;
    }

    if (payload.list && payload.selectedList) {
        updatedInitList = payload.list;
        data = {
            ...data,
            selectedList: payload.selectedList,
            selectedAll: payload.selectedAll,
        };
    }

    let list = onUpdatingListWithFilter(updatedInitList, filters);

    // when 'unread' filter is turn on and list of unread notifications is an empty
    if (list.length === 0 && filters.find(({isActive}) => isActive).id === 'unread') {
        const openNotifications = updatedInitList.find(item => item.opened);
        if (openNotifications) {
            filters = filters.map((item) => {
                if (item.id === 'all') {
                    return {...item, isActive: true};
                }

                return {...item, isActive: false};
            });

            data = {...data, filters};
            list = onUpdatingListWithFilter(updatedInitList, filters);
        }
    }

    return {
        ...data,
        initList: updatedInitList,
        list,
    };
};

const onUpdatedDataWhenToggleFilters = (payload, state) => {
    const {initList: prevInitList} = state;
    if (prevInitList) {
        const updatedInitList = prevInitList.map((item) => {
            if (item.checked === 1) {
                return {...item, checked: 0};
            }

            return item;
        });

        const updatedList = onUpdatingListWithFilter(updatedInitList, payload);

        return {
            ...state,
            initList: updatedInitList,
            list: updatedList,
            filters: payload,
            selectedList: [],
            selectedAll: 0,
        };
    }

    return {
        ...state,
        filters: payload,
    };
};

const onUpdateFilterOnFailure = (language, state) => {
    const {filters: prevFilters} = state;
    const filtersStaticData = onGetFiltersStaticData(language);

    if (prevFilters) {
        return prevFilters.map((item, idx) => ({...item, label: filtersStaticData[idx]}));
    } else {
        return [
            {id: 'all', label: filtersStaticData[0], isActive: true},
            {id: 'unread', label: filtersStaticData[1], isActive: false},
        ];
    }
};

const notifications = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case NOTIFICATIONS_REQUEST:
            return initialState;

        case NOTIFICATIONS_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case ON_VIEW_NOTIFICATION_REQUEST:
        case ON_DELETE_NOTIFICATION_REQUEST:
            return state;

        case ON_VIEW_NOTIFICATION_FAILURE:
        case ON_DELETE_NOTIFICATION_FAILURE:
            return {
                ...state,
                filters: onUpdateFilterOnFailure(payload.language, state),
            };

        case NOTIFICATIONS_SUCCESS:
            return onInitData(payload, state);

        case NOTIFICATIONS_UPDATING_SUCCESS:
            return onUpdatedData(payload, state);

        case ON_VIEW_NOTIFICATION_SUCCESS:
            return onUpdateDataAfterAction(payload, state, 'view');

        case ON_DELETE_NOTIFICATION_SUCCESS:
            return onUpdateDataAfterAction(payload, state, 'delete');

        case NOTIFICATIONS_FAILURE:
            return {
                ...initialState,
                loading: false,
                filters: onUpdateFilterOnFailure(payload.language, state),
            };

        case NOTIFICATIONS_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                filters: onUpdateFilterOnFailure(payload.language, state),
            };

        case 'UPDATE_NOTIFICATIONS':
            return onUpdatingDataFromComponent(payload, state);

        case 'UPDATE_NOTIFICATIONS_FILTERS':
            return onUpdatedDataWhenToggleFilters(payload, state);

        default:
            return state;
    }
};

export default notifications;