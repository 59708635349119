import {combineReducers} from 'redux';

import suggestPnum from './suggest-part-number';
import suggestDataByPnum from './suggest-data-by-pnum';
import suggestPlatforms from './suggest-platforms';
import suggestTitles from './suggest-titles';

export const suggestReducer = combineReducers({
    pnum: suggestPnum,
    dataByPnum: suggestDataByPnum,
    platforms: suggestPlatforms,
    titles: suggestTitles,
});