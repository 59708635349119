import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {usePagination} from '@material-ui/lab/Pagination';

import classnames from 'classnames/bind';
import styles from '../with-pagination/pagination.module.scss';

const withPaginationProductsPage = () => (Wrapper) => {
    return (props) => {
        const {staticData, productPage, withPagination, paginationType, onSwitchPage} = props;
        const {loading, productsOptions: {total_pages, current_page}} = productPage;
        const {prev, next} = staticData.pagination.button;
        const smDevice = useMediaQuery({minWidth: 576});

        const {items} = usePagination({
            page: current_page || 0,
            count: total_pages || 0,
            defaultPage: 1,
            siblingCount: 1,
            boundaryCount: 1,
        });

        const onSwitch = (page) => {
            onSwitchPage(page);
        };

        let i = 0;
        const paginationPageButtons = items.map((item, idx) => {
                const {type, page, selected, ...rest} = item;

                const cx = classnames.bind(styles);
                const paginationBtnClasses = cx('page-btn', {
                    'active': selected && smDevice,
                });

                if (type === 'previous') {
                    const prevBtnClasses = cx('prev-btn', {'disabled': page === 0});
                    return <div key={idx} className={prevBtnClasses} onClick={() => onSwitch(page)}>{prev}</div>;
                }

                if (type === 'next') {
                    const nextBtnClasses = cx('next-btn', {'disabled': page === total_pages + 1});
                    return <div key={idx} className={nextBtnClasses} onClick={() => onSwitch(page)}>{next}</div>;
                }

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    return <div key={idx} className={paginationBtnClasses}>...</div>;
                }

                i = smDevice ? i : ++i;
                return smDevice ? (
                    <div key={idx} className={paginationBtnClasses} {...rest}
                         onClick={() => onSwitch(page)}>
                        {page}
                    </div>
                ) : i === 1
                    ? <div key={idx} className={paginationBtnClasses}>{`${current_page} / ${total_pages}`}</div>
                    : null;
            },
        );

        const paginationBlock =
            withPagination && !loading
                ? (
                    <div className={styles.container}>
                        {paginationPageButtons}
                    </div>)
                : null;

        return (
            <>
                <Wrapper {...props}
                         loading={loading}
                         paginationBlock={paginationType === 'IN_CONTENT' ? paginationBlock : null}/>
                {paginationType === 'PRIMARY' && paginationBlock}
            </>
        );
    };
};

export default withPaginationProductsPage;