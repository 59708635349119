import {createRequestHandler} from '../../utils';

export const MY_PRODUCTS_LIST_REQUEST = 'MY_PRODUCTS_LIST_REQUEST';
export const MY_PRODUCTS_LIST_SUCCESS = 'MY_PRODUCTS_LIST_SUCCESS';
export const MY_PRODUCTS_LIST_FAILURE = 'MY_PRODUCTS_LIST_FAILURE';

export const MY_PRODUCTS_LIST_UPDATING_REQUEST = 'MY_PRODUCTS_LIST_UPDATING_REQUEST';
export const MY_PRODUCTS_LIST_UPDATING_SUCCESS = 'MY_PRODUCTS_LIST_UPDATING_SUCCESS';
export const MY_PRODUCTS_LIST_UPDATING_FAILURE = 'MY_PRODUCTS_LIST_UPDATING_FAILURE';

export const productsListRequest = createRequestHandler('MY_PRODUCTS_LIST');
export const updatingProductsListRequest = createRequestHandler('MY_PRODUCTS_LIST_UPDATING');

const initialState = {
    productsOptions: [],
    products: [],
    loading: true,
    updating: false,
    message: null,
    error: null,

    filters: null,
    sorting: null,
    fileButtons: null,
    isFilterOpen: false,
};

const myProducts = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case MY_PRODUCTS_LIST_REQUEST:
            return initialState;

        case MY_PRODUCTS_LIST_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
                error: null,
            };

        case MY_PRODUCTS_LIST_SUCCESS:
        case MY_PRODUCTS_LIST_UPDATING_SUCCESS:
            const {
                products, buttons,
                file, // need removed after remove on server
                sort, ...rest
            } = payload;
            if (products) {
                const {list, ...options} = products;
                return {
                    ...initialState,
                    productsOptions: options,
                    products: list,
                    loading: false,
                    updating: false,

                    filters: rest,
                    sorting: sort,
                    fileButtons: buttons,
                };
            } else {
                return {
                    ...initialState,
                    message: payload.message,
                    loading: false,
                    updating: false,
                };
            }

        case MY_PRODUCTS_LIST_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case MY_PRODUCTS_LIST_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        case 'TOGGLE_MY_PRODUCTS_FILTER':
            return {
                ...state,
                isFilterOpen: payload,
            };

        default:
            return state;
    }
};

export default myProducts;