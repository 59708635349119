import ServiceError from './service-error';
import {getDataFromLocalStorage} from '../utils';

export default class HttpService {
    constructor() {
        this.baseUrl = '/api';
    }

    encodeQueryParams(params) {
        if (!params) {
            return '';
        }

        return '?' + Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    }

    async sendRequest(method, url, body, query, requestArgs) {
        const language = getDataFromLocalStorage('slanguage');

        let headers = {
            'Content-Type': 'application/json',
        };

        if (language) {
            headers = {
                ...headers,
                'Content-Language': language,
            };
        }

        let opts = {
            method,
            headers,
            body: body && JSON.stringify(body),
        };

        if (body && body.fileData) {
            let formData = new FormData();
            formData.append('file', body.fileData);

            if (language) {
                headers = {
                    'Content-Language': language,
                };

                opts = {
                    method,
                    headers,
                    body: formData,
                };

            } else {
                opts = {
                    method,
                    body: formData,
                };
            }
        }

        if (body && body.formData) {
            if (language) {
                headers = {
                    'Content-Language': language,
                };

                opts = {
                    method,
                    headers,
                    body: body.formData,
                };

            } else {
                opts = {
                    method,
                    body: body.formData,
                };
            }
        }

        let reqUrl = `${this.baseUrl}${url}`;

        if (query) {
            if (typeof query === 'string') {
                reqUrl = `${this.baseUrl}${url}${query}`;
            } else {
                reqUrl = `${this.baseUrl}${url}${this.encodeQueryParams(query)}`;
            }
        }

        const resp = await fetch(reqUrl, opts);

        if (resp.status === SERVER_ERROR) {
            const error_response = {
                status_code: resp.status,
                data: {error: resp.statusText},
            };

            throw error_response;
        }

        if (resp.status === NOT_FOUND) {
            // need work
        }

        let data = await resp.json();

        if (resp.status === TEMPORARY_REDIRECT) {
            data = {
                data: {
                    temporary_redirect: true,
                    url: data.url,
                },
            };
        }

        if (!data.status_code) {
            data = {
                ...data,
                status_code: resp.status,
            };
        }

        if (requestArgs) {
            data = {
                ...data,
                request_args: requestArgs,
            };
        }

        if (resp.status >= 400) {
            const error = new ServiceError(url, resp);
            console.log(error);

            throw data;
        }

        return data;
    };

    get(url, query, requestArgs) {
        return this.sendRequest('GET', url, null, query, requestArgs);
    }

    post(url, body, query, requestArgs) {
        return this.sendRequest('POST', url, body, query, requestArgs);
    }
}

export const TEMPORARY_REDIRECT = 307;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const NOT_ACCEPTABLE = 406;
export const SERVER_ERROR = 500;