export default class RFQService {
    constructor(httpService) {
        this._http = httpService;
        this.baseUrl = '/rfquotation';
    }

    getRFQList = () => {
        return this._http.get(`${this.baseUrl}/list`);
    };

    removeRFQItem = (query) => {
        return this._http.post(`${this.baseUrl}/remove`, query);
    };
}