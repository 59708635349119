import React from 'react';
import {useMediaQuery} from 'react-responsive';

const withGridArea = () => (Wrapped) => {
    return (props) => {
        const isRowBased = useMediaQuery({minWidth: 1200});

        const gridItemPositionHandler = (idx) => {
            const row = idx % 4 === 0 ? idx / 4 + 1 : Math.ceil(idx / 4);
            const col = idx % 4 + 1;

            return {
                container: isRowBased => ({
                    gridArea: isRowBased && `${row} / ${col} / ${row + 1} / ${col + 1}`,
                }),
            };
        };

        const itemPosition = gridItemPositionHandler(props.idx).container(isRowBased);

        return <Wrapped {...props} style={itemPosition}/>;
    };
};

export default withGridArea;