import {createRequestHandler} from '../../../utils';
import {REMOVE_RFQ_ITEM_SUCCESS} from './remove-rfq-item';
import {CREATE_E_CONTRACT_SUCCESS} from '../e-contracts/create-contract';

export const RFQ_LIST_REQUEST = 'RFQ_LIST_REQUEST';
export const RFQ_LIST_SUCCESS = 'RFQ_LIST_SUCCESS';
export const RFQ_LIST_FAILURE = 'RFQ_LIST_FAILURE';

export const RFQ_LIST_UPDATING_REQUEST = 'RFQ_LIST_UPDATING_REQUEST';
export const RFQ_LIST_UPDATING_SUCCESS = 'RFQ_LIST_UPDATING_SUCCESS';
export const RFQ_LIST_UPDATING_FAILURE = 'RFQ_LIST_UPDATING_FAILURE';

export const rfqListRequest = createRequestHandler('RFQ_LIST');
export const rfqListUpdatingRequest = createRequestHandler('RFQ_LIST_UPDATING');

const initialState = {
    list: [],
    loading: true,
    updating: false,
    error: null,
};

const rfqList = (state, action) => {
    const {type, payload} = action;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case RFQ_LIST_REQUEST:
            return initialState;

        case RFQ_LIST_UPDATING_REQUEST:
            return {
                ...state,
                updating: true,
            };

        case RFQ_LIST_SUCCESS:
        case RFQ_LIST_UPDATING_SUCCESS:
        case REMOVE_RFQ_ITEM_SUCCESS:
        case CREATE_E_CONTRACT_SUCCESS:
            return {
                list: payload.list,
                loading: false,
                updating: false,
                error: null,
            };

        case RFQ_LIST_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload,
            };

        case RFQ_LIST_UPDATING_FAILURE:
            return {
                ...state,
                updating: false,
                error: payload,
            };

        default:
            return state;
    }
};

export default rfqList;