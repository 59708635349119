export const about_independent_technical_surveying_ru = {
    title: 'Подробнее о независимом техническом контроле',
    subtitle: `Мы в Exportery инспектируем каждую отправку запасных частей и являемся независимой 
    и непредвзятой стороной для наших клиентов`,
    content: [
        {
            description: `Вскрытие и проверка содержимого каждого отгрузочного места на предмет соответствия по количеству`,
            img: 'quantity.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Проверка технической документации, наличие паспортов, формуляров, этикеток и 
            соответствие поставляемой продукции стандартам ГОСТ, МАК, ICAO, ISO and EASA`,
            img: 'technical-books.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Проверка сроков хранения и консерваций каждого изделия`,
            img: 'exp-date.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Фотосъемка всех изделий с последующим отражением в фотоотчете. Фото сверка изделий на 
            предмет соответствия номеров, указанных в паспортах и формулярах и на самих изделиях`,
            img: 'photo.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Проверка каждого изделия на предмет использования в прошлом, перекраски, ремонта или деформаций`,
            img: 'checking.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Запрос заводов производителей на предмет аутентичности в особых случаях`,
            img: 'authentication.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
        {
            description: `Обязательное составление Инспекционного акта техническими специалистами Exportery для 
            последующего утверждения принимающей стороной - Импортером`,
            img: 'report.svg',
            alt: 'Image',
            assets_folder: 'about-independent-technical-surveying',
        },
    ],
};