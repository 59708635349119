import React from 'react';
import PropTypes from 'prop-types';

import TooltipCreator from '../tooltip-creator';

import classnames from 'classnames/bind';
import styles from './radio-button.module.scss';

const RadioButton = ({name, value, label, tooltip, isChecked, isActive, readOnly, onRadioChange}) => {
    const radioID = `${name}:${value}`;

    const cx = classnames.bind(styles);
    const radioClasses = cx('radio', {
        'read-only': !isActive || readOnly,
    });

    return (
        <label className={radioClasses} htmlFor={radioID}>
            <input id={radioID}
                   type="radio"
                   name={name}
                   value={value}
                   onChange={onRadioChange}
                   checked={isChecked}
                   disabled={!isActive || readOnly}
                   hidden/>
            <span className={styles['checked-circle']}/>
            <span>
                {label}
                {tooltip && <TooltipCreator tooltip={tooltip} classNames={styles.tooltip}/>}
            </span>
        </label>
    );
};

RadioButton.defaultProps = {
    isActive: true,
    isChecked: false,
    readOnly: false,
    onRadioChange: () => {
    },
};

RadioButton.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    tooltip: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
    }),
    isActive: PropTypes.bool,
    isChecked: PropTypes.bool,
    readOnly: PropTypes.bool,
    onRadioChange: PropTypes.func,
};

export default RadioButton;