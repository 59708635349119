const updatedUrlWithParams = (location, optionHref) => {
    const {pathname} = location;

    if (optionHref !== '') {
        return optionHref;

    } else {

        return pathname;
    }
};

export {updatedUrlWithParams};