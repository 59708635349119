import doc from '../components/common/assets/documents-icons/doc-icon.svg';
import jpg from '../components/common/assets/documents-icons/jpg-icon.svg';
import pdf from '../components/common/assets/documents-icons/pdf-icon.svg';
import xls from '../components/common/assets/documents-icons/xls-icon.svg';
import png from '../components/common/assets/documents-icons/png-icon.svg';
import defaultIcon from '../components/common/assets/documents-icons/default-icon.svg';

const documentIconHandler = (type) => {
    switch (type) {
        case 'application/pdf':
            return pdf;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return doc;
        case 'application/msword':
            return doc;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return xls;
        case 'application/vnd.ms-excel':
            return xls;
        case 'image/jpeg':
            return jpg;
        case 'image/png':
            return png;
        default:
            return defaultIcon;
    }
};

export default documentIconHandler;