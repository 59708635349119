const resetAllFilterOptions = (searchParams, defaultPath, canResetPnum) => {
    const regExpPNum = new RegExp('\\?pnum=.*?((?=&)|$)', 'g');
    const regExpSort = new RegExp('sort=.*', 'g');

    if (searchParams.match(regExpPNum) !== null && !canResetPnum) {
        if (searchParams.match(regExpSort) !== null) {
            return `${searchParams.match(regExpPNum)[0]}&${searchParams.match(regExpSort)[0]}`;
        }

        return searchParams.match(regExpPNum)[0];

    } else {
        if (searchParams.match(regExpSort) !== null) {
            return `?${searchParams.match(regExpSort)[0]}`;
        }

        return defaultPath;
    }
};

export {resetAllFilterOptions};