import React from 'react';

import {updatedPaginationParams} from './with-pagination-products-page/pagination-handler';

const withProductsHandler = () => (Wrapped) => {
    return (props) => {
        const {productsOptions: {total_pages}, products} = props.productPage;

        const onSwitchPage = (page) => {
            const {history, location} = props;
            const newUrl = updatedPaginationParams(location, page);

            history.push(newUrl);
        };

        const withScrollArrow = products.length !== 0;
        const isPagination = total_pages > 1;

        return (
            <Wrapped
                {...props}
                withScroll={withScrollArrow}
                paginationType="IN_CONTENT"
                withPagination={isPagination}
                onSwitchPage={(page) => onSwitchPage(page)}
            />
        );
    };
};

export default withProductsHandler;