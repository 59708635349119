import React, {Component} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../../../../../../common/hoc';
import {withServices} from '../../../../../../../../../service-context';
import {contractMapsAction} from '../../../../../../../../../../actions/activity-hub';
import {
    packingEditingFormRequest,
    updatingPackingEditingFormRequest,
} from '../../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/packing-editing-form';
import {
    unpackPackedProductRequest
} from '../../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/unpack-packed-product';
import {
    productsListForPackingRequest
} from '../../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/products-list-for-packing';
import {
    confirmPackingListRequest
} from '../../../../../../../../../../reducers/activity-hub/contract-maps/packing-list-wizard/confirm-packing-list';
import {onGetAlertResponse} from '../../../../../../../../../../utils';
import sweetAlert from '../../../../../../../../../common/sweet-alert';

import PackingList from './packing-list';

class PackingListContainer extends Component {
    state = {
        isConfirmedDisabled: false,
        isUnpackedProduct: true,
        scrollToForm: false,
    };

    componentDidMount() {
        const {packed: {list: packedProductsList}, unpacked: {list: unpackedProductList}} = this.props;

        /* look at status on each product in unpacked list, if status = true item is a packed */
        const unpackedProduct = unpackedProductList.findIndex(({row}) => !row[row.length - 1].value);
        /* look at status for packed products, if status isn't null, the confirm button always false */
        const confirmedItem = packedProductsList.findIndex(({status}) => status);

        this.setState({
            isConfirmedDisabled: unpackedProduct !== -1 || confirmedItem !== -1,
            isUnpackedProduct: unpackedProduct !== -1,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            if (this.props.packingEditingForm) {
                this.props.fetchPackingEditingFormUpdating(this.props.placeEditingID);
            }
        }

        if (prevProps.unpackPackedProductResp !== this.props.unpackPackedProductResp) {
            if (prevProps.unpackPackedProductResp.loading) {
                const {status, text} = onGetAlertResponse(this.props.unpackPackedProductResp);

                if (status !== '' && text !== '') {
                    sweetAlert(status, text).then(() => {
                        if (status === 'success') {
                            this.props.resetPackingListWizardPageWithoutTabs();
                            this.props.fetchProductsListForPacking(this.props.invoiceID);
                        } else {
                            this.props.resetUnpackPackedProduct();
                        }

                        window.scrollTo({top: 0});
                    });
                }
            }
        }

        if (prevProps.confirmPackingListResp !== this.props.confirmPackingListResp) {
            if (prevProps.confirmPackingListResp.loading) {
                const {status, text} = onGetAlertResponse(this.props.confirmPackingListResp);

                if (status !== '' && text !== '') {
                    sweetAlert(status, text).then(() => {
                        if (status === 'success') {
                            const {id} = this.props.confirmPackingListResp;
                            this.props.history.push(`/hub/contract/${id}/map`);
                        } else {
                            this.props.resetConfirmPackingList();
                        }
                    });
                }
            }
        }
    }

    onEditButtonClick = (id) => {
        this.props.fetchPackingEditingForm(id);
        this.props.updatePlaceEditingID(id);
        this.setState({scrollToForm: true});
    };

    onUnpackProduct = (id) => {
        const {unpack_question} = this.props.staticData;

        sweetAlert('question', unpack_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.unpackPackedProduct(id);
                }
            });
    };

    onConfirm = () => {
        const {staticData: {confirm_question}, invoiceID} = this.props;

        sweetAlert('question', confirm_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.props.confirmPackingList(invoiceID);
                }
            });
    };

    callbackScrollToFrom = () => this.setState({scrollToForm: false});

    render() {
        const {
            staticData, packed, packingEditingForm,
            packingEditingFormLoading, unpackPackedProductLoading,
            confirmPackingListLoading, switchSpareKitLoading,
        } = this.props;

        return <PackingList {...this.state}
                            staticData={staticData}
                            productsList={packed.list}
                            totalData={packed.total}
                            packingEditingForm={packingEditingForm}
                            packingEditingFormLoading={packingEditingFormLoading}
                            unpackPackedProductLoading={unpackPackedProductLoading}
                            confirmPackingListLoading={confirmPackingListLoading}
                            switchSpareKitLoading={switchSpareKitLoading}
                            onEditButtonClick={this.onEditButtonClick}
                            onUnpackProduct={this.onUnpackProduct}
                            onConfirm={this.onConfirm}
                            callbackScrollToFrom={this.callbackScrollToFrom}/>;
    }

    static propTypes = {
        staticData: PropTypes.object,
        language: PropTypes.string,
        invoiceID: PropTypes.number,
        packed: PropTypes.object,
        unpacked: PropTypes.object,
        packingEditingForm: PropTypes.array,
        unpackPackedProductResp: PropTypes.object,
        confirmPackingListResp: PropTypes.object,
        fetchPackingEditingForm: PropTypes.func,
        fetchPackingEditingFormUpdating: PropTypes.func,
        updatePlaceEditingID: PropTypes.func,
        unpackPackedProduct: PropTypes.func,
        resetPackingListWizardPageWithoutTabs: PropTypes.func,
        fetchProductsListForPacking: PropTypes.func,
        confirmPackingList: PropTypes.func,
        resetConfirmPackingList: PropTypes.func,
        resetUnpackPackedProduct: PropTypes.func,
    };
}

const mapServiceToProps = ({packingListWizardService}) => ({
    fetchPackingEditingForm: packingEditingFormRequest(packingListWizardService.getPackingEditingForm),
    fetchPackingEditingFormUpdating: updatingPackingEditingFormRequest(packingListWizardService.getPackingEditingForm),
    unpackPackedProduct: unpackPackedProductRequest(packingListWizardService.unpackPackedProduct),
    fetchProductsListForPacking: productsListForPackingRequest(packingListWizardService.getProductsListForPacking),
    confirmPackingList: confirmPackingListRequest(packingListWizardService.confirmPackingList),
});

const mapStateToProps = ({profile: {language}, activityHub: {contractMaps: {packingListWizard}}}) => {
    const {
        productsList, packingEditingForm,
        unpackPackedProduct, confirmPackingList,
    } = packingListWizard;

    let confirmPackingListResp = {
        loading: confirmPackingList.loading,
        error: confirmPackingList.error,
    };

    if (confirmPackingList.data) {
        confirmPackingListResp = {
            ...confirmPackingListResp,
            ...confirmPackingList.data,
        };
    }
    
    return {
        language,
        invoiceID: productsList.invoiceID,
        packed: productsList.packed,
        unpacked: productsList.unpacked,
        placeEditingID: packingEditingForm.placeEditingID,
        packingEditingForm: packingEditingForm.list,
        packingEditingFormLoading: packingEditingForm.loading,
        unpackPackedProductResp: unpackPackedProduct,
        unpackPackedProductLoading: unpackPackedProduct.loading,
        confirmPackingListResp,
        confirmPackingListLoading: confirmPackingList.loading,
    };
};

const mapDispatchToProps = (dispatch, {
    fetchPackingEditingForm,
    fetchPackingEditingFormUpdating,
    unpackPackedProduct,
    fetchProductsListForPacking,
    confirmPackingList,
}) => {
    const {
        packingListAction: {
            resetPackingListWizardPageWithoutTabs,
            resetUnpackPackedProduct,
            resetConfirmPackingList,
        },
        packingFormAction: {updatePlaceEditingID},
    } = contractMapsAction;

    return bindActionCreators({
        fetchPackingEditingForm,
        fetchPackingEditingFormUpdating,
        unpackPackedProduct,
        fetchProductsListForPacking,
        confirmPackingList,
        updatePlaceEditingID,
        resetPackingListWizardPageWithoutTabs,
        resetConfirmPackingList,
        resetUnpackPackedProduct,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService('activity_hub.e_contracts.pages.packing_list_wizard.pages.packing_list'),
    withServices(mapServiceToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(PackingListContainer);