import React from 'react';
import PropTypes from 'prop-types';

import StatusIndicator from '../../../../../../common/status-indicator';

import styles from './quotation-description.module.scss';

const QuotationDescription = (props) => {
    const {staticData, description} = props;
    const {status_row_label} = staticData.activity_hub.requests_for_quotations;

    const descriptions = description.map(({label, value, color}, idx) => {
        if (idx === description.length - 1) {
            return (
                <li key={idx} className={styles['item-block']}>
                    <div className={styles.label}>{status_row_label}</div>
                    <StatusIndicator status={{label, color}} classNames={styles.value}/>
                </li>
            );
        }

        return (
            <li key={idx} className={styles['item-block']}>
                <div className={styles.label}>{label}</div>
                <div className={styles.value}>{value}</div>
            </li>
        );
    });

    return <ul className={styles.container}>{descriptions}</ul>;
};

QuotationDescription.propTypes = {
    staticData: PropTypes.object,
    description: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string,
    })),
};

export default QuotationDescription;