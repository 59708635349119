import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withDataService} from '../../../../common/hoc';
import {withServices} from '../../../../service-context';
import {withHubSideMenu} from '../../common/hoc';
import {switchMenuActiveLinkID, resetRemoveRFQItem, resetCreateEContract} from '../../../../../actions/activity-hub';
import {
    rfqListRequest,
    rfqListUpdatingRequest,
} from '../../../../../reducers/activity-hub/requests-for-quotations/rfq-list';
import {removeRFQItemRequest} from '../../../../../reducers/activity-hub/requests-for-quotations/remove-rfq-item';
import {createContractRequest} from '../../../../../reducers/activity-hub/e-contracts/create-contract';
import {onGetAlertResponse} from '../../../../../utils';
import {onGetButtonMethod} from '../../../../common/button-constructor/handlers';
import sweetAlert from '../../../../common/sweet-alert';

import RequestsForQuotations from './requests-for-quotations';

class RequestsForQuotationsContainer extends Component {
    state = {
        quotationsList: null,
        removingItem: null,
        updatingItem: null,
    };

    componentDidMount() {
        const {pageID, menuActiveLinkID} = this.props;
        if (menuActiveLinkID !== pageID) {
            this.props.switchMenuActiveLinkID(pageID);
        }

        this.props.fetchRFQList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.props.fetchRFQListUpdating();
        }

        if (prevProps.rfqList !== this.props.rfqList) {
            if (this.props.rfqList.length !== 0) {
                const {table_row_type_column} = this.props.staticData.activity_hub.requests_for_quotations;

                const newList = this.props.rfqList.map(({row, ...rest}) => {
                    return ({
                        ...rest,
                        row: [
                            table_row_type_column,
                            ...row,
                        ],
                    });
                });

                this.setState({quotationsList: newList});
            }
        }

        if (prevProps.removeRFQItem !== this.props.removeRFQItem) {
            this.onClearAfterGetResponse(
                this.props.removeRFQItem,
                'removingItem',
                this.props.resetRemoveRFQItem,
            );
        }

        if (prevProps.removeRFQItem.loading !== this.props.removeRFQItem.loading) {
            if (this.props.removeRFQItem.loading !== this.state.removingItem.loading) {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        removingItem: {
                            ...prevState.removingItem,
                            loading: this.props.removeRFQItem.loading,
                        },
                    };
                });
            }
        }

        if (prevProps.createContract !== this.props.createContract) {
            this.onClearAfterGetResponse(
                this.props.createContract,
                'updatingItem',
                this.props.resetCreateEContract,
            );
        }

        if (prevProps.createContract.loading !== this.props.createContract.loading) {
            if (this.props.createContract.loading !== this.state.updatingItem.loading) {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        updatingItem: {
                            ...prevState.updatingItem,
                            loading: this.props.createContract.loading,
                        },
                    };
                });
            }
        }
    }

    onButtonClick = (rfq_id, rfq_sub_id, {method, url, target}) => {
        const {create_contract_question} = this.props.staticData.activity_hub.requests_for_quotations;
        if (method === 'POST') {
            sweetAlert('question', create_contract_question)
                .then((resp) => {
                    if (resp.isConfirmed) {
                        this.setState({
                            updatingItem: {
                                loading: true,
                                rfq_id,
                                rfq_sub_id,
                            },
                        });
                        this.props.fetchCreateContract(url);
                    }
                });
        } else {
            onGetButtonMethod(url, target, this.props.history);
        }
    };

    onRemoveSubQuotationItem = (rfq_id, offer_id) => {
        const {delete_item_question} = this.props.staticData.alert_modal;

        sweetAlert('question', delete_item_question)
            .then((resp) => {
                if (resp.isConfirmed) {
                    this.setState({
                        removingItem: {
                            loading: true,
                            rfq_id,
                            offer_id,
                        },
                    });
                    this.props.fetchRemoveRFQItem({rfq_id, offer_id});
                }
            });
    };

    onClearAfterGetResponse = (obj, localState, func) => {
        const {status, text} = onGetAlertResponse(obj);

        if (status !== '' && text !== '') {
            sweetAlert(status, text).then(() => {
                this.setState({[localState]: null});
                func();
            });
        }
    };

    render() {
        return (
            <RequestsForQuotations {...this.props} {...this.state}
                                   onButtonClick={this.onButtonClick}
                                   onRemoveSubQuotationItem={this.onRemoveSubQuotationItem}/>
        );
    }

    static propTypes = {
        staticData: PropTypes.object,
        rfqList: PropTypes.array,
        menuActiveLinkID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        language: PropTypes.string,
        removeRFQItem: PropTypes.object,
        createContract: PropTypes.object,
        fetchRFQList: PropTypes.func,
        fetchRFQListUpdating: PropTypes.func,
        fetchRemoveRFQItem: PropTypes.func,
        resetRemoveRFQItem: PropTypes.func,
        fetchCreateContract: PropTypes.func,
        switchMenuActiveLinkID: PropTypes.func,
        resetCreateEContract: PropTypes.func,
    };
}

const mapServicesToProps = ({rfqService, eContractsService}) => ({
    fetchRFQList: rfqListRequest(rfqService.getRFQList),
    fetchRFQListUpdating: rfqListUpdatingRequest(rfqService.getRFQList),
    fetchRemoveRFQItem: removeRFQItemRequest(rfqService.removeRFQItem),
    fetchCreateContract: createContractRequest(eContractsService.createContract),
});

const mapStateToProps
    = ({
           profile: {language},
           activityHub: {
               requestsForQuotations: {rfqList: {loading, list}, removeRFQItem},
               eContracts: {createContract},
               utils,
           },
       }) => ({
    loading,
    rfqList: list,
    removeRFQItem,
    menuActiveLinkID: utils.menuActiveLinkID,
    language,
    createContract,
});

const mapDispatchToProps = (dispatch, {
    fetchRFQList,
    fetchRFQListUpdating,
    fetchRemoveRFQItem,
    fetchCreateContract,
}) => {
    return bindActionCreators({
        fetchRFQList,
        fetchRFQListUpdating,
        fetchRemoveRFQItem,
        fetchCreateContract,
        resetRemoveRFQItem,
        switchMenuActiveLinkID,
        resetCreateEContract,
    }, dispatch);
};

export default compose(
    withRouter,
    withDataService(),
    withHubSideMenu(),
    withServices(mapServicesToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(RequestsForQuotationsContainer);