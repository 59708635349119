import {createRequestHandler, createAlertResponse} from '../../../utils';

export const UPLOAD_PROFILE_FORM_REQUEST = 'UPLOAD_PROFILE_FORM_REQUEST';
export const UPLOAD_PROFILE_FORM_SUCCESS = 'UPLOAD_PROFILE_FORM_SUCCESS';
export const UPLOAD_PROFILE_FORM_FAILURE = 'UPLOAD_PROFILE_FORM_FAILURE';

export const uploadProfileFormRequest = createRequestHandler('UPLOAD_PROFILE_FORM');

const initialState = {
    loading: false,
    message: null,
    error: null,
};

const uploadProfileForm = (state, actions) => {
    const {type, payload} = actions;

    if (state === undefined) {
        return initialState;
    }

    switch (type) {
        case UPLOAD_PROFILE_FORM_REQUEST:
            return {
                ...initialState,
                loading: true,
            };

        case UPLOAD_PROFILE_FORM_SUCCESS:
            const message = createAlertResponse('success', payload.message);
            return {
                loading: false,
                message,
                error: null,
            };

        case UPLOAD_PROFILE_FORM_FAILURE:
            const error = createAlertResponse('error', payload);
            return {
                ...initialState,
                error,
            };

        case 'RESET_UPLOAD_PROFILE_RESPONSE':
            return initialState;

        default:
            return state;
    }
};

export default uploadProfileForm;