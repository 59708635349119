import {staticURLs} from '../../../api-urls';

export const advantages_ru = [
    {
        title: 'Прямой доступ к поставкам и широкий ассортимент промышленных товаров',
        text: [
            `Exportery – это первая промышленная торговая электронная платформа с пулом технических ресурсов, 
            комплектующих и запасных частей для самолетов, вертолетов, наземных транспортных средств и различного 
            оборудования восточного и европейского производства, активно используемых африканским и 
            латиноамериканским частным и государственным секторами.`,
            `Как универсальный сервис, платформа Exportery располагает собственной системой электронного 
            документооборота, которая выводит эффективность и безопасность международных сделок на новый 
            уровень. Мы предоставляем современные решения, обеспечивающие оперативную доставку 
            запчастей и легкий процесс закупок.`,
        ],
        btn: 'Как работает Exportery',
        href: staticURLs.about_marketplace,
        img: 'marketplace.png',
        alt_img: 'Marketplace',
    },
    {
        title: 'Независимый технический контроль',
        text: [
            `Неотъемлемой частью любого процесса закупок является обеспечение качества и технический контроль. 
            Правильно организованный процесс обеспечения качества и контроля гарантирует, что конечные 
            потребители получат запасные части и компоненты в срок, с подтвержденным качеством и техническими 
            условиями, соответствующими международным стандартам ISO, EASA и ICAO, ГОСТ.`,
            `Ранее неоднократно отмечались случаи поставок отремонтированной или некачественной продукции, 
            выдаваемой за новую. Специалисты Exportery проводят независимую проверку каждой международной 
            партии товара, осуществляемого через нашу электронную платформу, с тем чтобы обеспечить 
            безопасность конечных пользователей товаров. Независимый технический осмотр проводится на 
            складах условного хранения в Эфиопии и Танзании сертифицированным техническим персоналом.`,
        ],
        btn: 'Подробнее о независимом техническом контроле',
        href: staticURLs.about_independent_technical_surveying,
        img: 'technical-surveying.png',
        alt_img: 'Technical surveying',
    },
    {
        title: 'Основные услуги',
        radio: [
            {
                title: 'Пункт отгрузки FCA для экспортера',
                text: [
                    `Все цены на запасные части и компоненты на платформе электронной коммерции 
                    базируются на условиях Инкотермс 2020 FCA Аэропорт страны происхождения, что делает 
                    процесс оценки и торговли прозрачным и удобным для участников рынка. От экспортера 
                    требуется только выполнить формальности по таможенному оформлению в стране экспорта.`,
                    `Exportery берет на себя всю логистическую нагрузку и несет все финансовые 
                    риски по отправке товаров из страны Экспортера через проверенную сеть логистических 
                    операторов, авиакомпаний и агентов.`,
                ],
                img: 'FCA.png',
                alt_img: 'Core services',
            },
            {
                title: 'Пункт назначения CIP для импортера',
                text: `Exportery автоматически просчитывает цены на все запасные части и компоненты после создания 
                электронной заявки для импортеров на условиях Инкотермс 2020 CIP Аэропорт пункта назначения. 
                Таким образом, импортер сможет в считанные минуты получить комплексное ценовое предложение, 
                которое будет включать доставку в любой из отдаленных пунктов назначения в мире, включая страхование.`,
                img: 'CIP.png',
                alt_img: 'Core services',
            },
            {
                title: 'Автоматизация экспортно-импортной документации',
                text: [
                    `Наша платформа электронной коммерции ориентирована на преодоление бюрократических и 
                    трудоемких процедур в процессах закупок и перевод процесса создания и обмена сертификата 
                    конечного пользователя, подтверждения поставки товара и банковских гарантий в цифровой 
                    формат с помощью внедренной технологии блокчейн и партнерских банковских учреждений.`,
                    `Мы уже предлагаем нашим клиентам удобство, беспрецедентную ответственность и непревзойденную 
                    поддержку благодаря разработанной нами системе управления контрактами, которая обеспечивает 
                    автоматический и интуитивно понятный выпуск и оборот важнейших импортно-экспортных документов, 
                    таких как электронные инвойсы, электронные акты приема-передачи, электронные упаковочные листы, 
                    электронные контракты и, наконец, электронные товарно-транспортные накладные.`,
                ],
                img: 'automation_of_documentation.png',
                alt_img: 'Core services',
            },
            {
                title: 'Электронные подписи на всех документах',
                text: `На платформе Exportery все документы защищены и подписываются электронной подписью 
                через надежную систему DocuSign, признанную в большинстве стран мира. Помимо упрощения 
                процесса таможенного оформления товаров, также обеспечивается подлинность и право собственности 
                на всю документацию, что повышает доверие к платформе электронной коммерции со стороны всех участников.`,
                img: 'e-signatures.png',
                alt_img: 'Core services',
            },
            {
                title: 'Система условного хранения и эскроу-счета',
                text: [
                    `Мы первопроходцы в применении эффективных условного хранения на развивающихся рынках
                    для обеспечения гарантии исполнения сделки между всеми сторонами. Товар будет отгружен 
                    и отправлен со склада условного хранения только после выполнения условий электронного контракта.`,
                    `Мы качественно меняем международный рынок торговли запасными частями своими новыми услугами 
                    условного складского хранения, своей беспрецедентной ответственностью и поддержкой, которые 
                    мы предлагаем всем нашим клиентам. Все товары, которые законтрактованы на торговой платформе 
                    проходят через процесс условного складского хранения в Танзании для исполнения гарантий по 
                    сделке, а средства Импортеров защищены на эскроу-счетах в авторитетных независимых 
                    панафриканских банковских учреждениях, что позволяет нашим импортерам осуществлять любые 
                    расчеты в местной валюте безопасно и эффективно.`,
                ],
                img: 'escrow-warehousing.png',
                alt_img: 'Core services',
            },
            {
                title: 'Безопасность и конфиденциальность электронных данных',
                text: `В отличие от других электронных платформ, уязвимых к нарушениям безопасности данных, 
                Exportery обладает надежной архитектурой обеспечения безопасности данных, которая защищает 
                информацию как импортеров, так и экспортеров от несанкционированного доступа и хищения. Наша 
                организация придерживается высоких стандартов конфиденциальности и не передает контакты и записи 
                операций электронной торговли третьим лицам. Помимо этого, наши базы данных и серверы расположены 
                в безопасных и нейтральных странах, таких как Швейцария и ОАЭ, что еще больше укрепляет доверие 
                к нам и нашу надежность.`,
                img: 'security.png',
                alt_img: 'Core services',
            },
        ],
        btn: 'Подробнее об основных услугах',
        href: staticURLs.about_core_services,
    },
];